import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { HTMLInputProps } from "@blueprintjs/core";

import { MPDIcon } from "src/mpd-library";
import { ReactComponent as SearchIcon } from "src/mpd-library/icon/assets/search.svg";

import "./styles.scss";

export const SearchInputClasses = {
    list: "search-input_list",
    dropdown: "search-input_dropdown"
};

export const SearchInput: React.FunctionComponent<HTMLInputProps> = (props) => {
    const [focused, setFocused] = useState<boolean>(false);
    const { className, autoFocus, ...newProps } = props;
    const ref = useRef(null);
    const classes = classNames("search-input", className, focused && "bp4-active");

    useEffect(() => {
        if (autoFocus) {
            ref.current.focus();
        }
    }, []);

    const onFocus = () => {
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
    };

    return (
        <div className={classes}>
            <MPDIcon IconComponent={SearchIcon} />
            <input type="search" {...newProps} ref={ref} onFocus={onFocus} onBlur={onBlur} />
        </div>
    );
};
