import omit from "lodash/omit";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { onUpdateToast } from "src/actions/global/global";
import {
    updateTagRequest,
    createTagRequest,
    removeTagRequest,
    getTags as getTagsRequest
} from "src/requests/tagsRequests";

import { ConversationTag } from "src/store";
import { useHistory } from "react-router-dom";

/*
    tags{
        byId: {
            ...
            [tagId]: {
                ...tag
            },
            ...
        }
        allIds: [...tagsIdsParentsAndNodes],
        uncheckedIds: [...tagsIdsAreUnchecked],
        checked: [...tagIdsAreChecked]
    }
*/
export type Tag = {
    id: string;
    name: string;
    nodes: Tag;
};

export type ConversationsTags = {
    byId: {
        [key: string]: Tag;
    };
    byParent: {
        [key: string]: Array<string>;
    };
    allIds: Array<string>;
    uncheckedIds: Array<string>;
    checkedIds: Array<string>;
};

export type UseConversationsTags = {
    getTagsProcessing: boolean;
    tagsById: { [key: string]: Tag };
    tagsAllIds: Array<string>;
    selectedTagsIds: Array<string>;
    notSelectedTagsIds: Array<string>;
    hasMore: boolean;
    // notSelectedTags: any;
    // selectedTags: Array<ConversationTag>;
    removeTag: (id: string) => void;
    getTags: () => void;
    updateTag: (tagId: string, data: ConversationTag) => void;
    createTag: (data: any) => void;
    deleteTag: (id: string) => void;
};

type useConversationsTagsProps = {
    checkedTagsMatchKeys?: { [tagId: string]: boolean };
    searchValue?: string;
    showDidsbledParent: boolean;
};

const TAGS_REQUEST_LIMIT = 30;

export const useConversationsTags = ({
    checkedTagsMatchKeys = {},
    searchValue = "",
    showDidsbledParent,
    getTagsParams
}: useConversationsTagsProps): UseConversationsTags => {
    const history = useHistory();
    const wasMount = useRef<boolean>(false);
    const [byId, setById] = useState<{ [key: string]: Tag }>({});
    const [allIds, setAllIds] = useState<Array<string>>([]);
    const [getTagsProcessing, setGetTagsProcessing] = useState<boolean>(false);
    const [foundTagsIds, setFoundTagsIds] = useState([]);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const dispatch = useDispatch();

    const getTags = async () => {
        try {
            setGetTagsProcessing(true);
            const {
                data: { has_more, tags }
            } = await getTagsRequest({ offset: allIds.length, limit: TAGS_REQUEST_LIMIT, ...getTagsParams });

            setHasMore(has_more);
            const tagsById = { ...byId };
            const allIdsUpdated = [...allIds];

            tags.forEach((tag) => {
                allIdsUpdated.push(tag.id);
                tagsById[tag.id] = tag;
            });

            setById(tagsById);
            setAllIds(allIdsUpdated);
        } finally {
            setGetTagsProcessing(false);
        }
    };

    const searchTags = useCallback(
        (searchValue: string) => {
            const filtered = allIds.filter((tagId) => {
                return byId[tagId].name.toLowerCase().includes(searchValue.toLowerCase());
            });
            setFoundTagsIds(filtered);
        },
        [allIds, searchValue]
    );

    useEffect(() => {
        getTags();
        wasMount.current = true;
    }, []);

    useEffect(() => {
        if (!wasMount.current) {
            return;
        }
        if (searchValue) {
            searchTags(searchValue);
        } else {
            setFoundTagsIds([]);
        }
    }, [searchValue]);

    const updateTag = async (tagId: string, data: ConversationTag) => {
        try {
            const res = await updateTagRequest(tagId, data);
            const {
                data: { tag: updatedTag }
            } = res;
            const updatedTagsById = { ...byId, [updatedTag.id]: { ...updatedTag } };
            setById(updatedTagsById);
            dispatch(onUpdateToast({ value: "Tag was successfully updated", type: "saved" }));
            return updatedTag;
        } catch (err) {
            dispatch(onUpdateToast({ value: "Something get wrong. Try again a bit later", type: "error" }));
        }
    };

    const createTag = async (data: Partial<ConversationTag>) => {
        try {
            const res = await createTagRequest(data);
            const {
                data: { tag: createdTag }
            } = res;

            let updatedAllIds;
            if (data.parent_id) {
                const index = allIds.findIndex((tagId: string) => data.parent_id === tagId);
                updatedAllIds = [...allIds.slice(0, index + 1), createdTag.id, ...allIds.slice(index + 1)];
            } else {
                updatedAllIds = [...allIds, createdTag.id];
            }

            const updatedTagsById = data.parent_id
                ? {
                      ...byId,
                      [data.parent_id]: {
                          ...byId[data.parent_id],
                          nodes: [...(byId[data.parent_id].nodes || []), createdTag]
                      },
                      [createdTag.id]: createdTag
                  }
                : { ...byId, [createdTag.id]: createdTag };
            setById(updatedTagsById);
            setAllIds(updatedAllIds);
            dispatch(onUpdateToast({ value: "Tag was successfully created", type: "saved" }));
            history.push("/account-settings/tags");
            return res;
        } catch (err) {
            console.log("TAG CREATE ERR >>>>", err);
            dispatch(onUpdateToast({ value: "Validation error. Please check the form", type: "error" }));
        }
    };

    const deleteTag = async (tagId: string) => {
        try {
            await removeTagRequest(tagId);
            setAllIds(allIds.filter((tagIdInArray) => tagIdInArray !== tagId));
            setById(omit(byId, tagId));
            dispatch(onUpdateToast({ value: "Tag was successfully removed", type: "saved" }));
            history.push("/account-settings/tags");
        } catch (err) {
            dispatch(onUpdateToast({ value: "Error! Try again a bit later", type: "error" }));
        }
    };

    const removeTag = async (tagId: string) => {
        try {
            await removeTagRequest(tagId);
            setAllIds(allIds.filter((tagIdInArray) => tagIdInArray !== tagId));
            setById(omit(byId, tagId));
            dispatch(onUpdateToast({ value: "Tag was successfully removed", type: "saved" }));
        } catch (err) {
            dispatch(onUpdateToast({ value: "Error! Try again a bit later", type: "error" }));
        }
    };

    const selectedTagsIds = useMemo((): Array<string> => {
        return allIds.filter((tagId: string) => !!checkedTagsMatchKeys[tagId]);
    }, [checkedTagsMatchKeys, allIds]);

    const notSelectedTagsIds = useMemo(() => {
        return allIds.filter(
            (tagId: string) =>
                !checkedTagsMatchKeys[tagId] ||
                (showDidsbledParent &&
                    byId[tagId].nodes.length &&
                    byId[tagId].nodes.some((tag) => !checkedTagsMatchKeys[tag.id]))
        );
    }, [allIds, checkedTagsMatchKeys, showDidsbledParent]);

    // ------------------------------------------------------------------------------

    return {
        getTagsProcessing,
        tagsById: byId,
        tagsAllIds: searchValue && foundTagsIds.length > 0 ? foundTagsIds : allIds,
        selectedTagsIds,
        notSelectedTagsIds: searchValue ? foundTagsIds : notSelectedTagsIds,
        hasMore,
        removeTag,
        getTags,
        updateTag,
        createTag,
        deleteTag
    };
};
