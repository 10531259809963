import { Text } from "@blueprintjs/core";
import "external-svg-loader";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { SmartLink } from "../../../../../../requests/smartLinksRequests";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../store";
import { useHistory } from "react-router";
import { SyntheticEvent } from "react";
import { formatPhoneNumber } from "react-phone-number-input";

const classname = "smart-link-info";

type SmartLinkItemInfoProps = {
    smartLink: SmartLink;
};

export const SmartLinkItemInfo = ({ smartLink }: SmartLinkItemInfoProps) => {
    const { qr_code_url, qr_code_params, keyword, conversations_count } = smartLink || {};
    const history = useHistory();

    const { branded_phone_number } = useSelector((state: AppState) => state.accountSettings.organization.details);

    const onLinkClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        history.push("/account-settings/branding");
    };

    return (
        <div className={classNames(styles[classname], classname)}>
            <div className={"info-cell"}>
                <Text className={"info-cell-title"}>SUBMISSIONS</Text>
                <Text className={"info-cell-value"}>{conversations_count}</Text>
            </div>
            <div className={"info-cell"}>
                <Text className={"info-cell-title"}>SMS LINE</Text>
                {branded_phone_number ? (
                    <Text className={"info-cell-value"}>{`Text ‘${keyword}’ to ${formatPhoneNumber(
                        branded_phone_number
                    )}`}</Text>
                ) : (
                    <Text className={classNames("info-cell-value", styles["link-to-branding"])} onClick={onLinkClick}>
                        Enable branded phone number
                    </Text>
                )}
            </div>

            {qr_code_params?.qr_code_img_url ? (
                <img src={qr_code_params?.qr_code_img_url} className={"qr-code"} />
            ) : (
                <svg data-src={qr_code_url} className={"qr-code"} />
            )}
        </div>
    );
};
