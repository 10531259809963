import classNames from "classnames";
import { useEffect } from "react";
import { StyledTextArea, StyledTextAreaTypes } from "src/components";
import styles from "./styles.module.scss";

const classname = "profile-listing-wrapper";

export const ProfileListing = ({ profileBioValue, getFieldProps, setFormikState, touched, errors, formik }) => {
    useEffect(() => {
        if (formik) {
            setFormikState(formik);
        }
    }, [formik]);

    return (
        <form>
            <div className={classNames(styles[classname], "profile-automations-item-wrapper")}>
                <StyledTextArea
                    autoCapitalize="none"
                    autoCorrect="none"
                    type={StyledTextAreaTypes.primaryStatic}
                    placeholder="CONVERSATIONS PROFILE BIO"
                    tabIndex={1}
                    rows={5}
                    isMaxLengthRequire={false}
                    value={profileBioValue}
                    showErrorText={true}
                    className={"profile-automations-section-textarea"}
                    error={touched.profileBioValue && errors.profileBioValue}
                    {...getFieldProps("profileBioValue")}
                />
                {/* <StyledDuoButtons
                className={classNames(styles[`${classname}-duo-buttons`], "duo-buttons", "profile-listing-footer")}
                firstButtonClick={onCollapseAfterApply}
                secondButtonClick={onSaveProfileListingSettings}
                firstButtonText="Cancel"
                secondButtonText="Save"
                type={StyledDuoButtonsTypes.primary}
                secondButtonProps={{ processing: updateSettingProcessing }}
            /> */}
            </div>
        </form>
    );
};
