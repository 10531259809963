import { useEffect, useRef, useState } from "react";
import { Input, InputTypes } from "src/mpd-library/input";
import { Collapse } from "@blueprintjs/core";
// import { AnimatePresence, motion } from "framer-motion";
import styles from "./EditPlace.module.scss";
import { MPDIcon } from "../../../../mpd-library";
import { useOutsideAlerter } from "../../../conversations/components/chat/hooks/useOutsideAlerter";
import { ReactComponent as PlaceWorkIcon } from "../../../../mpd-library/icon/assets/PlaceWork.svg";
import { ReactComponent as PlaceHomeIcon } from "../../../../mpd-library/icon/assets/PlaceHome.svg";
import { ReactComponent as PlaceGeneralIcon } from "../../../../mpd-library/icon/assets/PlaceGeneral.svg";

// const variants = {
//     visible: { height: "max-content" },
//     hidden: { height: 0 }
// };

const ICONS_TYPE_MATCH = {
    work: PlaceWorkIcon,
    home: PlaceHomeIcon,
    general: PlaceGeneralIcon
};

const INPUT_ACTIONS_EDITABLE_MODE = ["edit"];
const INPUT_ACTIONS_DEAFULT = ["edit", "copy", "remove"];

export const EditPlace = ({ place, formik, index }) => {
    const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false);
    const [shouldFocus, setShouldFocus] = useState<boolean>(false);
    const { city, state, zip_code, country, street_address } = place.address;
    const fullAddress = `${street_address} ${city} ${state} ${zip_code} ${country}`;
    const { added } = place;
    const ref = useRef();

    const outsideClick = () => {
        setEditModeEnabled(false);
        if (
            !Object.keys(place.value).some((key) => {
                return place.value[key].length;
            })
        ) {
            const { places } = formik.values;
            const updated = [...places];
            updated.splice(index, 1);
            if (updated.length === 0) {
                formik.setFieldValue("places", null);
            } else {
                formik.setFieldValue("places", updated);
            }
        }
    };

    useOutsideAlerter(ref, outsideClick);

    useEffect(() => {
        if (added) {
            setEditModeEnabled(true);
            setShouldFocus(true);
            const { places } = formik.values;
            formik.setFieldValue("places", [
                ...places?.slice(0, index),
                { ...places[index], added: false },
                ...places?.slice(index + 1)
            ]);
        }
    }, [added]);

    const onChangePlaceValues = (valueName, e) => {
        const { value } = e.target;
        formik.setFieldValue(`places[${index}].address[${valueName}]`, value);
    };

    const onRemoveClick = (valueName) => {
        const { places } = formik.values;
        const updatedArray = [...places?.slice(0, index), ...places.slice(index + 1)];
        formik.setFieldValue(`places`, updatedArray.length === 0 ? null : updatedArray);
    };

    const onFocus = () => {
        setEditModeEnabled(true);
    };

    return (
        <div ref={ref}>
            <Input
                autoFocus={shouldFocus}
                type={InputTypes.edit}
                value={editModeEnabled ? street_address : fullAddress}
                placeholder={editModeEnabled ? "Street address" : undefined}
                actions={editModeEnabled ? INPUT_ACTIONS_EDITABLE_MODE : INPUT_ACTIONS_DEAFULT}
                onEditClick={() => {
                    setEditModeEnabled(true);
                }}
                onRemoveClick={onRemoveClick}
                outerLeftIcon={
                    <div className={styles["edit-place-icon"]}>
                        <MPDIcon IconComponent={ICONS_TYPE_MATCH[place.type]} />
                    </div>
                }
                textAreaProps={{ rows: 2 }}
                onFocus={onFocus}
                onChange={(e) => onChangePlaceValues("street_address", e)}
            />
            <Collapse isOpen={editModeEnabled}>
                <div className={styles["address-details"]}>
                    <Input
                        type={InputTypes.edit}
                        placeholder={"city"}
                        value={city}
                        onChange={(e) => onChangePlaceValues("city", e)}
                        actions={INPUT_ACTIONS_EDITABLE_MODE}
                    />
                    <Input
                        type={InputTypes.edit}
                        placeholder={"state/province"}
                        value={state}
                        onChange={(e) => onChangePlaceValues("state", e)}
                        actions={INPUT_ACTIONS_EDITABLE_MODE}
                    />
                    <Input
                        type={InputTypes.edit}
                        placeholder={"ZIP/POSTAL"}
                        value={zip_code}
                        onChange={(e) => onChangePlaceValues("zip_code", e)}
                        actions={INPUT_ACTIONS_EDITABLE_MODE}
                    />
                    <Input
                        type={InputTypes.edit}
                        placeholder={"country"}
                        value={country}
                        onChange={(e) => onChangePlaceValues("country", e)}
                        actions={INPUT_ACTIONS_EDITABLE_MODE}
                    />
                </div>
            </Collapse>
            {/* <AnimatePresence>
                <motion.div
                    className={styles["address-details"]}
                    variants={variants}
                    animate={editModeEnabled ? "visible" : "hidden"}
                >
                    <Input type={InputTypes.edit} placeholder={"city"} value={city} actions />
                    <Input type={InputTypes.edit} placeholder={"state/province"} value={state} actions />
                    <Input type={InputTypes.edit} placeholder={"ZIP/POSTAL"} value={zip} actions />
                    <Input type={InputTypes.edit} placeholder={"country"} value={country} actions />
                </motion.div>
            </AnimatePresence> */}
        </div>
    );
};
