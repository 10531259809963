import { Store } from "redux";
import { AppState } from "src/store";
import { IToastType, ToastTypes } from "src/components";
import { updateToast, updateGlobalSpinnerStatus } from "src/actions/global/global-api";
import { UPDATE_ALERT_COMPOSE_DATA_ERRORS } from "src/actions";

export const alertComposeDataErrorHandler = (store: Store<AppState>, resErrors?: any) => {
    const {
        alerts: {
            composeData: { composeDataErrors }
        }
    } = store.getState();

    const newErrors = { ...composeDataErrors };

    Object.keys(resErrors).forEach((errorKey) => {
        if (composeDataErrors && composeDataErrors.messageDetails) {
            Object.keys(composeDataErrors.messageDetails).forEach((messageDetailsKey) => {
                if (errorKey === messageDetailsKey) {
                    newErrors.messageDetails[messageDetailsKey] = resErrors[errorKey][0];
                }
            });
        }
    });
    store.dispatch({
        type: UPDATE_ALERT_COMPOSE_DATA_ERRORS,
        payload: newErrors
    });
    const toast: IToastType = {
        value: "Validation error. Please check the form",
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));
    store.dispatch(updateGlobalSpinnerStatus(false));

    return;
};
