// tslint:disable jsx-no-lambda
// import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import { MPDButton, MPDScroll, MPDAnimations } from "src/mpd-library";

import { LeftPanelContainer, ILeftPanelContainerProps } from "src/components";

import "./styles.scss";
import { ReactComponent as BreadCrumbsIcon } from "../../mpd-library/icon/assets/bread-crumbs.svg"

export interface ISettingsLeftPanelSettingsProps extends IProps, ILeftPanelContainerProps {
    onToggleMobileSideBar?: (event: React.SyntheticEvent) => void;
    mobileSideBarActive?: boolean;
    rightPanelExpanded: boolean;
    title?: string;
}

export const SettingsLeftPanel: React.FunctionComponent<ISettingsLeftPanelSettingsProps> = (props) => {
    const {
        children,
        mobileSideBarActive,
        onToggleMobileSideBar,
        isMobileSize,
        rightPanelExpanded,
        title,
        isIn,
        enter,
        exit
    } = props;

    const classes = "settings-left-panel";

    return (
        <LeftPanelContainer
            className={classes}
            collapsed={rightPanelExpanded}
            mobileSideBarActive={mobileSideBarActive}
            overlayVisible={mobileSideBarActive}
            onOverlayClick={onToggleMobileSideBar}
            appear={true}
            isIn={isIn}
            enter={enter}
            exit={exit}
            timeout={150}
            animationType={isIn ? MPDAnimations.swipeRight : MPDAnimations.swipeLeft}
            isMobileSize={isMobileSize}
        >
            <div className={classes + "-header"}>
                <MPDButton IconComponent={BreadCrumbsIcon} onClick={onToggleMobileSideBar} />
                <Text className={classes + "-title"}> {title} </Text>
            </div>
            <MPDScroll className="settings-left-panel-scroll-container" yDirection={true}>
                {children}
            </MPDScroll>
        </LeftPanelContainer>
    );
};

export default SettingsLeftPanel;
