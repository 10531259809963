// tslint:disable
import classNames from "classnames";
import React from "react";

import { MPDNavBar, MPDNavBarGroup, MPDNavBarItem } from "src/mpd-library";

import { IStyledNavBarItemType, StyledNavbarTypes } from "./types";

import { IProps, Position } from "@blueprintjs/core";
import { History } from "history";

import "./styles.scss";

interface IStyledNavBarProps extends IProps {
    history: History;
    items: Array<Array<IStyledNavBarItemType>>;
    isMobileSize?: boolean;
    mobileActive?: boolean;
    scrollGroup?: number;
    type: StyledNavbarTypes;
}

export const StyledNavBar: React.FunctionComponent<IStyledNavBarProps> = (props) => {
    const { className, history, isMobileSize, mobileActive, scrollGroup, type, items } = props;
    const classes = "styled-navbar";

    return (
        <MPDNavBar className={classNames(classes, mobileActive && "mpd-active", type, className)} history={history}>
            {items &&
                items.map((navBarGroup: IStyledNavBarItemType[], groupIndex: number) => (
                    <MPDNavBarGroup
                        className={classNames(classes + "-navbar-group", type)}
                        yDirection={scrollGroup === groupIndex}
                        key={groupIndex}
                    >
                        {navBarGroup &&
                            navBarGroup.map((navBarItem: any, index: number) => {
                                return (
                                    <MPDNavBarItem
                                        activePathname={history.location.pathname}
                                        className={classNames(classes + "-navbar-item", type, navBarItem.className)}
                                        key={index}
                                        disableRouting={navBarItem.disableRouting}
                                        pathname={navBarItem.pathname}
                                        notSelectable={
                                            navBarItem.notSelectable !== undefined ? navBarItem.notSelectable : false
                                        }
                                        navItemElement={navBarItem.element}
                                        popOverProps={navBarItem.popOverProps}
                                        onItemClick={navBarItem.onItemClick}
                                        toolTipProps={
                                            !isMobileSize
                                                ? {
                                                      //   className: navBarItem.toolTipClassName,
                                                      popoverClassName: "sidebar-item-tooltip",
                                                      content: navBarItem.toolTipText,
                                                      position: Position.LEFT,
                                                      transitionDuration: 0
                                                  }
                                                : undefined
                                        }
                                    />
                                );
                            })}
                    </MPDNavBarGroup>
                ))}
        </MPDNavBar>
    );
};
