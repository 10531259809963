import { combineReducers } from "redux";
import {
    accountSettingsReducer,
    alertsReducer,
    analyticsReducer,
    authReducer,
    globalReducer,
    incidentManagementReducer,
    onBoardingReducer,
    userSettingsReducer,
    userOnBoardingReducer,
    studioReducer,
    collectionReducer,
    cardReducer,
    websocketReducer,
    mapReducer,
    conversationsReducer,
    profileAutomationsReducer,
    notificationsReducer,
    conversationMessagesReducer
} from "../reducers";
import { AccountSettingsState } from "./account-settings";
import { AlertsState } from "./alerts";
import { AnalyticsState } from "./analytics";
import { AuthState } from "./auth";
import { GlobalState } from "./global";
import { IncidentManagementState } from "./incident-management";
import { OnBoardingState } from "./on-boarding";
import { UserSettingsState } from "./user-settings";
import { UserOnBoardingState } from "./user-onboarding";
import { StudioState } from "./studio";
import { CollectionState } from "src/store/collection";
import { CardState } from "src/store/card";
import { WebSocketState } from "src/store/websocket";
import { MapState } from "src/store/map";
import { ConversationsState } from "./conversations";
import { ProfileAutomationsSettingsState } from './profile-automations'; 
import { NotificationsState } from "./notifications";
import { ConversationMessagesState } from "./conversationMessages";

export interface AppState {
    accountSettings: AccountSettingsState;
    alerts: AlertsState;
    analytics: AnalyticsState;
    auth: AuthState;
    global: GlobalState;
    incidentManagement: IncidentManagementState;
    onBoarding: OnBoardingState;
    userSettings: UserSettingsState;
    userOnBoarding: UserOnBoardingState;
    studio: StudioState;
    collection: CollectionState;
    card: CardState;
    websocketReducer: WebSocketState;
    mapState: MapState;
    conversations: ConversationsState;
    conversationMessages: ConversationMessagesState;
    profileAutomations: ProfileAutomationsSettingsState;
    notifications: NotificationsState;
}

export const rootReducer = combineReducers<AppState>({
    accountSettings: accountSettingsReducer,
    alerts: alertsReducer,
    analytics: analyticsReducer,
    auth: authReducer,
    global: globalReducer,
    incidentManagement: incidentManagementReducer,
    onBoarding: onBoardingReducer,
    userSettings: userSettingsReducer,
    userOnBoarding: userOnBoardingReducer,
    studio: studioReducer,
    collection: collectionReducer,
    card: cardReducer,
    websocketReducer,
    mapState: mapReducer,
    conversations: conversationsReducer,
    profileAutomations: profileAutomationsReducer,
    notifications: notificationsReducer,
    conversationMessages: conversationMessagesReducer
});