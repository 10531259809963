import { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { Text } from "@blueprintjs/core";
import { EmptyBlock } from "src/components";
import { DropdownContent } from "src/containers/conversations/components/dropdown-content/dropdown-content";
import { UserDropdownItem } from "../../../user-dropdown-item/user-dropdown-item";
import styles from "./styles.module.scss";
import { GetAdminsFilterValuesEnum } from "src/actions/conversations/conversations-api";
import { MPDSelectItem, MPDIcon } from "src/mpd-library";
import { InfiniteScroll } from "src/components/infinite-scroll/infinite-scroll";
import { useAdmins } from "../../../../hooks/useAdmins";
import { DropdownSearchHeader } from "src/components/dropdown-search-header/dropdown-search-header";
import { EmptyBlockTypes } from "../../../../../../../../components";
import { ReactComponent as DropdownSearchNoResultsIcon } from "src/mpd-library/icon/assets/dropdownSearchNoResults.svg";
import { ReactComponent as TwoPeopleIcon } from "src/mpd-library/icon/assets/two-people.svg";

const classname = "assign-dropdown";

type AssignDropdownProps = {
    showAllAdmins?: boolean;
    selectedAdmin: any;
    notShowTitles?: boolean;
    adminsFilter: GetAdminsFilterValuesEnum | null;
    title: string;
    onAdminItemClick: (admin: any) => void;
};

export const AssignDropdown = ({
    showAllAdmins = false,
    selectedAdmin,
    notShowTitles = false,
    adminsFilter,
    title,
    onAdminItemClick
}: AssignDropdownProps) => {
    const [adminsSearchValue, setSearchValue] = useState("");
    const [assignableAdminId, setAssignableAdminId] = useState<string | null>(null);

    const {
        admins: adminsList,
        adminsLoads: loadingAdmins,
        hasMore: hasMoreAdmins,
        onGetAdmins
    } = useAdmins({
        limit: 10,
        withInvited: false,
        searchValue: adminsSearchValue,
        filter: adminsFilter
    });

    const debounced = useRef(
        debounce((query) => {
            onGetAdmins(false, query);
        })
    );

    useEffect(() => {
        debounced.current(adminsSearchValue);
    }, [adminsSearchValue]);

    const onSearchChangeHandler = (event: any) => {
        setSearchValue(event.target.value);
    };

    const onAdminClick = async (admin) => {
        try {
            setAssignableAdminId(admin.id);
            await onAdminItemClick(admin);
        } finally {
            setAssignableAdminId(null);
        }
    };

    const onAllUsersClick = () => {
        onAdminItemClick({ value: "All Users", title: "All Users" });
    };

    return (
        <DropdownContent
            header={
                <DropdownSearchHeader
                    title={title}
                    onSearchChangeHandler={onSearchChangeHandler}
                    searchValue={adminsSearchValue}
                    // onClose={}
                />
            }
            content={
                <div className={styles[`${classname}-main-wrapper`]}>
                    <InfiniteScroll
                        loading={loadingAdmins}
                        onLoadMore={() => onGetAdmins(true, adminsSearchValue)}
                        hasMore={hasMoreAdmins}
                        listLength={adminsList.length}
                    >
                        {selectedAdmin && !notShowTitles && (
                            <div className={styles[`${classname}-content-wrapper`]}>
                                <Text className={styles[`${classname}-subtitle`]}>CURRENT OWNER</Text>
                                <UserDropdownItem className={"classname"} mention={selectedAdmin} selected />
                            </div>
                        )}
                        <div className={styles[`${classname}-content-wrapper`]}>
                            {!notShowTitles && (
                                <Text className={styles[`${classname}-subtitle`]}>ASSIGN NEW OWNER</Text>
                            )}
                            {!adminsList.length && !!adminsSearchValue && (
                                <EmptyBlock
                                    title={`No results for ‘${adminsSearchValue}’`}
                                    IconComponent={DropdownSearchNoResultsIcon}
                                    type={EmptyBlockTypes.dropdown}
                                />
                            )}
                            {showAllAdmins && !adminsSearchValue && (
                                <MPDSelectItem
                                    className={styles[`${classname}-all-users-item`]}
                                    onClick={onAllUsersClick}
                                    selected={!selectedAdmin}
                                >
                                    <>
                                        <div className={styles[`${classname}-two-people-icon`]}>
                                            <MPDIcon IconComponent={TwoPeopleIcon} />
                                        </div>
                                        <Text className={styles[`${classname}-all-user-label`]}>All Users</Text>
                                    </>
                                </MPDSelectItem>
                            )}
                            {adminsList.map((admin) => {
                                return (
                                    <UserDropdownItem
                                        className={"classname"}
                                        mention={admin}
                                        key={admin.id}
                                        onClick={() => onAdminClick(admin)}
                                        assignableAdminId={assignableAdminId}
                                        selected={admin.id === selectedAdmin?.id}
                                    />
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            }
        />
    );
};
