import moment from "moment";
import { IMPDTreeNode } from "../../mpd-library";
import { formattedTextTypes, dateTypes } from "./types";

export function abrNum(num: any, decPlaces: number) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate num abbreviations
    const abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3);

        // If the num is bigger or equal do the abbreviation
        if (size <= num) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            num = Math.round((num * decPlaces) / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if (num === 1000 && i < abbrev.length - 1) {
                num = 1;
                i++;
            }

            // Add the letter for the abbreviation
            num += abbrev[i];

            // We are done... stop
            break;
        }
    }

    return num;
}

export function sortList(list: Array<any>, asc?: boolean, obj?: string) {
    if (list && obj === undefined) {
        list.sort(function compare(a, b) {
            const dateA = new Date(parseInt(a.date, 10) * 1000) as any;
            const dateB = new Date(parseInt(b.date, 10) * 1000) as any;
            if (asc) {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
    } else if (list && obj !== undefined) {
        list.sort(function compare(a, b) {
            const dateA = new Date(parseInt(a[obj], 10) * 1000) as any;
            const dateB = new Date(parseInt(b[obj], 10) * 1000) as any;
            if (asc) {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
    }
    return list;
}

export function forEachNode(nodes: Array<IMPDTreeNode>, callback: (node: IMPDTreeNode) => void) {
    if (nodes === null) {
        return;
    }
    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes || [], callback);
    }
}

export function formatDateTime(metric: number, filler: string, str: string) {
    return metric > 0 ? str + metric + " " + filler : str;
}

export function validateEmail(email?: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function validURL(url?: string) {
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return regex.test(String(url).toLowerCase());
}

export function validateTwitter(user?: string) {
    const regex = /@([A-Za-z0-9_]{1,15})/;
    return regex.test(String(user).toLowerCase());
}

export function getCreatedDate(date: Date | string): string {
    const today = moment(new Date()).format("MM/DD/YYYY");
    const day = moment(date).format("MM/DD/YYYY");
    const time = moment(date).format("hh:mma zz");
    return `${day === today ? "Today" : day} at ${time}`;
}

export function doesStringExistInArr(text: string, array: Array<any>, key?: string) {
    let index = -1;
    for (let i = 0; i < array.length; i++) {
        let topItem = array[i];
        if (key !== undefined) {
            topItem = topItem[key];
        }
        topItem = topItem.toLowerCase();
        if (text.includes(topItem)) {
            index = i;
            break;
        }
    }
    return index;
}

export function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (userAgent.match(/Android/i)) {
        return "Android";
    }

    if (userAgent.match(/iPhone|iPad|iPod/i)) {
        return "iOS";
    }
    return "unknown";
}

export function createHalfHourIntervals(format: string) {
    const startDate = moment(new Date()).startOf("day");
    const dates: Array<any> = [];
    const hourDivider = 2;
    let t = hourDivider * 24;
    while (t--) {
        dates.push(startDate.format(format));
        startDate.add(60 / hourDivider, "minute");
    }
    return dates;
}

export function convertToDate(date: string, type: dateTypes, formatType?: formattedTextTypes) {
    switch (formatType) {
        case formattedTextTypes.short:
            return shortUTCConversion(date, type);
        case formattedTextTypes.detailed:
            return detailedUTCConversion(date, type);
        default:
            return defaultConversion(date, type);
    }
}

function defaultConversion(dateString: string, type: dateTypes) {
    if (type === dateTypes.unix) {
        return moment.unix(parseInt(dateString, 10)).calendar();
    } else {
        return moment(dateString).format("MM/DD/YY");
    }
}

function shortUTCConversion(dateString: string, type: dateTypes) {
    const now = new Date();

    let dateObj: any;

    if (type === dateTypes.unix) {
        dateObj = moment.unix(parseInt(dateString, 10));
    } else if (type === dateTypes.dateTime) {
        dateObj = moment(dateString);
    }

    const dateStr = dateObj.toDate();

    const diffMs = now.getTime() - dateStr.getTime();

    return dateObj.calendar(undefined, {
        sameDay: () => {
            const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
            const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
            if (diffHrs > 0) {
                if (diffHrs === 1) {
                    return "[1 hour ago]";
                } else {
                    return diffHrs + "[ hours ago]";
                }
            } else if (diffMins > 0) {
                if (diffMins === 1) {
                    return "[1 min ago]";
                } else {
                    return diffMins + "[ mins ago]";
                }
            }
            return "[Moments ago]";
        },
        nextDay: "[Tomorrow]",
        nextWeek: "[Next week]",
        lastDay: "[1 day ago]",
        lastWeek: () => {
            const diffWeeks = Math.floor(diffMs / 604800000); // weeks
            const diffDays = Math.floor(diffMs / 86400000); // days
            if (diffWeeks > 0) {
                if (diffWeeks === 1) {
                    return "[1 week ago]";
                } else {
                    return diffWeeks + "[ weeks ago]";
                }
            } else {
                return diffDays + "[ days ago]";
            }
        },
        sameElse: () => {
            const diffMonths = Math.floor(diffMs / 2628000000); // months
            const diffWeeks = Math.floor(diffMs / 604800000); // weeks
            const diffDays = Math.floor(diffMs / 86400000); // days
            if (diffMonths > 0) {
                if (diffMonths === 1) {
                    return "[1 month ago]";
                } else {
                    return diffMonths + "[ months ago]";
                }
            } else if (diffWeeks > 0) {
                if (diffWeeks === 1) {
                    return "[1 week ago]";
                } else {
                    return diffWeeks + "[ weeks ago]";
                }
            } else if (diffDays > 0) {
                return diffDays + "[ days ago]";
            }
            return "MM/DD/YYYY";
        }
    });
}

function detailedUTCConversion(dateString: string, type: dateTypes) {
    let dateObj: any;

    if (type === dateTypes.unix) {
        dateObj = moment.unix(parseInt(dateString, 10));
    } else if (type === dateTypes.dateTime) {
        dateObj = moment(dateString);
    }
    return dateObj.calendar(undefined, {
        sameDay: () => {
            return "[Today] hh:mm a";
        },
        nextDay: "[Tomorrow at ] hh:mm a",
        nextWeek: "[Next] dddd [at] hh:mm a",
        lastDay: "[Yesterday at ] hh:mm a",
        lastWeek: "[Last] dddd [at] hh:mm a",
        sameElse: "MM/DD/YYYY [at] hh:mm a"
    });
}

export function convertUTCToFormattedText(dateString: string, type?: dateTypes) {
    let dateObj: any;

    dateObj = moment.unix(parseInt(dateString, 10));

    if (type === dateTypes.dateTime) {
        dateObj = moment(dateString);
    }
    const myDate = dateObj.format("MM/DD/YYYY");
    const myTime = dateObj.format("hh:mmA");
    return myDate + " at " + myTime;
}

export const formatRecievedServerDataToGeoJson = (coordinates: any, type: string) => {
    const geoJson = {
        type: "Feature",
        properties: {},
        geometry: {
            type,
            coordinates
        }
    };
    return geoJson;
};

export const formatPhoneNumber = (str: string) => {
    const cleaned = ("" + str).replace(/\D/g, "");
    const match = cleaned.match(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);
    if (match) {
        const intlCode = match[1] ? `+${match[1]}` : "";
        return `${intlCode} (${match[2]}) ${match[3]}-${match[4]}`;
        // return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    } else {
        return str;
    }
};

export function phoneFormat(value: string) {
    let input = value;
    input = input.replace(/\D/g, "");
    input = input.substring(0, 10);
    const size = input.length;

    if (size === 0) {
        return input;
    }
    if (size < 4) {
        return "(" + input;
    }
    if (size < 7) {
        return "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    }

    return "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + " - " + input.substring(6, 10);
}

export function reversePhoneFormat(input: string) {
    input = input.replace("-", "");
    input = input.replace(" ", "");
    input = input.replace("(", "");
    input = input.replace(")", "");
    return input;
}

export const hashCode = (s) => {
    return s.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
    }, 0);
};
