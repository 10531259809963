import classNames from "classnames";
import React from "react";
import * as myClasses from "../";

import { IProps, Text } from "@blueprintjs/core";

import { StepperNode } from "../";

import { MPDButton } from "src/mpd-library";

import "./styles.scss";
import { ReactComponent as RightBlueIcon } from "../../mpd-library/icon/assets/right-blue.svg";

export interface IStepperProps extends IProps {
    data: Array<StepperNode>;
    loading: boolean;
    type?: string;
    onItemClick?(index: number): void;
    isMobileSize?: boolean;
    availabaleSteps?: Array<number>;
    activeStepIndex: number;
    stepsWithErrors: { [key: number]: boolean };
}

export class Stepper extends React.Component<IStepperProps, {}> {
    public render() {
        const { className, type, data, isMobileSize, availabaleSteps, activeStepIndex, steps, stepsWithErrors } =
            this.props;
        const classes = "stepper";
        const attributes = isMobileSize ? { IconComponent: "" } : { IconComponent: RightBlueIcon };
        return (
            <div className={classNames(classes, type, className)}>
                {!steps &&
                    data &&
                    data.map((element: StepperNode, index: number) => {
                        let disabled = false;
                        if (availabaleSteps?.length) {
                            disabled = !availabaleSteps.includes(index);
                        }
                        return (
                            <div
                                className={`${classNames(
                                    "step-container",
                                    myClasses.stepperStatesClass(element.state),
                                    activeStepIndex > index && myClasses.stepperStatesClass("finished"),
                                    activeStepIndex === index && myClasses.stepperStatesClass("active"),
                                    element.error && "step-with-validation-error",
                                    index === 0 && "first-item",
                                    index === data.length - 1 && "last-item"
                                )}  ${disabled && "disabled"}`}
                                key={index}
                            >
                                <div
                                    className={classes + "-step"}
                                    onClick={(e) => this.onClick(e, disabled)}
                                    data-id={index}
                                >
                                    {element.state === "finished" ? (
                                        <MPDButton
                                            className={`${classes}-step-number ${isMobileSize ? "mobile" : ""}`}
                                            {...attributes}
                                        />
                                    ) : (
                                        <MPDButton
                                            className={`${classes}-step-number ${isMobileSize ? "mobile" : ""}`}
                                            text={isMobileSize && element.state !== "active" ? "" : element.num}
                                            disabled={disabled}
                                        />
                                    )}
                                    <Text className={classes + "-step-text"}>{isMobileSize ? "" : element.title}</Text>
                                </div>
                            </div>
                        );
                    })}

                {!data &&
                    steps &&
                    steps.map((step, index) => {
                        let IconComponent;
                        if (activeStepIndex > index) {
                            IconComponent = RightBlueIcon;
                        }
                        return (
                            <div
                                className={`${classNames(
                                    "step-container",
                                    className,
                                    activeStepIndex > index && myClasses.stepperStatesClass("finished"),
                                    activeStepIndex === index && myClasses.stepperStatesClass("active"),
                                    stepsWithErrors[index] && "step-with-validation-error"
                                )}`}
                                key={index}
                            >
                                <div className={classes + "-step"} onClick={(e) => this.onClick(e)} data-id={index}>
                                    <MPDButton
                                        className={`${classes}-step-number`}
                                        // text={isMobileSize && element.state !== "active" ? "" : element.num}
                                        // disabled={disabled}
                                        IconComponent={IconComponent}
                                        text={stepsWithErrors[index] && "!"}
                                    />
                                    <Text className={classes + "-step-text"}>{isMobileSize ? "" : step.stepLabel}</Text>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }

    onClick = (event: any, disabled: boolean) => {
        if (!disabled) {
            const { onItemClick } = this.props;
            const index = parseInt(event.currentTarget.dataset.id, 10);
            if (onItemClick) {
                onItemClick(index);
            }
        }
    };
}
