import { AppState, AlertStateAlertData, AccountSettingsUserState } from "src/store";
import { ListsIdTypes } from "src/containers";
import { ShapeCoords, AccountRole } from "src/requests";

export const getAlertById = (
    appState: AppState,
    alertId: string,
    alertListName?: ListsIdTypes
): AlertStateAlertData | null => {
    if (!alertId) {
        return null;
    }

    const lists = appState.alerts.alert.lists.active;
    let alert: AlertStateAlertData | null = null;

    if (alertListName) {
        alert = lists[alertListName].find((listAlert: AlertStateAlertData) => listAlert.id === alertId) || null;
    }

    Object.keys(lists).forEach(listName => {
        if (alert === null) {
            alert = lists[listName].find((listAlert: AlertStateAlertData) => listAlert.id === alertId) || null;
        }
    });

    return alert;
};

export const getListItem = (list: Array<AlertStateAlertData>, id: string) => {
    const index = list.findIndex(element => (element && element.id.toString()) === id.toString());
    const selectedAlert = list[index];

    return selectedAlert;
};

export const getListItemIndex = (
    list: Array<AccountSettingsUserState> | Array<AlertStateAlertData> | Array<AccountRole>,
    id: string
) => {
    const index = list.findIndex(
        (element: AccountSettingsUserState | AlertStateAlertData | AccountRole) =>
            element.id.toString() === id.toString()
    );

    return index;
};

interface GetCenterPropsByAlert {
    type: "ByAlert";
    alert: AlertStateAlertData;
}
interface GetCenterPropsByAlertId {
    type: "ByAlertId";
    alertId: string;
    alertListName?: ListsIdTypes;
}
export const getCenterForAlertMap = (
    appState: AppState,
    props: GetCenterPropsByAlert | GetCenterPropsByAlertId
): ShapeCoords | null => {
    const alert: AlertStateAlertData | null =
        props.type === "ByAlert" ? props.alert : getAlertById(appState, props.alertId, props.alertListName);

    if (!alert) {
        return null;
    }

    if (alert.center) {
        return alert.center;
    }

    if (alert.location?.coordinates) {
        return {
            lat: alert.location.coordinates[0],
            lng: alert.location.coordinates[1]
        };
    }

    if (alert.owner?.location?.coordinates) {
        return {
            lat: alert.owner.location.coordinates[0],
            lng: alert.owner.location.coordinates[1]
        };
    }

    return null;
};
