import {
    GET_CONVERSATIONS,
    SET_SELECTED_CONVERSATION_ID,
    UPDATE_GET_CONVVERSATIONS_TAGS_STATUS,
    SET_DEFAULT_STATE,
    SET_VIEW_TYPE,
    SET_ADMINS,
    SET_SELECTED_QUEUE,
    ConversationsActionTypes,
    SET_CONVERSATIONS_COUNT,
    ACCEPT_FILTER_VALUES,
    SET_CONVERSATIONS_VIEWS,
    SET_SELECTED_VIEW_DATA,
    UPDATE_CONVERSATIONS_LOADING_STATUS,
    SET_HAS_MORE_CONVERSATIONS,
    UPDATE_ACCOUNT_SETTINGS_QUEUE,
    DELETE_SAVED_VIEW,
    SET_ACCESS_ERROR_TO_CONVERSATION,
    SET_CONVERSATIONS_COUNTER,
    SET_PREVIOUS_CONVERSATIONS_WITH_SENDER,
    SET_HAS_MORE_CONVERSATIONS_WITH_SENDER,
    SET_BASED_ON_PERMISSIONS_ADMINS,
    SET_TO_ZERO_CONVERSATION_UNREAD_COUNT,
    UPDATE_CONVERSATION,
    REMOVE_CONVERSATION_FROM_LIST,
    UPDATE_CONVERSATIONS_IDS_LIST,
    UDPATE_CONVERSATIONS_USER
} from "./types";
import {
    Conversation,
    ConversationQueue,
    ViewEnum,
    ConversationsCounts,
    ConversationsFilterValues,
    AppState,
    ViewData,
    GlobalViewEnum
} from "src/store";
import { getFiltersCounter } from "./utils";
import { Dispatch } from "react";
import { SET_GLOBAL_VIEW } from ".";

export type AcceptFilterValuesTypes = {
    filters: ConversationsFilterValues;
    id: string | null;
    name: string | null;
    shouldBeEdited?: boolean;
};

export const acceptFilterValues = (savedView: AcceptFilterValuesTypes, isFilterApplyed: boolean) => {
    const { shouldBeEdited } = savedView;
    const filterValues = { ...savedView.filters };
    const counter = getFiltersCounter(filterValues);

    if (Array.isArray(filterValues.tag_ids)) {
        filterValues.tag_ids = filterValues.tag_ids.reduce((accumulator, tagId: string) => {
            return { ...accumulator, [tagId]: true };
        }, {});
    }

    if (Array.isArray(filterValues.queue_ids)) {
        filterValues.queue_ids = filterValues.queue_ids.reduce(
            (accumulator, queueId: string) => ({ ...accumulator, [queueId]: true }),
            {}
        );
    }

    if (Array.isArray(filterValues.admin_ids)) {
        filterValues.admin_ids = filterValues.admin_ids.reduce(
            (accumulator, admin_id: string) => ({ ...accumulator, [admin_id]: true }),
            {}
        );
    }

    if (Array.isArray(filterValues.user_ids)) {
        filterValues.user_ids = filterValues.user_ids.reduce(
            (accumulator, user_id: string) => ({ ...accumulator, [user_id]: true }),
            {}
        );
    }
    
    return {
        type: ACCEPT_FILTER_VALUES,
        payload: {
            isFilterApplyed,
            filterValues,
            filterCounter: counter,
            viewId: savedView.id,
            selectedFilterName: savedView.name,
            shouldBeEdited
        }
    };
};

export const updateConversationsLoadingStatus = (status: boolean) => {
    return { type: UPDATE_CONVERSATIONS_LOADING_STATUS, payload: status };
};

export const updateGetConversationsTagsStatus = (value: boolean): ConversationsActionTypes => ({
    type: UPDATE_GET_CONVVERSATIONS_TAGS_STATUS,
    payload: value
});

export const updateConversation = (conversation: Conversation): ConversationsActionTypes => {
    return {
        type: UPDATE_CONVERSATION,
        payload: conversation
    };
};

export const setViewType = (value: ViewEnum): ConversationsActionTypes => {
    return {
        type: SET_VIEW_TYPE,
        payload: value
    };
};

const convertToMentionData = (userList: any): Array<any> => {
    return userList.map((user) => {
        return {
            id: user.uuid || user.id,
            name: user.name || `${user.first_name} ${user.last_name}`,
            first_name: user.first_name,
            last_name: user.last_name,
            link: user.email,
            avatar: user.profile_pic?.url || user.avatar,
            status: user.status
        };
    });
};

export const setAdmins = (users: any, hasMoreAdmins: boolean): ConversationsActionTypes => {
    return {
        type: SET_ADMINS,
        adminsList: convertToMentionData(users),
        hasMoreAdmins
    };
};

export const setSelectedQueueId = (queueId: string) => {
    return {
        type: SET_SELECTED_QUEUE,
        payload: queueId
    };
};

export const setConversationsCounts = (counts: Partial<ConversationsCounts>): ConversationsActionTypes => {
    return {
        type: SET_CONVERSATIONS_COUNT,
        payload: counts
    };
};

export const setHasMoreConversations = (status: boolean): ConversationsActionTypes => {
    return {
        type: SET_HAS_MORE_CONVERSATIONS,
        payload: status
    };
};

export const setDefaultState = () => (dispatch: Dispatch<any>, getState: () => AppState) => {
    const { privileges } = getState().userSettings;
    const viewType = privileges.some(
        (privilege) =>
            privilege.feature_hrid === "conversations_access_all" ||
            privilege.feature_hrid === "conversations_access_enqueued"
    )
        ? "opened"
        : "assigned";
    dispatch({
        type: SET_DEFAULT_STATE,
        viewType
    });
};

export const setViews = (views) => {
    return {
        type: SET_CONVERSATIONS_VIEWS,
        payload: views
    };
};

export const updateAccountSettingsQueue = (queue: ConversationQueue) => {
    return {
        type: UPDATE_ACCOUNT_SETTINGS_QUEUE,
        payload: queue
    };
};

export const setConversations = (
    conversations: any,
    isSearching?: boolean,
    refresh?: boolean,
    conversationsGroupName?: string
): ConversationsActionTypes => {
    return {
        type: GET_CONVERSATIONS,
        isSearching,
        refresh,
        conversationsGroupName,
        payload: conversations
    };
};

export const setSelectedViewData = (viewData: ViewData | null) => {
    return {
        type: SET_SELECTED_VIEW_DATA,
        payload: viewData
    };
};

export const setSelectedConversationId = (id: string): ConversationsActionTypes => {
    return {
        type: SET_SELECTED_CONVERSATION_ID,
        payload: id
    };
};

export const updateTagInList = (tag) => {
    return {
        type: UPDATE_TAG,
        payload: tag
    };
};

export const deleteSavedView = (viewId) => {
    return {
        type: DELETE_SAVED_VIEW,
        payload: viewId
    };
};

export const setAccessErrorToConversation = (conversationId: string) => {
    return {
        type: SET_ACCESS_ERROR_TO_CONVERSATION,
        payload: conversationId
    };
};

export const setConversationsCounter = (value: number) => {
    return {
        type: SET_CONVERSATIONS_COUNTER,
        payload: value
    };
};

export const setPreviousConversationsWithSender = (conversations: Array<Conversation>) => {
    return {
        type: SET_PREVIOUS_CONVERSATIONS_WITH_SENDER,
        payload: conversations
    };
};

export const setHasMorePreviousConversations = (hasMore: boolean) => {
    return {
        type: SET_HAS_MORE_CONVERSATIONS_WITH_SENDER,
        payload: hasMore
    };
};

export const setBasedOnPermissionsAdmins = (data) => {
    return {
        type: SET_BASED_ON_PERMISSIONS_ADMINS,
        payload: data
    };
};

export const setGlobalView = (value: GlobalViewEnum) => {
    return {
        type: SET_GLOBAL_VIEW,
        payload: value
    };
};

export const resetToZeroConversationUnreadCount = (conversationId: string) => {
    return {
        type: SET_TO_ZERO_CONVERSATION_UNREAD_COUNT,
        payload: {
            conversationId
        }
    };
};

export const removeConversationFromList = (conversationId: string, viewType?: ViewEnum) => {
    return {
        type: REMOVE_CONVERSATION_FROM_LIST,
        payload: {
            conversationId,
            viewType
        }
    };
};

export const updateConversationsIdsList = (conversationsIds: Array<string>, viewType?: ViewEnum) => {
    return {
        type: UPDATE_CONVERSATIONS_IDS_LIST,
        payload: {
            conversationsIds,
            viewType
        }
    };
};

export const updateConversationsUser = (user: any) => {
    return {
        type: UDPATE_CONVERSATIONS_USER,
        payload: user
    };
};
