// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { useCallback, FunctionComponent, SVGProps } from "react";

import { IProps } from "@blueprintjs/core";

import { useDropzone } from "react-dropzone";

import { MPDIcon } from "src/mpd-library";

import { ImageUploadTypes } from "./types";

import "./styles.scss";

export interface IImageUploadProps extends IProps {
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    imageName?: string;
    fileTypes?: string | Array<string>;
    src?: string | ArrayBuffer | null;
    title?: string;
    type?: ImageUploadTypes;
    onUploadImage?: (event: any) => void;
}

export const ImageUpload = ({
    className,
    onUploadImage,
    title,
    src,
    IconComponent,
    imageName,
    type,
    fileTypes
}: IImageUploadProps) => {
    const classes = "image-upload";
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (onUploadImage) {
                onUploadImage(acceptedFiles);
            }
        },
        []
    );
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: fileTypes || "image/*"
    });

    switch (type) {
        case ImageUploadTypes.default:
            return (
                <div className={classNames(classes, type, className)}>
                    {src ? (
                        <div
                            className={classes + "-icon-image"}
                            style={{
                                backgroundImage: `url(${src})`,
                                // backgroundRepeat: "no-repeat",
                                backgroundSize: "cover"
                            }}
                        />
                    ) : (
                        <div className={classes + "-icon-filler"}>
                            {IconComponent && <MPDIcon IconComponent={IconComponent} />}
                        </div>
                    )}
                    {onUploadImage && (
                        <>
                            <input {...getInputProps()} />
                            <div
                                {...getRootProps({ className: `${classes + "-icon-filler-image"} dropzone` })}
                                onDrop={onUploadImage}
                            >
                                <span>{title}</span>
                            </div>
                        </>
                    )}
                </div>
            );
        case ImageUploadTypes.image:
            return (
                <div className={classNames(classes, type, className)}>
                    {onUploadImage && (
                        <>
                            <input {...getInputProps()} />
                            <div {...getRootProps({ className: `${classes + "-icon-filler-image"} dropzone` })}>
                                <MPDIcon IconComponent={IconComponent} />
                            </div>
                        </>
                    )}
                </div>
            );
        default:
            return (
                <div className={classNames(classes, type, className)}>
                    {src ? (
                        <div
                            className={classes + "-icon-image"}
                            style={{
                                backgroundImage: `url(${src})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover"
                            }}
                        />
                    ) : (
                        <div className={classes + "-icon-filler"}>
                            {IconComponent && <MPDIcon IconComponent={IconComponent} />}
                        </div>
                    )}
                    {onUploadImage && (
                        <>
                            <input {...getInputProps()} />
                            <div {...getRootProps({ className: `${classes + "-icon-filler-image"} dropzone` })}>
                                <span>{title}</span>
                            </div>
                        </>
                    )}
                </div>
            );
    }
};

export default ImageUpload;