// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Text } from "@blueprintjs/core";

import { IDetailView } from "../../../../";

import { Classes } from "src/mpd-library";

import { connect } from "react-redux";
import { globalActions, formatPhoneNumber } from "src/actions";

import { AccessPermissions } from "../../../components";
import {
    BreadCrumbs,
    SettingsTitle,
    StyledButton,
    StyledButtonTypes,
    ImagePlusText,
    ImageSource
} from "src/components";
import { ReactComponent as PersonLockBlueSmallIcon } from "../../../../../mpd-library/icon/assets/person-lock-blue-small.svg";
import { ReactComponent as EmailBlueSmallIcon } from "../../../../../mpd-library/icon/assets/email-blue-small.svg";
import { ReactComponent as PhoneBlueSmallIcon } from "../../../../../mpd-library/icon/assets/phone-blue-small.svg";

import "./styles.scss";
import { AppState, AccountSettingsGeneralCurrentUser } from "src/store";
import { AccountPrivilege } from "src/requests";
import { RouteComponentProps, matchPath } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { viewUser } from "src/actions/account-settings/account-settings-api";
import { Permissions } from "../../../../../components/permissions/Permissions";

interface IUserSettingsViewDetailsProps extends IDetailView {
    privileges: Array<AccountPrivilege> | null;
    currentUser: AccountSettingsGeneralCurrentUser;
    conversations_enabled: boolean;
    onViewUser: (id: string, routeProps: RouteComponentProps) => void;
}

class UserSettingsViewDetails extends React.Component<IUserSettingsViewDetailsProps> {
    constructor(props: IUserSettingsViewDetailsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.onEditUser = this.onEditUser.bind(this);
        this.updateToolBar(this.props);
    }

    public componentDidUpdate(prevProps: IUserSettingsViewDetailsProps) {
        const { currentUser, onViewUser, routeProps } = this.props;
        const id = globalActions.getSelectedId(routeProps);
        if (prevProps.currentUser.id !== currentUser.id && currentUser.id && id) {
            this.updateToolBar(this.props);
            onViewUser(id, routeProps);
        }
    }

    public componentDidMount() {
        const { onViewUser, routeProps } = this.props;
        const id = globalActions.getSelectedId(routeProps);
        id && onViewUser(id, routeProps);
    }

    public render() {
        const { currentUser, currentPlan, className, privileges, conversations_enabled } = this.props;
        const classes = "user-settings-view-details";

        const userPrivileges = currentUser.role?.id ? currentUser?.role?.privileges : currentUser?.privileges;
        return (
            <div className={classNames(Classes.ROW, Classes.RESPONSIVE, Classes.CONTAINER_MARGIN, classes, className)}>
                <div className={Classes.COLUMN}>
                    <div className={classNames(classes + "-info")}>
                        <SettingsTitle title="Basic Info" />
                        <div className={classNames(classes + "-detail-info", Classes.ASSIGN_BLOCK_MARGIN)}>
                            <ImagePlusText
                                className={Classes.BLOCK_MARGIN_BOTTOM}
                                element={
                                    <ImageSource
                                        initials={
                                            (currentUser?.firstName?.charAt(0) || "") +
                                            (currentUser?.lastName?.charAt(0) || "")
                                        }
                                        src={currentUser?.profile_pic?.url || ""}
                                        profile_pic_color={currentUser?.profile_pic_color}
                                    />
                                }
                                text={currentUser?.firstName + " " + currentUser?.lastName}
                            />
                            {/* {currentUser.role &&
                                <Text className={classes + '-role'}> { currentUser.role.title } </Text>
                            } */}
                            <ImagePlusText
                                IconComponent={PersonLockBlueSmallIcon}
                                text={currentUser.firstName + " " + currentUser.lastName}
                            />
                            <ImagePlusText IconComponent={EmailBlueSmallIcon} text={currentUser.email} />
                            <ImagePlusText
                                IconComponent={PhoneBlueSmallIcon}
                                text={formatPhoneNumber(currentUser.phone)}
                            />
                        </div>
                    </div>
                    {currentPlan !== "essential" && (
                        <div className={classes + "-info"}>
                            <SettingsTitle title="Teams" />
                            <div className={classNames(classes + "-teams", Classes.ASSIGN_BLOCK_PADDING)}>
                                <div>
                                    {currentUser.teams.map((team: any, index: number) => (
                                        <Text className={classes + "-team"} key={index}>
                                            {team.title}
                                        </Text>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={Classes.COLUMN}>
                    <div className={classes + "-info"}>
                        <SettingsTitle title="ACCESS &amp; PERMISSIONS" />
                        <div className={Classes.ASSIGN_BLOCK_PADDING}>
                            <Permissions formik={{ values: { privileges: userPrivileges } }} disabledCheckboxes />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private updateToolBar(props: IUserSettingsViewDetailsProps) {
        const { routeProps, currentUser } = props;
        const title = currentUser.firstName + " " + currentUser.lastName;

        const isUserSettingsViewDetailsPath = matchPath(routeProps.location.pathname, {
            path: ["/account-settings/users/view/details", "/account-settings/users/view/details/"],
            exact: false,
            strict: false
        });

        if (!isUserSettingsViewDetailsPath) {
            return;
        }

        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Users", pathname: "/account-settings/users" }, { text: title }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: <StyledButton type={StyledButtonTypes.primary} text="Edit User" onClick={this.onEditUser} />
        };
        this.props.onUpdateToolBar(topBarProps);
    }

    private onEditUser() {
        const { routeProps, currentUser } = this.props;
        const id = currentUser.id;
        routeProps.history.push(`/account-settings/users/edit/id-${id}`);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;
    const privileges = accountSettings.general.privileges;
    const { conversations_enabled } = accountSettings.organization;

    return {
        accountSettings,
        currentPlan,
        privileges,
        conversations_enabled
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onViewUser: viewUser
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsViewDetails);
