import { useCallback, useEffect } from "react";
import { Classes, Text } from "@blueprintjs/core";
import { Avatar } from "../../../../../../components";
import { useDataFetch } from "../../../../../../hooks/useDataFetch";
import { getAdminsForQueue } from "../../../../../../requests/conversationsRequests";
import useInfiniteScroll from "../../../../../conversations/components/chat/useInfiniteScroll";
import styles from "./TooltipContent.module.scss";
import classNames from "classnames";

const transformAdmin = (admin) => ({
    ...admin,
    ...admin.admin
});

export const TooltipContent = ({ queueId, initialAdmins }) => {
    const getQueuesAdmins = useCallback(
        async (params) => {
            const res = await getAdminsForQueue(queueId, params);
            const transformed = {
                ...res,
                data: {
                    ...res.data,
                    admins: res.data.admins.map(transformAdmin)
                }
            };
            return transformed;
        },
        [queueId]
    );

    const {
        entities: admins,
        loading,
        hasMore,
        setAtBottomOfList
    } = useDataFetch({
        initialEntites: initialAdmins.map(transformAdmin),
        skeletonLoading: true,
        listItemHeight: 40,
        entityKey: "admins",
        fetchDataRequest: getQueuesAdmins,
    });

    const [bottomSentryRef] = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore: () => setAtBottomOfList(true)
    });

    return (
        <>
            {admins.allIds.map((adminId, index) => {
                const admin = admins.byId[adminId];
                return (
                    <div
                        className={classNames(
                            styles[`admins-tooltip-list-item`],
                            adminId === "loading" && Classes.SKELETON
                        )}
                        key={adminId === "loading" ? `${loading}-${index}` : adminId}
                    >
                        <Avatar
                            avatarUrl={admin?.profile_pic?.url}
                            user={admin}
                            className={styles[`tooltip-avatar`]}
                            key={admin?.id}
                        />
                        <Text
                            className={styles[`tooltip-admin-name`]}
                        >{`${admin?.first_name} ${admin?.last_name}`}</Text>
                    </div>
                );
            })}
            <div ref={bottomSentryRef} style={{ width: "100%", height: "2px" }} />
        </>
    );
};
