// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import * as Classes from "../";

import "./styles.scss";

import { HTMLInputProps, Text } from "@blueprintjs/core";
import { MPDIcon } from "../";

export interface IMPDFloatingInputProps extends HTMLInputProps {
    focus?: boolean;
    maxCounter?: number;
    inputRef?: any;
    fixedPlaceholder?: string;
    hint?: string;
    isMaxLengthRequire?: boolean;
}

interface IMPDFloatingInputState {
    counter?: number;
    invisibleDivWidth?: number;
    focused?: boolean;
}

export class MPDFloatingInput extends React.Component<IMPDFloatingInputProps, IMPDFloatingInputState> {
    public static defaultProps: HTMLInputProps = {
        placeholder: ""
    };

    private input: React.RefObject<HTMLInputElement>;

    private invisibleInput: React.RefObject<HTMLDivElement>;

    constructor(props: HTMLInputProps) {
        super(props);
        this.input = React.createRef();
        this.invisibleInput = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.state = {
            counter: undefined,
            focused: false
        };
    }

    public componentDidMount() {
        const { focus, value } = this.props;
        if (focus !== undefined) {
            this.setFocus(focus);
        }

        if (value) {
            this.setState({ counter: value.toString().length });
        }
    }

    public componentDidUpdate(prevProps: IMPDFloatingInputProps) {
        const { value } = this.props;

        if (value !== prevProps.value) {
            if (value) {
                this.setState({ counter: value.toString().length });
            }
        }
    }

    public UNSAFE_componentWillReceiveProps({ focus }: IMPDFloatingInputProps) {
        if (focus !== undefined && focus !== this.props.focus) {
            this.setFocus(focus);
        }
    }

    public onFocus = () => {
        this.setState({ focused: true });
    };

    public onBlur = () => {
        this.setState({ focused: false });
    };

    public render() {
        const {
            className,
            placeholder,
            maxCounter,
            value,
            inputRef,
            hint,
            leftIcon,
            isMaxLengthRequire = true,
            ...newProps
        } = this.props;

        const { counter } = this.state;

        const classes = classNames(Classes.FLOATING_INPUT_CONTAINER, this.state.focused && Classes.ACTIVE, className);

        const properties = isMaxLengthRequire ? { maxLength: maxCounter } : null;

        return (
            <div className={classes} tabIndex={-1}>
                <span className={Classes.FLOATING_INPUT_LABEL} tabIndex={-1}>
                    {placeholder}
                </span>
                <div className={"input-wrapper"}>
                    {typeof leftIcon === "string" ? <MPDIcon IconComponent={leftIcon} className={"input-left-icon"} /> : leftIcon}
                    <input
                        // required={true}
                        value={value ? value : ""}
                        ref={inputRef || this.input}
                        onChange={this.onChange}
                        // maxLength={maxCounter}
                        placeholder={hint}
                        {...newProps}
                        {...properties}
                        onFocus={(event) => {
                            newProps.onFocus && newProps.onFocus(event);
                            this.onFocus();
                        }}
                        onBlur={(event) => {
                            newProps.onBlur && newProps.onBlur(event);
                            this.onBlur();
                        }}
                        className={classNames(leftIcon && "input_with-left-icon")}
                    />
                </div>
                {maxCounter && (
                    <Text className={Classes.FLOATING_INPUT_COUNTER}>
                        {counter}/{maxCounter}
                    </Text>
                )}
            </div>
        );
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { onChange } = this.props;
        const counter = event.currentTarget.value.length;

        setTimeout(() => {
            if (this.invisibleInput.current) {
                const invisibleDivWidth = this.invisibleInput.current.clientWidth;
                if (counter === 0) {
                    this.setState({ invisibleDivWidth: 0 });
                } else {
                    this.setState({ invisibleDivWidth });
                }
            }
        }, 10);

        this.setState({ counter });
        if (onChange) {
            onChange(event);
        }
    }

    private setFocus(state: boolean) {
        if (this.input.current) {
            if (state) {
                this.input.current.focus();
            } else {
                this.input.current.blur();
            }
            return state;
        }
        return;
    }
}
