import { Store } from "redux";
import { AppState } from "src/store";
import { ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE } from "src/actions/account-settings";
import { IToastType, ToastTypes } from "src/components";
import { updateToast } from "src/actions/global/global-api";

export const userCreatingErrorHandler = (store: Store<AppState>, error: any) => {
    if (error && error.errors && error.errors.email_identity && error.errors.email_identity.length) {
        store.dispatch({
            type: ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE,
            payload: { email: error.errors.email_identity }
        });
    } else if (error && error.errors && error.errors.phone_identity && error.errors.phone_identity.length) {
        store.dispatch({
            type: ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE,
            payload: { key: error.errors.phone_identity }
        });
    } else {
        store.dispatch({
            type: ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE,
            payload: error.errors
        });
    }

    const toast: IToastType = {
        value: "Validation error. Please check the form",
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));

    return;
};
