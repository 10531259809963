import { AxiosResponse } from "axios";
import { axiosInstance } from "../actions";
import { getConfig } from "../common";
import {
    AlertData,
    AlertDeleteResponse,
    AlertSearchByQueryResponse,
    AlertsListResponse,
    AlertResponse,
    AlertsListParams,
    AlertsSearchParams,
    AlertDataUpdateResponse
} from "./alertApi";

export async function getAlertsListRequest({
    limit,
    offset,
    status,
    sortTitle,
    user_id
}: AlertsListParams): Promise<AxiosResponse<AlertsListResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/alerts`, {
        params: { limit, offset, status, order: `updated_at:${sortTitle}`, user_id }
    });
}

export async function getAlertByIdRequest(id: string): Promise<AxiosResponse<AlertResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/alerts/${id}`);
}

export function postAlertRequest(body: AlertData): Promise<AxiosResponse<AlertResponse>> {
    const config = getConfig();
    return axiosInstance.post(`${config.API_URL}/alerts/create`, body);
}

export async function patchChangeAlertStatusByIdRequest(
    id: string,
    body: { status: string }
): Promise<AxiosResponse<AlertResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/alerts/${id}`, body);
}

export async function patchAlertRepliesSettingsRequest(
    id: string,
    body: { status: string }
): Promise<AxiosResponse<AlertResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/alerts/${id}/replies_settings`, body);
}

export async function postUpdateAlertByIdRequest(
    id: string,
    body: {
        attachments: any;
        body: any;
        generate_notification: any;
    }
): Promise<AxiosResponse<AlertDataUpdateResponse>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/alerts/${id}/updates`, body);
}

export async function deleteAlertRequest(id: string): Promise<AxiosResponse<AlertDeleteResponse>> {
    const config = getConfig();
    return await axiosInstance.delete(`${config.API_URL}/alerts/${id}`);
}

export async function getAlertByQueryRequest(
    params: AlertsSearchParams
): Promise<AxiosResponse<AlertSearchByQueryResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/alerts/search`, {
        params: {
            q: params.query,
            limit: params.limit,
            offset: params.offset,
            status: params.status
        }
    });
}

export async function deleteAlertAttachmentByIdRequest({
    alertId,
    attachmentId
}: {
    alertId: string;
    attachmentId: string;
}): Promise<AxiosResponse<{ status: string }>> {
    const config = getConfig();
    return await axiosInstance.delete(`${config.API_URL}/alerts/${alertId}/attachments/${attachmentId}`);
}

export const postAlertToChannel = async (alertId: string, channelId: string) => {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/alerts/${alertId}/channels`, { channel_id: channelId });
} 

export const retryPostAlertToChannel = async (alertId: string, channelId: string) => {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/alerts/${alertId}/channels/${channelId}/retry`);
}
