import { Switch, Route } from "react-router-dom";
import { SmsVoiceChannelCreate } from "./components/sms-voice-channel-create/sms-voice-channel-create";
import { SmsVoiceChannelEdit } from "./components/sms-voice-channel-edit/sms-voice-channel-edit";

export const SmsVoiceFlow = () => {
    return (
        <Switch>
            <Route path={"/account-settings/channels/sms_voice/create"} render={() => <SmsVoiceChannelCreate />} />
            <Route path={"/account-settings/channels/sms_voice/edit/:id"} render={() => <SmsVoiceChannelEdit />} />
        </Switch>
    );
};
