import { useContext, useMemo, useState } from "react";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { ConversationDetails } from "../../../../conversations/components";
import styles from "./ContactDetails.module.scss";
import { ContactDetails } from "../../../../../components/contact-details/ContactDetails";
import { StyledButton, StyledButtonTypes } from "../../../../../components";
import { Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { Position } from "@blueprintjs/core";
import classNames from "classnames";
import { useHistory } from "react-router";
import { starContact } from "../../../../../requests/contactsRequests";
import { ContactsListContext } from "../../contacts-list";
import { ReactComponent as CloseIcon } from "src/mpd-library/icon/assets/close.svg";

const SECTIONS_PARAMS = [
    { title: "CONTACT DETAILS", value: "contacts", defaultExpanded: true },
    {
        title: "PREVIOUS CONVERSATIONS",
        value: "conversations",
        defaultExpanded: false,
        sectionClassName: styles["prev-convos"]
    },
    { tags: "TAGS", value: "tags", defaultExpanded: true }
    //     { title: "CONTACT DETAILS", value: "contacts", defaultExpanded: false }
];

export const ContactsDetails = ({ user, onCloseClick, onDelete }) => {
    const [isViewRecordButtonHovered, setIsViewRecordButtonHovered] = useState(false);
    const { updateContacts } = useContext(ContactsListContext);
    const history = useHistory();

    const formatedUser = useMemo(
        () =>
            user
                ? {
                      ...user,
                      contacts: user.channels.reduce(
                          (acc, channel) => ({
                              ...acc,
                              [channel.type]: channel.identifier
                          }),
                          {}
                      ),
                      id: user.user_id
                  }
                : null,
        [user]
    );

    const onViewRecordClick = () => {
        history.push(`/contacts/${user.id}`);
    };

    const onStarUser = async () => {
        const res = await starContact(user.id, !user.starred);
        updateContacts({ [res.data.contact.id]: res.data.contact });
    };
    // if (!formatedUser) {
    //     return null;
    // }

    return (
        <AnimatePresence>
            {user && (
                <motion.div
                    initial={{ scale: 0, x: 320 }}
                    animate={{
                        scale: 1,
                        x: 0,
                        transition: { duration: 0.4 }
                    }}
                    exit={{
                        scale: 0,
                        x: 320,
                        transition: { duration: 0.4 }
                    }}
                    className={styles["root"]}
                >
                    <div className={styles["header"]}>
                        <span className={styles["header__user-name"]}>{`${user.first_name} ${user.last_name}`}</span>
                        <StyledButton
                            LeftIconComponent={CloseIcon}
                            type={StyledButtonTypes.secondary}
                            className={styles["header__close-button"]}
                            onClick={onCloseClick}
                        />
                    </div>
                    <div className={styles["actions-wrapper"]}>
                        <StyledButton
                            type={StyledButtonTypes.secondary}
                            className={styles["view-record-button"]}
                            text={"View Record"}
                            onMouseEnter={() => setIsViewRecordButtonHovered(true)}
                            onMouseLeave={() => setIsViewRecordButtonHovered(false)}
                            onClick={onViewRecordClick}
                        />
                        <Dropdown
                            targetClassName={classNames(
                                DropdownTargetClasses.HALF_BUTTON,
                                isViewRecordButtonHovered && styles["neighbour-hovered"]
                            )}
                            position={Position.BOTTOM}
                            staticOptions={[
                                {
                                    label: "Delete",
                                    value: null,
                                    onClick: async () => {
                                        await onDelete([user.id]);
                                        onCloseClick();
                                    }
                                },
                                { label: "Edit", value: null, onClick: onViewRecordClick }
                            ]}
                            position={Position.BOTTOM_LEFT}
                            contentBlockWidth={220}
                            ArrowIconComponent
                        />
                    </div>
                    <div className={styles["content"]}>
                        <ContactDetails
                            user={formatedUser}
                            sectionsParams={SECTIONS_PARAMS}
                            isDetailsExpanded
                            onStarUser={onStarUser}
                        />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
