import { FinishingTouchGeoFenceData } from "src/store";
import { CollectionActionTypes } from "./collections/collection-types";

export const STUDIO_TOGGLE_RIGHTPANEL = "studio-toggle-rightpanel";
export const FETCH_ATTACHMENT_BANNER = "fetch_attachment-banner";
export const FETCH_ATTACHMENT_ICON = "fetch_attachment-icon";
export const UPDATE_FINISHING_TOUCH_DATA = "update-finishing-touch-data";
export const UPDATE_LOADING_STUDIO = "update-loading-studio";
export const UPDATE_ATTACHMENT_LOADING = "update-attachment-loading";
export const UPDATE_ISFULLLIST = "update-isFullList";

interface StudioToggleRightpanelAction {
    type: typeof STUDIO_TOGGLE_RIGHTPANEL;
    payload: boolean;
}

interface FetchAttachment {
    type: typeof FETCH_ATTACHMENT_BANNER;
    payload: any;
}

interface FetchIconAttachment {
    type: typeof FETCH_ATTACHMENT_ICON;
    payload: any;
}
interface UpdateFinishingTouchDataAction {
    type: typeof UPDATE_FINISHING_TOUCH_DATA;
    payload: FinishingTouchGeoFenceData;
}

interface UpdateLoader {
    type: typeof UPDATE_LOADING_STUDIO;
    payload: boolean;
}

interface UpdateAttachmentLoading {
    type: typeof UPDATE_ATTACHMENT_LOADING;
    payload: boolean;
}

interface UpdateIsFullList {
    type: typeof UPDATE_ISFULLLIST;
    payload: any;
}

export type StudioActionTypes =
    | StudioToggleRightpanelAction
    | CollectionActionTypes
    | FetchAttachment
    | UpdateFinishingTouchDataAction
    | UpdateLoader
    | FetchIconAttachment
    | UpdateAttachmentLoading
    | UpdateIsFullList;
