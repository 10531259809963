import { UserOnBoardingState } from "src/store";

export const USER_ONBOARDING_UPDATE = "user-onboarding-update";
export const USER_ONBOARDING_CONFIRM = "user-onboarding-confirm";

interface UserOnboardingUpdateAction {
    type: typeof USER_ONBOARDING_UPDATE;
    payload: UserOnBoardingState;
}

interface UserOnboardingConfirmAction {
    type: typeof USER_ONBOARDING_CONFIRM;
    payload: UserOnBoardingState;
}

export type UserOnboardingActionTypes = UserOnboardingUpdateAction | UserOnboardingConfirmAction;
