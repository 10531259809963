import { Spinner } from "@blueprintjs/core";
import { useEffect } from "react";
import useInfiniteScroll from "src/containers/conversations/components/chat/useInfiniteScroll";
import { PageLoadingSpinner } from "../page-loading-spinner/page-loading-spinner";
import styles from "./styles.module.scss";
const classname = "infinite-scroll";

type InfiniteScrollProps = {
    loading: boolean;
    hasMore: boolean;
    children: JSX.Element;
    listLength: number;
    onLoadMore: (...args: Array<any>) => void;
};

export const InfiniteScroll = ({ loading, onLoadMore, hasMore, children, listLength }: InfiniteScrollProps) => {
    const [ref] = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore
    });

    if (loading && !listLength) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={classname}>
            {children}
            {(loading || hasMore) && (
                <div ref={ref} className={styles[`${classname}-load-more-spinner-wrapper`]}>
                    <Spinner intent="primary" size={30} />
                </div>
            )}
        </div>
    );
};
