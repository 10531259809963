export interface Config {
    API_URL: string;
    SOCKET_URL: string;
}

export function getConfig(): Config {
    return {
        API_URL: process.env.REACT_APP_API_URL || "https://atlas1.dev/api/v1/portal",
        SOCKET_URL: `wss://${process.env.REACT_APP_API_HOSTNAME}/api/v1/portal/websocket?jwt=`
    };
}
