import {
    UserSettingsPasswordState,
    UserSettingsNotificationState,
    UserSettingsScheduleState,
    UserSettingsEmailPassOTPState,
    UserSettingsMyInfo,
    UserSettingsActivity
} from "src/store";
import { AccountPrivilege } from "src/requests";

export const USER_SETTINGS_TOGGLE_RIGHTPANEL = "user-settings-toggle-rightpanel";
export const USER_SETTINGS_MY_INFO_UPDATE = "user-settings-my-info-update";
export const USER_SETTINGS_PASSWORD_UPDATE = "user-settings-password-update";
export const USER_SETTINGS_PRIVILEGES_UPDATE = "user-settings-privileges-update";
export const USER_SETTINGS_NOTIFICATIONS_UPDATE = "user-settings-notifications-update";
export const USER_SETTINGS_SCHEDULE_UPDATE = "user-settings-schedule-update";
export const USER_SETTINGS_LOADING = "user-settings-loading";
export const USER_SETTINGS_EMAIL_PASS_OTP_UPDATE = "user-settings-email-pass-otp-update";
export const USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE = "user-settings-email-pass-change-error-update";
export const USER_SETTINGS_SAVED_EMAIL_INTERMEDIATE_VALUE_UPDATE =
    "user-settings-saved-email-intermediate-value-update";

export const USER_SETTINGS_ACTYVITY_UPDATE = "user-settings-activity-update";
export const USER_SETTINGS_ACTYVITY_LOADING_STATUS_UPDATE = "user-settings-activity-loading-status-update";

interface UserSettingsToggleRightpanelAction {
    type: typeof USER_SETTINGS_TOGGLE_RIGHTPANEL;
    payload: boolean;
}

interface UserSettingsMyInfoUpdateAction {
    type: typeof USER_SETTINGS_MY_INFO_UPDATE;
    payload: UserSettingsMyInfo;
}

interface UserSettingsLoadingAction {
    type: typeof USER_SETTINGS_LOADING;
    payload: boolean;
}

interface UserSettingsEmailPassOTPUpdateAction {
    type: typeof USER_SETTINGS_EMAIL_PASS_OTP_UPDATE;
    payload: UserSettingsEmailPassOTPState;
}

interface UserSettingsPasswordUpdateAction {
    type: typeof USER_SETTINGS_PASSWORD_UPDATE;
    payload: UserSettingsPasswordState;
}

interface UserSettingsPrivilegesUpdateAction {
    type: typeof USER_SETTINGS_PRIVILEGES_UPDATE;
    payload: Array<AccountPrivilege>;
}

interface UserSettingsNotificationsUpdateAction {
    type: typeof USER_SETTINGS_NOTIFICATIONS_UPDATE;
    payload: UserSettingsNotificationState;
}

interface UserSettingsScheduleUpdateAction {
    type: typeof USER_SETTINGS_SCHEDULE_UPDATE;
    payload: UserSettingsScheduleState;
}

interface UserSettingsEmailPassChangeErrorUpdateAction {
    type: typeof USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE;
    payload: string;
}

interface UserSettingsSavedEmailIntermediateValueUpdateAction {
    type: typeof USER_SETTINGS_SAVED_EMAIL_INTERMEDIATE_VALUE_UPDATE;
    payload: string;
}

interface UserSettingsActivityUpdateAction {
    type: typeof USER_SETTINGS_ACTYVITY_UPDATE;
    payload: Array<UserSettingsActivity>;
}

interface UserSettingsActivityLoadingStatusUpdateAction {
    type: typeof USER_SETTINGS_ACTYVITY_LOADING_STATUS_UPDATE;
    payload: boolean;
}

export type UserSettingsActionTypes =
    | UserSettingsToggleRightpanelAction
    | UserSettingsMyInfoUpdateAction
    | UserSettingsLoadingAction
    | UserSettingsEmailPassOTPUpdateAction
    | UserSettingsPasswordUpdateAction
    | UserSettingsPrivilegesUpdateAction
    | UserSettingsNotificationsUpdateAction
    | UserSettingsScheduleUpdateAction
    | UserSettingsEmailPassChangeErrorUpdateAction
    | UserSettingsSavedEmailIntermediateValueUpdateAction
    | UserSettingsActivityUpdateAction
    | UserSettingsActivityLoadingStatusUpdateAction;
