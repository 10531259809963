import { ConversationsState, ConversationsViewType, defaultConversationsState } from "src/store/conversations";
import {
    GET_CONVERSATIONS,
    UPDATE_CONVERSATION,
    SET_SELECTED_CONVERSATION_ID,
    SET_GETTING_QUEUES_STATUS,
    SET_VIEW_TYPE,
    SET_ADMINS,
    SET_SELECTED_QUEUE,
    SET_CONVERSATIONS_COUNT,
    ConversationsActionTypes,
    SET_DEFAULT_STATE,
    ACCEPT_FILTER_VALUES,
    SET_CONVERSATIONS_VIEWS,
    SET_SELECTED_VIEW_DATA,
    UPDATE_CONVERSATIONS_LOADING_STATUS,
    SET_HAS_MORE_CONVERSATIONS,
    DELETE_SAVED_VIEW,
    SET_ACCESS_ERROR_TO_CONVERSATION,
    SET_CONVERSATIONS_COUNTER,
    SET_PREVIOUS_CONVERSATIONS_WITH_SENDER,
    SET_HAS_MORE_CONVERSATIONS_WITH_SENDER,
    SET_BASED_ON_PERMISSIONS_ADMINS,
    SET_GLOBAL_VIEW,
    SET_TO_ZERO_CONVERSATION_UNREAD_COUNT,
    REMOVE_CONVERSATION_FROM_LIST,
    UPDATE_CONVERSATIONS_IDS_LIST,
    UDPATE_CONVERSATIONS_USER
} from "../actions/conversations/types";
// comment to trigger build 

const getConversationsGroupName = (action) => {
    if (action.isSearching) {
        return "search";
    } else if (action.conversationsGroupName) {
        return action.conversationsGroupName;
    }
    return null;
};

export const conversationsReducer = (
    state: ConversationsState = defaultConversationsState(),
    action: ConversationsActionTypes
): ConversationsState => {
    switch (action.type) {
        case GET_CONVERSATIONS:
            const conversationsGroupName = getConversationsGroupName(action) || state.viewType;
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    byId: {
                        ...state.conversations.byId,
                        ...action.payload.reduce((accumulator, conversation) => {
                            return {
                                ...accumulator,
                                [conversation.id]: {
                                    ...state.conversations.byId[conversation.id],
                                    ...conversation,
                                    user: conversation?.user?.id
                                }
                            };
                        }, {})
                    },
                    [conversationsGroupName]: [
                        ...(!action.refresh ? state.conversations[conversationsGroupName] || [] : []),
                        ...action.payload.map((conversation) => conversation.id)
                    ]
                },
                conversationsUsers: {
                    byId: {
                        ...state.conversationsUsers.byId,
                        ...action.payload.reduce((accumulator, conversation) => {
                            return {
                                ...accumulator,
                                [conversation?.user?.id]: {
                                    ...state.conversationsUsers.byId[conversation.user?.id],
                                    ...conversation.user
                                }
                            };
                        }, {})
                    }
                }
            };
        case UDPATE_CONVERSATIONS_USER:
            return {
                ...state,
                conversationsUsers: {
                    byId: {
                        ...state.conversationsUsers.byId,
                        [action.payload.id]: {
                            ...state.conversationsUsers.byId[action.payload.id],
                            ...action.payload
                        }
                    }
                }
            };
        case UPDATE_CONVERSATION:
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    byId: {
                        ...state.conversations.byId,
                        [action.payload.id]: {
                            ...state.conversations.byId[action.payload.id],
                            ...action.payload,
                            user: action.payload?.user
                                ? action.payload?.user?.id
                                : state.conversations.byId[action.payload.id]?.user
                        }
                    }
                },
                conversationsUsers: {
                    ...state.conversationsUsers,
                    byId: {
                        ...state.conversationsUsers.byId,
                        [action.payload.user?.id]: action.payload.user
                            ? action.payload.user
                            : state.conversationsUsers[action.payload.user?.id]
                    }
                }
            };
        case UPDATE_CONVERSATIONS_IDS_LIST:
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.payload.viewType || state.viewType]: action.payload.conversationsIds
                }
            };
        case REMOVE_CONVERSATION_FROM_LIST:
            const viewTypeRemoveFrom = action.payload.viewType ? action.payload.viewType : state.viewType;
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [viewTypeRemoveFrom]: [
                        ...state.conversations[viewTypeRemoveFrom]?.filter(
                            (conversationId) => conversationId !== action.payload.conversationId
                        )
                    ]
                }
            };
        case SET_HAS_MORE_CONVERSATIONS:
            return {
                ...state,
                hasMoreConversations: action.payload
            };
        case SET_SELECTED_CONVERSATION_ID:
            return {
                ...state,
                selectedConversationId: action.payload
            };
        case SET_GETTING_QUEUES_STATUS:
            return {
                ...state,
                gettingConversationStatus: action.payload
            };
        case SET_VIEW_TYPE:
            return {
                ...state,
                viewType: action.payload
            };
        case SET_ADMINS:
            return {
                ...state,
                admins: { adminsList: action.adminsList, hasMoreAdmins: action.hasMoreAdmins }
            };
        case SET_SELECTED_QUEUE:
            return {
                ...state,
                selectedQueueId: action.payload
            };
        case SET_CONVERSATIONS_COUNT: {
            return {
                ...state,
                counts: {
                    ...state.counts,
                    ...action.payload
                }
            };
        }
        case ACCEPT_FILTER_VALUES:
            return {
                ...state,
                conversationFilter: {
                    filterValues: { ...action.payload.filterValues },
                    isFilterApplyed: action.payload.isFilterApplyed,
                    filterCounter: action.payload.filterCounter,
                    selectedFilterId: action.payload.viewId || null,
                    selectedFilterName: action.payload.selectedFilterName || null,
                    shouldBeEdited: action.payload.shouldBeEdited || null
                }
            };
        case SET_CONVERSATIONS_VIEWS:
            return {
                ...state,
                conversationsViews: action.payload
            };
        case UPDATE_CONVERSATIONS_LOADING_STATUS:
            return {
                ...state,
                conversationsListLoading: action.payload
            };
        case SET_DEFAULT_STATE:
            return {
                ...defaultConversationsState(),
                conversationsCounter: state.conversationsCounter,
                viewType: action.viewType
            };
        case SET_SELECTED_VIEW_DATA:
            return {
                ...state,
                selectedViewData: action.payload
            };
        case DELETE_SAVED_VIEW:
            return {
                ...state,
                conversationsViews: [
                    ...state.conversationsViews.filter(
                        (conversationView: ConversationsViewType) => conversationView.id !== action.payload
                    )
                ]
            };
        case SET_ACCESS_ERROR_TO_CONVERSATION:
            return {
                ...state,
                accessErrorToConversation: {
                    ...state.accessErrorToConversation,
                    [action.payload]: true
                }
            };
        case SET_CONVERSATIONS_COUNTER:
            return {
                ...state,
                conversationsCounter: action.payload
            };
        case SET_PREVIOUS_CONVERSATIONS_WITH_SENDER:
            return {
                ...state,
                prevConversationsWithSender: {
                    ...state.prevConversationsWithSender,
                    list: action.payload
                }
            };
        case SET_HAS_MORE_CONVERSATIONS_WITH_SENDER:
            return {
                ...state,
                prevConversationsWithSender: {
                    ...state.prevConversationsWithSender,
                    has_more: action.payload
                }
            };
        case SET_BASED_ON_PERMISSIONS_ADMINS:
            return {
                ...state,
                queuesBasedOnPermissionsAdmins: action.payload
            };
        case SET_GLOBAL_VIEW:
            return {
                ...state,
                globalView: action.payload
            };
        case SET_TO_ZERO_CONVERSATION_UNREAD_COUNT:
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    byId: {
                        ...state.conversations.byId,
                        [action.payload.conversationId]: {
                            ...state.conversations.byId[action.payload.conversationId],
                            unread_count: 0
                        }
                    }
                }
            };
        default:
            return state;
    }
};
