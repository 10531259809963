import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Option } from "../../types";
import { MPDSelectItem } from "../../../select-item";
import { Component, useEffect, useState } from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";

// fake data generator
const getItems = (count) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    // background: isDragging ? "#1f76ff" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
    height: "40px",
    borderRadius: "6px",
    left: "auto !important",
    top: "auto !important"
});

const getListStyle = (isDraggingOver) => ({});

export const DndContent = ({ onChange, ...props }) => {
    console.log("DndContent propes ...", props);
    const [options, setOptions] = useState(props.options);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    const onDragEnd = (result) => {
        console.log("OOOPTIONS >>>> end", options);
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(options.allIds, result.source.index, result.destination.index);

        console.log("ITEMS <<<", items);

        setOptions({ ...options, allIds: items });

        onChange(
            items.map((id) => {
                return options.byId[id];
            })
        );
    };

    const onCheckboxClick = (option) => {
        const updatedState = {
            ...options,
            byId: {
                ...options.byId,
                [option.field]: {
                    ...option,
                    enabled: !option.enabled
                }
            }
        };

        setOptions(updatedState);
        // onSelectOption(updatedState);

        onChange(
            updatedState.allIds.map((id) => {
                return updatedState.byId[id];
            })
        );
    };

    console.log("OOOPTIONS >>>>", options);

    return (
        <div id={"fencyyy"}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="droppable"
                    renderClone={(provided, snapshot, rubric) => {
                        const itemId = options.allIds[rubric.source.index];
                        const item = options.byId?.[itemId];
                        return (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <MPDSelectItem
                                    label={item?.label}
                                    className={classNames(snapshot.isDragging && "dragging")}
                                    withCheckbox
                                    checked={item?.enabled}
                                    draggable
                                    LeftIconComponent={item.LeftIconComponent}
                                />
                            </div>
                        );
                    }}
                >
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {options.allIds.map((itemId, index) => {
                                const item = options.byId[itemId];
                                return (
                                    <Draggable
                                        key={item === "loading" ? `loading-${index}` : item?.field}
                                        draggableId={item === "loading" ? `loading-${index}` : item?.field}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <MPDSelectItem
                                                    label={item?.label}
                                                    className={classNames(snapshot.isDragging && "dragging")}
                                                    withCheckbox
                                                    loading={item === "loading"}
                                                    onCheckBoxClick={() => onCheckboxClick(item)}
                                                    checked={item?.enabled}
                                                    draggable
                                                    LeftIconComponent={item.LeftIconComponent}
                                                    disabled={item.disabled}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

class PortalAwareItem extends Component {
    render() {
        const provided = this.props.provided;
        const snapshot = this.props.snapshot;
        const item = this.props.item;

        console.log("snapshot.isDragging", snapshot.isDragging);

        const usePortal: boolean = snapshot.isDragging;
        const rootElement = document.getElementById("fencyyy");

        const child: Node = (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
                <MPDSelectItem
                    label={item.content}
                    className={classNames(snapshot.isDragging && "dragging")}
                    withCheckbox
                />
                {/* {item.content} */}
            </div>
        );

        if (!usePortal) {
            return child;
        }

        // if dragging - put the item in a portal
        return createPortal(child, rootElement);
    }
}
