import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { MPDSelectItem } from "../../../select-item";
import { Position } from "@blueprintjs/core";
import { Tooltip } from "../../../../components/tooltip/tooltip";
import styles from "./TagDropdownListItem.module.scss";
import { ReactComponent as ButtonChevronRightIcon } from "src/mpd-library/icon/assets/ButtonChevronRight.svg";
import { MPDIcon } from "../../../icon";
import classNames from "classnames";

export const TagDropdownListItem = (props) => {
    const { option: tag } = props;

    const LeftIconComponent = useCallback(() => {
        if (tag?.ancestors_formatted?.[0]) {
            return (
                <Tooltip
                    position={Position.BOTTOM}
                    tootltipTarget={
                        <span className={styles["ancestor"]}>
                            {"..."}
                            <MPDIcon IconComponent={ButtonChevronRightIcon} className={styles["chevron-right"]} />
                        </span>
                    }
                    content={
                        <div className={styles["tooltip-content"]}>
                            <span>{tag?.ancestors_formatted?.[0]}</span>
                            <MPDIcon IconComponent={ButtonChevronRightIcon} className={styles["chevron-right"]} />
                            <span>{tag?.name}</span>
                        </div>
                    }
                />
            );
        }
        return null;
    }, [tag]);

    return (
        <MPDSelectItem
            {...props}
            LeftIconComponent={LeftIconComponent}
            className={classNames(props.className, styles["tag-dropdown-item"])}
        />
    );
};
