// tslint:disable
import React from "react";
import classNames from "classnames";

import { IProps, Text } from "@blueprintjs/core";

import { StyledSwitch, StyledButton } from "src/components/";

import { Alignment } from "src/mpd-library";

import "./styles.scss";
import { RouteComponentProps, RouterProps, withRouter } from "react-router";
import { ReactComponent as ProfileSettingsIcon } from "../../../../mpd-library/icon/assets/profile-settings.svg"

interface ISideBarAccountProps extends IProps, RouterProps {
    firstName?: string;
    lastName?: string;
    routeProps: RouteComponentProps;
    src?: string | ArrayBuffer | null;
    status?: boolean;
    awayModeActive?: boolean;
    onItemClick?: () => void;
    onToggleProfileStatus?: (event: React.SyntheticEvent) => void;
}

const SideBarAccountComponent: React.FunctionComponent<ISideBarAccountProps> = (props) => {
    const {
        className,
        firstName,
        awayModeActive,
        lastName,
        src,
        onToggleProfileStatus,
        status,
        history,
        onItemClick
    } = props;
    const classes = "sidebar-account";

    return (
        <div className={classNames(classes, className)} onClick={(e) => e.stopPropagation()}>
            <div className={classes + "-profile-info"}>
                {src ? (
                    <div
                        className={classes + "-initials-src"}
                        style={{
                            backgroundImage: `url(${src})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}
                    />
                ) : (
                    <Text className={classes + "-initials"}>
                        {" "}
                        {(firstName ? firstName.charAt(0) : "") + (lastName ? lastName.charAt(0) : "")}{" "}
                    </Text>
                )}
                <div className={classes + "-info"}>
                    <Text className={classes + "-name"}> {firstName + " " + lastName} </Text>
                    <Text className={classes + "-status"}> {status ? "Away" : "Active"} </Text>
                </div>
            </div>
            {awayModeActive && (
                <div className={classes + "-status-control"}>
                    <StyledSwitch
                        checked={status}
                        onChange={onToggleProfileStatus}
                        label="Away Mode"
                        alignIndicator={Alignment.RIGHT}
                    />
                </div>
            )}
            <div className={classes + "-menu-container"}>
                <div className="inner-container">
                    <StyledButton
                        className={classes + "-settings"}
                        // IconComponent={ProfileSettingsIcon}
                        text="Profile Settings"
                        selectable={false}
                        onClick={() => {
                            onItemClick();
                            history.push("/user-settings");
                        }}
                    />
                    <div />
                </div>
                <div className="inner-container">
                    <StyledButton
                        onClick={() => {
                            onItemClick();
                            history && history.push("/signout");
                        }}
                        text="Logout"
                    />
                </div>
            </div>
        </div>
    );
};

export const SideBarAccount = withRouter(SideBarAccountComponent);
