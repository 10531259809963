import {
    UPDATE_COLLECTION,
    CollectionActionTypes,
    UPDATE_CURRENT_COLLECTION,
    FETCH_ALL_COLLECTION_UPDATE,
    FETCH_SINGLE_COLLECTION_UPDATE,
    UPDATE_LOADING_ALL_COLLECTION,
    UPDATE_SELECTED_COLLECTION,
    UPDATE_COLLECTION_BOTTOM_TOOLBAR,
    DELETE_COLLECTION,
    ADD_COLLECTION,
    UPDATE_EDIT_COLLECTION,
    DELETE_ENTRY,
    COLLECTION_IS_FULL_LIST_STATUS_UPDATE,
    EDIT_ENTRY,
    UPDATE_ENTRY,
    UPDATE_LOADING_COLLECTION_LIST,
    UPDATE_ENTRY_LOADING_LIST,
    ADD_ENTRY,
    ENTRY_LOADING
} from "../actions/studio/collections/collection-types";
import { CollectionState, defaultCollectionState } from "../store";

export const collectionReducer = (
    state: CollectionState = defaultCollectionState,
    action: CollectionActionTypes
): CollectionState => {
    switch (action.type) {
        case UPDATE_COLLECTION_BOTTOM_TOOLBAR:
            return {
                ...state,
                addCollection: action.payload
            };
        case ADD_COLLECTION:
            return {
                ...state,
                collections: [action.payload, ...state.collections],
                addCollection: {
                    ...state.addCollection,
                    collection: action.payload
                }
            };
        case UPDATE_COLLECTION:
            const updatedCollections = state.collections.filter(
                (val) => val.id !== action.payload.updatedCollection.id
            );
            return {
                ...state,
                collections: [action.payload.updatedCollection, ...updatedCollections],
                selectedCollection: {
                    ...state.selectedCollection,
                    currentCollection: action.payload.updatedCollection
                }
            };
        case UPDATE_CURRENT_COLLECTION:
            return {
                ...state,
                selectedCollection: {
                    ...state.selectedCollection,
                    currentCollection: action.payload
                }
            };
        case UPDATE_EDIT_COLLECTION:
            return {
                ...state,
                addCollection: {
                    ...state.addCollection,
                    collection: action.payload
                }
            };
        case UPDATE_LOADING_ALL_COLLECTION:
            return {
                ...state,
                loading: action.payload
            };
        case COLLECTION_IS_FULL_LIST_STATUS_UPDATE:
            return {
                ...state,
                isFullLists: { ...action.payload }
            };
        case FETCH_ALL_COLLECTION_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        case FETCH_SINGLE_COLLECTION_UPDATE:
            return {
                ...state,
                selectedCollection: {
                    entries: [],
                    currentCollection: action.payload
                }
            };
        case UPDATE_ENTRY_LOADING_LIST:
            return {
                ...state,
                entryLoading: action.payload
            };
        case UPDATE_LOADING_COLLECTION_LIST:
            return {
                ...state,
                collectionLoading: action.payload
            };
        case UPDATE_SELECTED_COLLECTION:
            return {
                ...state,
                entryLoading: false,
                selectedCollection: action.payload
            };
        case DELETE_COLLECTION:
            const newCollections = state.collections.filter((val) => val.id !== parseInt(action.payload.id, 0));
            return {
                ...state,
                collections: [...newCollections]
            };
        case DELETE_ENTRY:
            const newEntries = state.selectedCollection.entries.filter(
                (val) => val.id !== parseInt(action.payload.entryId, 0)
            );

            const lstCollection = state.collections.map((collection) => {
                if (collection.id === state.selectedCollection.currentCollection.id) {
                    collection.entries_count -= 1;
                }
                return collection;
            });
            return {
                ...state,
                collections: lstCollection,
                selectedCollection: {
                    ...state.selectedCollection,
                    entries: [...newEntries]
                }
            };
        case ADD_ENTRY:
            const collections = state.collections.map((collection) => {
                if (collection.id === state.selectedCollection.currentCollection.id) {
                    collection.entries_count += 1;
                }
                return collection;
            });
            return {
                ...state,
                collections,
                selectedCollection: {
                    currentCollection: state.selectedCollection.currentCollection,
                    entries: [...state.selectedCollection.entries, action.payload]
                }
            };
        case EDIT_ENTRY:
            return {
                ...state,
                addEntry: action.payload
            };
        case UPDATE_ENTRY:
            const updatedEntry = state.selectedCollection.entries.map((entry) => {
                return entry.id === action.payload.id ? action.payload.updatedEntry : entry;
            });
            return {
                ...state,
                selectedCollection: {
                    ...state.selectedCollection,
                    entries: [...updatedEntry] as any
                }
            };
        case ENTRY_LOADING:
            return {
                ...state,
                entryLoadingData: action.payload
            };
        default:
            return state;
    }
};
