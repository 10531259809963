// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Classes, MPDAnimationContainer, MPDAnimations } from "src/mpd-library/";

import { IProps } from "@blueprintjs/core";

import "./styles.scss";

export interface ILeftPanelContainerProps extends IProps {
    collapsed?: boolean;
    overlayVisible?: boolean;
    scrollRef?: any;
    mobileSideBarActive?: boolean;
    isIn?: boolean;
    exit?: boolean;
    appear?: boolean;
    enter?: boolean;
    timeout?: number;
    isMobileSize?: boolean;
    animationType?: MPDAnimations;
    onOverlayClick?: (event: React.SyntheticEvent) => void;
}

export interface ILeftPanelContainerState {
    mounted: boolean;
}

export class LeftPanelContainer extends React.Component<ILeftPanelContainerProps, ILeftPanelContainerState> {
    constructor(props: ILeftPanelContainerProps) {
        super(props);
        this.state = {
            mounted: false
        };
    }

    public componentDidMount() {
        this.setState({ mounted: true });
    }

    public componentWillUnmount() {
        this.setState({ mounted: false });
    }

    public render() {
        const {
            className,
            animationType,
            timeout,
            isIn,
            isMobileSize,
            exit,
            appear,
            enter,
            scrollRef,
            children,
            collapsed,
            onOverlayClick,
            overlayVisible,
            mobileSideBarActive
        } = this.props;
        const { mounted } = this.state;
        const classes = "left-panel-container";

        return (
            <MPDAnimationContainer
                className={classNames(classes, mobileSideBarActive && "sidebar-" + Classes.ACTIVE)}
                onEntering={() => timeout}
                onExit={() => timeout}
                classNames={isMobileSize && mounted ? animationType : MPDAnimations.none}
                appear={isMobileSize ? appear : true}
                in={isMobileSize ? isIn : true}
                exit={isMobileSize ? exit : false}
                enter={isMobileSize ? enter : true}
                transitionDuration={timeout}
                timeout={timeout ? timeout : 0}
                // unmountOnExit={true}
            >
                <div
                    ref={scrollRef}
                    className={classNames(
                        classes + "-wrapper",
                        collapsed && Classes.COLLAPSED,
                        mobileSideBarActive && "sidebar-" + Classes.ACTIVE,
                        className
                    )}
                >
                    <div
                        className={classNames("left-panel-overlay", overlayVisible && Classes.ACTIVE)}
                        onClick={onOverlayClick}
                    />
                    {children}
                </div>
            </MPDAnimationContainer>
        );
    }
}
