import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ToastTypes } from "../../components";
import { MPDIcon } from "../icon";
import styles from "./Input.module.scss";
import { ActionsButtons } from "./ActionsButtons";
import { useDispatch } from "react-redux";
import { updateToast } from "../../actions/global/global-api";

interface Input {
    placeholder?: string;
    leftIcon: string | JSX.Element;
}

export const InputTypes = {
    primary: styles["primary"],
    secondary: styles["secondary"],
    edit: styles["edit"]
};

export const Input = ({
    placeholder,
    leftIcon,
    actions = [],
    type,
    outerLeftIcon,
    children,
    onEditClick,
    textAreaProps,
    autoFocus,
    onCopyClick,
    onRemoveClick,
    ...remain
}) => {
    const [active, setActive] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const dispatch = useDispatch();
    const ref = useRef();

    useEffect(() => {
        if (autoFocus) {
            ref.current?.focus?.();
        }
    }, [autoFocus]);

    const onMouseEnter = () => {
        setActive(true);
    };

    const onMouseLeave = () => {
        setActive(false);
    };

    const onFocus = () => {
        setFocused(true);
        remain.onFocus?.();
    };

    const onBlur = () => {
        setFocused(false);
        remain.onBlur?.();
    };

    const onCopy = () => {
        try {
            navigator.clipboard.writeText(remain.value);
            onCopyClick?.(remain.value);
            dispatch(updateToast({ toast: { type: ToastTypes.saved, value: "Value copied to clipboard" } }));
        } catch (err) {
            console.log("COPY ERROR >>>", err);
        }
    };

    const onEdit = () => {
        ref.current?.focus?.();
        onEditClick?.();
    };

    const onRemove = () => {
        onRemoveClick?.(remain.id);
    };
    return (
        <div className={styles["root"]}>
            {typeof outerLeftIcon === "string" ? <MPDIcon name={outerLeftIcon} /> : outerLeftIcon && outerLeftIcon}
            <div
                className={classNames(
                    styles["input-wrapper"],
                    type,
                    (active || !!remain.value || focused) && styles["active"],
                    !placeholder && styles["input-wrapper_without-title"]
                )}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <span className={styles["placeholder"]}>{placeholder}</span>
                {/* {!!placeholder && ( */}
                {/* <AnimatePresence>
                    <motion.span
                        className={styles["placeholder"]}
                        variants={variants}
                        animate={placeholder ? "visible" : "hidden"}
                    >
                        {placeholder}
                    </motion.span>
                </AnimatePresence> */}
                {/* )} */}
                {children ? (
                    children
                ) : (
                    <>
                        {typeof leftIcon === "string" ? <MPDIcon name={leftIcon} /> : leftIcon && leftIcon}
                        {!textAreaProps && (
                            <input ref={ref} type="text" {...remain} onFocus={onFocus} onBlur={onBlur} />
                        )}
                        {!!textAreaProps && (
                            <textarea ref={ref} type={"text"} {...remain} onFocus={onFocus} onBlur={onBlur} />
                        )}
                        <ActionsButtons
                            onEditClick={onEdit}
                            onCopyClick={onCopy}
                            onRemoveClick={onRemove}
                            actions={actions}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
