// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";
import { Text } from "@blueprintjs/core";

import { IProps } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

import "./styles.scss";
import { StyledDateTimeInputs } from "../styled-datetime-inputs";
import { StyledSelectTypes } from "../styled-select";

interface IStartEndTimerProps extends IProps {
    startTimeProps?: any;
    endTimeProps?: any;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const StartEndDateTimer: React.FunctionComponent<IStartEndTimerProps> = (props) => {
    const { className, startTimeProps, endTimeProps } = props;
    const classes = "start-end-date-timer";
    return (
        <div className={classNames(classes, Classes.ROW, className)}>
            <StyledDateTimeInputs key={0} type={StyledSelectTypes.primary} {...startTimeProps} />
            <Text className={classes + "-to"}>to</Text>
            <StyledDateTimeInputs key={1} type={StyledSelectTypes.primary} {...endTimeProps} />
        </div>
    );
};
