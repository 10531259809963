import { Collapse, Position, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Avatar } from "../avatar/avatar";
import { formatPhoneNumber } from "src/actions";
import styles from "./ContactDetails.module.scss";
import { Fragment, useContext, useMemo, useState } from "react";
import { Tooltip2 } from "@blueprintjs/popover2";
import { StyledButton } from "../styled-button";
import { MPDIcon } from "../../mpd-library";
import { TitleWIthDevider } from "../../containers/conversations/components/conversation-details/components/title-with-devider/title-with-devider";
import { SenderPreviousConversations } from "../../containers/conversations/components/conversation-details/components/sender-previous-conversations/sender-previous-conversations";
import { Tag } from "../tag/tag";
import { ContactsListContext } from "../../containers/contacts/contacts-list/contacts-list";
import { starContact } from "../../requests/contactsRequests";
import { ContactActivityChart } from "../contact-activity-chart/contact-activity-chart";
import { ReactComponent as ContactPhoneNumberIcon } from "../../mpd-library/icon/assets/contact-phone-number.svg";
import { ReactComponent as EmailEnvelopesIcon } from "../../mpd-library/icon/assets/envelope-email.svg";
import { ReactComponent as RatingStarIcon } from "../../mpd-library/icon/assets/rating-star.svg";
import { CHANNEL_TYPE_MATCH_ICON } from "../../containers/contacts/contacts-list/components/contacts-list-item/contacts-list-item";

const classname = "conversation-details";

const nameVariants = {
    open: { opacity: 1, scale: 1, height: 22 },
    closed: { opacity: 0, height: 0, scale: 0 }
};

const contactDetailsKeys = [
    {
        key: "landline_phone",
        IconComponent: ContactPhoneNumberIcon,
        bgcolor: "#34C759",
        hrefPrefix: "tel:"
    },
    {
        key: "email",
        IconComponent: EmailEnvelopesIcon,
        bgcolor: "#FF3B6A",
        hrefPrefix: "mailto:"
    },
    {
        key: "mobile_phone",
        IconComponent: ContactPhoneNumberIcon,
        bgcolor: "#34C759",
        hrefPrefix: "tel:"
    },
    {
        key: "landline",
        IconComponent: CHANNEL_TYPE_MATCH_ICON.landline,
        bgcolor: "#CC7722",
        hrefPrefix: "tel:"
    }

    //  CHANNEL_TYPE_MATCH_ICON[channel.type] ||
    // CHANNEL_TYPE_MATCH_ICON[channel.phone_type]
    // {
    //     key: "twitter",
    //     icon: "twitterRounded"
    // },
    // {
    //     key: "facebook",
    //     icon: "facebook-icon"
    // }
];

export const ContactDetails = ({ user, sectionsParams, isDetailsExpanded, onStarUser }) => {
    const userName = useMemo(() => {
        if (!user) {
            return `Profile Hidden`;
        } else if (user?.first_name) {
            return `${user?.first_name} ${user?.last_name}`;
        } else {
            return formatPhoneNumber(user?.contacts?.phone_number) || user?.contacts?.email;
        }
    }, [user]);

    const [expandedFeaturesKeys, setExpandedFeaturesKeys] = useState<any>(
        sectionsParams.reduce((acc, section) => {
            if (section.defaultExpanded) {
                return {
                    ...acc,
                    [section.value]: true
                };
            }
            return acc;
        }, {})
    );

    const onStarClick = async () => {
        await onStarUser();
    };

    console.log("USER >>>>>>", user);

    return (
        <div className={classNames(styles["root"], !isDetailsExpanded && styles["root_expanded"])}>
            <div className={styles[`user-block`]}>
                <Avatar
                    avatarUrl={user?.profile_pic?.url}
                    status={user?.status}
                    user={user}
                    className={classNames(styles[`avatar`], isDetailsExpanded && styles["avatar_expanded"])}
                    statusDotClassName={styles[`avatar-status-dot`]}
                    anonimousUser={!user}
                />
                {/* <div className={styles[`user-block-name-wrapper`]}> */}
                <AnimatePresence>
                    <motion.div
                        className={styles[`user-block-name`]}
                        initial={{ opacity: 0, height: 0, scale: 0 }}
                        animate={isDetailsExpanded ? "open" : "closed"}
                        variants={nameVariants}
                    >
                        {userName}
                        {user && (
                            <Tooltip2
                                portalClassName={"mpd-simple-tooltip"}
                                hoverOpenDelay={300}
                                content={"Favorite"}
                                placement={Position.BOTTOM}
                                className={styles[`star-button-tooltip-wrapper`]}
                            >
                                <StyledButton
                                    onClick={onStarClick}
                                    IconComponent={RatingStarIcon}
                                    className={classNames(styles[`star-button`], !!user?.starred && styles["active"])}
                                />
                            </Tooltip2>
                        )}
                    </motion.div>
                </AnimatePresence>
                {!!user?.engagement_stats && (
                    <div className={styles["chart-wrapper"]}>
                        <ContactActivityChart data={user?.engagement_stats} />
                    </div>
                )}
                {/* </div> */}
            </div>
            {sectionsParams.map((section) => {
                const { value } = section;
                switch (value) {
                    case "contacts":
                        if (!!user?.channels?.length || !!user?.contacts) {
                            // return <div style={{ width: "100%", height: "100px", background: "red" }} />;
                            return (
                                <section>
                                    <TitleWIthDevider
                                        title={"CONTACT DETAILS"}
                                        index={0}
                                        prevStickyElemTop={52}
                                        height={54}
                                        id={"contactDetails"}
                                        onClick={() =>
                                            setExpandedFeaturesKeys({
                                                ...expandedFeaturesKeys,
                                                [value]: !expandedFeaturesKeys[value]
                                            })
                                        }
                                        arrowState={expandedFeaturesKeys[value]}
                                        isDetailsExpanded={isDetailsExpanded}
                                    />
                                    <Collapse
                                        isOpen={expandedFeaturesKeys[value]}
                                        className={classNames(
                                            styles[`contact-details`],
                                            !isDetailsExpanded && styles["contact-details_not-expanded"]
                                        )}
                                    >
                                        <div className={styles["collapse-content-wrapper"]}>
                                            {user.channels.map((channel, index) => {
                                                let href;
                                                let valueToDisplay = channel?.identifier;
                                                if (channel.type === "phone_number") {
                                                    href = `tel:${channel.identifier}`;
                                                    valueToDisplay = formatPhoneNumber(channel?.identifier);
                                                } else if (channel.type === "email") {
                                                    href = `mailto:${channel.identifier}`;
                                                }
                                                return (
                                                    <Fragment key={index}>
                                                        <a
                                                            href={href}
                                                            className={classNames(
                                                                styles[`${classname}-link`],
                                                                styles[`contact-details-item`]
                                                            )}
                                                        >
                                                            <div
                                                                className={styles[`contact-details-item-icon-wrapper`]}
                                                            >
                                                                <MPDIcon
                                                                    IconComponent={
                                                                        CHANNEL_TYPE_MATCH_ICON[channel.type] ||
                                                                        CHANNEL_TYPE_MATCH_ICON[channel.phone_type]
                                                                    }
                                                                />
                                                            </div>
                                                            {isDetailsExpanded && (
                                                                <Text className={styles[`contact-details-item-label`]}>
                                                                    {valueToDisplay}
                                                                </Text>
                                                            )}
                                                        </a>
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    </Collapse>
                                </section>
                            );
                        } else {
                            return null;
                        }
                    case "conversations":
                        return (
                            <section>
                                {isDetailsExpanded && user && (
                                    <>
                                        <TitleWIthDevider
                                            title={"PREVIOUS CONVERSATIONS"}
                                            index={1}
                                            prevStickyElemTop={user ? -2 : 52}
                                            height={54}
                                            id={"prevConversations"}
                                            onClick={() =>
                                                setExpandedFeaturesKeys({
                                                    ...expandedFeaturesKeys,
                                                    [value]: !expandedFeaturesKeys[value]
                                                })
                                            }
                                            arrowState={expandedFeaturesKeys[value]}
                                            isDetailsExpanded={isDetailsExpanded}
                                        />
                                        <Collapse isOpen={expandedFeaturesKeys[value]} keepChildrenMounted>
                                            <SenderPreviousConversations
                                                user={user}
                                                className={section.sectionClassName}
                                            />
                                        </Collapse>
                                    </>
                                )}
                            </section>
                        );
                    case "tags":
                        return (
                            <section>
                                <TitleWIthDevider
                                    title={"TAGS"}
                                    index={1}
                                    prevStickyElemTop={user ? -2 : 52}
                                    height={54}
                                    id={"prevConversations"}
                                    onClick={() =>
                                        setExpandedFeaturesKeys({
                                            ...expandedFeaturesKeys,
                                            [value]: !expandedFeaturesKeys[value]
                                        })
                                    }
                                    arrowState={expandedFeaturesKeys[value]}
                                    isDetailsExpanded={isDetailsExpanded}
                                />

                                <Collapse isOpen={expandedFeaturesKeys[value]}>
                                    <div
                                        className={classNames(
                                            styles["collapse-content-wrapper"],
                                            styles["tags-wrapper"]
                                        )}
                                    >
                                        {user.tags.map((tag) => {
                                            return <Tag label={tag.name} />;
                                        })}
                                    </div>
                                </Collapse>
                            </section>
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};
