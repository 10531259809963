import classNames from "classnames";
import React from "react";

import { MPDButton } from "src/mpd-library";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";

interface IProfileItemProps extends IProps {
    src?: string | ArrayBuffer | null;
    firstName: string;
    lastName: string;
    title: string;
}

export const ProfileItem: React.FunctionComponent<IProfileItemProps> = (props) => {
    const { className, src, firstName, lastName, title } = props;

    const classes = "sidebar-profile-item";

    return (
        <div className={classNames(classes, className)}>
            {src ? (
                <div
                    className={classes + "-icon"}
                    style={{
                        backgroundImage: `url(${src})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                />
            ) : (
                <Text className={classes + "-icon"}>
                    {" "}
                    {firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : ""}{" "}
                </Text>
            )}
            <MPDButton className={classes + "-button"} text={title} />
        </div>
    );
};
