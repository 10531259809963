import { useRef, useState } from "react";
import { Props } from "@blueprintjs/core";
import { Popover2, Popover2Props, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface ErrorTooltipProps extends Partial<Props>, Partial<Popover2Props> {
    tootltipTarget: JSX.Element;
    contentClassName: string;
}
const classname = "tooltip";

export const TooltipClsasses = {
    error: styles["error-tooltip"],
    primary: styles["primary-tooltip"]
};

export const Tooltip = ({
    tootltipTarget,
    content,
    contentClassName,
    disabled,
    ...remainPopoverProps
}: ErrorTooltipProps) => {
    const timerId = useRef<NodeJS.Timeout>();

    const [hovered, setHovered] = useState<boolean>(false);

    const onHover: VoidFunction = (): void => {
        if (disabled) {
            return;
        }
        clearTimeout(timerId.current);
        setHovered(true);
    };

    const onLeave: VoidFunction = (): void => {
        timerId.current = setTimeout(() => {
            setHovered(false);
        }, 150);
    };

    const onContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Tooltip2
            isOpen={hovered}
            content={
                <div
                    onClick={onContentClick}
                    onMouseEnter={onHover}
                    onMouseLeave={onLeave}
                    className={classNames(styles[`${classname}-content-wraper`])}
                >
                    {content}
                </div>
            }
            {...remainPopoverProps}
            className={classNames(styles["target"], remainPopoverProps.className)}
            portalClassName={classNames(styles["portal"], remainPopoverProps.portalClassName)}
            disabled={disabled}
            lazy
        >
            <div onMouseEnter={onHover} onMouseLeave={onLeave} className={styles["target-wrapper"]}>
                {tootltipTarget}
            </div>
        </Tooltip2>
    );
};
