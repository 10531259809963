import { useRef } from "react";
import "./styles.scss";
// import "prismjs/plugins/line-numbers/prism-line-numbers";

export const PrismCode = ({ code, plugins, language }) => {
    const ref = useRef();

    return (
        <pre className={!plugins ? "" : plugins.join(" ")}>
            <code ref={ref} className={`language-${language}`}>
                {code?.trim()}
            </code>
        </pre>
    );
};
