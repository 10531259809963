import { ProfileAutomationsSettingsState, defaultProfileAutomationsSettingsState } from "src/store/profile-automations";
import {
    SET_PROFILE_AUTOMATIONS_SETTINGS,
    ProfileAutomationsSettingsActionTypes
} from "../actions/profile-automations/types";

export const profileAutomationsReducer = (
    state: ProfileAutomationsSettingsState = defaultProfileAutomationsSettingsState(),
    action: ProfileAutomationsSettingsActionTypes
): ProfileAutomationsSettingsState => {
    switch (action.type) {
        case SET_PROFILE_AUTOMATIONS_SETTINGS:
            return {
                ...state,
                conversations_settings: {
                    ...action.payload
                }
            }
        default:
            return state;
    }
};
