import { createContext } from "react";
import { Route, Switch } from "react-router";
import { UseConversationsSnippets, useSnippets } from "src/containers/conversations/components/chat/hooks/useConversationsSnippets";
import { SnippetAdd } from "./snippets-add/snippets-add";
import { SnippetsList } from "./snippets-list/snippets-list";

export const SnippetsContext = createContext({} as UseConversationsSnippets);

export const SnippetsSettings = () => {
    const snippetProps = useSnippets();
    return (
        <Switch>
            <SnippetsContext.Provider value={snippetProps}>
                <Route path={"/account-settings/snippets"} exact render={() => <SnippetsList />} />
                <Route
                    path={"/account-settings/snippets/:id/edit"}
                    exact
                    render={() => <SnippetAdd />}
                />
                <Route path={"/account-settings/snippets/create"} render={() => <SnippetAdd />} />
            </SnippetsContext.Provider>
        </Switch>
    );
};
