import { cloneElement, useMemo } from "react";
import styles from "./styles.module.scss";

type SwitchingStepsAnimationProps = {
    whereToScroll: "bottom" | "top";
};

export const childFactory = (cssTransitionAnimationClasses) => (child) => {
    return cloneElement(child, {
        classNames: cssTransitionAnimationClasses
    });
};

export const useSwitchingStepsAnimation = ({ whereToScroll }: SwitchingStepsAnimationProps) => {

    const cssTransitionAnimationClasses = useMemo(
        () => ({
            enterActive: styles["fade-enter-active"],
            enter: whereToScroll === "bottom" ? styles["fade-from-bottom-enter"] : styles["fade-from-top-enter"],
            exitActive:
                whereToScroll === "bottom" ? styles["fade-exit-to-top-active"] : styles["fade-exit-to-bottom-active"],
            exit: styles["fade-exit"]
        }),
        [whereToScroll]
    );

    return cssTransitionAnimationClasses;
};
