import classNames from "classnames";
import styles from "./Target.module.scss";

export const DropdownTargetClasses = {
    SELECT: styles["select-target"],
    SELECT_PRIMARY: classNames(styles["select-target"], styles["select-target-primary"]),
    FORM_SELECT: styles["form-select-target"],
    SIMPLE: styles["simple-select-target"],
    FILTER_SELECT: styles["filter-select"],
    FILTER_COMPARISON_SELECT: styles["filter-comparison-select"],
    HALF_BUTTON: styles["half-button-target"],
    FORM_SELECT_TABLE: classNames(styles["form-select-target"], styles["form-select-table-target"]),
    EDIT_CHANNELS_COUNTRY_SELECT: styles["edit-channels-country-select-target"],
    EDIT_SELECT: styles["edit-select-target"],
    MORE: styles["more"]
};
