import { Position, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { Dropdown } from "../../mpd-library/dropdown/dropdown";
import styles from "./ListSort.module.scss";
import { DropdownTargetClasses } from "../../mpd-library/dropdown/components/target/constants";
import { StyledButton, StyledButtonTypes } from "../styled-button";
import { ReactComponent as RotationArrowIcon } from "src/mpd-library/icon/assets/rotationArrow.svg";
import { Option } from "../../mpd-library/dropdown/types";
import { useCallback, useState } from "react";

type ListSortProps = {
    sortByValues: Array<any>;
    sortValue: any;
    sortDirection: string;
    onSortSelect: (value: any) => void;
    onChange: any;
    // onSetSortDirection: (value: string) => void;
};

export const ListSort = ({ sortByValues, onChange }: ListSortProps) => {
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [sortValue, setSortValue] = useState<Option>(sortByValues[0]);

    const onSetSortDirection = useCallback(() => {
        const updatedSortDirection = sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(updatedSortDirection);
        onChange({ order: `${sortValue.value}:${updatedSortDirection}` });
    }, [sortDirection, sortValue]);

    const onSortSelect = useCallback(
        (option: Option) => {
            setSortValue(option);
            onChange({ order: `${option.value}:${sortDirection}` });
        },
        [sortDirection]
    );

    return (
        <div className={styles["sort"]}>
            <Dropdown
                position={Position.BOTTOM_RIGHT}
                contentBlockWidth={220}
                targetClassName={classNames(DropdownTargetClasses.SELECT, styles["sort-dropdown"])}
                selectedValue={sortValue}
                staticOptions={sortByValues}
                onStaticOptionClick={onSortSelect}
                leftLabel={"Sort By"}
                ArrowIconComponent
            />
            <StyledButton
                onClick={onSetSortDirection}
                className={classNames(styles["sort-direction-button"], sortDirection === "desc" && Classes.ACTIVE)}
                LeftIconComponent={RotationArrowIcon}
                type={StyledButtonTypes.secondary}
            />
        </div>
    );
};
