import { History } from "history";
import { Dispatch } from "redux";

import { IToastType, ToastTypes, ProgressToastCustomParams, isAuthenticated } from "src/components";
import { postMediaRequest } from "src/requests";
import { getMobileOperatingSystem } from "..";
import { AppState, TopBarProps } from "../../store";
import axios from "axios";
import {
    TOGGLE_RIGHTPANEL,
    UPDATE_NAV_CALLBACK,
    UPDATE_LOADING,
    TOGGLE_SIDEBAR,
    UPDATE_CHANGES_MADE,
    UPDATE_DEVICE_TYPE,
    UPDATE_NAV_PATH,
    UPDATE_IS_MOBILE_SIZE,
    UPDATE_TOAST,
    UPDATE_TOOL_BAR,
    UPDATE_WINDOW_HEIGHT,
    UPDATE_WINDOW_WIDTH,
    UPDATE_ALERT_OPEN,
    UPDATE_IS_TABLET_PORTRAIT_SIZE,
    UPDATE_GLOBAL_SPINNER_STATUS,
    SET_IS_APP_TAB_IN_FOCUS
} from "./types";
import { getUserSettings } from "../user-settings/user-settings-api";
import { getAccountSettingsDetails } from "../account-settings/account-settings-api";
import { setFirstAccessibleRoute, setInitialLoadingProcessingStatus } from "./global";
import { getFirstAccessibleRoute } from "../common/utils";
import { getSettingsCountries } from "../../requests";
import { setMainCountriesList } from "../account-settings/account-settings";

export const updateWindowWidth = (ref: { width: number }) => (dispatch: Dispatch<AppState>) => {
    const { width } = ref;
    dispatch({ type: UPDATE_WINDOW_WIDTH, payload: width });
    dispatch(updateIsMobileSize(ref));
    updateisOrSmallerTabletPortraitSize(ref);
};

export const updateLoading = (ref: { loading: boolean }) => (dispatch: Dispatch<AppState>) => {
    const { loading } = ref;
    dispatch({ type: UPDATE_LOADING, payload: loading });
};

export const updateIsMobileSize = (ref: { width: number }) => (dispatch: Dispatch<AppState>) => {
    const { width } = ref;
    const isMobileSize = width <= 450;
    dispatch({ type: UPDATE_IS_MOBILE_SIZE, payload: isMobileSize });
};

export const updateisOrSmallerTabletPortraitSize = (ref: { width: number }) => (dispatch: Dispatch<AppState>) => {
    const { width } = ref;
    const isMobileSize = width < 900;
    dispatch({ type: UPDATE_IS_TABLET_PORTRAIT_SIZE, payload: isMobileSize });
};

export const updateDeviceType = () => (dispatch: Dispatch<AppState>) => {
    const deviceType = getMobileOperatingSystem();
    dispatch({ type: UPDATE_DEVICE_TYPE, payload: deviceType });
};

export const updateWindowHeight = (ref: { height: number }) => (dispatch: Dispatch<AppState>) => {
    const { height } = ref;
    dispatch({ type: UPDATE_WINDOW_HEIGHT, payload: height });
};

export interface UpdateToolBarProps {
    topBarProps: TopBarProps;
}
export const updateToolBar = (ref: UpdateToolBarProps) => (dispatch: Dispatch<AppState>) => {
    const { topBarProps } = ref;
    dispatch({
        type: UPDATE_TOOL_BAR,
        payload: topBarProps
    });
};

export interface UpdateToastProps {
    toast: IToastType | ToastTypes;
}
export const updateToast = (ref: UpdateToastProps) => (dispatch: Dispatch<AppState>) => {
    const { toast } = ref;
    const newToast = JSON.parse(JSON.stringify(toast));
    newToast.active = true;
    dispatch({
        type: UPDATE_TOAST,
        payload: newToast
    });

    setTimeout(() => {
        const hiddenToast = JSON.parse(JSON.stringify(toast));
        hiddenToast.active = false;
        dispatch({
            type: UPDATE_TOAST,
            payload: hiddenToast
        });
    }, 5000);
};

export interface UpdateProgressToastProps {
    text: string;
    customParams: ProgressToastCustomParams;
}
export const updateProgressToast = (ref: UpdateProgressToastProps) => (dispatch: Dispatch<AppState>) => {
    const { text, customParams } = ref;
    dispatch({
        type: UPDATE_TOAST,
        payload: {
            value: text || "",
            type: "progress",
            active: "true",
            customParams
        }
    });
};

export const toggleMobileSideBar = (ref: { mobileSideBarActive: boolean }) => (dispatch: Dispatch<AppState>) => {
    const { mobileSideBarActive } = ref;
    dispatch({
        type: TOGGLE_SIDEBAR,
        payload: !mobileSideBarActive
    });
};

export const updateAlert = (ref: { alertOpen: boolean }) => (dispatch: Dispatch<AppState>) => {
    const { alertOpen } = ref;
    if (alertOpen !== undefined) {
        dispatch({
            type: UPDATE_ALERT_OPEN,
            payload: alertOpen
        });
    }
};

export interface UpdateNavPathProps {
    navPath: string;
    alertOpen?: boolean;
    func?: any;
}
export const updateNavPath = (ref: UpdateNavPathProps) => (dispatch: Dispatch<AppState>) => {
    const { navPath, alertOpen, func } = ref;
    dispatch({
        type: UPDATE_NAV_PATH,
        payload: navPath
    });
    dispatch({
        type: UPDATE_NAV_CALLBACK,
        payload: func
    });
    if (alertOpen) {
        dispatch(updateAlert({ alertOpen }));
    }
};

export const updateChangesMade = (ref: { changesMade: boolean }) => (dispatch: Dispatch<AppState>) => {
    const { changesMade } = ref;
    dispatch({
        type: UPDATE_CHANGES_MADE,
        payload: changesMade
    });
};

export const toggleRightPanel = (ref: { rightPanelExpanded: boolean }) => (dispatch: Dispatch<AppState>) => {
    const { rightPanelExpanded } = ref;
    dispatch({
        type: TOGGLE_RIGHTPANEL,
        payload: !rightPanelExpanded
    });
};

export interface NavigateProps {
    pathname: string;
    history: History;
    alertOpen?: boolean;
}

export const navigate = (ref: NavigateProps) => (dispatch: Dispatch<AppState>) => {
    const { alertOpen, pathname, history } = ref;
    if (pathname !== null) {
        history.push(pathname);
    }
    const changesMade = false;
    if (dispatch) {
        dispatch(updateChangesMade({ changesMade }));
    }
    if (alertOpen !== undefined) {
        dispatch(updateAlert({ alertOpen }));
    }
};

export const updateGlobalSpinnerStatus = (status: boolean) => (dispatch: Dispatch<AppState>) => {
    dispatch({
        type: UPDATE_GLOBAL_SPINNER_STATUS,
        payload: status
    });
};

interface UploadedFile {
    preview_url: string;
    uuid: string;
    presigned_url: string;
    file: File;
    url: string;
}

export const uploadFile = async (file: File): Promise<UploadedFile> => {
    const { name: filename, type } = file;

    const updatedFileObj = {
        filename,
        type
    };
    const response = await postMediaRequest(updatedFileObj);

    const { preview_url, presigned_url, uuid, url } = response.data;

    await axios.put(presigned_url, file, { headers: { "Content-Type": file.type } });

    return { preview_url, uuid, presigned_url, file, url };
};

export const setIsAppTabInFocus = (status: boolean) => {
    return {
        type: SET_IS_APP_TAB_IN_FOCUS,
        payload: status
    };
};

export const loadInitialData = () => async (dispatch) => {
    try {
        if (!isAuthenticated("auth")) {
            dispatch(setFirstAccessibleRoute("/signin"));
            return;
        }
        dispatch(setInitialLoadingProcessingStatus(true));

        const res = await Promise.all([
            dispatch(getUserSettings()),
            dispatch(getAccountSettingsDetails()),
            getSettingsCountries()
        ]);

        const userData = res[0];
        dispatch(setMainCountriesList(res[2].data.countries));
        if (userData.response?.status == 401) {
            dispatch(setFirstAccessibleRoute("/signin"));
            return;
        }
        const route = getFirstAccessibleRoute(userData.user.privileges);
        dispatch(setFirstAccessibleRoute(route));
    } catch (err) {
        console.log("ERR RRR >>>>", err);
    } finally {
        dispatch(setInitialLoadingProcessingStatus(false));
    }
};
