import classNames from "classnames";
import React from "react";

import { Alignment, IProps, Navbar, NavbarGroup } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

import { StyledToolBarTypes } from "./types";

import "./styles.scss";

export interface IStyledToolBarProps extends IProps {
    leftElement?: JSX.Element | null;
    centerElement?: JSX.Element;
    rightElement?: JSX.Element | null;
    type?: StyledToolBarTypes;
}

export class StyledToolBar extends React.PureComponent<IStyledToolBarProps, {}> {
    public render() {
        const { className, leftElement, centerElement, rightElement, type } = this.props;
        const classes = "styled-toolbar";

        if (!leftElement && !centerElement && !rightElement) {
            return null;
        }

        return (
            <Navbar className={classNames(classes, type, className)}>
                {leftElement && <NavbarGroup align={Alignment.LEFT}>{leftElement}</NavbarGroup>}
                {centerElement && (
                    <NavbarGroup align={Alignment.CENTER} className={Classes.ALIGN_CENTER}>
                        {centerElement}
                    </NavbarGroup>
                )}
                {rightElement && (
                    <NavbarGroup align={Alignment.RIGHT} className={"navbar-right-wrapper"}>
                        {rightElement}
                    </NavbarGroup>
                )}
            </Navbar>
        );
    }
}

export default StyledToolBar;
