import { ShapeCoords } from "src/requests";

export interface IncidentManagementIncidentTab {
    text: string;
    id: string;
    pathname: string;
}

export interface IncidentManagementChatParticipants {
    title: string;
    active: boolean;
    profile_color?: string;
    profile_url?: string;
}

export interface ChatAssigned {
    title: string;
    active: boolean;
    profile_color?: string;
    profile_url?: string;
}

export interface IncidentManagementContentLog {
    from?: string;
    to?: string;
    id: string;
    title: string;
    time: string;
}

export interface IncidentManagementTagList {
    action: string;
    title: string;
}

export interface IncidentManagementMarker {
    markerId: string;
    center: ShapeCoords;
    large: string;
    medium: string;
    small: string;
    type: string;
}

export interface IncidentManagementContentChat {
    dataType: string;
    type: string;
    tagged?: string;
    data: string;
    date: string;
    firstName: string;
    lastName: string;
}

export interface IncidentManagementConversationLog {
    id: string;
    title: string;
    time: string;
}

export interface IncidentManagementConversationTag {
    action: string;
    title: string;
}

export interface IncidentManagementConversationChat {
    dataType: string;
    type: string;
    data: string;
    date: string;
    firstName: string;
    lastName: string;
}

export interface IncidentManagementConversation {
    id: string;
    description: string;
    unreadMessages: string;
    date: string;
    subject: string;
    type: string;
    location: string;
    status: string;
    lastUpdated: string;
    priority: string;
    channel: string;
    firstName: string;
    lastName: string;
    chat: Array<IncidentManagementConversationChat>;
    log?: Array<IncidentManagementConversationLog>;
    tagList?: Array<IncidentManagementConversationTag>;
    reporter?: Array<IncidentManagementReporter>;
}

export interface IncidentManagementReporter {
    firstName: string;
    lastName: string;
    initials: string;
    profile_color?: string;
    email: string;
    phone: string;
    conversations: Array<IncidentManagementConversation>;
}

export interface IncidentManagementContent {
    id: string;
    type: string;
    subject: string;
    status: string;
    channel: string;
    lastUpdated: string;
    date: string;
    priority: string;
    priority_color?: string;
    location: string;
    description: string;
    unreadMessages: string;
    chatParticipants: Array<IncidentManagementChatParticipants>;
    chatAssigned: Array<ChatAssigned>;
    log: Array<IncidentManagementContentLog>;
    tagList: Array<IncidentManagementTagList>;
    image: string;
    marker: IncidentManagementMarker;
    chat: Array<IncidentManagementContentChat>;
    reporter: IncidentManagementReporter;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    center?: any;
    checked?: boolean;
}

export interface IncidentManagementMyUnsolvedTickets {
    tabs: Array<IncidentManagementIncidentTab>;
    selectedId: string;
    center: ShapeCoords;
    conversations: Array<IncidentManagementConversation>;
    content: Array<IncidentManagementContent>;
}

export interface IncidentManagementState {
    rightPanelExpanded: boolean;
    chatActive: boolean;
    preventToggle: boolean;
    openedRoute: string;
    myUnsolvedTickets: IncidentManagementMyUnsolvedTickets;
}

export const defaultIncidentManagementState: IncidentManagementState = {
    rightPanelExpanded: false,
    chatActive: false,
    preventToggle: false,
    openedRoute: "/incidents/my-unsolved-tickets",
    myUnsolvedTickets: {
        tabs: [
            {
                text: "My unsolved tickets",
                id: "0",
                pathname: "/incidents/my-unsolved-tickets"
            }
        ],
        selectedId: "100",
        center: {
            lat: 30.262034,
            lng: -97.719896
        },
        conversations: [
            {
                id: "1001",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                date: "1557334081",
                subject: "Suspicious Activity",
                type: "CRIME TIP",
                location: "2025 East 7th Street",
                status: "new",
                lastUpdated: "1557334081",
                priority: "P1",
                channel: "spot",
                firstName: "Kushyar",
                lastName: "Kasrae",
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ]
            },
            {
                id: "1002",
                description: "There are two gang members spotted shooting their guns in the neighbourhood",
                unreadMessages: "1",
                date: "1559063477",
                subject: "Shots Fired",
                type: "CRIME TIP",
                location: "2025 East 7th Street",
                status: "new",
                lastUpdated: "1m ago",
                priority: "P3",
                channel: "spot",
                firstName: "Kushyar",
                lastName: "Kasrae",
                log: [
                    {
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        id: "assignedCase",
                        title: "Assigned Case #41291 to Jamieson Johnson",
                        time: "8:42 pm"
                    },
                    {
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    },
                    {
                        id: "forwardedCase",
                        title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                        time: "7:44 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ]
            },
            {
                id: "1003",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                date: "1557334081",
                subject: "Pothole",
                type: "311",
                location: "2025 East 7th Street",
                status: "new",
                lastUpdated: "1557334081",
                priority: "P1",
                channel: "spot",
                firstName: "Kushyar",
                lastName: "Kasrae",
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ]
            }
        ],
        content: [
            {
                id: "129",
                type: "CRIME TIP",
                subject: "Suspicious Package",
                status: "new",
                channel: "spot",
                lastUpdated: "1563849802",
                date: "1566775590",
                priority: "P3",
                priority_color: "#00CEC95",
                location: "2025 East 7th Street, Austin, TX 78702",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                chatParticipants: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: true,
                        profile_color: "#F28622",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: true,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                chatAssigned: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#F28622",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                log: [
                    {
                        from: "Frank",
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        from: "Kush",
                        to: "Jamie",
                        id: "assignedCase",
                        title: "Assigned Case #41291",
                        time: "8:42 pm"
                    },
                    {
                        from: "Jamie",
                        to: "",
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        from: "Kush",
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        from: "Arian",
                        to: "Frank",
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                image:
                    "https://cdn.cnn.com/cnnnext/dam/assets/181029155347-suspicious-package-cnn-center-atlanta-large-169.jpg",
                marker: {
                    markerId: "100",
                    center: {
                        lat: 30.26157,
                        lng: -97.71947
                    },
                    large:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=840x173&markers=size:mid|color:0x1f76ff|41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    medium:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=324x207&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    small:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=277x120&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    type: "red"
                },
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "image",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/hurricane-earth-satellite-tracking-71116.png?alt=media&token=4abf6a83-902d-4ea5-978e-5627b8b8417a",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "????",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Leave me alone",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "video",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/VID_20190520_153601.mp4?alt=media&token=22d6cd0c-f1df-4c95-8139-c6cdc7175d7f",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "sarah",
                        data: "can you take a look at this?",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "kushyar",
                        data: "no",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ],
                reporter: {
                    firstName: "John",
                    lastName: "Doe",
                    initials: "jd",
                    profile_color: "#4496F6",
                    email: "John@gmail.com",
                    phone: "(512) 928-9218",
                    conversations: [
                        {
                            id: "1001",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Suspicious Activity",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1002",
                            description: "There are two gang members spotted shooting their guns in the neighbourhood",
                            unreadMessages: "1",
                            date: "1559063477",
                            subject: "Shots Fired",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1m ago",
                            priority: "P3",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            log: [
                                {
                                    id: "viewedCase",
                                    title: "Viewed Case #419219",
                                    time: "5 mins ago"
                                },
                                {
                                    id: "assignedCase",
                                    title: "Assigned Case #41291 to Jamieson Johnson",
                                    time: "8:42 pm"
                                },
                                {
                                    id: "changedStatus",
                                    title: "Changed the status of Case #41291 from Open to Pending",
                                    time: "8:41 pm"
                                },
                                {
                                    id: "repliedTo",
                                    title: "Replied to User Name on Case #41291",
                                    time: "8:40 pm"
                                },
                                {
                                    id: "leftNote",
                                    title: "Left a Note on Case #41291",
                                    time: "7:59 pm"
                                },
                                {
                                    id: "forwardedCase",
                                    title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                                    time: "7:44 pm"
                                }
                            ],
                            tagList: [
                                {
                                    action: "arrest",
                                    title: "Arrest"
                                },
                                {
                                    action: "dispatched",
                                    title: "Dispatched"
                                }
                            ],
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1003",
                            description: "Kids are you smoking weed in the park again",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Pothole",
                            type: "311",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "130",
                type: "NG911",
                subject: "Police",
                description: "There are two gang members spotted shooting their guns in the neighbourhood",
                unreadMessages: "1",
                date: "1563826929",
                location: "2000 East 4th Street, Austin, TX 78702",
                status: "pending",
                lastUpdated: "1563827469",
                priority: "P1",
                channel: "spot",
                chatParticipants: [
                    {
                        title: "Jamieson Johnson",
                        active: true
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: true
                    },
                    {
                        title: "Arian Taj",
                        active: false
                    }
                ],
                chatAssigned: [
                    {
                        title: "Jamieson Johnson",
                        active: false
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: true
                    },
                    {
                        title: "Arian Taj",
                        active: false
                    }
                ],
                log: [
                    {
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        id: "assignedCase",
                        title: "Assigned Case #41291 to Jamieson Johnson",
                        time: "8:42 pm"
                    },
                    {
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    },
                    {
                        id: "forwardedCase",
                        title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                        time: "7:44 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                image: "",
                marker: {
                    markerId: "101",
                    center: {
                        lat: 30.25932,
                        lng: -97.72152
                    },
                    large:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=840x173&markers=size:mid|color:0x1f76ff|41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    medium:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=324x207&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    small:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=277x120&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    type: "blue"
                },
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "image",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/hurricane-earth-satellite-tracking-71116.png?alt=media&token=4abf6a83-902d-4ea5-978e-5627b8b8417a",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "????",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Leave me alone",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "video",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/VID_20190520_153601.mp4?alt=media&token=22d6cd0c-f1df-4c95-8139-c6cdc7175d7f",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "sarah",
                        data: "can you take a look at this?",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "kushyar",
                        data: "no",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ],
                reporter: {
                    firstName: "Sarah",
                    lastName: "Smith",
                    initials: "ss",
                    email: "Sarah@gmail.com",
                    phone: "(512) 928-9260",
                    conversations: [
                        {
                            id: "2000",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Suspicious Activity",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "2001",
                            description: "There are two gang members spotted shooting their guns in the neighbourhood",
                            unreadMessages: "1",
                            date: "1559063477",
                            subject: "Shots Fired",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1m ago",
                            priority: "P3",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            log: [
                                {
                                    id: "viewedCase",
                                    title: "Viewed Case #419219",
                                    time: "5 mins ago"
                                },
                                {
                                    id: "assignedCase",
                                    title: "Assigned Case #41291 to Jamieson Johnson",
                                    time: "8:42 pm"
                                },
                                {
                                    id: "changedStatus",
                                    title: "Changed the status of Case #41291 from Open to Pending",
                                    time: "8:41 pm"
                                },
                                {
                                    id: "repliedTo",
                                    title: "Replied to User Name on Case #41291",
                                    time: "8:40 pm"
                                },
                                {
                                    id: "leftNote",
                                    title: "Left a Note on Case #41291",
                                    time: "7:59 pm"
                                },
                                {
                                    id: "forwardedCase",
                                    title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                                    time: "7:44 pm"
                                }
                            ],
                            tagList: [
                                {
                                    action: "arrest",
                                    title: "Arrest"
                                },
                                {
                                    action: "dispatched",
                                    title: "Dispatched"
                                }
                            ],
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "2003",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "testing",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "131",
                type: "CRIME TIP",
                subject: "Drugs",
                status: "open",
                channel: "spot",
                date: "1563811102",
                lastUpdated: "1563812289",
                priority: "P2",
                priority_color: "#FDCB6E",
                location: "280 Congress Avenue, Austin, TX 78701",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                chatParticipants: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: true,
                        profile_color: "#F28622",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                chatAssigned: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                log: [
                    {
                        from: "Frank",
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        from: "Kush",
                        to: "Jamie",
                        id: "assignedCase",
                        title: "Assigned Case #41291",
                        time: "8:42 pm"
                    },
                    {
                        from: "Jamie",
                        to: "",
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        from: "Kush",
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        from: "Arian",
                        to: "Frank",
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                image:
                    "https://www.wthr.com/sites/default/files/styles/article_image/public/2019/03/28/marijuana.png?itok=BBKGhnu5",
                marker: {
                    markerId: "102",
                    center: {
                        lat: 30.26525,
                        lng: -97.74378
                    },
                    large:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=840x173&markers=size:mid|color:0x1f76ff|41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    medium:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=324x207&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    small:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=277x120&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    type: "red"
                },
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "image",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/hurricane-earth-satellite-tracking-71116.png?alt=media&token=4abf6a83-902d-4ea5-978e-5627b8b8417a",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "????",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Leave me alone",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "video",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/VID_20190520_153601.mp4?alt=media&token=22d6cd0c-f1df-4c95-8139-c6cdc7175d7f",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "sarah",
                        data: "can you take a look at this?",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "kushyar",
                        data: "no",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ],
                reporter: {
                    firstName: "John",
                    lastName: "Doe",
                    initials: "jd",
                    profile_color: "#4496F6",
                    email: "John@gmail.com",
                    phone: "(512) 928-9218",
                    conversations: [
                        {
                            id: "1001",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Suspicious Activity",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1002",
                            description: "There are two gang members spotted shooting their guns in the neighbourhood",
                            unreadMessages: "1",
                            date: "1559063477",
                            subject: "Shots Fired",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1m ago",
                            priority: "P3",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            log: [
                                {
                                    id: "viewedCase",
                                    title: "Viewed Case #419219",
                                    time: "5 mins ago"
                                },
                                {
                                    id: "assignedCase",
                                    title: "Assigned Case #41291 to Jamieson Johnson",
                                    time: "8:42 pm"
                                },
                                {
                                    id: "changedStatus",
                                    title: "Changed the status of Case #41291 from Open to Pending",
                                    time: "8:41 pm"
                                },
                                {
                                    id: "repliedTo",
                                    title: "Replied to User Name on Case #41291",
                                    time: "8:40 pm"
                                },
                                {
                                    id: "leftNote",
                                    title: "Left a Note on Case #41291",
                                    time: "7:59 pm"
                                },
                                {
                                    id: "forwardedCase",
                                    title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                                    time: "7:44 pm"
                                }
                            ],
                            tagList: [
                                {
                                    action: "arrest",
                                    title: "Arrest"
                                },
                                {
                                    action: "dispatched",
                                    title: "Dispatched"
                                }
                            ],
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1003",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Pothole",
                            type: "311",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "133",
                type: "NG911",
                subject: "Fire",
                status: "open",
                channel: "spot",
                date: "1563654562",
                lastUpdated: "1563655462",
                priority: "P1",
                priority_color: "#FF7675",
                location: "331 Rainey Street, Austin, TX 78701",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                chatParticipants: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: true,
                        profile_color: "#F28622",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: true,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                chatAssigned: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                log: [
                    {
                        from: "Frank",
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        from: "Kush",
                        to: "Jamie",
                        id: "assignedCase",
                        title: "Assigned Case #41291",
                        time: "8:42 pm"
                    },
                    {
                        from: "Jamie",
                        to: "",
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        from: "Kush",
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        from: "Arian",
                        to: "Frank",
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                image:
                    "https://bloximages.chicago2.vip.townnews.com/nwitimes.com/content/tncms/assets/v3/editorial/d/37/d3715466-18a3-5fd3-8004-d2dd11ae2221/5c5e1be4e7a69.image.jpg",
                marker: {
                    markerId: "103",
                    center: {
                        lat: 30.2579,
                        lng: -97.73909
                    },
                    large:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=840x173&markers=size:mid|color:0x1f76ff|41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    medium:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=324x207&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    small:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=277x120&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    type: "red"
                },
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "image",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/hurricane-earth-satellite-tracking-71116.png?alt=media&token=4abf6a83-902d-4ea5-978e-5627b8b8417a",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "????",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Leave me alone",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "video",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/VID_20190520_153601.mp4?alt=media&token=22d6cd0c-f1df-4c95-8139-c6cdc7175d7f",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "sarah",
                        data: "can you take a look at this?",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "kushyar",
                        data: "no",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ],
                reporter: {
                    firstName: "Jason",
                    lastName: "Gonzalez",
                    initials: "jg",
                    profile_color: "#23AEC1",
                    email: "Jason@gmail.com",
                    phone: "(512) 928-9218",
                    conversations: [
                        {
                            id: "1001",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Suspicious Activity",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1002",
                            description: "There are two gang members spotted shooting their guns in the neighbourhood",
                            unreadMessages: "1",
                            date: "1559063477",
                            subject: "Shots Fired",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1m ago",
                            priority: "P3",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            log: [
                                {
                                    id: "viewedCase",
                                    title: "Viewed Case #419219",
                                    time: "5 mins ago"
                                },
                                {
                                    id: "assignedCase",
                                    title: "Assigned Case #41291 to Jamieson Johnson",
                                    time: "8:42 pm"
                                },
                                {
                                    id: "changedStatus",
                                    title: "Changed the status of Case #41291 from Open to Pending",
                                    time: "8:41 pm"
                                },
                                {
                                    id: "repliedTo",
                                    title: "Replied to User Name on Case #41291",
                                    time: "8:40 pm"
                                },
                                {
                                    id: "leftNote",
                                    title: "Left a Note on Case #41291",
                                    time: "7:59 pm"
                                },
                                {
                                    id: "forwardedCase",
                                    title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                                    time: "7:44 pm"
                                }
                            ],
                            tagList: [
                                {
                                    action: "arrest",
                                    title: "Arrest"
                                },
                                {
                                    action: "dispatched",
                                    title: "Dispatched"
                                }
                            ],
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1003",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Pothole",
                            type: "311",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "134",
                type: "Crime Tip",
                subject: "Noise Complaint",
                status: "new",
                channel: "spot",
                date: "1563621682",
                lastUpdated: "1563623362",
                priority: "P4",
                priority_color: "#FF7675",
                location: "1400 Springdale Ave, Austin, TX 78721",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                chatParticipants: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#F28622",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                chatAssigned: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                log: [
                    {
                        from: "Frank",
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        from: "Kush",
                        to: "Jamie",
                        id: "assignedCase",
                        title: "Assigned Case #41291",
                        time: "8:42 pm"
                    },
                    {
                        from: "Jamie",
                        to: "",
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        from: "Kush",
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        from: "Arian",
                        to: "Frank",
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                image: "",
                marker: {
                    markerId: "104",
                    center: {
                        lat: 30.26742,
                        lng: -97.69436
                    },
                    large:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=840x173&markers=size:mid|color:0x1f76ff|41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    medium:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=324x207&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    small:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=277x120&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    type: "red"
                },
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "image",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/hurricane-earth-satellite-tracking-71116.png?alt=media&token=4abf6a83-902d-4ea5-978e-5627b8b8417a",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "????",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Leave me alone",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "video",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/VID_20190520_153601.mp4?alt=media&token=22d6cd0c-f1df-4c95-8139-c6cdc7175d7f",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "sarah",
                        data: "can you take a look at this?",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "kushyar",
                        data: "no",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ],
                reporter: {
                    firstName: "Jason",
                    lastName: "Gonzalez",
                    initials: "jg",
                    profile_color: "#23AEC1",
                    email: "Jason@gmail.com",
                    phone: "(512) 928-9218",
                    conversations: [
                        {
                            id: "1001",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Suspicious Activity",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1002",
                            description: "There are two gang members spotted shooting their guns in the neighbourhood",
                            unreadMessages: "1",
                            date: "1559063477",
                            subject: "Shots Fired",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1m ago",
                            priority: "P3",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            log: [
                                {
                                    id: "viewedCase",
                                    title: "Viewed Case #419219",
                                    time: "5 mins ago"
                                },
                                {
                                    id: "assignedCase",
                                    title: "Assigned Case #41291 to Jamieson Johnson",
                                    time: "8:42 pm"
                                },
                                {
                                    id: "changedStatus",
                                    title: "Changed the status of Case #41291 from Open to Pending",
                                    time: "8:41 pm"
                                },
                                {
                                    id: "repliedTo",
                                    title: "Replied to User Name on Case #41291",
                                    time: "8:40 pm"
                                },
                                {
                                    id: "leftNote",
                                    title: "Left a Note on Case #41291",
                                    time: "7:59 pm"
                                },
                                {
                                    id: "forwardedCase",
                                    title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                                    time: "7:44 pm"
                                }
                            ],
                            tagList: [
                                {
                                    action: "arrest",
                                    title: "Arrest"
                                },
                                {
                                    action: "dispatched",
                                    title: "Dispatched"
                                }
                            ],
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1003",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Pothole",
                            type: "311",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "135",
                type: "NG911",
                subject: "EMS",
                status: "new",
                channel: "spot",
                date: "1563612262",
                lastUpdated: "1563612562",
                priority: "P2",
                priority_color: "#FDCB6E",
                location: "1200 Cesar Chavez, Austin, TX 78702",
                description: "Kids are you smoking weed in the park again.",
                unreadMessages: "1",
                chatParticipants: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#F28622",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                chatAssigned: [
                    {
                        title: "Jamieson Johnson",
                        active: true,
                        profile_color: "#22A7F2",
                        profile_url: ""
                    },
                    {
                        title: "Kushyar Kasraie",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    },
                    {
                        title: "Arian Taj",
                        active: false,
                        profile_color: "#22B4F2",
                        profile_url: ""
                    }
                ],
                log: [
                    {
                        from: "Frank",
                        id: "viewedCase",
                        title: "Viewed Case #419219",
                        time: "5 mins ago"
                    },
                    {
                        from: "Kush",
                        to: "Jamie",
                        id: "assignedCase",
                        title: "Assigned Case #41291",
                        time: "8:42 pm"
                    },
                    {
                        from: "Jamie",
                        to: "",
                        id: "changedStatus",
                        title: "Changed the status of Case #41291 from Open to Pending",
                        time: "8:41 pm"
                    },
                    {
                        from: "Kush",
                        id: "repliedTo",
                        title: "Replied to User Name on Case #41291",
                        time: "8:40 pm"
                    },
                    {
                        from: "Arian",
                        to: "Frank",
                        id: "leftNote",
                        title: "Left a Note on Case #41291",
                        time: "7:59 pm"
                    }
                ],
                tagList: [
                    {
                        action: "arrest",
                        title: "Arrest"
                    },
                    {
                        action: "dispatched",
                        title: "Dispatched"
                    }
                ],
                image:
                    "https://thenypost.files.wordpress.com/2018/11/051118-cpr-studies-women.jpg?quality=90&strip=all&w=618&h=410&crop=1",
                marker: {
                    markerId: "105",
                    center: {
                        lat: 30.26013,
                        lng: -97.73232
                    },
                    large:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=840x173&markers=size:mid|color:0x1f76ff|41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    medium:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=324x207&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    small:
                        "https://maps.googleapis.com/maps/api/staticmap?&size=277x120&markers=41.903769090334826,-87.6530107727051&key=AIzaSyAHLS01jiagWneMkc_W4-zQx-2MgtvWt14",
                    type: "red"
                },
                chat: [
                    {
                        dataType: "reply",
                        type: "text",
                        data: "I want to report a drug problem in my neighborhood.",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Hi Kush, what would you like to report?",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "image",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/hurricane-earth-satellite-tracking-71116.png?alt=media&token=4abf6a83-902d-4ea5-978e-5627b8b8417a",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "????",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    },
                    {
                        dataType: "reply",
                        type: "text",
                        data: "Leave me alone",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "reply",
                        type: "video",
                        data:
                            "https://firebasestorage.googleapis.com/v0/b/public-portal-development.appspot.com/o/VID_20190520_153601.mp4?alt=media&token=22d6cd0c-f1df-4c95-8139-c6cdc7175d7f",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "sarah",
                        data: "can you take a look at this?",
                        date: "1559063477",
                        firstName: "Kushyar",
                        lastName: "Kasrae"
                    },
                    {
                        dataType: "note",
                        type: "text",
                        tagged: "kushyar",
                        data: "no",
                        date: "1559063477",
                        firstName: "Sarah",
                        lastName: "Veshlogin"
                    }
                ],
                reporter: {
                    firstName: "Jason",
                    lastName: "Gonzalez",
                    initials: "jg",
                    profile_color: "#23AEC1",
                    email: "Jason@gmail.com",
                    phone: "(512) 928-9218",
                    conversations: [
                        {
                            id: "1001",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Suspicious Activity",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1002",
                            description: "There are two gang members spotted shooting their guns in the neighbourhood",
                            unreadMessages: "1",
                            date: "1559063477",
                            subject: "Shots Fired",
                            type: "CRIME TIP",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1m ago",
                            priority: "P3",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            log: [
                                {
                                    id: "viewedCase",
                                    title: "Viewed Case #419219",
                                    time: "5 mins ago"
                                },
                                {
                                    id: "assignedCase",
                                    title: "Assigned Case #41291 to Jamieson Johnson",
                                    time: "8:42 pm"
                                },
                                {
                                    id: "changedStatus",
                                    title: "Changed the status of Case #41291 from Open to Pending",
                                    time: "8:41 pm"
                                },
                                {
                                    id: "repliedTo",
                                    title: "Replied to User Name on Case #41291",
                                    time: "8:40 pm"
                                },
                                {
                                    id: "leftNote",
                                    title: "Left a Note on Case #41291",
                                    time: "7:59 pm"
                                },
                                {
                                    id: "forwardedCase",
                                    title: "Forwarded Case #41291 to jamieson@mobilepd.com",
                                    time: "7:44 pm"
                                }
                            ],
                            tagList: [
                                {
                                    action: "arrest",
                                    title: "Arrest"
                                },
                                {
                                    action: "dispatched",
                                    title: "Dispatched"
                                }
                            ],
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        },
                        {
                            id: "1003",
                            description: "Kids are you smoking weed in the park again.",
                            unreadMessages: "1",
                            date: "1557334081",
                            subject: "Pothole",
                            type: "311",
                            location: "2025 East 7th Street",
                            status: "new",
                            lastUpdated: "1557334081",
                            priority: "P1",
                            channel: "spot",
                            firstName: "Kushyar",
                            lastName: "Kasrae",
                            chat: [
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "I want to report a drug problem in my neighborhood.",
                                    date: "1559063477",
                                    firstName: "Kushyar",
                                    lastName: "Kasrae"
                                },
                                {
                                    dataType: "reply",
                                    type: "text",
                                    data: "Hi Kush, what would you like to report?",
                                    date: "1559063477",
                                    firstName: "Sarah",
                                    lastName: "Veshlogin"
                                }
                            ]
                        }
                    ]
                }
            }
        ]
    }
};
