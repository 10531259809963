import { useEffect, useState } from "react";
import { FormikValues } from "formik";
import { useHistory } from "react-router";
import { CSSTransition } from "react-transition-group";
import differenceBy from "lodash/differenceBy";
import isEqualWith from "lodash/isEqualWith";
import pluralize from "pluralize";
import { StyledButton, StyledButtonTypes } from "../styled-button";
import styles from "./FormWasChanged.module.scss";

type FormWasChangedProps = {
    formik: FormikValues;
    disableCounter?: boolean;
};

export const FormWasChanged = ({
    formik,
    disableCounter,
    onCancelClick,
    setCurrentUser,
    setCurrentRole
}: FormWasChangedProps) => {
    const [counter, setCounter] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (disableCounter) {
            return;
        }

        let updatedCounter = 0;

        formik?.values &&
            Object.keys(formik?.values).forEach((fieldName: string) => {
                const initialValue = formik.getFieldMeta(fieldName).initialValue;
                const currentValue = formik.getFieldMeta(fieldName).value;
                const counterSaved = updatedCounter;
                if (currentValue?.id !== initialValue?.id) {
                    updatedCounter = updatedCounter + 1;
                } else if (currentValue?.value !== initialValue?.value) {
                    updatedCounter = updatedCounter + 1;
                    // for strings
                } else if (currentValue !== initialValue) {
                    // for resolve case when need to compare null and empty string
                    if (!currentValue && !initialValue && !!currentValue === !!initialValue) {
                        return;
                    }
                    updatedCounter = updatedCounter + 1;
                } else if (
                    Array.isArray(currentValue) &&
                    Array.isArray(initialValue) &&
                    !isEqualWith(currentValue, initialValue, (currentValue, initialValue) => {
                        if (currentValue?.length !== initialValue?.length) {
                            return false;
                        } else if (
                            currentValue?.some((item) => {
                                return !initialValue.find((initialItem) => initialItem.id === item.id);
                            })
                        ) {
                            return false;
                        } else return true;
                    })
                ) {
                    updatedCounter = updatedCounter + 1;
                }

                if (updatedCounter - counterSaved > 0) {
                    console.log(`${fieldName} <<<< was changed`, updatedCounter - counterSaved);
                }
            });

        setCounter(updatedCounter);
    }, [formik.values, formik.initialValues]);

    const cancelClick = () => {
        if (!!onCancelClick) {
            onCancelClick();
        } else if (disableCounter) {
            history.goBack();
        }
        formik.resetForm();
    };

    const onSaveClick = async () => {
        await formik.handleSubmit();
    };

    return (
        <CSSTransition
            in={counter || disableCounter}
            timeout={300}
            classNames={{
                enter: styles["form-was-changed-enter"],
                enterActive: styles["form-was-changed-enter-active"],
                exit: styles["form-was-changed-exit"],
                exitActive: styles["form-was-changed-exit-active"]
            }}
            unmountOnExit
            style={{ zIndex: 23 }}
        >
            <div className={styles["root"]}>
                {!disableCounter && (
                    <span className={styles["counter"]}>{`You changed ${!!counter ? counter : 1} ${pluralize(
                        "property",
                        !!counter ? counter : 1
                    )}`}</span>
                )}
                <StyledButton
                    type={StyledButtonTypes.secondary}
                    text={"Cancel"}
                    onClick={() => {
                        cancelClick();
                        if (setCurrentUser) {
                            setCurrentUser();
                        }
                        if (setCurrentRole) {
                            setCurrentRole();
                        }
                    }}
                />
                <StyledButton
                    disabled={!formik.isValid}
                    type={StyledButtonTypes.primary}
                    text={"Save"}
                    onClick={onSaveClick}
                    processing={formik.isSubmitting}
                />
            </div>
        </CSSTransition>
    );
};
