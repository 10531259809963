// type Config = {
//     idPath: string;
//     labelPartsPaths: Array<string>;
//     labelPath: string;
// };

import { ComponentType } from "react";

export const dropdownListItemTransformer = ({
    idPath = "id",
    leftIconPath,
    LeftIconComponent,
    labelPartsPaths,
    labelPath,
    options
}: {
    idPath?: string;
    leftIconPath?: string;
    LeftIconComponent?: ComponentType<{ option: Required<{ id: string }> }>;
    labelPartsPaths?: Array<string>;
    labelPath?: string;
    options: Array<Required<{ id: string }>>;
}) => {
    return options?.map((option) => ({
        ...option,
        id: option[idPath],
        LeftIconComponent: () => <LeftIconComponent option={option} />,
        label: labelPath
            ? option[labelPath]
            : labelPartsPaths.reduce((acc: string, path: string) => acc + " " + option[path], "").trim(),
        value: option
    }));
};
