import { FC, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { NavigationListItems } from "../../../components";
import { getContactsSummary } from "../../../requests/contactsRequests";
import { getFittedFilters } from "../utils";
import { SummaryContext, useQuery } from "../contacts";
import { AppState } from "../../../store";
import { removeWsData } from "../../../actions/websocket/websocket";
import { ContactsContext } from "../contacts-list/context";

const summaryOrderArray = ["views", "lists", "tags"];
const event = "contacts_summary_counts";
const topic = "portal_admin_general";

export const convertSummary = (summary) => {
    return Object.keys(summary).reduce((acc, groupKey) => {
        const group = summary[groupKey];
        return {
            ...acc,
            [groupKey]: group.map((item) => ({
                ...item,
                title: item.name,
                value: item.name
            }))
        };
    }, {});
};

type ContactsGroupProps = {
    parentFilter?: any;
    setBreadCrumbsList: (value: any) => void;
};

export const ContactsGroup: FC<{}> = memo(({ setBreadCrumbsList, parentFilter }: ContactsGroupProps): JSX.Element => {
    const { summary: notConvertedSummary, setSummary } = useContext(SummaryContext);
    const dispatch = useDispatch();
    const { setSavedView, setPresettedFilter, setList, presettedFilter } = useContext(ContactsContext);
    const [selectedTabValue, setSelectedTabValue] = useState<string>("");
    const [getSummaryProcessing, setGetSummaryProcessing] = useState<boolean>(true);
    const { counts } = useSelector((state: AppState) => state.websocketReducer.wsData?.[topic]?.[event] || {});
    const queryRef = useRef<null | string>(null);
    const history = useHistory();

    useEffect(() => {
        if (!isEmpty(counts)) {
            const updatedSummary = Object.keys(counts).reduce((acc, key) => {
                const groupCounts = counts[key];
                return {
                    ...acc,
                    [key]: notConvertedSummary[key]?.map((summaryItem) => {
                        const findedItem = groupCounts.find((a) => a.id == summaryItem.id);
                        if (findedItem) {
                            return {
                                ...summaryItem,
                                ...findedItem
                            };
                        }
                        return summaryItem;
                    })
                };
            }, {});
            setSummary(updatedSummary);
            dispatch(removeWsData(`${topic}.${event}`));
        }
    }, [counts]);

    const summary = useMemo(() => {
        if (notConvertedSummary) {
            return convertSummary(notConvertedSummary);
        }
    }, [notConvertedSummary]);

    const findAndSetSelectedTab = (summary) => {
        let searchableItem;
        let keyOfItem;
        Object.keys(summary).forEach((key: string) => {
            if (searchableItem) {
                return;
            }

            searchableItem = summary[key].find((item) => {
                console.log("CONDITION >>>>", item, parentFilter.id);
                return item.id === selectedTabValue;
            });

            if (searchableItem) {
                keyOfItem = key;
            }
        });

        console.log("VIEWS >>>>", searchableItem, summary.views);

        if (searchableItem) {
            setSelectedTabValue(searchableItem.id);
            keyOfItem === "views" && setSavedView(!searchableItem.is_default && searchableItem);
            keyOfItem === "lists" && setList(searchableItem);
        } else {
            setSelectedTabValue(summary.views?.[0].id);
        }
    };

    console.log("selectedTabValue", selectedTabValue);

    const getSummary = async () => {
        try {
            setGetSummaryProcessing(true);
            const res = await getContactsSummary();
            setSummary(convertSummary(res.data.summary));
        } finally {
            setGetSummaryProcessing(false);
        }
    };

    useEffect(() => {
        if (parentFilter) {
            findAndSetSelectedTab(summary);
        }
    }, [parentFilter]);

    useEffect(() => {
        getSummary();
    }, []);

    useEffect(() => {
        if (summary) {
            findAndSetSelectedTab(summary);
        }
    }, [summary]);

    const onItemClick = (value: string, _: number, item, groupKey) => {
        setSelectedTabValue(item.id);
        if (groupKey === "views") {
            setSavedView(item);
            setList(null);
        } else if (groupKey === "lists") {
            setList(item);
            setSavedView(null);
        } else {
            setList(null);
            setSavedView(null);
        }
        queryRef.current = item.filters;
        setPresettedFilter(item.filters);
        setBreadCrumbsList([{ text: item.name, pathname: `/contacts` }]);
        history.replace("/contacts");
    };

    if (getSummaryProcessing) {
        return (
            <>
                {summaryOrderArray.map((groupKey: string) => {
                    return (
                        <NavigationListItems
                            key={groupKey}
                            loading
                            activeIndex={selectedTabValue}
                            title={groupKey}
                            items={Array(10).fill("loading")}
                            onItemClick={onItemClick}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <>
            {!!summary &&
                summaryOrderArray.map((groupKey: string) => {
                    return (
                        <NavigationListItems
                            key={groupKey}
                            activeIndex={selectedTabValue}
                            title={groupKey}
                            items={summary[groupKey]}
                            onItemClick={(...args) => onItemClick(...args, groupKey)}
                        />
                    );
                })}
            {/* {Object.keys(MOCKED_CONTACTS_FILTER_VALUES).map((groupKey: string) => {
                return (
                    <NavigationListItems
                        activeIndex={selectedTabValue}
                        title={groupKey}
                        items={MOCKED_CONTACTS_FILTER_VALUES[groupKey]}
                        onItemClick={onItemClick}
                    />
                );
            })} */}
        </>
    );
});
