import { SyntheticEvent, useMemo, useState } from "react";
import { Classes, Position, Text } from "@blueprintjs/core";
import { FormikValues } from "formik";
import { getCountryCallingCode } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import { StyledFloatingInput, StyledFloatingInputTypes } from "src/components";
import { getPhoneNumbers } from "src/requests/channelsRequests";
import styles from "./styles.module.scss";
import { useDataFetch } from "../../../../../../../../hooks/useDataFetch";
import classNames from "classnames";
import { CountrySelect } from "../../../../../../../../mpd-library";
import { Dropdown, Option } from "../../../../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../../../store";
import { EmptyBlock } from "../../../../../../../../components";
import { ReactComponent as NoSearchResultsForIcon } from "src/mpd-library/icon/assets/NoSearchResultsFor.svg";

type ChooseNumberProps = {
    formik: FormikValues;
    hideCapabilityInput: boolean;
    formValueKey: string;
    selectNumber: (value: string) => void;
    // phoneNumbersList: Array<GetPhoneNumbersResponsePhoneNumber>;
    // selectedNumberData: GetPhoneNumbersResponsePhoneNumber;
    // setSelectedNumberData: (phoneNumberData: GetPhoneNumbersResponsePhoneNumber) => void;
};

const classname = "choose-number-step";

export const ChooseNumber = ({ formik, hideCapabilityInput, selectNumber, formValueKey }: ChooseNumberProps) => {
    const [countryCode, setCountryCode] = useState<Option>({ value: "US", label: "United States (+1)" });
    const { mainCountries } = useSelector((state: AppState) => state.accountSettings);

    const fetchDataParams = useMemo(
        () => ({
            country_code: countryCode.value
        }),
        [countryCode]
    );

    const {
        entities: phoneNumbers,
        searchValue: searchNumberValue,
        setSearchValue: setSearchNumberValue,
        loading
    } = useDataFetch({
        fetchDataRequest: getPhoneNumbers,
        fetchDataParams,
        entityKey: "available_phone_numbers",
        skeletonLoading: true,
        listItemHeight: 60,
        fieldNameForId: "phone_number"
    });

    const onSearchPhoneNumberChange = (event: SyntheticEvent) => {
        const { value } = event.target;
        setSearchNumberValue(value);
    };

    const onSelectNumber = (phoneNumberData: any) => {
        if (selectNumber) {
            selectNumber(phoneNumberData);
            return;
        }
        const { setFieldValue, setTouched }: FormikValues = formik;
        setTouched({ phone_number: true });
        setFieldValue(formValueKey || "phone_number", phoneNumberData, true);
    };

    const phoneNumbersWithSelected = useMemo(() => {
        if (
            formik.values[formValueKey || "phone_number"] &&
            !phoneNumbers.allIds.includes(formik.values[formValueKey || "phone_number"]?.phone_number)
        ) {
            return {
                ...phoneNumbers,
                byId: {
                    ...phoneNumbers.byId,
                    [formik.values[formValueKey || "phone_number"].phone_number]:
                        formik.values[formValueKey || "phone_number"]
                }
            };
        }

        return phoneNumbers;
    }, [phoneNumbers, formik.values[formValueKey || "phone_number"]]);

    const renderPhoneItemContent = (phoneNumberId) => {
        const phoneNumberData = phoneNumbersWithSelected.byId[phoneNumberId] || {};
        const { iso_country, region, phone_number, phone_number_formatted } = phoneNumberData;
        return (
            <li className={classNames(styles[`${classname}-list-item`], styles["row-template"])}>
                <Text
                    className={classNames(
                        styles[`${classname}-list-item-phone-number`],
                        phoneNumberId === "loading" && Classes.SKELETON
                    )}
                >
                    {`+${getCountryCallingCode(countryCode.value)} ${phone_number_formatted?.replace(/[\(\)']+/g, "")}`}
                </Text>
                <Text
                    className={classNames(
                        styles[`${classname}-list-item-location`],
                        phoneNumberId === "loading" && Classes.SKELETON
                    )}
                >
                    <img
                        key={iso_country}
                        alt={en[iso_country]}
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${iso_country}.svg`}
                        className={"flag-icon"}
                    />
                    {`${iso_country}, ${region}`}
                </Text>
                <Text
                    className={classNames(
                        styles[`${classname}-list-item-price`],
                        phoneNumberId === "loading" && Classes.SKELETON
                    )}
                >
                    Included
                </Text>
                <div
                    className={classNames(
                        styles[`${classname}-list-item-buy-button`],
                        phoneNumberId === "loading" && Classes.SKELETON
                    )}
                >
                    <input
                        type="radio"
                        id={`${phone_number}-buy`}
                        name={"phone-number-buy"}
                        checked={formik.values[formValueKey || "phone_number"]?.phone_number === phone_number}
                        {...formik.getFieldProps(formValueKey || "phone_number")}
                        onChange={() => {
                            onSelectNumber(phoneNumberData);
                        }}
                    />
                    <label
                        className={classNames(
                            styles[`${classname}-list-item-buy-button-label`],
                            formik.values[formValueKey || "phone_number"]?.phone_number === phone_number &&
                                styles[`${classname}-list-item-buy-button-label_checked`]
                        )}
                        htmlFor={`${phone_number}-buy`}
                    >
                        Add
                    </label>
                </div>
            </li>
        );
    };

    return (
        <div className={styles["main-wrapper"]}>
            <div
                className={classNames(styles["top-wrapper"], hideCapabilityInput && styles["top-wrapper_only-number"])}
            >
                <CountrySelect
                    title={"COUNTRY"}
                    targetClassName={DropdownTargetClasses.FORM_SELECT}
                    onCountrySelectProp={setCountryCode}
                    value={countryCode}
                    usePortal={false}
                    customCountriesList={mainCountries}
                />
                {!hideCapabilityInput && (
                    // <MPDSelect
                    //     options={["SMS & Voice", "SMS", "Voice"]}
                    //     defaultValue={"SMS & Voice"}
                    //     className={MPDSelectTargetBorderRoundingClasses.semiRounded}
                    //     title={"CAPABILITY"}
                    //     position={Position.BOTTOM}
                    // />

                    <Dropdown
                        title={"CAPABILITY"}
                        targetClassName={DropdownTargetClasses.FORM_SELECT}
                        staticOptions={[{ label: "SMS & Voice" }, { label: "SMS" }, { label: "Voice" }]}
                    />
                )}
            </div>
            <StyledFloatingInput
                hint={"Search by Phone Number Prefix..."}
                placeholder={"Search for phone number"}
                floatingType={StyledFloatingInputTypes.primary}
                onChange={onSearchPhoneNumberChange}
                value={searchNumberValue}
            />

            <ul className={styles[`${classname}-list`]}>
                <li className={classNames(styles[`${classname}-list-header`], styles["row-template"])}>
                    <Text className={styles[`${classname}-list-header-value-label`]}>PHONE NUMBER</Text>
                    <Text className={styles[`${classname}-list-header-value-label`]}>LOCATION</Text>
                    <Text className={styles[`${classname}-list-header-value-label`]}>PRICE</Text>
                    <div />
                </li>
                {formik.values[formValueKey || "phone_number"] &&
                    !phoneNumbers.allIds.includes(formik.values[formValueKey || "phone_number"].phone_number) &&
                    renderPhoneItemContent(formik.values[formValueKey || "phone_number"].phone_number)}
                {phoneNumbers.allIds.map(renderPhoneItemContent)}
                {!loading && !phoneNumbers.allIds.length && (
                    <div className={styles["empty-wrapper"]}>
                        <EmptyBlock
                            IconComponent={NoSearchResultsForIcon}
                            title={"No Results"}
                            subtitle={`We couldn’t find any phone numbers that start with ${searchNumberValue}`}
                        />
                    </div>
                )}
            </ul>
        </div>
    );
};
