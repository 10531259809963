// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { createContext, useCallback, useContext, useState } from "react";
import * as Classes from "../";

import "./styles.scss";

import "react-phone-number-input/style.css";
import { PhoneInputProps, CountryCode } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import { getCountries } from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input";
import { Position, Text } from "@blueprintjs/core";
import { Dropdown } from "../dropdown/dropdown";
import { MPDIcon } from "../icon/icon";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { parsePhoneNumber } from "react-phone-number-input";
import { ReactComponent as DropdownArrowBlackIcon } from "src/mpd-library/icon/assets/down-arrow.svg";

export interface IMPDFloatingPhoneInputProps extends PhoneInputProps {
    focus?: boolean;
    maxCounter?: number;
    inputRef?: any;
    fixedPlaceholder?: string;
    value: string;
    placeholder?: string;
    selectedCountry: string;
    onlyCountry: boolean;
    disabled?: boolean;
    setCountry: (value: CountryCode) => void;
    setPhoneNumber: (value: string) => void;
    onChange: (value: string) => void;
}

interface IMPDFloatingPhoneInputState {
    invisibleDivWidth?: number;
    searchCountryValue: string;
    filteredCountries: Array<string>;
    countriesDropdownOpened: boolean;
    phoneInputFocused: boolean;
}

export const FloatingPhoneInputClasses = {
    primary: "primary-main-target",
    edit: "edit"
};

const PhoneInputContext = createContext({});

export const CountrySelect = ({
    disabled,
    title,
    value: selectedCountry,
    onCountrySelectProp,
    targetClassName,
    CustomTarget,
    customCountriesList,
    setIsCountrySelectOpened,
    ...props
}) => {
    const options = customCountriesList?.map((item) => item.value) || getCountries();
    const [filteredCountries, setFilteredCountries] = useState<Array<CountryCode>>(options);
    const { onCountrySelect } = useContext(PhoneInputContext);

    return (
        <Dropdown
            // usePortal={false}
            disabled={disabled}
            // usePortal
            onIntercationCustom={setIsCountrySelectOpened}
            title={title}
            selectedValue={selectedCountry}
            contentBlockHeight={300}
            showContentSearchHeader
            targetClassName={targetClassName}
            ArrowIconComponent={() => (
                <MPDIcon IconComponent={DropdownArrowBlackIcon} className={"country-select-arrow-icon"} />
            )}
            CustomTarget={CustomTarget}
            matchTargetWidth
            onStaticOptionClick={(country) => {
                if (onCountrySelectProp) {
                    onCountrySelectProp({
                        value: country.value,
                        label: `${en[country.value]} (+${getCountryCallingCode(country.value)})`
                    });
                    return;
                }
                onCountrySelect({
                    value: country.value,
                    label: `${en[country.value]} (+${getCountryCallingCode(country.value)})`
                });
            }}
            LeftIconComponent={useCallback(
                () => (
                    <img
                        key={`${selectedCountry.value}-flag-icon`}
                        alt={en[selectedCountry.value]}
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry.value}.svg`}
                        className={"flag-icon"}
                    />
                ),
                [selectedCountry.value]
            )}
            staticOptions={filteredCountries.map((country) => {
                const countryCallingCode = getCountryCallingCode(country);

                if (countryCallingCode) {
                    return {
                        label: en[country],
                        value: country,
                        className: "country-item",
                        children: (
                            <>
                                <img
                                    alt="United States"
                                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
                                    className={"flag-icon"}
                                />
                                <Text>{`${en[country]} +${countryCallingCode}`}</Text>
                            </>
                        )
                    };
                }
            })}
            position={Position.BOTTOM}
            {...props}
        />
    );
};

export class MPDFloatingPhoneInput extends React.Component<IMPDFloatingPhoneInputProps, IMPDFloatingPhoneInputState> {
    // private input: React.RefObject<HTMLInputElement>;

    // private invisibleInput: React.RefObject<HTMLDivElement>;

    private countries = getCountries();
    private searcHeaderProps = { title: "Country" };
    private input = React.createRef();

    constructor(props: PhoneInputProps) {
        super(props);
        this.state = {
            filteredCountries: this.countries,
            selectedCountry: "US",
            phoneNumber: "",
            isCountrySelectOpened: false
        };

        this.onPhoneInputBlured = this.onPhoneInputBlured.bind(this);
    }

    public setIsCountrySelectOpened = (value: boolean) => {
        this.setState({ isCountrySelectOpened: value });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.autoFocus !== this.props.autoFocus && this.props.autoFocus) {
            this.input?.current?.focus?.();
        }
    }

    public onCountriesDropdownOpeen = (value: boolean) => {
        this.setState({ countriesDropdownOpened: value });
    };

    public onPhoneNumberChange = (value: string) => {
        if (this.props.disabled) {
            return;
        }

        const phN = parsePhoneNumber(`${value}`);
        if (phN) {
            const newSelectedCountry = phN?.country || this.state.selectedCountry;
            if (this.state.selectedCountry !== newSelectedCountry) {
                this.setState({ selectedCountry: phN?.country || this.state.selectedCountry });
            }
            this.props.onChange(value);
        }
    };

    public onCountrySelect = (option) => {
        this.setState({ selectedCountry: option.value });
        this.props.onChange("");
    };

    public onPhoneInputFocused = () => {
        this.setState({ phoneInputFocused: true });
    };

    public onPhoneInputBlured = () => {};

    public countrySelectComponent = (props) => {
        const { countrySelectComponent, CustomTarget, customCountriesList, disabled } = this.props;
        const Comp = countrySelectComponent;
        return countrySelectComponent ? (
            // countrySelectComponent({ value: props.value, ...props })
            <Comp
                key={"country-select"}
                {...props}
                value={props.value}
                customCountriesList={customCountriesList}
                setIsCountrySelectOpened={this.setIsCountrySelectOpened}
                disabled={this.props.disabled}
            />
        ) : (
            <CountrySelect
                {...props}
                disabled={this.props.disabled}
                onCountrySelect={this.onCountrySelect}
                targetClassName={DropdownTargetClasses.SIMPLE}
                value={{ value: props.value }}
                CustomTarget={CustomTarget}
                matchTargetWidth
                searcHeaderProps={this.searcHeaderProps}
                customCountriesList={customCountriesList}
                setIsCountrySelectOpened={this.setIsCountrySelectOpened}
            />
        );
    };

    public render() {
        const { value, className, placeholder, CustomTarget, countrySelectComponent, ...newProps } = this.props;
        console.log("this.state.isCountrySelectOpened", this.state.phoneInputFocused, this.state.isCountrySelectOpened);

        const classes = classNames(
            // Classes.FLOATING_PHONE_INPUT_CONTAINER,
            value && value.toString().length > 0 && Classes.ACTIVE,
            className
        );

        return (
            <PhoneInputContext.Provider value={{ onCountrySelect: this.onCountrySelect }}>
                <div className={classes} tabIndex={-1}>
                    <div
                        className={classNames(
                            "primary-main-target",
                            (this.state.phoneInputFocused || this.state.isCountrySelectOpened) &&
                                "primary-main-target_focused"
                        )}
                    >
                        <PhoneInput
                            ref={this.input}
                            international
                            value={value}
                            countryCallingCodeEditable={false}
                            onFocus={this.onPhoneInputFocused}
                            defaultCountry={this.state.selectedCountry}
                            disabled={this.props.disabled}
                            {...newProps}
                            onChange={this.onPhoneNumberChange}
                            countrySelectComponent={this.countrySelectComponent}
                            onBlur={(e) => {
                                this.setState({ phoneInputFocused: false, isCountrySelectOpened: false });
                                newProps?.onBlur?.(e);
                            }}
                        />
                    </div>
                    <span className={Classes.FLOATING_PHONE_INPUT_LABEL} tabIndex={-1}>
                        {placeholder}
                    </span>
                </div>
            </PhoneInputContext.Provider>
        );
    }

    // private setFocus(state: boolean) {
    //     if (this.input.current) {
    //         if (state) {
    //             this.input.current.focus();
    //         } else {
    //             this.input.current.blur();
    //         }
    //         return state;
    //     }
    //     return;
    // }
}
