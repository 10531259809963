import { FormikValues } from "formik";
import { StyledFloatingInput, StyledFloatingInputTypes } from "src/components";

type SmartLinkOverviewProps = {
    formikValues: FormikValues;
};

export const SmartLinkOverview = ({ formikValues }: SmartLinkOverviewProps) => {
    const { getFieldProps, touched, errors }: FormikValues = formikValues;

    return (
        <div className={"step-inputs-wrapper"}>
            <StyledFloatingInput
                hint={"Enter a name for your smart link..."}
                placeholder={"SMART LINK NAME"}
                floatingType={StyledFloatingInputTypes.primary}
                error={touched.name && errors.name}
                {...getFieldProps("name")}
            />
            <StyledFloatingInput
                hint={"Enter a keyword for your smart link..."}
                placeholder={"KEYWORD"}
                floatingType={StyledFloatingInputTypes.primary}
                error={touched.keyword && errors.keyword}
                {...getFieldProps("keyword")}
            />
            <StyledFloatingInput
                hint={"Enter a description for your smart link (optional)..."}
                placeholder={"DESCRIPTION"}
                floatingType={StyledFloatingInputTypes.primary}
                error={touched.description && errors.description}
                {...getFieldProps("description")}
            />
        </div>
    );
};
