// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { BreadCrumbs, ContainerEmpty, SimpleList, StyledButton, StyledButtonTypes } from "src/components";

import { SettingsGroupsListItem } from "../../components";

import { Redirect, Route, Switch } from "react-router-dom";

import { MPDScroll } from "src/mpd-library";

import { IDetailView } from "../../../";

import TeamsSettingsEdit from "./team-settings-edit/team-settings-edit";
import TeamsSettingsAdd from "./teams-settings-add/teams-settings-add";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { History } from "history";
import { bindActionCreators, Dispatch } from "redux";
import { addTeam, onSaveChanges } from "src/actions/account-settings/account-settings-api";
import { ReactComponent as GroupBlueIcon } from "../../../../mpd-library/icon/assets/group-blue.svg"

interface ITeamSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    onAddTeam: (history: History) => void;
}

interface IMyInfoSettingsState {
    listHeaderChecked: boolean;
    accountSettings: AccountSettingsState;
    currentTeam?: any;
    filter?: string;
    sort?: Array<string> | string;
}

const pagePathname = "/account-settings/teams";

class TeamSettings extends React.Component<ITeamSettingsProps, IMyInfoSettingsState> {
    constructor(props: ITeamSettingsProps) {
        super(props);
        this.updateToolBar();

        const { accountSettings } = this.props;

        this.state = {
            listHeaderChecked: false,
            accountSettings: JSON.parse(JSON.stringify(accountSettings)),
            currentTeam: null
        };
    }

    public componentDidUpdate(prevProps: ITeamSettingsProps) {
        if (prevProps.accountSettings !== this.props.accountSettings) {
            this.setState({ accountSettings: this.props.accountSettings });
        }
        if (
            prevProps.routeProps.location !== this.props.routeProps.location &&
            this.props.routeProps.location.pathname === pagePathname
        ) {
            this.updateToolBar();
        }
    }

    public render() {
        const { accountSettings, routeProps, onCloseRightPanel, onToggleRightPanel } = this.props;
        const { currentTeam } = this.state;
        const classes = "team-settings";

        return (
            <div className={classes}>
                <MPDScroll className={classes + "-inner-container"} yDirection={true}>
                    <Switch location={routeProps.history.location}>
                        {currentTeam === null && (
                            <Redirect from="/account-settings/teams/edit" to="/account-settings/teams" />
                        )}
                        <Route
                            exact={true}
                            path={"/account-settings/teams/"}
                            render={() => this.renderTeamSettings(classes)}
                        />
                        <Route
                            exact={true}
                            path={"/account-settings/teams/add/"}
                            render={() => (
                                <TeamsSettingsAdd
                                    routeProps={routeProps}
                                    onCloseRightPanel={onCloseRightPanel}
                                    onToggleRightPanel={onToggleRightPanel}
                                    team={accountSettings.general.teams.newTeam}
                                    onUpdateChangesMade={this.props.onUpdateChangesMade}
                                />
                            )}
                        />
                        <Route
                            path={"/account-settings/teams/edit/"}
                            render={() => (
                                <TeamsSettingsEdit
                                    onCloseRightPanel={onCloseRightPanel}
                                    onToggleRightPanel={onToggleRightPanel}
                                    routeProps={routeProps}
                                    currentTeam={currentTeam}
                                    onUpdateChangesMade={this.props.onUpdateChangesMade}
                                />
                            )}
                        />
                        <Redirect exact={true} from="/account-settings/teams" to="/account-settings/teams" />
                    </Switch>
                </MPDScroll>
            </div>
        );
    }

    private renderTeamSettings = (classes: string) => {
        const { accountSettings, listHeaderChecked } = this.state;
        const { isOrSmallerTabletPortraitSize } = this.props;
        return (
            <React.Fragment>
                {accountSettings.general.teams.list.length === 0 ? (
                    <ContainerEmpty
                        className={classNames(classes + "-empty")}
                        title="No Teams Found"
                        subtitle="Looks like you haven’t added any teams yet. Click on the “Add Teams” button above to add your first team."
                        IconComponent={GroupBlueIcon}
                    />
                ) : (
                    <SimpleList
                        isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                        renderedComponent={this.renderList}
                        list={accountSettings.general.teams.list}
                        showOptions={listHeaderChecked}
                        placeholder="Search Teams…"
                    />
                )}
            </React.Fragment>
        );
    };

    private renderList = (props: any) => {
        return (
            <React.Fragment>
                {props.list.length === 0 ? (
                    <ContainerEmpty
                        className={"team-settings-list-container-empty"}
                        title="No Teams Found"
                        subtitle="Looks like you haven’t added any teams yet. Click on the “Add Teams” button above to add your first team."
                        IconComponent={GroupBlueIcon}
                    />
                ) : (
                    props.list.map((team: any, index: number) => (
                        <SettingsGroupsListItem
                            key={index}
                            active={team.active}
                            checked={team.checked}
                            onCheckChanged={() => this.onCheckChanged(index)}
                            description={team.description}
                            members={team.members}
                            onSelectChanged={(e) => this.onListStatusChange(e, index)}
                            selectValue={team.active ? "Active Since " + team.date : "Deactive"}
                            options={team.active ? ["Edit", "Delete", "Deactivate"] : ["Edit", "Delete", "Activate"]}
                            title={team.name}
                            onClick={() => this.onClickRow(index)}
                        />
                    ))
                )}
            </React.Fragment>
        );
    };

    private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filter = event.currentTarget.value;
        this.setState({ filter });
    };

    private onListSortChange = (event: any) => {
        let sort = event.currentTarget.textContent;
        if (sort.includes("A-Z")) {
            sort = ["title", "description"];
        } else if (sort.includes("Private")) {
            sort = "type";
        } else if (sort.includes("Public")) {
            sort = "-type";
        }
        this.setState({ sort });
    };

    private onListSelectChange = (event: any) => {
        const { accountSettings } = this.state;
        const list = accountSettings.general.teams.list;
        const select = event.currentTarget.textContent;
        if (select.includes("All")) {
            for (let i = 0; i < list.length; i++) {
                list[i].checked = true;
            }
            accountSettings.general.teams.showListDetails = true;
        } else if (select.includes("None")) {
            for (let i = 0; i < list.length; i++) {
                list[i].checked = false;
            }
            accountSettings.general.teams.showListDetails = false;
        }
        this.setState({ accountSettings });
    };

    private onCheckChanged = (index: number) => {
        const { accountSettings } = this.state;
        const list = accountSettings.general.teams.list;
        list[index].checked = !list[index].checked;
        accountSettings.general.teams.showListDetails = false;
        if (list.some((element: any) => element.checked)) {
            accountSettings.general.teams.showListDetails = true;
        }
        if (list.every((element: any) => element.checked)) {
            this.setState({ listHeaderChecked: true });
        } else if (list.every((element: any) => !element.checked)) {
            this.setState({ listHeaderChecked: false });
        }
        this.setState({ accountSettings });
    };

    private onListStatusChange = (event: any, index: number) => {
        const { accountSettings } = this.state;
        const value = event.currentTarget.textContent;
        if (value.includes("Delete")) {
            accountSettings.general.teams.list.splice(index, 1);
        } else if (value.includes("Edit")) {
            this.onClickRow(index);
        } else if (value.includes("Deactivate")) {
            accountSettings.general.teams.list[index].active = false;
        } else if (value.includes("Activate")) {
            accountSettings.general.teams.list[index].active = true;
        }
        this.setState({ accountSettings });
    };

    private onClickRow = (index: number) => {
        const { routeProps, accountSettings } = this.props;
        const currentTeam = accountSettings.general.teams.list[index];
        const id = accountSettings.general.teams.list[index].id;
        this.setState({ currentTeam });
        routeProps.history.push(`/account-settings/teams/edit/${id}`);
    };

    private updateToolBar = () => {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Teams", pathname: "/account-settings/teams" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: <StyledButton type={StyledButtonTypes.primary} text="Add Team" onClick={this.onAddTeam} />
        };
        this.props.onUpdateToolBar(topBarProps);
    };

    private onAddTeam = () => {
        const history = this.props.routeProps.history;
        this.props.onAddTeam(history);
    };
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings, global } = state;
    const { isOrSmallerTabletPortraitSize } = global;
    return {
        accountSettings,
        isOrSmallerTabletPortraitSize
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onAddTeam: addTeam,
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onSaveChanges,
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings);
