// import classNames from "classnames";
import React from "react";

export interface IMPDListEmptyProps {
    emptyElement?: JSX.Element;
}

export const MPDListEmpty: React.FunctionComponent<IMPDListEmptyProps> = (props) => {
    const { emptyElement } = props;
    return <React.Fragment>{emptyElement}</React.Fragment>;
};
