import { useState, useEffect, useMemo } from "react";
import pluralize from "pluralize";
import { Text } from "@blueprintjs/core";
import styles from "./ImportToUnmapped.module.scss";
import { StyledCheckbox } from "../../../../../../../components";
import { calculateUnmapped } from "../../utils";
import { Tooltip } from "../../../../../../../components/tooltip/tooltip";

interface ImportToUnmappedProps {
    formik: any;
}

const FIELD_NAME_LABEL_MATCH = {
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone Number",
    email: "Email",
    address: "Address"
};

export const ImportToUnmapped = (props: ImportToUnmappedProps) => {
    const { formik } = props;
    const [unmappedCounter, setUnmappedCounter] = useState<number>(0);
    const [unmappedFieldsNames, setUnmappedFieldsNames] = useState<Array<string>>([]);
    const [importToUnmapped, setImportToUnmapped] = useState<boolean>(
        formik.values.canGoNext && calculateUnmapped(formik.values.mapped_fields).unmappedCounter > 0
    );

    useEffect(() => {
        const { unmappedCounter, unmappedFieldsNames } = calculateUnmapped(formik.values.mapped_fields);
        setUnmappedCounter(unmappedCounter);
        setUnmappedFieldsNames(unmappedFieldsNames);

        if (unmappedCounter > 0 && !importToUnmapped) {
            formik.setValues({ ...formik.values, canGoNext: false });
        } else {
            formik.setFieldValue("canGoNext", true, true);
        }
    }, [formik.values.mapped_fields, importToUnmapped]);

    const onCheckBoxChange = (e) => {
        const { checked } = e.target;
        setImportToUnmapped(checked);
    };

    const namesString = useMemo(() => {
        let str = "";
        unmappedFieldsNames.forEach((fieldName: string, index, array) => {
            if (index === array.length - 1) {
                str = str + " " + FIELD_NAME_LABEL_MATCH[fieldName];
            } else {
                str = str + " " + FIELD_NAME_LABEL_MATCH[fieldName] + "," + " ";
            }
        });
        return str;
    }, [unmappedCounter, unmappedFieldsNames]);

    if (!unmappedCounter) {
        return null;
    }

    return (
        <div className={styles["unmapped"]}>
            <Text className={styles["unmapped__counter"]}>
                {`You have ${unmappedCounter} unmapped ${pluralize("column", unmappedCounter)}`}{" "}
                <Tooltip
                    content={<Text className={styles["tooltip-content"]}>{namesString}</Text>}
                    tootltipTarget={<Text className={styles["unmapped-fields-tooltip-target"]}>!</Text>}
                    usePortal={false}
                />
            </Text>
            <StyledCheckbox
                label={"Don’t Import data in unmapped columns"}
                className={styles["unmapped__checkbox"]}
                onChange={onCheckBoxChange}
                checked={importToUnmapped}
            />
        </div>
    );
};
