// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IDetailView } from "../../../";

import { createHalfHourIntervals } from "src/actions";

import { ListHeader } from "./components";

import { SettingsDataExportSettingsListItem } from "../../components";

import { Classes, MPDScroll } from "src/mpd-library";

import {
    BreadCrumbs,
    StartEndTimer,
    StyledButton,
    StyledSelect,
    StyledSelectTypes,
    StyledButtonTypes
} from "src/components";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { ReactComponent as TimClockBlueIcon } from "../../../../mpd-library/icon/assets/tim-clock-blue.svg"
import { ReactComponent as TimClockIcon } from "../../../../mpd-library/icon/assets/tim-clock-blue.svg"

export interface IDataExportSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    routeProps: RouteComponentProps;
}

interface IDataExportSettingsState {
    timeOptions: Array<string>;
}

export class DataExportSettings extends React.Component<IDataExportSettingsProps, IDataExportSettingsState> {
    public constructor(props: IDataExportSettingsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.updateToolBar();
        const timeOptions = createHalfHourIntervals("h:mm a");
        this.state = {
            timeOptions
        };
    }

    public render() {
        const { accountSettings, className } = this.props;
        const { timeOptions } = this.state;
        const classes = "data-export-settings";
        const { auditLog } = accountSettings.general;
        return (
            <MPDScroll className={classNames(classes, Classes.CONTAINER_MARGIN_VERTICAL, className)} xDirection={true}>
                <div className={classNames(classes + "-header", Classes.ROW, Classes.CONTAINER_MARGIN_HORIZONTAL)}>
                    <StartEndTimer
                        className={classes + "-start-end-timer"}
                        startTimeProps={{
                            className: classNames("primary", auditLog.hours.startTime !== null && Classes.ACTIVE),
                            value: "Start Time",
                            options: timeOptions,
                            IconComponent: auditLog.hours.startTime !== null ? TimClockBlueIcon : TimClockIcon,
                            arrowIcon:
                                auditLog.hours.startTime !== null
                                    ? "drop-down-arrow-black-blue"
                                    : "dropDownArrowBlack"
                            // onChange: (e: any) => onTimeChanged && onTimeChanged(e, 'startTime')
                        }}
                        endTimeProps={{
                            className: classNames("primary", auditLog.hours.endTime !== null && Classes.ACTIVE),
                            value: "End Time",
                            IconComponent: auditLog.hours.endTime !== null ? TimClockBlueIcon : TimClockIcon,
                            arrowIcon:
                                auditLog.hours.endTime !== null
                                    ? "drop-down-arrow-black-blue"
                                    : "dropDownArrowBlack",
                            options: timeOptions
                            // onChange: (e: any) => onTimeChanged && onTimeChanged(e, 'endTime')
                        }}
                    />
                    <StyledSelect
                        className={classes + "-alerts-select"}
                        type={StyledSelectTypes.primary}
                        value="Alerts"
                        options={["Alerts", "No Alerts"]}
                    />
                    <StyledButton
                        className={classes + "-export-data"}
                        type={StyledButtonTypes.primary}
                        text="Export Data"
                    />
                </div>
                <div className={classes + "-list"}>
                    <ListHeader />
                    <SettingsDataExportSettingsListItem
                        date="Nov 2, 2018"
                        time="14:09:10"
                        fileSize="1.2 M"
                        fileName="All_Time_Alerts.csv"
                        exportID="9124390124"
                        exportedBy="Jamieson Johnson"
                        status="Processing"
                        result="Success"
                    />
                </div>
            </MPDScroll>
        );
    }

    private updateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Data Export" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DataExportSettings);
