import { Notification, NotificationsSettings } from "src/store";
import {
    NotificationsActionTypes,
    SET_NOTIFICATIONS,
    SET_HAS_MORE_NOTIFICATIONS,
    SET_NOTIFICATION_SETTINGS,
    UPDATE_NOTIFICATIONS_COUNTER
} from "./types";

export const setNotifications = (notifications: Array<Notification>): NotificationsActionTypes => {
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications
    };
};

export const setHasMoreNotifications = (value: boolean) => {
    return { type: SET_HAS_MORE_NOTIFICATIONS, payload: value };
};

export const setNotificationsSettings = (notificationsSettings: NotificationsSettings): NotificationsActionTypes => {
    return {
        type: SET_NOTIFICATION_SETTINGS,
        payload: notificationsSettings
    };
};

export const updateNotificationsCounter = (value: number): NotificationsActionTypes => {
    return {
        type: UPDATE_NOTIFICATIONS_COUNTER,
        payload: value
    };
};
