import classNames from "classnames";
import React from "react";

import { IProps } from "@blueprintjs/core";
import { History } from "history";

export interface IMPDNavBarProps extends IProps {
    history: History;
}

export interface IMPDNavBarState extends IProps {
    activePathname: string | undefined;
}

export class MPDNavBar extends React.Component<IMPDNavBarProps, IMPDNavBarState> {
    // constructor(props: IMPDNavBarProps) {
        // super(props);

        // let activePathname;

        // if (this.props.history) {
        //     activePathname = this.props.history.location.pathname;
        // }

        // this.state = {
        //     activePathname
        // };
    // }

    public render() {
        const classes = "mpd-navbar";
        const { className, children, history } = this.props;
        // const { activePathname } = this.state;

        const activePathname = this.props.history.location.pathname;
        
        const navGroupWithProps = React.Children.map(children, (child: React.ReactElement<any>) =>
            React.cloneElement(child, {
                activePathname,
                history
            })
        );

        return <div className={classNames(classes, className)}>{navGroupWithProps}</div>;
    }

    // private onItemClick(pathname: string){
    //   const { history } = this.props;
    //   history.push(pathname);
    // }
}
