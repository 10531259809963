import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import { ConversationStatus } from "src/store/conversations";
import styles from './styles.module.scss';

const labelTextMatch = {
    opened: "ENQUEUED",
    assigned: "ASSIGNED",
    resolved: "CLOSED"
};

const statusClassnameMatch = {
    opened: "inbox",
    assigned: "assigned",
    resolved: "resolved"
};

type ConversationStatusLabelProps = {
    status: ConversationStatus;
};

const classname = "conversation-status-label";

export const ConversationStatusLabel = ({ status }: ConversationStatusLabelProps) => {
    return (
        <Text
            className={classNames(
                styles[classname],
                styles[statusClassnameMatch[status]],
                "conversation-status-label"
            )}
        >
            {labelTextMatch[status]}
        </Text>
    );
};