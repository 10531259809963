// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { Classes } from "src/mpd-library";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";
import { AppState, AccountSettingsSubscriptionDetailsState, SubscriptionDetailsListState } from "src/store";
import { bindActionCreators, Dispatch } from "redux";

export interface ISubscriptionSettingsDetailsProps extends IProps {
    details: AccountSettingsSubscriptionDetailsState;
}

interface ISubscriptionSettingsDetailsState {
    test?: string;
}

class SubscriptionSettingsDetails extends React.Component<
    ISubscriptionSettingsDetailsProps,
    ISubscriptionSettingsDetailsState
> {
    constructor(props: ISubscriptionSettingsDetailsProps) {
        super(props);
    }

    public render() {
        const { className, details } = this.props;
        const classes = "subscription-settings-details";

        return (
            <div className={classNames(classes, className)}>
                <div
                    className={classNames(
                        classes + "-header",
                        Classes.ROW,
                        Classes.CONTAINER_PADDING_HORIZONTAL,
                        Classes.BLOCK_PADDING_VERTICAL,
                        Classes.DIVISION_4
                    )}
                >
                    <Text className={classes + "-header-item"}>Product</Text>
                    <Text className={classes + "-header-item"}>Standard Price</Text>
                    <Text className={classes + "-header-item"}>Your Price</Text>
                </div>
                <div className={classes + "-list"}>
                    {details.list.map((detail: SubscriptionDetailsListState, index: number) => (
                        <div
                            className={classNames(
                                classes + "-list-item",
                                Classes.ROW,
                                Classes.CONTAINER_PADDING_HORIZONTAL,
                                Classes.BLOCK_PADDING_VERTICAL,
                                Classes.DIVISION_4
                            )}
                            key={index}
                        >
                            <div className={classes + "-product"}>
                                {" "}
                                {detail.product}{" "}
                                <span className={classes + "-product-subtitle"}> {detail.productSubtitle} </span>{" "}
                            </div>
                            <Text className={classes + "-standard-price"}> {detail.standardPrice} </Text>
                            <Text className={classes + "-your-price"}> {detail.yourPrice} </Text>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const details = accountSettings.organization.subscription.details;
    return {
        details
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettingsDetails);
