import { useRef, useState, useEffect, FC } from "react";
import { FormikValues } from "formik";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { ColorSelector } from "../color-selector/color-selector";
import { QR_CODE_BACKGROUND_COLORS, QR_CODE_COLORS } from "../color-selector/constants";
import { QRStyle, QRCode } from "../qr-code-generator/qr-code-generator";
import { StyledButton, StyledButtonTypes } from "../styled-button";
import { QRCodeStyleSelector } from "./components/qr-code-style-selector/qr-code-style-selector";
import styles from "./SmartLinksQrCode.module.scss";
import { updateSmartLink, SmartLink } from "src/requests/smartLinksRequests";
import { uploadFile } from "../../actions/global/global-api";

const classname = "smart-links-qr-code";

type SmartLinksQrCodeProps = {
    formikValues: FormikValues;
    selectedSmartLink: SmartLink;
};

export const SmartLinksQrCode: FC<SmartLinksQrCodeProps> = ({
    formikValues,
    selectedSmartLink,
    setFile
}: SmartLinksQrCodeProps): JSX.Element => {
    const accountLogo = useSelector((state: AppState) => state.accountSettings.organization.details.logo.url);
    const [logoWasLoaded, setLogoWasLoaded] = useState<boolean>(false);
    const qrCodeCanvasRef = useRef<HTMLCanvasElement>();
    const wasMount = useRef<boolean>(false);
    const logoInitLoading = useRef<boolean>(false);

    const onDownLoadClick = () => {
        try {
            const link = document.createElement("a");
            const dataUrl = qrCodeCanvasRef.current?.toDataURL();
            link.download = "qrcode.png";
            link.href = dataUrl;
            link.click();
        } catch (err) {
            console.log("RES err >>>>", err);
        }
    };

    const {
        setFieldValue,
        values: { qrCodeBgColor, qrCodeStyle, qrCodeColor, qrCodeImgUrl }
    } = formikValues;

    // useEffect(() => {
    //     const uploadQrCodeImg = async () => {
    //         if (!qrCodeImgUrl && logoWasLoaded) {
    //             qrCodeCanvasRef.current?.toBlob(async (blob) => {
    //                 const qrCodeImage = new File([blob], "smart-link-qr-code.png", { type: "image/png" });
    //                 const { preview_url, presigned_url } = await uploadFile(qrCodeImage);
    //                 await updateSmartLink(selectedSmartLink.id, {
    //                     ...selectedSmartLink,
    //                     qr_code_params: {
    //                         ...selectedSmartLink.qr_code_params,
    //                         qr_code_img_url: preview_url,
    //                         qr_code_img_pressigned_url: presigned_url
    //                     }
    //                 });
    //                 setFieldValue("qrCodeImgUrl", preview_url);
    //             });
    //         }
    //     };
    //     uploadQrCodeImg();
    // }, [logoWasLoaded]);

    useEffect(() => {
        if (logoWasLoaded && wasMount.current) {
            qrCodeCanvasRef.current?.toBlob((blob) => {
                const qrCodeImage = new File([blob], "smart-link-qr-code.png", { type: "image/png" });
                setFile(qrCodeImage);
                // setFieldValue("qrCodeFile", qrCodeImage);
                // setCodeStyleWasChanged(false);
            });
        }
    }, [logoWasLoaded]);

    useEffect(() => {
        wasMount.current = true;
    }, []);

    const onBgColorChange = (value: string) => {
        setFieldValue("qrCodeBgColor", value);
        // setCodeStyleWasChanged(true);
    };

    const onQrCodeColorChange = (value: string) => {
        setFieldValue("qrCodeColor", value);
        // setCodeStyleWasChanged(true);
    };

    const onQrCodeStyleChange = (value: QRStyle) => {
        setFieldValue("qrCodeStyle", value);
        // setCodeStyleWasChanged(true);
    };

    return (
        <div className={styles[classname]}>
            <div className={styles["generated-qr-code"]}>
                <div className={styles["qr-code-wrapper"]}>
                    <QRCode
                        value={selectedSmartLink?.public_url}
                        qrStyle={qrCodeStyle}
                        bgColor={qrCodeBgColor}
                        fgColor={qrCodeColor}
                        logoHeight={50}
                        logoWidth={50}
                        quietZone={15}
                        logoImage={accountLogo}
                        size={135}
                        ref={qrCodeCanvasRef}
                        enableCORS
                        setLogoWasLoaded={setLogoWasLoaded}
                    />
                </div>
                <StyledButton type={StyledButtonTypes.primary} text="Download" onClick={onDownLoadClick} />
            </div>
            <QRCodeStyleSelector activeStyle={qrCodeStyle} onSelect={onQrCodeStyleChange} />
            <ColorSelector
                colors={QR_CODE_BACKGROUND_COLORS}
                changeColor={onBgColorChange}
                label={"BACKGROUND COLOR"}
                selectedColor={qrCodeBgColor}
            />
            <ColorSelector
                colors={QR_CODE_COLORS}
                changeColor={onQrCodeColorChange}
                label={"QR CODE COLOR"}
                selectedColor={qrCodeColor}
            />
        </div>
    );
};
