import {
    saveMyInfoChanges,
    toggleProfileStatus,
    toggleRightPanel,
    getUserSettings,
    updateUserPassword,
    uploadProfilePhoto,
    changeEmailPassOTP,
    sendForEmailOTP,
    confirmOTPForEmailChange,
    updateNotifications,
    updateSchedule,
    sendForPhoneOTP,
    confirmOTPForPhoneChange,
    updateEmailPassChangeError,
    updateSavedEmailIntermediateValue,
    getMyActivity,
    resetUserPasswordErrors
} from "./user-settings-api";

import { navigate } from "../global/global-api";
import { Dispatch } from "redux";
import {
    AppState,
    UserSettingsMyInfo,
    UserSettingsNotificationState,
    UserSettingsScheduleState,
    OTPAlertTypes,
    UserSettingsEmailPassOTPState
} from "../../store";
import { History } from "history";
import { UserEmailChangeData, UserPhoneChangeData } from "src/requests";
import { MyActivityParams, getMyActivityRequest } from "../../requests";
import { AppThunkDispatch } from "../types";

export const onSaveMyInfoChanges = (myInfo: UserSettingsMyInfo, pathname?: string, history?: History) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(saveMyInfoChanges({ myInfo, pathname, history }));
    };
};

export const onUploadProfilePhoto = (file: any) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(uploadProfilePhoto({ file }));
    };
};

export const onToggleRightPanel = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(toggleRightPanel());
    };
};

export const onCloseRightPanel = (pathname: string, history: History) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(navigate({ pathname, history }));
    };
};

export const onToggleProfileStatus = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(toggleProfileStatus());
    };
};

export const onGetUserSettings = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(getUserSettings());
    };
};

export const onUpdateUserPassword = (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(
            updateUserPassword({
                currentPassword,
                newPassword,
                confirmNewPassword
            })
        );
    };
};

export const onResetUserPasswordErrors = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(resetUserPasswordErrors());
    };
};

export const onСhangeEmailPassOTP = (emailPassOTP: UserSettingsEmailPassOTPState) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(changeEmailPassOTP(emailPassOTP));
    };
};

export const onSendForEmailOTP = (body: UserEmailChangeData, nextOTPAlertType: OTPAlertTypes) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(sendForEmailOTP(body, nextOTPAlertType));
    };
};

export const onConfirmOTPForEmailChange = (
    body: UserEmailChangeData,
    updatedObj: UserSettingsMyInfo,
    nextAlertType?: OTPAlertTypes,
    password?: string,
    pathname?: string,
    history?: History
) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(confirmOTPForEmailChange(body, updatedObj, nextAlertType, password, pathname, history));
    };
};

export const onSendForPhoneOTP = (body: UserPhoneChangeData, nextOTPAlertType: OTPAlertTypes) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(sendForPhoneOTP(body, nextOTPAlertType));
    };
};

export const onConfirmOTPForPhoneChange = (
    body: UserPhoneChangeData,
    updatedObj: UserSettingsMyInfo,
    pathname?: string,
    history?: History
) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(confirmOTPForPhoneChange(body, updatedObj, pathname, history));
    };
};

export const onUpdateNotifications = (
    notifications: UserSettingsNotificationState,
    pathname?: string,
    history?: History
) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateNotifications(notifications, pathname, history));
    };
};

export const onUpdateSchedule = (schedule: UserSettingsScheduleState, pathname?: string, history?: History) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateSchedule(schedule, pathname, history));
    };
};

export const onUpdateEmailPassChangeError = (error?: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateEmailPassChangeError(error));
    };
};

export const onUpdateSavedEmailIntermediateValue = (email?: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateSavedEmailIntermediateValue(email));
    };
};

// export const onGetMyActivity = (params: MyActivityParams) => {
//     return (dispatch: Dispatch<AppState>) => {
//         dispatch(getMyActivity(params));
//     };
// };
