// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { SyntheticEvent } from "react";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";
import { RoundedIcon } from "src/components/rounded-icon/rounded-icon";
import { StyledButton, StyledButtonTypes } from "src/components";

export interface ISettingsSocialBanterProps extends IProps {
    active?: boolean;
    accountType?: string;
    title?: string;
    subtitle?: string;
    roundedIconSize?: number;
    entityName?: string;
    // roundedIconBackgroundColor?: string;
    roundedIconProps: any;
    customRightElement: JSX.Element;
    // actionButtonText?: string;
    // actionButtonTextActive?: string;
    // actionButtonClick?: (event: React.SyntheticEvent) => void;

    rightButtonProps: {
        text: string;
        className?: string;
        type?: StyledButtonTypes;
        onClick: (event: SyntheticEvent) => void;
        processing?: boolean;
    };
}

export const SettingsSocialBanter: React.FunctionComponent<ISettingsSocialBanterProps> = (props) => {
    const {
        className,
        title,
        subtitle,
        roundedIconSize,
        rightButtonProps,
        entityName,
        roundedIconProps,
        customRightElement
    } = props;
    const classes = "settings-social-banter";

    return (
        <div className={classNames(classes + "-container", className)}>
            <div className={classes + "-left-wrapper"}>
                <div className={classes + "-main-info-wrapper"}>
                    <RoundedIcon name={entityName} {...roundedIconProps} size={roundedIconSize || 60} />
                    <div className={classes + "-description"}>
                        <Text className={classes + "-title"}> {title} </Text>
                        <Text className={classes + "-sub-title"}> {subtitle} </Text>
                    </div>
                </div>
                {customRightElement}
            </div>
            {!!rightButtonProps && (
                <div className={"add-new-button-wrapper"}>
                    <StyledButton className={"add-new-button"} {...rightButtonProps} />
                </div>
            )}
        </div>
    );
};

export default SettingsSocialBanter;
