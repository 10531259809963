import { useCallback, useEffect, useMemo, useState } from "react";
import pluralize from "pluralize";
import { useDataFetch } from "src/hooks/useDataFetch";
import { getContactsLists } from "src/requests/contactsRequests";
import {
    Avatar,
    BreadCrumbs,
    EmptyBlock,
    SearchInput,
    SearchInputClasses,
    StyledAlert,
    StyledAlertTypes,
    StyledButtonTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes
} from "../../../components";
import { Route, matchPath, useHistory } from "react-router";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { useDispatch, useSelector } from "react-redux";
import { onToggleRightPanel } from "../../../actions/account-settings/account-settings-api";
import { navigate } from "../../../actions/global/global-api";
import { onUpdateToolBar } from "../../../actions/global/global";
import { TableVirtuoso } from "react-virtuoso";
import styles from "./ContactsListsSettings.module.scss";
import {
    ContactsListSettingsItem,
    ContactsListsHeader
} from "./components/ContactsListSettingsItem/ContactsListSettingsItem";
import classNames from "classnames";
import { ListSort } from "../../../components/list-sort/ListSort";
import { Dropdown } from "../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "../../../mpd-library/dropdown/components/target/constants";
import { Position } from "@blueprintjs/core";
import { ContactsListPreview } from "./components/ContactsListPreview/ContactsListPreview";
import { CreateNewContactsListStepper } from "./components/CreateNewContactsListStepper/CreateNewContactsListStepper";
import { ReactComponent as NoSearchResultsForIcon } from "src/mpd-library/icon/assets/NoSearchResultsFor.svg";
import { ReactComponent as NoListsIcon } from "src/mpd-library/icon/assets/NoListsIcon.svg";
import { deleteContactsLists, updateContactList } from "../../../requests/contactsRequests";
import { AppState } from "../../../store";
import { removeWsData } from "../../../actions/websocket/websocket";
import { ReactComponent as TrashIcon } from "src/mpd-library/icon/assets/trash.svg";
import { ReactComponent as ListTypesDropdownIcon } from "src/mpd-library/icon/assets/ListTypesDropdownIcon.svg";
import { ReactComponent as ListsSettingsCreatorDropdownIcon } from "src/mpd-library/icon/assets/ListsSettingsCreatorDropdownIcon.svg";
import { ReactComponent as ListsSettingsAllCreatorDropdownIcon } from "src/mpd-library/icon/assets/ListsSettingsAllCreatorDropdownIcon.svg";
import { getAccountSettingsUsersRequest } from "../../../requests";
import { MPDIcon, TSelectItemTypes } from "../../../mpd-library";
import { ReactComponent as VerticalTripleLineIcon } from "src/mpd-library/icon/assets/vertical-triple-line.svg";

const contacts_list_deleted = "contacts_list_deleted";
const topic = "portal_admin_general";

const TYPES_OPTIONS = [
    { label: "All Types", value: "", paramName: "type" },
    { label: "Static Lists", value: "static", paramName: "type" },
    { label: "Active Lists", value: "dynamic", paramName: "type" }
];

export const SORT_BY_VALUE = [
    { value: "name", label: "Name", paramName: "order" },
    { value: "updated_at", label: "Last Updated", paramName: "order" },
    { value: "inserted_at", label: "Last Created", paramName: "order" }
];

const FETCH_DATA_PARAMS_INIT_VALUE = {
    type: TYPES_OPTIONS[0],
    created_by_admin_id: { label: "All Creators", value: "" },
    order: { label: "Name", value: "name:asc" }
};

const ADMINS_DROPDOWN_INITIAL_ENTITES = [
    {
        id: "all",
        value: "",
        label: "All Creators",
        isSelectAllOption: true,
        paramName: "created_by_admin_id",
        LeftIconComponent: (props) => (
            <MPDIcon IconComponent={ListsSettingsAllCreatorDropdownIcon} className={props.className} />
        ),
        LeftTargetIconComponent: (props) => (
            <MPDIcon IconComponent={ListsSettingsCreatorDropdownIcon} className={props.className} />
        )
    }
];

const CREATORS_FETCH_DATA_PARAMS = {
    filter: "contacts_list_creators"
};

export const ContactsListsSettings = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { list_id } = useSelector(
        (state: AppState) => state.websocketReducer.wsData?.[topic]?.[contacts_list_deleted] || {}
    );
    const [listRefresh, setListRefresh] = useState<boolean>(false);
    const [selectedList, setSelectedList] = useState(null);
    const [editableList, setEditableList] = useState<any>(null);
    const [deletableList, setDeletableList] = useState<Array<any> | null>(null);
    const [selectedLists, setSelectedLists] = useState<{ [key: string]: { [key: string] } } | "all">({});

    const onSelectList = (e, list) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedLists({ ...(selectedLists !== "all" ? selectedLists : {}), [list.id]: list });
        } else {
            setSelectedLists(omit(selectedLists !== "all" ? selectedLists : {}, list.id));
        }
    };

    const onSelectAll = useCallback(
        (value) => {
            setSelectedLists(value ? "all" : {});
        },
        [selectedLists]
    );

    const {
        entities: lists,
        searchValue,
        setSearchValue,
        setAtBottomOfList,
        initialised,
        deleteEntity,
        updateEntites,
        count,
        fetchDataParams,
        setFetchDataParams
    } = useDataFetch({
        fetchDataRequest: getContactsLists,
        entityKey: "lists",
        skeletonLoading: true,
        listItemHeight: 60,
        fetchDataParams: FETCH_DATA_PARAMS_INIT_VALUE,
        refresh: listRefresh
    });

    useEffect(() => {
        if (list_id) {
            deleteEntity(list_id);
            dispatch(removeWsData(`${topic}.${contacts_list_deleted}`));
        }
    }, [list_id]);

    useEffect(() => {
        if (listRefresh) {
            setListRefresh(false);
        }
    }, [listRefresh]);

    useEffect(() => {
        const contactsListMatch = matchPath(history.location.pathname, {
            path: "/account-settings/lists",
            exact: true,
            strict: false
        });
        console.log("contactsListMatch", contactsListMatch);
        if (contactsListMatch) {
            updateToolBar();
        }
    }, [history.location.pathname]);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Lists" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: `/account-settings`, history }))
                    }}
                />
            ),
            rightElement: <CreateNewContactsListStepper setListRefresh={setListRefresh} />
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    const onOpenEditNameDialog = (list) => {
        setEditableList(list);
    };

    const onListNameChange = (e) => {
        setEditableList({ ...editableList, name: e.target.value });
    };

    const onSaveName = async () => {
        try {
            const res = await updateContactList(editableList);
            updateEntites({ [editableList.id]: res.data.list });
        } finally {
            setEditableList(null);
        }
    };

    const onOpenDeleteWarning = async (lists) => {
        setDeletableList(lists);
    };

    const deleteLists = async () => {
        const deletableListsIds = deletableList === "all" ? "all" : deletableList;
        await deleteContactsLists(deletableListsIds);
        deleteEntity(deletableListsIds);
        setSelectedLists({});
        setDeletableList(null);
    };

    const selectedListsCount = useMemo(() => Object.keys(selectedLists).length, [selectedLists]);
    const displayedCount = useMemo(() => (selectedLists === "all" ? count : selectedListsCount), [selectedLists]);

    const getCreators = async (params) => {
        const res = await getAccountSettingsUsersRequest(params);
        return {
            ...res,
            data: {
                ...res.data,
                users: res.data.users.map((user) => ({
                    ...user,
                    LeftIconComponent: () => (
                        <Avatar avatarUrl={user?.profile_pic?.url} status={user?.status} user={user} size={24} />
                    ),
                    paramName: "created_by_admin_id",
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.uuid
                }))
            }
        };
    };

    console.log("fetchDataParams", fetchDataParams);

    if (
        !searchValue &&
        initialised &&
        lists.allIds.length === 0 &&
        JSON.stringify(FETCH_DATA_PARAMS_INIT_VALUE) === JSON.stringify(fetchDataParams)
    ) {
        return (
            <EmptyBlock
                title={"No lists found"}
                subtitle={"Create your first contact list above"}
                IconComponent={NoListsIcon}
            />
        );
    }

    return (
        <>
            <Route
                path={"/account-settings/lists/:id"}
                render={() => <ContactsListPreview selectedList={selectedList} setSelectedList={setSelectedList} />}
            />
            <StyledAlert
                isOpen={!!editableList}
                type={StyledAlertTypes.primary}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={false}
                confirmButtonProps={{
                    // processing: removeViewProcessing,
                    // onClick: onDeleteView,
                    type: StyledButtonTypes.primary
                }}
                onCancel={() => onOpenEditNameDialog(null)}
                confirmButtonText={"Save"}
                cancelButtonText={"Cancel"}
                onConfirm={onSaveName}
                fullScreen
                globalContentProps={{
                    title: "Edit List Name",
                    hideDescription: true
                }}
                childrenWrapperClassName={styles["edit-list-name-dialog-content"]}
            >
                <StyledFloatingInput
                    value={editableList?.name}
                    placeholder={"LIST NAME"}
                    onChange={onListNameChange}
                    // type={StyledFloatingInputTypes.primary}
                    floatingType={StyledFloatingInputTypes.primary}
                />
            </StyledAlert>
            <StyledAlert
                isOpen={!!deletableList}
                type={StyledAlertTypes.warning}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={false}
                confirmButtonProps={{
                    // processing: removeViewProcessing,
                    // onClick: onDeleteView,
                    type: StyledButtonTypes.delete
                }}
                onCancel={() => onOpenDeleteWarning(null)}
                confirmButtonText={"Delete"}
                cancelButtonText={"Cancel"}
                onConfirm={deleteLists}
                fullScreen
                globalContentProps={{
                    title: `Remove ${pluralize("List", deletableList?.length)}?`,
                    description: `${
                        deletableList === "all" ? "All" : deletableList?.length > 1 ? deletableList?.length : ""
                    } ${pluralize("List", deletableList?.length)} will be deleted`
                }}
                actionConfirmationProps={{
                    confirmInputProps: {
                        placeholder: "confirmation",
                        hint: `Enter "delete ${deletableList?.length > 1 ? "lists" : "list"}" to confirm`
                    },
                    valueToMatch: `delete ${deletableList?.length > 1 ? "lists" : "list"}`
                }}
                childrenWrapperClassName={styles["edit-list-name-dialog-content"]}
            />
            {!selectedList && (
                <div className={styles["root"]}>
                    {initialised && (
                        <header className={styles["header"]}>
                            <div className={styles["header__left"]}>
                                <SearchInput
                                    placeholder={"Search lists..."}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    className={classNames(SearchInputClasses.list, styles["search-input"])}
                                />
                                <Dropdown
                                    position={Position.BOTTOM_LEFT}
                                    ArrowIconComponent
                                    targetClassName={DropdownTargetClasses.SELECT}
                                    staticOptions={TYPES_OPTIONS}
                                    selectedValue={fetchDataParams["type"]}
                                    contentBlockWidth={180}
                                    onStaticOptionClick={(option) => setFetchDataParams({ type: option })}
                                    LeftIconComponent={ListTypesDropdownIcon}
                                />
                                <Dropdown
                                    targetClassName={DropdownTargetClasses.SELECT}
                                    position={Position.BOTTOM_LEFT}
                                    ArrowIconComponent
                                    selectedValue={fetchDataParams["created_by_admin_id"]}
                                    contentBlockWidth={250}
                                    contentBlockHeight={280}
                                    searcHeaderProps={{
                                        title: "Creators"
                                    }}
                                    entityKey={"users"}
                                    fetchDataRequest={getCreators}
                                    fetchDataParams={CREATORS_FETCH_DATA_PARAMS}
                                    LeftIconComponent={
                                        fetchDataParams["created_by_admin_id"]?.LeftTargetIconComponent ||
                                        fetchDataParams["created_by_admin_id"]?.LeftIconComponent ||
                                        ListsSettingsCreatorDropdownIcon
                                    }
                                    // initialEntites={ADMINS_DROPDOWN_INITIAL_ENTITES}
                                    allOption={ADMINS_DROPDOWN_INITIAL_ENTITES[0]}
                                    onStaticOptionClick={(option) =>
                                        setFetchDataParams({ created_by_admin_id: option })
                                    }
                                />
                                {selectedListsCount > 0 && (
                                    <>
                                        <div className={styles["selected-counter"]}>{`${displayedCount} ${pluralize(
                                            "List",
                                            displayedCount
                                        )} Selected`}</div>
                                        <Dropdown
                                            id={"MOREDROPDOWN"}
                                            staticOptions={[
                                                {
                                                    label: "Delete permanently",
                                                    value: null,
                                                    onClick: () =>
                                                        onOpenDeleteWarning(
                                                            selectedLists === "all" ? "all" : Object.keys(selectedLists)
                                                        ),
                                                    type: TSelectItemTypes.delete
                                                }
                                            ]}
                                            usePortal
                                            ArrowIconComponent={VerticalTripleLineIcon}
                                            targetClassName={classNames(
                                                DropdownTargetClasses.SELECT,
                                                DropdownTargetClasses.MORE
                                            )}
                                            position={Position.BOTTOM_LEFT}
                                            portalStopPropagationEvents={["click"]}
                                            contentBlockWidth={220}
                                        />
                                        {/* <StyledAlert
                                            Target={({ onClick }) => (
                                                <StyledButton
                                                    type={StyledButtonTypes.secondary}
                                                    text={"Delete"}
                                                    onClick={onClick}
                                                    LeftIconComponent={({ className }) => (
                                                        <TrashIcon
                                                            className={classNames(className, styles["trash-icon"])}
                                                        />
                                                    )}
                                                />
                                            )}
                                            type={StyledAlertTypes.warning}
                                            globalContentProps={{
                                                title: "Delete lists?",
                                                description: `${displayedCount} ${pluralize(
                                                    "list",
                                                    displayedCount
                                                )} will be deleted`
                                            }}
                                            onConfirm={deleteLists}
                                            confirmButtonProps={{ type: StyledButtonTypes.delete, text: "Delete" }}
                                        /> */}
                                    </>
                                )}
                            </div>
                            <ListSort sortByValues={SORT_BY_VALUE} onChange={setFetchDataParams} />
                        </header>
                    )}
                    <TableVirtuoso
                        style={{ height: lists.allIds.length === 0 ? "60px" : "100%", minHeight: "60px" }}
                        className={classNames(styles["table"], lists.allIds.length === 0 && styles["no-results"])}
                        initialItemCount={30}
                        fixedItemHeight={60}
                        data={lists.allIds}
                        itemContent={(index, data) => {
                            const item = lists.byId[data];
                            return (
                                <ContactsListSettingsItem
                                    onOpenEditNameDialog={onOpenEditNameDialog}
                                    onDeleteContactsList={onOpenDeleteWarning}
                                    item={item}
                                    selectItem={setSelectedList}
                                    loadingState={data === "loading"}
                                    onSelectList={onSelectList}
                                    checked={selectedLists[data] || selectedLists === "all"}
                                    checkboxDisabled={selectedLists === "all"}
                                />
                            );
                        }}
                        fixedHeaderContent={() =>
                            initialised ? (
                                <ContactsListsHeader
                                    indeterminate={!isEmpty(selectedLists) && selectedLists !== "all"}
                                    onSelectAll={onSelectAll}
                                    checked={selectedLists === "all"}
                                />
                            ) : null
                        }
                        atBottomStateChange={setAtBottomOfList}
                        components={{
                            ScrollSeekPlaceholder: ({ index, data }) => {
                                const item = lists.byId[data];
                                return (
                                    <ContactsListSettingsItem
                                        onOpenEditNameDialog={onOpenEditNameDialog}
                                        onDeleteContactsList={onOpenDeleteWarning}
                                        item={item}
                                        selectItem={setSelectedList}
                                        loadingState
                                        onSelectList={onSelectList}
                                        checked={selectedLists[data] || selectedLists === "all"}
                                        checkboxDisabled={selectedLists === "all"}
                                    />
                                );
                            }
                        }}
                    />
                    {lists.allIds.length === 0 && initialised && (
                        <EmptyBlock
                            title={searchValue ? `No results for ‘${searchValue}’` : "No results for applied filters"}
                            subtitle={searchValue ? "Please try another query" : ""}
                            IconComponent={NoSearchResultsForIcon}
                        />
                    )}
                </div>
            )}
        </>
    );
};
