import { TOGGLE_ALERT_RIGHTPANEL } from "..";
import { AppState } from "../../store";
import { navigate } from "../global/global-api";
import { History } from "history";
import { getListItem } from "src/selectors";
import { UPDATE_SELECTED_ALERT } from "./types";
import { AppThunkDispatch } from "../types";

export const toggleRightPanel = () => (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
        alerts: { rightPanelExpanded },
    } = getState();

    dispatch({
        type: TOGGLE_ALERT_RIGHTPANEL,
        payload: !rightPanelExpanded,
    });
};

interface CloseRightPanelProps {
    history: History;
    pathname: string;
    id: string;
}
export const closeRightPanel = (ref: CloseRightPanelProps) => (
    dispatch: AppThunkDispatch,
    getState: () => AppState
) => {
    const {
        alerts: { alert },
    } = getState();
    const { history, pathname, id } = ref;

    const lists = alert.lists;
    const allAlertLists = [
        ...lists.active.alertsList,
        ...lists.expired.alertsList,
        ...lists.archived.alertsList,
        ...lists.draft.alertsList,
    ];
    const selectedAlert = getListItem(allAlertLists, id);
    selectedAlert.isSelected = false;
    dispatch({ type: UPDATE_SELECTED_ALERT, payload: undefined });
    dispatch(navigate({ pathname, history }));
};
