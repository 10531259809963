import { Text } from "@blueprintjs/core";
import { MPDIcon } from "../../mpd-library";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as RemoveCircleIcon } from '../../mpd-library/icon/assets/remove-circle.svg'

interface TagProps {
    id: string;
    label: string | JSX.Element;
    className?: string;
    color?: string;
    onDeleteClick?: (id: string) => void;
}

export const TagTypes = {
    list: "list",
    tag: "tag"
};

const TagClasses = {
    tag: styles["tag-main-wrapper"],
    list: styles["list-main-wrapper"]
};

export const Tag = ({ label, type, className, color, onDeleteClick }: TagProps) => {
    const onDelete = (e) => {
        e.stopPropagation();
        onDeleteClick?.();
    };

    return (
        // <div className={ ${className}`}>
        <div
            className={classNames(type ? TagClasses[type] : styles["tag-main-wrapper"], className)}
            style={{ backgroundColor: color }}
        >
            <Text className={styles["tag-label"]}>{label}</Text>
            {!!onDeleteClick && (
                <button className={styles["remove-button"]} onClick={onDelete}>
                    <MPDIcon IconComponent={RemoveCircleIcon} />
                </button>
            )}
        </div>
    );
};
