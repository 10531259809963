// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { BreadCrumbs } from "src/components";

import { IDetailView } from "../../../";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { bindActionCreators, Dispatch } from "redux";

export interface IInvoicesSettingsProps extends IDetailView {
    accountSettings?: AccountSettingsState;
}

class InvoicesSettings extends React.Component<IInvoicesSettingsProps> {
    public render() {
        const { className } = this.props;

        this.onUpdateToolBar = this.onUpdateToolBar.bind(this);
        this.onUpdateToolBar();

        const classes = classNames("sub-account-settings", className);
        return <div className={classes}>{/*  */}</div>;
    }

    private onUpdateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Invoices" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesSettings);
