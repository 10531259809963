// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IDetailView } from "../../../../";

import { Classes } from "src/mpd-library";

import {
    BreadCrumbs,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    SettingsTitle
} from "src/components";

import "./styles.scss";
import { AppState, AccountSettingsGeneralTeams } from "src/store";
import { bindActionCreators, Dispatch } from "redux";
import { updateTeams } from "src/actions/account-settings/account-settings-api";

interface ITeamsSettingsAddProps extends IDetailView {
    teams: AccountSettingsGeneralTeams;
    team: any;
    onSaveChanges: (teams: AccountSettingsGeneralTeams) => void;
}

interface ITeamsSettingsAddState {
    team: any;
}

class TeamsSettingsAdd extends React.Component<ITeamsSettingsAddProps, ITeamsSettingsAddState> {
    constructor(props: ITeamsSettingsAddProps) {
        super(props);
        this.onCancelChanges = this.onCancelChanges.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.onInputChanged = this.onInputChanged.bind(this);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.updateToolBar();

        const { team } = this.props;

        this.state = {
            team: JSON.parse(JSON.stringify(team))
        };
    }

    public componentDidUpdate(prevProps: ITeamsSettingsAddProps) {
        if (prevProps.routeProps !== this.props.routeProps) {
            this.updateToolBar();
        }
        if (prevProps.teams !== this.props.teams) {
            this.props.routeProps.history.push("/account-settings/teams");
        }
    }

    public render() {
        const { className } = this.props;
        const { team } = this.state;
        const classes = "teams-settings-add";
        return (
            <div className={classNames(classes, Classes.CONTAINER_PADDING, className)}>
                <SettingsTitle title="Team Information" />
                <div className={classNames(Classes.ASSIGN_BLOCK_PADDING)}>
                    <StyledFloatingInput
                        autoCapitalize="none"
                        autoCorrect="none"
                        floatingType={StyledFloatingInputTypes.primary}
                        value={team.name}
                        onChange={(e: any) => this.onInputChanged(e, "name")}
                        placeholder="TEAM NAME"
                        tabIndex={1}
                    />
                    <StyledFloatingInput
                        autoCapitalize="none"
                        autoCorrect="none"
                        floatingType={StyledFloatingInputTypes.primary}
                        value={team.description}
                        onChange={(e: any) => this.onInputChanged(e, "description")}
                        placeholder="TEAM DESCRIPTION"
                        tabIndex={1}
                    />
                </div>
            </div>
        );
    }

    private updateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Teams", pathname: "/account-settings/teams" },
                        {
                            text: "New Team",
                            pathname: "/account-settings/teams/add"
                        }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledDuoButtons
                    firstButtonClick={this.onCancelChanges}
                    secondButtonClick={this.onSaveChanges}
                    firstButtonText="Cancel"
                    secondButtonText="Save"
                    type={StyledDuoButtonsTypes.primary}
                />
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    }

    private onInputChanged(event: React.ChangeEvent<HTMLInputElement>, type: string) {
        const { team } = this.state;
        const value = event.currentTarget.value;
        team[type] = value;
        this.setState({ team });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    }

    private onCancelChanges() {
        const { routeProps } = this.props;
        routeProps.history.push("/account-settings/teams");
    }

    private onSaveChanges() {
        const { team } = this.state;
        const { teams, routeProps } = this.props;
        const pathname = routeProps.history.location.pathname;
        if (pathname.includes("add")) {
            const id = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();
            team.members = [];
            team.date = "8/28/18";
            team.id = id;
            team.active = true;
            teams.list.push(team);
        } else if (pathname.includes("edit")) {
            const index = teams.list.map((item: any) => item.id).indexOf(team.id);
            teams.list[index] = team;
        }
        this.props.onSaveChanges(teams);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const { teams } = accountSettings.general;
    return {
        teams
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onSaveChanges: updateTeams
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TeamsSettingsAdd);
