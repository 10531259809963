import classNames from "classnames";
import React from "react";

import { IMPDListEmptyProps, MPDListEmpty } from "./list-empty";

import { Classes, filterArrByProperty } from "../";

import { IMPDFilterType, IMPDSortTypes } from "./types";

import { IMPDListFullProps, MPDListFull } from "./list-full";

import "./styles.scss";

export interface IMPDSortType {
    keys?: Array<string>;
    value?: string;
}

export interface IMPDListProps extends IMPDListEmptyProps, IMPDListFullProps {
    filter?: string | IMPDFilterType;
    style?: any;
    loading?: boolean;
    maximumRendered?: number;
    sort?: string | Array<string>;
    sortOrder?: IMPDSortTypes;
}

interface IMPDListState {
    currentListPos: number;
    list: Array<any>;
}

export const MPDList = (Component: React.ComponentType<any>, loadingMoreComp) => {
    return class extends React.Component<IMPDListProps, IMPDListState> {
        public constructor(props: IMPDListProps) {
            super(props);
            this.onScrollBottom = this.onScrollBottom.bind(this);
            this.onIntializeList = this.onIntializeList.bind(this);
            const list = this.onIntializeList(this.props);
            this.state = {
                list,
                currentListPos: this.props.maximumRendered || 0
            };
        }

        public componentDidUpdate(prevProps: IMPDListProps) {
            if (
                this.props.list !== prevProps.list ||
                this.props.filter !== prevProps.filter ||
                this.props.maximumRendered !== prevProps.maximumRendered ||
                this.props.sortOrder !== prevProps.sortOrder ||
                this.props.filter !== prevProps.filter
            ) {
                const list = this.onIntializeList(this.props);
                this.setState({
                    ...this.state,
                    list,
                    currentListPos: this.props.maximumRendered || 0
                });
            }
        }

        public render() {
            const { className, emptyElement, style, hiddenScrollProps, ...props } = this.props as IMPDListProps;
            const classes = classNames("mpd-list-container", this.props.loading && Classes.LOADING, className);

            if (this.state.list === undefined || (this.state.list.length === 0 && !this.props.loading)) {
                return (
                    <React.Fragment>
                        {emptyElement ? (
                            <div className={classNames(classes, "mpd-list-container-centered", Classes.EMPTY)}>
                                <MPDListEmpty emptyElement={emptyElement} />
                            </div>
                        ) : null}
                    </React.Fragment>
                );
            }
            return (
                <MPDListFull
                    style={style}
                    className={classes}
                    onScrollBottom={this.onScrollBottom}
                    Component={Component}
                    list={this.state.list}
                    loadingMoreComp={loadingMoreComp}
                    hiddenScrollProps={hiddenScrollProps}
                    {...props}
                />
            );
        }

        private onIntializeList(ref: any) {
            const { filter, maximumRendered } = ref;
            let { list } = ref;
            if (list && filter) {
                if (filter.length > 0) {
                    list = filterArrByProperty(list, filter);
                } else if (filter.value) {
                    list = filterArrByProperty(list, filter.value, filter.keys);
                }
            }
            if (list && maximumRendered) {
                list = this.props.list.slice(0, maximumRendered);
            }
            return list;
        }

        private onScrollBottom(event: React.SyntheticEvent) {
            const { maximumRendered, onScrollBottom } = this.props;
            let { currentListPos } = this.state;
            if (maximumRendered && this.state.list.length >= currentListPos) {
                currentListPos = currentListPos + maximumRendered;
                const list = this.props.list.slice(0, currentListPos);
                this.setState({ currentListPos, list });
            }
            if (onScrollBottom) {
                onScrollBottom(event);
            }
        }
    };
};
