import moment from "moment";
import { store } from "src";
import { Conversation, ConversationsFilterValues, ViewEnum } from "src/store";

export const convertFilterValues = (filterValues: ConversationsFilterValues) => {
    const convertedFilterValues: any = {};
    const { order, status, period, queue_ids, admin_ids, tag_ids, user_ids, queues, admins, tags, users } =
        filterValues;
    if (status) {
        convertedFilterValues.status = filterValues.status;
    }

    if (period) {
        convertedFilterValues.period = filterValues.period;
    }

    if (filterValues.user) {
        convertedFilterValues.user_id = filterValues.user.user_id;
    }

    const queue_ids_keys = Object.keys(queue_ids || {});
    const admin_ids_keys = admin_ids === "all_admins" ? "all_admins" : Object.keys(admin_ids || {});
    const user_ids_keys = user_ids === "all_users" ? "all_users" : Object.keys(user_ids || {});

    const tag_ids_keys = Object.keys(tag_ids || {});

    if (queue_ids_keys.length) {
        convertedFilterValues.queue_ids = queue_ids_keys;
        convertedFilterValues.queues = queues;
    }

    if (admin_ids_keys.length) {
        convertedFilterValues.admin_ids = admin_ids_keys;
        convertedFilterValues.admins = admins;
    }

    if (tag_ids_keys.length) {
        convertedFilterValues.tag_ids = tag_ids_keys;
        convertedFilterValues.tags = tags;
    }

    if (user_ids_keys.length) {
        convertedFilterValues.user_ids = user_ids_keys;
        convertedFilterValues.users = users;
    }

    convertedFilterValues.order = order || "updated_at:desc";

    return convertedFilterValues;
};

const match = {
    queue_ids: true,
    status: true,
    period: true,
    admin_ids: true,
    tag_ids: true,
    order: true,
    user_ids: true
};

export const getRequiredFiltersForBE = (filterValues) => {
    const filters = {};
    Object.keys(match).forEach((key) => {
        if (filterValues[key]) {
            filters[key] = filterValues[key];
        }
    });
    return filters;
};

export const getFiltersCounter = (filterValues: ConversationsFilterValues) => {
    const filters = convertFilterValues(filterValues);

    let counter = 0;
    Object.keys(filters).forEach((filterKey) => {
        if (match[filterKey]) {
            counter = ++counter;
        }
    });

    if (filters.order === "updated_at:desc") {
        counter = --counter;
    }

    return counter;
};

export const getFiltersInitialState = (): ConversationsFilterValues => ({
    order: "updated_at:desc",
    status: null,
    queue_ids: {},
    admin_ids: {},
    user_ids: {},
    tag_ids: {},
    period: null
});

export const convertFiltersToClientFormat = (filters) => {
    const filterValues = {
        ...filters,
        order: filters.order,
        period: filters.period,
        status: filters.status,
        queue_ids: filters.queue_ids.reduce((accumulator: { [key: string]: boolean }, queueId: string) => {
            accumulator[queueId] = true;
            return accumulator;
        }, {}),
        tag_ids: filters.tag_ids.reduce((accumulator: { [key: string]: boolean }, tagId: string) => {
            accumulator[tagId] = true;
            return accumulator;
        }, {}),
        admin_ids:
            filters.admin_ids === "all_admins"
                ? "all_admins"
                : filters.admin_ids.reduce((accumulator: { [key: string]: boolean }, adminId: string) => {
                      accumulator[adminId] = true;
                      return accumulator;
                  }, {}),
        user_ids: filters.user_ids?.reduce((accumulator: { [key: string]: boolean }, userId: string) => {
            accumulator[userId] = true;
            return accumulator;
        }, {})
    };

    return filterValues;
};

export const getRightViews = (view: ViewEnum) => {
    switch (view) {
        case "snoozed":
            return "assigned";
        case "spam":
            return "resolved";
        case "mentions_resolved":
            return "mentions";
        default:
            return view;
    }
};

const checkPeriodConsistence = (periodKey: ConversationsFilterValuesPeriod, updatedAt: string) => {
    const object: { [key: ConversationsFilterValuesPeriod]: boolean } = {
        today: moment().startOf("day").unix(),
        yesterday: moment().subtract(1, "day").startOf("day").unix(),
        "30 days": moment().subtract(30, "day").unix(),
        "90 days": moment().subtract(90, "day").unix()
    };

    return object[periodKey] < moment(updatedAt).unix();
};

export const checkIfConversationSatisfyAppliedFilter = (conversation: Conversation) => {
    const currentAdminId = store.getState().userSettings.myInfo.uuid;
    const { conversationFilter, viewType, selectedQueueId } = store.getState().conversations;
    const { isFilterApplyed, filterValues, filterCounter } = conversationFilter;

    let counter = 0;

    if (currentAdminId !== conversation.admin_id && !!isFilterApplyed) {
        if (filterValues.order !== "updated_at:desc") {
            counter = ++counter;
        }
        if (filterValues.status && filterValues.status === filterValues.status) {
            counter = ++counter;
        }
        if (Object.keys(filterValues.queue_ids).length && !!filterValues.queue_ids[conversation.queue_id]) {
            counter = ++counter;
        }
        if (Object.keys(filterValues.admin_ids).length && !!filterValues.admin_ids[conversation.admin_id]) {
            counter = ++counter;
        }

        const findSameTag = Object.keys(filterValues.tag_ids).some((tagId: string) => {
            return conversation.tags.findIndex((tag: ConversationTag) => tag.id === tagId) !== -1;
        });

        if (filterValues.tag_ids.length && findSameTag) {
            counter = ++counter;
        }

        if (filterValues.period && checkPeriodConsistence(filterValues.period, conversation.updated_at)) {
            counter = ++counter;
        }

        if (filterCounter === counter) {
            return true;
        } else {
            return false;
        }
    }

    if (
        conversation.status === viewType &&
        (selectedQueueId === null || selectedQueueId === conversation.queue_id) &&
        (currentAdminId === conversation.admin_id || !conversation.admin_id)
    ) {
        return true;
    }

    return false;
};

export const sortConversations = (
    conversationsIds: Array<string>,
    order: string,
    conversationsById: { [key: string]: Conversation } = {}
) => {
    const sortedConversations = conversationsIds.sort((a, b) => {
        const fieldNameForSort = order.match(/.+?(?=:)/gm)[0];
        if (order.includes("asc")) {
            return (
                moment(conversationsById[a][fieldNameForSort]).unix() -
                moment(conversationsById[b][fieldNameForSort]).unix()
            );
        } else {
            return (
                moment(conversationsById[b][fieldNameForSort]).unix() -
                moment(conversationsById[a][fieldNameForSort]).unix()
            );
        }
    });

    return sortedConversations;
};
