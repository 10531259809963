import moment from "moment";
import pluralize from "pluralize";
import { COMPARISON_LABEL_MATCH, ICONS_TYPE_MATCH } from "./constants";
import styles from "./ContactsFilters.module.scss";

export const getDropdownTargetLabel = (condition, error) => {
    const { comparison, isComparisonValueEditable, value, label, type, meta } = condition;
    let comparisonLabelPart: string;

    if (comparison && isComparisonValueEditable && value) {
        comparisonLabelPart = `${COMPARISON_LABEL_MATCH?.[type]?.[comparison]} ${
            // meta?.name || (typeof value === "object" ? value.label : value)
            getValueLabel(condition)
        }`;
    } else if (comparison && !isComparisonValueEditable) {
        comparisonLabelPart = `${COMPARISON_LABEL_MATCH?.[type]?.[comparison]}`;
    } else if (error) {
        comparisonLabelPart = "is missing value";
    } else {
        comparisonLabelPart = "";
    }
    return (
        <span className={styles["filter-value"]}>
            <span className={styles["value-key-label"]}>{label}</span>
            &nbsp;
            {comparisonLabelPart}
        </span>
    );
};

export const formatFilters = (filters) => {
    return {
        ...filters,
        conditions: filters?.conditions?.map((condition) => {
            return {
                ...condition,
                conditions: condition.conditions?.map((condition) => {
                    let value;
                    if (condition.value?.type) {
                        value = condition?.value;
                    } else {
                        value = condition?.value?.value || condition?.value;
                    }
                    return {
                        field: condition.field,
                        value,
                        comparison: condition.comparison,
                        type: condition.type,
                        ...(condition.meta ? { meta: condition.meta } : {})
                    };
                })
            };
        })
    };
};

export const convertFiltersCallback = (filter) => {
    return {
        value: filter.field,
        label: filter.label,
        type: filter.type,
        LeftIconComponent: ICONS_TYPE_MATCH[filter.type]
    };
};

export const getValueLabel = (condition) => {
    const { type, value } = condition;
    switch (type) {
        case "date":
            if (!isNaN(value)) {
                return `${value} ${pluralize("days", value)} ago`;
            } else {
                return moment(value).format("MMM D, YYYY");
            }
        case "import_id":
        case "lists":
        case "tags":
            return `${condition.meta?.name}`;
        case "location":
        case "personal_data":
        case "phone":
        case "email":
        case "location":
            return `${value}`;
        case "place":
            return condition?.meta?.name ? `${condition?.meta?.name}` : "Custom Layer";
        default:
            return "";
    }
};
