import { useState, useEffect, useRef, forwardRef, useContext, createContext, useMemo, memo } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import styles from "./EditChannelsPhoneInput.module.scss";
import { ActionsButtons } from "../input/ActionsButtons";
import { CountrySelect, MPDFloatingPhoneInput } from "../floating-phone-input";
import en from "react-phone-number-input/locale/en";
import { MPDIcon } from "../icon";
import classNames from "classnames";
import { useOutsideAlerter } from "../../containers/conversations/components/chat/hooks/useOutsideAlerter";
import { updateToast } from "src/actions/global/global-api";
import { ToastTypes } from "../../components";
import { ReactComponent as EditChannelsCountrySelectDownArrowIcon } from "../icon/assets/EditChannelsCountrySelectDownArrow.svg";

const variants = {
    visible: { width: "100%", scale: 1, opacity: 1 },
    hidden: { width: 0, scale: 0, opacity: 0 }
};

const inputVariants = {
    visible: { x: 45 },
    hidden: { x: 0 }
};

const Context = createContext({});
const searcHeaderProps = {};

const CustomCS = memo((props) => {
    const { value, onCountrySelect } = props;
    const { editModeEnabled } = useContext(Context);
    return (
        <div className={styles["country-select-target-wrapper"]}>
            <CountrySelect
                usePortal={false}
                disabled={false}
                value={{ value }}
                searcHeaderProps={searcHeaderProps}
                CustomTarget={(props) => (
                    <motion.div
                        animate={editModeEnabled ? "visible" : "hidden"}
                        variants={variants}
                        className={classNames(props.className, styles["country-select-target"])}
                    >
                        <img
                            key={`${value}-flag-icon`}
                            alt={en[value]}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
                            className={"flag-icon"}
                        />
                        <MPDIcon IconComponent={EditChannelsCountrySelectDownArrowIcon} />
                    </motion.div>
                )}
            />
        </div>
    );
});

const InputComponent = forwardRef((props, forwardedRef) => {
    const { editModeEnabled, isFocused } = useContext(Context);
    const fallbackRef = useRef(null);
    const ref = forwardedRef || fallbackRef;

    useEffect(() => {
        if (isFocused) {
            setTimeout(() => {
                ref?.current?.focus();
            }, 2000);
        }
    }, [isFocused]);

    return (
        <motion.div
            variants={inputVariants}
            className={styles["phone-input-wrapper"]}
            animate={editModeEnabled ? "visible" : "hidden"}
        >
            <input {...props} ref={ref} />
        </motion.div>
    );
});

export const EditChannelsPhoneInput = ({ value, onChange, autoFocus, onRemoveClick, id }) => {
    const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState(false);
    const rootRef = useRef();
    const dispatch = useDispatch();

    const onEditClick = () => {
        setEditModeEnabled(true);
        setIsFocused(true);
    };

    const onOutsideClick = () => {
        setEditModeEnabled(false);
    };

    useOutsideAlerter(rootRef, onOutsideClick);

    useEffect(() => {
        if (autoFocus) {
            setIsFocused(true);
        }
    }, [autoFocus]);

    const onInputFocus = () => {
        setIsFocused(true);
        setEditModeEnabled(true);
    };

    const onInputBlur = () => {
        setIsFocused(false);
    };

    const onCopy = () => {
        try {
            navigator.clipboard.writeText(value);
            dispatch(updateToast({ toast: { type: ToastTypes.saved, value: "Value copied to clipboard" } }));
        } catch (err) {
            console.log("COPY ERROR >>>", err);
        }
    };

    const onRemove = () => {
        onRemoveClick(id);
    };

    const contextValue = useMemo(() => ({ editModeEnabled, isFocused }), [editModeEnabled]);

    return (
        <Context.Provider value={contextValue}>
            <div className={styles["root"]} ref={rootRef}>
                <MPDFloatingPhoneInput
                    autoFocus={isFocused}
                    international
                    value={value}
                    className={styles["phone-input"]}
                    inputComponent={InputComponent}
                    onChange={onChange}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    countryCallingCodeEditable={false}
                    countrySelectComponent={CustomCS}
                    // countrySelectComponent={() => (
                    //     <div style={{ width: "30px", height: "30px", background: "orange" }} />
                    // )}
                />
                {!editModeEnabled && (
                    <ActionsButtons
                        actions={["copy", "edit", "remove"]}
                        onEditClick={onEditClick}
                        onCopyClick={onCopy}
                        onRemoveClick={onRemove}
                    />
                )}
            </div>
        </Context.Provider>
    );
};
