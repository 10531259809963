import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { Position } from "@blueprintjs/core";
import { Dropdown } from "../../mpd-library/dropdown/dropdown";
import { formatFilters } from "./utils";
import styles from "./ContactsFilters.module.scss";
import { SaveViewDialog } from "../../containers/contacts/contacts-list/components/save-view-dialog/SaveViewDialog";
import { Tooltip, TooltipClsasses } from "../tooltip/tooltip";
import { FilterDropdownContent } from "../../containers/contacts/contacts-list/components/filter-dropdown-content/filter-dropdown-content";
import { ERROR_TOOTLTIP_TEXT, ICONS_TYPE_MATCH, LOGICAL_OPERATIONS_ITEMS } from "./constants";
import { StyledButton, StyledButtonTypes } from "../styled-button";
import { StyledAlert, StyledAlertTypes } from "../styled-alert";
import { ReactComponent as FilterDropdownPlusIcon } from "../../mpd-library/icon/assets/FilterDropdownPlus.svg";
import { ReactComponent as PlusIcon } from "../../mpd-library/icon/assets/plus.svg";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { IMPDButtonProps } from "../../mpd-library";
import { getFittedFilters } from "../../containers/contacts/utils";
import { FiltersContext } from "./context";
import { FilterConditionComponent } from "./components/FilterConditionComponent";

export type EditFiltersButtonProps = Partial<IMPDButtonProps> & {
    defaultText: string;
    editModeText?: string;
};

interface ContactsFiltersProps {
    presettedFilter: any;
    appliedFilters: any;
    savedView: string;
    contactsFiltersList: Array<any>;
    editFiltersButtonProps: EditFiltersButtonProps;
    disableFilters?: boolean;
    saveView?: () => void;
    editView?: (value: any) => void;
    setAppliedFilters: (value: any) => void;
    setPresettedFilter: (value: any) => void;
    deleteSavedView?: (id: string) => void;
}

export const ContactsFilters = ({
    presettedFilter,
    appliedFilters,
    filtersList,
    savedView,
    disableFilters,
    editFiltersButtonProps,
    setAppliedFilters,
    setPresettedFilter,
    deleteSavedView,
    saveView,
    editView
}: ContactsFiltersProps) => {
    const [isComparisonDropdownOpen, setIsComparisonDropdownOpen] = useState<{
        [key: string]: { [key: string]: boolean };
    }>({});
    const [filters, setFilters] = useState({ ...appliedFilters });

    const [removeViewProcessing, setRemoveViewProcessing] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const presettedFilterRef = useRef(null);

    useEffect(() => {
        if (presettedFilter) {
            const fittedFilter = getFittedFilters(presettedFilter);
            setFilters(fittedFilter);
            presettedFilterRef.current = presettedFilter;
            setPresettedFilter(null);
        }
    }, [presettedFilter]);

    const addFilter = (option) => {
        const lastGroup = filters?.conditions?.[filters?.conditions?.length - 1];
        const updatedFilters = {
            ...filters,
            // counter: filters?.counter || 0,
            operator: filters?.operator || "and",
            conditions: [
                // ...(!!filters?.conditions ? filters?.conditions : {}),
                ...(filters?.conditions?.length && lastGroup?.conditions?.length ? filters?.conditions : []),
                {
                    operator: "and",
                    conditions: [
                        {
                            field: option.value,
                            value: "",
                            // --------------
                            label: option.label,
                            iconName: option.iconName,
                            type: option.type
                        }
                    ]
                }
            ]
        };

        setFilters(updatedFilters);
        setIsComparisonDropdownOpen({
            ...isComparisonDropdownOpen,
            [updatedFilters.conditions.length - 1]: { 0: true }
        });
    };

    const addFilterToGroup = (groupIndex, conditionIndex, option) => {
        setFilters({
            ...filters,
            conditions: [
                ...filters?.conditions.slice(0, groupIndex),
                {
                    ...filters?.conditions[groupIndex],
                    conditions: [
                        ...filters?.conditions[groupIndex].conditions,
                        {
                            label: option.label,
                            iconName: option.iconName,
                            field: option.value,
                            value: "",
                            type: option.type
                        }
                    ]
                },
                ...filters?.conditions.slice(groupIndex + 1)
            ]
        });

        setIsComparisonDropdownOpen({
            ...isComparisonDropdownOpen,
            [groupIndex]: { [conditionIndex + 1]: true }
        });
    };
    const onLogicalOptionClick = (groupIndex: number, option) => {
        const updatedFilters = {
            ...filters,
            conditions: [
                ...filters.conditions.slice(0, groupIndex),
                { ...filters.conditions[groupIndex], operator: option.value },
                ...filters.conditions.slice(groupIndex + 1)
            ]
        };
        setAppliedFilters(formatFilters(updatedFilters));
        setFilters(updatedFilters);
    };

    const onChangeLogialOperatorBetweenGroups = (option) => {
        const updatedFilters = {
            ...filters,
            operator: option.value
        };
        setAppliedFilters(formatFilters(updatedFilters));
        setFilters(updatedFilters);
    };

    const onSomethingClick = (groupIndex, conditionIndex, option, applied) => {
        const updatedFilters = {
            ...filters,
            conditions: [
                ...filters.conditions.slice(0, groupIndex),
                {
                    ...filters.conditions[groupIndex],
                    conditions: [
                        ...filters.conditions[groupIndex].conditions.slice(0, conditionIndex),
                        {
                            ...filters.conditions[groupIndex].conditions[conditionIndex],
                            comparison: option.comparison,
                            value: option.value,
                            isComparisonValueEditable: option.isComparisonValueEditable,
                            error: option.error,
                            ...(option.meta ? { meta: option.meta } : {}),
                            ...(option.id ? { id: option.id } : {})
                        },
                        ...filters.conditions[groupIndex].conditions.slice(conditionIndex + 1)
                    ]
                },
                ...filters.conditions.slice(groupIndex + 1)
            ]
        };

        if (applied) {
            setAppliedFilters(formatFilters(updatedFilters));
            setFilters(updatedFilters);
        } else {
            setFilters(updatedFilters);
        }
    };

    const deleteFilter = (groupIndex: number, conditionIndex: number) => {
        if (filters.conditions.length === 1 && filters.conditions[0].conditions.length === 1) {
            setAppliedFilters(null);
            setFilters(null);
            return;
        }
        const filteredConditions = filters.conditions[groupIndex].conditions.filter(
            (_, index) => index !== conditionIndex
        );
        const updatedFilters = {
            ...filters,
            conditions: [
                ...filters.conditions.slice(0, groupIndex),
                ...(filteredConditions?.length
                    ? [
                          {
                              ...filters.conditions[groupIndex],
                              conditions: filteredConditions
                          }
                      ]
                    : []),
                ...filters.conditions.slice(groupIndex + 1)
            ]
        };

        setAppliedFilters(formatFilters(updatedFilters));
        setFilters(updatedFilters);
    };

    const hasErrors = useMemo(() => {
        return filters?.conditions?.some((conditionsGroup) => {
            return conditionsGroup?.conditions?.some((condition) => !!condition.error);
        });
    }, [filters]);

    const onDeleteView = async () => {
        try {
            setRemoveViewProcessing(true);
            await deleteSavedView?.(savedView?.id);
        } finally {
            setRemoveViewProcessing(false);
        }
    };

    const onEditClick = async () => {
        if (editMode) {
            await editView?.(filters);
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    };

    const disabled = useMemo(() => (editView && !editMode) || disableFilters, [disableFilters, editView, editMode]);

    const contextValue = useMemo(
        () => ({
            filters,
            disabled,
            isComparisonDropdownOpen,
            hasErrors,
            filtersList,
            deleteFilter,
            setIsComparisonDropdownOpen,
            onSomethingClick,
            onLogicalOptionClick,
            addFilterToGroup,
            addFilter,
            onChangeLogialOperatorBetweenGroups
        }),
        [
            filters,
            disabled,
            isComparisonDropdownOpen,
            hasErrors,
            filtersList,
            deleteFilter,
            setIsComparisonDropdownOpen,
            onSomethingClick,
            onLogicalOptionClick,
            addFilterToGroup,
            addFilter,
            onChangeLogialOperatorBetweenGroups
        ]
    );

    return (
        <FiltersContext.Provider value={contextValue}>
            <div
                className={classNames(
                    styles["filters-block"],
                    ((editView && !editMode) || disableFilters) && styles["disable-mask"]
                )}
            >
                {!isEmpty(filters) &&
                    filters?.conditions?.map((filterGroup, groupIndex, conditionsGroupArray) => (
                        <FilterConditionComponent
                            filterGroup={filterGroup}
                            groupIndex={groupIndex}
                            conditionsGroupArray={conditionsGroupArray}
                        />
                    ))}
                {isEmpty(filters) && (
                    <Dropdown
                        contentBlockWidth={250}
                        contentBlockHeight={620}
                        selectedValue={{ label: "Add Filter" }}
                        targetClassName={DropdownTargetClasses.SELECT}
                        LeftIconComponent={PlusIcon}
                        position={Position.BOTTOM_LEFT}
                        onStaticOptionClick={(option) => {
                            addFilter(option);
                        }}
                        staticOptions={filtersList}
                        entityKey={"filters"}
                        fieldNameForId={"value"}
                        disabled={hasErrors || disabled}
                    />
                )}
                {!savedView && deleteSavedView && saveView && !editView && (
                    <SaveViewDialog filters={filters} appliedFilters={appliedFilters} saveView={saveView} />
                )}
                {editFiltersButtonProps && (
                    <>
                        <StyledButton
                            type={StyledButtonTypes.primarySimple}
                            text={editMode ? editFiltersButtonProps.editModeText : editFiltersButtonProps.defaultText}
                            onClick={onEditClick}
                        />
                        {editMode && (
                            <StyledButton
                                type={StyledButtonTypes.primarySimple}
                                // type={StyledButtonTypes.deleteSimple}
                                text={"Cancel"}
                                onClick={() => {
                                    setEditMode(false);
                                    setPresettedFilter(presettedFilterRef.current);
                                }}
                                className={styles["cancel-edit-button"]}
                            />
                        )}
                    </>
                )}
                {deleteSavedView && !savedView?.is_default && (
                    <StyledAlert
                        Target={(props) => (
                            <>
                                {savedView && (
                                    <StyledButton
                                        {...props}
                                        text={"Delete View"}
                                        type={StyledButtonTypes.primarySimple}
                                        className={styles["remove-button"]}
                                    />
                                )}
                            </>
                        )}
                        type={StyledAlertTypes.warning}
                        canOutsideClickCancel={true}
                        canEscapeKeyCancel={false}
                        confirmButtonProps={{
                            // processing: removeViewProcessing,
                            // onClick: onDeleteView,
                            type: StyledButtonTypes.delete
                        }}
                        confirmButtonText={"Delete"}
                        cancelButtonText={"Cancel"}
                        onConfirm={onDeleteView}
                        className={styles["create-convo-popup"]}
                        fullScreen
                        globalContentProps={{
                            title: "Delete View",
                            description: `Are you sure you want to delete your view “${savedView?.name}”? This cannot be undone.`
                        }}
                    />
                )}
            </div>
        </FiltersContext.Provider>
    );
};
