import { createContext, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Text } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { onUpdateToast, onUpdateToolBar } from "src/actions/global/global";
import { BreadCrumbs, Stepper, StepperStates, StyledButton, StyledButtonTypes } from "src/components";
import { SmartLinkOverview } from "../smart-link-overview/smart-link-overview";
import styles from "./styles.module.scss";
import { FormikValues, useFormik } from "formik";
import { useQueues, UseQueues } from "src/containers/conversations/components/chat/hooks/useQueues";
import { FORMIK_SMART_LINKS_VALIDATION_SCHEMA } from "../../constants";
import {
    childFactory,
    useSwitchingStepsAnimation
} from "src/components/switching-steps-animation/switching-steps-animation";
import { onGetChannelsArray } from "src/actions/account-settings/account-settings-api";
import { SmartLinksQrCode } from "../../../../../../components/smart-link-qr-code/smart-links-qr-code";
import { postMediaRequest } from "../../../../../../requests";
import { createSmartLink, getSmartLinksPublicUrl, SmartLink } from "../../../../../../requests/smartLinksRequests";
import { SmartLinksRoutingSettings } from "../smart-link-settings/smart-link-settings";
import { getKeys, getStepsWithErrors, setFormikFieldErrors } from "../../../../../../common/utils";
import { ReactComponent as CloseIcon } from "../../../../../../mpd-library/icon/assets/close.svg";
import { ReactComponent as LeftArrowBlackIcon } from "../../../../../../mpd-library/icon/assets/left-arrow-black.svg";
import { AppState } from "../../../../../../store";

const classname = "smart-link-add";

const baseUrl = "account-settings/smart-links/create";

export const STEPPER_DATA_INIT_DATA = [
    {
        title: "Overview",
        nav: "overview",
        state: StepperStates.active,
        num: "1",
        active: true
    },
    // {
    //     title: "Accessibility",
    //     nav: "accessibility",
    //     state: StepperStates.default,
    //     num: "2",
    //     active: false
    // },
    {
        title: "Routing",
        nav: "routing",
        state: StepperStates.default,
        num: "2",
        active: false
    },
    {
        title: "QR Code Design",
        nav: "qrcode",
        state: StepperStates.default,
        num: "3",
        active: false
    }
];

const STEPS_FIELDS_MATCH_INDEX = {
    name: 0,
    description: 0,
    keyword: 0
};

export const FormikContext = createContext({});

type SmartLinkAddProps = {
    selectedSmartLink: SmartLink | null;
    onSmartLinkCreate: (smartLink: SmartLink) => void;
};

export const SmartLinkAdd = ({ selectedSmartLink, onSmartLinkCreate }: SmartLinkAddProps) => {
    const [stepperData, setStepperData] = useState<any>(STEPPER_DATA_INIT_DATA);
    const activeStepIndex: MutableRefObject<number> = useRef<number>(0);
    const [whereToScroll, setWhereToscroll] = useState<"bottom" | "top">("bottom");
    const [saveSmartLinkProcessing, setSaveSmartLinkProcessing] = useState<boolean>(false);
    const wasMount: MutableRefObject<boolean> = useRef<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const cssTransitionAnimationClasses = useSwitchingStepsAnimation({ whereToScroll });
    const selectedSmartLinkRef = useRef<SmartLink>(null);
    const [backEndValidateErrors, setBackEndValidateErrors] = useState<{ [key: number]: string }>({});

    const { default_queue: defaultQueue } = useSelector((state: AppState) => state.accountSettings.organization);

    const setStepWithError = (err?: any, actions?: any, keys?: any) => {
        actions && err && setFormikFieldErrors(err, actions);
        const stepsNumbers = getStepsWithErrors(
            STEPS_FIELDS_MATCH_INDEX,
            keys || getKeys(err || backEndValidateErrors)
        );
        const updatedSteps = stepperData.map((stepData, index) => {
            if (stepsNumbers[index]) {
                return {
                    ...stepData,
                    error: true
                };
            }

            return { ...stepData, error: false };
        });
        setStepperData(updatedSteps);
    };

    const initialValues = {
        name: "",
        description: "",
        keyword: "",
        queue: defaultQueue,
        admin: "",
        tags: [],
        qrCodeStyle: "squares",
        qrCodeBgColor: "#000000",
        qrCodeColor: "#FFFFFF",
        qrCodeFile: null
        // qrCodeImgUrl: null
    };

    const formikValues: FormikValues = useFormik<FormikValues>({
        enableReinitialize: true,
        initialValues,
        isInitialValid: false,
        // validateOnMount: true,
        validationSchema: FORMIK_SMART_LINKS_VALIDATION_SCHEMA[activeStepIndex.current],
        onSubmit: async (values, actions) => {
            if (activeStepIndex.current === 2) {
                try {
                    setSaveSmartLinkProcessing(true);
                    const res = await postMediaRequest({
                        type: "image/png",
                        filename: "qr.png",
                        key: "smart_link_qr_url"
                    });
                    const { preview_url, presigned_url } = res.data;

                    await axios.put(presigned_url, values.qrCodeFile, {
                        headers: { "Content-Type": values.qrCodeFile.type }
                    });

                    const id = uuidv4();

                    const {
                        data: { public_url }
                    } = await getSmartLinksPublicUrl(id);

                    const createSmartLinkRes = await createSmartLink({
                        id,
                        name: values.name,
                        description: values.description,
                        keyword: values.keyword,
                        public_url,
                        conversations_settings: {
                            queue_id: values.queue?.id,
                            admin_id: values.admin?.id,
                            tag_ids: values.tags.map((tag) => tag.id)
                        },
                        qr_code_params: {
                            qr_code_style: values.qrCodeStyle,
                            qr_code_bg_color: values.qrCodeBgColor,
                            qr_code_color: values.qrCodeColor,
                            qr_code_img_url: preview_url
                        }
                    });

                    onSmartLinkCreate(createSmartLinkRes.data.smart_link);
                    dispatch(onUpdateToast({ type: "saved", value: "Smart Link was successfully created." }));

                    history.replace("/account-settings/smart-links");
                } catch (err) {
                    setBackEndValidateErrors(err);
                    setStepWithError(err, actions);
                    // setStepsWithErrors(stepsNumbers);
                } finally {
                    setSaveSmartLinkProcessing(false);
                }
            } else {
                try {
                    onNextClick();
                    actions.setTouched({});
                    actions.setSubmitting(false);
                } catch (err) {
                    console.log("SUBMIT >>>>> errr", err);
                }
            }
        }
    });

    useEffect(() => {
        if (wasMount.current) {
            setStepWithError(undefined, undefined, Object.keys(formikValues.errors));
        }
    }, [formikValues.errors]);

    const { submitForm, isValid, errors } = formikValues;

    const checkAndJumpToStep = useCallback(
        (stepIndex): void => {
            if (stepIndex > activeStepIndex.current && stepIndex === 3 && !selectedSmartLink) {
                dispatch(
                    onUpdateToast({
                        type: "error",
                        value: "QR code generator will be available after smart link will be created on third step"
                    })
                );
                return;
            }
            if (stepIndex > activeStepIndex.current) {
                submitForm().then(() => {
                    if (isValid) {
                        changeActiveStep(stepIndex);
                    } else {
                        dispatch(onUpdateToast({ type: "error", value: "Validation error. Please check the form" }));
                    }
                });
            } else {
                changeActiveStep(stepIndex);
            }
        },
        [submitForm, isValid, stepperData]
    );

    const changeActiveStep = useCallback(
        (stepIndex: number) => {
            const updatedWtepperData = [
                ...stepperData.map((step, index) => {
                    if (index === stepIndex) {
                        return {
                            ...step,
                            active: true,
                            state: StepperStates.active
                        };
                    }
                    return {
                        ...step,
                        active: false,
                        state: StepperStates.default
                    };
                })
            ];
            setStepperData(updatedWtepperData);
            setWhereToscroll(stepIndex >= activeStepIndex.current ? "bottom" : "top");
            activeStepIndex.current = stepIndex;
            history.push(`/${baseUrl}/${STEPPER_DATA_INIT_DATA[stepIndex].nav}`);
        },
        [stepperData]
    );

    useEffect(() => {
        updateToolBar();
        changeActiveStep(0);
        if (!wasMount.current) {
            wasMount.current = true;
        }
        dispatch(onGetChannelsArray());
    }, []);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Smart Links", pathname: "/account-settings/smart-links" },
                        { text: "New Smart Link" }
                    ]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => null,
                        onClose: () => null
                    }}
                />
            ),
            rightElement:
                match.path === "/account-settings/smart-links/create" ? (
                    <StyledButton
                        className="actions-button"
                        type={StyledButtonTypes.primarySimple}
                        IconComponent={CloseIcon}
                        onClick={() => history.push("/account-settings/smart-links")}
                    />
                ) : (
                    <StyledButton
                        className="actions-button"
                        type={StyledButtonTypes.primary}
                        text={"New Smart Link"}
                        onClick={() => history.push("/account-settings/smart-links/create")}
                    />
                )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    const onNextClick = useCallback(() => {
        changeActiveStep(activeStepIndex.current + 1);
    }, [activeStepIndex.current]);

    const onBackClick = () => {
        changeActiveStep(activeStepIndex.current - 1);
    };
    useEffect(() => {
        selectedSmartLinkRef.current = selectedSmartLink;
    }, [selectedSmartLink]);

    const availableSteps = useMemo(() => {
        if (selectedSmartLink) {
            return [0, 1, 2, 3];
        } else {
            return [0, 1, 2];
        }
    }, [selectedSmartLink]);

    if (!wasMount.current) {
        return <Redirect to="/account-settings/smart-links/create/overview" />;
    }

    return (
        <form onSubmit={formikValues.handleSubmit} className={styles[classname]}>
            <div className={styles[`${classname}-content`]}>
                <div className={styles[`${classname}-stepper-wrapper`]}>
                    <Text className={styles[`${classname}-stepper-title`]}>NEW SMART LINK</Text>
                    <Stepper
                        data={stepperData}
                        onItemClick={checkAndJumpToStep}
                        className={"vertical"}
                        availabaleSteps={availableSteps}
                    />
                </div>
                <TransitionGroup
                    className={styles[`${classname}-tr-group`]}
                    childFactory={childFactory(cssTransitionAnimationClasses)}
                >
                    <CSSTransition timeout={300} key={location.pathname}>
                        <Switch location={location}>
                            <Route
                                path={"/account-settings/smart-links/create/overview"}
                                render={() => (
                                    <div className={styles[`${classname}-route-wrapper`]}>
                                        <h1 className={styles[`${classname}-step-title`]}>Overview</h1>
                                        <SmartLinkOverview formikValues={formikValues} />
                                    </div>
                                )}
                            />
                            <Route
                                path={"/account-settings/smart-links/create/routing"}
                                render={() => (
                                    <div className={styles[`${classname}-route-wrapper`]}>
                                        <h1 className={styles[`${classname}-step-title`]}>Routing</h1>
                                        <SmartLinksRoutingSettings formikValues={formikValues} />
                                    </div>
                                )}
                            />
                            <Route
                                path={"/account-settings/smart-links/create/qrcode"}
                                render={() => (
                                    <div className={styles[`${classname}-route-wrapper`]}>
                                        <h1 className={styles[`${classname}-step-title`]}>QR Code Design</h1>
                                        <SmartLinksQrCode
                                            selectedSmartLink={selectedSmartLink}
                                            formikValues={formikValues}
                                            setFile={(qrCodeImage) =>
                                                formikValues.setFieldValue("qrCodeFile", qrCodeImage)
                                            }
                                        />
                                    </div>
                                )}
                            />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <div className={styles[`${classname}-footer`]}>
                {activeStepIndex.current > 0 ? (
                    <StyledButton
                        className={"bottom-toolbar-back-button"}
                        text="BACK"
                        LeftIconComponent={LeftArrowBlackIcon}
                        onClick={onBackClick}
                    />
                ) : (
                    <div />
                )}
                <StyledButton
                    onClick={formikValues.handleSubmit}
                    type={StyledButtonTypes.primary}
                    text={activeStepIndex.current < 2 ? "Next" : "Save"}
                    disabled={!isEmpty(errors)}
                    processing={saveSmartLinkProcessing}
                />
            </div>
        </form>
    );
};
