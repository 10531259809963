import { AppState } from "../../store";
import { Dispatch } from "redux";
import {
    UPDATE_WEB_SOCKET,
    UPDATE_TOPICS_LIST,
    UPDATE_WEB_SOCKET_STATUS,
    RESET_ALERT_PREVIEW,
    WebSocketTypes,
    SET_WS_DATA,
    REMOVE_WS_DATA
} from "./types";
import { onPutThumbnails } from "../alerts/alerts-compose";
import { setGeneratedPreview } from "../alerts/alerts-compose-api";
import { updateSelectedAlertAction } from "../alerts/alerts";
import {
    onGetConversationMessageFromSocket,
    onGetNewConversation,
    onUpdateConversationsCountsFromWebSocket
} from "../conversations/conversations-api";
import { onGetPushNotification } from "../notifications/notifications-api";
import { updateCurrentUserFromWebSocket } from "../account-settings/account-settings-api";
import { addTypingStatus, endOfTyping } from "../conversationsMessages/conversationsMessagesApi";
import { updateNewApiRequestFromSocket } from "../account-settings/account-settings";
import { foo } from "../../containers/contacts/components/ContactActivity/ContactActivity";

// const PORTAL_CONVERSATIONS = "portal_conversations";
// const PORTAL_GENERAL = "portal_general";
// const PORTAL_ALERTS = "portal_alerts";

export const setWsData = (data, event, topic) => (dispatch) => {
    dispatch({
        type: SET_WS_DATA,
        payload: {
            data,
            event,
            topic
        }
    });
};

const THUMBNAIL_GENERATED = "thumbnail_generated";

const PREVIEW_GENERATED = "preview_generated";
const ALERT_CREATED = "alert_created";
// const ALERT_UPDATED = "alert_updated";
const ALERT_CHANNEL_UPDATED = "alert_channel_updated";
const MESSAGE = "message";
const TYPING = "typing";
const CONVERSATION = "conversation";
const GET_PUSH_NOTIFICATION = "notification";
const COUNTS = "counts";
const END_TYPING = "end_typing";
const ADMIN_UPDATED = "admin_updated";
const EXTERNAL_API_REQUEST = "external_api_request";
const CONTACT_ACTIVITY_CREATED = "contact_activity_created";
const CONTACTS_SUMMARY_UPDATED = "contacts_summary_counts";
const CONTACTS_LIST_DELETED = "contacts_list_deleted";
const CONTACTS_TAGS_COUNT_UPDATED = "contacts_tags_count_updated";

const eventsActions = {
    [THUMBNAIL_GENERATED]: (data) => onPutThumbnails(data),
    [PREVIEW_GENERATED]: (data) => setGeneratedPreview(data),
    [ALERT_CREATED]: (data) => updateSelectedAlertAction(data),
    [ALERT_CHANNEL_UPDATED]: (data) => updateSelectedAlertAction(data),
    [MESSAGE]: (data) => onGetConversationMessageFromSocket(data),
    [TYPING]: (data) => addTypingStatus(data),
    [CONVERSATION]: (data) => onGetNewConversation(data, true),
    [GET_PUSH_NOTIFICATION]: (data) => onGetPushNotification(data),
    [COUNTS]: (data) => onUpdateConversationsCountsFromWebSocket(data),
    [END_TYPING]: (data) => endOfTyping(data),
    [ADMIN_UPDATED]: (data) => updateCurrentUserFromWebSocket(data),
    [EXTERNAL_API_REQUEST]: (data) => updateNewApiRequestFromSocket(data),
    [CONTACT_ACTIVITY_CREATED]: setWsData,
    [CONTACTS_SUMMARY_UPDATED]: setWsData,
    [CONTACTS_LIST_DELETED]: setWsData,
    [CONTACTS_TAGS_COUNT_UPDATED]: setWsData
};

export const updateWebSocket = (socket: WebSocket | null) => (dispatch: Dispatch<WebSocketTypes>) => {
    dispatch({
        type: UPDATE_WEB_SOCKET,
        payload: socket
    });
};

export const subscribeToTopic = (topic: string) => (dispatch: Dispatch<WebSocketTypes>, getState: () => AppState) => {
    const organizationId = getState().accountSettings.organization.details.uuid;
    const adminId = getState().userSettings.myInfo.uuid;
    const socket = getState().websocketReducer.webSocket;
    const webSocketTopics = getState().websocketReducer.webSocketTopics;
    const socket_payload = JSON.stringify({
        topic:
            topic === "portal_admin_conversations" || topic === "portal_admin_general"
                ? `${topic}:${adminId}`
                : `${topic}:${organizationId}`,
        event: "phx_join",
        payload: {},
        ref: null
    });

    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(socket_payload);
    }

    if (!webSocketTopics.includes(topic)) {
        dispatch({
            type: UPDATE_TOPICS_LIST,
            payload: topic
        });
    }
};

export const onGetDataFromSocket = (data: any) => (dispatch: Dispatch<any>) => {
    if (data.topic !== "phoenix" && data.event !== "phx_reply") {
        dispatch(eventsActions[data.event](data.payload, data.event, data.topic));
    }
};

export const onUpdateWebScoketStatus = (status: string | number) => (dispatch: Dispatch<WebSocketTypes>) => {
    dispatch({
        type: UPDATE_WEB_SOCKET_STATUS,
        payload: status
    });
};

export const resetAlertPreview = (topic: string) => (dispatch: Dispatch<WebSocketTypes>) => {
    dispatch({
        type: RESET_ALERT_PREVIEW,
        topic
    });
};

export const removeWsData = (path) => (dispatch) => {
    dispatch({
        type: REMOVE_WS_DATA,
        payload: path
    });
};
