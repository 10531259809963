import { OnUpdateCardBottomToolBarProps } from "src/actions/studio/cards/add-card";
import { StudioAttachment } from "./commonInterfaces";
import { ProfilePic } from "./commonInterfaces";
import { Entries } from "./collection";
import moment from "moment";

// its just comment which should be removed soon 

export interface Card {
    id: number;
    title: string;
    about?: string;
    account?: {
        profile_pic?: ProfilePic;
        title?: string;
    };
    author?: {
        first_name?: string;
        last_name?: string;
        profile_pic?: string;
    };
    address?: string;
    banner?: {
        name: string;
        type: string;
        url: string;
        color?: string;
        size?: number;
    };
    type: "info" | "event" | "place" | "incident";
    status?: string;
    inserted_at?: Date;
    updated_at?: Date;
    start_at?: number;
    end_at?: number;

    location?: {
        type: string;
        coordinates: Array<number>;
    };
    geo_fence?: {
        type: string;
        coordinates: Array<any>;
    };

    hours_of_operation?: string;
    push_notification?: {
        enabled: boolean;
        default: boolean;
        title: string;
        description: string;
    };
    startDate: number | Date;
    endDate: number | Date; 
}

export interface CardStepper {
    active: number;
}

export interface AddCardData extends Card {
    bottomBarProps: OnUpdateCardBottomToolBarProps | null;
    loading: boolean;
    stepper: CardStepper;
    file: any;
    startTime: string;
    endTime: string;
    startDate: Date;
    endDate: Date;

    error: {
        title: string;
        type: string;
        about: string;
        address: string;
        start_at: string;
        end_at: string;
        hours_of_operation: string;
    };

    isOpenGradient: boolean;
    bannerAttachments: Array<StudioAttachment>;
    bannerChange: StudioAttachment;
    push_notification: {
        enabled: boolean;
        default: boolean;
        title: string;
        description: string;
    };
}

export interface ActionData {
    type: string;
    title: string;
    phone_number?: string;
    email?: string;
    url?: string;
    icon: {
        type: string;
        url: string;
        color?: string;
        name?: string;
        size?: number;
    };
    id: number;
    position: any;
}

export interface CollectionData {
    author?: {
        first_name: string;
        last_name: string;
        profile_pic: {
            type: string;
            url: string;
            size?: number;
            color?: string;
            name?: string;
        };
    };
    description: string;
    entries_count: number;
    id: number;
    title: string;
    type: string;
    icon: {
        attachment_id: number;
        color: string;
        type: string;
        url: string;
    };
    entries: Array<Entries>;
    inserted_at: string;
    updated_at: string;
}

export interface Panel {
    id: number;
    position: any;
    type: string;
    inserted_at: string;
    updated_at: string;
    action?: ActionData;
    actions?: Array<ActionData>;
    collection?: CollectionData;
    content?: any;
}

export interface CardStateCardList {
    data: Array<Card>;
    loading: boolean;
    isFull: boolean;
}

export type CardsListTypes = "all" | "mine" | "published" | "draft" | "archived";

export interface CardStateCards {
    all: CardStateCardList;
    mine: CardStateCardList;
    published: CardStateCardList;
    draft: CardStateCardList;
    archived: CardStateCardList;
}

export interface CardStateSelectedCard {
    currentCard: Card;
    panels: Array<Panel>;
    currentPanel: Panel;
    currentAction: ActionData;
}

export interface CardState {
    cards: CardStateCards;
    addCard: AddCardData;
    selectedCard: CardStateSelectedCard;
}

export const defaultCardState: CardState = {
    addCard: {
        bottomBarProps: null,
        loading: false,
        stepper: {
            active: -1
        },
        id: 0,
        title: "",
        type: "incident",
        about: "",
        address: "",
        file: [],
        hours_of_operation: "",
        banner: {
            type: "",
            url: "",
            color: "",
            name: "",
            size: 0
        },
        error: {
            title: "",
            type: "",
            about: "",
            address: "",
            start_at: "",
            end_at: "",
            hours_of_operation: ""
        },
        location: {
            type: "",
            coordinates: []
        },
        geo_fence: {
            type: "",
            coordinates: []
        },
        isOpenGradient: false,
        bannerAttachments: [],
        bannerChange: {
            body: {
                type: "",
                url: ""
            },
            id: 0,
            title: "",
            type: "",
            inserted_at: "",
            updated_at: ""
        },
        push_notification: {
            enabled: false,
            default: false,
            title: "",
            description: ""
        },
        startTime: "",
        endTime: "",
        startDate: new Date(),
        endDate: new Date(),
    },
    cards: {
        all: {
            data: [],
            loading: false,
            isFull: false
        },

        mine: {
            data: [],
            loading: false,
            isFull: false
        },
        published: {
            data: [],
            loading: false,
            isFull: false
        },
        draft: {
            data: [],
            loading: false,
            isFull: false
        },
        archived: {
            data: [],
            loading: false,
            isFull: false
        }
    },
    selectedCard: {
        currentCard: {
            id: 0,
            title: "",
            type: "incident",
            about: "",
            account: {
                profile_pic: {
                    type: "",
                    url: ""
                }
            },
            author: {
                first_name: "",
                last_name: "",
                profile_pic: ""
            },
            address: "",
            banner: {
                name: "",
                url: "",
                type: ""
            },
            status: "",
            start_at: moment().unix(),
            end_at: moment().unix(),
            startDate: moment().unix(),
            endDate: moment().unix(),
            hours_of_operation: ""
        },
        panels: [],
        currentPanel: {
            id: 0,
            position: "",
            type: "",
            inserted_at: "",
            updated_at: "",
            actions: []
        },
        currentAction: {
            id: 0,
            position: "",
            title: "",
            type: "call",
            url: "",
            phone_number: "",
            email: "",
            icon: {
                type: "",
                url: "",
                color: "#747D8C",
                name: "",
                size: 0
            }
        }
    }
};
