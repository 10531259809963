import {
    AccountSettingsState,
    UserCreationErrors,
    AccountSettingsActivity,
    UserActivityByIdState,
    JurisdictionFeature,
    AccountSettingsGeneralCurrentUser,
    AccountSettingsGeneral,
    AccountSettingsOrganization,
    AccountSettingsGeneralUsers,
    AccountSettingsGeneralRoles,
    AccountSettingsAlerts,
    Channels,
    ChannelsAvailable,
    ArrayChannel
} from "src/store";
import { AccountPrivilege } from "src/requests";

export const ACCOUNT_SETTINGS_TOGGLE_RIGHTPANEL = "account-settings-toggle-rightpanel";
export const ACCOUNT_SETTINGS_UPDATE = "account-settings-update";
export const ACCOUNT_SETTINGS_LOADING_UPDATE = "account-settings-loading-update";
export const ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE = "account-settings-creating-user-errors-update";
export const ACCOUNT_SETTINGS_SELECT_LAYER_IN_MAP_LAYERS_UPDATE = "account-settings-select-layer-in-map-layers-update";
export const ACCOUNT_SETTINGS_ACTIVITY_LOG_UPDATE = "account-settings-activity-log-update";
export const ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_LOG_UPDATE = "account-settings-is-full-activity-log-update";
export const ACCOUNT_SETTINGS_ACTIVITY_BY_ID_UPDATE = "account-settings-activity-by-id-update";
export const ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_BY_ID_UPDATE = "account-settings-is-full-activity-by-id-update";
export const ACCOUNT_SETTINGS_ACTIVITY_TYPES_UPDATE = "account-settings-activity-types-update";
export const ACCOUNT_SETTINGS_IS_ACTIVITIES_LOADING_UPDATE = "account-settings-is-activities-loading-update";
export const ACCOUNT_SETTINGS_USERS_ACTIVITIES_BY_ID_UPDATE = "account-settings-users-activities-by-id-update";
export const ACCOUNT_SETTINGS_IS_MAP_LAYERS_FULL_UPDATE = "account-settings-is-map-layers-full-update";
export const ACCOUNT_SETTINGS_SEARCH_MAP_LAYERS_UPDATE = "account-settings-search-map-layers-update";
export const ACCOUNT_SETTINGS_IS_SEARCH_MAP_LAYERS_FULL_UPDATE = "account-settings-is-search-map-layers-full-update";
export const ACCOUNT_SETTINGS_LOADING_MAP_LAYERS_UPDATE = "account-settings-loading-map-layers-update";
export const UPDATE_ALL_PRIVILEGES = "update-all-privileges";
export const UPDATE_ACCOUNT_JURISDICTION = "update-account-jurisdiction";
export const UPDATE_ACCOUNT_SETTINGS_CURRENT_USER = "update-account-settings-current-user";
export const UPDATE_ACCOUNT_SETTINGS_GENERAL = "update-account-settings-general";
export const UPDATE_ACCOUNT_SETTINGS_ORGANIZATION = "update-account-settings-organization";
export const UPDATE_ACCOUNT_SETTINGS_GENERAL_USERS = "update-account-settings-general-users";
export const UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES = "update-account-settings-general-roles";
export const UPDATE_ACCOUNT_SETTINGS_ALERTS = "update-account-settings-alerts";
export const GET_CHANNELS = "get-channels";
export const CHANGE_GET_CHANNELS_PROCESSING_STATUS = "change-get-channels-processing-status";
export const UPDATE_NEW_API_REQUEST_FROM_SOCKET = "update-new-api-request-from-socket";
export const UPDATE_CHANNEL = "update-channel";
export const ADD_CHANNEL = "add-channel";
export const UPDATE_ACCOUNT_SETTINGS = "update-account-settings";
export const SET_MAIN_COUNTRY_CODES_LIST = "set-main-country-codes-list";

type SetMainCountryCodesList = {
    type: typeof SET_MAIN_COUNTRY_CODES_LIST;
    payload: Array<{ value: string; label: string }>;
};

type UpdateAccountSettings = {
    type: typeof UPDATE_ACCOUNT_SETTINGS;
    payload: {
        events_matching_table_version: string;
    };
};

type AddChannel = {
    type: typeof ADD_CHANNEL;
    payload: {
        channel: ArrayChannel;
        type: "facebook" | "twitter" | "sms_voice" | "instagram";
    };
};

type UpdateChannel = {
    type: typeof UPDATE_CHANNEL;
    payload: ArrayChannel;
};

type UpdateNewApiRequestFromSocket = {
    type: typeof UPDATE_NEW_API_REQUEST_FROM_SOCKET;
    payload: any;
};

interface AccountSettingToggleRightpanelAction {
    type: typeof ACCOUNT_SETTINGS_TOGGLE_RIGHTPANEL;
    payload: boolean;
}

interface AccountSettingsUpdateAction {
    type: typeof ACCOUNT_SETTINGS_UPDATE;
    payload: AccountSettingsState;
}

interface AccountSettingsLoadingUpdateAction {
    type: typeof ACCOUNT_SETTINGS_LOADING_UPDATE;
    payload: any;
}

interface AccountSettingsCreatingUserErrorsUpdate {
    type: typeof ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE;
    payload: UserCreationErrors;
}

interface AccountSettingsSelectLayerInMapLayersUpdateAction {
    type: typeof ACCOUNT_SETTINGS_SELECT_LAYER_IN_MAP_LAYERS_UPDATE;
    payload: string;
}

interface AccountSettingsActivityLogUpdateAction {
    type: typeof ACCOUNT_SETTINGS_ACTIVITY_LOG_UPDATE;
    payload: Array<AccountSettingsActivity>;
}

interface AccountSettingsIsFullActivityLogUpdateAction {
    type: typeof ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_LOG_UPDATE;
    payload: boolean;
}

interface AccountSettingsActivityByIdUpdateAction {
    type: typeof ACCOUNT_SETTINGS_ACTIVITY_BY_ID_UPDATE;
    payload: AccountSettingsActivity;
}

interface AccountSettingsIsFullActivityByIdUpdateAction {
    type: typeof ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_BY_ID_UPDATE;
    payload: boolean;
}

interface AccountSettingsActivityTypesUpdateAction {
    type: typeof ACCOUNT_SETTINGS_ACTIVITY_TYPES_UPDATE;
    payload: Array<string>;
}

interface AccountSettingsIsActivitiesLoadingUpdateAction {
    type: typeof ACCOUNT_SETTINGS_IS_ACTIVITIES_LOADING_UPDATE;
    payload: boolean;
}

interface AccountSettingsUsersActivitiesByIdUpdateAction {
    type: typeof ACCOUNT_SETTINGS_USERS_ACTIVITIES_BY_ID_UPDATE;
    payload: { id: string; data: UserActivityByIdState };
}

interface AccountSettingsIsMapLayersFullUpdateAction {
    type: typeof ACCOUNT_SETTINGS_IS_MAP_LAYERS_FULL_UPDATE;
    payload: boolean;
}

interface AccountSettingsSearchMapLayersUpdateAction {
    type: typeof ACCOUNT_SETTINGS_SEARCH_MAP_LAYERS_UPDATE;
    payload: Array<any>;
}

interface AccountSettingsIsSearchMapLayersFullUpdateAction {
    type: typeof ACCOUNT_SETTINGS_IS_SEARCH_MAP_LAYERS_FULL_UPDATE;
    payload: boolean;
}

interface AccountSettingsLoadingMapLayersUpdateAction {
    type: typeof ACCOUNT_SETTINGS_LOADING_MAP_LAYERS_UPDATE;
    payload: boolean;
}

interface UpdateAllPrivilegesAction {
    type: typeof UPDATE_ALL_PRIVILEGES;
    payload: Array<AccountPrivilege>;
}

interface UpdateAccountJurisdictionAction {
    type: typeof UPDATE_ACCOUNT_JURISDICTION;
    payload: JurisdictionFeature;
}

interface UpdateAccountSettingsCurrentUserAction {
    type: typeof UPDATE_ACCOUNT_SETTINGS_CURRENT_USER;
    payload: AccountSettingsGeneralCurrentUser;
}

interface UpdateAccountSettingsGeneralAction {
    type: typeof UPDATE_ACCOUNT_SETTINGS_GENERAL;
    payload: Partial<AccountSettingsGeneral>;
}

interface UpdateAccountSettingsOrganizationAction {
    type: typeof UPDATE_ACCOUNT_SETTINGS_ORGANIZATION;
    payload: Partial<AccountSettingsOrganization>;
}

interface UpdateAccountSettingsGeneralUsersAction {
    type: typeof UPDATE_ACCOUNT_SETTINGS_GENERAL_USERS;
    payload: Partial<AccountSettingsGeneralUsers>;
}

interface UpdateAccountSettingsGeneralRolesAction {
    type: typeof UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES;
    payload: Partial<AccountSettingsGeneralRoles>;
}

interface UpdateAccountSettingsAlertsAction {
    type: typeof UPDATE_ACCOUNT_SETTINGS_ALERTS;
    payload: AccountSettingsAlerts;
}

interface GetChannels {
    type: typeof GET_CHANNELS;
    payload: {
        channels: Channels;
        channels_available: ChannelsAvailable;
    };
}

interface ChangeGetChannelsProcessingStatus {
    type: typeof CHANGE_GET_CHANNELS_PROCESSING_STATUS;
    payload: boolean;
}

export type AccountSettingsActionTypes =
    | SetMainCountryCodesList
    | UpdateAccountSettings
    | AddChannel
    | UpdateChannel
    | AccountSettingToggleRightpanelAction
    | AccountSettingsUpdateAction
    | AccountSettingsLoadingUpdateAction
    | AccountSettingsCreatingUserErrorsUpdate
    | AccountSettingsSelectLayerInMapLayersUpdateAction
    | AccountSettingsActivityLogUpdateAction
    | AccountSettingsIsFullActivityLogUpdateAction
    | AccountSettingsActivityByIdUpdateAction
    | AccountSettingsIsFullActivityByIdUpdateAction
    | AccountSettingsActivityTypesUpdateAction
    | AccountSettingsIsActivitiesLoadingUpdateAction
    | AccountSettingsUsersActivitiesByIdUpdateAction
    | AccountSettingsIsMapLayersFullUpdateAction
    | AccountSettingsSearchMapLayersUpdateAction
    | AccountSettingsIsSearchMapLayersFullUpdateAction
    | AccountSettingsLoadingMapLayersUpdateAction
    | UpdateAccountJurisdictionAction
    | UpdateAllPrivilegesAction
    | UpdateAccountSettingsCurrentUserAction
    | UpdateAccountSettingsGeneralAction
    | UpdateAccountSettingsOrganizationAction
    | UpdateAccountSettingsGeneralUsersAction
    | UpdateAccountSettingsGeneralRolesAction
    | UpdateAccountSettingsAlertsAction
    | GetChannels
    | ChangeGetChannelsProcessingStatus
    | UpdateNewApiRequestFromSocket;
