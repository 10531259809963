// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Classes } from "src/mpd-library";

import { ICheckboxProps, Text } from "@blueprintjs/core";

import "./styles.scss";

export interface IGroupsListItemProps extends ICheckboxProps {
    checked?: boolean;
    title?: string;
    description?: string;
    members?: Array<any>;
    type?: string;
    loading?: boolean;
    itemId?: string | number;
    selected?: boolean;
    onCheckChanged?: (event: React.SyntheticEvent) => void;
    onItemClick?(event: React.MouseEvent<HTMLElement>): void;
}

export class GroupsListItem extends React.Component<IGroupsListItemProps> {
    public render() {
        const { title, description, members, type, className, loading, onItemClick } = this.props;
        const classes = classNames("groups-list-item-container", loading && Classes.LOADING, className);
        return (
            <div className={classNames(classes, Classes.CONTAINER_PADDING_HORIZONTAL)} onClick={onItemClick}>
                {/* <div onClick={e => e.stopPropagation()}>
          <Checkbox
              className={classNames(checked && Classes.ACTIVE)}
              checked={checked}
              onChange={onCheckChanged}
          />
        </div> */}
                <Text className={classes + "-title"}>{title}</Text>
                <Text className="group-list-item-description"> {description} </Text>
                <div className={classes + "-group-list-item-type-wrapper"}>
                    <Text className={classNames("group-list-item-type", type)}> {type} </Text>
                </div>
                {members && <Text className="group-list-item-members"> {members && members.length} Members </Text>}
            </div>
        );
    }
}
