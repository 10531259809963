import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "src/actions";
import { onToggleRightPanel, onUpdateToolBar } from "src/actions/global/global";
import { BreadCrumbs } from "src/components";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { ShadowedListItem } from "src/components/shadowed-settings-list-item/shadowed-settings-list-item";
import { TextClasses } from "src/components/styled-text/styled-text";
import { ApiRequestLog } from "./components/api-request-log/api-request-log";
import { RightApiKeyItemComponent } from "./components/right-api-key-item-component/right-api-key-item-component";
import styles from "./styles.module.scss";
import { ReactComponent as ProgrammingBrowserIcon } from "../../../mpd-library/icon/assets/programming-browser.svg"

const classname = "api-keys-settings";

const apiKeyStateMatch = {
    disabled: "Not yet enabled",
    active: "Active",
    suspended: "Suspended"
};

export const ApiKeysSettings = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [apiKey, setApiKey] = useState(null);
    const [apiKeyState, setApiKeyState] = useState<"active" | "suspended">();
    const [getApiKeyListProcessing, setGetApiKeyListProcessing] = useState<boolean>(true);

    const getApiKey = async () => {
        try {
            setGetApiKeyListProcessing(true);
            const res = await axiosInstance.get("/account/settings/api/api_key");
            setApiKey(res.data.api_key);
            setApiKeyState(res.data.api_key_state);
        } finally {
            setGetApiKeyListProcessing(false);
        }
    };

    useEffect(() => {
        getApiKey();
    }, []);

    useMemo(() => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "API Key" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel())
                        // onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    }, []);

    if (getApiKeyListProcessing) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[classname]}>
            <div className={styles[`${classname}-top-wrapper`]}>
                <Text className={classNames(TextClasses.MARGIN_VERTICAL_40, TextClasses.LIST_SUBTITLE)}>
                    YOUR API KEY
                </Text>
                <ShadowedListItem
                    title={"API Key"}
                    subtitle={apiKeyStateMatch[apiKeyState]}
                    IconComponent={ProgrammingBrowserIcon}
                    customRightElement={
                        <RightApiKeyItemComponent
                            apiKey={apiKey}
                            setApiKey={setApiKey}
                            setApiKeyState={setApiKeyState}
                            apiKeyState={apiKeyState}
                        />
                    }
                />
            </div>
            <ApiRequestLog />
        </div>
    );
};
