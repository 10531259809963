import { Store } from "redux";
import { updateToast } from "src/actions/global/global-api";
import { IToastType, ToastTypes } from "src/components";
import { AppState } from "src/store";

export const createCardHanlder = (store: Store<AppState>, data: any) => {
    if (data.reason === "validation_error") {
        let errorMessage = "";

        if (data.errors.end_at) {
            errorMessage = "Error! Invalid dates";
        }
        const toast: IToastType = {
            value: errorMessage || "Validation error. Please check the form",
            type: ToastTypes.error
        };


        store.dispatch(updateToast({ toast }));
    }
};
