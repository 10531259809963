import { useEffect, useState } from "react";
import { FormikConfig, FormikValues, useFormik, actions } from "formik";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

import { store } from "src";
import { onUpdateToast } from "../../../../../actions/global/global";

export const getKeys = (err) => {
    if (err?.response?.status === 422) {
        const keys = Object.keys(err.response.data.errors);
        return keys;
    }

    return [];
};

export const setFormikFieldErrors = (err, formik) => {
    if (err?.response?.status === 422) {
        getKeys(err).map((key) => {
            const error = err.response.data.errors?.[key]?.[0];
            formik.setFieldError(key, error);
            return key;
        });

        store.dispatch(onUpdateToast({ type: "error", value: "Validation error. Please check the form" }));
    }
};

export const getStepsWithErrors = (matchObject, keys) => {
    return keys?.reduce((acc, key: string) => {
        return {
            ...acc,
            [matchObject?.[key]]: true
        };
    }, {});
};

interface UseExtendedFormikProps {
    formikConfig: FormikConfig<any>;
    errorsFieldsMatchObject: { [key: string]: number };
}

interface UseExtendedFormik {
    formik: FormikValues;
    stepsWithErrors: { [key: string]: number };
}

export const useExtendedFormik = ({
    formikConfig,
    errorsFieldsMatchObject
}: UseExtendedFormikProps): UseExtendedFormik => {
    const [stepsWithErrors, setStepsWithError] = useState<{ [key: number]: boolean }>({});
    const [error, setError] = useState<any>(null);

    const formik = useFormik<FormikValues>({
        ...formikConfig,
        onSubmit: async (values: FormikValues, actions: actions) => {
            try {
                const res = await formikConfig?.onSubmit(values, actions);
                return res;
            } catch (err) {
                setError(err);
            }
        }
    });

    const handleBEValidationErr = (err) => {
        setFormikFieldErrors(err, formik);
        if (errorsFieldsMatchObject) {
            const stepsWithErrors = getStepsWithErrors(errorsFieldsMatchObject, getKeys(err));
            setStepsWithError(stepsWithErrors);
        }
    };

    useEffect(() => {
        handleBEValidationErr(error);
    }, [error]);

    useEffect(() => {
        if (error?.response?.status === 422) {
            Object.keys(formik.errors).forEach((key) => {
                if (!!error.response.data.errors[key]) {
                    setError({
                        ...error,
                        response: {
                            ...error.response,
                            data: {
                                ...error.response.data,
                                errors: Object.keys(error.response.data.errors).reduce((acc, fieldName) => {
                                    if (!!formik.errors[fieldName]) {
                                        return {
                                            ...acc,
                                            [fieldName]: error.response.data.errors[fieldName]
                                        };
                                    }
                                    return acc;
                                }, {})
                            }
                        }
                    });
                }
            });

            if (isEmpty(formik.errors)) {
                setError(null);
            }
        }
    }, [formik.errors]);

    return {
        formik,
        stepsWithErrors
    };
};
