import classNames from "classnames";
import { FC } from "react";
import { MPDIcon } from "../../../../mpd-library";
import { QRStyle } from "../../../qr-code-generator/qr-code-generator";
import styles from "./QRCodeStyleSelector.module.scss";
import { ReactComponent as QrSquaresIcon } from "../../../../mpd-library/icon/assets/qr-squares.svg"
import { ReactComponent as QrRoundedIcon } from "../../../../mpd-library/icon/assets/qr-rounded-squares.svg"
import { ReactComponent as QrExtraRoundedIcon } from "../../../../mpd-library/icon/assets/qr-extra-rounded-squares.svg"
import { ReactComponent as QrDotsIcon } from "../../../../mpd-library/icon/assets/qr-dots.svg"

type QRCodeStyleSelectorProps = {
    activeStyle: QRStyle;
    onSelect: (value: QRStyle) => void;
};

const qrStyles: Array<QRStyle> = ["squares", "rounded", "dots", "extraRounded"];

const qrStylesIconNamesMatch: { [key in QRStyle]: any } = {
    squares: QrSquaresIcon,
    rounded: QrRoundedIcon,
    extraRounded: QrExtraRoundedIcon,
    dots: QrDotsIcon
};

export const QRCodeStyleSelector: FC<QRCodeStyleSelectorProps> = ({
    activeStyle,
    onSelect
}: QRCodeStyleSelectorProps): JSX.Element => {
    return (
        <section className={styles["qr-style-selector"]}>
            <span className={styles["qr-style-selector-label"]}>STYLE</span>
            <ul className={styles["qr-styles-list"]}>
                {qrStyles.map((qrStyle: QRStyle, index: number) => {
                    return (
                        <li
                            key={index}
                            onClick={() => onSelect(qrStyle)}
                            className={classNames(
                                styles["qr-styles-list-item"],
                                qrStyle === activeStyle && styles["qr-styles-list-item_active"]
                            )}
                        >
                            <MPDIcon IconComponent={qrStylesIconNamesMatch[qrStyle]} />
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};
