// --------------------------- TAGS REQUESTS ---------------------------

import { AxiosResponse } from "axios";
import { axiosInstance } from "../actions";

export type ConversationTag = {
    description?: string;
    id: string;
    name: string;
    nodes: Array<ConversationTag>;
    ancestors_formatted?: any;
    parent?: ConversationTag;
};

type TagsResponseAdmin = {
    first_name: string;
    id: string;
    last_name: string;
    profile_pic: {
        type: string;
        url: string;
    };
    status: "away" | "online";
};

type GetConversationsTagsResponse = {
    admin: TagsResponseAdmin;
    ancestors_formatted: null | string;
    conversations_count: number;
    description: null | string;
    id: string;
    name: string;
    // nodes: Array<any>;
};

type GetConversationsTagRequestParams = {
    offset?: number;
    limit?: number;
};

// GET /tags
// GET /tags/:id
// PATCH /tags/:id
// DELETE /tags/:id

export const getTags = async (
    params: GetConversationsTagRequestParams
): Promise<Array<GetConversationsTagsResponse>> => {
    return await axiosInstance.get(`/tags`, { params });
};

export const updateTagRequest = async (tagId: string, data: ConversationTag): Promise<AxiosResponse> => {
    return await axiosInstance.patch<ConversationTag>(`/tags/${tagId}`, data);
};

export const createTagRequest = async (data: Partial<ConversationTag>): Promise<AxiosResponse> => {
    return await axiosInstance.post<ConversationTag>(`/tags/create`, data);
};

export const getTagDetails = async (tagId: string) => {
    return await axiosInstance.get(`/tags/${tagId}`);
};

export const deleteTagRequest = async (tagId: string): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`/tags/${tagId}`);
};

export const removeTagRequest = async (tagId: string) => {
    return await axiosInstance.delete(`/tags/${tagId}`);
};
