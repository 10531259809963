// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { TabId } from "@blueprintjs/core";

import { IDetailView } from "../../../..";

import {
    BreadCrumbs,
    ContainerEmpty,
    StyledButton,
    StyledButtonTypes,
    StyledTabs,
    StyledTabsTypes,
    SimpleList
} from "src/components";

import { SettingsGroupsMemberListItem } from "../../../components";

import moment from "moment";

import { Redirect, Route, Switch } from "react-router-dom";

import GroupsSettingsAdd from "../group-settings-add/groups-settings-add";

import "./styles.scss";
import { AppState, AccountSettingsGeneralAlertGroups } from "src/store";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { updateGroups } from "src/actions/account-settings/account-settings-api";
import { ReactComponent as GroupBlueIcon } from "../../../../../mpd-library/icon/assets/group-blue.svg"

interface IGroupsSettingsEditProps extends IDetailView {
    groups: AccountSettingsGeneralAlertGroups;
    groupToEdit: any;
    routeProps: RouteComponentProps;
    onSaveChanges: (groups: AccountSettingsGeneralAlertGroups) => void;
}

interface IGroupsSettingsEditState {
    groupToEdit?: any;
    selectedTabNum: TabId;
    tabs: Array<string>;
}

class GroupsSettingsEdit extends React.Component<IGroupsSettingsEditProps, IGroupsSettingsEditState> {
    constructor(props: IGroupsSettingsEditProps) {
        super(props);
        this.onInviteMembers = this.onInviteMembers.bind(this);
        this.updatedSelectedTabNum = this.updatedSelectedTabNum.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.updateToMemberToolBar = this.updateToMemberToolBar.bind(this);
        this.renderList = this.renderList.bind(this);
        const { groupToEdit } = this.props;
        this.state = {
            groupToEdit,
            selectedTabNum: 0,
            tabs: ["members", "settings"]
        };
    }

    public componentDidMount() {
        this.updateToMemberToolBar();
    }

    public componentDidUpdate(prevProps: IGroupsSettingsEditProps) {
        const { routeProps, groups, groupToEdit } = this.props;
        if (prevProps.routeProps !== this.props.routeProps) {
            const pathname = routeProps.history.location.pathname;
            this.updatedSelectedTabNum();
            if (pathname.includes("members")) {
                this.updateToMemberToolBar();
            }
        }
        if (prevProps.groups !== this.props.groups) {
            const index = groups.list.findIndex((listItem: any) => listItem.id === groupToEdit.id);
            const groupItem = JSON.parse(JSON.stringify(groups.list[index]));
            this.setState({ groupToEdit: groupItem });
        }
    }

    public render() {
        const { className, routeProps, isOrSmallerTabletPortraitSize } = this.props;
        const { groupToEdit, tabs, selectedTabNum } = this.state;

        const classes = "group-settings-edit-container";

        return (
            <div className={classNames(classes, className)}>
                <Switch>
                    {!groupToEdit && (
                        <Redirect from={`/account-settings/groups/edit/`} to={`/account-settings/groups/`} />
                    )}
                    <Redirect
                        exact={true}
                        from={`/account-settings/groups/edit/${groupToEdit && groupToEdit.id}`}
                        to={`/account-settings/groups/edit/${groupToEdit && groupToEdit.id}/members`}
                    />
                    <Route
                        exact={true}
                        path={`/account-settings/groups/edit/${groupToEdit && groupToEdit.id}/members`}
                        render={() => (
                            <React.Fragment>
                                <StyledTabs
                                    className="group-settings-edit-tabs"
                                    tabs={tabs}
                                    type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                                    tabsProps={{
                                        id: "social-settings-tabs",
                                        onChange: this.onChangeTab,
                                        selectedTabId: selectedTabNum
                                    }}
                                />
                                {groupToEdit.members && groupToEdit.members.length > 0 ? (
                                    <React.Fragment>
                                        <SimpleList
                                            renderedComponent={this.renderList}
                                            list={groupToEdit.members}
                                            isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                                            // showOptions={listHeaderChecked}
                                            placeholder="Search members..."
                                        />
                                    </React.Fragment>
                                ) : (
                                    <ContainerEmpty
                                        className={classes + "-empty"}
                                        title="No Members Found"
                                        subtitle="It looks like no one has signed up for your group yet."
                                        IconComponent={GroupBlueIcon}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    />
                    <Route
                        exact={true}
                        path={`/account-settings/groups/edit/${groupToEdit && groupToEdit.id}/settings`}
                        render={() => (
                            <React.Fragment>
                                <StyledTabs
                                    className="group-settings-edit-tabs"
                                    tabs={tabs}
                                    type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                                    tabsProps={{
                                        id: "social-settings-tabs",
                                        onChange: this.onChangeTab,
                                        selectedTabId: selectedTabNum
                                    }}
                                />
                                <GroupsSettingsAdd
                                    edit={true}
                                    groupToEdit={groupToEdit}
                                    routeProps={routeProps}
                                    onCloseRightPanel={this.props.onCloseRightPanel}
                                    onToggleRightPanel={this.props.onToggleRightPanel}
                                    onUpdateChangesMade={this.props.onUpdateChangesMade}
                                />
                            </React.Fragment>
                        )}
                    />
                </Switch>
            </div>
        );
    }

    private renderList(props: any) {
        return (
            <div className="group-settings-edit-list">
                {props.list.map((member: any, index: number) => (
                    <SettingsGroupsMemberListItem
                        key={index}
                        active={member.active}
                        initials={member.initials}
                        title={member.title}
                        status={member.active ? "Member Since " + member.date : "Deactive"}
                        statusOptions={["edit", "delete", "deactivate"]}
                    />
                ))}
            </div>
        );
    }

    private updatedSelectedTabNum() {
        const { routeProps } = this.props;
        const { tabs } = this.state;
        const pathname = routeProps.history.location.pathname;
        const pathArr = pathname.split("/");
        const selectedTabNum = tabs.findIndex((tab: any) => tab === pathArr[pathArr.length - 1]);
        this.setState({ selectedTabNum });
    }

    private onChangeTab(newTabNum: TabId) {
        const { routeProps } = this.props;
        const { tabs, groupToEdit } = this.state;
        const tabId = tabs[newTabNum].toLowerCase();
        this.setState({ selectedTabNum: newTabNum });
        this.updateToMemberToolBar();
        routeProps.history.push(`/account-settings/groups/edit/${groupToEdit && groupToEdit.id}/${tabId}`);
    }

    private onInviteMembers() {
        const { groups, onSaveChanges } = this.props;
        const { groupToEdit } = this.state;
        const index = groups.list.findIndex((listItem: any) => listItem.id === groupToEdit.id);
        const groupItem = groups.list[index];
        const date = moment(new Date()).format("L");
        const id = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();
        const obj = {
            active: true,
            checked: true,
            date,
            id,
            initials: "J.S",
            title: "Jason Smith"
        };
        groupItem.members.push(obj);
        onSaveChanges(groups);
    }

    private updateToMemberToolBar() {
        const { routeProps, groupToEdit } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={
                        groupToEdit
                            ? [
                                  {
                                      text: "Groups",
                                      pathname: "/account-settings/groups/"
                                  },
                                  {
                                      text: groupToEdit.title,
                                      pathname: `account-settings/groups/edit/${groupToEdit.id}/members`
                                  }
                              ]
                            : [
                                  {
                                      text: "Groups",
                                      pathname: "/account-settings/groups/"
                                  }
                              ]
                    }
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledButton type={StyledButtonTypes.primary} onClick={this.onInviteMembers} text="Invite Members" />
            )
        };
        if (this.props.onUpdateToolBar) {
            this.props.onUpdateToolBar(topBarProps);
        }
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings, global } = state;
    const { isOrSmallerTabletPortraitSize } = global;
    const groups = accountSettings.alerts.groups;
    return {
        isOrSmallerTabletPortraitSize,
        groups
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onSaveChanges: updateGroups
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSettingsEdit);
