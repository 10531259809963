import { ReactElement } from "react";
import { IToastType } from "src/components";

export interface TopBarProps {
    leftElement?: ReactElement | null;
    rightElement?: ReactElement | null;
}

export interface GlobalState {
    isMobileSize: boolean;
    isOrSmallerTabletPortraitSize: boolean;
    rightPanelExpanded: boolean;
    windowHeight: number | null;
    windowWidth: number | null;
    deviceType: "Android" | "iOS" | "unknown";
    topBarProps: TopBarProps | null;
    mobileSideBarActive: boolean;
    toast?: IToastType;
    alertOpen: boolean;
    changesMade: boolean;
    navPath: any;
    loading: boolean;
    callBack: any;
    displayGlobalSpinner: boolean;
    isAppTabInFocus: boolean;
    initialLoadingProcessingStatus: boolean;
    firstAccessibleRoute: string;
}

export const defaultGlobalState: GlobalState = {
    isMobileSize: false,
    isOrSmallerTabletPortraitSize: false,
    rightPanelExpanded: false,
    windowHeight: null,
    windowWidth: null,
    deviceType: "unknown",
    topBarProps: null,
    mobileSideBarActive: false,
    toast: undefined,
    alertOpen: false,
    changesMade: false,
    navPath: null,
    loading: false,
    callBack: null,
    displayGlobalSpinner: false,
    isAppTabInFocus: true,
    initialLoadingProcessingStatus: false,
    firstAccessibleRoute: ""
};
