export const FILTER_SORT_TYPE_VALUES = [
    {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    {
        isComparisonValueEditable: true,
        label: "is not",
        comparison: "neq"
    },
    {
        isComparisonValueEditable: true,
        label: "starts with",
        comparison: "starts_with"
    },
    {
        isComparisonValueEditable: true,
        label: "ends with",
        comparison: "ends_with"
    },
    {
        isComparisonValueEditable: true,
        label: "contains",
        comparison: "contains"
    },
    {
        isComparisonValueEditable: true,
        label: "does not contain",
        comparison: "not_contains"
    },
    {
        isComparisonValueEditable: false,
        label: "is unknown",
        comparison: "is_null"
    },
    {
        isComparisonValueEditable: false,
        label: "has any value",
        comparison: "is_not_null"
    }
];

export const FILTER_SORT_PLACES_VALUES = [
    {
        isComparisonValueEditable: true,
        label: "inside",
        comparison: "inside"
    },
    {
        isComparisonValueEditable: true,
        label: "outside",
        comparison: "outside"
    },
    {
        isComparisonValueEditable: false,
        label: "is unknown",
        comparison: "is_null"
    },
    {
        isComparisonValueEditable: false,
        label: "has any value",
        comparison: "is_not_null"
    }
];

export const FILTER_SORT_TAGS_VALUES = [
    {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    {
        isComparisonValueEditable: true,
        label: "is not",
        comparison: "neq"
    }
];

export const FILTER_SORT_DATE_VALUES = [
    {
        title: "realtive",
        options: [
            {
                id: 1,
                isComparisonValueEditable: true,
                label: "less than",
                comparison: "lt"
            },
            {
                id: 2,
                isComparisonValueEditable: true,
                label: "exactly",
                comparison: "eq"
            },
            {
                id: 3,
                isComparisonValueEditable: true,
                label: "more than",
                comparison: "gt"
            }
        ]
    },
    {
        title: "Absolute",
        options: [
            {
                id: 4,
                isComparisonValueEditable: true,
                label: "after",
                comparison: "gt"
            },
            {
                id: 5,
                isComparisonValueEditable: true,
                label: "on",
                comparison: "eq"
            },
            {
                id: 6,
                isComparisonValueEditable: true,
                label: "before",
                comparison: "lt"
            },
            {
                id: 7,
                isComparisonValueEditable: false,
                label: "is unknown",
                comparison: "is_null"
            },
            {
                id: 8,
                isComparisonValueEditable: false,
                label: "has any value",
                comparison: "is_not_null"
            }
        ]
    }
];

export const MATCH_COMPARISON_VALUE_EDITABLE = {
    ...FILTER_SORT_PLACES_VALUES.reduce((acc, item) => {
        acc[item.comparison] = item.isComparisonValueEditable;
        return acc;
    }, {}),
    ...FILTER_SORT_TYPE_VALUES.reduce((acc, item) => {
        acc[item.comparison] = item.isComparisonValueEditable;
        return acc;
    }, {}),
    ...FILTER_SORT_TAGS_VALUES.reduce((acc, item) => {
        acc[item.comparison] = item.isComparisonValueEditable;
        return acc;
    }, {}),
    ...FILTER_SORT_DATE_VALUES.reduce((acc, group) => {
        group.options.forEach((item) => {
            acc[item.comparison] = item.isComparisonValueEditable;
        });
        return acc;
    }, {})
};

export const DATA_TYPE_DEFAULT_COMPARISON = {
    personal_data: {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    email: { isComparisonValueEditable: true, label: "is", comparison: "eq" },
    phone: {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    tags: {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    date: {
        isComparisonValueEditable: true,
        label: "exactly",
        comparison: "eq",
        id: 2
    },
    location: {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    place: {
        isComparisonValueEditable: true,
        label: "inside",
        comparison: "inside"
    },
    lists: {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    },
    import_id: {
        isComparisonValueEditable: true,
        label: "is",
        comparison: "eq"
    }
};
