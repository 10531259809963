import { string, array, object, mixed } from "yup";

export const FORMIK_SMART_LINKS_VALIDATION_SCHEMA = [
    object().shape({
        name: string().required("Can't be blank"),
        description: string().nullable(),
        keyword: string().required("Can't be blank")
    }),
    object().shape({ queue: object().required("Can't be blank"), admin: object(), tags: array() }),
    object().shape({
        qrCodeStyle: string(),
        qrCodeBgColor: string(),
        qrCodeColor: string(),
        qrCodeFile: mixed()
        // qrCodeImgUrl: string()
    })
];

export const FORMIK_SMART_LINKS_VALIDATION_SCHEMA_ALL = object().shape({
    name: string().required("Can't be blank"),
    description: string().nullable(),
    keyword: string().required("Can't be blank"),
    queue: object().required("Can't be blank"),
    admin: object(),
    tags: array(),
    qrCodeStyle: string(),
    qrCodeBgColor: string(),
    qrCodeColor: string(),
    qrCodeFile: mixed(),
    qrCodeImgUrl: string()
});
