import classNames from "classnames";
import React from "react";

import { Radio, RadioGroup, IRadioGroupProps, IRadioProps } from "@blueprintjs/core";

import { StyledRadioGroupTypes } from "./types";

import "./styles.scss";

export interface IStyledRadioGroupProps extends IRadioGroupProps {
    list?: Array<IRadioProps>;
    type?: StyledRadioGroupTypes;
    size?: "small" | "large";
}

export const StyledRadioGroup: React.FunctionComponent<IStyledRadioGroupProps> = (props) => {
    const { className, list, type, size, children, ...radioGroupProps } = props;

    const classes = "styled-radio-group";

    return (
        <RadioGroup className={classNames(classes, type, className, size)} {...radioGroupProps}>
            {list &&
                list.map((radioProp: IRadioProps, index: number) => {
                    return (
                        <Radio
                            key={index}
                            {...radioProp}
                        />
                    );
                })}
            {children}
        </RadioGroup>
    );
};
