// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import { StyledButton } from "src/components";

import moment from "moment";

import { Classes, MPDScroll, MPDList } from "src/mpd-library";

import "./styles.scss";
import { ReactComponent as CloseWhiteSmallIcon } from "../../../../mpd-library/icon/assets/close-white-small.svg"

interface ISelectListAddProps extends IProps {
    placeholder?: string;
    keyName: string;
    displayedList?: Array<any>;
    options: Array<any>;
    onUpdateList?: (list: Array<any>) => void;
}

interface ISelectListAddState {
    focus?: boolean;
    filter?: string;
    displayedList: Array<any>;
    hoverIndex?: number;
}

export class SelectListAdd extends React.Component<ISelectListAddProps, ISelectListAddState> {
    private input: React.RefObject<HTMLInputElement>;

    public constructor(props: ISelectListAddProps) {
        super(props);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onCloseListItem = this.onCloseListItem.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.addToList = this.addToList.bind(this);
        this.input = React.createRef();

        const { displayedList } = this.props;

        this.state = {
            displayedList: (displayedList && displayedList.slice()) || []
        };
    }

    public componentDidUpdate(prevProps: ISelectListAddProps) {
        if (this.props.displayedList !== prevProps.displayedList && this.props.displayedList) {
            this.setState({ displayedList: this.props.displayedList });
        }
    }

    public render() {
        const { className, options, keyName, placeholder } = this.props;
        const { displayedList, filter } = this.state;
        const classes = "select-list-add";
        const FilteredList = MPDList(this.renderSelectList);

        return (
            <div
                className={classNames(
                    classes,
                    ((displayedList && displayedList.length) > 0 || (filter && filter.length > 0)) && Classes.FULL,
                    className
                )}
            >
                <MPDScroll xDirection={true} className={classes + "-horizontal-list"}>
                    <div className={classes + "-teams"}>
                        {displayedList &&
                            displayedList.map((item: any, index: number) => (
                                <div className={classes + "-team"} key={index}>
                                    <Text className={classes + "-team-text"}>{item[keyName]}</Text>
                                    <div className={classes + "-team-close"}>
                                        <StyledButton
                                            IconComponent={CloseWhiteSmallIcon}
                                            onClick={() => this.onCloseListItem(index)}
                                        />
                                    </div>
                                </div>
                            ))}
                        <input
                            className={classes + "-input"}
                            onKeyUp={this.onKeyUp}
                            onKeyDown={this.onKeyDown}
                            onChange={this.onSearchChange}
                            ref={this.input}
                            value={filter}
                        />
                        {placeholder && (
                            <span className={classes + "-placeholder"} tabIndex={-1}>
                                {placeholder}
                            </span>
                        )}
                    </div>
                </MPDScroll>
                {filter && filter.length > 0 && (
                    <FilteredList
                        className={"select-list-add-select-list"}
                        style={{ height: options.length * 40 }}
                        filter={filter}
                        list={options}
                        emptyElement={<div className={classNames("select-list-add-select-list-empty")}>No results</div>}
                    />
                )}
            </div>
        );
    }

    private onKeyDown(event: any) {
        const { displayedList, filter } = this.state;
        const { onUpdateList } = this.props;
        const key = event.keyCode || event.charCode;
        if ((key === 8 && filter !== undefined && filter.length === 0) || filter === undefined) {
            displayedList.splice(displayedList.length - 1, 1);
            this.setState({ displayedList }, () => {
                if (onUpdateList) {
                    onUpdateList(displayedList);
                }
            });
        }
    }

    private onKeyUp(event: any) {
        const { hoverIndex } = this.state;
        const { options } = this.props;
        const key = event.keyCode || event.charCode;
        if (key === 40) {
            if (hoverIndex !== undefined && hoverIndex < options.length - 1) {
                this.setState({ hoverIndex: hoverIndex + 1 });
            } else if (hoverIndex === undefined) {
                this.setState({ hoverIndex: 0 });
            }
        } else if (key === 38 && hoverIndex && hoverIndex > 0) {
            this.setState({ hoverIndex: hoverIndex - 1 });
        } else if (key === 13 && hoverIndex !== undefined) {
            const item = options[hoverIndex];
            this.addToList(item);
        }
    }

    private onCloseListItem(index: number) {
        const { displayedList } = this.state;
        const { onUpdateList } = this.props;
        displayedList.splice(index, 1);
        this.setState({ displayedList }, () => {
            if (onUpdateList) {
                onUpdateList(displayedList);
            }
        });
    }

    private onSearchChange(event: any) {
        const filter = event.currentTarget.value;
        this.setState({ filter });
    }

    private onRowClick(item: any) {
        this.addToList(item);
    }

    private addToList(item: any) {
        const { displayedList } = this.state;
        let unique = true;
        for (let i = 0; i < displayedList.length; i++) {
            if (item.id === displayedList[i].id) {
                unique = false;
            }
        }
        if (unique) {
            const { onUpdateList } = this.props;
            const date = moment(new Date()).format("L");
            item.date = date;
            item.checked = false;
            item.active = true;
            displayedList.push(item);
            this.setState({ displayedList, filter: "" }, () => {
                if (onUpdateList) {
                    onUpdateList(displayedList);
                }
                if (this.input.current) {
                    this.input.current.focus();
                }
            });
        } else {
            this.setState({ filter: "" });
        }
    }

    private renderSelectList = (props: any) => (
        <React.Fragment>
            {props.list.map((option: any, index: number) => (
                <div
                    className={classNames(
                        "select-list-add-select-list-item",
                        this.state.hoverIndex === index && "hover"
                    )}
                    onClick={() => this.onRowClick(option)}
                    key={index}
                >
                    <Text className={"select-list-add-select-list-item-initials"}>
                        {" "}
                        {option.first_name.charAt(0) + option.last_name.charAt(0)}{" "}
                    </Text>
                    <Text className={"select-list-add-select-list-item-text"}>
                        {" "}
                        {option.first_name + " " + option.last_name}{" "}
                    </Text>
                </div>
            ))}
        </React.Fragment>
    );
}

export default SelectListAdd;
