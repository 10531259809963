import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";
import moment from "moment";

import { StyledSelectTypes, StyledDateInput, StyledSelect } from "src/components";

import { StyledDateTimeInputsType } from "./types";

import { Position, IProps } from "@blueprintjs/core";

import "./styles.scss";
import { IMPDButtonProps } from "src/mpd-library";

export interface IStyledDateTimeInputsProps extends IProps {
    date?: Date;
    time?: string;
    type?: StyledDateTimeInputsType;
    withoutTime?: boolean;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    minDate?: Date;
    timeError?: string;
    onDateChange?: (date: Date) => void;
    onTimeChange?: (event: React.ChangeEvent, time: string) => void;
    usePortal?: boolean;
}

interface IStyledDateTimeInputstState {
    timeOptions: Array<any>;
}
export class StyledDateTimeInputs extends React.Component<IStyledDateTimeInputsProps, IStyledDateTimeInputstState> {
    constructor(props: IStyledDateTimeInputsProps) {
        super(props);
        const timeOptions = this.createHalfHourIntervals("hh:mm A");
        this.state = {
            timeOptions
        };
    }

    public render() {
        const {
            className,
            type,
            onDateChange,
            onTimeChange,
            withoutTime,
            LeftIconComponent,
            minDate,
            timeError,
            date,
            time
        } = this.props;

        const { timeOptions } = this.state;
        const classes = "styled-datetime-inputs";

        return (
            <div className={classNames(classes, type || StyledDateTimeInputsType.primary, className)}>
                <StyledDateInput
                    className={classes + "-styled-date-input"}
                    closeOnSelection={true}
                    minDate={minDate || new Date()}
                    defaultValue={minDate || new Date()}
                    formatDate={(date) => moment(date).format("MM/DD/YYYY")}
                    onChange={onDateChange}
                    parseDate={(str) => moment(str, "MM/DD/YYYY").toDate()}
                    popoverProps={{
                        position: Position.BOTTOM,
                        popoverClassName: classNames(
                            classes + "-content-date-input-popover",
                            className + "-content-date-input-popover"
                        )
                    }}
                    LeftIconComponent={LeftIconComponent}
                    value={date || new Date()}
                    usePortal={this.props.usePortal}
                    portalClassName={"date-input-portal"}
                />
                {!withoutTime && (
                    <StyledSelect
                        className={classes + "-content-time"}
                        options={timeOptions}
                        type={StyledSelectTypes.primary}
                        cannotChange={true}
                        value={time}
                        onChange={onTimeChange}
                        error={timeError}
                        usePortal={this.props.usePortal}
                        portalClassName={"date-input-portal"}
                    />
                )}
            </div>
        );
    }

    private createHalfHourIntervals(format: string) {
        const startDate = moment(new Date()).startOf("day");
        const dates: Array<string> = [];
        const hourDivider = 2;
        let t = hourDivider * 24;
        while (t--) {
            dates.push(startDate.format(format));
            startDate.add(60 / hourDivider, "minute");
        }
        return dates;
    }
}
