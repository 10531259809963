import { Tooltip2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import { memo, useMemo } from "react";
import { TOOLTIP_DELAY } from "src/containers/conversations/constants";
import { MPDIcon } from "src/mpd-library";
import { ImageSource } from "../image-source";
import styles from "./styles.module.scss";
import { ReactComponent as ChannelsFacebookIcon } from "../../mpd-library/icon/assets/ChannelsFacebook.svg";
import { ReactComponent as ChannelsInstagramIcon } from "../../mpd-library/icon/assets/ChannelsInstagram.svg";
import { ReactComponent as ChannelsTwitterIcon } from "../../mpd-library/icon/assets/ChannelsTwitter.svg";
import { ReactComponent as PersonWhiteIcon } from "../../mpd-library/icon/assets/person-white.svg";

interface AvatarProps {
    avatarUrl: string;
    status?: string;
    user: any;
    className?: string;
    statusDotClassName?: string;
    anonimousUser?: boolean;
    showTooltip?: boolean;
    channel?: {
        id: string;
        type: "facebook" | "twitter";
    };
}

const channelIconMatchObject = {
    facebook: () => <MPDIcon IconComponent={ChannelsFacebookIcon} />,
    instagram: () => <MPDIcon IconComponent={ChannelsInstagramIcon} />,
    twitter: () => <MPDIcon IconComponent={ChannelsTwitterIcon} />
};

export const Avatar = memo(({ avatarUrl, user, className, channel, anonimousUser, showTooltip, size }: AvatarProps) => {
    const sizes = useMemo(
        () => ({
            width: `${size}px`,
            height: `${size}px`
        }),
        [size]
    );

    return (
        <div className={`${styles["avatar-wrapper"]} ${className} avatar`} style={sizes}>
            {/* Will be implemented on v2 */}
            {/* {status !== undefined && (
                <div className={`${styles["avatar-status-dot"]} ${styles[status]} ${statusDotClassName}`} />
            )} */}
            {!anonimousUser && (
                <Tooltip2
                    placement={"top"}
                    content={`${user?.first_name} ${user?.last_name}`}
                    portalClassName={"mpd-simple-tooltip"}
                    className={classNames(styles["image-source-tooltip-wrapper"], "image-source-tooltip-wrapper")}
                    disabled={!showTooltip}
                    hoverOpenDelay={TOOLTIP_DELAY}
                >
                    {user?.first_name || user?.last_name ? (
                        <ImageSource
                            initials={
                                (user?.first_name?.charAt(0) || "") +
                                (user?.last_name?.charAt(0) ||
                                    user?.name?.split(" ")[0].charAt(0) + user?.name?.split(" ")[1].charAt(0))
                            }
                            src={avatarUrl || user?.profile_pic?.profile_pic_url}
                            height={"100%"}
                            width={"100%"}
                            className={styles["image-source"]}
                            userId={user?.id}
                            profile_pic_color={user?.profile_pic_color}
                            size={size}
                        />
                    ) : (
                        <div className={styles["no-name-user-avatar"]}>
                            <MPDIcon IconComponent={PersonWhiteIcon} />
                        </div>
                    )}
                </Tooltip2>
            )}
            {anonimousUser && <div className={classNames(styles[`anon-circle`], "anon-circle")}>?</div>}
            {!!channel && user?.type !== "admin" && (
                <div className={styles[`avatar-channel-icon`]}>{channelIconMatchObject[channel.type]()}</div>
            )}
        </div>
    );
});
