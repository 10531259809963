import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormikValues, useFormik } from "formik";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { onGetChannelsArray, onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToast, onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import { SettingsSocialBanter } from "src/containers/account-settings/general/channel-settings/components";
import { useQueues, UseQueues } from "src/containers/conversations/components/chat/hooks/useQueues";
import { AppState } from "src/store";
import {
    StyledAlert,
    StyledAlertTypes,
    StyledButton,
    StyledTabs,
    StyledTabsTypes,
    BreadCrumbs,
    StyledButtonTypes
} from "../../../../../../components";
import { postMediaRequest } from "../../../../../../requests";
import { FORMIK_SMART_LINKS_VALIDATION_SCHEMA_ALL } from "../../constants";
import { SmartLinksRoutingSettings } from "../smart-link-settings/smart-link-settings";
import styles from "./styles.module.scss";
import { FormWasChanged } from "../../../../../../components/form-was-changed/FormWasChanged";
import { SmartLinkOverview } from "../smart-link-overview/smart-link-overview";
import { SmartLinksQrCode } from "../../../../../../components/smart-link-qr-code/smart-links-qr-code";
import {
    SmartLink,
    deleteSmartLink,
    getSmartLink,
    updateSmartLink
} from "../../../../../../requests/smartLinksRequests";
import { SmartLinkItemInfo } from "../smart-link-item-info/smart-link-item-info";
import { getKeys, getStepsWithErrors, setFormikFieldErrors } from "../../../../../../common/utils";
import { PageLoadingSpinner } from "../../../../../../components/page-loading-spinner/page-loading-spinner";
import { NotFound } from "../../../../../../components/not-found/not-found";

const classname = "smart-link-edit";

const getSmsVoiceChannels = (state: AppState) => {
    return state.accountSettings.general.channelsArray?.sms_voice || [];
};

type SmartLinkEditProps = {
    onSmartLinkUpdate: (smartLink: SmartLink) => void;
    setSelectedSmartLink: (smartLink: SmartLink) => void;
    onDeleteSmartLink: (smartLinkId: string) => void;
};

const STEPS_FIELDS_MATCH_INDEX = {
    name: 0,
    description: 0,
    keyword: 0
};

export const SmartLinksEdit = ({ onSmartLinkUpdate, onDeleteSmartLink }: SmartLinkEditProps) => {
    const { default_queue: defaultQueue } = useSelector((state: AppState) => state.accountSettings.organization);
    const dispatch = useDispatch();
    const history = useHistory();
    const [tabId, setTabId] = useState<number>(0);
    const [selectedSmartLink, setSelectedSmartLink] = useState<SmartLink | null>(null);
    const [smartLinkLoading, setSmartLinkLoading] = useState<boolean>(true);
    const [error404, setError404] = useState(false);
    const [backEndValidateErrors, setBackEndValidateErrors] = useState<any>(null);
    const [stepsWithValidationError, setStepsWithValidationError] = useState({});

    const [qrCodeFile, setQrCodeFile] = useState<File | null>(null);
    const [saveChangesProcessing, setSaveChangesProcessing] = useState(false);
    const wasMount: MutableRefObject<boolean> = useRef<boolean>(false);

    const { id: idFromUrl } = useParams<RouteParams>();

    const setStepWithError = (err?: any, actions?: any, keys?: any) => {
        actions && err && setFormikFieldErrors(err, actions);
        const stepsNumbers = getStepsWithErrors(
            STEPS_FIELDS_MATCH_INDEX,
            keys || getKeys(err || backEndValidateErrors)
        );
        setStepsWithValidationError(stepsNumbers);
    };

    const initialValues = selectedSmartLink
        ? {
              name: selectedSmartLink?.name,
              description: selectedSmartLink?.description,
              keyword: selectedSmartLink?.keyword,
              queue: selectedSmartLink?.conversations_settings?.queue || defaultQueue,
              admin: selectedSmartLink?.conversations_settings?.admin,
              tags: selectedSmartLink?.conversations_settings?.tags,
              qrCodeStyle: selectedSmartLink?.qr_code_params?.qr_code_style,
              qrCodeBgColor: selectedSmartLink?.qr_code_params?.qr_code_bg_color,
              qrCodeColor: selectedSmartLink?.qr_code_params?.qr_code_color,
              qrCodeFile: null,
              qrCodeImgUrl: selectedSmartLink?.qr_code_params?.qr_code_img_url
          }
        : undefined;

    const formikValues: FormikValues = useFormik<FormikValues>({
        enableReinitialize: true,
        initialValues,
        validationSchema: FORMIK_SMART_LINKS_VALIDATION_SCHEMA_ALL,
        onSubmit: async (values, actions) => {
            try {
                setSaveChangesProcessing(true);
                const updatedData = {
                    name: values.name,
                    description: values.description,
                    keyword: values.keyword,
                    conversations_settings: {
                        queue_id: values.queue?.id,
                        admin_id: values.admin?.id,
                        tag_ids: values.tags?.map((tag) => tag.id)
                    },
                    qr_code_params: {
                        qr_code_style: values.qrCodeStyle,
                        qr_code_bg_color: values.qrCodeBgColor,
                        qr_code_color: values.qrCodeColor,
                        qr_code_img_url: values.qrCodeImgUrl
                    }
                };
                if (
                    initialValues.qrCodeStyle !== values.qrCodeStyle ||
                    initialValues.qrCodeBgColor !== values.qrCodeBgColor ||
                    initialValues.qrCodeColor !== values.qrCodeColor
                ) {
                    const res = await postMediaRequest({
                        type: "image/png",
                        filename: "qr.png",
                        key: "smart_link_qr_url"
                    });
                    const { preview_url, presigned_url } = res.data;

                    await axios.put(presigned_url, qrCodeFile, {
                        headers: { "Content-Type": qrCodeFile?.type }
                    });

                    updatedData.qr_code_params.qr_code_img_url = preview_url;
                }
                const res = await updateSmartLink(selectedSmartLink.id, updatedData);
                onSmartLinkUpdate(res.data.smart_link);
                history.push("/account-settings/smart-links");
                dispatch(onUpdateToast({ type: "saved", value: "Smart Link was successfully updated" }));
            } catch (err) {
                setBackEndValidateErrors(err);
                setStepWithError(err, actions);
            } finally {
                setSaveChangesProcessing(false);
            }
        }
    });

    const onGetSmartLink = async () => {
        try {
            setSmartLinkLoading(true);
            const res = await getSmartLink(idFromUrl);
            setSelectedSmartLink(res.data.smart_link);
        } catch (err) {
            if (err.response.status == 404) {
                setError404(true);
                setTimeout(() => {
                    history.replace("/account-settings/smart-links");
                    setError404(false);
                }, 3000);
            }
        } finally {
            setSmartLinkLoading(false);
        }
    };

    useEffect(() => {
        dispatch(onGetChannelsArray());
        onGetSmartLink();
        wasMount.current = true;
    }, []);

    const onSmartLinkDelete = useCallback(async () => {
        await deleteSmartLink(selectedSmartLink.id);
        onDeleteSmartLink(selectedSmartLink.id);
        history.push("/account-settings/smart-links");
    }, [selectedSmartLink?.id]);

    useMemo(() => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Smart Links" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            ),
            rightElement: (
                <StyledAlert
                    Target={(props) => (
                        <StyledButton {...props} type={StyledButtonTypes.delete} minimal={true} text={"Delete"} />
                    )}
                    type={StyledAlertTypes.warning}
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={false}
                    confirmButtonProps={{
                        type: StyledButtonTypes.delete
                    }}
                    confirmButtonText={"Delete"}
                    cancelButtonText={"Cancel"}
                    onConfirm={onSmartLinkDelete}
                    fullScreen
                    globalContentProps={{
                        title: "Delete Smart Link",
                        description: `Are you sure you want to delete your smart link "${selectedSmartLink?.name}"?`
                    }}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    }, [history, formikValues.handleSubmit, selectedSmartLink, onSmartLinkDelete]);

    const socailBanterThumbnailProps = useMemo(
        () => ({
            backgroundColor: selectedSmartLink?.thumbnail_color
        }),
        [selectedSmartLink]
    );

    useEffect(() => {
        if (wasMount.current) {
            setStepWithError(undefined, undefined, Object.keys(formikValues.errors));
        }
    }, [formikValues.errors]);

    if (error404) {
        return <NotFound />;
    }

    if ((smartLinkLoading && !selectedSmartLink) || !formikValues.values) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[classname]}>
            <SettingsSocialBanter
                title={selectedSmartLink?.name}
                subtitle={
                    <a href={selectedSmartLink?.public_url} target="_blank">
                        {selectedSmartLink?.public_url}
                    </a>
                }
                roundedIconProps={socailBanterThumbnailProps}
                entityName={selectedSmartLink?.name}
                customRightElement={<SmartLinkItemInfo smartLink={selectedSmartLink} />}
            />
            <StyledTabs
                // notShowTabs={viewType === "mentions" || isSearching}
                tabsWithErrors={stepsWithValidationError}
                tabs={["SETTINGS", "DESIGN"]}
                tabsProps={{
                    // id: classes,
                    selectedTabId: tabId,
                    onChange: setTabId
                }}
                tabClassName={styles[`${classname}-tab-panel`]}
                type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                panel={
                    <div className={styles[`${classname}-main-container`]}>
                        <form className={styles[`${classname}-form`]}>
                            {tabId === 0 && (
                                <>
                                    <h4>GENERAL SETTINGS</h4>
                                    <SmartLinkOverview formikValues={formikValues} />
                                    <h4>ROUTING</h4>
                                    <SmartLinksRoutingSettings formikValues={formikValues} />
                                </>
                            )}
                            {tabId === 1 && (
                                <SmartLinksQrCode
                                    selectedSmartLink={selectedSmartLink}
                                    formikValues={formikValues}
                                    setFile={setQrCodeFile}
                                />
                            )}
                        </form>
                        <FormWasChanged formik={formikValues} />
                    </div>
                }
            />
        </div>
    );
};
