// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { MPDScroll } from "src/mpd-library";

import { TabId } from "@blueprintjs/core";

import { BreadCrumbs, StyledTabs, StyledTabsTypes } from "src/components";

import SubscriptionSettingsDetails from "./subscription-settings-details/subscription-settings-details";

import SubscriptionSettingsOverview from "./subscription-settings-overview/subscription-settings-overview";

import { Redirect, Route, Switch } from "react-router-dom";

import { IDetailView } from "../../../";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { bindActionCreators } from "redux";

export interface ISubscriptionSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
}

interface ISubscriptionSettingsState {
    selectedTabNum: TabId;
    tabs: Array<string>;
}

class SubscriptionSettings extends React.Component<ISubscriptionSettingsProps, ISubscriptionSettingsState> {
    constructor(props: ISubscriptionSettingsProps) {
        super(props);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.updatedSelectedTabNum = this.updatedSelectedTabNum.bind(this);
        this.onUpdateToolBar = this.onUpdateToolBar.bind(this);
        this.onUpdateToolBar();

        this.state = {
            selectedTabNum: 0,
            tabs: ["overview", "details"]
        };
    }

    public render() {
        const { className, routeProps } = this.props;
        const { selectedTabNum, tabs } = this.state;
        const classes = classNames("subscription-settings", className);

        return (
            <div className={classes}>
                <Redirect
                    exact={true}
                    from="/account-settings/subscription"
                    to="/account-settings/subscription/overview"
                />
                <StyledTabs
                    tabs={tabs}
                    type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                    tabsProps={{
                        id: "subscription-settings-tabs",
                        onChange: this.onChangeTab,
                        selectedTabId: selectedTabNum
                    }}
                />
                <MPDScroll className={classNames(classes + "-scroll-container")} yDirection={true}>
                    <Switch location={routeProps.history.location}>
                        <Route
                            path={"/account-settings/subscription/overview"}
                            exact={true}
                            render={() => <SubscriptionSettingsOverview />}
                        />
                        <Route
                            exact={true}
                            path={"/account-settings/subscription/details"}
                            render={() => (
                                <React.Fragment>
                                    <SubscriptionSettingsDetails />
                                </React.Fragment>
                            )}
                        />
                    </Switch>
                </MPDScroll>
            </div>
        );
    }

    private updatedSelectedTabNum() {
        const { routeProps } = this.props;
        const { tabs } = this.state;
        const pathname = routeProps.history.location.pathname;
        const pathArr = pathname.split("/");
        const selectedTabNum = tabs.findIndex((tab: string) => tab === pathArr[pathArr.length - 1]);
        this.setState({ selectedTabNum });
    }

    private onChangeTab(newTabNum: TabId) {
        const { routeProps } = this.props;
        const { tabs } = this.state;
        const tabId = tabs[newTabNum].toLowerCase();
        this.setState({ selectedTabNum: newTabNum });
        routeProps.history.push(`/account-settings/subscription/${tabId}`);
    }

    private onUpdateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Subscription" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettings);
