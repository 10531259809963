import { Point } from "@turf/helpers";
import { ProfilePic } from "./commonInterfaces";

export interface ConversationUser {
    first_name: string;
    id: string;
    last_name: string;
    profile_pic: any;
    status?: string;
}

export interface LastMessage {
    body: string;
    id: string;
    inserted_at: string;
    location: null;
    type: string;
    updated_at: string;
}

export interface ConversationListItem {
    id: string;
    lastMessage: LastMessage;
    status: string;
    tags: Array<Tag>;
    user: ConversationUser;
    unread_count: number;
}

export type ConversationStatus = "opened" | "assigned" | "resolved";

export interface Conversation {
    admin?: {
        first_name: string;
        id: string;
        last_name: string;
    };
    id: string;
    inserted_at: string;
    last_seen_ts: number | string | null;
    queue: {
        id: string;
        name: string;
    };
    status: ConversationStatus;
    tags: Array<Tag>;
    unread_count: null | number;
    updated_at: string;
    user: ConversationUser;
    user_identity_hidden: boolean;
    snoozed_until: string;
    spam: boolean;
    last_message_summary: string;
}

export interface ConversationMessageAttachment {
    attachment: {
        size: number;
        type: string;
        url: string;
    };
    id: string;
}

export interface ConversationMessageEntity {
    id: string;
    text: string;
    type: string;
}

export interface ConversationMessageSender {
    first_name: string;
    last_name: string;
    id: string;
    profile_pic: null | {
        type: string;
        url: string;
    };
    status: string;
}
export interface ConversationMessage {
    attachments?: Array<ConversationMessageAttachment>;
    body: string;
    entities?: Array<ConversationMessageEntity>;
    id?: string;
    inserted_at?: string;
    location?: Point | null;
    type: string;
    updated_at?: string;
    sender?: ConversationMessageSender;
}
export interface ConversationTag {
    description?: string;
    id: string;
    name: string;
    nodes: Array<ConversationTag>;
    ancestors_formatted?: any;
    parent?: ConversationTag;
}

export interface ConversationQueue {
    id: string;
    is_default: boolean;
    name: string;
    description: string;
    admins: Array<{
        admin: {
            first_name: string;
            last_name: string;
            id: string;
            profile_pic: null | {
                utl: string;
            };
        };
        based_on_permissions: boolean;
    }>;
}

export type ViewEnum =
    | "assigned"
    | "resolved"
    | "mentions"
    | "snoozed"
    | "opened"
    | "spam"
    | "mentions_resolved"
    | "allConversations";
export type NotificationTypesEnum = "incoming" | "mention" | "reply" | "assign";

export type AdminMention = {
    id: string;
    name: string;
    link: string;
    avatar: string;
    first_name: string;
    last_name: string;
};

export type ConversationsCounts = {
    assigned: number;
    inbox: number;
    resolved: number;
    spam: number;
    snoozed: number;
};

export type SnippetCategory = {
    id: string;
    name: string;
};

export type ConversationsFilterValuesPeriod = null | "today" | "yesterday" | "30 days" | "90 days";

export type OrderEnum = "inserted_at:asc" | "inserted_at:desc" | "updated_at:asc" | "updated_at:desc";

export type StatusesEnum = "opened" | "assigned" | "resolved" | null;

export type ConversationsFilterValues = {
    order: OrderEnum;
    status: StatusesEnum;
    queue_ids: { [key: string]: boolean };
    admin_ids: { [key: string]: boolean } | "all_admins";
    tag_ids: { [key: string]: boolean };
    user_ids: { [key: string]: boolean };
    period: ConversationsFilterValuesPeriod;
    queues: Required<{ id: string }>;
    admins: Required<{ id: string }>;
    users: Required<{ id: string }>;
    tags: Required<{ id: string }>;
};

export type ConversationFilter = {
    isFilterApplyed: boolean;
    filterValues: ConversationsFilterValues;
    filterCounter: number;
    selectedFilterId: null | string;
    viewId?: null | string;
    selectedFilterName?: null | string;
    shouldBeEdited: boolean;
};

export type ConversationsViewType = {
    filters: ConversationsFilterValues;
    id: string;
    name: string;
};

export type ViewData = {
    id?: string;
    filters?: {
        admin_ids: Array<string>;
        order: OrderEnum;
        period: ConversationsFilterValuesPeriod;
        queue_ids: Array<string>;
        status: StatusesEnum;
        tag_ids: StatusesEnum;
    };
    name: string;
    value?: ViewEnum;
};

export type GlobalViewEnum = "all" | "inbox";

export type ConversationsUser = {
    id: string;
    blocked: boolean;
    blocked_until: null | number;
    contacts: {
        email: string;
        phone_number: string;
    };
    first_name: string;
    last_name: string;
    notes: Array<any>;
    profile_pic: ProfilePic;
    profile_pic_color: string;
    starred: boolean;
    status: "away" | "online";
};

export interface ConversationsState {
    conversations: {
        byId: {
            [key: string]: ConversationListItem;
        };
        inbox: Array<string>;
        assigned: Array<string>;
        resolved: Array<string>;
        mentions: Array<string>;
        search: Array<string>;
        alertReplies: Array<string>;
    };
    conversationsUsers: {
        byId: { [key: string]: ConversationsUser | null };
    };
    selectedConversationId: string;
    gettingConversationStatus: boolean;
    // conversationsDetails: {
    //     [key: string]: Conversation;
    // };
    viewType: ViewEnum;
    admins: { hasMoreAdmins: boolean; adminsList: Array<AdminMention> };
    selectedQueueId: string;
    counts: ConversationsCounts;

    conversationFilter: ConversationFilter;
    conversationsViews: Array<ConversationsViewType>;
    conversationsListLoading: boolean;
    hasMoreConversations: boolean;
    // selectedConversationTag: ConversationTag | null;
    accessErrorToConversation: {
        [key: string]: boolean;
    };
    conversationsCounter: number | null;
    prevConversationsWithSender: { list: Array<Conversation>; has_more: boolean };
    selectedViewData: ViewData;
    queuesBasedOnPermissionsAdmins: Array<any>;
    globalView: GlobalViewEnum;
}

export const defaultConversationsState = (): ConversationsState => ({
    conversations: {
        byId: {}
    },
    selectedConversationId: "",
    gettingConversationStatus: false,
    // conversationsDetails: {},
    viewType: null,
    admins: {
        hasMoreAdmins: true,
        adminsList: []
    },
    selectedQueueId: null,
    counts: {
        assigned: 0,
        inbox: 0,
        resolved: 0,
        snoozed: 0,
        spam: 0
    },

    conversationFilter: {
        filterCounter: 0,
        isFilterApplyed: false,
        selectedFilterId: null,
        selectedFilterName: null,
        filterValues: {
            order: "updated_at:desc",
            status: null,
            queue_ids: {},
            admin_ids: {},
            tag_ids: {},
            period: null
        },
        shouldBeEdited: false
    },
    conversationsViews: [],
    selectedViewData: {
        name: "Inbox",
        value: "inbox"
    },
    conversationsListLoading: false,
    hasMoreConversations: false,
    accessErrorToConversation: {},
    conversationsCounter: null,
    prevConversationsWithSender: {
        list: [],
        has_more: false
    },
    queuesBasedOnPermissionsAdmins: [],
    globalView: "inbox",
    conversationsUsers: { byId: {} }
});
