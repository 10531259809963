import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { Text } from "@blueprintjs/core";
import { FormikValues, useFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, Route, Redirect, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import { BreadCrumbs, Stepper, StepperStates, StyledButton, StyledButtonTypes } from "src/components";
import {
    childFactory,
    useSwitchingStepsAnimation
} from "src/components/switching-steps-animation/switching-steps-animation";
import { useQueues } from "src/containers/conversations/components/chat/hooks/useQueues";
import { createSmsVoiceChannel, GetPhoneNumbersResponsePhoneNumber } from "src/requests/channelsRequests";
import { FORMIK_LINE_VALIDATION_OBJECT, STEPPER_INIT_DATA } from "../../constants";
import { ChooseNumber } from "../choose-number/choose-number";
import { Customize } from "../customize/customize";
import { SmsVoiceChannelCreateOverview } from "../sms-voice-channel-create-overview/sms-voice-channel-create-overview";
import styles from "./styles.module.scss";
import { onUpdateToast } from "../../../../../../../../actions/global/global";
import { addChannel } from "../../../../../../../../actions/account-settings/account-settings";
import { DialogStepper } from "../../../../../../../../components/dialog-stpper/DialogStepper";
import { ReactComponent as LeftArrowBlackIcon } from "../../../../../../../../mpd-library/icon/assets/left-arrow-black.svg";
import { AppState } from "../../../../../../../../store";

const classname = "sms-channel-create";
const baseUrl = "account-settings/channels/sms_voice/create";

export const CreateSmsVoiceChannelModal = ({ Target }) => {
    const { default_queue: defaultQueue } = useSelector((state: AppState) => state.accountSettings.organization);
    const dispatch = useDispatch();

    const formikConfig = {
        validationSchema: FORMIK_LINE_VALIDATION_OBJECT,
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            lineName: "",
            description: "",
            phone_number: null,
            enableForConversations: false,
            queue: defaultQueue,
            admin: "",
            tags: [],
            user_identity_hidden: false
        },
        initialTouched: {
            queue: true
        }
    };

    const submit = async (selectedValues, actions) => {
        try {
            // setCreateSmsChannelProcessing(true);
            const res = await createSmsVoiceChannel({
                name: selectedValues.lineName,
                description: selectedValues.description,
                phone_number: selectedValues.phone_number.phone_number,
                conversations_enabled: true,
                user_identity_hidden: selectedValues.user_identity_hidden,
                conversations_settings: {
                    queue_id: selectedValues.queue.id,
                    tag_ids: selectedValues.tags.map((tag) => tag.id),
                    admin_id: selectedValues.admin.id
                }
            });
            // should be improved
            dispatch(addChannel("sms_voice", res.data.channel));
        } catch (err) {
            console.log("CREATE LINE ERROR >>>>", err);
        } finally {
            // setCreateSmsChannelProcessing(false);
        }
    };

    const renderTarget = (onModaOpen) => {
        return Target ? (
            <Target onClick={onModaOpen} />
        ) : (
            <StyledButton type={StyledButtonTypes.primary} text="Add New" onClick={onModaOpen} />
        );
    };

    return (
        <DialogStepper
            stepsPanels={[
                {
                    title: "Name your SMS Channel",
                    subtitle: "To start, please give your new SMS/Voice channel a name",
                    stepLabel: "name",
                    component: SmsVoiceChannelCreateOverview
                },
                {
                    title: "Select a new number",
                    subtitle: "Enter the details below to select a new number for your sms/voice line",

                    stepLabel: "choose number",
                    component: ChooseNumber
                },
                {
                    title: "Customize conversation settings",
                    subtitle: "Add automation when you receive inbound messages from this sms line",
                    stepLabel: "automation",
                    component: Customize
                }
            ]}
            title={"Create SMS/Voice Line"}
            formikConfig={formikConfig}
            renderTarget={renderTarget}
            submit={submit}
        />
    );
};

export const SmsVoiceChannelCreate = () => {
    const activeStepIndex: MutableRefObject<number> = useRef<number>(0);
    const wasMount: MutableRefObject<boolean> = useRef<boolean>(false);

    const [stepperData, setStepperData] = useState<any>(STEPPER_INIT_DATA);
    const [whereToScroll, setWhereToscroll] = useState<"bottom" | "top">("bottom");
    const [selectedNumberData, setSelectedNumberData] = useState<GetPhoneNumbersResponsePhoneNumber | null>(null);
    const [createSmsChannelProcessing, setCreateSmsChannelProcessing] = useState<boolean>(false);

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const cssTransitionAnimationClasses = useSwitchingStepsAnimation({ whereToScroll });
    const { default_queue: defaultQueue } = useSelector((state: AppState) => state.accountSettings.organization);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Channels", pathname: `/account-settings/channels` },
                        { text: "SMS/Voice", pathname: "/account-settings/channels/sms_voice" },
                        { text: "New SMS/Voice Account" }
                    ]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    useEffect(() => {
        updateToolBar();
        changeActiveStep(0);
        if (!wasMount.current) {
            wasMount.current = true;
        }
    }, []);

    const formikValues: FormikValues = useFormik<FormikValues>({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            lineName: "",
            description: "",
            phone_number: "",
            enableForConversations: false,
            queue: defaultQueue,
            admin: "",
            tags: []
        },
        initialTouched: {
            queue: true
        },
        // combinedErrors: false,
        validationSchema: FORMIK_LINE_VALIDATION_OBJECT[activeStepIndex.current],
        onSubmit: (values, actions) => {
            // if (activeStepIndex.current === 2) {
            //     onFinishClick(values);
            // } else {
            //     onNextClick();
            //     actions.setTouched({});
            //     actions.setSubmitting(false);
            // }
        }
    });

    const { submitForm, isValid } = formikValues;

    const checkAndJumpToStep = useCallback(
        (stepIndex): void => {
            if (stepIndex > activeStepIndex.current) {
                submitForm().then(() => {
                    if (isValid) {
                        changeActiveStep(stepIndex);
                    } else {
                        dispatch(onUpdateToast({ type: "error", value: "Validation error. Please check the form" }));
                    }
                });
            } else {
                changeActiveStep(stepIndex);
            }
        },
        [submitForm, isValid]
    );

    const changeActiveStep = async (stepIndex: number) => {
        setStepperData([
            ...stepperData.map((step, index) => {
                if (index === stepIndex) {
                    return {
                        ...step,
                        active: true,
                        state: StepperStates.active
                    };
                }
                return {
                    ...step,
                    active: false,
                    state: StepperStates.default
                };
            })
        ]);
        setWhereToscroll(stepIndex >= activeStepIndex.current ? "bottom" : "top");
        activeStepIndex.current = stepIndex;
        history.push(`/${baseUrl}/${STEPPER_INIT_DATA[stepIndex].nav}`);
    };

    const onNextClick = () => {
        changeActiveStep(activeStepIndex.current + 1);
    };

    const onBackClick = () => {
        changeActiveStep(activeStepIndex.current - 1);
    };

    const onFinishClick = async (selectedValues) => {
        try {
            setCreateSmsChannelProcessing(true);
            const res = await createSmsVoiceChannel({
                name: selectedValues.lineName,
                description: selectedValues.description,
                phone_number: selectedValues.phoneNumber,
                conversations_enabled: selectedValues.enableForConversations,
                conversations_settings: selectedValues.enableForConversations
                    ? {
                          queue_id: selectedValues.queue.id,
                          tag_ids: selectedValues.tags.map((tag) => tag.id),
                          admin_id: selectedValues.admin.id
                      }
                    : null
            });
            // should be improved
            dispatch(addChannel("sms_voice", res.data.channel));
        } catch (err) {
            console.log("CREATE LINE ERROR >>>>", err);
        } finally {
            setCreateSmsChannelProcessing(false);
        }
    };

    if (!wasMount.current) {
        return <Redirect to={`/${baseUrl}/overview`} />;
    }

    return (
        <form onSubmit={formikValues.handleSubmit} className={styles[classname]}>
            {/* <div className={styles[classname]}> */}
            <div className={styles[`${classname}-stepper-wrapper`]}>
                <Text className={styles[`${classname}-stepper-title`]}>NEW LINE</Text>
                <Stepper
                    data={stepperData}
                    onItemClick={checkAndJumpToStep}
                    // availabaleSteps={availableStepsIndexes}
                    className={"vertical"}
                />
            </div>

            <TransitionGroup
                className={styles[`${classname}-tr-group`]}
                childFactory={childFactory(cssTransitionAnimationClasses)}
            >
                <CSSTransition timeout={300} key={location.pathname}>
                    <Switch location={location}>
                        <Route
                            path={`/${baseUrl}/overview`}
                            render={() => (
                                <div className={styles[`${classname}-route-wrapper`]}>
                                    <h1 className={styles[`${classname}-step-title`]}>Overview</h1>
                                    <SmsVoiceChannelCreateOverview formik={formikValues} />
                                </div>
                            )}
                        />
                        <Route
                            path={`/${baseUrl}/number`}
                            render={() => (
                                <div className={styles[`${classname}-route-wrapper`]}>
                                    <h1 className={styles[`${classname}-step-title`]}>Choose Number</h1>
                                    <ChooseNumber
                                        formik={formikValues}
                                        selectedNumberData={selectedNumberData}
                                        setSelectedNumberData={setSelectedNumberData}
                                    />
                                </div>
                            )}
                        />
                        <Route
                            path={`/${baseUrl}/customize`}
                            render={() => (
                                <div className={styles[`${classname}-route-wrapper`]}>
                                    <h1 className={styles[`${classname}-step-title`]}>Customize</h1>
                                    <Customize formik={formikValues} />
                                </div>
                            )}
                        />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
            {/* <button type="submit">SUBMIT</button> */}

            <div className={styles[`${classname}-footer`]}>
                {activeStepIndex.current > 0 ? (
                    <StyledButton
                        className={"bottom-toolbar-back-button"}
                        text="BACK"
                        LeftIconComponent={LeftArrowBlackIcon}
                        onClick={onBackClick}
                    />
                ) : (
                    <div />
                )}
                <StyledButton
                    inputType="submit"
                    type={StyledButtonTypes.primary}
                    text={activeStepIndex.current < 2 ? "Next" : "Finish"}
                    disabled={!isEmpty(formikValues.errors)}
                    processing={createSmsChannelProcessing}
                />
            </div>
            {/* </div> */}
        </form>
    );
};
