import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import { MPDIcon } from "src/mpd-library";

import { IStyledButtonProps, StyledButton } from "src/components";

import "./styles.scss";
import { ReactComponent as CheckmarkBlueIcon } from "../../../../../../mpd-library/icon/assets/checkmark-blue.svg"

interface ISubscriptionBoxContentProps extends IProps {
    test?: string;
    list: Array<string>;
    title?: string;
    actionProps?: IStyledButtonProps;
}

export class SubscriptionBoxContent extends React.Component<ISubscriptionBoxContentProps> {
    public render() {
        const { className, list, title, actionProps } = this.props;
        const classes = "subscription-box-content";

        return (
            <div className={classNames(classes, className)}>
                <Text className={classes + "-title"}> {title} </Text>
                {list.map((text: string, index: number) => (
                    <div className={classes + "-list-item"} key={index}>
                        <MPDIcon className={classes + "-list-item-checkmark"} IconComponent={CheckmarkBlueIcon} />
                        <Text className={classes + "-list-item-title"}> {text} </Text>
                    </div>
                ))}
                <StyledButton className={classes + "-action"} {...actionProps} />
            </div>
        );
    }
}
