import { Spinner, Text } from "@blueprintjs/core";
import { useCallback } from "react";
import { useReauthChannel } from "src/containers/conversations/components/chat/hooks/useReauthChannel";
import "./styles.scss";

type ReauthCahnnelMessageProps = {
    channel: any;
    title?: string;
};

export const ReauthCahnnelMessage = ({ channel, title }: ReauthCahnnelMessageProps) => {
    const { onInstall, authChannelProcessing } = useReauthChannel();

    const onReauthClick = useCallback(
        (e) => {
            onInstall(e, channel.type);
            return;
        },
        [channel.id]
    );

    return (
        <div className={"reauth-message-wrapper"}>
            <Text className={"reauth-warning"}>
                {title || "Warning: This account is offline and needs to be re-authorized"}
            </Text>
            <button onClick={onReauthClick}>Re-Authorize Now</button>
            {!!authChannelProcessing && <Spinner intent="primary" size={20} />}
        </div>
    );
};
