import React, { FunctionComponent, SVGProps } from "react";
import { Collapse, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { cloneElement, memo, useMemo, useState } from "react";
import { StyledSwitch } from "src/components";
import { MPDIcon } from "src/mpd-library";
import styles from "./styles.module.scss";
import { ReactComponent as DownArrowIcon } from "../../../../../../mpd-library/icon/assets/down-arrow.svg"

const classname = "profile-automation-item";

type ProfileAutomationsItemProps = {
    title: string;
    subtitle: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    children: JSX.Element;
    index: number;
    collapsedIndex: number | null;
    showSwitch?: boolean;
    onSwitchToggle: any;
    notShowActive?: boolean;
    setCollapsedIndex: (index: number) => void;
};

export const ProfileAutomationsItem = memo(
    ({
        title,
        subtitle,
        IconComponent,
        children,
        index,
        collapsedIndex,
        showSwitch,
        onSwitchToggle,
        notShowActive,
        setCollapsedIndex
    }: ProfileAutomationsItemProps) => {
        const [isChildrenCollapsed, setIsChildrenCollapsed] = useState(false);

        const onClick = () => {
            setIsChildrenCollapsed(!isChildrenCollapsed);
            setCollapsedIndex(index === collapsedIndex ? null : index);
        };

        const onCollapseAfterApply = () => {
            setIsChildrenCollapsed(false);
            setCollapsedIndex(null);
        };

        const activeClassName = useMemo(() => index === collapsedIndex && "active" && !notShowActive, [
            collapsedIndex,
            notShowActive
        ]);

        return (
            <div className={classNames(styles[classname], styles[activeClassName])}>
                <div className={styles[`${classname}-top-wrapper`]} onClick={onClick}>
                    <div className={styles[`${classname}-left-wrapper`]}>
                        <MPDIcon IconComponent={IconComponent} />
                        <div className={styles[`${classname}-titles-wrapper`]}>
                            <Text className={styles[`${classname}-title`]}>{title}</Text>
                            <Text className={styles[`${classname}-subtitle`]}>{subtitle}</Text>
                        </div>
                    </div>
                    {!showSwitch && <MPDIcon IconComponent={DownArrowIcon} className={styles[`${classname}-down-arrow`]} />}
                    {showSwitch && <StyledSwitch onChange={onSwitchToggle} checked={index === collapsedIndex} />}
                </div>

                <Collapse isOpen={index === collapsedIndex} className={styles[`${classname}-collapse`]}>
                    {cloneElement(children, { onCollapseAfterApply })}
                </Collapse>
            </div>
        );
    }
);
