import { useDispatch, useSelector } from "react-redux";
import { ContactsList } from "../../../../contacts/contacts-list/contacts-list";
import { onUpdateToast, onUpdateToolBar } from "../../../../../actions/global/global";
import { onToggleRightPanel } from "../../../../../actions/account-settings/account-settings-api";
import { navigate } from "../../../../../actions/global/global-api";
import { useContext, useEffect, useMemo, useState } from "react";
import { BreadCrumbs } from "../../../../../components";
import { useHistory, useParams } from "react-router";
import { ContactsContext, ContactsContextProvider } from "../../../../contacts/contacts-list/context";
import { getFittedFilters } from "../../../../contacts/utils";
import { getContactsList, updateContactList } from "../../../../../requests/contactsRequests";
import { PageLoadingSpinner } from "../../../../../components/page-loading-spinner/page-loading-spinner";
import { AppState } from "../../../../../store";
import { removeWsData } from "../../../../../actions/websocket/websocket";
import { EditFiltersButtonProps } from "../../../../../components/contacts-filters/ContactsFilters";

const LIST_ITEM_FIELDS = {
    allIds: ["checkbox", "name", "channels", "activity", "last_contacted_at", "tags", "more"],
    byId: {
        checkbox: {
            field: "checkbox",
            enabled: true
        },
        name: {
            field: "name",
            enabled: true
        },
        channels: {
            field: "channels",
            enabled: true
        },
        activity: {
            field: "activity",
            enabled: true
        },
        last_contacted_at: {
            field: "last_contacted_at",
            enabled: true
        },
        tags: {
            field: "tags",
            enabled: true
        },
        more: {
            field: "more",
            enabled: true
        }
    }
};

const getSelectedContactsOptions = (selectedList) => {
    return {
        tags: true,
        ...(selectedList?.type === "static" ? { remove_from_list: true } : {}),
        more: true
    };
};

const contacts_list_deleted = "contacts_list_deleted";
const topic = "portal_admin_general";

const EDIT_FILTER_BUTTON_PROPS: EditFiltersButtonProps = {
    defaultText: "Edit Filter",
    editModeText: "Save Filter"
};

export const ContactsListPreviewComponent = ({ selectedList, setSelectedList }) => {
    const { list_id } = useSelector(
        (state: AppState) => state.websocketReducer.wsData?.[topic]?.[contacts_list_deleted] || {}
    );
    const { setPresettedFilter, presettedFilter } = useContext(ContactsContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);

    const { id: idFromUrl } = useParams<any>();

    useEffect(() => {
        if (list_id) {
            dispatch(removeWsData(`${topic}.${contacts_list_deleted}`));
            history.push("/account-settings/lists");
            dispatch(onUpdateToast({ type: "error", value: "List was removed" }));
        }
    }, [list_id]);

    const getSelectedList = async () => {
        try {
            setLoading(true);
            const res = await getContactsList(idFromUrl);
            setSelectedList(res.data.list);

            setPresettedFilter(getFittedFilters(res.data?.list?.filters));
        } catch (err) {
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (loading && presettedFilter && selectedList) {
            setLoading(false);
        }
    }, [presettedFilter, loading, selectedList]);

    useEffect(() => {
        if (selectedList) {
            setPresettedFilter(getFittedFilters(selectedList?.filters));
            setLoading(false);
        } else {
            getSelectedList();
        }

        return () => {
            setSelectedList(null);
        };
    }, []);

    useEffect(() => {
        if (selectedList?.id) {
            updateToolBar();
        }
    }, [selectedList?.id]);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Lists", pathname: "/account-settings/lists" },
                        { text: selectedList?.name }
                    ]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: `/account-settings`, history }))
                    }}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    const editView = async (filters) => {
        const res = await updateContactList({ ...selectedList, filters });
        setSelectedList(res.data.list);
        dispatch(onUpdateToast({ type: "saved", value: "Changes were successfully saved" }));
    };

    const selectedContactsOptions = useMemo(() => getSelectedContactsOptions(selectedList), [selectedList?.type]);

    if (loading) {
        return <PageLoadingSpinner />;
    }

    return (
        <ContactsList
            isCreateNewContactPopupOpen={false}
            setIsCreateNewContactPopupOpen={() => null}
            listItemConfig={LIST_ITEM_FIELDS}
            optionsForSelectedContacts={selectedContactsOptions}
            editFiltersButtonProps={EDIT_FILTER_BUTTON_PROPS}
            filtersProps={{
                editView,
                editFiltersButtonProps: {
                    defaultText: "Edit Filter",
                    editModeText: "Save Filter"
                }
            }}
        />
    );
};

export const ContactsListPreview = (props) => (
    <ContactsContextProvider>
        <ContactsListPreviewComponent {...props} shouldSetFiltersToUrl={false} />
    </ContactsContextProvider>
);
