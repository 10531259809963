// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { BreadCrumbs, StyledButton, StyledButtonTypes, ToastTypes, IToastType } from "src/components";


import { IDetailView } from "../../../";

import "./styles.scss";
import { AppState } from "src/store/store.js";
import { AccountSettingsState, JurisdictionFeature } from "src/store";
import { bindActionCreators } from "redux";
import { getAccountSettingsJurisdiction, updateAccountSettingsJurisdiction } from "src/actions/account-settings/account-settings-api";
import StyledMap from "src/components/styled-map";

export interface IJurisdicationSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    onGetAccountSettingsJurisdiction: () => void;
    onUpdateAccountSettingsJurisdiction: (updatedObj: JurisdictionFeature) => void;
    onUpdateToast: (toast: IToastType | ToastTypes) => void;
}

interface IJurisdicationSettingsState {
    jurisdiction?: JurisdictionFeature;
    editMode: boolean;
}

class JurisdicationSettings extends React.Component<IJurisdicationSettingsProps, IJurisdicationSettingsState> {
    constructor(props: IJurisdicationSettingsProps) {
        super(props);
        const { accountSettings } = this.props;

        this.onShapeComplete = this.onShapeComplete.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onUpdateToolBar = this.onUpdateToolBar.bind(this);

        this.state = {
            editMode: false,
            jurisdiction: JSON.parse(JSON.stringify(accountSettings.organization.jurisdiction))
        };
    }

    public componentDidMount() {
        this.onUpdateToolBar();
        this.props.onGetAccountSettingsJurisdiction();
    }

    public componentDidUpdate({ accountSettings }: IJurisdicationSettingsProps) {
        const { jurisdiction } = this.props.accountSettings.organization;
        const prevPropsJurisdiction = accountSettings.organization.jurisdiction;
        if (jurisdiction && prevPropsJurisdiction !== jurisdiction) {
            this.setState({ jurisdiction });
        }
    }

    public render() {
        // const { accountSettings, className } = this.props;
        const { className, onUpdateToast } = this.props;
        const { editMode, jurisdiction } = this.state;
        const classes = classNames("jurisdiction-settings", className);
        const drawingMode = editMode ? "polygon" : null;

        return (
            <div className={classes}>
                <StyledMap
                    showInfoBox={false}
                    showZoomControls={true}
                    showGps={true}
                    showUploadFile={editMode}
                    showDrawControls={editMode}
                    drawingMode={drawingMode}
                    // showListToggle={!editMode}
                    geoJsonData={jurisdiction}
                    onShapeComplete={this.onShapeComplete}
                    onUpdateToast={onUpdateToast}
                    isJurisdictionUploadPage={true}
                />
            </div>
        );
    }

    private onUpdateToolBar() {
        const { routeProps } = this.props;
        const { editMode } = this.state;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Jurisdiction" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <React.Fragment>
                    {!editMode ? (
                        <StyledButton type={StyledButtonTypes.primary} onClick={this.onEditClick} text={"Edit"} />
                    ) : (
                        <StyledButton type={StyledButtonTypes.primary} onClick={this.onSaveChanges} text={"Save"} />
                    )}
                </React.Fragment>
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    }

    private onEditClick() {
        this.setState({ editMode: true }, () => {
            this.onUpdateToolBar();
        });
    }

    private onSaveChanges() {
        const { jurisdiction } = this.state;
        const { onUpdateAccountSettingsJurisdiction } = this.props;
        if (jurisdiction) {
            this.props.accountSettings.organization.jurisdiction = jurisdiction;
        }
        if (onUpdateAccountSettingsJurisdiction && jurisdiction) {
            this.setState({ editMode: false }, () => {
                this.onUpdateToolBar();
                onUpdateAccountSettingsJurisdiction(jurisdiction);
            });
        }
    }

    private onShapeComplete(jurisdiction: JurisdictionFeature) {
        this.setState({ jurisdiction }, () => {
            if (this.props.onUpdateChangesMade) {
                this.props.onUpdateChangesMade(true);
            }
        });
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;

    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onUpdateAccountSettingsJurisdiction: updateAccountSettingsJurisdiction,
            onCancelChanges: globalActions.onCancelChanges,
            onGetAccountSettingsJurisdiction: getAccountSettingsJurisdiction,

            onUpdateToast: globalActions.onUpdateToast
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(JurisdicationSettings);
