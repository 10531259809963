import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";
import * as MPDClasses from "../";

import { Button, IButtonProps, Text, Spinner } from "@blueprintjs/core";

import { Alignment, alignmentClass, MPDIcon, States } from "../";

import "./styles.scss";

export interface IMPDButtonProps extends IButtonProps {
    text?: string;
    alignIcon?: Alignment;
    selectable?: boolean;
    urlSrc?: string;
    name?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    tabIndex?: number;
    style?: React.CSSProperties;
    processing?: boolean;
    buttonIconClassName?: string;
    inputType?: "submit" | "button";
}

interface IMPDButtonState {
    buttonState?: States;
    align: Alignment;
}

export class MPDButton extends React.Component<IMPDButtonProps, IMPDButtonState> {
    constructor(props: IMPDButtonProps) {
        super(props);
        this.onHover = this.onHover.bind(this);
        this.onLeave = this.onLeave.bind(this);
        const { alignIcon } = this.props;

        this.state = {
            align: alignIcon || Alignment.LEFT,
            buttonState: this.props.selectable ? States.selected : States.unselected,
            processing: false
        };
    }
    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IMPDButtonProps>) {
        if (nextProps.selectable !== this.props.selectable) {
            this.setState({
                buttonState: nextProps.selectable ? States.selected : States.unselected
            });
        }
    }

    public render() {
        const {
            LeftIconComponent,
            className,
            name,
            text,
            tabIndex,
            alignIcon,
            urlSrc,
            buttonIconClassName,
            inputType = "button",
            rightIconName,
            IconComponent,
            processing: processingProp,
            ...newProps
        } = this.props;
        const { buttonState, processing } = this.state;

        const classes = MPDClasses.BUTTON;

        return (
            <Button
                loading={processing || processingProp}
                className={classNames(
                    classes,
                    alignmentClass(alignIcon),
                    MPDClasses.statesClass(buttonState),
                    className,
                    processing && "with-spinner",
                    !text && "only-icon"
                )}
                tabIndex={tabIndex}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onLeave}
                type={inputType}
                {...newProps}
                onClick={this.onClick}
            >
                {((urlSrc === undefined && !processing) || LeftIconComponent) && (
                    <MPDIcon
                        IconComponent={LeftIconComponent}
                        className={classNames("button-left-icon", buttonIconClassName)}
                    />
                )}
                {urlSrc !== undefined && (
                    <div
                        className={classes + "-url-src"}
                        style={{
                            backgroundImage: `url(${urlSrc})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}
                    />
                )}

                {text !== undefined && <Text className={MPDClasses.BUTTON_TEXT}> {text} </Text>}
                {(!processing || IconComponent) && <MPDIcon IconComponent={IconComponent} className={"right-icon"} />}
            </Button>
        );
    }

    private onClick = async (event: any) => {
        const { processing } = this.state;
        if (processing) {
            return;
        }

        this.setState({ processing: true });
        try {
            const { onClick, selectable } = this.props;

            if (onClick) {
                if (selectable) {
                    this.setState({ buttonState: States.selected });
                }
                await onClick(event);
            }
        } finally {
            this.setState({ processing: false });
        }
    };

    private onHover() {
        if (this.state.buttonState !== States.selected) {
            this.setState({ buttonState: States.hover });
        }
    }

    private onLeave() {
        if (this.state.buttonState !== States.selected) {
            this.setState({ buttonState: States.default });
        }
    }
}
