import { USER_ONBOARDING_UPDATE, USER_ONBOARDING_CONFIRM } from "./types";

import axios from "axios";
import { postLoginSetPasswordRequest } from "../../requests";
import { Dispatch } from "redux";
import { AppState, UserOnBoardingState, UserOnboardingErrors, defaultUserOnBoardingState } from "../../store";
import { RouteComponentProps } from "react-router";
import {
    postUsersOnboardingPresignedUrlRequest,
    postUsersOnboardingSignupRequest
} from "src/requests/usersOnboardingRequests";

export const updateChanges = (ref: { updatedObj: UserOnBoardingState }) => (dispatch: Dispatch<AppState>) => {
    const { updatedObj } = ref;
    const userOnBoarding = updatedObj;
    dispatch(updateOnBoarding({ userOnBoarding }));
};

interface UploadLogoProps {
    file: any;
    src: string;
    routeProps: RouteComponentProps;
}
export const uploadLogo = (ref: UploadLogoProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const { userOnBoarding } = getState();
    const { file, src, routeProps } = ref;
    const { name: filename, type } = file;

    const token = getToken(routeProps, "confirm_account/");

    const updatedObj = {
        filename,
        type,
        token
    };

    const res = await postUsersOnboardingPresignedUrlRequest(updatedObj);

    const presigned_url = res.data.presigned_url;
    const formData = new FormData();
    formData.append("image", file, file.name);
    await axios.put(presigned_url, file);

    const url = presigned_url.split("?")[0];
    userOnBoarding.logo.url = url;
    userOnBoarding.logo.src = src;
    userOnBoarding.logo.type = type;
    dispatch(updateOnBoarding({ userOnBoarding }));
};

const updateOnBoarding = (ref: { userOnBoarding: UserOnBoardingState }) => (dispatch: Dispatch<AppState>) => {
    const { userOnBoarding } = ref;
    dispatch({
        type: USER_ONBOARDING_UPDATE,
        payload: userOnBoarding
    });
};

const getToken = (routeProps: RouteComponentProps, parseString: string) => {
    const pathname = routeProps.history.location.pathname;
    const pathArr = pathname.split(parseString);
    const uid = pathArr[pathArr.length - 1];
    return uid;
};

interface ConfirmUserProps {
    userOnBoarding: UserOnBoardingState;
    routeProps: RouteComponentProps;
    forgotPassword: boolean;
}
export const confirmUser = (ref: ConfirmUserProps) => async (dispatch: Dispatch<AppState>) => {
    const { userOnBoarding, routeProps, forgotPassword } = ref;
    const { password, confirmPassword, firstName, lastName, email, phoneNumber, logo, errors } = userOnBoarding;
    const { url, type } = logo;

    const error = validateForm({
        firstName,
        lastName,
        email,
        phoneNumber,
        errors,
        confirmPassword,
        password
    });

    if (!error && !forgotPassword) {
        localStorage.removeItem("auth");
        const token = getToken(routeProps, "confirm_account/");
        let profilePic: any;

        if (url && url.length > 0) {
            profilePic = {
                type,
                url
            };
        }

        const updatedObj = {
            token,
            user: {
                password,
                password_confirmation: confirmPassword,
                profile_pic: profilePic
            }
        };

        await postUsersOnboardingSignupRequest(updatedObj);

        dispatch({
            type: USER_ONBOARDING_CONFIRM,
            payload: { ...userOnBoarding, errors: defaultUserOnBoardingState.errors }
        });
        routeProps.history.push("/signin");
    } else if (!error && forgotPassword) {
        const token = getToken(routeProps, "restore_password/");
        const updatedObj = {
            token,
            password,
            password_confirmation: confirmPassword
        };

        await postLoginSetPasswordRequest(updatedObj);

        dispatch({
            type: USER_ONBOARDING_CONFIRM,
            payload: { ...userOnBoarding, errors: defaultUserOnBoardingState.errors }
        });
        routeProps.history.push("/signin");
    } else {
        dispatch({
            type: USER_ONBOARDING_UPDATE,
            payload: userOnBoarding
        });
    }
};

interface ValidateFormProps {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    errors: UserOnboardingErrors;
}
const validateForm = (ref: ValidateFormProps) => {
    // const { password, confirmPassword, firstName, lastName, email, phoneNumber, errors } = ref;
    const { password, confirmPassword, errors } = ref;

    let error = false;

    // if(firstName.length === 0){
    //     errors.firstName = `Please enter your first name`;
    //     error = true;
    // }
    // else {
    //     errors.firstName = ``;
    // }

    // if(lastName.length === 0){
    //     errors.lastName = `Please enter your last name`
    //     error = true;
    // }
    // else {
    //     errors.lastName = ``
    // }

    // if(email.length === 0){
    //     errors.email = `Please enter your email`;
    //     error = true;
    // }
    // else {
    //     errors.email = ``;
    // }

    // if(phoneNumber.length === 0){
    //     errors.phoneNumber = `Please enter your phone number`;
    //     error = true;
    // }
    // else {
    //     errors.phoneNumber = ``;
    // }

    if (password.length === 0) {
        errors.password = `Please enter a password`;
        error = true;
    } else {
        errors.password = ``;
    }

    if (confirmPassword.length === 0) {
        errors.confirmPassword = `Please enter confirm your password`;
        error = true;
    } else {
        errors.confirmPassword = ``;
    }

    if (password !== confirmPassword) {
        errors.password = `Passwords don't match`;
        errors.confirmPassword = `Passwords don't match`;
        error = true;
    }

    return error;
};
