import {
    TOGGLE_SIDEBAR,
    UPDATE_DEVICE_TYPE,
    UPDATE_HISTORY,
    UPDATE_IS_MOBILE_SIZE,
    UPDATE_IS_TABLET_PORTRAIT_SIZE,
    UPDATE_LOCATION,
    UPDATE_TOOL_BAR,
    UPDATE_WINDOW_HEIGHT,
    UPDATE_WINDOW_WIDTH,
    UPDATE_TOAST,
    UPDATE_CHANGES_MADE,
    UPDATE_NAV_PATH,
    UPDATE_ALERT_OPEN,
    UPDATE_LOADING,
    UPDATE_NAV_CALLBACK,
    GlobalActionTypes,
    TOGGLE_RIGHTPANEL,
    UPDATE_GLOBAL_SPINNER_STATUS,
    SET_IS_APP_TAB_IN_FOCUS,
    SET_INITIAL_LOADING_PROCESSING_STATUS,
    SET_FIRST_ACCESSIBLE_ROUTE
} from "../actions/global/types";
import { GlobalState, defaultGlobalState } from "../store";

export const globalReducer = (state: GlobalState = defaultGlobalState, action: GlobalActionTypes): GlobalState => {
    switch (action.type) {
        case UPDATE_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case TOGGLE_RIGHTPANEL: {
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        }
        case UPDATE_TOAST:
            return {
                ...state,
                toast: action.payload
            };
        case UPDATE_TOOL_BAR:
            return {
                ...state,
                topBarProps: action.payload
            };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                mobileSideBarActive: action.payload
            };
        case UPDATE_WINDOW_WIDTH:
            return {
                ...state,
                windowWidth: action.payload
            };
        case UPDATE_WINDOW_HEIGHT:
            return {
                ...state,
                windowHeight: action.payload
            };
        case UPDATE_DEVICE_TYPE:
            return {
                ...state,
                deviceType: action.payload
            };
        case UPDATE_IS_MOBILE_SIZE:
            return {
                ...state,
                isMobileSize: action.payload
            };
        case UPDATE_IS_TABLET_PORTRAIT_SIZE:
            return {
                ...state,
                isOrSmallerTabletPortraitSize: action.payload
            };
        case UPDATE_HISTORY:
            return {
                ...state,
                history: action.payload
            };
        case UPDATE_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case UPDATE_CHANGES_MADE:
            return {
                ...state,
                changesMade: action.payload
            };
        case UPDATE_ALERT_OPEN:
            return {
                ...state,
                alertOpen: action.payload
            };
        case UPDATE_NAV_PATH:
            return {
                ...state,
                navPath: action.payload
            };
        case UPDATE_NAV_CALLBACK:
            return {
                ...state,
                callBack: action.payload
            };
        case UPDATE_GLOBAL_SPINNER_STATUS:
            return {
                ...state,
                displayGlobalSpinner: action.payload
            };
        case SET_IS_APP_TAB_IN_FOCUS:
            return {
                ...state,
                isAppTabInFocus: action.payload
            };
        case SET_INITIAL_LOADING_PROCESSING_STATUS:
            return {
                ...state,
                initialLoadingProcessingStatus: action.value
            };
        case SET_FIRST_ACCESSIBLE_ROUTE:
            return {
                ...state,
                firstAccessibleRoute: action.value
            };
        default:
            return state;
    }
};
