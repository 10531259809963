import {
    ComponentType,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    SyntheticEvent,
    useState,
    FunctionComponent,
    SVGProps
} from "react";
import { Classes } from "@blueprintjs/core";
import { Popover2, Popover2Props, Popover2TargetProps } from "@blueprintjs/popover2";
import classNames from "classnames";

import { EmptyBlock, EmptyBlockProps, StyledButton, StyledButtonTypes } from "../../components";
import { DropdownTarget } from "./components/target";
import { DropdownSearchHeader } from "../../components/dropdown-search-header/dropdown-search-header";
import { denormalize, useDataFetch } from "../../hooks/useDataFetch";
import { MPDIcon } from "../icon";
import { MPDSelectItem } from "../select-item";
import { useSelectedExistingItemsHook } from "../../containers/contacts/hooks/useSelectedExistingItemsHook";
import useInfiniteScroll from "../../containers/conversations/components/chat/useInfiniteScroll";
import { ReactComponent as NoSearchResultsForIcon } from "src/mpd-library/icon/assets/NoSearchResultsFor.svg";
import { ReactComponent as PlusWhiteIcon } from "src/mpd-library/icon/assets/plus-white.svg";
import styles from "./dropdown.module.scss";
import { Option } from "./types";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { AxiosResponse } from "axios";
import { ICommonListsRequestsParams } from "src/actions";

// type ConditionalProps =
//     | {
//           staticOptions: Array<Option>;
//       }
//     | {
//           groupedOptions: { [key: string]: Array<Option> };
//           groupedOptionsTitle: { [key: string]: string };
//       };

export interface CustomOptionsComponentProps {
    onCheckBoxClick: any;
    closeDropdown: any;
    options: any;
    itemComponent: any;
    selectedItems: any;
    unselectedItems: any;
    contentBlockWidth: number;
}

export interface SelectedItemComponentProps {
    item: any;
    remove: (id: string) => void;
}

export interface ICreateNewEntityProps<CreateFunctionParams> {
    create: (params: CreateFunctionParams) => Promise<AxiosResponse<Required<{ id: string }>>>;
    entityName: string;
    onCreateNewEntityClick: (value: Option) => void;
}

interface DropdownProps extends Popover2Props {
    // commone props
    id: string;
    targetClassName: string;
    title?: string;
    error?: string;
    withSearch?: boolean;
    searchInputPlaceholder?: string;
    contentBlockWidth: number;
    contentBlockHeight: number;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    isOpenProp?: boolean;
    ArrowIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>> | boolean;
    leftLabel: string; // need to implment better approach (should be removed, used only in contacts sort dropdown)
    emptyBlockProps?: EmptyBlockProps;
    createNewEntityProps: TCreateNewEntityProps;
    onChange: (value: any, checked: boolean, option: Option) => void;
    onIntercationCustom: (value: boolean) => void;
    // useDataFetch stuff
    allOption?: Option;
    refreshList?: boolean;
    initialEntites: Array<Option>;
    fetchDataParams: ICommonListsRequestsParams;
    entityKey: string;
    fieldNameForId?: string;
    disableBackEndSearch?: boolean;
    initialValue: Array<Option>;
    fetchDataRequest?: (params: ICommonListsRequestsParams) => Promise<AxiosResponse<Array<Option>>>;
    // useSelectedExistingItemsHook
    values: Array<Option>;
    doClear?: boolean;
    diffDisabled?: boolean;
    // static options stuff
    selectedValue?: Option;
    staticOptions?: Array<Option>;
    onStaticOptionClick: (option: Option) => void;
    // footer stuff
    showClearButton: boolean;
    onApply?: () => void;
    // search stuff
    searcHeaderProps: boolean;
    // customization
    CustomTarget: ComponentType<Popover2TargetProps>;
    CustomOptionsComponent: ComponentType<CustomOptionsComponentProps>;
    SelectedItemComponent: ComponentType<SelectedItemComponentProps>;
}

export const Dropdown = ({
    // ---------- TARGET PROPS ---------------------
    title,
    CustomTarget,
    disabled,
    targetClassName,
    error,
    selectedValue,
    LeftIconComponent,
    withSearch,
    leftLabel, // find another solution. Better to remove,
    onDelete, // should be removed,
    ArrowIconComponent,
    SelectedItemComponent,
    searchInputPlaceholder,
    doClear,
    // useDataFetch props
    allOption,
    initialEntites,
    refreshList,
    fetchDataParams,
    fetchDataRequest,
    entityKey,
    fieldNameForId,
    disableBackEndSearch,
    //--------------------
    createNewEntityProps,
    emptyBlockProps,
    isOpenProp,
    staticOptions,
    CustomOptionsComponent,
    showClearButton,
    onApply,
    searcHeaderProps,
    contentBlockWidth,
    contentBlockHeight,
    onStaticOptionClick,
    initialValue,
    onChange,
    onIntercationCustom,
    id,
    diffDisabled,
    values,
    ...remainProps
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(typeof isOpenProp === "undefined" ? false : isOpenProp);
    const [createNewEntityProcessing, setCreateNewEntityProcessing] = useState<boolean>(false);
    const [staticOptionProcessingIndex, setStaticOptionProcessingIndex] = useState<number | undefined>(undefined);
    const [contentWasMountState, setWasContentMount] = useState<boolean>(false);
    const [seachInputFocused, setSeachInputFocused] = useState<boolean>(false);
    const [changesWasMade, setChangesWasMade] = useState<boolean>(false);

    // -------------------------- Dynamic data dropdowns stuff ---------------------------

    const {
        searchValue,
        entities: options,
        isListEmpty,
        hasMore,
        loading,
        initialised,
        setSearchValue,
        updateEntites,
        deleteEntity,
        setAtBottomOfList
    } = useDataFetch<Option>({
        allOption,
        initialEntites,
        refresh: refreshList,
        fetchDataParams,
        fetchDataRequest,
        canGetInitialData: contentWasMountState && !staticOptions && !initialEntites,
        entityKey,
        fieldNameForId,
        skeletonLoading: !staticOptions,
        disableBackEndSearch,
        listItemHeight: 40
    });

    const [bottomSentryRef] = useInfiniteScroll({
        disabled: !!staticOptions,
        loading,
        hasNextPage: hasMore,
        onLoadMore: () => setAtBottomOfList(true)
    });

    // -------------------------------------------------------------------------------------

    // --------------------- Multiselect dropdowns stuff (checkboxes) ----------------------

    const onCheckBoxClick = (option, checked) => {
        let updated;
        if (option.isSelectAllOption) {
            updated = onAllItemClick(option, checked);
        } else {
            updated = onSelectDeselect(option, checked);
        }

        onChange?.(denormalize(updated), checked, option);

        if (!!onApply) {
            setChangesWasMade(true);
        }
    };

    useEffect(() => {
        if (doClear) {
            clear();
        }
    }, [doClear]);

    if (entityKey === "tags") {
        console.log("777 >>>> values", values);
    }

    const { selectedItems, unselectedItems, onAllItemClick, onSelectDeselect, deselect, revert, clear } =
        useSelectedExistingItemsHook({
            hasOnApply: !!onApply,
            allItems: options,
            diffDisabled,
            values
        });

    const onApllyClick = async () => {
        try {
            const res = options.allIds.reduce(
                (acc, entityId) => {
                    if (
                        selectedItems.byId[entityId]?.checkedUpdated ||
                        unselectedItems.byId[entityId]?.checkedUpdated
                    ) {
                        acc.allIds.push(entityId);
                        acc.byId[entityId] = options.byId[entityId];
                    }
                    return acc;
                },
                { allIds: [], byId: {} }
            );
            await onApply?.(res);
            closeDropdown();
        } catch (err) {
            console.log("ERR >>>>", err);
        }
    };

    const onClearClick = () => {
        clear();
        !onApply && onChange?.([]);
        !!onApply && setChangesWasMade(true);
        // closeDropdown();
    };

    const removeSelectedItem = (option) => {
        const updatedValue = denormalize(deselect(option));
        onChange?.(updatedValue);
    };

    // ----------------------------------------------------------------------------------------

    // -------------------------------- Static options stuff ----------------------------------

    const filteredStaicOptions = useMemo(() => {
        if (staticOptions && searchValue) {
            return staticOptions?.filter((option) => {
                return option.label.toLowerCase().includes(searchValue.toLocaleLowerCase());
            });
        }

        return staticOptions;
    }, [staticOptions, searchValue]);

    const staticOptionClick = (option: Option) => {
        onStaticOptionClick?.(option);
        closeDropdown();
    };

    // ----------------------------------------------------------------------------------------

    // ----------------------------------- common stuff ---------------------------------------

    const closeDropdown = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (typeof isOpenProp !== "undefined") {
            setIsOpen(isOpenProp);
        }
    }, [isOpenProp]);

    useEffect(() => {
        if (!!isOpen && !contentWasMountState) {
            setWasContentMount(true);
        }
    }, [isOpen]);

    const contentBlockWidthHeight = useMemo(
        () => ({
            ...(contentBlockWidth ? { width: `${contentBlockWidth}px` } : {}),
            ...(contentBlockHeight ? { maxHeight: `${contentBlockHeight}px` } : {})
        }),
        [contentBlockWidth, contentBlockHeight]
    );

    const onInteration = (state: boolean, e?: SyntheticEvent<HTMLElement>) => {
        e?.stopPropagation?.();
        onIntercationCustom?.(state);
        setIsOpen(state);
        if (!state && !!onApply) {
            revert();
        }
    };

    useEffect(() => {
        if (seachInputFocused) {
            setIsOpen(true);
        }
    }, [seachInputFocused]);

    // ----------------------------------------------------------------------------------------

    const onCreateNewEntityClick = useCallback(async () => {
        try {
            const { create, entityName, onCreateNewEntityClick } = createNewEntityProps;
            setCreateNewEntityProcessing(true);
            const res = await create({ name: searchValue.trim() });
            const updated = onSelectDeselect({ ...res.data[entityName], checked: false, checkedUpdated: true }, true);
            onChange?.(denormalize(updated), true, res.data[entityName]);
            setChangesWasMade(true);
            setSearchValue("");
            onCreateNewEntityClick?.(res.data[entityName]);
        } finally {
            setCreateNewEntityProcessing(false);
        }
    }, [searchValue]);

    return (
        <div className={styles["row-gap-fixer"]}>
            <Popover2
                disabled={disabled}
                minimal={false}
                isOpen={isOpen}
                openOnTargetFocus
                transitionDuration={300}
                enforceFocus={false}
                autoFocus={false}
                onInteraction={onInteration}
                matchTargetWidth={targetClassName === DropdownTargetClasses.FORM_SELECT} // bad practice. Improve it
                content={
                    <div className="content-size-limiter" style={contentBlockWidthHeight}>
                        {searcHeaderProps && (
                            <div className={styles["content-header"]}>
                                <DropdownSearchHeader
                                    title={searcHeaderProps.title}
                                    searchValue={searchValue}
                                    onSearchChangeHandler={(e) => setSearchValue(e.target.value)}
                                    withoutSearch={searcHeaderProps.withoutSearch}
                                />
                            </div>
                        )}
                        {!loading && !!isListEmpty && initialised && !createNewEntityProps && (
                            <EmptyBlock
                                className={styles["empty"]}
                                title={`No results for ${searchValue}`}
                                subtitle={"Please try another query"}
                                IconComponent={NoSearchResultsForIcon}
                                type={"dropdown"}
                            />
                        )}

                        {createNewEntityProps && isListEmpty && (
                            <div className={styles["create-new-entity-header"]}>
                                <MPDSelectItem
                                    LeftIconComponent={() => (
                                        <div className={styles["plus-circle"]}>
                                            <MPDIcon IconComponent={PlusWhiteIcon} />
                                        </div>
                                    )}
                                    // option={{ label: "Create New List" }}
                                    label={`Create ${createNewEntityProps.entityName} for '${searchValue}'`}
                                    onClick={onCreateNewEntityClick}
                                    processing={createNewEntityProcessing}
                                />
                            </div>
                        )}
                        {fetchDataRequest && isListEmpty && !!emptyBlockProps && (
                            <EmptyBlock {...emptyBlockProps} type={"dropdown"} />
                        )}
                        {!isListEmpty && (
                            <>
                                {!!filteredStaicOptions &&
                                    filteredStaicOptions.map((option: Option, index) => {
                                        const { label, LeftIconComponent, children, disabled, onClick } = option || {};
                                        return (
                                            <MPDSelectItem
                                                type={option?.type}
                                                disabled={disabled}
                                                key={index}
                                                LeftIconComponent={LeftIconComponent}
                                                label={label}
                                                onClick={async () => {
                                                    if (onClick) {
                                                        try {
                                                            setStaticOptionProcessingIndex(index);
                                                            await onClick();
                                                        } finally {
                                                            setStaticOptionProcessingIndex(undefined);
                                                        }
                                                    }
                                                    staticOptionClick(option);
                                                }}
                                                processing={index === staticOptionProcessingIndex}
                                                selected={selectedValue?.value === option.value}
                                            >
                                                {children}
                                            </MPDSelectItem>
                                        );
                                    })}

                                {!CustomOptionsComponent &&
                                    options.allIds.map((optionId: string, index) => {
                                        const option: Option = options.byId[optionId];
                                        const { label, name, LeftIconComponent, disabled } = option || {};
                                        console.log("RENDER <<<< laoding", optionId);
                                        return (
                                            <MPDSelectItem
                                                type={option?.type}
                                                key={index}
                                                disabled={disabled}
                                                LeftIconComponent={LeftIconComponent}
                                                label={label || name}
                                                onClick={() => {
                                                    staticOptionClick(option);
                                                }}
                                                className={optionId === "loading" ? Classes.SKELETON : ""}
                                            >
                                                {/* {children} */}
                                            </MPDSelectItem>
                                        );
                                    })}

                                {CustomOptionsComponent && (
                                    <CustomOptionsComponent
                                        contentBlockWidth={contentBlockWidth}
                                        onCheckBoxClick={onCheckBoxClick}
                                        closeDropdown={closeDropdown}
                                        options={options}
                                        unselectedItems={unselectedItems}
                                        selectedItems={selectedItems}
                                        updateEntites={updateEntites}
                                        deleteEntity={deleteEntity}
                                        initialised={initialised}
                                        searchValue={searchValue}
                                        hasHeaderSearch={!!searcHeaderProps}
                                    />
                                )}
                                <div ref={bottomSentryRef} />
                            </>
                        )}

                        {(onApply || showClearButton) && (
                            <div className={styles["content-footer"]}>
                                {showClearButton && (
                                    <StyledButton
                                        text={"Clear"}
                                        type={StyledButtonTypes.primarySimple}
                                        onClick={onClearClick}
                                    />
                                )}
                                {onApply && (
                                    <StyledButton
                                        text={"Apply"}
                                        type={StyledButtonTypes.primary}
                                        onClick={onApllyClick}
                                        disabled={!changesWasMade}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                }
                renderTarget={(targetProps) => (
                    <DropdownTarget
                        {...targetProps}
                        onDelete={onDelete}
                        targetClassName={targetClassName}
                        selectedValue={selectedValue}
                        removeSelectedItem={removeSelectedItem}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        // -------------------------------
                        leftLabel={leftLabel}
                        withSearch={withSearch}
                        error={error}
                        disabled={disabled}
                        CustomTarget={CustomTarget}
                        setSeachInputFocused={setSeachInputFocused}
                        selectedItems={selectedItems}
                        LeftIconComponent={LeftIconComponent}
                        ArrowIconComponent={ArrowIconComponent}
                        title={title}
                        searchInputPlaceholder={searchInputPlaceholder}
                        SelectedItemComponent={SelectedItemComponent}
                    />
                )}
                hasBackdrop={false}
                {...remainProps}
                portalClassName={classNames(styles["portal"], remainProps.portalClassName)}
            />
        </div>
    );
};
