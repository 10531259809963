// tslint:disable jsx-no-lambda
import classNames from "classnames";
import moment from "moment";
import React, { SyntheticEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Route, Switch } from "react-router-dom";
import { History } from "history";
import { globalActions, axiosInstance } from "src/actions";
import { BreadCrumbs, IBreadCrumbObj, IToast, ToastTypes } from "src/components";
import { MPDIcon, MPDScroll } from "src/mpd-library";
import { AppState, TopBarProps, AccountSettingsGeneralChannels, Channels, ArrayChannel } from "src/store";
import { IDetailView } from "../../../../../";
import { SettingsSocialBanter } from "../../components";
import SocialSettingsEdit from "./social-settings-edit";
import "./styles.scss";
import { getConfig } from "src/common";
import { bindActionCreators, Dispatch } from "redux";
import { onUpdateToast } from "src/actions/global/global";
import { ShadowedListItem } from "src/components/shadowed-settings-list-item/shadowed-settings-list-item";
import {
    disconnectChannel,
    onGetChannelsArray,
    onSaveChannelsChanges,
    onUpdateSettingsArrayChannels,
    onUpdateSettingsChannels
} from "src/actions/account-settings/account-settings-api";
import { updateChannel } from "src/actions/account-settings/account-settings";
import { RoundedIcon } from "src/components/rounded-icon/rounded-icon";
import { StyledButton } from "src/components/styled-button/styled-button";
import { StyledButtonTypes } from "src/components";
import { DotStatus } from "src/components/dot-status/dot-status";
import { SOCIAL_NETWORKS_ITEMS_CONFIG } from "../../constants";
import { Text } from "@blueprintjs/core";
import { SmsVoiceFlow } from "../sms-voice-flow/sms-voice-flow";
import { updateSmsVoiceChannel } from "src/requests/channelsRequests";
import { StyledRadioGroupTypes } from "../../../../../../components";
import { CreateSmsVoiceChannelModal } from "../sms-voice-flow/components/sms-voice-channel-create/sms-voice-channel-create";
import { ReactComponent as UsaFlagIcon } from "../../../../../../mpd-library/icon/assets/usa-flag.svg";

const accountTypesWithSimpleEditPage = {
    facebook: true,
    twitter: true,
    instagram: true
};

interface ISocialSettingsProps extends IDetailView {
    accountType: string;
    routeProps: RouteComponentProps;
    channels: AccountSettingsGeneralChannels;
    previousRoute?: IBreadCrumbObj;
    channelsArray: Channels;
    onDisconnectChannel: (id: string) => void;
    onUpdateToolBar: (topBarProps: TopBarProps) => void;
    onUpdateSettingsChannels: (channels: AccountSettingsGeneralChannels) => void;
    onUpdateSettingsArrayChannels: (channelsArray: Channels) => void;
    onSaveChannelsChanges: (
        channels: Channels,
        pathname: string,
        history: History,
        index: number,
        accountType: string
    ) => void;
    getChannelsArray: () => void;
    onCloseRightPanel?(): void;
    onToggleRightPanel?(): void;
    onUpdateToast: (toast: IToast) => void;
    onInstallClick: (event: SyntheticEvent, socialNetworkName: string) => void;
    updateChannel: (channel: ArrayChannel) => void;
}

interface ISocialSettingsState {
    currentSocialSetting: any;
    hoveredChannel: string | null;
    createSmsVoiceChannelModalOpen: boolean;
}

class SocialSettings extends React.Component<ISocialSettingsProps, ISocialSettingsState> {
    componentPathname: string;
    constructor(props: ISocialSettingsProps) {
        super(props);
        this.onAddAccount = this.onAddAccount.bind(this);
        this.onAccountNameChange = this.onAccountNameChange.bind(this);
        this.onAccountStatusChange = this.onAccountStatusChange.bind(this);
        this.onRenderSocialSettings = this.onRenderSocialSettings.bind(this);
        this.onAccountNameChange = this.onAccountNameChange.bind(this);
        this.updateToolBar();
        this.componentPathname = `/account-settings/channels/${this.props.accountType}`;
        this.state = {
            currentSocialSetting: null,
            hoveredChannel: null,
            createSmsVoiceChannelModalOpen: false
        };
    }

    public componentDidMount() {
        this.props.getChannelsArray();
    }

    public componentDidUpdate(prevProps: ISocialSettingsProps) {
        const { routeProps } = this.props;
        const rightPathname = routeProps.location.pathname === this.componentPathname;
        if (prevProps.accountType !== this.props.accountType && rightPathname) {
            this.updateToolBar();
        }
        if (prevProps.routeProps !== routeProps && rightPathname) {
            this.updateToolBar();
        }
    }

    public onHoverChannel = (id: string) => {
        this.setState({ hoveredChannel: id });
    };

    public onMouseLeaveChannel = () => {
        this.setState({ hoveredChannel: null });
    };

    public render() {
        const { accountType, previousRoute, routeProps } = this.props;
        const { currentSocialSetting } = this.state;
        return (
            <>
                <Switch location={routeProps.history.location}>
                    <Route
                        exact={true}
                        path={`/account-settings/channels/${accountType}`}
                        render={() => this.onRenderSocialSettings()}
                    />
                    {accountTypesWithSimpleEditPage[accountType] && (
                        <Route
                            path={`/account-settings/channels/${accountType}/edit`}
                            render={() => (
                                <React.Fragment>
                                    <SocialSettingsEdit
                                        previousRoute={previousRoute}
                                        currentSocialSetting={currentSocialSetting}
                                        accountType={accountType}
                                        routeProps={routeProps}
                                        onCloseRightPanel={this.props.onCloseRightPanel}
                                        onToggleRightPanel={this.props.onToggleRightPanel}
                                        onUpdateChangesMade={this.props.onUpdateChangesMade}
                                        onInstallClick={this.props.onInstallClick}
                                    />
                                </React.Fragment>
                            )}
                        />
                    )}

                    <SmsVoiceFlow />
                </Switch>
            </>
        );
    }

    public onReauthClick = async (e, currentSocialSettingName) => {
        this.props.onInstallClick(e, currentSocialSettingName);
    };

    public renderCustomChannelRightElement = (account) => {
        const { hoveredChannel } = this.state;
        const { accountType, authChannelProcessing } = this.props;
        if (account.id === hoveredChannel && account.status === "offline") {
            return (
                <StyledButton
                    text={"Re-Authorize"}
                    type={StyledButtonTypes.deleteSimple}
                    onClick={(e) => {
                        this.onReauthClick(e, accountType);
                    }}
                    processing={authChannelProcessing}
                />
            );
        } else {
            return (
                <DotStatus
                    status={account.status}
                    label={account.status === "offline" ? "Needs Reauthorization" : account.status}
                />
            );
        }
    };

    public renderSmsChannelRightElement = () => {
        return (
            <div className={"flag-state-wrapper"}>
                <MPDIcon IconComponent={UsaFlagIcon} className={"country-flag"} />
                <Text className={"state-name"}>Austin, TX</Text>
            </div>
        );
    };

    public onSmsSwitchToggle = async (checked, channel: ArrayChannel) => {
        const res = await updateSmsVoiceChannel(channel.id, {
            status: checked ? "online" : "offline"
        });
        this.props.updateChannel(res.data.channel);
        this.props.onUpdateToast({
            type: ToastTypes.saved,
            value: checked ? "Line was successfully activated" : "Line was successfully disabled"
        });
    };

    public onRenderSocialSettings() {
        const { className, accountType, channelsArray } = this.props;
        const social = channelsArray[accountType];
        return (
            <div className={classNames("social-settings-container", className)}>
                <div>
                    <SettingsSocialBanter
                        accountType={accountType}
                        title={SOCIAL_NETWORKS_ITEMS_CONFIG[accountType].title}
                        active={!!social}
                        subtitle={SOCIAL_NETWORKS_ITEMS_CONFIG[accountType].subtitle}
                        customRightElement={
                            this.props.routeProps.location.pathname.includes("sms_voice") ? (
                                <CreateSmsVoiceChannelModal />
                            ) : (
                                <StyledButton
                                    type={StyledRadioGroupTypes.primary}
                                    text={"Add New"}
                                    onClick={this.onAddNewChannel}
                                />
                            )
                        }
                        // rightButtonProps={{
                        //     onClick: this.onAddNewChannel,
                        //     text: "Add New",
                        //     type: StyledButtonTypes.primary
                        // }}
                        roundedIconProps={SOCIAL_NETWORKS_ITEMS_CONFIG[accountType]?.customLeftIconProps}
                        roundedIconSize={60}
                    />
                </div>
                <MPDScroll yDirection={true} className="social-settings-accounts-wrapper">
                    {!!social?.length &&
                        social.map((account: any, index: number) => {
                            if (accountType === "sms_voice") {
                                const splited = account.name?.split(" ");
                                const initilas = `${splited?.[0]?.charAt(0)}${splited?.[1]?.charAt(0) || ""}`;
                                return (
                                    <ShadowedListItem
                                        key={account.id}
                                        title={account.name || account.page_name}
                                        subtitle={account.page_name || account.phone_number_formatted}
                                        customLeftIconComponent={<div className={"line-circle"}>{initilas}</div>}
                                        customRightElement={
                                            <div className={"flag-state-wrapper"}>
                                                <img
                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${account.phone_number_country}.svg`}
                                                    className={"country-flag"}
                                                />
                                                <Text className={"state-name"}>{account.phone_number_region}</Text>
                                            </div>
                                        }
                                        isSwitchChecked={account.status === "online"}
                                        onToggleSwitch={(checked: boolean) => this.onSmsSwitchToggle(checked, account)}
                                        onClick={() =>
                                            this.props.routeProps.history.push(
                                                `/account-settings/channels/sms_voice/edit/${account.id}`
                                            )
                                        }
                                    />
                                );
                            }
                            return (
                                <div
                                    onMouseEnter={() => this.onHoverChannel(account.id)}
                                    onMouseLeave={this.onMouseLeaveChannel}
                                >
                                    <ShadowedListItem
                                        key={account.id}
                                        onClick={() => this.onUpdateCurrentSocialSetting(index)}
                                        title={account.name || account.page_name}
                                        subtitle={account.name || account.page_name}
                                        customLeftIconComponent={<RoundedIcon size={48} src={account.profile_pic} />}
                                        customRightElement={this.renderCustomChannelRightElement(account)}
                                    />
                                </div>
                            );
                        })}
                </MPDScroll>
            </div>
        );
    }

    private onUpdateCurrentSocialSetting = (index: number) => {
        const { accountType, channelsArray, routeProps } = this.props;
        const currentSocialSetting = channelsArray[accountType][index];
        const accountId = currentSocialSetting.id;
        this.setState({ currentSocialSetting }, () => {
            const pathname = "/account-settings/channels/" + accountType.toLowerCase() + `/edit/${accountId}`;
            routeProps.history.push(pathname);
        });
    };

    private onAccountStatusChange(event: any, index: number) {
        const { accountType, channelsArray, routeProps, onSaveChannelsChanges, onDisconnectChannel } = this.props;
        const status = event.currentTarget.textContent;
        const accounts = channelsArray;
        const accountId = accounts[accountType][index].id;
        if (status.includes("Edit")) {
            this.setState({ currentSocialSetting: accounts[index] }, () => {
                const pathname = "/account-settings/channels/" + accountType.toLowerCase() + `/edit/${accountId}`;
                routeProps.history.push(pathname);
            });
            return;
        } else if (status.includes("Deactivate") || status.includes("Activate")) {
            accounts[accountType][index].active = !!status.includes("Activate");
            onSaveChannelsChanges(accounts, routeProps.location.pathname, routeProps.history, index, accountType);
        } else if (status.includes("Delete")) {
            onDisconnectChannel(accounts[index].id);
            accounts[accountType].splice(index, 1);
        }
        this.props.onUpdateSettingsArrayChannels(channelsArray);
    }

    private onAccountNameChange(event: any, index: number) {
        const { accountType, channels } = this.props;
        const name = event.currentTarget.value;
        channels[accountType].list[index].name = name;
        this.props.onUpdateSettingsChannels(channels);
    }

    private onAddNewChannel = async () => {
        try {
            const { accountType, routeProps } = this.props;
            if (accountType === "sms_voice") {
                this.setState({ createSmsVoiceChannelModalOpen: true });
                return;
            }
            const config = getConfig();
            const result = await axiosInstance.get(`${config.API_URL}/channels/${accountType}/authenticate_url`);
            window.location.href = result.data.redirect_url;
        } catch {
            this.props.onUpdateToast({ value: "Something get wrong. Try again a bit later", type: "error" });
        }
    };

    private onAddAccount() {
        const { accountType, channels } = this.props;
        const date = moment(new Date()).format("L");
        const accountLength = channels[accountType].list.length + 1;
        const id = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();
        const account = {
            date,
            id,
            name: accountType === "twitter" ? "@account_" + accountLength : "account_" + accountLength,
            preSelected: true,
            trackLikes: true,
            active: true
        };
        channels[accountType].list.push(account);
        this.props.onUpdateSettingsChannels(channels);
    }

    private updateToolBar = () => {
        const { routeProps, accountType, previousRoute } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={
                        previousRoute
                            ? [
                                  previousRoute,
                                  {
                                      //   text: accountType.charAt(0).toUpperCase() + accountType.slice(1)
                                      text: SOCIAL_NETWORKS_ITEMS_CONFIG[accountType].title
                                  }
                              ]
                            : [
                                  {
                                      text: SOCIAL_NETWORKS_ITEMS_CONFIG[accountType].title
                                  }
                              ]
                    }
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    };
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const { channels, channelsArray } = accountSettings.general;
    return {
        channels,
        channelsArray: {
            ...channelsArray
        }
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            getChannelsArray: onGetChannelsArray,
            onUpdateSettingsChannels,
            onUpdateSettingsArrayChannels,
            onDisconnectChannel: disconnectChannel,
            onSaveChannelsChanges,
            onUpdateToast,
            updateChannel
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SocialSettings);
