import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { DateInput, IDateInputProps } from "@blueprintjs/datetime";

import { IMPDButtonProps, MPDIcon } from "src/mpd-library";

import { StyledDateInputType } from "./types";

import "./styles.scss";
import { Position } from "@blueprintjs/core";

export interface IStyledDateInputProps extends IDateInputProps {
    type?: StyledDateInputType;
    usePortal?: boolean;
    portalClassName?: string;
    RightIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const defaultPopoverProps = {
    position: Position.BOTTOM,
    portalClassName: "end-date-customize-calendar"
};

export const StyledDateInput: React.FunctionComponent<IStyledDateInputProps> = (props) => {
    const { className, type, usePortal, portalClassName, RightIconComponent, LeftIconComponent, ...dateInputProps } =
        props;

    const { popoverProps } = dateInputProps;
    const { ...remainingPopOverProps } = popoverProps || defaultPopoverProps;

    const classes = "styled-dateinput";

    return (
        <div className={classNames(classes + "-wrapper", type || StyledDateInputType.primary, className)}>
            {LeftIconComponent && <MPDIcon className={classes + "-left-icon"} IconComponent={LeftIconComponent} />}
            <DateInput
                {...dateInputProps}
                className={classNames(classes, LeftIconComponent && "padding-left")}
                popoverProps={{
                    ...remainingPopOverProps,
                    popoverClassName: classNames(classes + "-popover", type || StyledDateInputType.primary),
                    usePortal,
                    portalClassName
                }}
            />
            {RightIconComponent && <MPDIcon className={classes + "-right-icon"} IconComponent={RightIconComponent} />}
        </div>
    );
};
