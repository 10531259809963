import { ConversationTag } from "./conversations";


export type BusinessHours = {
    day: "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
    is_opened: boolean;
    opened_from: string;
    opened_till: string;
};

export type ConversationsSettings = {
    auto_response: string;
    greeting_message?: string;
    profile_bio: string;
    business_hours?: Array<BusinessHours>;
    follow_up_message?: string;
    custom_away_message: string;
    greeting_tags: Array<ConversationTag>;
};

export type ProfileAutomationsSettingsState = {
    conversations_settings: ConversationsSettings;
}

export const defaultProfileAutomationsSettingsState = (): ProfileAutomationsSettingsState => ({
    conversations_settings: {
        auto_response: "",
        greeting_message: "",
        profile_bio: "",
        greeting_tags: [],
        custom_away_message: '',
        follow_up_message: ''
    }
});
