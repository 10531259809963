export const DEFAULT_FILTER =
    "eyJjb25kaXRpb25zIjpbeyJjb25kaXRpb25zIjpbXSwib3BlcmF0b3IiOiJhbmQifV0sIm9wZXJhdG9yIjoiYW5kIn0=";

export const LIST_ITEM_FIELDS_DAFAULT = {
    allIds: ["name", "channels", "last_contacted_at", "tags", "lists"],
    byId: {
        name: {
            field: "name",
            enabled: true
        },
        channels: {
            field: "channels",
            enabled: true
        },
        last_contacted_at: {
            field: "last_contacted_at",
            enabled: true
        },
        tags: {
            field: "tags",
            enabled: true
        },
        lists: {
            field: "lists",
            enabled: true
        }
    }
};

export const SELECTED_CONTACTS_OPTIONS = {
    add_to_list: true,
    tags: true,
    more: true
};
