import moment from "moment";

export const PERIODS = [
    {
        key: "todayPeriod",
        name: "Today",
        value: {
            from: moment().startOf("day"),
            to: moment().endOf("day")
        }
    },
    {
        key: "yesterdayPeriod",
        name: "Yesterday",
        value: {
            from: moment().subtract(1, "day").startOf("day"),
            to: moment().subtract(1, "day").endOf("day")
        }
    },
    {
        key: "thisWeekPeriod",
        name: "This Week",
        value: {
            from: moment().startOf("week"),
            to: moment().endOf("week")
        }
    },
    {
        key: "lastWeekPeriod",
        name: "Last Week",
        value: {
            from: moment().subtract(1, "week").startOf("week"),
            to: moment().subtract(1, "week").endOf("week")
        }
    },
    {
        key: "thisMonthPeriod",
        name: "This Month",
        value: {
            from: moment().startOf("month"),
            to: moment().endOf("month")
        }
    },
    {
        key: "lastMonthPeriod",
        name: "Last Month",
        value: {
            from: moment().subtract(1, "month").startOf("month"),
            to: moment().subtract(1, "month").endOf("month")
        }
    },
    {
        key: "customPeriod",
        name: "Custom Dates",
        value: "custom"
    }
];
