import { AccountPrivilege } from "src/requests";

export interface UserSettingsNotification {
    id: string;
    title: string;
    desktop: boolean;
    mobile: boolean;
    email: boolean;
}

export interface UserSettingsActivity {
    actor: {
        first_name: string;
        id: string;
        last_name: string;
        type: string;
    };
    geo_ip?: {
        city: string;
        country_code: string;
        country_name: string;
        ip: string;
        latitude: number;
        longitude: number;
        region_code: string;
        region_name: string;
    };
    id: string;
    published: Date;
    summary: string;
    type: string;
    user_agent: string;
}

export interface UserSettingsMyInfo {
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
    status?: boolean;
    logo?: {
        src: string | ArrayBuffer | null;
        url?: string;
        type?: string;
        updatedSrc?: string;
        file?: any;
    };
    uuid: string;
}

export interface UserSettingsPasswordState {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    errors: any;
}

export interface UserSettingsNotificationState {
    notificationList: Array<UserSettingsNotification>;
    playSound: boolean;
    dailyEmail: boolean;
}

export interface UserSettingsScheduleState {
    workWeek: Array<string>;
    hours: {
        startTime: Date | null;
        endTime: Date | null;
    };
    vacationIndicator: {
        status: boolean;
        date: Date | null;
    };
    awayReassignment: string | null;
}

export type OTPAlertTypes = "password" | "email" | "phone";

export interface UserSettingsEmailPassOTPState {
    otpAlertOpen: boolean;
    otpAlertType: OTPAlertTypes;
    otpToken?: string;
}

export interface UserSettingsActivityState {
    list?: Array<UserSettingsActivity>;
    loading?: boolean;
}

export interface UserSettingsState {
    rightPanelExpanded: boolean;
    privileges: Array<AccountPrivilege>;
    loading: boolean;
    emailPassOTP: UserSettingsEmailPassOTPState;
    emailPassChangeError?: string;
    savedEmailIntermediateValue?: string;
    myInfo: UserSettingsMyInfo;
    password: UserSettingsPasswordState;
    schedule: UserSettingsScheduleState;
    notifications?: UserSettingsNotificationState;
    activity: UserSettingsActivityState;
}

export const defaultUserSettingsState: UserSettingsState = {
    rightPanelExpanded: false,
    emailPassOTP: {
        otpAlertOpen: false,
        otpAlertType: "password"
    },
    myInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        status: false,
        logo: {
            src: "",
            url: "",
            type: "",
            updatedSrc: ""
        }
    },
    privileges: [],
    loading: false,
    password: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        errors: {}
    },
    // notifications: {
    //   notificationList: [
    //     {
    //       id: 'reportRecieved',
    //       title: 'When a new report is recieved',
    //       desktop: false,
    //       mobile: false,
    //       email: false
    //     },
    //     {
    //       id: 'reportAssignedYou',
    //       title: 'When a new report is assigned to you',
    //       desktop: false,
    //       mobile: false,
    //       email: false
    //     },
    //     {
    //       id: 'reportResponse',
    //       title: 'When someone responds to an alert you sent',
    //       desktop: false,
    //       mobile: false,
    //       email: false
    //     },
    //     {
    //       id: 'teammateMention',
    //       title: 'When a teammate mentions you in a note',
    //       desktop: false,
    //       mobile: false,
    //       email: false
    //     },
    //     {
    //       id: 'reportAssignedTeam',
    //       title: 'When a new report is assigned to your team',
    //       desktop: false,
    //       mobile: false,
    //       email: false
    //     },
    //     {
    //       id: 'ActivityAssigned',
    //       title: 'Activity in anything assigned to you',
    //       desktop: false,
    //       mobile: false,
    //       email: false
    //     }
    //   ],
    //   playSound: false,
    //   dailyEmail: false,
    // },
    schedule: {
        workWeek: [],
        hours: {
            startTime: null,
            endTime: null
        },
        vacationIndicator: {
            status: false,
            date: null
        },
        awayReassignment: null
    },
    activity: {}
};
