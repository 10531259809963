import { FormikValues, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { createSelector } from "reselect";
import { updateChannel } from "src/actions/account-settings/account-settings";
import { disconnectChannel, onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToast, onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import { BreadCrumbs, StyledButton, StyledButtonTypes } from "src/components";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { useQueues } from "src/containers/conversations/components/chat/hooks/useQueues";
import { updateSmsVoiceChannel } from "src/requests/channelsRequests";
import { AppState } from "src/store";
import { FormWasChanged } from "../../../../../../../../components/form-was-changed/FormWasChanged";
import { SettingsSocialBanter } from "../../../../components";
import { FORMIK_LINE_VALIDATION_OBJECT_ALL } from "../../constants";
import { Customize } from "../customize/customize";
import { SmsVoiceChannelCreateOverview } from "../sms-voice-channel-create-overview/sms-voice-channel-create-overview";
import styles from "./styles.module.scss";
import { StyledAlert, StyledAlertTypes } from "../../../../../../../../components";

const classname = "sms-voice-channel-edit";

const smsChannelSelector = createSelector(
    (state: AppState) => state.accountSettings.general.channelsArray,
    (_, channelId: string) => channelId,
    (channelsArray: any, channelId: string) => {
        return channelsArray.sms_voice?.find((channel) => channel?.id === channelId);
    }
);

const roundedIconProps = {
    backgroundColor: "#E1286B"
};

export const SmsVoiceChannelEdit = () => {
    const { defaultQueue } = useSelector((state: AppState) => state.accountSettings.organization);
    const [saveChangesProcessing, setSaveChangesProcessing] = useState<boolean>(false);
    const [disconnectChannelProcessing, setDisconnectChannelProcessing] = useState<boolean>(false);
    const getChannelsProcessingStatus = useSelector(
        (state: AppState) => state.accountSettings.getChannelsProcessingStatus
    );
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const channel = useSelector((state: AppState) => smsChannelSelector(state, params.id));
    const smsVoiceChannels = useSelector((state: AppState) => state.accountSettings.general.channelsArray.sms_voice);

    const formik: FormikValues = useFormik<FormikValues>({
        enableReinitialize: true,
        initialValues: {
            lineName: channel?.name,
            description: channel?.description,
            phone_number: channel?.phone_number,
            enableForConversations: channel?.conversations_enabled,
            queue: channel?.conversations_settings?.queue || defaultQueue,
            admin: channel?.conversations_settings?.admin,
            tags: channel?.conversations_settings?.tags,
            user_identity_hidden: channel?.user_identity_hidden
        },
        validationSchema: FORMIK_LINE_VALIDATION_OBJECT_ALL,
        onSubmit: async (values) => {
            try {
                setSaveChangesProcessing(true);
                const res = await updateSmsVoiceChannel(channel.id, {
                    name: values.lineName,
                    description: values.description,
                    phone_number: values.phone_number,
                    conversations_enabled: true,
                    user_identity_hidden: values.user_identity_hidden,
                    conversations_settings: {
                        queue_id: values.queue.id,
                        tag_ids: values.tags?.map((tag) => tag.id),
                        admin_id: values.admin?.id
                    }
                });
                dispatch(updateChannel(res.data.channel));
                dispatch(onUpdateToast({ type: "saved", value: "Channel was successfully updated" }));
                // history.push("/account-settings/channels/sms_voice");
            } catch (err) {
                console.log("ERR >>>>", err, values);
                dispatch(onUpdateToast({ type: "error", value: "Something get wrong. Try again a bit later" }));
            } finally {
                setSaveChangesProcessing(false);
            }
        }
    });

    const onClickDisconnect = async () => {
        try {
            setDisconnectChannelProcessing(true);
            await dispatch(disconnectChannel(channel.id, "sms_voice"));
            if (smsVoiceChannels.length === 1) {
                history.push("/account-settings/channels");
            } else {
                history.push("/account-settings/channels/sms_voice");
            }
        } finally {
            setDisconnectChannelProcessing(false);
        }
    };

    useMemo(() => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Channels", pathname: `/account-settings/channels` },
                        { text: "SMS/Voice", pathname: "/account-settings/channels/sms_voice" },
                        { text: channel?.name }
                    ]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            ),
            rightElement: (
                <StyledAlert
                    Target={(props) => (
                        <StyledButton {...props} type={StyledButtonTypes.delete} minimal={true} text={"Delete"} />
                    )}
                    type={StyledAlertTypes.warning}
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={false}
                    confirmButtonProps={{
                        type: StyledButtonTypes.delete
                    }}
                    confirmButtonText={"Delete"}
                    cancelButtonText={"Cancel"}
                    onConfirm={onClickDisconnect}
                    fullScreen
                    globalContentProps={{
                        title: "Delete Channel",
                        description: `Are you sure you want to delete your channel "${channel?.name}"?`
                    }}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    }, [channel?.id, history, saveChangesProcessing, disconnectChannelProcessing, smsVoiceChannels?.length]);

    if (getChannelsProcessingStatus || !channel) {
        // return <Redirect to="/account-settings/channels/sms_voice" />;
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[classname]}>
            <SettingsSocialBanter
                title={channel?.name}
                subtitle={channel?.description}
                roundedIconSize={60}
                roundedIconProps={roundedIconProps}
                entityName={channel?.name}
            />
            <div className={styles[`${classname}-form`]}>
                <div className={styles["form-part"]}>
                    <h4 className={styles[`${classname}-devider-title`]}>GENERAL SETTINGS</h4>
                    <SmsVoiceChannelCreateOverview formik={formik} showNumber />
                </div>
                <div className={styles["form-part"]}>
                    <h4 className={styles[`${classname}-devider-title`]}>ENABLE FOR CONVERSATIONS</h4>
                    <Customize formik={formik} />
                </div>
            </div>

            <FormWasChanged formik={formik} />
        </div>
    );
};
