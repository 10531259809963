import { Classes, Text } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./ContactActivityListItem.module.scss";
import classNames from "classnames";
import { useMemo } from "react";
import { ReactComponent as CreatedIcon } from "./assets/Created.svg";
import { ReactComponent as UpdateIcon } from "./assets/Updated.svg";

moment.defineLocale("en-cust1", {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        ss: "%d secs",
        m: "a min ago",
        mm: "%d mins ago",
        h: "an hour",
        hh: "%dh ago",
        d: "a day ago",
        dd: "%d days ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
    }
});

const getSubtitle = (activity) => {
    switch (activity?.action) {
        case "import":
            return {
                subtitle: (
                    <span>
                        <span className={styles["highlighted"]}>
                            {activity.object.first_name + " " + activity.object.last_name + " "}
                        </span>
                        was imported by{" "}
                        <span
                            // to={`/account-settings/users/view/details/id-${activity.actor.id}`}
                            className={styles["highlighted"]}
                        >{`${activity.actor_formatted}`}</span>
                    </span>
                ),
                Icon: <CreatedIcon />
            };
        case "create":
            return {
                subtitle: (
                    <span>
                        <span className={styles["highlighted"]}>
                            {activity.object.first_name + " " + activity.object.last_name + " "}
                        </span>
                        was created by{" "}
                        <span
                            // to={`/account-settings/users/view/details/id-${activity.actor.id}`}
                            className={styles["highlighted"]}
                        >{`${activity.actor_formatted}`}</span>
                    </span>
                ),
                Icon: <CreatedIcon />
            };
        case "update":
            return {
                subtitle: (
                    <span>
                        <span className={styles["highlighted"]}>
                            {activity.object.first_name + " " + activity.object.last_name + " "}
                        </span>
                        was updated by{" "}
                        <span
                            // to={`/account-settings/users/view/details/id-${activity.actor.id}`}
                            className={styles["highlighted"]}
                        >{`${activity.actor_formatted}`}</span>
                    </span>
                ),
                Icon: <UpdateIcon />
            };
        default:
            return {};
    }
};

export const ContactActivityListItem = ({ ...props }) => {
    const { itemId, item } = props;
    const loading = useMemo(() => {
        return itemId === "loading";
    }, [itemId]);

    const { subtitle, Icon } = useMemo(() => {
        return getSubtitle(item);
    }, [item]);

    return (
        <div {...props} className={styles["root"]}>
            <div className={classNames(styles["left-icon"], loading && Classes.SKELETON)}>{Icon}</div>
            <div className={styles["main-content"]}>
                <Text className={classNames(styles["title"], loading && Classes.SKELETON)}>{item?.title}</Text>
                <Text className={classNames(styles["subtitle"], loading && Classes.SKELETON)}>{subtitle}</Text>
            </div>
            <div className={classNames(styles["date"], loading && Classes.SKELETON)}>
                {moment(item?.inserted_at).locale("en-cust1").fromNow()}
            </div>
        </div>
    );
};
