import { useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import { StyledAlert, StyledTabs, StyledTabsTypes } from "../../../components";
import styles from "./ContactPage.module.scss";
import { Classes, MPDScroll } from "../../../mpd-library";
import { ContactOverview } from "../components/contact-overview/ContactOverview";
import classNames from "classnames";
import { ContactEdit } from "../components/contact-edit/ContactEdit";
import { FormWasChanged } from "src/components/form-was-changed/FormWasChanged";
import { axiosInstance } from "../../../actions";
import { useHistory, useParams } from "react-router";
import { PageLoadingSpinner } from "../../../components/page-loading-spinner/page-loading-spinner";
import { assignTags, assignToList, deleteContacts, updateContact } from "../../../requests/contactsRequests";
import { ContactActivity } from "../components/ContactActivity/ContactActivity";
import { TIMEZONES } from "../components/contact-edit/constants";
import { PHONE_REGEXP } from "src/common/constants.ts";
import { ContactsContext } from "../contacts-list/context";
import { useDispatch } from "react-redux";
import { onUpdateToast } from "../../../actions/global/global";

const availableTabs = ["overview", "activity"];

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;

export const ContactPage = ({
    contact: contactProp,
    setBreadCrumbsList,
    breadCrumbsList,
    confirmDeletingAlertProps
}) => {
    const { setDeletableContacts } = useContext(ContactsContext);
    const [tabId, setTabId] = useState<any>(0);
    const [contact, setContact] = useState<any>(contactProp);
    const [contactLoading, setContactLoading] = useState<boolean>(true);
    const { id: idFromUrl } = useParams<RouterProps>();
    const history = useHistory();
    const dispatch = useDispatch();

    const getContact = async () => {
        try {
            setContactLoading(true);
            const res = await axiosInstance.get(`/contacts/${idFromUrl}`);
            setContact({ ...contact, ...res.data.contact });
            const updatedBreadCrumbs = [...breadCrumbsList];
            updatedBreadCrumbs[1] = { text: `${res.data.contact?.first_name} ${res.data.contact?.last_name}` };
            setBreadCrumbsList(updatedBreadCrumbs);
        } finally {
            setContactLoading(false);
        }
    };

    useEffect(() => {
        getContact();
    }, []);

    const timezone = useMemo(() => {
        const res = TIMEZONES.find((timezone) => timezone.abbr === contact?.timezone);
        if (res) {
            return {
                label: res.value,
                value: res?.abbr
            };
        }
        return null;
    }, [contact]);

    const initialValues = {
        contact: {
            first_name: contact?.first_name,
            last_name: contact?.last_name,
            user_id: contact?.user_id,
            external_id: contact?.external_id,
            language: contact?.language
                ? {
                      label: `${contact?.language?.charAt(0)?.toUpperCase()}${contact?.language?.slice(1)}`,
                      value: contact?.language
                  }
                : {
                      label: "English",
                      value: "English"
                  },
            timezone: timezone || {
                label: "Eastern Standard Time",
                value: "EST"
            }
        },
        tags: contact?.tags?.length ? contact?.tags : null,
        channels: contact?.channels.map((channel) => {
            return {
                ...channel,
                value: channel.identifier
            };
        }),
        lists: contact?.lists?.length ? contact?.lists : null,
        notes: contact?.notes?.length ? contact?.notes : null,
        places: contact?.places?.length ? contact?.places : null,
        opt_in: { label: contact?.opt_in ? "Yes" : "No", value: contact?.opt_in }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: object({
            contact: object().shape({
                first_name: string().required("Can't be blank"),
                last_name: string().required("Can't be blank"),
                timezone: object().shape({
                    value: string(),
                    label: string()
                }),
                language: object()
                    .shape({
                        value: string(),
                        label: string()
                    })
                    .nullable()
            }),
            places: array()
                .nullable()
                .of(
                    object().shape({
                        type: string().required(),
                        address: object().shape({
                            city: string(),
                            street_address: string(),
                            state: string(),
                            zip_code: string(),
                            country: string()
                        })
                    })
                ),
            notes: array()
                .nullable()
                .of(
                    object().shape({
                        note: string()
                    })
                ),
            channels: array()
                .nullable()
                .of(
                    object().shape({
                        type: string(),
                        value: string().when("type", (type, schema) => {
                            if (type === "phone_number") {
                                return string()
                                    .required("Phone number is required")
                                    .matches(PHONE_REGEXP, "Phone number is not valid");
                            }
                            if (type === "email") {
                                // return string().email("Email is not valid").required("Phone number is required");
                                return string().required("Email is required").matches(emailRegex, "Email is not valid");
                                // return string().matches(emailRegex, "Email is not valid").required("Email is required");
                            }
                            if (type === "facebook" || type === "twitter" || type === "instagram") {
                                return string().required("Account name is required");
                            }
                        })
                    })
                )
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const res = await updateContact(idFromUrl, {
                    contact: {
                        ...values.contact,
                        language: values.contact.language.velue,
                        timezone: values.contact.timezone.value
                    },
                    channels: values.channels?.map((channel) => {
                        return {
                            type: channel.type,
                            identifier: channel.value,
                            ...(channel.type === "phone_number"
                                ? { phone_type: channel.phone_type, type: channel.type }
                                : {})
                        };
                    }),
                    places: values.places || [],
                    notes: values.notes?.map((note) => ({ note: note.note })) || [],
                    list_ids: values.lists?.map((list) => list.id) || [],
                    tag_ids: values.tags?.map((tag) => tag.id) || []
                });

                setContact(res.data.contact);
                setSubmitting(false);
            } catch (err) {
                console.log("ERR >>> sss", err);
            }
        }
    });

    const deleteContact = async () => {
        await deleteContacts([contact.id]);
        setDeletableContacts(null);
        history.replace("/contacts");
        setBreadCrumbsList(breadCrumbsList.slice(0, 1));
        dispatch(onUpdateToast({ type: "saved", value: "Tag was successfully removed" }));
    };

    // if (contactLoading) {
    //     return <PageLoadingSpinner />;
    // }
    return (
        <>
            <div className={styles["root"]}>
                <StyledAlert onConfirm={deleteContact} {...confirmDeletingAlertProps} />
                <StyledTabs
                    type={StyledTabsTypes.freeBlue}
                    className={styles["tabs"]}
                    tabs={availableTabs}
                    notShowTabs={availableTabs.length < 2}
                    tabsProps={{
                        id: "social-settings-tabs",
                        onChange: setTabId,
                        selectedTabId: tabId
                    }}
                    panel={
                        <MPDScroll yDirection={true} className={styles["panel-wrapper"]}>
                            {tabId === 0 && <ContactOverview contact={contact} contactLoading={contactLoading} />}
                            {tabId === 1 && <ContactActivity contact={contact} contactLoading={contactLoading} />}
                        </MPDScroll>
                    }
                />
                <ContactEdit contact={contact} formik={formik} contactLoading={contactLoading} />
            </div>
            <FormWasChanged formik={formik} />
        </>
    );
};
