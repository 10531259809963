import classNames from "classnames";
import React from "react";

import { IMPDSelectProps, MPDSelect } from "src/mpd-library";

import { StyledSelectTypes } from "./types";

import "./styles.scss";
import { Text } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

export interface IStyledSelectProps extends IMPDSelectProps {
    type?: StyledSelectTypes | Array<StyledSelectTypes>;
    error?: string;
    usePortal?: boolean;
    selectClassName?: string;
}

export const StyledSelect: React.FunctionComponent<IStyledSelectProps> = (props) => {
    const { className, type, options, popOverClassName, error, selectClassName, ...newProps } = props;     
    const classes = "styled-select";
    return (
        <div className={classNames(classes, type, className, error && Classes.ERROR)}>
            <MPDSelect
                popOverClassName={popOverClassName ? classNames(classes + "-popover", popOverClassName) : ""}
                options={options}
                className={selectClassName}
                {...newProps}
            />
            {error && (
                <Text className={classNames("error", error && Classes.ACTIVE)}>
                    {error.charAt(0).toUpperCase() + error.slice(1)}
                </Text>
            )}
        </div>
    );
};
