import { useMemo } from "react";
import { StyledButton } from "../../components";
import styles from "./Input.module.scss";
import { ReactComponent as InputCopyActionIcon } from "../icon/assets/InputCopyActionIcon.svg"
import { ReactComponent as InputEditActionIcon } from "../icon/assets/InputEditActionIcon.svg"
import { ReactComponent as InputRemoveActionIcon } from "../icon/assets/InputRemoveActionIcon.svg"

type ActionsButtonsProps = {
    actions: Array<"edit" | "copy" | "remove">;
    onEditClick: () => null;
    onCopyClick: () => null;
    onRemoveClick: () => null;
};

export const ActionsButtons = ({ actions = [], onEditClick, onCopyClick, onRemoveClick }: ActionsButtonsProps) => {
    const availableActions = useMemo(() => {
        return actions.reduce((acc, action) => {
            acc[action] = true;
            return acc;
        }, {});
    }, [actions]);

    if (actions.length) {
        return (
            <div className={styles["actons-wrapper"]}>
                {availableActions?.copy && <StyledButton IconComponent={InputCopyActionIcon} onClick={onCopyClick} />}
                {availableActions?.edit && <StyledButton IconComponent={InputEditActionIcon} onClick={onEditClick} />}
                {availableActions?.remove && <StyledButton IconComponent={InputRemoveActionIcon} onClick={onRemoveClick} />}
            </div>
        );
    }
    return null;
};
