const isValueFalse = (str, commaIndex) => {
    const falseKeywordLength = 5;
    if (str.slice(commaIndex - falseKeywordLength, commaIndex) === "false") {
        return true;
    }
    return false;
};

const isValueTrue = (str, commaIndex) => {
    const falseKeywordLength = 4;
    if (str.slice(commaIndex - falseKeywordLength, commaIndex) === "true") {
        return true;
    }
    return false;
};

const isDigit = (str, commaIndex) => {
    return /^\d+$/.test(str[commaIndex - 1]);
};

const isNull = (str, commaIndex) => {
    return str.slice(commaIndex - 4, commaIndex) === "null";
};

const isJsonLineEnd = (str, commaIndex) => {
    const prevSymbol = str[commaIndex - 1];
    const prevX2Symbol = str[commaIndex - 2];
    if (prevSymbol === `"` && prevX2Symbol !== `\\`) {
        return true;
    } else if (prevSymbol === `}` || prevSymbol === `]`) {
        return true;
    } else if (isValueTrue(str, commaIndex) || isValueFalse(str, commaIndex) || isDigit(str, commaIndex)) {
        return true;
    } else if (isNull(str, commaIndex)) {
        return true;
    } else {
        return false;
    }
};

export const formatJson = (json) => {
    const str = json.replaceAll(/\r|\n|\t|\s/g, "");
    let tabsCounter = 0;
    let formattedStr = "";
    for (let i = 0; i < str.length; i++) {
        if (str[i] === "{" || str[i] === "[") {
            tabsCounter = ++tabsCounter;
            const tabs = `\t`.repeat(tabsCounter >= 0 ? tabsCounter : 0);
            formattedStr = formattedStr.concat(`${str[i]}\n${tabs}`);
        } else if (str[i] === "," && isJsonLineEnd(str, i)) {
            const tabs = `\t`.repeat(tabsCounter >= 0 ? tabsCounter : 0);
            formattedStr = formattedStr.concat(`${str[i]}\n${tabs}`);
        } else if (str[i] === "}" || str[i] === "]") {
            tabsCounter = --tabsCounter;
            if (tabsCounter >= 0) {
                const tabs = `\t`.repeat(tabsCounter >= 0 ? tabsCounter : 0);
                formattedStr = formattedStr.concat(`\n${tabs}`).concat(str[i]);
            }
        } else {
            formattedStr = formattedStr.concat(str[i]);
        }
    }

    const res = formattedStr.replace(/"\s*([^"]*)\s*":/g, (match) => {
        return match.replace(`":`, `": `);
    });

    return res;
};
