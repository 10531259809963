import classNames from "classnames";
import styles from "./ContactOverview.module.scss";
import { Classes } from "../../../../mpd-library";
import { Text } from "@blueprintjs/core";
import { ContactActivityChart } from "../../../../components/contact-activity-chart/contact-activity-chart";
import { MOCKED_ALERT, MOCKED_CONVERSATIONS, Recent } from "../../recent/Recent";
import { getConversationsRequest } from "../../../../requests/conversationsRequests";
import { useMemo } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { onFilterApply } from "../../../../actions/conversations/conversations-api";
import { getAlertsListRequest } from "src/requests";
import { PageLoadingSpinner } from "../../../../components/page-loading-spinner/page-loading-spinner";

export const ContactOverview = ({ contact, contactLoading }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const filters = useMemo(() => {
        const atlasOneIdentifier = contact?.user_id;
        console.log("atlasOneIdentifier", atlasOneIdentifier);
        if (atlasOneIdentifier) {
            return window.btoa(JSON.stringify({ user_id: atlasOneIdentifier }));
        } else {
            return null;
        }
    }, [contact]);

    const fetchRecentConvos = useMemo(() => {
        if (filters) {
            return () =>
                getConversationsRequest({
                    limit: 5,
                    offset: 0,
                    filters,
                    view: "all"
                });
        } else return () => null;
    }, [filters]);

    const fetchRecentAlerts = useMemo(() => {
        if (filters) {
            return () => getAlertsListRequest({ limit: 5, offset: 0, user_id: contact?.user_id, sortTitle: "desc" });
        } else return () => null;
    }, [contact?.user_id]);

    const onSeeAllConversationsClick = () => {
        history.push("/conversations");
        dispatch(
            onFilterApply({
                filters: {
                    users: [{ user_id: contact?.user_id, ...contact }],
                    user_ids: { [contact?.user_id]: true }
                }
            })
        );
    };

    if (contactLoading) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={classNames(styles["root"], Classes.BLOCK_PADDING)}>
            <div className={classNames(Classes.CARD_BOX_SHADOW, styles["contact-statistics"])}>
                <div className={styles["contact-statistics__card"]}>
                    <Text className={styles["contact-statistics__card__title"]}>Created DATE</Text>
                    <Text className={styles["contact-statistics__card__value"]}>
                        {moment(contact?.inserted_at).format("M/D/YYYY HH:mm A z")}
                    </Text>
                </div>
                <div className={styles["contact-statistics__card"]}>
                    <Text className={styles["contact-statistics__card__title"]}>ENGAGEMENT</Text>
                    {!!contact?.engagement_stats ? (
                        <ContactActivityChart data={contact?.engagement_stats} />
                    ) : (
                        <Text className={styles["contact-statistics__card__value"]}>~</Text>
                    )}
                </div>
                <div className={styles["contact-statistics__card"]}>
                    <Text className={styles["contact-statistics__card__title"]}>LAST ACTIVITY DATE</Text>
                    <Text className={styles["contact-statistics__card__value"]}>
                        {contact?.last_contacted_at
                            ? moment(contact?.last_contacted_at).format("M/D/YYYY HH:mm A z")
                            : "Never"}
                    </Text>
                </div>
                {/* <div className={styles["contact-statistics__card"]}>
                    <Text className={styles["contact-statistics__card__title"]}>TOTAL CONVERSATIONS</Text>
                    <Text className={styles["contact-statistics__card__value"]}>~</Text>
                </div> */}
                {/* <div className={styles["contact-statistics__card"]}>
                    <Text className={styles["contact-statistics__card__title"]}>MESSAGES DELIVERED</Text>
                    <Text className={styles["contact-statistics__card__value"]}>4</Text>
                </div> */}
            </div>
            <Recent
                items={MOCKED_CONVERSATIONS}
                title={"RECENT CONVERSATIONS"}
                fetchData={fetchRecentConvos}
                contact={contact}
                recentEntityKey={"conversations"}
                onSeeAllClick={onSeeAllConversationsClick}
            />
            <Recent
                items={MOCKED_ALERT}
                title={"RECENT ALERTS"}
                contact={contact}
                recentEntityKey={"alerts"}
                fetchData={fetchRecentAlerts}
            />
        </div>
    );
};
