import { StepperStates } from "src/components";
import { string, array, object, boolean } from "yup";


export const STEPPER_INIT_DATA = [
    {
        title: "Overview",
        nav: "overview",
        state: StepperStates.active,
        num: "1",
        active: true
    },
    {
        title: "Choose Number",
        nav: "number",
        state: StepperStates.default,
        num: "2",
        active: false
    },
    {
        title: "Customize",
        nav: "customize",
        state: StepperStates.default,
        num: "3",
        active: false
    }
];

export const FORMIK_LINE_VALIDATION_OBJECT = [
    object().shape({
        lineName: string().required("Can't be blank"),
        description: string().nullable()
    }),
    object().shape({
        phone_number: object().required("Can't be blank"),
        enableForConversations: boolean()
    }),
    object().shape({
        queue: object(),
        admin: object(),
        tags: array()
    })
];

export const FORMIK_LINE_VALIDATION_OBJECT_ALL = object({
    lineName: string().required("Can't be blank"),
    description: string().nullable(),
    phone_number: string().required("Can't be blank"),
    // enableForConversations: boolean(),
    queue: object(),
    admin: object(),
    tags: array()
});
