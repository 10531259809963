// tslint:disable jsx-no-lambda
import classNames from "classnames";
import { History } from "history";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { globalActions } from "src/actions";
import { onUpdateBillingOverview } from "src/actions/account-settings/account-settings-api";
import {
    BreadCrumbs,
    SettingsTitle,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes
} from "src/components";
import { Classes } from "src/mpd-library";
import { AccountSettingsBillingOverviewState, AppState } from "src/store";
import { IDetailView } from "../../../../";
import "./styles.scss";

export interface IBillingSettingsOverviewProps extends IDetailView {
    overview?: AccountSettingsBillingOverviewState;
    onCancelChanges: (pathname: string, history: History) => void;
    onSaveChanges: (overview: AccountSettingsBillingOverviewState) => void;
}

interface IBillingSettingsOverviewState {
    overview?: AccountSettingsBillingOverviewState;
}

class BillingSettingsOverview extends React.Component<IBillingSettingsOverviewProps, IBillingSettingsOverviewState> {
    constructor(props: IBillingSettingsOverviewProps) {
        super(props);
        this.onInputChanged = this.onInputChanged.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onCancelChanges = this.onCancelChanges.bind(this);
        this.onUpdateToolBar = this.onUpdateToolBar.bind(this);
        this.onUpdateToolBar();
        const { overview } = this.props;
        this.state = {
            overview: JSON.parse(JSON.stringify(overview))
        };
    }

    public render() {
        const { className } = this.props;
        const { overview } = this.state;
        const classes = "billing-settings-overview";

        return (
            <div className={classNames(Classes.ROW, Classes.RESPONSIVE, Classes.CONTAINER_PADDING, classes, className)}>
                <div className={Classes.COLUMN}>
                    <div>
                        <SettingsTitle title="BILLING ADDRESS" />
                        {overview && (
                            <div className={classNames(classes + "-billing-address", Classes.ASSIGN_BLOCK_PADDING)}>
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={overview.billingAddress.address}
                                    onChange={(e) => this.onInputChanged(e, "billingAddress", "address")}
                                    placeholder="ADDRESS"
                                    tabIndex={1}
                                />
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={overview.billingAddress.addressLine2}
                                    onChange={(e) => this.onInputChanged(e, "billingAddress", "addressLine2")}
                                    placeholder="ADDRESS LINE 2"
                                    tabIndex={1}
                                />
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={overview.billingAddress.city}
                                        onChange={(e) => this.onInputChanged(e, "billingAddress", "city")}
                                        placeholder="CITY"
                                        tabIndex={1}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={overview.billingAddress.state}
                                        onChange={(e) => this.onInputChanged(e, "billingAddress", "state")}
                                        placeholder="STATE/PROVINCE"
                                        tabIndex={1}
                                    />
                                </div>
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={overview.billingAddress.postalCode}
                                        onChange={(e) => this.onInputChanged(e, "billingAddress", "postalCode")}
                                        placeholder="ZIP/POSTAL CODE"
                                        tabIndex={1}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={overview.billingAddress.country}
                                        onChange={(e) => this.onInputChanged(e, "billingAddress", "country")}
                                        placeholder="COUNTRY"
                                        tabIndex={1}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={Classes.COLUMN}>
                    <div>
                        <SettingsTitle title="BILLING CONTACT" />
                        <div className={classNames(classes + "-billing-contact", Classes.ASSIGN_BLOCK_PADDING)}>
                            {overview && (
                                <>
                                    <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                        <StyledFloatingInput
                                            autoCapitalize="none"
                                            autoCorrect="none"
                                            floatingType={StyledFloatingInputTypes.primary}
                                            value={overview.billingContact.firstName}
                                            onChange={(e) => this.onInputChanged(e, "billingContact", "firstName")}
                                            placeholder="FIRST NAME"
                                            tabIndex={1}
                                        />
                                        <StyledFloatingInput
                                            autoCapitalize="none"
                                            autoCorrect="none"
                                            floatingType={StyledFloatingInputTypes.primary}
                                            value={overview.billingContact.lastName}
                                            onChange={(e) => this.onInputChanged(e, "billingContact", "lastName")}
                                            placeholder="LAST NAME"
                                            tabIndex={1}
                                        />
                                    </div>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={overview.billingContact.email}
                                        onChange={(e) => this.onInputChanged(e, "billingContact", "email")}
                                        placeholder="EMAIL"
                                        tabIndex={1}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={overview.billingContact.phone}
                                        onChange={(e) => this.onInputChanged(e, "billingContact", "phone")}
                                        placeholder="PHONE"
                                        tabIndex={1}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onCancelChanges() {
        const { routeProps } = this.props;
        const { history } = routeProps;
        const pathname = `/account-settings/billing/overview`;
        this.props.onCancelChanges(pathname, history);
    }

    private onSaveChanges() {
        const { onSaveChanges } = this.props;
        const overview = this.state.overview;
        if (overview) {
            onSaveChanges(overview);
        }
    }

    private onInputChanged(event: React.ChangeEvent<HTMLInputElement>, objType: string, type: string) {
        const { overview } = this.state;
        if (overview) {
            overview[objType][type] = event.currentTarget.value;
        }
        this.setState({ overview });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    }

    private onUpdateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Billing" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledDuoButtons
                    firstButtonClick={this.onCancelChanges}
                    secondButtonClick={this.onSaveChanges}
                    firstButtonText="Cancel"
                    secondButtonText="Save"
                    type={StyledDuoButtonsTypes.primary}
                />
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const overview = accountSettings.organization.billing.overview;
    return {
        overview
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCancelChanges: globalActions.onCancelChanges,
            onSaveChanges: onUpdateBillingOverview
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettingsOverview);
