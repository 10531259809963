import { createContext, useContext, useMemo } from "react";
import { Position } from "@blueprintjs/core";
import classNames from "classnames";
import styles from "../ContactsFilters.module.scss";
import { FiltersContext } from "../context";
import { FiltersGroupComponent } from "./FilterGroupComponent";
import { ERROR_TOOTLTIP_TEXT, LOGICAL_OPERATIONS_ITEMS } from "../constants";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { Tooltip, TooltipClsasses } from "../../tooltip/tooltip";
import { ReactComponent as PlusIcon } from "src/mpd-library/icon/assets/plus.svg";
import { Dropdown } from "src/mpd-library/dropdown/dropdown";

export const GroupContext = createContext({});

export const FilterConditionComponent = ({ filterGroup, groupIndex, conditionsGroupArray }) => {
    const { filters, disabled, onChangeLogialOperatorBetweenGroups, hasErrors, filtersList, addFilter } =
        useContext(FiltersContext);

    const contextValue = useMemo(() => {
        return { filterGroup, groupIndex, conditionsGroupArray };
    }, [filterGroup, groupIndex, conditionsGroupArray]);

    return (
        <GroupContext.Provider value={contextValue}>
            <div className={styles["filter-slice"]}>
                {!!filterGroup?.conditions.length && (
                    <div className={styles["filter-group-wrapper"]}>
                        {filterGroup?.conditions?.map((condition, conditionIndex, conditionsArray) => (
                            <FiltersGroupComponent
                                condition={condition}
                                conditionIndex={conditionIndex}
                                conditionsArray={conditionsArray}
                            />
                        ))}
                    </div>
                )}
                {groupIndex < conditionsGroupArray.length - 1 && (
                    <Dropdown
                        disabled={disabled}
                        staticOptions={LOGICAL_OPERATIONS_ITEMS}
                        targetClassName={classNames(
                            DropdownTargetClasses.SIMPLE,
                            styles["operator-between-group-dropdown-target"]
                        )}
                        position={Position.BOTTOM}
                        onStaticOptionClick={onChangeLogialOperatorBetweenGroups}
                        selectedValue={{
                            label: filters.operator,
                            value: filters.operator
                        }}
                    />
                )}
                {groupIndex === conditionsGroupArray.length - 1 && (
                    <Tooltip
                        className={styles["add-filter-tooltip-target_margin"]}
                        disabled={!hasErrors || disabled}
                        content={ERROR_TOOTLTIP_TEXT}
                        popoverClassName={TooltipClsasses.error}
                        tootltipTarget={
                            <Dropdown
                                targetClassName={DropdownTargetClasses.SELECT}
                                contentBlockWidth={250}
                                contentBlockHeight={600}
                                selectedValue={{ label: "Add Filter" }}
                                LeftIconComponent={PlusIcon}
                                position={Position.BOTTOM_LEFT}
                                onStaticOptionClick={(option) => {
                                    addFilter(option);
                                }}
                                staticOptions={filtersList}
                                entityKey={"filters"}
                                fieldNameForId={"value"}
                                disabled={hasErrors || disabled}
                            />
                        }
                    />
                )}
            </div>
        </GroupContext.Provider>
    );
};
