/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */ 
export const StyledTabsTypes = {
    primary: "primary" as const,
    primaryReversed: "primary-reversed" as const,
    freeBlue: "free-blue" as const,
    freeBlueReversed: "free-blue-reversed" as const,
    rectangle: "rectangle" as const,
    padding: "padding" as const,
    rounded: "rounded" as const
};

export type StyledTabsTypes = typeof StyledTabsTypes[keyof typeof StyledTabsTypes];
