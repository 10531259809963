import { createContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "../contacts";
import { getFittedFilters } from "../utils";

type ContactsContextType = {
    savedView: any;
    presettedFilter: any;
    setSavedView: any;
    setPresettedFilter: any;
};

export const ContactsContext = createContext<ContactsContextType | {}>({});

export const ContactsContextProvider = ({ children }) => {
    const query = useQuery();
    const filter = query.get("filter");
    const [presettedFilter, setPresettedFilter] = useState(filter ? getFittedFilters(filter) : null);
    const [savedView, setSavedView] = useState<boolean>(false);
    const [list, setList] = useState<any>(null);
    const [deletableContacts, setDeletableContacts] = useState<Array<string> | null | "all">(null);
    const [selectedContacts, setSelectedContacts] = useState<{ [key: string]: { [key: string] } } | "all">({});

    // useEffect(() => {
    //     if (presettedFilter) {
    //         setPresettedFilter(null);
    //     }
    // }, [presettedFilter]);

    const contactsContextValue = useMemo(
        () => ({
            savedView,
            presettedFilter,
            deletableContacts,
            list,
            selectedContacts,
            setSelectedContacts,
            setList,
            setDeletableContacts,
            setSavedView,
            setPresettedFilter
        }),
        [
            savedView,
            presettedFilter,
            deletableContacts,
            list,
            selectedContacts,
            setSelectedContacts,
            setList,
            setSavedView,
            setPresettedFilter,
            setDeletableContacts
        ]
    );

    return <ContactsContext.Provider value={contactsContextValue} children={children} />;
};
