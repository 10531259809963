import { ACTIVE, DEFAULT, FINISHED, HIDE } from "src/mpd-library";
import { StepperStates } from "../";

export function stepperStatesClass(state?: StepperStates) {
    switch (state) {
        case StepperStates.finished:
            return FINISHED;
        case StepperStates.active:
            return ACTIVE;
        case StepperStates.default:
            return DEFAULT;
        case StepperStates.hidden:
            return HIDE;
        default:
            return undefined;
    }
}
