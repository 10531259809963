import { useEffect, useMemo, useState } from "react";
import { Text } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import pluralize from "pluralize";

import styles from "./styles.module.scss";
import { TextClasses } from "../../../../../../components/styled-text/styled-text";
import { onGetChannelsArray as getChannelsArray } from "src/actions/account-settings/account-settings-api";
import { AppState } from "src/store";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { ShadowedListItem } from "src/components/shadowed-settings-list-item/shadowed-settings-list-item";
import { StyledButton } from "src/components";
import { RoundedIcon } from "src/components/rounded-icon/rounded-icon";
import { SOCIAL_NETWORKS_ITEMS_CONFIG } from "../../constants";
import { useReauthChannel } from "src/containers/conversations/components/chat/hooks/useReauthChannel";
import { CreateSmsVoiceChannelModal } from "../../channels/sms-voice-flow/components/sms-voice-channel-create/sms-voice-channel-create";

const classname = "social-networks-channels-list";

const channelsKeysSequence = [
    "facebook",
    "twitter",
    "instagram",
    "sms_voice"
    // "email"
];

export const SocialNetworksChannelsList = () => {
    const [loadingChannels, setLoadingChannels] = useState(false);
    const channelsArrays = useSelector((state: AppState) => state.accountSettings.general.channelsArray);
    const dispatch = useDispatch();
    const { onInstall: onInstallClick, authChannelProcessing: installProcessing, authChannelKey } = useReauthChannel();

    const onGetChannels = async () => {
        try {
            setLoadingChannels(true);
            await dispatch(getChannelsArray());
        } finally {
            setLoadingChannels(false);
        }
    };

    useEffect(() => {
        onGetChannels();
    }, []);

    const onIsntall = (e, socialNetworkName) => {
        if (socialNetworkName === "sms_voice") {
            return;
        }

        onInstallClick(e, socialNetworkName);
    };

    const { withoutAccountsKeys, withAccountsKeys } = useMemo(() => {
        const withoutAccountsKeys = [];
        const withAccountsKeys = [];
        channelsKeysSequence.forEach((socialNetworkKey: string) => {
            if (channelsArrays[socialNetworkKey]?.length > 0) {
                withAccountsKeys.push(socialNetworkKey);
            } else {
                withoutAccountsKeys.push(socialNetworkKey);
            }
        });

        return { withoutAccountsKeys, withAccountsKeys };
    }, [channelsArrays]);

    if (loadingChannels) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[classname]}>
            {!!withAccountsKeys.length && (
                <>
                    <Text className={classNames(TextClasses.LIST_SUBTITLE, TextClasses.MARGIN_BOTTOM_40)}>
                        MY CHANNELS
                    </Text>
                    <div className={styles[`${classname}-group-wrapper`]}>
                        {withAccountsKeys.map((key) => {
                            return (
                                <ShadowedListItem
                                    navToRoute={SOCIAL_NETWORKS_ITEMS_CONFIG[key].navToRoute}
                                    title={SOCIAL_NETWORKS_ITEMS_CONFIG[key].title}
                                    subtitle={SOCIAL_NETWORKS_ITEMS_CONFIG[key].subtitle}
                                    customRightElement={
                                        <div className={styles[`${classname}-connected-item-left-wrapper`]}>
                                            <span>
                                                {`${channelsArrays[key]?.length} ${pluralize(
                                                    "Account",
                                                    channelsArrays[key]?.length
                                                )} installed`}
                                            </span>
                                        </div>
                                    }
                                    IconComponent={SOCIAL_NETWORKS_ITEMS_CONFIG[key]?.icon}
                                    customLeftIconComponent={
                                        <RoundedIcon {...SOCIAL_NETWORKS_ITEMS_CONFIG[key]?.customLeftIconProps} />
                                    }
                                />
                            );
                        })}
                    </div>
                </>
            )}
            {!!withoutAccountsKeys.length && (
                <>
                    <Text
                        className={classNames(
                            TextClasses.LIST_SUBTITLE,
                            !withAccountsKeys.length ? TextClasses.MARGIN_BOTTOM_40 : TextClasses.MARGIN_VERTICAL_40
                        )}
                    >
                        ADD NEW CHANNELS
                    </Text>
                    <div className={styles[`${classname}-group-wrapper`]}>
                        {withoutAccountsKeys.map((key) => {
                            return (
                                <ShadowedListItem
                                    // navToRoute={SOCIAL_NETWORKS_ITEMS_CONFIG[key].navToRoute}
                                    title={SOCIAL_NETWORKS_ITEMS_CONFIG[key].title}
                                    subtitle={SOCIAL_NETWORKS_ITEMS_CONFIG[key].subtitle}
                                    customRightElement={
                                        <>
                                            {key !== "sms_voice" ? (
                                                <StyledButton
                                                    text={"Install"}
                                                    type={"primary"}
                                                    onClick={(e) => onIsntall(e, key)}
                                                    processing={installProcessing && authChannelKey === key}
                                                />
                                            ) : (
                                                <CreateSmsVoiceChannelModal
                                                    Target={({ onClick }) => (
                                                        <StyledButton
                                                            text={"Install"}
                                                            type={"primary"}
                                                            onClick={onClick}
                                                            processing={installProcessing && authChannelKey === key}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </>
                                    }
                                    IconComponent={SOCIAL_NETWORKS_ITEMS_CONFIG[key]?.icon}
                                    customLeftIconComponent={
                                        <RoundedIcon {...SOCIAL_NETWORKS_ITEMS_CONFIG[key]?.customLeftIconProps} />
                                    }
                                    disabled={SOCIAL_NETWORKS_ITEMS_CONFIG[key].disabled}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};
