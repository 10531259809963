import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import { Classes, MPDIOSSelectableDiv } from "src/mpd-library";

import "./styles.scss";

interface ISubscriptionBoxProps extends IProps {
    active?: boolean;
    description?: JSX.Element;
    title?: string;
    subtitle?: string;
    content?: JSX.Element;
    list?: Array<string>;
}

export class SubscriptionBox extends React.Component<ISubscriptionBoxProps> {
    public render() {
        const { className, active, description, title, subtitle, content } = this.props;

        const classes = "subscription-box";

        return (
            <MPDIOSSelectableDiv className={classNames(classes, className, active && Classes.ACTIVE)}>
                <div className={classes + "-top-bar"}>
                    <div className={classes + "-header"}>
                        <Text className={classes + "-header-title"}>{title}</Text>
                        <Text className={classes + "-header-subtitle"}> {subtitle} </Text>
                    </div>
                    {description}
                </div>
                {content}
            </MPDIOSSelectableDiv>
        );
    }
}
