import { FC, SyntheticEvent } from "react";
import { Text } from "@blueprintjs/core";
import { SearchInput, SearchInputClasses } from "../search-input/search-input";
import styles from "./styles.module.scss";
import classNames from "classnames";

const classname = "dropdown-search-header";

type DropdownSearchHeaderProps = {
    title: string;
    searchValue: string;
    withoutSearch?: boolean;
    onSearchChangeHandler: (event: SyntheticEvent) => void;
};

export const DropdownSearchHeader: FC<DropdownSearchHeaderProps> = ({
    title,
    searchValue,
    withoutSearch,
    onSearchChangeHandler
}: DropdownSearchHeaderProps) => {
    return (
        <div className={styles[classname]}>
            {!!title && <Text className={styles[`${classname}-title`]}>{title}</Text>}
            {!withoutSearch && (
                <SearchInput
                    autoFocus
                    className={classNames(
                        styles[`${classname}-search-input`],
                        "conversations-dropdown-search-input",
                        SearchInputClasses.dropdown
                    )}
                    placeholder={"Search"}
                    onChange={onSearchChangeHandler}
                    value={searchValue}
                />
            )}
        </div>
    );
};
