import classNames from "classnames";
import { FC, FunctionComponent, SVGProps, memo, useEffect, useRef, useState } from "react";
import { ICON_PATHS } from "./constants";
// import { useDynamicSVGImport } from "./useSvgIconsHook";

// import { ReactComponent as Smss } from "./assets/ChannelsSms.svg";
// import Sms from "./assets/ChannelsSms.svg";

// import LazyIconLoader from "./lazy-icon-loader";

type IconProps = {
    name: string;
    className: string;
    width?: number;
    height?: number;
    size?: number;
    asImg: JSX.Element | React.ReactNode;
    IconComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
};

const classes = "mpd-icon";

const cache = {};

const useDynamicSVGImport = (name) => {
    const ImportedIconRef = useRef();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            if (!name) {
                return;
            }

            try {
                if (!cache[name]) {
                    const res = await import(`./assets/${ICON_PATHS[name]}.svg`);
                    cache[name] = res;
                }
            } catch (err) {
                setError(err);
                console.log("ERR >>>>", err);
                throw err;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [name]);

    return name ? { error, loading, SvgIcon: cache[name] } : {};
};

export const MPDIcon: FC<IconProps> = memo(
    ({ name, className, width, height, size, asImg, IconComponent, ...rest }): JSX.Element | null => {
        const { loading, SvgIcon } = useDynamicSVGImport(name);

        if (loading) {
            return (
                <div
                    style={{ width: `${size || width}px`, height: `${size || height}px` }}
                    className={classNames(classes, className)}
                />
            );
        }
        if (asImg) {
            return <div> {asImg}</div>;
        } else {
            if (IconComponent) {
                return <IconComponent className={classNames(classes, className)} />;
            }
        }
        if (!!SvgIcon) {
            const SvgIconComponent = SvgIcon.ReactComponent;
            return (
                <SvgIconComponent
                    className={classNames(classes, className)}
                    style={{ width: `${size || width}px`, height: `${size || height}px` }}
                    {...rest}
                />
            );
        }
        return null;
    }
);
