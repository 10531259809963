// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Classes, MPDFloatingPhoneInput, IMPDFloatingPhoneInputProps } from "src/mpd-library";

import { Text } from "@blueprintjs/core";

import { StyledFloatingPhoneInputTypes } from "./types";

import "./styles.scss";

export interface IStyledFloatingPhoneInputProps extends IMPDFloatingPhoneInputProps {
    error?: string;
    floatingType?: StyledFloatingPhoneInputTypes;
    moreInfo?: string;
    inputRef?: any;
}

export const StyledFloatingPhoneInput: React.FunctionComponent<IStyledFloatingPhoneInputProps> = (props) => {
    const { className, disabled, error, floatingType, moreInfo, ...remaining } = props;

    const classes = classNames(
        "styled-floating-phone-input",
        floatingType,
        disabled && Classes.DISABLED,
        className,
        error && Classes.ERROR,
        disabled && "disabled"
    );

    return (
        <div className={classes}>
            <MPDFloatingPhoneInput disabled={disabled} {...remaining} />
            {moreInfo && <Text className={classNames("more-info", moreInfo && Classes.ACTIVE)}>{moreInfo}</Text>}
            {error && <Text className={classNames("error", error && Classes.ACTIVE)}>{error}</Text>}
        </div>
    );
};
