import { OnUpdateComposeBottomToolBarProps } from "src/actions/alerts/alerts-compose";
import { ShapeCoords, Logo, AlertData, FileType } from "src/requests";
import { ListsIdTypes } from "src/containers";
import { GeoFence, IIncidentLocation } from "./commonInterfaces";
import { EventsMatchingTable } from "src/requests/settingsRequests";

export interface ComposeDataAlertDetails {
    severity: string;
    incidentLocation: IIncidentLocation;
    perimeter: GeoFence;
    address: ReversseGeocodeAddress;
    generatedPreviewLink: string;
    location_centered: boolean | null;
    perimeter_equals_geo_fence: boolean | null;
    expiration: {
        value: string;
        custom: {
            date: Date;
            time: string;
        };
    };
    senderName: {
        value: string;
    };
    emailFrom: {
        value: string;
        custom: {
            value: string;
        };
    };
    emailReply: {
        value: string;
        custom: {
            value: string;
        };
    };
    voice: {
        callerId: {
            value: string;
        };
        callerName: {
            value: string;
        };
    };
}

export interface ReversseGeocodeAddress {
    components: Array<any>;
    custom_text?: string;
    address_formatted: string;
}

export interface ComposeDataSelectChannels {
    spot: {
        status: boolean;
    };
    twitter: {
        status: boolean;
        list: Array<{
            status: boolean;
            title: string;
        }>;
    };
    facebook: {
        status: boolean;
        list: Array<{
            status: boolean;
            title: string;
        }>;
    };
}

export interface ComposeDataComposeMessage {
    messageDetails: {
        moreInfo: boolean;
        description: string;
        headline: string;
        instructions: string;
        wea: string;
    };
    multiMedia: {
        currentMediaFiles: any;
        fileName: any;
        fileTypes: any;
        files: Array<any>;
    };
}

export interface ComposeDataAudienceGeoFence extends GeoFence {
    list: Array<any>;
    search: {
        value: any;
    };
}

export interface ComposeDataChooseAudience {
    audiences: string;
    geoFence: ComposeDataAudienceGeoFence;
}

export interface AlertComposeStepper {
    active: number;
}

export interface AlertComposeComposeDataErrors {
    messageDetails: {
        description: string | null;
        title: string | null;
        instructions: string | null;
    };
    alertDetails: {
        expiration: string | null;
    };
}
export type RepliesSettingsKeysEnum = "admin_ids" | "tag_ids" | "queue_id";

export type RepliesSettingsAdmin = { id: string; first_name: string; last_name: string; profile_pic: any } | null;

export type RepliesSettingsQueue = { id: string; name: string } | null;

export type RepliesSettingsTag = { id: string; name: string };

export type RepliesSettings = {
    admin: RepliesSettingsAdmin;
    tags: Array<RepliesSettingsTag>;
    queue: null | RepliesSettingsQueue;
};

export interface AlertComposeData {
    bottomBarProps: OnUpdateComposeBottomToolBarProps | null;
    loading: boolean;
    status: ListsIdTypes;
    alertDetails: ComposeDataAlertDetails;
    saveStepProcessing: boolean;
    chooseAudience: ComposeDataChooseAudience;
    composeMessage: ComposeDataComposeMessage;
    composeDataErrors: AlertComposeComposeDataErrors;
    previewSend: any;
    stepper: AlertComposeStepper;
    logo: Logo | null;
    center: ShapeCoords | null;
    generatePreviewProcessingStatus: boolean;
    channelIdsObject: {
        status: boolean;
        channelIds?: Array<string>;
    };
    og_image_style: string;
    og_image_theme: string;
    allow_replies: boolean;
    replies_settings: RepliesSettings;
}

export interface ChannelObject {
    name: string;
    url: string;
    type?: string;
    status: string;
}

export interface AlertStateAlertData extends AlertData {
    isSelected: boolean;
    multiMedia: AlertStateMultimediaData;
    generatePushNotif?: boolean;
    geoFence?: {
        type: string;
        properties: any;
        geometry: {
            type: string;
            coordinates: any;
        };
    };
    address: ReversseGeocodeAddress;
    body?: string;
    channels: {
        atlasone?: Array<ChannelObject>;
        twitter?: Array<ChannelObject>;
        facebook?: Array<ChannelObject>;
    };
}

export interface AlertStateMultimediaData {
    currentMediaFiles: Array<FileType>;
    fileName: string;
    fileTypes: any;
    files: Array<FileType>;
}

export interface IsFullAlertsListState {
    active: boolean;
    expired: boolean;
    archived: boolean;
    draft: boolean;
}

export interface AlertListState {
    alertsList: Array<AlertStateAlertData>;
    alertsListLoading: boolean;
    isFullAlertsList: boolean;
}

export interface AlertListsState {
    active: AlertListState;
    expired: AlertListState;
    archived: AlertListState;
    draft: AlertListState;
}

export interface AlertStateData {
    lists: AlertListsState;
    searchList: AlertListState;
    tabId: ListsIdTypes;
    multiMedia?: AlertStateMultimediaData;
    searchBarText: string;
    selectedAlert?: AlertStateAlertData;
    previousListItem?: any;
    multimediaForUpdate?: any;
}

export interface AlertsState {
    composeData: AlertComposeData;
    alert: AlertStateData;
    rightPanelExpanded: boolean;
    createAlertProcessing: boolean;
    eventsMatchingTableData: {
        loading: boolean;
        eventsMatchingTable: EventsMatchingTable;
    };
}

export const defaultAlertsState: AlertsState = {
    composeData: {
        generatePreviewProcessingStatus: false,
        channelIdsObject: {
            status: false,
            channelIds: []
        },
        allow_replies: false,
        replies_settings: {
            admin: null,
            tags: [],
            queue: null
        },
        bottomBarProps: null,
        loading: false,
        status: "active",
        og_image_style: "map",
        og_image_theme: "dark",
        saveStepProcessing: false,
        alertDetails: {
            perimeter_equals_geo_fence: null,
            location_centered: null,
            generatedPreviewLink: "",
            severity: "minor",
            incidentLocation: {
                status: false,
                marker: null
            },
            perimeter: {
                status: false,
                currentShape: null
            },
            address: {
                components: [],
                custom_text: "",
                address_formatted: ""
            },
            expiration: {
                value: "24",
                custom: {
                    date: new Date(),
                    time: "12:00 PM"
                }
            },
            senderName: {
                value: ""
            },
            emailFrom: {
                value: "",
                custom: {
                    value: ""
                }
            },
            emailReply: {
                value: "",
                custom: {
                    value: ""
                }
            },
            voice: {
                callerId: {
                    value: ""
                },
                callerName: {
                    value: ""
                }
            }
        },
        chooseAudience: {
            audiences: "",
            geoFence: {
                status: false,
                list: [],
                currentShape: null,
                search: {
                    value: null
                }
            }
        },
        composeDataErrors: {
            messageDetails: {
                description: null,
                title: null,
                instructions: null
            },
            alertDetails: {
                expiration: null
            }
        },
        composeMessage: {
            messageDetails: {
                moreInfo: false,
                description: "",
                headline: "",
                instructions: "",
                wea: ""
            },
            multiMedia: {
                currentMediaFiles: [],
                fileName: null,
                fileTypes: null,
                files: []
            }
        },
        previewSend: undefined,
        stepper: {
            active: -1
        },
        logo: null,
        center: null
    },
    alert: {
        selectedAlert: undefined,
        previousListItem: undefined,
        lists: {
            active: {
                alertsList: [],
                alertsListLoading: false,
                isFullAlertsList: false
            },
            expired: {
                alertsList: [],
                alertsListLoading: false,
                isFullAlertsList: false
            },
            archived: {
                alertsList: [],
                alertsListLoading: false,
                isFullAlertsList: false
            },
            draft: {
                alertsList: [],
                alertsListLoading: false,
                isFullAlertsList: false
            }
        },
        searchList: {
            alertsList: [],
            alertsListLoading: false,
            isFullAlertsList: false
        },
        tabId: "active",
        multiMedia: undefined,
        searchBarText: ""
    },
    rightPanelExpanded: false,
    createAlertProcessing: false,
    eventsMatchingTableData: {
        loading: false,
        eventsMatchingTable: {}
    }
};
