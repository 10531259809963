// tslint:disable jsx-no-lambda
import React from "react";

import {
    IProps,
    Position,
    Toaster,
    Toast,
    IToastProps,
    IIntentProps,
    ProgressBar,
    Classes,
    Intent
} from "@blueprintjs/core";

import { IToastType } from "src/components";

import classNames from "classnames";

import "./styles.scss";
import { ProgressToastReceivedParams } from "./types";
import { AppState } from "src/store";
import { connect } from "react-redux";
import { ConversationsPushNotification } from "../conversations-push-botification/conversations-push-botification";

export interface ICommonToastProps extends IProps {
    toast?: IToastType;
    allowInAppNotifications?: boolean;
}

export interface ICommonToastState {
    toasts: Array<IToastProps>;
    countersForFakeProgress: Array<{
        id: string;
        counter: number;
    }>;
}

class CommonToastComponent extends React.Component<ICommonToastProps, ICommonToastState> {
    private toaster: Toaster;
    private notificationToaster: Toaster;
    private progressToastInterval: { [key: string]: any } = {};
    private intentMock = {
        error: "danger",
        saved: "success",
        warning: "warning",
        primary: "primary",
        progress: "primary",
        none: "none"
    };

    private refHandlers = {
        toaster: (ref: Toaster) => (this.toaster = ref),
        notificationToaster: (ref: Toaster) => (this.notificationToaster = ref)
    };

    constructor(props: ICommonToastProps) {
        super(props);
        this.state = {
            toasts: [],
            countersForFakeProgress: []
        };
    }

    public componentDidMount() {
        // this.handleNotificationToast({});
    }

    public componentDidUpdate(prevProps: ICommonToastProps) {
        const { toast } = this.props;
        if (toast && prevProps.toast !== toast && toast.active) {
            const type = toast.type || "none";
            if (toast.type === "progress") {
                return this.handleProgressToast();
            }

            if (toast.type === "notification") {
                return this.handleNotificationToast(toast);
            }

            this.toaster.show({
                message: toast.value,
                intent: this.intentMock[type] as IIntentProps["intent"]
            });
        }
    }

    public render() {
        const { toasts } = this.state;

        return (
            <>
                <Toaster className="global-toast" position={Position.TOP} ref={this.refHandlers.toaster} key={'ddd'}>
                    {toasts.map((toast: ICommonToastProps) => (
                        <Toast {...toast} />
                    ))}
                </Toaster>
                <Toaster
                    className="global-toast"
                    position={Position.TOP_RIGHT}
                    ref={this.refHandlers.notificationToaster}
                >
                    {toasts.map((toast: ICommonToastProps) => (
                        <Toast {...toast} />
                    ))}
                </Toaster>
            </>
        );
    }

    private handleNotificationToast = (toast) => {
        toast.className = "conversation-push-notification";
        toast.message = (
            <ConversationsPushNotification notification={toast.notification} toast={this.notificationToaster} />
        );
        toast.timeout = 5000;

        this.notificationToaster.show(toast,  toast.notification.id);
    };

    private renderProgress(amount: number, interval?: any): IToastProps {
        return {
            icon: "cloud-upload",
            message: (
                <ProgressBar
                    className={classNames("docs-toast-progress", { [Classes.PROGRESS_NO_STRIPES]: amount >= 100 })}
                    intent={amount < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={amount / 100}
                />
            ),
            onDismiss: (didTimeoutExpire: boolean) => {
                if (!didTimeoutExpire) {
                    window.clearInterval(interval);
                }
            },
            timeout: amount < 100 ? 0 : 1000
        };
    }

    private handleProgressToast = () => {
        const { toast } = this.props;
        if (!toast) {
            return;
        }

        let progress = 0;
        const customParams = toast.customParams && toast.customParams;
        const key = customParams ? customParams.id : this.toaster.show(this.renderProgress(0));
        this.progressToastInterval.key = window.setInterval(() => {
            const receivedProgress = customParams ? customParams.getProgress(customParams.id) : null;

            const isFinishedProgressToast =
                this.toaster === null ||
                (progress >= 100 && !customParams) ||
                receivedProgress === null ||
                (customParams && receivedProgress && receivedProgress.isFinished && progress >= 100);
            const progressToastIntervalKeys = this.progressToastInterval && Object.keys(this.progressToastInterval);

            if (receivedProgress === null && progressToastIntervalKeys.length) {
                progressToastIntervalKeys.forEach((objKey: string) => {
                    window.clearInterval(this.progressToastInterval[objKey]);
                });

                return;
            }

            if (isFinishedProgressToast) {
                window.clearInterval(this.progressToastInterval.key);
                if (customParams) {
                    customParams.removeUploadingOrder(customParams.id);
                }
            } else {
                if (customParams) {
                    progress = receivedProgress ? this.countProgress(receivedProgress) : 100;
                } else {
                    progress = this.countDefaultProgress(progress);
                }

                this.toaster.show(this.renderProgress(progress, this.progressToastInterval.key), key);
            }
        }, 250);
    };

    private countDefaultProgress = (progress: number) => {
        return (progress += 10 + Math.random() * 20);
    };

    private countProgress = ({ isFinished, percentage }: ProgressToastReceivedParams) => {
        const defaultMaxValue = 60;
        if (!isFinished) {
            return percentage < defaultMaxValue ? percentage : defaultMaxValue;
        } else {
            return 100;
        }
    };
}

const mapStateToProps = (state: AppState) => {
    const {
        global: { toast }
    } = state;

    const allowInAppNotifications = state.notifications.notificationsSettings?.types["in-app"];

    return {
        toast,
        allowInAppNotifications
    };
};

export const CommonToast = connect(mapStateToProps, {})(CommonToastComponent);
