import { Conversation } from "src/store/conversations";
import { useDataFetch } from "../../../../../../hooks/useDataFetch";
import useInfiniteScroll from "../../../chat/useInfiniteScroll";
import { getConversationsRequest } from "src/requests/conversationsRequests.ts";
import { ConversationCard, ConversationCardTypes } from "../../../conversation-card/conversation-card";
import styles from "./styles.module.scss";
import { EmptyBlock, EmptyBlockTypes } from "../../../../../../components";
import { useMemo } from "react";
import classNames from "classnames";
import { ReactComponent as NoSelectedConversationIcon } from "src/mpd-library/icon/assets/no-selected-conversation-icon.svg";

const classname = "prev-conversations";

export const SenderPreviousConversations = ({ user, className }) => {
    const { id: userId } = user;
    const fetchDataParams = useMemo(
        () => ({
            filters: btoa(JSON.stringify({ user_id: userId })),
            view: "all"
        }),
        [userId]
    );
    const {
        entities: prevConversationsWithSender,
        hasMore,
        loading,
        getMoreData,
        isListEmpty
    } = useDataFetch({
        entityKey: "conversations",
        listItemHeight: 70,
        fetchDataRequest: getConversationsRequest,
        fetchDataParams,
        skeletonLoading: true
    });

    const [bottomSentryRef] = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore: getMoreData
    });

    return (
        <div className={classNames(styles[classname], className)}>
            {isListEmpty && (
                <EmptyBlock
                    title="No conversations found"
                    IconComponent={NoSelectedConversationIcon}
                    type={EmptyBlockTypes.list}
                />
            )}
            {!isListEmpty &&
                prevConversationsWithSender.allIds.map((conversationId: Conversation) => {
                    const conversation = prevConversationsWithSender.byId[conversationId];
                    return (
                        <ConversationCard
                            loading={conversationId === "loading"}
                            type={ConversationCardTypes.PREVIOUS_WITH_SENDER}
                            item={conversationId === "loading" ? {} : conversation}
                        />
                    );
                })}
            {hasMore && <div ref={bottomSentryRef} className={styles[`${classname}-load-more-spinner-wrapper`]} />}
        </div>
    );
};
