import { TopBarProps } from "src/store";
import { IToastType } from "src/components";

export const TOGGLE_SIDEBAR = "toggle-sidebar";
export const TOGGLE_RIGHTPANEL = "update-toggle-rightpanel";
export const UPDATE_IS_MOBILE_SIZE = "update-is-mobile-size";
export const UPDATE_IS_TABLET_PORTRAIT_SIZE = "update-is-tablet-portrait-size";
export const UPDATE_DEVICE_TYPE = "update-device-type";
export const UPDATE_LOCATION = "update-location";
export const UPDATE_HISTORY = "update-history";
export const UPDATE_WINDOW_WIDTH = "update-window-width";
export const UPDATE_WINDOW_HEIGHT = "update-window-height";
export const UPDATE_TOOL_BAR = "update-tool-bar";
export const UPDATE_PROFILE_DATA = "update-profile-data";

export const UPDATE_TOAST = "update-toast";
export const UPDATE_CHANGES_MADE = "update-changes-made";
export const UPDATE_NAV_PATH = "update-nav-path";
export const UPDATE_ALERT_OPEN = "update-alert-open";
export const UPDATE_LOADING = "update-loading";
export const UPDATE_NAV_CALLBACK = "update-nav-callback";
export const UPDATE_GLOBAL_SPINNER_STATUS = "update-global-spinner-status";
export const SET_IS_APP_TAB_IN_FOCUS = "set-is-app-tab-in-focus";
export const SET_INITIAL_LOADING_PROCESSING_STATUS = "SET_INITIAL_LOADING_PROCESSING_STATUS";
export const SET_FIRST_ACCESSIBLE_ROUTE = "SET_FIRST_ACCESSIBLE_ROUTE";

type SetFirstAccessibleRoute = {
    type: typeof SET_FIRST_ACCESSIBLE_ROUTE;
    value: string;
};

type SetInitialLoadingProcessingStatus = {
    type: typeof SET_INITIAL_LOADING_PROCESSING_STATUS;
    value: boolean;
};

interface ToggleSidebarAction {
    type: typeof TOGGLE_SIDEBAR;
    payload: boolean;
}

interface ToggleRightpanelAction {
    type: typeof TOGGLE_RIGHTPANEL;
    payload: boolean;
}

interface UpdateIsMobileSizeAction {
    type: typeof UPDATE_IS_MOBILE_SIZE;
    payload: boolean;
}

interface UpdateIsTabletPortraitSizeAction {
    type: typeof UPDATE_IS_TABLET_PORTRAIT_SIZE;
    payload: boolean;
}

interface UpdateDeviceTypeAction {
    type: typeof UPDATE_DEVICE_TYPE;
    payload: "Android" | "iOS" | "unknown";
}

interface UpdateLocationAction {
    type: typeof UPDATE_LOCATION;
    payload: any;
}

interface UpdateHistoryAction {
    type: typeof UPDATE_HISTORY;
    payload: any;
}

interface UpdateWindowWidthAction {
    type: typeof UPDATE_WINDOW_WIDTH;
    payload: number;
}

interface UpdateWindowHeightAction {
    type: typeof UPDATE_WINDOW_HEIGHT;
    payload: number;
}

interface UpdateToolBarAction {
    type: typeof UPDATE_TOOL_BAR;
    payload: TopBarProps;
}

interface UpdateProfileDataAction {
    type: typeof UPDATE_PROFILE_DATA;
    payload: any;
}

interface UpdateToastAction {
    type: typeof UPDATE_TOAST;
    payload: IToastType;
}

interface UpdateChangesMadeAction {
    type: typeof UPDATE_CHANGES_MADE;
    payload: boolean;
}

interface UpdateNavPathAction {
    type: typeof UPDATE_NAV_PATH;
    payload: string;
}

interface UpdateAlertOpenAction {
    type: typeof UPDATE_ALERT_OPEN;
    payload: boolean;
}

interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING;
    payload: boolean;
}

interface UpdateNavCallbackAction {
    type: typeof UPDATE_NAV_CALLBACK;
    payload: any;
}

interface UpdateGlobalSpinnerStatus {
    type: typeof UPDATE_GLOBAL_SPINNER_STATUS;
    payload: boolean;
}

type SetIsAppTabInFocus = {
    type: typeof SET_IS_APP_TAB_IN_FOCUS;
    payload: boolean;
};

export type GlobalActionTypes =
    | ToggleSidebarAction
    | ToggleRightpanelAction
    | UpdateIsMobileSizeAction
    | UpdateIsTabletPortraitSizeAction
    | UpdateDeviceTypeAction
    | UpdateLocationAction
    | UpdateHistoryAction
    | UpdateWindowWidthAction
    | UpdateWindowHeightAction
    | UpdateToolBarAction
    | UpdateProfileDataAction
    | UpdateToastAction
    | UpdateChangesMadeAction
    | UpdateNavPathAction
    | UpdateAlertOpenAction
    | UpdateLoadingAction
    | UpdateNavCallbackAction
    | UpdateGlobalSpinnerStatus
    | SetIsAppTabInFocus
    | SetInitialLoadingProcessingStatus
    | SetFirstAccessibleRoute;
