import { Avatar } from "../avatar/avatar";
import styles from "./DropdownAdminsSelectedItems.module.scss";

export const DropdownAdminsSelectedItems = ({ item: admin, index, count: adminsCount }) => {
    if (admin.value === "all_admins") {
        return <span>All</span>;
    }
    if (index > 4) {
        return null;
    }
    if (index === 4) {
        if (adminsCount > 4) {
            return (
                <div
                    className={styles[`admin-plus`]}
                    style={{
                        position: "relative",
                        marginLeft: "-16px",
                        border: "2px solid white",
                        borderRadius: "100%"
                    }}
                >{`+${adminsCount - 4}`}</div>
            );
        } else {
            return null;
        }
    }
    return (
        <div
            style={{
                position: "relative",
                marginLeft: index === 0 ? 0 : "-16px",
                border: "2px solid white",
                borderRadius: "100%"
            }}
        >
            <Avatar
                avatarUrl={admin?.avatar || admin?.profile_pic?.url}
                user={admin}
                className={styles["selected-avatar"]}
                size={32}
            />
        </div>
    );
};
