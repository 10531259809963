export interface AnalyticsData {
    geoFenceBreakdown: {
        everyone: any;
        geoFence: any;
    };
    severityBreakdown: {
        extreme: string;
        minor: string;
        moderate: string;
        severe: string;
    };
    notificationsSent?: string;
    uniqueUsers: any;
    alertsSent: string;
}

export interface AnalyticsState {
    rightPanelExpanded: boolean;
    analyticsData: AnalyticsData;
}

export const defaultAnalyticsState: AnalyticsState = {
    rightPanelExpanded: false,
    analyticsData: {
        geoFenceBreakdown: {
            everyone: "",
            geoFence: ""
        },
        severityBreakdown: {
            extreme: "",
            minor: "",
            moderate: "",
            severe: ""
        },
        notificationsSent: "",
        uniqueUsers: null,
        alertsSent: ""
    }
};
