// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IProps, Text } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

import { StyledButton } from "src/components";

import "./styles.scss";
import { AppState, AccountSettingsBillingDetailsState, AccountSettingsBillingDetailsItem } from "src/store";
import { bindActionCreators, Dispatch } from "redux";

export interface IBillingSettingsDetailsProps extends IProps {
    details?: AccountSettingsBillingDetailsState;
}

interface IBillingSettingsDetailsState {
    test?: string;
}

class BillingSettingsDetails extends React.Component<IBillingSettingsDetailsProps, IBillingSettingsDetailsState> {
    constructor(props: IBillingSettingsDetailsProps) {
        super(props);
    }

    public render() {
        const { className, details } = this.props;
        const classes = "billing-settings-details";

        return (
            <div className={classNames(classes, className)}>
                <div className={classNames(classes + "-header", Classes.ROW, Classes.DIVISION_7)}>
                    <Text className={classes + "-header-item"}>Invoice ID</Text>
                    <Text className={classes + "-header-item"}>Date Created</Text>
                    <Text className={classes + "-header-item"}>Due Date</Text>
                    <Text className={classes + "-header-item"}>Amount</Text>
                    <Text className={classes + "-header-item"}>Terms</Text>
                    <Text className={classes + "-header-item"}>Status</Text>
                    <div />
                </div>
                <div className={classes + "-list"}>
                    {details &&
                        details.list.map((detail: AccountSettingsBillingDetailsItem, index: number) => (
                            <div
                                className={classNames(classes + "-list-item", Classes.ROW, Classes.DIVISION_7)}
                                key={index}
                            >
                                <Text className={classes + "-list-item-element"}> {detail.invoiceID} </Text>
                                <Text className={classes + "-list-item-element"}> {detail.dateCreated} </Text>
                                <Text className={classes + "-list-item-element"}> {detail.dueDate} </Text>
                                <Text className={classes + "-list-item-element"}> {detail.amount} </Text>
                                <Text className={classes + "-list-item-element"}> {detail.terms} </Text>
                                <Text className={classNames(classes + "-list-item-element", "status")}>
                                    {" "}
                                    {detail.status}{" "}
                                </Text>
                                <StyledButton className={classes + "-list-item-download-button"} text="Download" />
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const details = accountSettings.organization.billing.details;
    return {
        details
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettingsDetails);
