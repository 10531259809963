import {
    AddCollectionData,
    CurrentCollection,
    Collection,
    SelectedCollection,
    CollectionState,
    NewCollectionEntryProps,
    EditNewEntry,
    Entries
} from "src/store";

export const ADD_COLLECTION = "collection-add";
export const UPDATE_COLLECTION = "collection-update";
export const UPDATE_COLLECTION_BOTTOM_TOOLBAR = "collection-update-toolbar";
export const UPDATE_CURRENT_COLLECTION = "studio-update-collection";
export const FETCH_ALL_COLLECTION_UPDATE = "fetch-all-collection";
export const UPDATE_LOADING_ALL_COLLECTION = "update-loading-all-collection";
export const UPDATE_LOADING_COLLECTION_LIST = "update-loading-collection-list";
export const FETCH_SINGLE_COLLECTION_UPDATE = "fetch-single-collection";
export const UPDATE_SELECTED_COLLECTION = "update-selected-collection";
export const DELETE_COLLECTION = "delete-collection";
export const UPDATE_EDIT_COLLECTION = "update-edit-collection";
export const DELETE_ENTRY = "delete-entry";
export const COLLECTION_IS_FULL_LIST_STATUS_UPDATE = "collection-is-full-list-status-update";
export const ADD_ENTRY = "add-entry";
export const EDIT_ENTRY = "edit-entry";
export const UPDATE_ENTRY = "update-entry";
export const UPDATE_ENTRY_LOADING_LIST = "update-entry-loading-list";
export const ENTRY_LOADING = "entry-loading";

interface UpdateCollectionDataAction {
    type: typeof UPDATE_COLLECTION_BOTTOM_TOOLBAR;
    payload: AddCollectionData;
}

interface UpdateCurrentCollectionAction {
    type: typeof UPDATE_CURRENT_COLLECTION;
    payload: Collection;
}

interface EditCollection {
    type: typeof UPDATE_EDIT_COLLECTION;
    payload: CurrentCollection;
}

interface FetchAllCollection {
    type: typeof FETCH_ALL_COLLECTION_UPDATE;
    payload: CollectionState;
}

interface FetchSingleCollection {
    type: typeof FETCH_SINGLE_COLLECTION_UPDATE;
    payload: Collection;
}

interface UpdateSelectedCollectionAction {
    type: typeof UPDATE_SELECTED_COLLECTION;
    payload: SelectedCollection;
}

interface UpdateLoadingAllCollection {
    type: typeof UPDATE_LOADING_ALL_COLLECTION;
    payload: boolean;
}

interface UpdateLoadingCollection {
    type: typeof UPDATE_LOADING_COLLECTION_LIST;
    payload: boolean;
}

interface UpdateCollectionList {
    type: typeof ADD_COLLECTION;
    payload: Collection;
}

interface DeleteCollectionList {
    type: typeof DELETE_COLLECTION;
    payload: { id: string };
}

interface UpdateCollection {
    type: typeof UPDATE_COLLECTION;
    payload: { updatedCollection: Collection; id: number };
}

interface DeleteEntry {
    type: typeof DELETE_ENTRY;
    payload: { entryId: string };
}

interface UpdateCollectionListStatus {
    type: typeof COLLECTION_IS_FULL_LIST_STATUS_UPDATE;
    payload: { collection: boolean; entry: boolean };
}

interface EditEntry {
    type: typeof EDIT_ENTRY;
    payload: EditNewEntry;
}

interface UpdateEntry {
    type: typeof UPDATE_ENTRY;
    payload: { updatedEntry: NewCollectionEntryProps; id: number };
}

interface UpdateEntryLoader {
    type: typeof UPDATE_ENTRY_LOADING_LIST;
    payload: boolean;
}

interface AddEntry {
    type: typeof ADD_ENTRY;
    payload: Entries;
}

interface EntryLoading {
    type: typeof ENTRY_LOADING;
    payload: boolean;
}
export type CollectionActionTypes =
    | UpdateCollectionDataAction
    | UpdateCurrentCollectionAction
    | EditCollection
    | FetchAllCollection
    | UpdateLoadingAllCollection
    | FetchSingleCollection
    | UpdateSelectedCollectionAction
    | UpdateCollectionList
    | DeleteCollectionList
    | UpdateCollection
    | DeleteEntry
    | UpdateCollectionListStatus
    | UpdateEntry
    | EditEntry
    | UpdateLoadingCollection
    | UpdateEntryLoader
    | AddEntry
    | EntryLoading;
