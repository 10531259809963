import {
    STUDIO_TOGGLE_RIGHTPANEL,
    StudioActionTypes,
    UPDATE_FINISHING_TOUCH_DATA,
    UPDATE_ISFULLLIST,
    UPDATE_LOADING_STUDIO
} from "../actions/studio/types";
import { StudioState, defaultStudioState } from "../store";
import { FETCH_ATTACHMENT_BANNER, FETCH_ATTACHMENT_ICON, UPDATE_ATTACHMENT_LOADING } from "src/actions/studio/types";

export const studioReducer = (state: StudioState = defaultStudioState, action: StudioActionTypes): StudioState => {
    switch (action.type) {
        case STUDIO_TOGGLE_RIGHTPANEL:
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        case UPDATE_LOADING_STUDIO:
            return {
                ...state,
                loading: action.payload
            };
        case FETCH_ATTACHMENT_BANNER:
            return {
                ...state,
                loading: false,
                attachments: {
                    icon: state.attachments.icon,
                    banner: action.payload
                }
            };
        case FETCH_ATTACHMENT_ICON:
            return {
                ...state,
                loading: false,
                attachments: {
                    icon: action.payload,
                    banner: state.attachments.banner
                }
            };
        case UPDATE_FINISHING_TOUCH_DATA:
            return {
                ...state,
                finishingTouchData: action.payload
            };
        case UPDATE_ATTACHMENT_LOADING:
            return {
                ...state,
                attachmentLoading: action.payload
            };
        case UPDATE_ISFULLLIST:
            return {
                ...state,
                isFullList: {
                    ...action.payload
                }
            };
        default:
            return state;
    }
};
