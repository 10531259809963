import { useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./UploadFile.module.scss";
import { StyledButton, StyledButtonTypes } from "../../../../../../../components";
import { Classes, MPDIcon } from "../../../../../../../mpd-library";
import { Icon, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { ReactComponent as BigBlueDrawerIcon } from "src/mpd-library/icon/assets/BigBlueDrawer.svg";
import { ReactComponent as FileCSVIcon } from "src/mpd-library/icon/assets/FileCSV.svg";
import { ReactComponent as FileXLSXIcon } from "src/mpd-library/icon/assets/FileXLSX.svg";
import { getContactsImportSpreadSheet } from "../../../../../../../requests/contactsRequests";

export const UploadFile = ({ formik }) => {
    const {
        values,
        values: { file },
        setValues
    } = formik;
    const dropzoneRef = useRef();
    const onDrop = (files) => {
        const { setFieldValue } = formik;
        setFieldValue("file", files[0], true);
        setFieldValue("name", files?.[0].name);

        setValues({
            ...values,
            file: files[0],
            name: files?.[0].name
        });
    };

    const { getRootProps, getInputProps, open, ...remain } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // "application/vnd.ms-excel",
            "text/csv"
        ]
    });

    const formatedFileSize = useMemo(() => {
        const MBytes = Math.round(file?.size / (1024 * 1024));
        if (MBytes > 0) {
            return `${Math.round((file?.size / (1024 * 1024)) * 100) / 100} MB`;
        } else {
            return `${Math.round((file?.size / 1024) * 100) / 100} KB`;
        }
    }, [formik.values?.file?.size]);

    const onFileRemove = () => {
        const { setFieldValue } = formik;
        setFieldValue("file", null);
    };

    return (
        <div className={styles["root"]}>
            {!file ? (
                <div
                    {...getRootProps({
                        className: styles["dropzone"],
                        ref: dropzoneRef
                    })}
                >
                    <input {...getInputProps()} />
                    <MPDIcon IconComponent={BigBlueDrawerIcon} />
                    <div className={styles["label-and-button"]}>
                        <Text className={styles["label-and-button__title"]}>Drag and Drop, or </Text>
                        <StyledButton type={StyledButtonTypes.secondary} text={"Choose File"} onClick={open} />
                    </div>
                </div>
            ) : (
                <div className={classNames(Classes.CARD_BOX_SHADOW, styles["file-card"])}>
                    <MPDIcon IconComponent={file.type?.includes("csv") ? FileCSVIcon : FileXLSXIcon} />
                    <div className={styles["file-card__main-info"]}>
                        <Text className={styles["file-card__main-info__name"]}>{file?.name}</Text>
                        <Text className={styles["file-card__main-info__size"]}>{formatedFileSize}</Text>
                    </div>
                    <button onClick={onFileRemove} className={styles["file-card__remove-button"]}>
                        <Icon icon={"cross"} size={10} color={"white"} />
                    </button>
                </div>
            )}

            <div className={styles["need-help"]}>
                <Text className={styles["need-help__title"]}>Need help getting started?</Text>
                <StyledButton
                    type={StyledButtonTypes.primarySimple}
                    text={"Download sample spreadsheet"}
                    className={styles["need-help__button"]}
                    onClick={getContactsImportSpreadSheet}
                />
            </div>
        </div>
    );
};
