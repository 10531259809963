// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, ISwitchProps, Text } from "@blueprintjs/core";

import { StyledSwitch } from "src/components";

import { Classes } from "src/mpd-library";

import "./styles.scss";

export interface ISettingsControlPropertyProps extends IProps {
    title?: string;
    subtitle?: string;
    switchProps?: ISwitchProps;
    isSwitchLeft?: boolean;
}

export const SettingsControlProperty: React.FunctionComponent<ISettingsControlPropertyProps> = (props) => {
    const { className, title, subtitle, switchProps, isSwitchLeft } = props;

    const classes = isSwitchLeft ? "settings-control-property-left" : "settings-control-property";

    return (
        <div className={classNames(classes, Classes.ROW, className)}>
            {isSwitchLeft ? (
                <>
                    <div className={classes + "-switch-Wrap"}>
                        <StyledSwitch label="" {...switchProps} />
                        <Text className={classes + "-title"}> {title} </Text>
                    </div>
                    <div className={classes + "-wrapper"}>
                        <Text className={classes + "-sub-title"}> {subtitle} </Text>
                    </div>
                </>
            ) : (
                <>
                    <Text className={classes + "-title"}> {title} </Text>
                    <div className={classes + "-wrapper"}>
                        <StyledSwitch label="" {...switchProps} />
                        <Text className={classes + "-sub-title"}> {subtitle} </Text>
                    </div>
                </>
            )}
        </div>
    );
};

export default SettingsControlProperty;
