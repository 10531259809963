import axios from "axios";
import { getConfig } from "../../common";
import { store } from "src/";
import { onUpdateToast } from "../global/global";

export interface ICommonListsRequestsParams {
    limit?: number;
    offest?: number;
    q?: string;
}

// export let axiosInstance = axios.create();

const getAxiosConfig = () => {
    return {
        baseURL: getConfig().API_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth")
        }
    };
};

export let axiosInstance = axios.create(getAxiosConfig());

function isTokenExpiredError(errorResponse: any) {
    const { reason } = errorResponse;
    if (reason === "unauthenticated" || reason === "unauthorized") {
        return true;
    }
    return false;
}

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        error = JSON.parse(JSON.stringify(error));
        const { response } = error;

        if (response && isTokenExpiredError(response.data)) {
            localStorage.removeItem("auth");
            window.location.replace("/signin");
        }

        if (response?.status == 500) {
            store.dispatch(
                onUpdateToast({ type: "error", value: error.message || "Something get wrong. Try again a bit later" })
            );
        }

        return Promise.reject(error);
    }
);

export async function createAxiosInstance() {
    axiosInstance = axios.create(getAxiosConfig());
}

export function refreshAxiosInstance() {
    axiosInstance = axios.create(getAxiosConfig());
}
