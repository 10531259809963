import React, { FunctionComponent, SVGProps } from "react";
import classNames from "classnames";

import { ListHeader, ListHeaderIcons, ContainerEmpty } from "src/components";

import { IMPDSortTypes, IMPDListProps, MPDList } from "src/mpd-library";

import { Props } from "@blueprintjs/core";

import "./styles.scss";
import {
    AccountSettingsUsersExtendingParams,
    AccountSettingsRolesRequestExtendingParams,
    CollectionListParams,
    CardListParams,
    GetAllCollectionEntriesParams
} from "src/requests";
import { ReactComponent as SearchIcon } from "../../mpd-library/icon/assets/search.svg"

interface ISimpleListProps extends Props {
    list: Array<any>;
    emptyElement?: JSX.Element;
    rightElement?: JSX.Element;
    showOptions?: boolean;
    renderedComponent: () => React.ComponentType<any>;
    customHeader?: any;
    filter?: any;
    sort?: Array<string> | string;
    loading?: boolean;
    placeholder?: string;
    isOrSmallerTabletPortraitSize?: boolean;
    hideCheckMarkSelect?: boolean;
    hideSortSelect?: boolean;
    label?: string;
    leftIcon?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    listId?: "usersList" | "rolesList" | "collectionList" | "cardList" | "entriesList";
    onGetListItems?: (
        params:
            | AccountSettingsUsersExtendingParams
            | AccountSettingsRolesRequestExtendingParams
            | CollectionListParams
            | CardListParams
            | GetAllCollectionEntriesParams
    ) => void;
    onGetInitalList?: (
        params:
            | AccountSettingsUsersExtendingParams
            | AccountSettingsRolesRequestExtendingParams
            | CollectionListParams
            | CardListParams
            | GetAllCollectionEntriesParams
    ) => void;
    handleFilterChange?: (filter: string) => void;
    hideHeader?: boolean;
    renderMap?: JSX.Element;
    wrapperRefs?: any;
    loadingMoreComp?: JSX.Element;
    fullListClassName?: string;
}

interface ISimpleListState {
    emptyElement?: JSX.Element;
    listHeaderChecked: boolean;
    showOptions?: boolean;
    list: Array<any>;
    sortOrder: IMPDSortTypes;
    sort?: Array<string> | string;
    needDownloadMore: boolean;
    typingTimeout: NodeJS.Timer;
}

export class SimpleList extends React.Component<ISimpleListProps, ISimpleListState> {
    public FilteredList: any = null;
    public elemForCheckHeight: any;
    public onSearchChangeDebounced: any;
    public renderFilteredList: any;
    public wrapperRefs: any;

    constructor(props: ISimpleListProps) {
        super(props);
        this.onListSelectChange = this.onListSelectChange.bind(this);
        this.onListSortChange = this.onListSortChange.bind(this);

        const { list, showOptions, sort, emptyElement } = this.props;

        const defaultEmptyElement = (
            <ContainerEmpty
                className={"generic-list-empty"}
                title="No Search Results"
                subtitle="We don’t have any results that match the search terms you entered. Please try again."
                IconComponent={SearchIcon}
            />
        );

        this.state = {
            emptyElement: emptyElement || defaultEmptyElement,
            sortOrder: "asc",
            sort,
            listHeaderChecked: false,
            showOptions,
            list,
            needDownloadMore: true,
            typingTimeout: setTimeout(() => null, 300)
        };
    }

    // public componentDidUpdate(prevProps: ISimpleListProps, prevState: ISimpleListState) {
    //     const { list } = this.props;
    //     const { needDownloadMore } = this.state;
    //     if (list !== prevProps.list && needDownloadMore && this.wrapperRefs) {
    //         this.onBottomScroll();
    //         const bottom = this.wrapperRefs.getBoundingClientRect().bottom;
    //         this.setState({ needDownloadMore: bottom < window.innerHeight });
    //     }
    // }

    public render() {
        const {
            filter,
            className,
            placeholder,
            hideSortSelect,
            hideCheckMarkSelect,
            isOrSmallerTabletPortraitSize,
            loading,
            rightElement,
            label,
            leftIcon,
            IconComponent,
            customHeader,
            hideHeader,
            renderMap,
            list,
            showOptions,
            loadingMoreComp,
            isListFullyLoaded,
            renderedComponent
        } = this.props;

        const { emptyElement, listHeaderChecked, sort, sortOrder } = this.state;

        const classes = "simple-list";
        if (!this.FilteredList) {
            this.FilteredList = MPDList(renderedComponent, loadingMoreComp) as React.ComponentClass<IMPDListProps>;
        }
        const FilteredList = this.FilteredList;
        const filteredListProps = {
            className: classNames(classes + "-filtered-list", className),
            filter,
            list,
            sort,
            loading,
            isListFullyLoaded,
            emptyElement,
            sortOrder,
            onScrollBottom: this.onBottomScroll
        };
        return (
            <div className={classNames(classes, className)} ref={(ref) => (this.wrapperRefs = ref)}>
                {!hideHeader && (
                    <ListHeader
                        className={classes + "-list-header"}
                        leftElement={
                            showOptions ? <ListHeaderIcons hideDetails={isOrSmallerTabletPortraitSize} /> : undefined
                        }
                        selectChange={this.onListSelectChange}
                        onSearchChange={this.onSearchChange}
                        label={label}
                        IconComponent={IconComponent}
                        hideCheckMarkSelect={hideCheckMarkSelect}
                        hideSortSelect={hideSortSelect}
                        sortOrder={sortOrder}
                        sortChange={this.onListSortChange}
                        checked={listHeaderChecked}
                        placeholder={placeholder}
                        rightElement={rightElement}
                        customHeader={customHeader}
                        searchValue={filter}
                    />
                )}

                {renderMap ? (
                    <div className={classes + "-map-contain-wrapper"}>
                        {renderMap}
                        <FilteredList {...filteredListProps} />
                    </div>
                ) : (
                    <>
                        <FilteredList {...filteredListProps} />
                    </>
                )}
            </div>
        );
    }

    private onBottomScroll = () => {
        const { onGetListItems, listId } = this.props;
        const { sortOrder } = this.state;
        let params:
            | AccountSettingsUsersExtendingParams
            | AccountSettingsRolesRequestExtendingParams
            | CollectionListParams
            | CardListParams
            | GetAllCollectionEntriesParams;

        if (!onGetListItems) {
            return;
        }

        if (listId && listId === "usersList") {
            params = { sortFirstName: sortOrder };
        } else if (listId && listId === "collectionList") {
            params = {
                updatedAt: sortOrder,
                q: this.props.filter
            };
        } else if (listId && listId === "cardList") {
            params = {
                order: sortOrder,
                q: this.props.filter
            };
        } else if (listId && listId === "entriesList") {
            params = {
                order: sortOrder,
                q: this.props.filter
            };
        } else {
            params = { sortTitle: sortOrder };
        }
        onGetListItems(params);
    };

    private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { handleFilterChange } = this.props;
        const value = event.currentTarget.value;
        const filter = value;
        if (handleFilterChange) {
            handleFilterChange(filter);
        }
        const { onGetInitalList, listId } = this.props;
        const { sortOrder } = this.state;
        let params:
            | AccountSettingsUsersExtendingParams
            | AccountSettingsRolesRequestExtendingParams
            | CollectionListParams
            | CardListParams
            | GetAllCollectionEntriesParams;

        if (!onGetInitalList) {
            return;
        }

        if (listId && listId === "usersList") {
            params = { sortFirstName: sortOrder, query: filter };
        } else if (listId && listId === "collectionList") {
            params = {
                q: filter,
                order: sortOrder
            };
        } else if (listId && listId === "cardList") {
            params = {
                q: filter,
                order: sortOrder,
                offset: 0
            };
        } else if (listId && listId === "entriesList") {
            params = {
                q: filter
            };
        } else {
            params = { sortTitle: sortOrder, query: filter };
        }
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            needDownloadMore: true,
            typingTimeout: setTimeout(() => {
                onGetInitalList(params);
            }, 500)
        });
    };

    private onListSelectChange(event: React.ChangeEvent) {
        const { list } = this.state;
        const select = event.currentTarget.textContent;
        let showOptions = false;
        if (select && select.includes("All")) {
            for (let i = 0; i < list.length; i++) {
                list[i].checked = true;
            }
            showOptions = true;
        } else if (select && select.includes("None")) {
            for (let i = 0; i < list.length; i++) {
                list[i].checked = false;
            }
        }
        this.setState({ list, showOptions });
    }

    private onListSortChange(event: React.ChangeEvent) {
        const { listId, onGetInitalList, filter } = this.props;
        const sort = event.currentTarget.textContent;
        let sortOrder: any;
        if (sort && sort.includes("A-Z")) {
            sortOrder = IMPDSortTypes.asc;
        }
        if (sort && sort.includes("Z-A")) {
            sortOrder = IMPDSortTypes.desc;
        }

        let params:
            | AccountSettingsUsersExtendingParams
            | AccountSettingsRolesRequestExtendingParams
            | CollectionListParams
            | CardListParams;

        this.setState({ sortOrder });

        if (!onGetInitalList) {
            return;
        }
        if (listId && listId === "usersList") {
            params = { sortFirstName: sortOrder, query: filter };
        } else if (listId && listId === "collectionList") {
            params = {
                q: filter,
                sortTitle: sortOrder
            };
        } else if (listId && listId === "cardList") {
            params = {
                q: filter,
                sortTitle: sortOrder,
                offset: 0
            };
        } else {
            params = { sortTitle: sortOrder, query: filter };
        }

        onGetInitalList(params);
    }
}
