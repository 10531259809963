import omit from "lodash/omit";
import { useCallback, useState } from "react";

export const useContactsTagsHook = () => {
    const [tempSelectedTags, setSelectedTags] = useState<any>({ allIds: [], byId: {} });
    const onTagItemClick = useCallback(
        (tag: any, checked: boolean) => {
            if (checked) {
                setSelectedTags({
                    allIds: [...tempSelectedTags.allIds, tag.id],
                    byId: {
                        ...tempSelectedTags.byId,
                        [tag.id]: tag
                    }
                });
            } else {
                setSelectedTags({
                    allIds: tempSelectedTags.allIds.filter((tagId) => tagId !== tag.id),
                    byId: { ...omit(tempSelectedTags.byId, tag.id) }
                });
            }
        },
        [tempSelectedTags]
    );

    return {
        selectedTags: tempSelectedTags,
        onTagItemClick
    };
};
