// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Classes, MPDFloatingInput, IMPDFloatingInputProps } from "src/mpd-library";

import { Text } from "@blueprintjs/core";

import { StyledFloatingInputTypes } from "./types";

import "./styles.scss";

export interface IStyledFloatingInputProps extends IMPDFloatingInputProps {
    error?: string;
    floatingType?: StyledFloatingInputTypes;
    moreInfo?: string;
    inputRef?: any;
    showErrorText?: boolean;
    isMaxLengthRequire?: boolean;
    placeholder?: string;
    hint?: string;
}

export const StyledFloatingInput: React.FunctionComponent<IStyledFloatingInputProps> = (props) => {
    const { className, disabled, error, showErrorText = true, floatingType, moreInfo, ...remaining } = props;

    const classes = classNames(
        "styled-floating-input",
        floatingType,
        disabled && Classes.DISABLED,
        className,
        error && Classes.ERROR
    );

    return (
        <div className={classes}>
            <MPDFloatingInput disabled={disabled} {...remaining} />
            {moreInfo && <Text className={classNames("more-info", moreInfo && Classes.ACTIVE)}>{moreInfo}</Text>}
            {error && showErrorText && (
                <Text className={classNames("error", error && Classes.ACTIVE)}>
                    {error.charAt(0).toUpperCase() + error.slice(1)}
                </Text>
            )}
        </div>
    );
};
