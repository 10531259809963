import { memo } from "react";
import "./styles.scss";

interface TopToolbarProps {
    leftElement?: JSX.Element;
    rightElement?: JSX.Element;
    centerElement?: JSX.Element;
    className?: string;
}

export const TopToolbar = memo(({ leftElement, rightElement, centerElement, className }: TopToolbarProps) => {
    return (
        <div className={`top-toolbar-main-wrapper ${className}`}>
            {leftElement && leftElement}
            {centerElement && centerElement}
            {rightElement && rightElement}
        </div>
    );
});