// tslint:disable
import React, { Suspense } from "react";
import { AccountSettings, UserOnBoarding } from "src/containers";
import SideBar from "../containers/sidebar/sidebar";

import { globalActions, websocketActions } from "../actions";

import { isAuthenticated } from "src/components";

import { LoginState } from "../containers";

import { Route, Switch, RouteComponentProps, withRouter } from "react-router-dom";

import "./style.scss";
import { AppState, ViewEnum } from "../store";
import { connect } from "react-redux";
import { axiosInstance } from "src/actions";
import { getConfig } from "src/common";
import { bindActionCreators, Dispatch } from "redux";
import { setIsAppTabInFocus } from "src/actions/global/global-api";
import {
    hasAccessToAccountSettings,
    hasAccessToAlerts,
    hasAccessToAnalytics,
    hasAccessToConversations,
    hasAccessToStudio,
    hasAccessToContacts,
    versionCompare
} from "src/actions/common/utils";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { NotFound } from "src/components/not-found/not-found";
import { AssetsGallery } from "src/mpd-library";
import NotificationsPopover from "src/components/notifications-popover/notifications-popover";

import heartbeat_worker_script from "src/web-workers/heartBeatWorker";
import reconnectToWebSocketScript from "src/web-workers/reconnectToWebscoketWorker";
import ProtectedRoute from "src/components/protected-route/protected-route";
import ErrorBoundary from "src/components/error-boundary/error-boundary";
import moment from "moment";
import packageJson from "../../package.json";
import { lazyWithRetry, resetCacheUtil } from "src/components/lazy-with-retry/lazy-with-retry";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "../mpd-library/common/responsive-styles/index.scss";
import { ContactsContextProvider } from "../containers/contacts/contacts-list/context";
// import "react-window/styles.css";

const Alerts = lazyWithRetry(() => import("src/containers/alerts/alerts" /* webpackChunkName: "alerts-flow" */));
const Analytics = lazyWithRetry(
    () => import("src/containers/analytics/analytics" /* webpackChunkName: "analytics-flow" */)
);

const UserSettings = lazyWithRetry(
    () => import("src/containers/user-settings/user-settings" /* webpackChunkName: "user-setings-flow" */)
);
const Studio = lazyWithRetry(() => import("src/containers/studio/studio" /* webpackChunkName: "studio-flow" */));
const Conversations = lazyWithRetry(
    () => import("src/containers/conversations/conversations" /* webpackChunkName: "conversations-flow" */)
);
const Contacts = lazyWithRetry(() => import("src/containers/contacts"));

const SignIn = lazyWithRetry(() => import("../containers/signin/signin"));
const SignOut = lazyWithRetry(() => import("../containers/signout/signout"));

moment.defineLocale("en-cust2", {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        ss: "%d secs",
        m: "a min ago",
        mm: "%d mins ago",
        h: "an hour",
        hh: "%dh ago",
        d: "a day ago",
        dd: "%d days ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
    }
});

interface AppComponentProps extends RouteComponentProps<{}> {
    webSocket: WebSocket | null;
    webSocketTopics: Array<string>;
    webSocketStatus: string | number | undefined;
    displayGlobalSpinner: boolean;
    mobileSideBarActive: boolean;
    viewType: ViewEnum;
    conversations_enabled: boolean;
    onUpdateWindowWidth: (innerWidth: number) => void;
    onUpdateWindowHeight: (innerHeight: number) => void;
    onUpdateDeviceType: () => void;
    onGetAccountSettingsDetails: () => void;
    updateWebSocket: (socket: any) => void;
    onGetDataFromSocket: (data: any) => void;
    subscribeToTopic: (topic: string) => void;
    onUpdateWebScoketStatus: (status: string | number) => void;
    setIsAppTabInFocus: (status: boolean) => void;
    isMobileSize: boolean;
    initialLoadingProcessingStatus: boolean;
    firstAccessibleRoute: string;
    authenticated: boolean;
}

interface AppComponentState {
    loginState?: LoginState;
    forceSignout?: boolean;
    storeState?: AppState;
    firstAccessibleRoute: string;
}

class AppComponent extends React.Component<AppComponentProps, AppComponentState> {
    public intervalId: any;
    public connectInterval;
    public webSocketHeartbeatWorker;
    public recconectToWebSocketWorker;
    public constructor(props: AppComponentProps) {
        super(props);

        this.state = {
            forceSignout: false,
            firstAccessibleRoute: "/signin"
        };
    }

    public async componentDidMount() {
        const { onUpdateDeviceType } = this.props;

        this.clearCacheData();
        this.onResize();
        onUpdateDeviceType();

        window.addEventListener("focus", () => {
            this.props.setIsAppTabInFocus(true);
        });
        window.addEventListener("blur", () => {
            this.props.setIsAppTabInFocus(false);
        });

        window.addEventListener("online", this.connectSocket);

        if (isAuthenticated("auth")) {
            this.connectSocket();
        }

        this.webSocketHeartbeatWorker = new Worker(heartbeat_worker_script);
        this.recconectToWebSocketWorker = new Worker(reconnectToWebSocketScript);

        window.addEventListener("offline", () => {
            this.webSocketHeartbeatWorker.postMessage("offline");
        });

        if (typeof window !== "undefined") {
            window.addEventListener("storage", (e) => {
                if (!isAuthenticated("auth") && e.url !== "/signin") {
                    this.setState({ forceSignout: true });
                }
            });
        }
    }

    public componentDidUpdate(prevProps: Readonly<AppComponentProps>): void {
        if (prevProps.authenticated !== this.props.authenticated && !!this.props.authenticated) {
            this.connectSocket();
        }
    }

    // shouldComponentUpdate(nextProps: AppComponentProps) {
    //     if (this.props.webSocketStatus !== nextProps.webSocketStatus) {
    //         return false;
    //     }
    //     if (this.props.webSocket !== nextProps.webSocket) {
    //         return false;
    //     }
    //     return true;
    // }

    public connectSocket = async () => {
        // const that = this;
        const { webSocket } = this.props;
        if (!webSocket || webSocket?.readyState == WebSocket.CLOSED) {
            try {
                const {
                    updateWebSocket,
                    onGetDataFromSocket,
                    webSocketTopics,
                    subscribeToTopic,
                    onUpdateWebScoketStatus
                } = this.props;

                clearTimeout(this.connectInterval);
                this.connectInterval = undefined;

                const result = await axiosInstance.post("/websocket/auth");

                const { ws_jwt } = result.data;
                const socketUrl = getConfig().SOCKET_URL;
                const socket = new WebSocket(`${socketUrl}${ws_jwt}`);
                updateWebSocket(socket);

                socket.onopen = () => {
                    this.timeout = 250;

                    this.webSocketHeartbeatWorker.onmessage = () => {
                        const hearbeatPayload = JSON.stringify({
                            topic: `phoenix`,
                            event: "heartbeat",
                            payload: {},
                            ref: null
                        });

                        socket.send(hearbeatPayload);
                    };

                    this.webSocketHeartbeatWorker.postMessage("heartbeat");

                    onUpdateWebScoketStatus(socket.readyState);
                    subscribeToTopic(`portal_admin_conversations`);
                    subscribeToTopic(`portal_admin_general`);
                    if (webSocketTopics.length) {
                        webSocketTopics.forEach((topicName: string) => {
                            subscribeToTopic(topicName);
                        });
                    }
                };

                socket.onmessage = (event: any) => {
                    const wsData = JSON.parse(event.data);
                    console.log("WS DATA >>>>", wsData);
                    onGetDataFromSocket(wsData);
                };

                socket.onclose = () => {
                    onUpdateWebScoketStatus(socket.readyState);

                    this.recconectToWebSocketWorker.postMessage({
                        action: "tryReconnect",
                        message: "Socket is closed. Reconnect will be attempted in 5s"
                    });

                    this.webSocketHeartbeatWorker.postMessage("offline");

                    this.recconectToWebSocketWorker.onmessage = () => {
                        this.connectSocket();
                    };
                };

                socket.onerror = () => {
                    socket.close();
                    onUpdateWebScoketStatus(socket.readyState);
                };
            } catch (err) {
                this.recconectToWebSocketWorker.postMessage({
                    action: "tryReconnect",
                    message: "Socket is error to connect. Reconnect will be attempted in 5s"
                });
                this.webSocketHeartbeatWorker.postMessage("offline");
            }
        }
    };

    public componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        const { webSocket } = this.props;
        webSocket?.close();
    }

    public clearCacheData = () => {
        const appVersion = localStorage.getItem("AppVersion");
        const versionsCompare = appVersion ? versionCompare(appVersion, packageJson.version) : 1;
        if (versionsCompare !== 0 && !!appVersion) {
            localStorage.setItem("AppVersion", packageJson.version);
            resetCacheUtil();
        }

        if (!appVersion) {
            localStorage.setItem("AppVersion", packageJson.version);
        }
    };

    public render() {
        const {
            displayGlobalSpinner,
            mobileSideBarActive,
            isMobileSize,
            initialLoadingProcessingStatus,
            contacts_enabled,
            conversations_enabled
        } = this.props;
        const { forceSignout } = this.state;

        if (initialLoadingProcessingStatus) {
            return (
                <div
                    id="app-container"
                    style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center" }}
                >
                    <PageLoadingSpinner />
                </div>
            );
        }

        return (
            <div id="app-container">
                {displayGlobalSpinner && <div className="global-spinner-wrapper" />}
                {isAuthenticated("auth") && <SideBar mobileActive={mobileSideBarActive} />}

                <ErrorBoundary>
                    <Switch>
                        <Route
                            path="/signin"
                            render={(routeProps) => (
                                <Suspense fallback={<PageLoadingSpinner />}>
                                    <SignIn routeProps={routeProps} {...(this.props as any)} />{" "}
                                </Suspense>
                            )}
                        />

                        <ProtectedRoute path={"/"} exact />
                        <ProtectedRoute
                            component={Alerts}
                            path="/alerts"
                            onCheckPermissionsToRoute={hasAccessToAlerts}
                        />
                        {conversations_enabled && (
                            <ProtectedRoute
                                key={"/conversations"}
                                component={Conversations}
                                path="/conversations"
                                onCheckPermissionsToRoute={hasAccessToConversations}
                            />
                        )}
                        {contacts_enabled && (
                            <ProtectedRoute
                                key={"/contacts"}
                                component={Contacts}
                                path={"/contacts"}
                                exact={false}
                                onCheckPermissionsToRoute={hasAccessToContacts}
                            />
                        )}
                        {isMobileSize && (
                            <ProtectedRoute component={NotificationsPopover} path="/notifications" storageItem="auth" />
                        )}
                        <ProtectedRoute
                            component={Analytics}
                            path="/analytics"
                            onCheckPermissionsToRoute={hasAccessToAnalytics}
                        />
                        <ProtectedRoute
                            component={Studio}
                            path="/studio"
                            onCheckPermissionsToRoute={hasAccessToStudio}
                        />
                        {process.env.REACT_APP_STAGE === "development" && (
                            <Route render={() => <AssetsGallery />} path="/assets" />
                        )}
                        {/* {process.env.REACT_APP_STAGE === "development" ? (
                            <Route
                                render={(routeProps) => (
                                    <IncidentManagement routeProps={routeProps} {...(this.props as any)} />
                                )}
                                path="/incidents"
                            />
                        ) : (
                            <PrivateRoute
                                component={IncidentManagement as any}
                                path="/incidents"
                                redirectPath="/signin"
                                storageItem="auth"
                            />
                        )} */}
                        <Route
                            render={(routeProps) => <UserOnBoarding routeProps={routeProps} {...(this.props as any)} />}
                            path={["/login/restore_password", "/login/confirm_account"]}
                        />
                        <Route
                            render={(routeProps) => <UserOnBoarding routeProps={routeProps} {...(this.props as any)} />}
                            path="/login/restore_password"
                        />
                        {/* <PrivateRoute
                        path="/signout"
                        redirectPath="/signin"
                        storageItem="auth"
                        forceSignout={forceSignout}
                        component={SignOut as any}
                    /> */}
                        <ProtectedRoute path="/signout" forceSignout={forceSignout} component={SignOut} />
                        <ProtectedRoute
                            key={"/user-settings"}
                            component={UserSettings}
                            path="/user-settings"
                            forceSignout={forceSignout}
                        />
                        <ProtectedRoute
                            component={AccountSettings}
                            path="/account-settings"
                            onCheckPermissionsToRoute={hasAccessToAccountSettings}
                        />
                        <Route render={() => <NotFound />} />
                    </Switch>
                </ErrorBoundary>
            </div>
        );
    }

    private onResize = () => {
        const { onUpdateWindowWidth, onUpdateWindowHeight } = this.props;
        onUpdateWindowWidth(window.innerWidth);
        onUpdateWindowHeight(window.innerHeight);
    };
}

const mapStateToProps = (state: AppState) => {
    const {
        websocketReducer: { webSocket, webSocketTopics, webSocketStatus },
        global: {
            displayGlobalSpinner,
            mobileSideBarActive,
            isMobileSize,
            initialLoadingProcessingStatus,
            firstAccessibleRoute
        },
        accountSettings: {
            organization: { conversations_enabled, contacts_enabled }
        },
        conversations: { viewType },
        auth: { authenticated }
    } = state;

    return {
        contacts_enabled,
        conversations_enabled,
        displayGlobalSpinner,
        webSocket,
        webSocketTopics,
        webSocketStatus,
        mobileSideBarActive,
        viewType,
        isMobileSize,
        initialLoadingProcessingStatus,
        firstAccessibleRoute,
        authenticated
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateWindowWidth: globalActions.onUpdateWindowWidth,
            onUpdateWindowHeight: globalActions.onUpdateWindowHeight,
            onUpdateDeviceType: globalActions.onUpdateDeviceType,
            updateWebSocket: websocketActions.updateWebSocket,
            onGetDataFromSocket: websocketActions.onGetDataFromSocket,
            subscribeToTopic: websocketActions.subscribeToTopic,
            onUpdateWebScoketStatus: websocketActions.onUpdateWebScoketStatus,
            setIsAppTabInFocus: setIsAppTabInFocus
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent));
