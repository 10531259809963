import { Dropdown } from "src/mpd-library/dropdown/dropdown";
import { FILTER_SORT_PLACES_VALUES } from "../contastants";
import styles from "../FilterDropdownContent.module.scss";
import { MPDSelectItem, MPDIcon } from "src/mpd-library";
import { ReactComponent as PlusWhiteIcon } from "src/mpd-library/icon/assets/plus-white.svg";
import { Classes, Position } from "@blueprintjs/core";
import { getAccountSettingsMapLayersRequest } from "../../../../../../requests";
import {
    StyledAlert,
    StyledAlertChildrenWrapperClasses,
    StyledAlertTypes,
    StyledRadioGroup
} from "../../../../../../components";
import { useEffect, useState } from "react";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import StyledMap from "../../../../../../components/styled-map";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../store";
import { getAccountSettingsJurisdiction } from "../../../../../../actions/account-settings/account-settings-api";

export const PlacesFilters = ({ selectedValue, setSelectedValue, selectedValueMatch, setSelectedValueMatch }) => {
    const [isCustomMapLayerAlertOpen, setIsCustomMapLayerAlertOpen] = useState<boolean>(false);
    const { jurisdiction } = useSelector((state: AppState) => state.accountSettings.organization);
    const { center } = useSelector((state: AppState) => state.accountSettings.organization.details);
    const [customLayer, setCustomLayer] = useState(selectedValueMatch?.value?.value || null);
    const dispatch = useDispatch();

    const onCustomLayerSelect = () => {
        setIsCustomMapLayerAlertOpen(true);
    };

    useEffect(() => {
        if (!jurisdiction) {
            dispatch(getAccountSettingsJurisdiction());
        }
    }, []);

    const onCustomLayerComplete = (shape) => {
        setCustomLayer(shape);
    };

    const closeCustomLayerAlert = () => {
        setIsCustomMapLayerAlertOpen(false);
    };

    const onConfirm = () => {
        setSelectedValueMatch({ meta: { name: "Custom Layer" }, value: { value: customLayer, type: "geo_json" } });
    };
    const onSelectExistingLayer = (option) => {
        setSelectedValueMatch({
            label: option.title,
            meta: { name: option.title },
            value: {
                value: option.id,
                type: "map_layer_id"
            }
        });
    };

    return (
        <>
            <StyledAlert
                isOpen={isCustomMapLayerAlertOpen}
                globalContentProps={{ hideDescription: true, title: "Draw Custom Map Layer" }}
                type={StyledAlertTypes.primary}
                childrenWrapperClassName={StyledAlertChildrenWrapperClasses.withoutPadding}
                onClose={closeCustomLayerAlert}
                onCancel={closeCustomLayerAlert}
                onConfirm={onConfirm}
            >
                <div style={{ width: "600px", height: "400px" }}>
                    <StyledMap
                        center={center}
                        className={styles["styled-map"]}
                        showDrawControls={true}
                        showGps={true}
                        showInfoBox={false}
                        showZoomControls={true}
                        showSearchBox={true}
                        onShapeComplete={onCustomLayerComplete}
                        jurisdictionGeoJsonData={jurisdiction}
                        geoJsonData={selectedValueMatch?.value?.value}
                        // onUpdateToast={onUpdateToast}
                        // mapLayersSubtitle={mapLayersSubtitle}
                        // onScrollBottom={onScrollBottom}
                        // onSearchScrollBottom={onSearchScrollBottom}
                        // onSearchAccountSettingsMapLayers={onSearchAccountSettingsMapLayers}
                        // dataFromGeoFenceSwitchProps={dataFromGeoFenceSwitchProps}
                        drawingMode="polygon"
                        // loadingMapLayers={loadingMapLayers}
                        // intersectWithGeoFence={intersectWithGeoFence}
                        // isAllPoligonsBlue={isAllPoligonsBlue}
                    />
                </div>
            </StyledAlert>
            <StyledRadioGroup
                className={styles["default"]}
                selectedValue={selectedValue.comparison}
                list={FILTER_SORT_PLACES_VALUES.map((item) => {
                    const { isComparisonValueEditable, label, value, comparison } = item;
                    return {
                        className: styles["radio-item"],
                        onClick: () => {
                            setSelectedValue(item);
                        },
                        value: comparison,
                        children: (
                            <div className={styles["radio-content"]}>
                                {label}
                                {isComparisonValueEditable && selectedValue.comparison === comparison && (
                                    <Dropdown
                                        ArrowIconComponent
                                        onStaticOptionClick={() => null}
                                        contentBlockWidth={209}
                                        contentBlockHeight={300}
                                        selectedValue={
                                            selectedValueMatch
                                                ? { label: selectedValueMatch?.meta?.name }
                                                : { label: "Select Map Layer" }
                                        }
                                        targetClassName={DropdownTargetClasses.FILTER_COMPARISON_SELECT}
                                        position={Position.BOTTOM_LEFT}
                                        fetchDataRequest={getAccountSettingsMapLayersRequest}
                                        entityKey={"layers"}
                                        CustomOptionsComponent={(props) => {
                                            return (
                                                <>
                                                    <div className={styles["create-new-entity-header"]}>
                                                        <MPDSelectItem
                                                            LeftIconComponent={() => (
                                                                <div className={styles["plus-circle"]}>
                                                                    <MPDIcon IconComponent={PlusWhiteIcon} />
                                                                </div>
                                                            )}
                                                            // option={{ label: "Create New List" }}
                                                            label={`Draw Map Layer`}
                                                            onClick={() => {
                                                                onCustomLayerSelect();
                                                                props.closeDropdown();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={styles["devider"]} />
                                                    {props.options.allIds.map((id) => {
                                                        const layer = props.options.byId[id];
                                                        return (
                                                            <MPDSelectItem
                                                                // option={{ label: "Create New List" }}
                                                                className={id === "loading" ? Classes.SKELETON : ""}
                                                                label={layer?.title}
                                                                onClick={() => {
                                                                    onSelectExistingLayer(layer);
                                                                    props.closeDropdown();
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </>
                                            );
                                        }}
                                        // staticOptions={[]}
                                        // {...dropdownTypeRelatedProps}
                                    />
                                )}
                            </div>
                        )
                    };
                })}
            />
        </>
    );
};
