import { ANALYTICS_TOGGLE_RIGHTPANEL, ANALYTICS_UPDATE, AnalyticsActionTypes } from "../actions/analytics/types";
import { AnalyticsState, defaultAnalyticsState } from "../store";

export const analyticsReducer = (state: AnalyticsState = defaultAnalyticsState, action: AnalyticsActionTypes) => {
    switch (action.type) {
        case ANALYTICS_TOGGLE_RIGHTPANEL:
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        case ANALYTICS_UPDATE:
            return {
                ...state,
                analyticsData: action.payload
            };
        default:
            return state;
    }
};
