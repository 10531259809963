import { Position, Text } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Avatar } from "src/components";
import { MPDIcon, States } from "src/mpd-library";
import { ConversationQueue } from "src/store";
import styles from "./styles.module.scss";
import { TooltipContent } from "../tooltip-content/TooltipContent";
import { ReactComponent as InboxIcon } from "../../../../../../mpd-library/icon/assets/inbox-icon.svg"

const classname = "queue-item";

type QueueItemProps = {
    queue: ConversationQueue;
    defaultLogo: boolean;
    defaultQueue?: boolean;
};

export const QueueItem = ({ queue, defaultQueue }: QueueItemProps) => {
    const history = useHistory();
    const [itemState, setItemState] = useState(null);
    const timerId = useRef<NodeJS.Timeout>();

    const onQueueClick = () => {
        history.push(`/account-settings/queues/${queue.id}/details`);
    };

    const onHover = () => {
        clearTimeout(timerId.current);
        if (itemState !== States.selected) {
            setItemState(States.hover);
        }
    };

    const onLeave = () => {
        timerId.current = setTimeout(() => {
            if (itemState !== States.selected) {
                setItemState(States.default);
            }
        }, 150);
    };

    return (
        <div className={styles[classname]} onClick={onQueueClick}>
            <div className={styles[`${classname}-left-wrapper`]}>
                <div
                    className={styles[`${classname}-quque-logo`]}
                    style={{ backgroundColor: queue.is_default ? "#1F76FF" : queue.color }}
                >
                    {queue.is_default && <MPDIcon IconComponent={InboxIcon} />}
                    {!queue.is_default && (
                        <Text className={styles[`${classname}-queue-name-first-letter`]}>{queue.name.charAt(0)}</Text>
                    )}
                </div>
                <div className={styles[`${classname}-name-and-description-wrapper`]}>
                    <Text className={styles[`${classname}-queue-name`]}>{queue.name}</Text>
                    {queue.description && (
                        <Text className={styles[`${classname}-queue-description`]}>{queue.description}</Text>
                    )}
                    {defaultQueue && (
                        <Text className={styles[`${classname}-queue-description`]}>
                            Your default queue is set to route all conversations to all agents
                        </Text>
                    )}
                </div>
            </div>

            <Popover2
                portalClassName={styles[`${classname}-admins-tooltip-portal`]}
                isOpen={itemState === States.hover}
                content={
                    <div
                        className={styles[`${classname}-admins-tooltip-list`]}
                        onMouseEnter={onHover}
                        onMouseLeave={onLeave}
                    >
                        <TooltipContent queueId={queue.id} initialAdmins={queue.admins} />
                    </div>
                }
                className={styles[`${classname}-admins-tooltip`]}
                position={Position.BOTTOM}
            >
                <div
                    className={styles[`${classname}-avatars-wrapper`]}
                    // style={{ width: `${queue.admins.length > 4 ? "140px" : `${queue.admins.length * 32}px`}` }}
                    onMouseEnter={onHover}
                    onMouseLeave={onLeave}
                >
                    {queue.admins.slice(0, 4).map((queueAdmin, index) => {
                        const { admin } = queueAdmin;
                        return (
                            <div
                                style={{
                                    // position: "relative",
                                    marginLeft: index === 0 ? 0 : "-16px",
                                    border: "2px solid white",
                                    borderRadius: "100%",
                                    zIndex: `${index}`
                                }}
                            >
                                <Avatar
                                    avatarUrl={admin.profile_pic?.url}
                                    user={admin}
                                    className={styles[`${classname}-avatar`]}
                                    key={admin.id}
                                />
                            </div>
                        );
                    })}
                    {queue.admins_count > 4 && (
                        <Text
                            className={styles[`${classname}-admin-plus`]}
                            style={{
                                // position: "relative",
                                marginLeft: "-16px",
                                border: "2px solid white",
                                borderRadius: "100%",
                                zIndex: 5,
                                width: "32px",
                                height: "32px"
                            }}
                        >{`+${queue.admins_count - 4}`}</Text>
                    )}
                </div>
            </Popover2>

            {/* <MPDSelect
                className={`rounded-dropdown ${styles[`${classname}-dropdown`]} ${
                    styles[`${classname}-dropdown-more`]
                }`}
                customOptions={
                    // <DropdownContent
                    //     content={() => (

                    <div className={`simple-dropdwon`}>
                        {moreDropdownItems.map((option: any) => {
                            return (
                                <MPDSelectItem className={styles[`${classname}-item`]} onClick={option.onClick}>
                                    <>
                                        <Text>{option.label}</Text>
                                    </>
                                </MPDSelectItem>
                            );
                        })}
                    </div>
                }
                // />
                arrowIcon={null}
                leftIcon={"verticalTripleLine"}
                position={Position.BOTTOM_RIGHT}
                value={""}
                cannotChange={true}
                onChange={onThreeDotButtonSelectOption}
            /> */}
        </div>
    );
};
