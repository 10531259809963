import { store } from "src";
import { AccountPrivilege, PriviligeHrid } from "src/requests";

const accountSettingsHrids = [
    "admin_settings_read_write",
    "admin_users_read_write",
    "admin_roles_read_write",
    "admin_jurisdiction_read_write",
    "admin_geo_layers_read_write",
    "can_view_audit_logs",
    "admin_channels_read_write",
    "conversations_manage_settings"
];

const accountSettingsHRIDSmatchRoute = {
    admin_settings_read_write: "/account-settings/account-details",
    admin_users_read_write: "/account-settings/users",
    admin_roles_read_write: "/account-settings/roles",
    admin_jurisdiction_read_write: "/account-settings/jurisdiction",
    admin_geo_layers_read_write: "/account-settings/map-layers",
    can_view_audit_logs: "/account-settings/audit-log",
    admin_channels_read_write: "/account-settings/channels",
    conversations_manage_settings: "/account-settings/profile-details"
};

export const hasAccessToContacts = () => {
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    return privileges.some((privilege) => privilege.feature_hrid === "contacts_access_all");
};

export const hasAccessToAlerts = () => {
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    const alertsFeaturesHrids = [
        "alerts_minor_create",
        "alerts_moderate_create",
        "alerts_severe_create",
        "alerts_extreme_create"
    ];
    return alertsFeaturesHrids.some((hrid: PriviligeHrid) =>
        privileges.some((privilege) => privilege.feature_hrid === hrid)
    );
};

export const hasAccessToAnalytics = () => {
    const analyticFeatureHrid = "alert_analytics_read";
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    return privileges.some((privilege) => privilege.feature_hrid === analyticFeatureHrid);
};

export const hasAccessToConversations = () => {
    const conversations_enabled: boolean = store.getState().accountSettings?.organization?.conversations_enabled;
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    const conversationsFeaturesHrids = [
        "conversations_access_assigned",
        "conversations_access_enqueued",
        "conversations_access_all"
    ];
    return (
        conversationsFeaturesHrids.some((hrid: PriviligeHrid) =>
            privileges.some((privilege) => privilege.feature_hrid === hrid)
        ) && conversations_enabled
    );
};

export const hasAccessToStudio = () => {
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    const studioFeaturesHrids = [
        "studio_view_my_cards_and_collections",
        "studio_view_all_cards_and_collections",
        "studio_publish_cards"
    ];
    return studioFeaturesHrids.some((hrid: PriviligeHrid) =>
        privileges.some((privilege) => privilege.feature_hrid === hrid)
    );
};

export const hasAccessToAccountSettings = () => {
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;

    return accountSettingsHrids.some((hrid: PriviligeHrid) =>
        privileges.some((privilege) => privilege.feature_hrid === hrid)
    );
};

export const getFirstAccessiblleAccountSettingsRoute = () => {
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    const privilege: AccountPrivilege = privileges.find(
        (privilege: AccountPrivilege) => !!accountSettingsHRIDSmatchRoute[privilege.feature_hrid]
    );

    return accountSettingsHRIDSmatchRoute[privilege?.feature_hrid];
};

export const getFirstAccessibleRoute = (): string => {
    const privileges: Array<AccountPrivilege> = store.getState().userSettings?.privileges;
    const routes = [
        { checkAccessFunction: hasAccessToAlerts, route: "/alerts" },
        { checkAccessFunction: hasAccessToConversations, route: "/conversations" },
        { checkAccessFunction: hasAccessToStudio, route: "/studio" },
        { checkAccessFunction: hasAccessToAnalytics, route: "/analytics" },
        { checkAccessFunction: hasAccessToAccountSettings, route: getFirstAccessiblleAccountSettingsRoute() }
    ];

    const foundRoute = routes.find((route) => !!route.checkAccessFunction(privileges))?.route;

    return !foundRoute ? "/user-settings/my-info" : foundRoute;
};

export const microtime = (getAsFloat, fixAfterComma) => {
    const now = (Date.now ? Date.now() : new Date().getTime()) / 1e3;

    if (getAsFloat) {
        if (fixAfterComma) {
            return parseFloat(now.toFixed(6));
        }
        return now;
    }

    return now;
};

export const compareMicroseconds = (value1, value2) => {
    return value1 * 1e6 - value2 * 1e6;
};

export function versionCompare(v1: string, v2: string, options?: any) {
    const lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend;
    let v1parts = v1.split(".");
    let v2parts = v2.split(".");

    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }

        if (v1parts[i] == v2parts[i]) {
            continue;
        } else if (v1parts[i] > v2parts[i]) {
            return 1;
        } else {
            return -1;
        }
    }

    if (v1parts.length != v2parts.length) {
        return -1;
    }

    return 0;
}
