import { Position, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { onUpdateToast } from "src/actions/global/global";
import { MPDIcon, MPDSelect, MPDSelectItem } from "src/mpd-library";
import { ConversationsSnippet, ConversationsSnippetCategory } from "src/requests/conversationsRequests";
import { ReactComponent as SnippetsBigIcon } from "../../../../../../mpd-library/icon/assets/snippets-icon-big.svg";
import { ReactComponent as VerticalTripleLineIcon } from "../../../../../../mpd-library/icon/assets/vertical-triple-line.svg";
import styles from "./styles.module.scss";

const classname = "snippet-item";

type SnippetItemProps = {
    snippet: ConversationsSnippet;
    category: ConversationsSnippetCategory;
    deleteSnippetFromList: (id: string) => void;
};

export const SnippetItem = ({ snippet, category, deleteSnippetFromList }: SnippetItemProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onDeleteClick = async () => {
        dispatch(onUpdateToast({ value: "Snippet was sucessfully deleted", type: "saved" }));
        deleteSnippetFromList(snippet.id);
    };

    const onUpdateClick = () => {
        history.push(`/account-settings/snippets/${snippet.id}/edit`);
    };

    const moreDropdownItems = useMemo(
        () => [
            {
                label: "Edit",
                onClick: onUpdateClick
            },
            {
                label: "Delete",
                onClick: onDeleteClick
            }
        ],
        [snippet.id]
    );
    return (
        <div className={styles[classname]}>
            <div className={styles[`${classname}-left-wrapper`]}>
                <MPDIcon IconComponent={SnippetsBigIcon} />
                <div className={styles[`${classname}-main-info`]}>
                    <div className={styles[`${classname}-name-and-category-wrapper`]}>
                        <Text className={styles[`${classname}-name`]}>{snippet.name}</Text>
                        <div className={styles[`${classname}-category`]}>{category.name}</div>
                    </div>
                    <Text className={styles[`${classname}-snippet-text`]}>{snippet.text}</Text>
                </div>
            </div>
            <div className={styles[`${classname}-right-wrapper`]}>
                <MPDSelect
                    className={classNames(
                        "rounded-dropdown",
                        styles[`${classname}-dropdown`],
                        styles[`${classname}-dropdown-more`],
                        "more-dropdown"
                    )}
                    arrowIcon={null}
                    ArrowIconComponent={VerticalTripleLineIcon}
                    disableArrowAnimation
                    position={Position.BOTTOM_RIGHT}
                    customOptions={
                        <div className={`simple-dropdwon`}>
                            {moreDropdownItems.map((option: any) => {
                                return (
                                    <MPDSelectItem className={styles[`${classname}-item`]} onClick={option.onClick}>
                                        <>
                                            <Text>{option.label}</Text>
                                        </>
                                    </MPDSelectItem>
                                );
                            })}
                        </div>
                    }
                />
            </div>
            {/* <FormWasChanged formik={Formik} /> */}
        </div>
    );
};
