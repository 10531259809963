/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export const StyledButtonTypes = {
    primary: "primary" as const,
    secondary: "secondary" as const,
    primarySimple: "primary-simple" as const,
    delete: "delete" as const,
    fillSvg: "fill-svg" as const,
    deleteSimple: "delete-simple" as const
};

export type StyledButtonTypes = typeof StyledButtonTypes[keyof typeof StyledButtonTypes];
