import { Store } from "redux";
import { AppState } from "src/store";
import { IToastType, ToastTypes } from "src/components";
import { updateToast } from "src/actions/global/global-api";

export const collectionDeleteErrorHandler = (store: Store<AppState>, resErrors?: any) => {
    const toast: IToastType = {
        value: resErrors.reason,
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));
    return;
};
