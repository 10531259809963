export function sortArrayByProperty(property: string, asc?: string) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    if (asc === "desc") {
        return (a: any, b: any) => {
            const properties = property.split(".");
            let resultA = a[properties[0]];
            let resultB = b[properties[0]];
            for (let i = 1; i < properties.length; i++) {
                resultA = resultA[properties[i]];
                resultB = resultB[properties[i]];
            }
            const result =
                resultA.toLowerCase() > resultB.toLowerCase().toLowerCase()
                    ? -1
                    : resultA.toLowerCase() < resultB.toLowerCase()
                    ? 1
                    : 0;
            return result * sortOrder;
        };
    }
    return (a: any, b: any) => {
        const properties = property.split(".");
        let resultA = a[properties[0]];
        let resultB = b[properties[0]];
        for (let i = 1; i < properties.length; i++) {
            resultA = resultA[properties[i]];
            resultB = resultB[properties[i]];
        }
        const result =
            resultA.toLowerCase() < resultB.toLowerCase() ? -1 : resultA.toLowerCase() > resultB.toLowerCase() ? 1 : 0;
        return result * sortOrder;
    };
}

export function sortByMultipleProperties(sortArr: Array<string>, asc?: string) {
    const arrCopy = sortArr.slice();
    return (obj1: any, obj2: any) => {
        let i = 0;
        let result = 0;
        const numberOfProperties = arrCopy.length;
        while (result === 0 && i < numberOfProperties) {
            result = sortArrayByProperty(arrCopy[i], asc)(obj1, obj2);
            i++;
        }
        return result;
    };
}

export function filterArrByProperty(array: any, filter: string, filterProperies?: Array<string>) {
    return array.filter((listItem: any) => {
        return filterItems(listItem, filter, filterProperies);
    });
}

function filterItems(item: any, filter: any, filterProperies?: Array<string>) {
    return Object.keys(item).some((property: any) => {
        if (item !== null && item !== undefined && item[property] !== null && item[property] !== undefined) {
            if (filterProperies) {
                return (
                    item[property].toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                    filterProperies.indexOf(property) > -1
                );
            } else {
                return item[property].toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }
        } else {
            return false;
        }
    });
}
