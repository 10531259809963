import { createSelector } from "reselect";
import differenceBy from "lodash/differenceBy";
import { AppState, Conversation, ConversationTag, ViewEnum } from "src/store";

export const getExisitingTags = createSelector(
    [
        (state: AppState): any => state.conversations.selectedConversationId,
        (state: AppState): any => state.conversations.conversations
    ],
    (selectedConversationId: string, conversations: Array<Conversation>): any => {
        const necessaryConversation = conversations.byId[selectedConversationId];
        const existingTagsById = {};
        const existingTagsIds = [];
        necessaryConversation.tags.forEach((tag) => {
            existingTagsById[tag.id] = tag;
            existingTagsIds.push(tag.id);
        });
        return { existingTagsById, existingTagsIds };
        // return necessaryConversation ? necessaryConversation.tags : [];
    }
);

export const getNotSelectedTagsTags = (
    existingTags: Array<ConversationTag>,
    tags: Array<ConversationTag>,
    searchValue: string
) => {
    let filteredTags = [];
    filteredTags = tags.reduce(function reduceCB(accumulator, tag) {
        let nodes = [];
        if (tag.nodes?.length) {
            nodes = [...tag.nodes.reduce(reduceCB, [])];
        }

        const isCurrentNameOverlapping = !!tag.name?.toLowerCase().includes(searchValue.toLowerCase());

        if (nodes?.length) {
            return [
                ...accumulator,
                {
                    ...tag,
                    nodes
                }
            ];
        }

        if (isCurrentNameOverlapping) {
            return tag.nodes?.length ? [...accumulator, tag] : differenceBy([...accumulator, tag], existingTags, "id");
        }

        return accumulator;
    }, []);
    return filteredTags;
};

// Should be removed after updating back end

export const getTags = createSelector(
    getExisitingTags,
    (state: AppState) => state.conversations.tags,
    (_, searchValue) => searchValue,
    getNotSelectedTagsTags
);

export const getTagsForFilter = createSelector(
    (state: AppState) => state.conversations.tags,
    (_, params) => {
        return params;
    },
    (tags, params) => getNotSelectedTagsTags(params.existingTagsForSelector, tags, params.searchValue)
);

// ---------------------------------------------

export const getAllTags = createSelector(
    (state: AppState) => state.conversations.tags,
    (_, searchValue: string) => searchValue,
    (tags: Array<ConversationTag>, searchValue: string) => {
        return tags.filter((tag: ConversationTag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
);

export const getConversationsIdsByViewType = createSelector(
    (state: AppState) => state.conversations.viewType,
    (state: AppState) => state.conversations.conversations,
    (_, view?: ViewEnum) => view,
    (viewType, conversations, view) => {
        return conversations[view ? view : viewType];
    }
);

export const getSelectedConversationId = (state: AppState): string => state.conversations.selectedConversationId;
export const getConversationMessagesList = (state: AppState) => state.conversationMessages.conversationMessagesList;
export const getSlelectedConversationMessageList = createSelector(
    getSelectedConversationId,
    getConversationMessagesList,
    (selectedConversationId: string, conversationMessagesList) => conversationMessagesList[selectedConversationId]
);

export const getSelectedConversationAndId = createSelector(
    getSelectedConversationId,
    (state: AppState) => state.conversations.conversations.byId,
    (selectedConversationId: string, conversationsDetails) => {
        return {
            selectedConversationId,
            selectedConversation: conversationsDetails[selectedConversationId] || {}
        };
    }
);

export const getConversationById = createSelector(
    (state: AppState) => state.conversations.conversations.byId,
    (_, conversationId: string) => conversationId,
    (conversationsById, conversationId) => conversationsById[conversationId]
);

export const getConversationsMessagesSelector = createSelector(
    getSlelectedConversationMessageList,
    (conversationMessagesList) => conversationMessagesList?.messages?.allIds
);

export const getConversationsMessagesById = createSelector(
    getSlelectedConversationMessageList,
    (conversationMessagesList) => conversationMessagesList?.messages?.byId
);

export const getConversationsMessagesHasMoreOldest = createSelector(
    getSlelectedConversationMessageList,
    (conversationMessagesList) => conversationMessagesList?.hasMoreOlder
);

export const getConversationsMessagesHasMoreLatest = createSelector(
    getSlelectedConversationMessageList,
    (conversationMessagesList) => {
        return conversationMessagesList?.hasMoreLater;
    }
);

export const getConversationUserIdSelector = createSelector(
    getSelectedConversationAndId,
    ({ selectedConversation: conversation }) => conversation.user
);

export const senderSelector = () =>
    createSelector(
        (state: AppState) => state.conversations.conversations.byId,
        (state: AppState) => state.conversations.conversationsUsers.byId,
        (_, conversationId) => conversationId,
        (conversationsById, conversationsUsersById, conversationId) =>
            conversationsUsersById[conversationsById[conversationId]?.user]
    );
