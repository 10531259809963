/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */ 
export const StyledSelectTypes = {
    primary: "primary" as const,
    simple: "simple" as const,
    solid: "solid" as const,
    noLine: "no-line" as const,
    image: "image" as const,
    hideArrow: "hide-arrow",
    fillSvg: "fill-svg" as const
};

export type StyledSelectTypes = typeof StyledSelectTypes[keyof typeof StyledSelectTypes];
