// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";

export const ListHeader: React.FunctionComponent<IProps> = (props) => {
    const { className } = props;
    const classes = "data-export-settings-list-header";
    return (
        <div className={classNames(classes, className)}>
            <Text className={classNames(classes + "-item", "activity")}>Export</Text>
            <div className={classes + "-inner-container"}>
                <Text className={classNames(classes + "-item", "file-size")}>File Size</Text>
                <Text className={classNames(classes + "-item", "export-data")}>Export ID</Text>
                <Text className={classNames(classes + "-item", "status")}>Status</Text>
                <Text className={classNames(classes + "-item", "result")}>Result</Text>
                <Text className={classNames(classes + "-item", "exported-by")}>Exported By</Text>
            </div>
        </div>
    );
};

export default ListHeader;
