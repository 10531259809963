import { Spinner, Text } from "@blueprintjs/core";
import { useCallback, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { BreadCrumbs, EmptyBlock, StyledButton, StyledButtonTypes } from "src/components";
import { ConversationQueue } from "src/store";
import { QueueItem } from "../components/queue-item/queue-item";
import styles from "./styles.module.scss";
import { onUpdateToolBar } from "src/actions/global/global";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import useInfiniteScroll from "src/containers/conversations/components/chat/useInfiniteScroll";
import { navigate } from "src/actions/global/global-api";
import { QueuesContext } from "../queues";
import { ReactComponent as SecondaryQueuesEmptyIcon } from "src/mpd-library/icon/assets/secondaryQueuesEmpty.svg";

const classname = "queues";

export const QueuesList = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const { queues, hasMore: hasMoreQueues, getQueuesProcessing, onGetQueues } = useContext(QueuesContext);

    const { secondaryQueues, primaryQueues } = useMemo(() => {
        const primaryQueues = queues.filter((queue: ConversationQueue) => !!queue.is_default);
        const secondaryQueues = queues.filter((queue: ConversationQueue) => !queue.is_default);
        return { secondaryQueues, primaryQueues };
    }, [queues]);

    const [bottomSentryRef] = useInfiniteScroll({
        loading: getQueuesProcessing,
        hasNextPage: hasMoreQueues,
        onLoadMore: useCallback(async () => {
            onGetQueues(true);
        }, [secondaryQueues.length, getQueuesProcessing])
    });

    const onNewQueueClick = () => {
        history.push("/account-settings/queues/create");
    };

    useMemo(() => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Queues" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            ),
            rightElement: (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primary}
                    text={"New queue"}
                    onClick={onNewQueueClick}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    }, []);

    if (getQueuesProcessing && !secondaryQueues.length) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[classname]}>
            <div className={styles[`${classname}-list-wrapper`]}>
                <Text className={styles[`${classname}-list-label`]}>PRIMARY QUEUE</Text>
                {primaryQueues.map((queue: ConversationQueue) => {
                    return <QueueItem defaultLogo queue={queue} defaultQueue key={queue.id} />;
                })}
                <Text className={styles[`${classname}-list-label`]}>SECONDARY QUEUES</Text>
                {!!secondaryQueues.length &&
                    secondaryQueues.map((queue: ConversationQueue) => {
                        return <QueueItem defaultLogo queue={queue} key={queue.id} />;
                    })}
                {!secondaryQueues.length && !getQueuesProcessing && (
                    <EmptyBlock
                        title={"No secondary queues have been added yet"}
                        subtitle={"Click the “New Queue” button on the top right to add a new queue"}
                        IconComponent={SecondaryQueuesEmptyIcon}
                    />
                )}
                {(!!getQueuesProcessing && !!secondaryQueues.length) ||
                    (hasMoreQueues && (
                        <div ref={bottomSentryRef} className={styles[`${classname}-load-more-spinner-wrapper`]}>
                            <Spinner intent="primary" size={30} />
                        </div>
                    ))}
            </div>
        </div>
    );
};
