import { getAccountSettingsUsersRequest } from "src/requests";
import { Dispatch } from "react";
import {
    checkIfConversationSatisfyAppliedFilter,
    convertFilterValues,
    getRequiredFiltersForBE,
    getRightViews,
    sortConversations
} from "./utils";
import { onUpdateToast } from "../global/global";
import {
    // acceptConversationAction,
    acceptFilterValues,
    AcceptFilterValuesTypes,
    deleteSavedView,
    setAdmins,
    setConversations,
    setConversationsCounter,
    setConversationsCounts,
    setHasMoreConversations,
    setHasMorePreviousConversations,
    setPreviousConversationsWithSender,
    updateConversation,
    setSelectedConversationId,
    setSelectedQueueId,
    setSelectedViewData,
    setViews,
    setViewType,
    updateConversationsLoadingStatus,
    setBasedOnPermissionsAdmins,
    removeConversationFromList,
    updateConversationsIdsList,
    updateConversationsUser
} from "./conversations";

import { AppState, Conversation, ViewEnum, ViewData } from "src/store";
import { ConversationsActionTypes } from "./types";
import {
    addMessage,
    clearMessages,
    removeTyping,
    setConversationMessages,
    setShouldJumpToBottom,
    setShouldShowJumpToNewMessages,
    updateMessage
} from "../conversationsMessages/conversationsMessages";
import {
    acceptConversationRequest,
    applyTagsRequest,
    assignConversationToAdminRequest,
    blockUserRequest,
    deleteViewRequest,
    getConversationDetailsRequest,
    getConversationsRequest,
    getCountsRequest,
    getQueueDetails,
    getViewsRequest,
    reopenConversationRequest,
    replaceToQueueRequest,
    resolveConversationRequest,
    returnToQueueRequest,
    searchConversationsRequest,
    snoozeConversationRequest,
    spamConversationRequest,
    starConversationRequest,
    starUserRequest,
    updateConversationLastSeenTs
} from "src/requests/conversationsRequests";
import {
    getConversationById,
    getConversationsIdsByViewType,
    getConversationUserIdSelector,
    getSelectedConversationAndId
} from "src/selectors";
import { compareMicroseconds } from "../common/utils";
import moment from "moment";
import { getQueuesAdmins } from "../../requests/conversationsRequests";

export const getCounts = () => async (dispatch) => {
    const {
        data: { counts }
    } = await getCountsRequest();
    dispatch(setConversationsCounts(counts));
};

export const onGetNewConversation = (data: any, isNew?: boolean) => async (dispatch, getState: () => AppState) => {
    const store = getState();
    const conversationsIds = getConversationsIdsByViewType(store, data.conversation.status) || [];
    const conversationsById = store.conversations.conversations.byId;
    const selectedOrder = store.conversations.conversationFilter.filterValues.order;
    const selectedAlert = store.alerts.alert.selectedAlert;

    const conversationsToSet = conversationsIds.filter((conversationId) => conversationId !== data.conversation.id);

    isNew && dispatch(getCounts());
    dispatch(updateConversation(data.conversation));
    if (checkIfConversationSatisfyAppliedFilter(data.conversation)) {
        const updatedConversationsIds = sortConversations(
            [data.conversation.id, ...conversationsToSet],
            selectedOrder,
            { ...conversationsById, [data.conversation.id]: data.conversation }
        );

        dispatch(updateConversationsIdsList(updatedConversationsIds));
    }

    if (data.conversation.referrer.id === selectedAlert.uuid) {
        const repliesIds = store.conversations.conversations.alertReplies;
        const repliesToSet = repliesIds.filter((conversationId) => conversationId !== data.conversation.id);
        const updatedConversationsIds = sortConversations([data.conversation.id, ...repliesToSet], "updated_at:desc", {
            ...conversationsById,
            [data.conversation.id]: data.conversation
        });

        dispatch(updateConversationsIdsList(updatedConversationsIds, "alertReplies"));
    }
};

export const blockUser = (value: number | null, blocked: boolean) => async (dispatch, getState: () => AppState) => {
    const {
        selectedConversation: { user }
    } = getSelectedConversationAndId(getState());

    const requestBody = {
        blocked,
        blocked_until: value
    };

    const res = await blockUserRequest(user, requestBody);

    dispatch(updateConversationsUser(res.data.user));
    dispatch(onUpdateToast({ value: blocked ? "User was blocked" : "User was unblocked", type: "saved" }));
};

export const starUser = (starred: boolean) => async (dispatch, getState: () => AppState) => {
    const userId = getConversationUserIdSelector(getState());

    const res = await starUserRequest(userId, starred);

    dispatch(updateConversationsUser(res.data.user));
    dispatch(
        onUpdateToast({
            value: starred ? "The user was added to favorites" : "The user was removed from favorites",
            type: "saved"
        })
    );
};

export const spamConversation = () => async (dispatch, getState: () => AppState) => {
    const store = getState();
    const {
        selectedConversationId,
        selectedConversation: { status: selectedConversationView }
    } = getSelectedConversationAndId(store);
    const { counts } = store.conversations;
    const res = await spamConversationRequest(selectedConversationId);
    dispatch(removeConversationFromList(res.data.conversation.id));
    dispatch(updateConversation(res.data.conversation));
    dispatch(
        setConversationsCounts({
            [selectedConversationView]: counts[selectedConversationView] - 1,
            resolved: counts.resolved + 1
        })
    );
    dispatch(setSelectedConversationId(null));
};

export const getConversationsViews = () => async (dispatch) => {
    const res = await getViewsRequest();
    dispatch(setViews(res.data.views));
};

export const starConversation = () => async (dispatch, getState) => {
    const { selectedConversationId, selectedConversation } = getSelectedConversationAndId(getState());

    const res = await starConversationRequest(selectedConversationId, !selectedConversation.starred);

    const {
        data: {
            conversation: { starred }
        }
    } = res;
    dispatch(updateConversation(res.data.conversation));
    dispatch(
        onUpdateToast({
            value: starred ? "The conversation was added to favourites" : "The conversation was removed from favorites",
            type: "saved"
        })
    );
};

export const snoozeConversation = (timestamp: number) => async (dispatch, getState) => {
    const { selectedConversationId } = getSelectedConversationAndId(getState());

    const res = await snoozeConversationRequest(selectedConversationId, timestamp);
    await dispatch(getConversations(true));
    await dispatch(getCounts());
    dispatch(updateConversation(res.data.conversation));
};

export const acceptConversation = (conversationId: string) => async (dispatch, getState: () => AppState) => {
    try {
        const counts = getState().conversations.counts;
        dispatch(setViewType("assigned"));
        dispatch(updateConversationsLoadingStatus(true));
        const res = await acceptConversationRequest(conversationId);
        dispatch(removeConversationFromList(conversationId, "opened"));
        dispatch(updateConversation(res.data.conversation));
        dispatch(setConversationsCounts({ assigned: counts.assigned + 1, inbox: counts.inbox - 1 }));
        // dispatch(setSelectedConversationId(conversationId));
        await dispatch(getConversations(true));
    } finally {
        dispatch(updateConversationsLoadingStatus(false));
    }
};

const addMessageFromSocket =
    (dataFromSocket: any) => (dispatch: Dispatch<ConversationsActionTypes>, getState: () => AppState) => {
        const state = getState();
        const { selectedConversationId } = getSelectedConversationAndId(state);
        const isChatScrolledToBottom = state.conversationMessages.isBottom;
        const { message, conversation } = dataFromSocket;
        const currentUserId = state.userSettings.myInfo.uuid;
        const { fromIdAreNew: fromIdAreNewOld, messages: { allIds: messagesAllIds = [] } = {} } =
            state.conversationMessages.conversationMessagesList[dataFromSocket.conversation.id] || {};

        const setConversationsMessagesPayload = {
            selectedConversationId: dataFromSocket.conversation.id,
            conversationMessagesList: {
                fromIdAreNew: fromIdAreNewOld ? fromIdAreNewOld : messagesAllIds[0]
            }
        };

        if (selectedConversationId === dataFromSocket.conversation.id) {
            !isChatScrolledToBottom &&
                currentUserId !== dataFromSocket.message?.sender?.id &&
                dispatch(setConversationMessages(setConversationsMessagesPayload));
        } else {
            currentUserId !== dataFromSocket.message?.sender?.id &&
                dispatch(setConversationMessages(setConversationsMessagesPayload));
        }

        if (state.conversationMessages.conversationMessagesList[conversation.id]?.messages?.byId[message.id]) {
            dispatch(updateMessage(message, conversation.id));
        } else if (messagesAllIds.length) {
            dispatch(addMessage(message, conversation.id));
        }
    };

export const onGetConversationMessageFromSocket =
    (dataFromSocket: any) => async (dispatch: Dispatch<ConversationsActionTypes>, getState: () => AppState) => {
        try {
            const state = getState();
            const isChatScrolledToBottom = state.conversationMessages.isBottom;
            const { selectedConversation, selectedConversationId } = getSelectedConversationAndId(state);
            const conversationCurrentData = getConversationById(state, dataFromSocket.conversation.id);
            const currentUserId = state.userSettings.myInfo.uuid;
            const ifThisChatSelected = dataFromSocket.conversation.id === selectedConversationId;

            dispatch(removeTyping(dataFromSocket.conversation.id, dataFromSocket.typing_id));
            dispatch(addMessageFromSocket(dataFromSocket));
            if (
                !conversationCurrentData ||
                moment(conversationCurrentData?.updated_at).valueOf("milliseconds") <
                    moment(dataFromSocket.conversation.updated_at).valueOf("milliseconds")
            ) {
                dispatch(
                    onGetNewConversation({
                        conversation: {
                            ...(selectedConversation || {}),
                            ...dataFromSocket.conversation,
                            ...(dataFromSocket.conversation.id === selectedConversationId
                                ? {
                                      unread_count:
                                          isChatScrolledToBottom && ifThisChatSelected
                                              ? 0
                                              : dataFromSocket.conversation.unread_count
                                  }
                                : {})
                        }
                    })
                );
            }

            ifThisChatSelected &&
                dispatch(
                    setShouldShowJumpToNewMessages(
                        selectedConversationId,
                        !!(!isChatScrolledToBottom && selectedConversation)
                    )
                );
            if (isChatScrolledToBottom && ifThisChatSelected) {
                if (currentUserId !== dataFromSocket.message?.sender?.id) {
                    dispatch(setShouldJumpToBottom(selectedConversationId, true));
                }
                await dispatch(setLastSeen(dataFromSocket.message.ts, dataFromSocket.conversation.id));
            }
        } catch (err) {
            console.log("onGetConversationMessageFromSocket err >>>>", err);
        }
    };

export const reopenConversation =
    (id: string) =>
    async (dispatch: Dispatch<ConversationsActionTypes>): Promise<void> => {
        try {
            dispatch(updateConversationsLoadingStatus(true));
            dispatch(setViewType("assigned"));
            const res = await reopenConversationRequest(id);
            dispatch(removeConversationFromList(id, "resolved"));
            dispatch(updateConversation(res.data.conversation));
            await dispatch(getConversations(true));
        } finally {
            dispatch(updateConversationsLoadingStatus(false));
        }
    };

export const returnToQueue = (queueId?: string) => async (dispatch, getState: () => AppState) => {
    const { selectedConversationId, counts } = getState().conversations;
    await returnToQueueRequest(selectedConversationId, queueId);
    dispatch(removeConversationFromList(selectedConversationId, "assigned"));
    dispatch(setConversationsCounts({ assigned: counts.assigned - 1, inbox: counts.inbox + 1 }));
    dispatch(setSelectedConversationId(""));
};

export const assignConversationToAdmin = (adminId?: string) => async (dispatch, getState) => {
    try {
        const { selectedConversationId, viewType } = getState().conversations;
        const res = await assignConversationToAdminRequest(selectedConversationId, adminId);
        const { conversation } = res.data;
        dispatch(updateConversation(conversation));
        viewType === "assigned" && dispatch(removeConversationFromList(conversation.id));
    } catch (err) {
        console.error("ASSIGN ADMIN ERROR >>>>", err);
    }
};

export type GetAdminsFilterValuesEnum =
    | "conversations"
    | "conversations_access_all"
    | "conversations_access_same_queues";

export const getAdmins =
    ({ queueId, limit, offset, filter, withInvited, withReset, q }) =>
    async (dispatch: Dispatch<ConversationsActionTypes>, getState: () => AppState) => {
        const admins = getState().conversations.admins.adminsList;
        let res;
        if (queueId) {
            res = await getQueuesAdmins(queueId, { limit, offset, q });
        } else {
            res = await getAccountSettingsUsersRequest({
                limit,
                offset,
                order: "first_name:asc,last_name:asc",
                q,
                filter,
                with_invited: withInvited
            });
        }

        const { users, has_more } = res.data;
        withReset ? dispatch(setAdmins([...users], has_more)) : dispatch(setAdmins([...admins, ...users], has_more));
    };

export const getSelectedConversation =
    (id: string) =>
    async (dispatch: Dispatch<ConversationsActionTypes>, getState: () => AppState): Promise<Conversation> => {
        const state = getState();
        const { viewType } = state.conversations;
        const { selectedConversation } = getSelectedConversationAndId(state);
        const res = await getConversationDetailsRequest(id);
        const { conversation } = res.data;
        dispatch(
            updateConversation(
                viewType === "mentions"
                    ? {
                          ...conversation,
                          last_message_summary: selectedConversation.last_message_summary,
                          last_message: selectedConversation.last_message
                      }
                    : conversation
            )
        );
        return conversation;
    };

export const applyTagsChanges = (tags: Array<string>) => async (dispatch, getState) => {
    const { selectedConversationId } = getState().conversations;
    const res = await applyTagsRequest(selectedConversationId, tags);
    dispatch(updateConversation(res.data.conversation));
    return res;
};

export const setLastSeen = (ts, conversationId) => async (dispatch, getState) => {
    const conversation = getConversationById(getState(), conversationId);

    if (compareMicroseconds(conversation.last_seen_ts, ts) > 0) {
        return;
    }

    const res = await updateConversationLastSeenTs(conversation.id, ts);
    dispatch(updateConversation({ id: conversationId, last_seen_ts: ts, unread_count: res.data.unread_count }));
};

export const getUserPrevoiusConversations =
    (userId: string) => async (dispatch: Dispatch<any>, getState: () => AppState) => {
        const prevConversationsWithSender = getState().conversations.prevConversationsWithSender.list;
        const requestParams: any = {
            limit: 10,
            filters: btoa(JSON.stringify({ user_id: userId })),
            offset: prevConversationsWithSender.length,
            view: "all"
        };

        const res = await getConversationsRequest(requestParams);

        dispatch(setPreviousConversationsWithSender([...prevConversationsWithSender, ...res.data.conversations]));
        dispatch(setHasMorePreviousConversations(res.data.has_more));
    };

export const onFilterApply = (view: AcceptFilterValuesTypes) => async (dispatch, getState: () => AppState) => {
    try {
        const { globalView: global_view } = getState().conversations;
        dispatch(updateConversationsLoadingStatus(true));
        dispatch(acceptFilterValues(view, true));

        const { id: view_id, filters: filterValues } = view;

        const requestParams: any = {
            limit: 10,
            global_view
        };

        if (view_id) {
            requestParams.view_id = view_id;
        } else {
            const convertedFilterValues = getRequiredFiltersForBE(convertFilterValues(filterValues));
            requestParams.filters = window.btoa(JSON.stringify(convertedFilterValues));
        }

        const res = await getConversationsRequest(requestParams);
        const viewTypeToSet = res.data.view;

        dispatch(setHasMoreConversations(res.data.has_more));

        dispatch(setViewType(viewTypeToSet));
        dispatch(setConversationsCounts(res.data.counts));
        dispatch(setConversations(res.data.conversations, false, true));
    } catch (err) {
        console.log("FILTER ERROR >>>>", err);
    } finally {
        dispatch(updateConversationsLoadingStatus(false));
    }
};

export const searchConversations = (searchQuery: string, getMore?: boolean) => async (dispatch, getState) => {
    try {
        const { conversations } = getState().conversations;
        const requestParams: any = {
            limit: 10,
            q: searchQuery,
            offset: getMore ? conversations.search?.length : 0
        };

        if (!searchQuery) {
            delete requestParams.q;
        }

        const res = await searchConversationsRequest(requestParams);

        const hasMoreConversations = !!res.data.conversations.length && res.data.conversations.length % 10 === 0;

        dispatch(setHasMoreConversations(hasMoreConversations));
        dispatch(setConversations(res.data.conversations, true, !getMore));
    } finally {
        dispatch(updateConversationsLoadingStatus(false));
    }
};

export const getConversations =
    (refresh?: boolean) => async (dispatch: Dispatch<ConversationsActionTypes>, getState: () => AppState) => {
        try {
            const {
                conversations,
                conversationFilter,
                view_id,
                viewType: type,
                selectedQueueId,
                globalView: global_view,
                viewType
            } = getState().conversations;

            const requestParams: any = {
                limit: 10,
                global_view
            };

            if (conversationFilter.isFilterApplyed && !view_id) {
                const convertedFilterValues = getRequiredFiltersForBE(
                    convertFilterValues(conversationFilter.filterValues)
                );
                requestParams.filters = btoa(JSON.stringify(convertedFilterValues));
            }

            if (view_id) {
                requestParams.view_id = view_id;
            }

            if (selectedQueueId) {
                requestParams.queue_id = selectedQueueId;
            }

            if (type) {
                requestParams.view = type;
            }

            const res = await getConversationsRequest({
                ...requestParams,
                offset: refresh ? 0 : conversations[viewType]?.length || 0
            });

            dispatch(setHasMoreConversations(res.data.has_more));
            dispatch(setConversationsCounts(res.data.counts));

            dispatch(setConversations(res.data.conversations, null, refresh));
        } catch (err) {
            return err;
        }
    };

export const onQueueSelect = (queueId: string) => async (dispatch, getState) => {
    try {
        dispatch(updateConversationsLoadingStatus(true));
        dispatch(setSelectedQueueId(queueId));

        const { viewType, globalView: global_view } = getState().conversations;

        const requestParams: any = {
            limit: 10,
            queue_id: queueId,
            view: viewType,
            global_view
        };

        const res = await getConversationsRequest(requestParams);

        const hasMoreConversations = !!res.data.conversations.length && res.data.conversations.length % 10 === 0;

        dispatch(setHasMoreConversations(hasMoreConversations));

        dispatch(setViewType(res.data.view));
        dispatch(setConversationsCounts(res.data.counts));
        dispatch(setConversations(res.data.conversations, false, true));
    } finally {
        dispatch(updateConversationsLoadingStatus(false));
    }
};

export const onViewSelect = (view: ViewData, viewToLoad?: ViewEnum) => async (dispatch, getState: () => AppState) => {
    try {
        const { globalView: global_view } = getState().conversations;
        dispatch(updateConversationsLoadingStatus(true));
        dispatch(setSelectedViewData(view));
        dispatch(setViewType(view.value));

        const requestParams: any = {
            limit: 10,
            view: viewToLoad || view.value,
            global_view
        };

        const res = await getConversationsRequest(requestParams);

        dispatch(setViewType(getRightViews(res.data.view)));
        dispatch(setHasMoreConversations(res.data.has_more));
        dispatch(setConversationsCounts(res.data.counts));
        dispatch(setConversations(res.data.conversations, false, true));
    } finally {
        dispatch(updateConversationsLoadingStatus(false));
    }
};

export const resolveConversation =
    (id: string, body: ResolveConversationBody) =>
    async (dispatch): Promise<void> => {
        try {
            dispatch(updateConversationsLoadingStatus(true));
            const res = await resolveConversationRequest(id, body);
            dispatch(updateConversation(res.data.conversation));
            dispatch(removeConversationFromList(id, "assigned"));
            dispatch(setViewType("resolved"));
            await dispatch(getConversations(true));
        } finally {
            dispatch(updateConversationsLoadingStatus(false));
        }
    };

export const onDeleteSavedView = (viewId: string) => async (dispatch) => {
    await deleteViewRequest(viewId);
    dispatch(deleteSavedView(viewId));
};

export const onUpdateConversationsCountsFromWebSocket =
    (data: any) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        const { conversationsCounter } = getState().conversations;
        if (conversationsCounter !== data.counts.conversations) {
            dispatch(setConversationsCounter(data.counts.conversations));
        }
    };

export const replaceToQueue =
    (queueId: string, selectedConversationId: string) => async (dispatch, getState: () => AppState) => {
        const { conversations, viewType } = getState().conversations;
        const currentUserId = getState().userSettings?.myInfo?.uuid;
        let updatedConversationsIds = [...conversations[viewType]];
        dispatch(updateConversationsLoadingStatus(true));

        const res = await replaceToQueueRequest(selectedConversationId, queueId);

        const queueDetailsRes = await getQueueDetails(queueId);
        const { queue } = queueDetailsRes.data;
        const isUserBelongsToNewQueue = queue.admins.some((admin) => admin.admin.id === currentUserId);
        const { data } = res;

        dispatch(updateConversation(data.conversation));
        dispatch(
            onUpdateToast({
                type: "saved",
                value: `Conversation was successfully moved to ${res.data.conversation.queue.name}`
            })
        );

        if (!isUserBelongsToNewQueue) {
            dispatch(setSelectedConversationId(null));
            dispatch(clearMessages(data.conversation.id));
            dispatch(removeConversationFromList(data.conversation.id));
        } else {
            updatedConversationsIds = [
                data.conversation.id,
                ...updatedConversationsIds.filter((conversationId: any) => conversationId !== data.conversation.id)
            ];
            dispatch(updateConversationsIdsList(updatedConversationsIds));
            dispatch(
                updateConversation(
                    data.conversation.id === selectedConversationId
                        ? { ...data.conversation, unread_count: 0 }
                        : data.conversation
                )
            );
        }

        dispatch(updateConversationsLoadingStatus(false));
        return isUserBelongsToNewQueue;
    };

export const getBasedOnPermissionsUsers = () => async (dispatch) => {
    const res = await getAccountSettingsUsersRequest({
        limit: 100,
        offset: 0,
        order: "first_name:asc,last_name:asc",
        filter: "conversations_access_all",
        with_invited: true
    });

    const admins = res.data.users.map((admin) => {
        return {
            ...admin,
            id: admin.uuid,
            avatar: admin.profile_pic?.url,
            based_on_permissions: true
        };
    });

    dispatch(setBasedOnPermissionsAdmins(admins));
};
