import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { IMPDButtonProps, MPDButton } from "src/mpd-library";

import { StyledButtonTypes } from "src/components";

import "./styles.scss";

export interface IStyledButtonProps extends IMPDButtonProps {
    type?: any | StyledButtonTypes;
    buttonIconClassName?: string;
    circle?: boolean;
    rightIconName?: string;
    onMouseDown?: () => void;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const StyledButton: React.FunctionComponent<IStyledButtonProps> = (props) => {
    const { className, type, circle, ...buttonProps } = props;

    const classes = classNames("styled-button", type ? type : "simple", className, circle ? "circle-button" : "");

    return <MPDButton className={classes} {...buttonProps} />;
};
