import { Classes, Collapse, Text } from "@blueprintjs/core";
import classNames from "classnames";
import pluralize from "pluralize";
import moment from "moment";
import { JsonOutput } from "../json-output/json-output";
import styles from "./ApiRequestLogItem.module.scss";
import { Tooltip } from "../../../../../components/tooltip/tooltip";
import { SyntheticEvent, useEffect, useContext, useMemo, memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { updateNewApiRequestFromSocket } from "../../../../../actions/account-settings/account-settings";
import { ScrollingContext } from "../api-request-log/api-request-log";

const classname = "api-request-log-item";

const logItemKeys = ["status", "method", "path", "text_result", "inserted_at"];
const columnTitles = ["HTTP", "MEHOD", "PATH", "RESULT", "TIMESTAMP"];

export const Header = () => {
    return (
        <div className={classNames(styles[`sticky-header`], styles[`root`])}>
            <div />
            {columnTitles.map((title) => (
                <Text className={styles[`header-item`]}>{title}</Text>
            ))}
        </div>
    );
};

export const ApiLogItem = memo(({ ...remain }) => {
    const { isScrolling, logList, expandedItemId, setExpandedItemId, newApiRequest } = useContext(ScrollingContext);
    const index = remain["data-item-index"];
    const id = logList.allIds[index];
    const loading = id === "loading";
    const logItem = logList.byId[id];
    const dispatch = useDispatch();

    const onItemClick = (event: SyntheticEvent, id: number) => {
        event.stopPropagation();
        if (expandedItemId === id) {
            setExpandedItemId(null);
            return;
        }
        setExpandedItemId(id);
    };

    useEffect(() => {
        if (newApiRequest?.id && newApiRequest?.id === logItem?.id) {
            setTimeout(() => {
                dispatch(updateNewApiRequestFromSocket(null));
            }, 5000);
        }
    }, [newApiRequest, logItem]);

    if (!!loading || !logItem) {
        return (
            <>
                <div className={styles[`root`]} {...remain}>
                    <div className={styles[`status-dot-wrapper`]}>
                        <div className={classNames(styles[`status-dot`], Classes.SKELETON)} />
                    </div>
                    {logItemKeys.map((key) => {
                        return <Text className={classNames(styles[`value`], Classes.SKELETON)}>{key}</Text>;
                    })}
                </div>
            </>
        );
    }

    return (
        <motion.div
            key={`${logItem.id}-key`}
            layoutId={`${logItem.id}-layout-id`}
            className={styles[`animated-wrapper`]}
            animate={newApiRequest?.id === logItem?.id && !isScrolling ? { scale: [1, 1.04, 1, 1.04, 1] } : undefined}
            {...remain}
            style={{
                ...remain.style,
                backgroundColor: newApiRequest?.id === logItem?.id ? "rgb(31, 118, 255, 0.06)" : "white"
            }}
        >
            <div className={styles["root"]} layout onClick={(e) => onItemClick(e, logItem.id)}>
                <div className={styles[`status-dot-wrapper`]}>
                    <div
                        className={classNames(
                            styles[`status-dot`],
                            styles[`${logItem.status}`.charAt(0) === "2" ? "success" : "error"],
                            styles[`${logItem.status}`.charAt(0) === "2" && logItem.warnings?.length && "warning"]
                        )}
                    />
                    {logItem.count > 1 && (
                        <Tooltip
                            tootltipTarget={
                                <Text className={classNames(styles[`value`], styles["counter"])}>{logItem.count}</Text>
                            }
                            content={`The request was consecutively executed ${logItem.count} ${pluralize(
                                "time",
                                logItem.count
                            )}`}
                        />
                    )}
                </div>
                {logItemKeys.map((logKeyItem) => {
                    let value;
                    let additionalClassname;
                    if (logKeyItem === "path") {
                        value = logItem[logKeyItem];
                        additionalClassname = styles["path-value"];
                    } else if (logKeyItem === "inserted_at") {
                        value = moment(logItem[logKeyItem]).format("MMMM D, YYYY HH:mm A");
                    } else if (logKeyItem === "text_result") {
                        if (`${logItem.status}`.charAt(0) !== "2") {
                            additionalClassname = styles["text-result-error"];
                        } else if (`${logItem.status}`.charAt(0) === "2" && logItem.warnings?.length) {
                            additionalClassname = styles["text-result-warning"];
                        }
                        value = logItem[logKeyItem];
                    } else {
                        value = logItem[logKeyItem];
                    }
                    return (
                        <Text className={classNames(styles[`value`], additionalClassname && additionalClassname)}>
                            {value}
                        </Text>
                    );
                })}
            </div>
            <Collapse isOpen={expandedItemId === logItem.id}>
                <JsonOutput
                    responseBody={logItem?.response_body}
                    payload={logItem.payload}
                    warnings={logItem.warnings}
                />
            </Collapse>
            {/* <AnimatePresence initial={false}>
                    {expandedItemId === logItem.id && (
                        <JsonOutput
                            responseBody={logItem?.response_body}
                            payload={logItem.payload}
                            warnings={logItem.warnings}
                        />
                    )}
                </AnimatePresence> */}
        </motion.div>
    );
});
