import { Checkbox, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import React, { FunctionComponent, SVGProps, useState } from "react";
import { MPDIcon } from "..";
import { StyledCheckbox } from "../../components";
import { ConditionalWrapper } from "../../components/conditional-wrapper/conditional-wrapper";
import { Option } from "../dropdown/dropdown";
import { ReactComponent as ListBreadCrumbWhiteIcon } from "src/mpd-library/icon/assets/list-bread-crumb-white.svg";
import "./styles.scss";
import { ReactComponent as TickIcon } from "../icon/assets/tick.svg";

export type TSelectItemType = "primary" | "delete";
export const TSelectItemTypes: { [key: string]: TSelectItemType } = {
    primary: "primary",
    delete: "delete"
};

interface IMPDSelectItemProps {
    type: TSelectItemType;
    children: JSX.Element;
    className: string;
    selected?: boolean;
    onClick?: (event: React.SyntheticEvent) => void;
    disabled?: boolean;
    "data-role-id"?: string;
    processing?: boolean;
    leftIcon?: JSX.Element | React.ReactNode;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    checked?: boolean;
    withCheckbox?: boolean;
    option: Option;
    searchValue?: string;
    onCheckBoxClick?: (option: Option) => void;

    closeDropdown?: () => void;
}

export const MPDSelectItem = (propes: IMPDSelectItemProps) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const {
        type,
        children,
        className,
        disabled,
        selected,
        processing = false,
        leftIcon,
        LeftIconComponent,
        checked,
        withCheckbox,
        option,
        label,
        onCheckBoxClick,
        closeDropdown,
        searchValue,
        draggable,
        ...props
    } = propes;

    const onClick = async (e) => {
        e.stopPropagation?.();
        await onClick?.(e);

        if (closeDropdown && !withCheckbox) {
            closeDropdown();
        }
    };

    const checkboxClick = (e) => {
        e.stopPropagation();
        const checked = e.target.checked;
        onCheckBoxClick?.(option, checked);
    };

    const getHighlightedText = (text) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = searchValue ? text.split(new RegExp(`(${searchValue})`, "gi")) : [text];
        return (
            <span>
                {" "}
                {parts.length &&
                    parts.map((part, i) => (
                        <span
                            key={i}
                            style={
                                part?.toLowerCase() === searchValue?.toLowerCase()
                                    ? { backgroundColor: "#FDCB6E", borderRadius: "3px", color: "black" }
                                    : {}
                            }
                        >
                            {part}
                        </span>
                    ))}{" "}
            </span>
        );
    };

    const onMouseOver = () => {
        setHovered(true);
    };

    const onMouseLeave = () => {
        setHovered(false);
    };

    return (
        <div
            {...props}
            onClick={(e) => {
                e.stopPropagation?.();
                props.onClick?.(e);
            }}
            className={classNames(
                "mpd-select-item",
                className && `${className}`,
                disabled && !draggable && "bp4-disabled",
                selected && "selected",
                type
            )}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <ConditionalWrapper
                condition={withCheckbox}
                wrapper={(children) => (
                    <StyledCheckbox
                        onChange={checkboxClick}
                        checked={(() => checked)()}
                        disabled={disabled}
                        alignIndicator={"right"}
                        className={"mpd-select-item-label"}
                    >
                        {children}
                    </StyledCheckbox>
                )}
            >
                {!children && (
                    <div className={"left-wrapper"}>
                        {(leftIcon || LeftIconComponent || draggable) && (
                            <MPDIcon
                                IconComponent={
                                    ((leftIcon || LeftIconComponent) && draggable && !hovered) || !draggable
                                        ? LeftIconComponent
                                        : (props) => (
                                              <ListBreadCrumbWhiteIcon
                                                  {...props}
                                                  className={classNames(props.className, "burger-icon")}
                                              />
                                          )
                                }
                                className={`left-icon`}
                            />
                        )}
                        {!!label && getHighlightedText(label)}
                        {!!processing && <Spinner size={18} className={"mpd-select-item-spinner"} />}
                    </div>
                )}
                {!!children && children}
                {!processing && selected && !withCheckbox && (
                    <MPDIcon IconComponent={TickIcon} className={`tick-icon`} />
                )}
            </ConditionalWrapper>
        </div>
    );
};
