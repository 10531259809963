import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { IProps, Text } from "@blueprintjs/core";

import { MPDIcon } from "src/mpd-library/";

import "./styles.scss";

interface IContainerEmptyProps extends IProps {
    title?: string;
    subtitle?: string;
    iconName?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const ContainerEmpty: React.FunctionComponent<IContainerEmptyProps> = (props) => {
    const { className, iconName, IconComponent, title, subtitle } = props;
    const classes = classNames("container-empty", className);

    return (
        <div className={classes}>
            {(iconName || IconComponent) && <MPDIcon name={iconName} IconComponent={IconComponent} className="icon" />}
            {title && <Text className="title">{title}</Text>}
            {subtitle && <Text className="subtitle">{subtitle}</Text>}
        </div>
    );
};

export default ContainerEmpty;
