import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, matchPath, useHistory, useLocation } from "react-router-dom";
import pluralize from "pluralize";
import {
    BreadCrumbs,
    IStyledAlertProps,
    RightPanelContainer,
    SettingsLeftPanel,
    StyledAlertTypes,
    StyledButton,
    StyledButtonTypes,
    StyledToolBar
} from "../../components";
import { MPDAnimations, TSelectItemTypes } from "../../mpd-library";
import { AppState } from "../../store";
import { ContactsGroup } from "./contacts-groups/contacts-groups";
import { ContactsList } from "./contacts-list/contacts-list";
import { onToggleMobileSideBar, onToggleRightPanel } from "../../actions/global/global";
import { ContactPage } from "./contact-page/ContactPage";
import { ImportContactsStepper } from "./contacts-list/components/import-contacts-stepper/ImportContactsStepper";
import styles from "./Contacts.module.scss";
import { Dropdown } from "../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { ReactComponent as AddPersonIcon } from "src/mpd-library/icon/assets/AddPersonIcon.svg";
import { ContactsContext, ContactsContextProvider } from "./contacts-list/context";
import { getContactsSettings, updateContactList, updateContactsSavedView } from "../../requests/contactsRequests";
import { Position } from "@blueprintjs/core";
import { normalize } from "../../hooks/useDataFetch";
import { ICONS_TYPE_MATCH } from "../../components/contacts-filters/constants";
import { LIST_ITEM_FIELDS_DAFAULT, DEFAULT_FILTER, SELECTED_CONTACTS_OPTIONS } from "./constants";

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export const SummaryContext = createContext({});

const Contacts: FC<{}> = (): JSX.Element => {
    const rightPanelExpanded = useSelector((state: AppState) => state.global.rightPanelExpanded);
    const mobileSideBarActive = useSelector((state: AppState) => state.global.mobileSideBarActive);
    const [isCreateNewContactPopupOpen, setIsCreateNewContactPopupOpen] = useState<boolean>(false);
    const [parentFilter, setParentFilter] = useState<boolean>(false);
    const [contactsSettings, setContactsSettings] = useState(LIST_ITEM_FIELDS_DAFAULT);
    const { setPresettedFilter, savedView, list, setList, setSavedView, setDeletableContacts, deletableContacts } =
        useContext(ContactsContext);

    const [breadCrumbsList, setBreadCrumbsList] = useState<Array<any>>([
        { text: "All Contacts", pathname: `/contacts` }
    ]);
    const [summary, setSummary] = useState({});

    const history = useHistory();
    const dispatch = useDispatch();

    const {
        location,
        location: { pathname }
    } = history;

    const onSetContactsSettings = async (settings) => {
        setContactsSettings(
            normalize(
                settings,
                {
                    allIds: [],
                    byId: {}
                },
                "field"
            )
        );
    };

    const onGetContactsSettings = async () => {
        const res = await getContactsSettings();
        onSetContactsSettings(
            res.data.list_fields.map((field) => {
                return {
                    ...field,
                    LeftIconComponent: ICONS_TYPE_MATCH[field.type],
                    disabled: field.field === "name"
                };
            })
        );
    };

    useEffect(() => {
        onGetContactsSettings();
    }, []);

    const onNewContactClick = () => {
        setIsCreateNewContactPopupOpen(true);
    };

    const summaryContextValue = useMemo(() => ({ summary, setSummary }), [summary, setSummary]);

    const onBreadCrumbClick = useCallback(
        (_, index) => {
            setBreadCrumbsList(breadCrumbsList.slice(0, index + 1));
        },
        [breadCrumbsList]
    );

    const topToolbarProps = useMemo(() => {
        const contactsListMatch = matchPath(history.location.pathname, {
            path: "/contacts",
            exact: true,
            strict: false
        });
        const contactEditMatch = matchPath(history.location.pathname, {
            path: "/contacts/:id",
            exact: true,
            strict: false
        });

        console.log("RRR >>>>", contactsListMatch, contactEditMatch);

        if (contactsListMatch) {
            return {
                rightElement: (
                    <>
                        <ImportContactsStepper setFilterByImport={setPresettedFilter} />
                        <StyledButton
                            text={"New Contact"}
                            type={StyledButtonTypes.primary}
                            onClick={onNewContactClick}
                            LeftIconComponent={AddPersonIcon}
                        />
                    </>
                )
            };
        } else if (contactEditMatch) {
            return {
                className: styles["top-toolbar_edit-contact-page"],
                rightElement: (
                    <Dropdown
                        position={Position.BOTTOM_RIGHT}
                        targetClassName={DropdownTargetClasses.SELECT_PRIMARY}
                        selectedValue={{ label: "Actions" }}
                        staticOptions={[
                            {
                                label: "Delete permanently",
                                value: null,
                                onClick: () => setDeletableContacts([contactEditMatch.params?.id]),
                                type: TSelectItemTypes.delete
                            }
                        ]}
                        ArrowIconComponent
                    />
                )
            };
        }
    }, [history.location.pathname]);

    const saveView = useCallback(
        (view) => {
            setSummary({ ...summary, views: [...(summary?.views || []), view] });
            setSavedView(view);
        },
        [summary]
    );

    const onEditContactsList = async (filters) => {
        const updatedList = { ...list, filters };
        const res = await updateContactList(updatedList);
        setList({ ...res.data.list });
    };

    const onEditView = async (filters) => {
        const res = await updateContactsSavedView({ ...savedView, filters });
        const updatedSummary = {
            ...summary,
            views: [
                ...(summary?.views || []).map((view) => {
                    if (view.id === savedView.id) {
                        return res.data.view;
                    }
                    return view;
                })
            ]
        };
        setSummary(updatedSummary);
        setSavedView(res.data.view);
    };

    const editFiltersRelatedProps = useMemo(() => {
        if (savedView) {
            if (savedView.is_default && savedView.filters === DEFAULT_FILTER) {
                return {
                    saveView
                };
            } else if (!savedView.is_default) {
                return {
                    editView: onEditView,
                    editFiltersButtonProps: {
                        defaultText: "Edit View",
                        editModeText: "Save View"
                    }
                };
            } else {
                return { disableFilters: true };
            }
        }

        if (list) {
            if (list.type === "static") {
                return { disableFilters: true };
            } else {
                return {
                    editView: onEditContactsList,
                    editFiltersButtonProps: {
                        defaultText: "Edit Filter",
                        editModeText: "Save Filter"
                    }
                };
            }
        }
        return {
            saveView,
            defaultText: "Save View"
        };
    }, [savedView, list, saveView, onEditContactsList, onEditView]);

    const confirmDeletingAlertProps: Partial<IStyledAlertProps> = useMemo(
        (): IStyledAlertProps => ({
            isOpen: !!deletableContacts,
            type: StyledAlertTypes.warning,
            globalContentProps: {
                title: `Delete ${pluralize("contact", deletableContacts?.length)}?`,
                description: `${
                    deletableContacts === "all" ? "All" : deletableContacts?.length > 1 ? deletableContacts?.length : ""
                } ${pluralize("Contact", deletableContacts?.length)} will be deleted`
            },
            actionConfirmationProps: {
                confirmInputProps: {
                    placeholder: "confirmation",
                    hint: `Enter "delete ${deletableContacts?.length > 1 ? "contacts" : "contact"}" to confirm`
                },
                valueToMatch: `delete ${deletableContacts?.length > 1 ? "contacts" : "contact"}`
            },
            confirmButtonProps: { type: StyledButtonTypes.delete, text: "Delete" }
        }),
        [deletableContacts]
    );

    const listItemConfig = useMemo(() => {
        return {
            allIds: ["checkbox", "name", ...(contactsSettings?.allIds || []), "more"],
            byId: {
                ...contactsSettings?.byId,
                checkbox: {
                    field: "checkbox",
                    enabled: true
                },
                name: {
                    field: "name",
                    enabled: true
                },
                more: {
                    field: "more",
                    enabled: true
                }
            }
        };
    }, [contactsSettings]);

    return (
        <SummaryContext.Provider value={summaryContextValue}>
            <div className="app-controller">
                <SettingsLeftPanel
                    title="Contacts"
                    rightPanelExpanded={rightPanelExpanded}
                    mobileSideBarActive={mobileSideBarActive}
                    onToggleMobileSideBar={onToggleMobileSideBar}
                    isIn={Boolean(pathname === "/contacts")}
                    enter={Boolean(pathname === "/contacts")}
                    exit={Boolean(pathname !== "/contacts")}
                    isMobileSize={false}
                >
                    <ContactsGroup setBreadCrumbsList={setBreadCrumbsList} parentFilter={parentFilter} />
                </SettingsLeftPanel>
                <RightPanelContainer
                    expanded={rightPanelExpanded}
                    animationType={pathname !== "/contacts" ? MPDAnimations.swipeLeft : MPDAnimations.swipeRight}
                    loading={false}
                    appear={true}
                    isIn={!pathname.includes("/contacts")}
                    enter={!pathname.includes("/contacts")}
                    exit={pathname.includes("/contacts")}
                    isMobileSize={false}
                >
                    <>
                        <StyledToolBar
                            key={`${location}`}
                            leftElement={
                                <BreadCrumbs
                                    breadCrumbsList={breadCrumbsList}
                                    toggleButtonProps={{
                                        onToggle: () => {
                                            dispatch(onToggleRightPanel());
                                        },
                                        onClose: () => {
                                            dispatch(onToggleRightPanel());
                                        }
                                    }}
                                    onBreadCrumbClick={onBreadCrumbClick}
                                />
                            }
                            {...topToolbarProps}
                        />
                        <Route
                            exact
                            path={"/contacts"}
                            render={() => (
                                <>
                                    <ContactsList
                                        confirmDeletingAlertProps={confirmDeletingAlertProps}
                                        isCreateNewContactPopupOpen={isCreateNewContactPopupOpen}
                                        setIsCreateNewContactPopupOpen={setIsCreateNewContactPopupOpen}
                                        listItemConfig={listItemConfig}
                                        optionsForSelectedContacts={SELECTED_CONTACTS_OPTIONS}
                                        filtersProps={editFiltersRelatedProps}
                                        setParentFilter={setParentFilter}
                                        onSetContactsSettings={onSetContactsSettings}
                                        contactsSettings={contactsSettings}
                                    />
                                </>
                            )}
                        />
                        <Route
                            exact
                            path={"/contacts/:id"}
                            render={() => {
                                return (
                                    <ContactPage
                                        confirmDeletingAlertProps={confirmDeletingAlertProps}
                                        setBreadCrumbsList={setBreadCrumbsList}
                                        breadCrumbsList={breadCrumbsList}
                                    />
                                );
                            }}
                        />
                    </>
                </RightPanelContainer>
            </div>
        </SummaryContext.Provider>
    );
};

export default (props) => (
    <ContactsContextProvider>
        <Contacts {...props} shouldSetFiltersToUrl />
    </ContactsContextProvider>
);
