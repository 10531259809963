import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import {
    createQueueRequest,
    getConversationsQueues,
    ResponseQueue,
    updateQueueRequest
} from "src/requests/conversationsRequests";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { onUpdateToast } from "src/actions/global/global";
import { useHistory } from "react-router";

export type UseQueues = {
    hasMore: boolean;
    getQueuesProcessing: boolean;
    counts: number;
    searchCount: number;
    queues: Array<any>;
    searchValue: string;
    createQueue: (data: Partial<ResponseQueue>) => void;
    onGetQueues: (getMore: boolean, searchValue?: string) => Promise<void>;
    updateQueue: (queueId: string, data) => void;
    setQueuesSearchValue: (vlaue: string) => void;
};

type UseQueuesProps = {
    myQueues: boolean;
    disableAutoLoad?: boolean;
};

export const useQueues = ({ myQueues, disableAutoLoad }: UseQueuesProps): UseQueues => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const [hasMore, setHasMoreQueues] = useState<boolean>(false);
    const [getQueuesProcessing, setGetQueuesProcessing] = useState<boolean>(true);
    const [counts, setCounts] = useState<number>(0);
    const [searchCount, setSearchCounts] = useState<number>(0);
    const [queues, setQueues] = useState<Array<any>>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const queuesRef = useRef<Array<any>>([]);
    const countRef = useRef<number>(0);
    const wasMount = useRef<boolean>(false);

    const debouncedSearch = useRef(
        debounce((searchValue) => {
            onGetQueues(false, searchValue);
        }, 300)
    );

    const createQueue = async (data: Partial<ResponseQueue>) => {
        try {
            const res = await createQueueRequest(data);
            const {
                data: { queue }
            } = res;
            setQueues([queue, ...queues]);
            dispatch(onUpdateToast({ value: `Queue ${queue.name} was created`, type: "saved" }));
            history.push("/account-settings/queues");
            return res;
        } catch (err) {
            dispatch(onUpdateToast({ value: `Something get wrong. Try again a bit later`, type: "error" }));
        }
    };

    const updateQueue = async (queueId: string, data: Partial<ResponseQueue>) => {
        try {
            const res = await updateQueueRequest(queueId, data);
            const {
                data: { queue }
            } = res;
            setQueues(
                queues.map((queueFromArray) => {
                    if (queueFromArray.id === queue.id) {
                        return queue;
                    }
                    return queueFromArray;
                })
            );
            dispatch(onUpdateToast({ value: `Queue ${queue.name} was updated`, type: "saved" }));

            return res;
        } catch (err) {
            dispatch(onUpdateToast({ value: "Something get wrong. Try again a bit later", type: "error" }));
        }
    };

    const onGetQueues = useCallback(
        async (getMore: boolean, searchValue?: string) => {
            try {
                setGetQueuesProcessing(true);
                !getMore && (countRef.current = 0);

                const res = await getConversationsQueues({
                    myQueues,
                    query: searchValue,
                    offset: getMore ? countRef.current : 0
                });

                !getMore && setQueues([]);

                const { queues: responseQueues, count: responseCount, has_more: hasMore } = res.data;

                const updatedQueues = getMore ? [...queuesRef.current, ...responseQueues] : responseQueues;

                setQueues(updatedQueues);
                setHasMoreQueues(hasMore);

                queuesRef.current = updatedQueues;
                countRef.current = countRef.current + responseQueues.length;

                if (searchValue) {
                    setSearchCounts(responseCount);

                    if (counts !== responseCount && !searchValue) {
                        setCounts(responseCount);
                    }
                } else {
                    setCounts(responseCount);
                    setSearchCounts(null);
                }
            } finally {
                setGetQueuesProcessing(false);
            }
        },
        [queues, searchValue]
    );

    useEffect(() => {
        if (disableAutoLoad) {
            return;
        }
        onGetQueues(false);
    }, []);

    useEffect(() => {
        if (!wasMount.current) {
            wasMount.current = true;
            return;
        }
        if (wasMount.current) {
            debouncedSearch.current(searchValue);
        }
    }, [searchValue]);

    return {
        hasMore,
        getQueuesProcessing,
        counts,
        searchCount,
        queues,
        searchValue,
        setQueuesSearchValue: setSearchValue,
        createQueue,
        onGetQueues,
        updateQueue
    };
};
