import { AxiosResponse } from "axios";
import { axiosInstance } from "../actions";
import { getConfig } from "../common";
import {
    LoginRestoreBody,
    LoginSetPasswordBody,
    PortalRestBody,
    UserDataResponse,
    AuthLoginResponse,
    UserEmailChangeData,
    UserPhoneChangeData,
    UserEmailPhoneChangeResponse,
    MyActivityResponse,
    MyActivityParams
} from "./authApi";

export async function postAuthRequest(body: {
    username: string;
    password: string;
    otp_token?: string;
    otp?: string;
    remember_me?: boolean;
}): Promise<AxiosResponse<AuthLoginResponse>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/login/otp`, body);
}

export async function postAskOneTimePasswordRequest(body: {
    otp_token: string;
    otp_channel: string;
}): Promise<AxiosResponse<{ status: string }>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/login/otp/challenge`, body);
}

export async function postLoginRestoreRequest(body: LoginRestoreBody) {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/login/restore_password`, body);
}

export async function postLoginSetPasswordRequest(body: LoginSetPasswordBody) {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/login/set_password`, body);
}

export async function getUserDataRequest(): Promise<AxiosResponse<UserDataResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/me`);
}

export async function patchUserDataRequest(body: UserDataResponse) {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/me`, body);
}

export async function patchUserEmailRequest(
    body: UserEmailChangeData
): Promise<AxiosResponse<UserEmailPhoneChangeResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/me/email`, body);
}

export async function patchUserPhoneRequest(
    body: UserPhoneChangeData
): Promise<AxiosResponse<UserEmailPhoneChangeResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/me/phone_number`, body);
}

export async function postPortalResetRequest(body: PortalRestBody) {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/reset_password`, body);
}

export async function getMyActivityRequest(props): Promise<AxiosResponse<MyActivityResponse>> {
    const config = getConfig();
    let params = {};
    if (props) {
        const { limit, offset } = props;
        params = {
            limit,
            offset
        };
    }
    return await axiosInstance.get(`${config.API_URL}/me/activity`, { params });
}
