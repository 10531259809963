export const UPDATE_WEB_SOCKET = "update-web-socket";
export const UPDATE_TOPICS_LIST = "update-topics-list";
export const UPDATE_GOT_DATA_FROM_WEBSOCKET = "update-got-data-from-webscoket";
export const UPDATE_WEB_SOCKET_STATUS = "update-web-socket-status";
export const RESET_ALERT_PREVIEW = "reset-alert-preview";
export const SET_WS_DATA = "set-ws-data";
export const REMOVE_WS_DATA = "remove-ws-data";

type SetWsDataPayload = Required<{ event: string; topic: string; data: any }>;

interface SetWsData {
    type: typeof SET_WS_DATA;
    payload: SetWsDataPayload;
}
interface RemoveWsData {
    type: typeof REMOVE_WS_DATA;
    payload: string;
}

interface UpdateWebSocket {
    type: typeof UPDATE_WEB_SOCKET;
    payload: WebSocket | null;
}

interface UpdateTopicList {
    type: typeof UPDATE_TOPICS_LIST;
    payload: string;
}

interface UpdateGotDataFromWebSocket {
    type: typeof UPDATE_GOT_DATA_FROM_WEBSOCKET;
    payload: any;
}

interface UpdateWebSocketStatus {
    type: typeof UPDATE_WEB_SOCKET_STATUS;
    payload: any;
}

interface ResetAlertPreview {
    type: typeof RESET_ALERT_PREVIEW;
    topic: string;
}

export type WebSocketTypes =
    | SetWsData
    | RemoveWsData
    | UpdateWebSocket
    | UpdateTopicList
    | UpdateGotDataFromWebSocket
    | UpdateWebSocketStatus
    | ResetAlertPreview;
