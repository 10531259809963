import { FIELDS_SHOULD_BE_MAPPED } from "./constants";

export const calculateUnmapped = (mapped_fields) => {
    let mappedCounter = 0;
    const unmappedFieldsNames = [];

    mapped_fields.forEach((field) => {
        if (FIELDS_SHOULD_BE_MAPPED[field.field]) {
            ++mappedCounter;
            unmappedFieldsNames.push(field.field);
        }
    });

    const unmappedCounter = Object.keys(FIELDS_SHOULD_BE_MAPPED).length - mappedCounter;

    return { unmappedCounter, unmappedFieldsNames };
};
