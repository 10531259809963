import { ConversationTag } from ".";

export type ConversationEventMessageEventType =
    | "conversation_opened"
    | "tag_added"
    | "tag_removed"
    | "conversation_created"
    | "conversation_reopened"
    | "conversation_returned_to_queue"
    | "conversation_reassigned";

export type ConversationEventMessage = {
    event: {
        id: string;
        summary: string;
        type: ConversationEventMessageEventType;
        tag?: ConversationTag;
    };
    id: string;
    inserted_at: string;
    type: "event";
    updated_at: string;
};

export type SocketSender = {
    first_name: string;
    id: string;
    last_name: string;
    profile_pic: {
        type: string;
        url: string;
    };
    status: string;
    type: "user" | "admin";
};

export type TypingsStatuses = {
    [key: string]: {
        [key: string]: Array<SocketSender>;
    };
};

export type ConversationMessagesState = {
    conversationMessagesList: {
        [key: string]: {
            messages: {
                byId: {
                    [key: string]: ConversationMessage;
                };
                allIds: Array<string>;
            };
            dates: {
                byId: {
                    [key: string]: Array<string>;
                };
                allIds: Array<string>;
            };
            typingIds: Array<string>;
            hasMoreOlder: boolean;
            hasMoreLater: boolean;
            fromIdAreNew: null | string;
            shoulJumpToBottomOfChatlist: boolean;
        };
    };
    shouldShowJumpToNewMessages: { [key: string]: boolean };
    isBottom: boolean;
};

export const defaultConversationMessagesState = (): ConversationMessagesState => ({
    conversationMessagesList: {},
    shouldShowJumpToNewMessages: {},
    isBottom: true
});
