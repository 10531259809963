import React, { FunctionComponent, SVGProps } from "react";
import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import { MPDIcon } from "src/mpd-library";
import styles from "./styles.module.scss";

const classname = "empty-block";

export type EmptyBlockProps = {
    title: string;
    subtitle?: string;
    type?: "page" | "dropdown";
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    className?: string;
};

export const EmptyBlockTypes = {
    page: "page",
    dropdown: "dropdown",
    list: "list"
};

export const EmptyBlock = ({ title, subtitle, type, IconComponent, className }: EmptyBlockProps) => {
    return (
        <div className={classNames(styles[classname], type && styles[type], "empty-block", className)}>
            {IconComponent && <MPDIcon IconComponent={IconComponent} className={"emty-block-icon"} />}
            <Text className={classNames(styles[`${classname}-title`], `${classname}-title`)}>{title}</Text>
            {!!subtitle && (
                <Text className={classNames(styles[`${classname}-subtitle`], `${classname}-subtitle`)}>{subtitle}</Text>
            )}
        </div>
    );
};
