// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IDetailView } from "../../../";

import { BreadCrumbs, SettingsControlProperty, SettingsTitle } from "src/components";

import { Classes } from "src/mpd-library";

import "./styles.scss";
import { AppState, AccountSettingsState, AccountSettingsGeneralChannels } from "src/store";
import { History } from "history";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { onSaveChanges } from "src/actions/account-settings/account-settings-api";

export interface ISecuritySettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    routeProps: RouteComponentProps;
    onSaveChanges: (ref: { channels: AccountSettingsGeneralChannels; pathname: string; history: History }) => void;
}

interface ISecuritySettingsState {
    accountSettings?: any;
}

export class SecuritySettings extends React.Component<ISecuritySettingsProps, ISecuritySettingsState> {
    public constructor(props: ISecuritySettingsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.onSwitchChange = this.onSwitchChange.bind(this);
        this.updateToolBar();

        const { accountSettings } = this.props;

        this.state = {
            accountSettings
        };
    }

    public componentDidUpdate(prevProps: ISecuritySettingsProps) {
        if (this.props.accountSettings !== prevProps.accountSettings) {
            this.setState({ accountSettings: this.props.accountSettings });
        }
    }

    public render() {
        const { className } = this.props;
        const { accountSettings } = this.state;
        const classes = "security-settings";
        return (
            <div className={classNames(classes, Classes.CONTAINER_PADDING, className)}>
                <div className={classNames(classes + "-inner-container", Classes.ROW, Classes.RESPONSIVE)}>
                    <div className={classes + "-control-property-container"}>
                        <SettingsTitle title="2 FACTOR AUTHENTICATION" />
                        <SettingsControlProperty
                            className={classNames(classes + "-control-property", Classes.BLOCK_MARGIN_TOP)}
                            title="Enable 2FA"
                            subtitle="Enabling 2 Factor Authentication will require users to use a second form of security to sign in to the account."
                            switchProps={{
                                checked: accountSettings.general.security.enable2FA,
                                onChange: () => this.onSwitchChange("enable2FA")
                            }}
                        />
                    </div>
                    <div className={classes + "-control-property-container"}>
                        <SettingsTitle title="SINGLE SIGN ON (SSO)" />
                        <SettingsControlProperty
                            className={classNames(classes + "-control-property", Classes.BLOCK_MARGIN_TOP)}
                            title="Enable SSO"
                            subtitle="Enabling Single sign on (SSO) will require users to sing on with a method of your choice."
                            switchProps={{
                                checked: accountSettings.general.security.enableSS,
                                onChange: () => this.onSwitchChange("enableSSO")
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private onSwitchChange(type: string) {
        const { accountSettings } = this.state;
        const {
            routeProps: { history }
        } = this.props;
        accountSettings.general.security[type] = !accountSettings.general.security[type];
        this.setState({ accountSettings }, () => {
            this.props.onSaveChanges({ channels: accountSettings, pathname: "/account-settings/security", history });
        });
    }

    private updateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        {
                            text: "Security",
                            pathname: "/account-settings/security"
                        }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onSaveChanges,
            onUpdateToolBar: globalActions.onUpdateToolBar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SecuritySettings);
