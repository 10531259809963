import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToolBar } from "src/actions/global/global";
import { BreadCrumbs } from "src/components";
import { ProfileAutomationsItem } from "../components/profile-automations-item/profile-automations-item";
import styles from "./styles.module.scss";
import { ProfileListing } from "../components/profile-listing/profile-listing";
import { BusinessHours } from "../components/busines-hours/busines-hours";
import { getProfileAutomationsSettings } from "src/actions/profile-automations/profile-automations";
import { AutoResponse } from "../components/auto-response/auto-response";
import { GreetingMessage } from "../components/greeting-message/greeting-message";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import "./styles.scss";
import classNames from "classnames";
import { navigate } from "src/actions/global/global-api";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "../../../../../store/store";
import { updateProfileAutomationsSetting } from "src/actions/profile-automations/profile-automations";
import { business_hours } from "../components/busines-hours/constants";
import { ReactComponent as ShopIcon } from "../../../../../mpd-library/icon/assets/shop-icon.svg"
import { ReactComponent as WatchIcon } from "../../../../../mpd-library/icon/assets/watch-icon.svg"
import { ReactComponent as GreetingIcon } from "../../../../../mpd-library/icon/assets/greeting-icon.svg"
import { ReactComponent as AutoResponseIcon } from "../../../../../mpd-library/icon/assets/auto-response.svg"

const classname = "profile-automations-list";

export const ProfileAutomationsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profileBio = useSelector((state: AppState) => state.profileAutomations.conversations_settings.profile_bio);
    const existingGreetingMessage = useSelector(
        (state: AppState) => state.profileAutomations.conversations_settings.greeting_message
    );
    const existingFollowUpMessage = useSelector(
        (state: AppState) => state.profileAutomations.conversations_settings.follow_up_message
    );
    const existingAutoResponse = useSelector(
        (state: AppState) => state.profileAutomations.conversations_settings.auto_response
    );
    const existingCustomAwayMessage = useSelector(
        (state: AppState) => state.profileAutomations.conversations_settings.custom_away_message
    );
    const businesHours = useSelector(
        (state: AppState) => state.profileAutomations.conversations_settings.business_hours
    );
    const profileBioValue = profileBio;
    const customAwayMessage = existingCustomAwayMessage;
    const greetingMessage = existingGreetingMessage;
    const followUpMessage = existingFollowUpMessage;
    const businessHourFunc = businesHours;
    const autoResponse = existingAutoResponse;

    const [gettingConversationsSettings, setGettingConversationsSettings] = useState(false);
    const [collapsedIndex, setCollapsedIndex] = useState(null);
    const [formikState, setFormikState] = useState(null);
    const [isChildrenCollapsed, setIsChildrenCollapsed] = useState(false);
    const [selectedRadioValue, setSelectedRadioValue] = useState(null);
    const [businesHoursState, setBusinesHoursState] = useState(businesHours ? [...businesHours] : business_hours);

    const onCollapseAfterApply = () => {
        setIsChildrenCollapsed(false);
        setCollapsedIndex(null);
    };
    useEffect(() => {
        const getSettings = async () => {
            try {
                setGettingConversationsSettings(true);
                await dispatch(getProfileAutomationsSettings());
            } finally {
                setGettingConversationsSettings(false);
            }
        };
        getSettings();
        updateToolBar();
    }, []);

    useEffect(() => {
        updateToolBar();
    }, [history]);

    //publicListing formik/////

    const parentFormik = useFormik({
        initialValues: {
            profileBioValue: profileBio,
            autoResponse: existingAutoResponse,
            greetingMessage: existingGreetingMessage,
            followUpMessage: existingFollowUpMessage,
            selectedRadioValue: businessHourFunc,
            customAwayMessage: existingCustomAwayMessage
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            profileBioValue: Yup.string().required("Can't be blank"),
            autoResponse: Yup.string().required("Can't be blank"),
            greetingMessage: Yup.string().required("Can't be blank"),
            followUpMessage: Yup.string().required("Can't be blank"),
            customAwayMessage: Yup.string().required("Can't be blank")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (profileBio !== values.profileBioValue) {
                await onSaveProfileListingSettings(values);
            }
            if (existingAutoResponse !== values.autoResponse) {
                await onSaveClick(values);
            }
            if (existingFollowUpMessage !== values.followUpMessage || existingGreetingMessage!== greetingMessage) {
                await onSaveGreeting(values);
            }
            if (businessHourFunc !== selectedRadioValue || existingCustomAwayMessage!== customAwayMessage) {
                await onSaveBusinessHours(values);
            }

            setSubmitting(false);
        }
    });

    const onSaveProfileListingSettings = async (values: any) => {
        try {
            const updatedSetting = {
                profile_bio: values.profileBioValue
            };
            await dispatch(updateProfileAutomationsSetting(updatedSetting));
        } finally {
            onCollapseAfterApply();
        }
    };

    const onSaveClick = async (values) => {
        try {
            const updatedSetting = {
                auto_response: values.autoResponse
            };
            await dispatch(updateProfileAutomationsSetting(updatedSetting));
        } finally {
            onCollapseAfterApply();
        }
    };

    const onSaveGreeting = async (values) => {
        try {
            const dataToUpdate = {
                greeting_message: values.greetingMessage,
                follow_up_message: values.followUpMessage
                // greeting_tag_ids: Object.keys(checkedTags).filter((key: string) => !!checkedTags[key])
            };
            await dispatch(updateProfileAutomationsSetting(dataToUpdate));
        } finally {
            onCollapseAfterApply();
        }
    };

    //businessHours formik/////

    useEffect(() => {
        const timerId = setTimeout(() => setSelectedRadioValue(businesHours ? "custom" : "1"), 300);
        return () => {
            clearTimeout(timerId);
        };
    }, []);

    const onRadioClick = (event: any) => {
        setSelectedRadioValue(event.target.value);
        parentFormik.setFieldValue("event", event);
    };

    const onSwitchDay = (event: any, dayIndex: number) => {
        const { checked } = event.target;
        setBusinesHoursState(
            businesHoursState.map((day, index) => {
                if (dayIndex === index) {
                    const dayObj = {
                        ...day,
                        is_opened: checked,
                        opened_from: "09:00 AM",
                        opened_till: "06:00 PM"
                    };
                    if (!checked) {
                        delete dayObj.opened_from;
                        delete dayObj.opened_till;
                    }
                    return dayObj;
                }
                return day;
            })
        );
        parentFormik.setFieldValue("checked", event);
    };
    const onSelectTime = (dayIndex: number, value: any, valueName: "opened_from" | "opened_till") => {
        setBusinesHoursState(
            businesHoursState.map((day, index) => {
                if (dayIndex === index) {
                    return {
                        ...day,
                        [valueName]: value
                    };
                }
                return day;
            })
        );
        parentFormik.setFieldValue("value", value);
    };

    const onSaveBusinessHours = async (values) => {
        try {
            const dataToUpdate = {
                business_hours: values.selectedRadioValue === "1" ? null : businesHoursState,
                custom_away_message: values.customAwayMessage
            };
            await dispatch(updateProfileAutomationsSetting(dataToUpdate));
        } finally {
            onCollapseAfterApply();
        }
    };

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Profile Details" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };
    if (gettingConversationsSettings) {
        return <PageLoadingSpinner />;
    }

    const AUTOMATION_PROFILE_CONFIG = [
        {
            title: "Profile Listing",
            subtitle:
                "Create your organization’s profile that users will first see when they initiate conversations with you.",
            IconComponent: ShopIcon,
            children: (
                <ProfileListing
                    key={"profileListing"}
                    getFieldProps={parentFormik.getFieldProps}
                    profileBioValue={profileBioValue}
                    formik={parentFormik}
                    setFormikState={setFormikState}
                    errors={parentFormik.errors}
                    touched={parentFormik.touched}
                />
            )
        },
        {
            title: "Business Hours",
            subtitle: "Set expectations of availability by creating business hours and custom away messages.",
            IconComponent: WatchIcon,
            children: (
                <BusinessHours
                    key={"businessHours"}
                    businessHoursFormik={parentFormik}
                    customAwayMessage={customAwayMessage}
                    getFieldProps={parentFormik.getFieldProps}
                    setFormikState={setFormikState}
                    onRadioClick={onRadioClick}
                    onSwitchDay={onSwitchDay}
                    onSelectTime={onSelectTime}
                    businesHoursState={businesHoursState}
                    selectedRadioValue={selectedRadioValue}
                    errors={parentFormik.errors}
                    touched={parentFormik.touched}
                />
            )
        }
    ];

    if (process.env.REACT_APP_STAGE === "development") {
        AUTOMATION_PROFILE_CONFIG.push(
            {
                title: "Greeting Flow",
                subtitle:
                    "Greet your users with a custom message when they initiate a conversation with your organization.",
                IconComponent: GreetingIcon,
                children: (
                    <GreetingMessage
                        greetingFormik={parentFormik}
                        getFieldProps={parentFormik.getFieldProps}
                        setFormikState={setFormikState}
                        greetingMessage={greetingMessage}
                        followUpMessage={followUpMessage}
                        errors={parentFormik.errors}
                        touched={parentFormik.touched}
                    />
                )
            },
            {
                title: "Auto-Response",
                subtitle:
                    "Set an expectation and confirm reciept of a message by creating an auto-response once a user submits a message.",
                IconComponent: AutoResponseIcon,
                children: (
                    <AutoResponse
                        getFieldProps={parentFormik.getFieldProps}
                        autoResponse={autoResponse}
                        setFormikState={setFormikState}
                        autoResponseFormik={parentFormik}
                        errors={parentFormik.errors}
                        touched={parentFormik.touched}
                    />
                )
            }
        );
    }
    return (
        <>
            <div className={classNames(styles[classname], classname)}>
                {AUTOMATION_PROFILE_CONFIG.map((setting, index: number) => {
                    const itemProps = { ...setting, index, collapsedIndex, setCollapsedIndex };
                    if (index === 0) {
                        return (
                            <>
                                {" "}
                                <h2 className={styles[`${classname}-subtitle`]}>PROFILE DETAILS</h2>
                                <ProfileAutomationsItem {...itemProps} />
                            </>
                        );
                    }
                    if (index === 2) {
                        return (
                            <>
                                <h2 className={styles[`${classname}-subtitle`]}>AUTOMATIONS</h2>
                                <ProfileAutomationsItem {...itemProps} />
                            </>
                        );
                    }
                    return <ProfileAutomationsItem {...itemProps} />;
                })}
            </div>
            {formikState && <FormWasChanged formik={formikState} />}
        </>
    );
};
