import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Dropdown } from "../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { getConversationsQueues } from "../../requests/conversationsRequests";
import { getTags } from "src/requests/tagsRequests";
import { Position } from "@blueprintjs/core";
import { GroupedDropdownContent } from "../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import { Tag } from "../tag/tag";
import { ReactComponent as SearchIcon } from "../../mpd-library/icon/assets/search.svg";
import { dropdownListItemTransformer } from "src/mpd-library/dropdown/utils";
import { getAccountSettingsUsersRequest } from "src/requests";
import { Avatar } from "../avatar/avatar";
import { DropdownAdminsSelectedSingleItem } from "../dropdown-admins-selected-items/DropdownAdminsSelectedSingleItem";
import { createTagRequest } from "../../requests/tagsRequests";
import { TagDropdownListItem } from "../../mpd-library/dropdown/components/tag-dropdown-list-item/TagDropdownListItem";

const FETCH_ADMINS_REQUEST_PARAMS = { filter: "conversations", with_invited: false };
const ADMINS_DROPDOWN_INITIAL_ENTITES = [{ label: "None", value: null }];

const classname = "alert-reply-settings";

type ConversationsRoutingSettingsDropdownsProps = {
    routingSettings: any;
    setSelectedSettings: (value: any, key: string) => void;
};

export type AdminsIds = { [key: string]: boolean };

export type TagsIds = { [key: string]: boolean };

const FETCH_TAGS_DATA_PARAMS = {
    feature: "conversations"
};

export const ConversationsRoutingSettingsDropdowns = ({
    routingSettings = {},
    setSelectedSettings
}: ConversationsRoutingSettingsDropdownsProps) => {
    const { tags, admin, queue } = routingSettings || {};
    const [getDefaultQueueProcessing, setGetDefaultQueueProcessing] = useState<boolean>(false);

    const getInitQueues = async () => {
        try {
            setGetDefaultQueueProcessing(true);
            await getQueues({});
        } finally {
            setGetDefaultQueueProcessing(false);
        }
    };

    useEffect(() => {
        getInitQueues();
    }, []);

    const getQueues = async (params) => {
        const res = await getConversationsQueues({ ...params, query: params.q });
        return {
            ...res,
            data: {
                ...res.data,
                queues: res.data.queues.map((queue) => {
                    return { ...queue, label: queue.name, value: queue };
                })
            }
        };
    };

    const setSettings = (value, key: string) => {
        // refactor!!!
        if (key === "admin") {
            value.id = value.uuid;
        }
        setSelectedSettings(value, key);
    };

    const getAdmins = async (params) => {
        const res = await getAccountSettingsUsersRequest(params);
        const transformedOptions = dropdownListItemTransformer({
            options: res.data.users,
            labelPartsPaths: ["first_name", "last_name"],
            LeftIconComponent: ({ option: admin }) => (
                <Avatar
                    avatarUrl={admin.avatar || admin.profile_pic?.url}
                    user={admin}
                    size={24}
                    className={styles[`avatar-wrapper_list-item`]}
                />
            )
        });
        return {
            ...res,
            data: {
                ...res.data,
                users: transformedOptions
            }
        };
    };

    const createNewTagProps = useMemo(
        () => ({
            create: async (data) => await createTagRequest({ ...data, enable_for_conversations: true }),
            entityName: "tag"
        }),
        [createTagRequest]
    );

    const selectedQueue = useMemo(() => {
        return { ...(queue || {}), label: queue?.name, value: queue || {} };
    }, [queue]);

    return (
        <>
            <Dropdown
                selectedValue={selectedQueue}
                title={"Add to queue"}
                targetClassName={DropdownTargetClasses.FORM_SELECT}
                fetchDataRequest={getQueues}
                entityKey="queues"
                position={Position.BOTTOM}
                contentBlockHeight={233}
                onStaticOptionClick={(value) => {
                    setSettings(value, "queue");
                }}
                ArrowIconComponent
            />
            <Dropdown
                ArrowIconComponent
                usePortal
                allOption={ADMINS_DROPDOWN_INITIAL_ENTITES[0]}
                selectedValue={{
                    ...admin,
                    value: admin,
                    label: <DropdownAdminsSelectedSingleItem admin={admin} />
                }}
                title={"ASSIGN TO"}
                targetClassName={DropdownTargetClasses.FORM_SELECT}
                withSearch
                fetchDataRequest={getAdmins}
                fetchDataParams={FETCH_ADMINS_REQUEST_PARAMS}
                entityKey={"users"}
                position={Position.BOTTOM}
                searchInputPlaceholder="Search for agents..."
                onStaticOptionClick={(value) => {
                    setSettings(value, "admin");
                }}
                contentBlockHeight={320}
            />

            <div className={styles[`${classname}-tags-dropdown-wrapper`]}>
                <Dropdown
                    values={tags || []}
                    initialValue={tags}
                    title={"TAGS"}
                    searchInputPlaceholder={"Search for tags..."}
                    withSearch
                    LeftIconComponent={SearchIcon}
                    targetClassName={DropdownTargetClasses.FORM_SELECT}
                    fetchDataRequest={getTags}
                    fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                    entityKey="tags"
                    position={Position.BOTTOM}
                    contentBlockHeight={233}
                    CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => {
                        return (
                            <GroupedDropdownContent
                                {...props}
                                CustomItemComponent={(props) => <TagDropdownListItem {...props} />}
                                firstGroupName={"Exisiting tags"}
                                secondGroupName={"Add tags"}
                            />
                        );
                    }}
                    showClearButton
                    SelectedItemComponent={({ item, remove }) => <Tag label={item?.name} onDeleteClick={remove} />}
                    onChange={(value) => {
                        setSettings(value, "tags");
                    }}
                    ArrowIconComponent
                    createNewEntityProps={createNewTagProps}
                />
            </div>
        </>
    );
};

type ConversationsRoutingSettingsProps = ConversationsRoutingSettingsDropdownsProps & { withoutWrapperDiv: boolean };

export const ConversationsRoutingSettings = ({ withoutWrapperDiv, ...props }: ConversationsRoutingSettingsProps) => {
    if (withoutWrapperDiv) {
        return <ConversationsRoutingSettingsDropdowns {...props} />;
    }
    return (
        <div className={classNames(styles["alert-replies-settings-wrapper"], "alert-replies-settings-wrapper")}>
            <ConversationsRoutingSettingsDropdowns {...props} />
        </div>
    );
};
