import { IDateFormatProps } from "@blueprintjs/datetime";

import moment from "moment";

export const StyledDateInputType = {
    primary: "primary" as const
};

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export type StyledDateInputType = typeof StyledDateInputType[keyof typeof StyledDateInputType];

export const IStyledDateInputFormat: IDateFormatProps = {
    formatDate: (date) => moment(date).format("MMM DD, YYYY"),
    parseDate: (str) => moment(str, "MMM DD, YYYY").toDate(),
    placeholder: moment(new Date()).format("MMM DD, YYYY").toString()
};
