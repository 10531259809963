import { LoginState } from "../containers/signin/types";

export interface AuthState {
    error: string;
    loading: boolean;
    loginState: LoginState;
    usernameValue: string;
    passwordValue: string;
    oneTimePasswordValue: string;
    otpTokenValue: string;
    otpEmail: string;
    otpPhoneNumber: string;
    forgotPasswordValue: string;
    selectedOTPChannel: "sms" | "email";
    rememberMe: boolean;
    authenticated: boolean;
}

export const defaultAuthState: AuthState = {
    error: "",
    loading: false,
    loginState: LoginState.EnteringUsername,
    usernameValue: "",
    passwordValue: "",
    oneTimePasswordValue: "",
    otpTokenValue: "",
    selectedOTPChannel: "email",
    otpEmail: "",
    otpPhoneNumber: "",
    forgotPasswordValue: "",
    rememberMe: false,
    authenticated: false
};
