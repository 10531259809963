import { Text } from "@blueprintjs/core";
import { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { axiosInstance } from "src/actions";
import { getFirstAccessibleRoute } from "src/actions/common/utils";
import { onUpdateToast } from "src/actions/global/global";
import { AppState } from "src/store";
import { ToastTypes } from "../toast";
import styles from "./styles.module.scss";

type ErrorBoundaryState = {
    error: Error | null;
    errorInfo: React.ErrorInfo;
    sendErrorProcessing: boolean;
};
class ErrorBoundaryComp extends Component<any, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null, sendErrorProcessing: false };
    }

    async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        if (!this.state.sendErrorProcessing) {
            this.setState({ error, errorInfo });
            await this.onResetButtonClick(error, errorInfo);
        }
    }

    public onResetButtonClick = async (error, errorInfo) => {
        const { history, onUpdateToast } = this.props;
        try {
            this.setState({ sendErrorProcessing: true });
            process.env.NODE_ENV !== "development" &&
                (await axiosInstance.post(`/track_error`, {
                    message: error.message,
                    stack_trace: error.stack,
                    path: history.location.pathname
                }));
            setTimeout(() => {
                this.setState({ error: null, errorInfo: null });
                const path = getFirstAccessibleRoute();
                process.env.NODE_ENV !== "development" && history.replace(path);
                process.env.NODE_ENV === "development" &&
                    this.setState({ error: null, errorInfo: null, sendErrorProcessing: false });
                onUpdateToast({
                    value: `Error report was successfully sent`,
                    type: ToastTypes.saved
                });
                this.setState({ sendErrorProcessing: false });
            }, 1500);
        } catch (err) {
            onUpdateToast({
                value: `Error. Please try again a bit later`,
                type: ToastTypes.error
            });
        }
    };

    render() {
        const { errorInfo, error } = this.state;
        if (error) {
            return (
                <div className={styles["main-error-fallback-wrapper"]}>
                    <Text className={styles["title"]}>Error: {error.message}</Text>
                    <div className={styles["error-info-wrapper"]}>
                        {errorInfo?.componentStack?.split(`\n`).map((line, index) => {
                            if (!line) {
                                return null;
                            }
                            return (
                                <p key={index}>
                                    {line.split(/\((.*?)\)/g).map((linePiece, index) => {
                                        if (index === 1) {
                                            return (
                                                <span key={`${index}-trace-line`} className={styles["route"]}>
                                                    ({linePiece})
                                                </span>
                                            );
                                        } else {
                                            return <span>{linePiece}&nbsp;</span>;
                                        }
                                    })}
                                </p>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToast
        },
        dispatch
    );

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundaryComp));
