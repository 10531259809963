// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IDetailView } from "../../../";

import { BreadCrumbs } from "src/components";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";

export interface IDeveloperSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    routeProps: RouteComponentProps;
}

export class DeveloperSettings extends React.Component<IDeveloperSettingsProps> {
    public constructor(props: IDeveloperSettingsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.updateToolBar();
    }

    public render() {
        const { className } = this.props;
        const classes = "developers-settings";
        return <div className={classNames(classes, className)}>{/*  */}</div>;
    }

    private updateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Developers" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperSettings);
