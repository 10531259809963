// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

import { IToggleButtonsProps, StyledButton, ToggleButtons } from "src/components";

import "./styles.scss";
import { History } from "history";
import { useHistory } from "react-router";

export interface IBreadCrumbObj {
    text: string;
    pathname?: string;
}

interface IBreadCrumbsProps extends IProps {
    history?: History;
    toggleButtonProps?: IToggleButtonsProps;
    breadCrumbsList?: Array<IBreadCrumbObj>;
    avatar?: React.ReactNode;
    onBreadCrumbClick?: (breadCrumb: IBreadCrumbObj, index: number) => void;
}

export const BreadCrumbs: React.FunctionComponent<IBreadCrumbsProps> = (props) => {
    const { breadCrumbsList, className, toggleButtonProps, avatar, onBreadCrumbClick } = props;
    const history = useHistory();
    const classes = "bread-crumbs";

    return (
        <div className={classNames(classes, className)}>
            <ToggleButtons className={classes + "-toggle"} leftIcon={true} {...toggleButtonProps} />
            {!!avatar && avatar}
            {breadCrumbsList &&
                breadCrumbsList.map((breadCrumb: IBreadCrumbObj, index: number) => (
                    <div className={classes + "-bread-crumb-container"} key={index}>
                        <StyledButton
                            className={classNames(
                                classes + "-button",
                                index === breadCrumbsList.length - 1 && Classes.ACTIVE
                            )}
                            text={breadCrumb.text}
                            onClick={() => {
                                history && breadCrumb && breadCrumb.pathname && history.push(breadCrumb.pathname);
                                onBreadCrumbClick?.(breadCrumb, index);
                            }}
                        />
                        {index !== breadCrumbsList.length - 1 && <Text className={classes + "-divider"}> / </Text>}
                    </div>
                ))}
        </div>
    );
};

export default BreadCrumbs;
