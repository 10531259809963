// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps } from "@blueprintjs/core";

import "./styles.scss";
import { MPDIcon } from "src/mpd-library";
import { ReactComponent as PersonWhiteIcon } from "../../mpd-library/icon/assets/person-white.svg"

interface IImageSourceProps extends IProps {
    src?: string | ArrayBuffer | null;
    height?: number | string;
    width?: number | string;
    initials?: string;
    userId?: string;
    noRandomColorBackground?: boolean;
    profile_pic_color?: string;
}

interface IImageSourceState {
    loading?: boolean;
}

const PROPORTION = 2.4;

export class ImageSource extends React.PureComponent<IImageSourceProps, IImageSourceState> {
    constructor(props: IImageSourceProps) {
        super(props);

        this.state = {
            loading: true
        };
    }

    public onImageLoaded = () => {
        this.setState({ loading: false });
    };

    public render() {
        const { className, height, width, src, initials, profile_pic_color, size } = this.props;
        const { loading } = this.state;
        const classes = "image-source";

        return (
            <div className={classNames(classes, className)} style={{ height, width }}>
                {loading && <div className={classes + "-filler"} />}
                <>
                    <img
                        key={`${src}`}
                        src={`${src}`}
                        className={classes + "-src"}
                        onLoad={this.onImageLoaded}
                        style={{ display: !loading && src ? "block" : "none" }}
                        alt={"source"}
                    />
                    <div
                        className={"before-avatar-loaded-wrapper"}
                        style={{ display: loading && src ? "flex" : "none" }}
                    >
                        <MPDIcon IconComponent={PersonWhiteIcon} />
                    </div>
                    {!src && (
                        <div
                            style={{
                                backgroundColor: profile_pic_color || "#2684F7",
                                fontSize: `${Math.floor(size / PROPORTION)}px`
                            }}
                            className={classes + "-initials"}
                        >
                            {" "}
                            {initials}{" "}
                        </div>
                    )}
                </>
            </div>
        );
    }
}
