import { AxiosResponse } from "axios";
import { axiosInstance } from "src/actions";

export type GetPhoneNumbersResponsePhoneNumber = {
    iso_country: string;
    phone_number: string;
    phone_number_formatted: string;
    region: string;
};

export type GetPhoneNumbersResponse = {
    available_phone_numbers: Array<GetPhoneNumbersResponsePhoneNumber>;
};

export const getPhoneNumbers = async (params: {
    country_code: string;
    query?: string;
}): Promise<AxiosResponse<GetPhoneNumbersResponse>> => {
    return await axiosInstance.get("/channels/sms_voice/search_phone_number", {
        params
    });
};

export const createSmsVoiceChannel = async (body: any): Promise<AxiosResponse<any>> => {
    return await axiosInstance.post("/channels/sms_voice/create", body);
};

export const updateSmsVoiceChannel = async (channelId: string, body: any): Promise<AxiosResponse<any>> => {
    return await axiosInstance.patch(`/channels/sms_voice/${channelId}`, body);
};
