export const CHANNEL_TYPE_MATCH_ICON = {
    facebook: "channelsFacebook",
    instagram: "channelsInstagram",
    twitter: "channelsTwitter",
    atlas_one: "channelsAtlasOne",
    sms: "channelsSms",
    email: "channelsMail",
    phone_number: "channelsPhone"
};

export const PHONE_REGEXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
