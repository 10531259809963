import classNames from "classnames";
import { useEffect, useState, memo } from "react";
import * as mpdClasses from "src/mpd-library";

import { IPopoverProps, ITooltipProps } from "@blueprintjs/core";

import "./styles.scss";

import { IMPDScrollProps, States } from "../";
import { History } from "history";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";

export interface IMPDNavBarItemProps extends IMPDScrollProps {
    activePathname?: string;
    disableRouting?: boolean;
    pathname: string;
    history?: History;
    navItemElement?: JSX.Element;
    popOverProps?: IPopoverProps;
    toolTipProps?: ITooltipProps;
    notSelectable?: boolean;
    onItemClick?: (id: string) => void;
}

const classes = "mpd-navbar-item";

export const MPDNavBarItem = memo(
    ({
        className,
        navItemElement,
        popOverProps,
        toolTipProps,
        activePathname,
        pathname,
        notSelectable,
        onItemClick
    }: IMPDNavBarItemProps) => {
        const [itemState, setItemState] = useState(null);
        const [showTooltip, setShowTooltip] = useState(false);

        useEffect(() => {
            const newItemState = activePathname.includes(pathname) && !notSelectable ? States.selected : States.default;
            setItemState(newItemState);
        }, [activePathname, pathname, notSelectable]);

        const onClick = () => {
            if (onItemClick) {
                onItemClick(pathname);
            }
            setShowTooltip(false);
        };

        const onHover = () => {
            setShowTooltip(true);
        };

        const onLeave = () => {
            setShowTooltip(false);
        };

        const renderTooltip = () => {
            return (
                <Tooltip2
                    portalClassName={classNames(classes + "-tooltip", "mpd-simple-tooltip")}
                    isOpen={showTooltip}
                    {...toolTipProps}
                    hoverOpenDelay={0}
                >
                    <div
                        onMouseEnter={onHover}
                        onMouseLeave={onLeave}
                        className={"nav-item-element-wrapper"}
                        onClick={(e) => {
                            onClick(e);
                        }}
                    >
                        {navItemElement}
                    </div>
                </Tooltip2>
            );
        };

        return (
            <div className={classNames(classes, mpdClasses.statesClass(itemState), className)}>
                {popOverProps ? (
                    <Popover2 {...popOverProps} usePortal={true} hoverOpenDelay={500}>
                        {renderTooltip()}
                    </Popover2>
                ) : (
                    renderTooltip()
                )}
            </div>
        );
    }
);
