// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { IProps } from "@blueprintjs/core";

import { StyledCheckbox, StyledRadioGroup, StyledRadioGroupTypes, StyledSwitch } from "src/components";

import { Classes, MPDIcon } from "src/mpd-library";

import "./styles.scss";

interface IAccessPermissionsProps extends IProps {
    title?: string;
    disabledCheckboxes?: boolean;
    iconName?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    checkBoxList?: Array<any>;
    showSimple?: boolean;
    showSwitch?: boolean;
    radioButtonList?: any;
    selectedRadioValue?: any;
    onSwitchChange?: (event: React.SyntheticEvent) => void;
    onCheckBoxChange?: (id: number | string) => void;
    onRadioButtonClick?: () => void;
}

export const AccessPermissions: React.FunctionComponent<IAccessPermissionsProps> = (props) => {
    const {
        checkBoxList,
        disabledCheckboxes,
        showSimple,
        className,
        iconName,
        IconComponent,
        showSwitch,
        radioButtonList,
        selectedRadioValue,
        onCheckBoxChange,
        onSwitchChange,
        onRadioButtonClick,
        title
    } = props;
    const classes = "access-permissions-item";

    return (
        <div className={classNames(classes, className)}>
            <div className={classes + "-title-container"}>
                <div className={classes + "-image"}>
                    {IconComponent && <MPDIcon IconComponent={IconComponent} name={iconName} />}
                </div>
                <div className={classNames(classes + "-styled-switch", showSwitch && Classes.ACTIVE)}>
                    <StyledSwitch label={title} checked={!!showSimple} onChange={onSwitchChange} type="primary" />
                </div>
            </div>
            {showSimple && (
                <React.Fragment>
                    {checkBoxList &&
                        checkBoxList.map((checkbox: any, index: number) => (
                            <StyledCheckbox
                                key={index}
                                className={classNames(
                                    classes + "-checkbox",
                                    disabledCheckboxes && Classes.DISABLED,
                                    showSwitch && "show-switch"
                                )}
                                label={checkbox.text}
                                checked={checkbox.checked}
                                onChange={(e) => onCheckBoxChange?.(checkbox, e)}
                            />
                        ))}
                    {radioButtonList && (
                        <StyledRadioGroup
                            size={"small"}
                            className={classNames(disabledCheckboxes && Classes.DISABLED, "restyled-radio-group")}
                            onChange={(event) => {
                                onRadioButtonClick(event.target.value, event);
                            }}
                            type={StyledRadioGroupTypes.primary}
                            selectedValue={selectedRadioValue}
                            list={[
                                ...radioButtonList.map((radio: any) => ({
                                    label: radio.text,
                                    value: radio.id
                                }))
                            ]}
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default AccessPermissions;
