import { createContext, useCallback, useEffect, useState } from "react";
import { matchPath, Route, Switch, useHistory } from "react-router-dom";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { SmartLink, getSmartLinks } from "src/requests/smartLinksRequests";
import { SmartLinksEdit } from "./components/smart-link-edit/smart-link-edit";
import { SmartLinksList } from "./components/smart-links-list/smart-links-list";
import { useDataFetch } from "../../../../hooks/useDataFetch";
import { SmartLinkAdd } from "./components/smart-link-add/smart-link-add";

export const SamrtLinksContext = createContext({});

export const SmartLinks = () => {
    const [selectedSmartLink, setSelectedSmartLink] = useState<SmartLink | null>(null);
    const history = useHistory();

    const {
        entities: smartLinks,
        loading,
        updateEntites,
        deleteEntity
    } = useDataFetch({
        fetchDataRequest: getSmartLinks,
        entityKey: "smart_links",
        skeletonLoading: true,
        listItemHeight: 88
    });

    const onSmartLinkUpdate = useCallback(
        (updatedSmartLink: any) => {
            updateEntites({ [updatedSmartLink.id]: updatedSmartLink });
        },
        [smartLinks]
    );

    const onDeleteSmartLink = useCallback(
        (deletetableSmartLinkId: string) => {
            deleteEntity(deletetableSmartLinkId);
        },
        [smartLinks]
    );

    const onSmartLinkCreate = useCallback(
        (newSmartLink: SmartLink) => {
            setSelectedSmartLink(newSmartLink);
            updateEntites({ [newSmartLink.id]: newSmartLink });
        },
        [smartLinks]
    );

    useEffect(() => {
        const match = matchPath(history.location.pathname, {
            path: "/account-settings/smart-links/edit/:id"
        });
        if (match?.params?.id) {
            const selectedSmartLinkId = smartLinks.allIds.find(
                (smartLinkId: string) => smartLinkId === match?.params?.id
            );
            const selectedSmartLink = smartLinks.byId[selectedSmartLinkId];
            selectedSmartLink && setSelectedSmartLink(selectedSmartLink);
        }
    }, [smartLinks, history.location.pathname]);

    return (
        <Switch>
            <Route
                path={"/account-settings/smart-links"}
                exact
                render={() => (
                    <SmartLinksList
                        setSelectedSmartLink={setSelectedSmartLink}
                        smartLinks={smartLinks}
                        onSmartLinkUpdate={onSmartLinkUpdate}
                    />
                )}
            />
            <Route
                path={"/account-settings/smart-links/create"}
                render={() => (
                    <SmartLinkAdd onSmartLinkCreate={onSmartLinkCreate} selectedSmartLink={selectedSmartLink} />
                )}
            />
            <Route
                path={"/account-settings/smart-links/edit/:id"}
                render={() =>
                    loading && !selectedSmartLink ? (
                        <PageLoadingSpinner />
                    ) : (
                        <SmartLinksEdit
                            selectedSmartLink={selectedSmartLink}
                            setSelectedSmartLink={setSelectedSmartLink}
                            onDeleteSmartLink={onDeleteSmartLink}
                            onSmartLinkUpdate={onSmartLinkUpdate}
                        />
                    )
                }
            />
        </Switch>
    );
};
