import React, { SyntheticEvent } from "react";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import styles from "./styles.module.scss";

const classname = "dropdown-content";

interface DropdownContentProps {
    title?: string;
    loading?: boolean;
    header?: JSX.Element;
    content?: JSX.Element;
    footer?: JSX.Element;
    children?: JSX.Element;
    onOptionItemClick?: (evnet: SyntheticEvent) => void;
}

export const DropdownContent = ({ header, title, loading, content, footer, children }: DropdownContentProps) => {
    if (children) {
        <div className={styles[classname]}>{children}</div>;
    }
    return (
        <div className={styles[classname]}>
            {loading && <PageLoadingSpinner />}
            {!loading && (
                <>
                    {title && <h4>{title}</h4>}
                    {header && <>{header}</>}
                    {content && <>{content}</>}
                    {footer && footer}
                </>
            )}
        </div>
    );
};
