import {
    USER_SETTINGS_TOGGLE_RIGHTPANEL,
    USER_SETTINGS_MY_INFO_UPDATE,
    USER_SETTINGS_LOADING,
    UserSettingsActionTypes,
    USER_SETTINGS_EMAIL_PASS_OTP_UPDATE,
    USER_SETTINGS_PASSWORD_UPDATE,
    USER_SETTINGS_NOTIFICATIONS_UPDATE,
    USER_SETTINGS_SCHEDULE_UPDATE,
    USER_SETTINGS_PRIVILEGES_UPDATE,
    USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE,
    USER_SETTINGS_SAVED_EMAIL_INTERMEDIATE_VALUE_UPDATE,
    USER_SETTINGS_ACTYVITY_UPDATE,
    USER_SETTINGS_ACTYVITY_LOADING_STATUS_UPDATE
} from "../actions/user-settings/types";
import { UserSettingsState, defaultUserSettingsState } from "../store";

export const userSettingsReducer = (
    state: UserSettingsState = defaultUserSettingsState,
    action: UserSettingsActionTypes
): UserSettingsState => {
    switch (action.type) {
        case USER_SETTINGS_MY_INFO_UPDATE:
            return {
                ...state,
                myInfo: action.payload
            };
        case USER_SETTINGS_TOGGLE_RIGHTPANEL:
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        case USER_SETTINGS_PRIVILEGES_UPDATE:
            return {
                ...state,
                privileges: action.payload
            };
        case USER_SETTINGS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case USER_SETTINGS_EMAIL_PASS_OTP_UPDATE:
            return {
                ...state,
                emailPassOTP: action.payload
            };
        case USER_SETTINGS_PASSWORD_UPDATE:
            return {
                ...state,
                password: action.payload
            };
        case USER_SETTINGS_NOTIFICATIONS_UPDATE:
            return {
                ...state,
                notifications: action.payload
            };
        case USER_SETTINGS_SCHEDULE_UPDATE:
            return {
                ...state,
                schedule: action.payload
            };
        case USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE:
            return {
                ...state,
                emailPassChangeError: action.payload
            };
        case USER_SETTINGS_SAVED_EMAIL_INTERMEDIATE_VALUE_UPDATE:
            return {
                ...state,
                savedEmailIntermediateValue: action.payload
            };
        case USER_SETTINGS_ACTYVITY_UPDATE:
            return {
                ...state,
                activity: {
                    ...state.activity,
                    list: action.payload
                }
            };
        case USER_SETTINGS_ACTYVITY_LOADING_STATUS_UPDATE:
            return {
                ...state,
                activity: {
                    ...state.activity,
                    loading: action.payload
                }
            };
        default:
            return state;
    }
};
