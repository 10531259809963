import { axiosInstance } from "../actions";
import { getConfig } from "../common";
import { MediaData, MediaPostResponse } from "./commonApi";
import { AxiosResponse } from "axios";

export async function postMediaRequest(updatedObj: MediaData): Promise<AxiosResponse<MediaPostResponse>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/media/presigned_url`, updatedObj);
}

export async function deleteMediaRequest(uuid: string): Promise<AxiosResponse<MediaPostResponse>> {
    const config = getConfig();

    return await axiosInstance.delete(`${config.API_URL}/media/${uuid}`);
}
