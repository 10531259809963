import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import omit from "lodash/omit";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import { normalize } from "../../../hooks/useDataFetch";
import { Option } from "src/mpd-library/dropdown/types";

export interface UseSelectedExistingItemsHookProps {
    diffDisabled?: boolean;
    allItems: NormalizedOptions;
    values: Array<Option>;
    hasOnApply?: boolean;
}

type NormalizedOptions = {
    allIds: Array<string>;
    byId: { [key: string]: Option };
};

const EMPTY_INITIAL_STATE = {
    allIds: [],
    byId: {}
};

export interface UseSelectedExistingItemsHook {
    selectedItems: NormalizedOptions;
    unselectedItems: NormalizedOptions;
    onAllItemClick: (option: Option, checked: boolean) => NormalizedOptions;
    onSelectDeselect: (option: Option, checked: boolean) => NormalizedOptions;
    select: (option: Option) => NormalizedOptions;
    deselect: (option: Option) => NormalizedOptions;
    clear: () => NormalizedOptions;
    revert: () => NormalizedOptions;
}

export const useSelectedExistingItemsHook = ({
    hasOnApply,
    values,
    allItems,
    diffDisabled
}: UseSelectedExistingItemsHookProps): UseSelectedExistingItemsHook => {
    const [allItemSelected, setAllItemSelected] = useState(false);
    const wasInit = useRef<boolean>(false);
    const [selectedItemsTemp, setSelectedItemsTemp] = useState<any>({});

    const selectedItems = useMemo(() => {
        if (!values) {
            return undefined;
        }
        const reduced = values?.reduce((acc, entity) => {
            if (entity.isSelectAllOption) {
                setAllItemSelected(true);
            }

            acc.push({
                ...entity,
                checked: true,
                checkedUpdated:
                    hasOnApply && selectedItemsTemp?.byId?.[entity.id]
                        ? selectedItemsTemp?.byId?.[entity.id].checkedUpdated
                        : true
            });

            return acc;
        }, []);

        const normalized = normalize(reduced || [], EMPTY_INITIAL_STATE, "id");

        hasOnApply && !wasInit.current && setSelectedItemsTemp(normalized);

        wasInit.current = true;
        return normalized;
    }, [values, selectedItemsTemp]);

    useEffect(() => {
        if (!hasOnApply) {
            wasInit.current = true;
        }
    }, []);

    const unselectedItems = useMemo(() => {
        if (diffDisabled) {
            return;
        }

        console.log("unselectedItems memo >>>>", values);

        if (hasOnApply) {
            // const res = allItems?.allIds.filter((entity) => {
            //     return !entity.checked;
            // });

            const diff = difference(allItems?.allIds, selectedItems?.allIds, "id");
            const res = allItems.allIds.reduce(
                (acc, id) => {
                    const entity = allItems.byId[id];
                    console.log("entity OH", id, ">>>", selectedItems?.byId);
                    if (!selectedItems?.byId[id]?.checked) {
                        return {
                            ...acc,
                            allIds: [...acc.allIds, id],
                            byId: {
                                ...acc.byId,
                                [id]: entity
                            }
                        };
                    }

                    return acc;
                },
                { allIds: [], byId: allItems.byId }
            );

            return res;
        }

        const res = difference(allItems?.allIds, selectedItems?.allIds, "id");
        return {
            allIds: res,
            byId: res.reduce((acc, id) => {
                return {
                    ...acc,
                    [id]: { ...allItems?.byId[id], checked: false, checkedUpdated: false }
                };
            }, {})
        };
    }, [selectedItems, allItems, diffDisabled, values]);

    const deselect = (option) => {
        let updatedState;
        if (hasOnApply) {
            updatedState = {
                ...selectedItems,
                byId: {
                    ...selectedItems?.byId,
                    [option.id]: {
                        ...option,
                        checkedUpdated: false
                    }
                }
            };
        } else {
            updatedState = {
                allIds: selectedItems.allIds.filter((optionId) => optionId !== option.id),
                byId: { ...omit(selectedItems.byId, option.id) }
            };
        }
        setSelectedItemsTemp(updatedState);
        return updatedState;
    };

    const select = useCallback(
        (option) => {
            let updatedState;
            if (allItemSelected) {
                updatedState = {
                    allIds: [option.id],
                    byId: {
                        ...selectedItems.byId,
                        [option.id]: option
                    }
                };
                setAllItemSelected(false);
                return updatedState;
            }

            if (hasOnApply) {
                updatedState = {
                    allIds: [...values.map((entity) => entity.id), option.id],
                    byId: {
                        ...values.reduce((acc, entity) => {
                            acc[entity.id] = { ...entity, ...(selectedItems.byId[entity.id] || {}) };
                            return acc;
                        }, {}),
                        [option.id]: {
                            ...option,
                            checked: false,
                            checkedUpdated: true
                        }
                    }
                };
            } else {
                updatedState = {
                    allIds: [...selectedItems.allIds, option.id],
                    byId: {
                        ...selectedItems.byId,
                        [option.id]: option
                    }
                };
            }

            setSelectedItemsTemp(updatedState);
            return updatedState;
        },
        [allItemSelected, selectedItems]
    );

    const onSelectDeselect = (option: any, checked: boolean) => {
        if (checked) {
            return select(option);
        } else {
            return deselect(option);
        }
    };

    const clear = () => {
        return EMPTY_INITIAL_STATE;
    };

    const onAllItemClick = (option, checked) => {
        if (checked) {
            setAllItemSelected(true);
            const updated = { allIds: [option.id], byId: { [option.id]: option } };
            return updated;
        } else {
            setAllItemSelected(false);
            return clear();
        }
    };

    const revert = () => {
        const checkedUpdatedReverted = selectedItems.allIds.map((itemId) => {
            const item = selectedItems.byId[itemId];
            return {
                ...item,
                checkedUpdated: item.checked
            };
        });
        const updated = normalize(checkedUpdatedReverted || [], EMPTY_INITIAL_STATE, "id") || EMPTY_INITIAL_STATE;
        return updated;
    };

    return {
        selectedItems,
        unselectedItems,
        onAllItemClick,
        onSelectDeselect,
        select,
        deselect,
        clear,
        revert
    };
};
