import {
    // ALERT_UPDATE,
    TOGGLE_ALERT_RIGHTPANEL,
    UPDATE_ALERT_COMPOSE_DATA,
    UPDATE_ALERT_STATE,
    AlertActionTypes,
    UPDATE_ALERT_LIST,
    UPDATE_ALERT_SEARCH_LIST,
    UPDATE_ALERT_TAB_ID,
    UPDATE_SELECTED_ALERT,
    UPDATE_ALERT_COMPOSE_DATA_ERRORS,
    CHANGE_CREATE_ALERT_PROCESSING,
    UPDATE_SOCIAL_MEDIA_DESIGN,
    UPDATE_SOCIAL_MEDIA_THEME,
    SET_ALERT_GENERATED_PREVIEW,
    UPDATE_SAVE_STEP_PROCESSING,
    UPDATE_GENERATE_PREVIEW_PROCESSING_STATUS,
    ADD_FILES_FOR_UPDATE,
    SET_ALERT_REPLIES_SETTINGS,
    SET_ALLOW_REPLIES,
    SET_EVENTS_MATHING_TABLE,
    SET_EVENTS_MATCHING_TABLE_LOADING
} from "../actions/alerts/types";
import { AlertsState, defaultAlertsState } from "../store";

export const alertsReducer = (state: AlertsState = defaultAlertsState, action: AlertActionTypes): AlertsState => {
    switch (action.type) {
        case UPDATE_ALERT_COMPOSE_DATA:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    ...action.payload
                }
            };
        case UPDATE_ALERT_STATE:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    ...action.payload
                }
            };
        case UPDATE_ALERT_COMPOSE_DATA_ERRORS:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    composeDataErrors: action.payload
                }
            };
        case UPDATE_ALERT_LIST:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    lists: {
                        ...state.alert.lists,
                        [action.payload.listName]: {
                            ...state.alert.lists[action.payload.listName],
                            ...action.payload.listData
                        }
                    }
                }
            };
        case UPDATE_ALERT_SEARCH_LIST:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    searchList: {
                        ...state.alert.searchList,
                        ...action.payload
                    }
                }
            };
        case TOGGLE_ALERT_RIGHTPANEL:
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        case UPDATE_ALERT_TAB_ID:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    tabId: action.payload
                }
            };
        case UPDATE_SELECTED_ALERT:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    selectedAlert: action.payload
                        ? {
                              ...(!action.shouldResetPrevData ? { ...state.alert.selectedAlert } : {}),
                              ...action.payload
                          }
                        : action.payload
                }
            };
        case CHANGE_CREATE_ALERT_PROCESSING:
            return { ...state, createAlertProcessing: action.payload };
        case UPDATE_SOCIAL_MEDIA_DESIGN:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    og_image_style: action.payload
                }
            };
        case UPDATE_SOCIAL_MEDIA_THEME:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    og_image_theme: action.payload
                }
            };
        case SET_ALERT_GENERATED_PREVIEW:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    alertDetails: {
                        ...state.composeData.alertDetails,
                        generatedPreviewLink: action.payload
                    }
                }
            };
        case UPDATE_SAVE_STEP_PROCESSING:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    saveStepProcessing: action.payload
                }
            };
        case UPDATE_GENERATE_PREVIEW_PROCESSING_STATUS:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    generatePreviewProcessingStatus: action.payload
                }
            };
        case ADD_FILES_FOR_UPDATE:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    multimediaForUpdate: action.payload
                }
            };
        case SET_ALLOW_REPLIES:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    allow_replies: action.payload
                }
            };
        case SET_ALERT_REPLIES_SETTINGS:
            return {
                ...state,
                composeData: {
                    ...state.composeData,
                    replies_settings: action.payload
                }
            };
        case SET_EVENTS_MATHING_TABLE: {
            return {
                ...state,
                eventsMatchingTableData: {
                    ...state.eventsMatchingTableData,
                    eventsMatchingTable: action.payload
                }
            };
        }
        case SET_EVENTS_MATCHING_TABLE_LOADING: {
            return {
                ...state,
                eventsMatchingTableData: {
                    ...state.eventsMatchingTableData,
                    loading: action.payload
                }
            };
        }
        default:
            return state;
    }
};
