import { memo, useEffect, useMemo, useState } from "react";
import { NumericInput, Position, Radio } from "@blueprintjs/core";
import moment from "moment";
import {
    DATA_TYPE_DEFAULT_COMPARISON,
    FILTER_SORT_DATE_VALUES,
    FILTER_SORT_TAGS_VALUES,
    FILTER_SORT_TYPE_VALUES
} from "./contastants";
import { StyledRadioGroup } from "../../../../../components/styled-radio-group";
import styles from "./FilterDropdownContent.module.scss";
import { Footer } from "../../../../../mpd-library/dropdown/footer/footer";
import { SettingsTitle, StyledButton, StyledButtonTypes } from "../../../../../components";
import { ContactsFilterType, getContactsLists, getImports } from "../../../../../requests/contactsRequests";
import { getTags } from "src/requests/tagsRequests";
import classNames from "classnames";
import { StyledDatePicker } from "../../../../../components/styled-datepicker/StyledDatePicker";
import { Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { PlacesFilters } from "./components/PlacesFilters";

const FETCH_TAGS_DATA_PARAMS = {
    feature: "contacts"
};

type FilterDropdownContentProps = {
    dataType: ContactsFilterType;
    alreadySelectedValue: any;
    error?: boolean;
    setValue: (value: any, aplied: boolean) => void;
    closeDropdown: () => void;
};

export const FilterDropdownContent = memo(({ ...props }: FilterDropdownContentProps) => {
    const { alreadySelectedValue, dataType, error, setValue, closeDropdown } = props;

    const [selectedValue, setSelectedValue] = useState<any>(
        alreadySelectedValue.comparison ? alreadySelectedValue : DATA_TYPE_DEFAULT_COMPARISON[dataType]
    );

    const [selectedValueMatch, setSelectedValueMatch] = useState<
        string | number | { value: string | number; label: string }
    >(
        (alreadySelectedValue.meta
            ? { value: alreadySelectedValue.value, meta: alreadySelectedValue.meta }
            : alreadySelectedValue.value) || ""
    );

    const onDateChange = (value) => {
        setSelectedValueMatch(moment(value).startOf("day").toISOString());
    };

    const onTagChange = (tag: { name: string; id: string }) => {
        setSelectedValueMatch({ value: tag.id, meta: { name: tag.name } });
    };

    const onChangeDaysAgo = (value: number) => {
        setSelectedValueMatch(value);
    };

    const onApplyClick = () => {
        setValue(
            {
                ...(selectedValue.id ? { id: selectedValue.id } : {}),
                comparison: selectedValue.comparison,
                value: selectedValueMatch.value || selectedValueMatch,
                isComparisonValueEditable: selectedValue.isComparisonValueEditable,
                error: selectedValue.isComparisonValueEditable && !selectedValueMatch,
                meta: selectedValueMatch.meta
            },
            true
        );
        closeDropdown();
    };

    useEffect(() => {
        if (!alreadySelectedValue.comparison) {
            setValue(
                {
                    ...alreadySelectedValue,
                    comparison: selectedValue.comparison,
                    isComparisonValueEditable: selectedValue.isComparisonValueEditable,
                    ...(selectedValue.id ? { id: selectedValue.id } : {})
                },
                false
            );
        }
    }, []);

    const onChangeSelectedValueMatch = (e) => {
        setSelectedValueMatch(e.target.value);
    };

    const renderFooter = () => {
        return (
            <Footer
                rightElement={<StyledButton type={StyledButtonTypes.primary} text={"Apply"} onClick={onApplyClick} />}
            />
        );
    };

    const dropdownTypeRelatedProps = useMemo(() => {
        switch (dataType) {
            case "tags":
                return {
                    fetchDataRequest: getTags,
                    entityKey: "tags",
                    fetchDataParams: FETCH_TAGS_DATA_PARAMS
                };
            case "lists":
                return {
                    fetchDataRequest: getContactsLists,
                    entityKey: "lists"
                };
            case "import_id":
                return {
                    fetchDataRequest: getImports,
                    entityKey: "imports"
                };
            default:
                return {};
        }
    }, [dataType]);

    if (dataType === "place") {
        return (
            <>
                <PlacesFilters
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    selectedValueMatch={selectedValueMatch}
                    setSelectedValueMatch={setSelectedValueMatch}
                />
                {renderFooter()}
            </>
        );
    }

    if (dataType === "tags" || dataType === "lists" || dataType === "import_id") {
        return (
            <>
                <StyledRadioGroup
                    className={styles["default"]}
                    selectedValue={selectedValue.comparison}
                    list={FILTER_SORT_TAGS_VALUES.map((item) => {
                        const { isComparisonValueEditable, label, value, comparison } = item;
                        return {
                            className: styles["radio-item"],
                            onClick: () => {
                                setSelectedValue(item);
                            },
                            value: comparison,
                            children: (
                                <div className={styles["radio-content"]}>
                                    {label}
                                    {isComparisonValueEditable && selectedValue.comparison === comparison && (
                                        <Dropdown
                                            ArrowIconComponent
                                            onStaticOptionClick={onTagChange}
                                            contentBlockWidth={209}
                                            contentBlockHeight={300}
                                            selectedValue={
                                                selectedValueMatch
                                                    ? { label: selectedValueMatch.meta?.name }
                                                    : { label: "Add Filter" }
                                            }
                                            targetClassName={DropdownTargetClasses.FILTER_COMPARISON_SELECT}
                                            position={Position.BOTTOM_LEFT}
                                            {...dropdownTypeRelatedProps}
                                        />
                                    )}
                                </div>
                            )
                        };
                    })}
                />
                {renderFooter()}
            </>
        );
    }

    if (dataType === "date") {
        return (
            <>
                <StyledRadioGroup
                    className={styles["date"]}
                    selectedValue={selectedValue.comparison}
                    children={FILTER_SORT_DATE_VALUES.map((item, index) => {
                        const { title, options } = item;
                        return (
                            <div className={styles["dates-comparisons"]}>
                                <SettingsTitle title={title} />
                                {options.map((option) => {
                                    const { label, value, isComparisonValueEditable, comparison, id } = option;
                                    return (
                                        <Radio
                                            className={styles["radio-item"]}
                                            onClick={() => {
                                                // setSelectedValueMatch("");
                                                if (isNaN(Number(selectedValueMatch.value?.value)) && index === 0) {
                                                    setSelectedValueMatch("");
                                                }
                                                setSelectedValue(option);
                                            }}
                                            checked={selectedValue.comparison === comparison && selectedValue.id === id}
                                            value={comparison}
                                            children={
                                                <div className={styles["radio-content"]}>
                                                    {label}
                                                    {isComparisonValueEditable &&
                                                        selectedValue.comparison === comparison &&
                                                        selectedValue.id === id && (
                                                            <>
                                                                {index === 0 ? (
                                                                    <div className={styles["numeric-input-wrapper"]}>
                                                                        <NumericInput
                                                                            min={0}
                                                                            autoFocus
                                                                            onValueChange={onChangeDaysAgo}
                                                                            value={selectedValueMatch.value}
                                                                            className={classNames(
                                                                                styles["value-input"],
                                                                                styles["value-input_numeric"],
                                                                                !selectedValueMatch &&
                                                                                    error &&
                                                                                    styles["error"]
                                                                            )}
                                                                            placeholder={"Enter a number..."}
                                                                        />
                                                                        <div className={styles["days-ago"]}>
                                                                            days ago
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <StyledDatePicker
                                                                        onChange={onDateChange}
                                                                        value={
                                                                            selectedValue.value &&
                                                                            moment(selectedValue.value.value).toDate()
                                                                        }
                                                                        maxDate={new Date()}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                </div>
                                            }
                                        />
                                    );
                                    // return {

                                    // };
                                })}
                            </div>
                        );
                    })}
                />
                {renderFooter()}
            </>
        );
    }

    return (
        <>
            <StyledRadioGroup
                className={styles["default"]}
                selectedValue={selectedValue.comparison}
                list={FILTER_SORT_TYPE_VALUES.map((item, index) => {
                    const { isComparisonValueEditable, label, value, comparison } = item;
                    console.log("ERR >>> >>> >>>", item);
                    return {
                        className: styles["radio-item"],
                        onClick: () => {
                            // setSelectedValueMatch("");
                            setSelectedValue(item);
                        },
                        value: comparison,
                        children: (
                            <div className={styles["radio-content"]}>
                                {label}
                                {isComparisonValueEditable && selectedValue.comparison === comparison && (
                                    <input
                                        autoFocus
                                        type={"text"}
                                        className={classNames(
                                            styles["value-input"],
                                            !selectedValueMatch && error && styles["error"]
                                        )}
                                        onChange={onChangeSelectedValueMatch}
                                        value={selectedValueMatch}
                                    />
                                )}
                            </div>
                        )
                    };
                })}
            />
            {renderFooter()}
        </>
    );
});

// {
//     FILTER_SORT_TYPE_VALUES.map((item) => {
//         const { isComparisonValueEditable, label, value, comparison } = item;
//         console.log("ERR >>> >>> >>>", item);
//         const proppps = {
//             className: styles["radio-item"],
//             onClick: () => {
//                 // setSelectedValueMatch("");
//                 setSelectedValue(item);
//             },
//             value: comparison,
//             children: (
//                 <div className={styles["radio-content"]}>
//                     {label}
//                     {isComparisonValueEditable && selectedValue.comparison === comparison && (
//                         <input
//                             // autoFocus
//                             type={"text"}
//                             className={classNames(
//                                 styles["value-input"],
//                                 !selectedValueMatch && error && styles["error"]
//                             )}
//                             onChange={onChangeSelectedValueMatch}
//                             value={selectedValueMatch}
//                         />
//                     )}
//                     {/* </Collapse> */}
//                 </div>
//             )
//         };
//         return <div {...proppps} />;
//     });
// }
