import { store } from "src";
import { onUpdateToast } from "../actions/global/global";

export const getKeys = (err) => {
    if (err?.response?.status === 422) {
        const keys = Object.keys(err.response.data.errors);
        return keys;
    }

    return [];
};

export const setFormikFieldErrors = (err, actions) => {
    if (err?.response?.status === 422) {
        getKeys(err).map((key) => {
            const error = err.response.data.errors?.[key]?.[0];
            actions.setFieldError(key, error);
            return key;
        });

        store.dispatch(onUpdateToast({ type: "error", value: "Validation error. Please check the form" }));
    }
};

export const getStepsWithErrors = (matchObject, keys) => {
    return keys?.reduce((acc, key: string) => {
        return {
            ...acc,
            [matchObject?.[key]]: true
        };
    }, {});
};
