import { Logo } from "src/requests";

export interface UserOnboardingErrors {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
}

export interface UserOnBoardingState {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    logo: Logo;
    errors: UserOnboardingErrors;
}

export const defaultUserOnBoardingState: UserOnBoardingState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    logo: {
        type: "",
        url: "",
        src: ""
    },
    errors: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
    }
};
