import classNames from "classnames";
import { Classes, Position } from "@blueprintjs/core";
import moment from "moment";

import styles from "./ContactsListSettingsItem.module.scss";
import { Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "../../../../../mpd-library/dropdown/components/target/constants";
import { ReactComponent as VerticalTripleLineIcon } from "src/mpd-library/icon/assets/vertical-triple-line.svg";
import { Avatar, StyledCheckbox, Tag } from "../../../../../components";
import { useHistory } from "react-router";
import { useCallback } from "react";
import { Tooltip, TooltipClsasses } from "../../../../../components/tooltip/tooltip";
import { TSelectItemTypes } from "../../../../../mpd-library";

const classname = "root";

export const ContactsListsHeader = ({ onSelectAll, indeterminate, checked }) => {
    const onCheckboxCLick = useCallback(
        (e) => {
            onSelectAll(indeterminate ? false : e.target.checked);
        },
        [indeterminate]
    );

    return (
        <div className={classNames(styles[classname], styles[`header`])}>
            <span>
                <StyledCheckbox
                    className={styles[`checkbox`]}
                    indeterminate={indeterminate}
                    checked={checked}
                    onChange={onCheckboxCLick}
                />
            </span>
            <span className={styles["header__label"]}>NAME</span>
            <span className={styles["header__label"]}>SIZE</span>
            <span className={styles["header__label"]}>TYPE</span>
            <span className={styles["header__label"]}>LAST UPDATE</span>
            <span className={styles["header__label"]}>CREATOR</span>
        </div>
    );
};

export const ContactsListSettingsItem = ({
    loadingState,
    item,
    style,
    checked,
    selectItem,
    onOpenEditNameDialog,
    onDeleteContactsList,
    onSelectList,
    checkboxDisabled
}) => {
    const history = useHistory();
    const onClick = () => {
        selectItem(item);
        history.push(`/account-settings/lists/${item.id}`);
    };
    return (
        <td className={styles[classname]} style={style} onClick={onClick}>
            {checkboxDisabled ? (
                <Tooltip
                    // isOpen={index === 0 && itemIndex === 0}
                    hoverOpenDelay={1000}
                    portalClassName={TooltipClsasses.primary}
                    position={Position.BOTTOM}
                    content={"Remove select all checkbox"}
                    tootltipTarget={
                        <StyledCheckbox
                            checked={checked}
                            className={styles[`checkbox`]}
                            onChange={(e) => onSelectList(e, item)}
                            disabled
                        />
                    }
                />
            ) : (
                <StyledCheckbox
                    checked={checked}
                    className={styles[`checkbox`]}
                    onChange={(e) => onSelectList(e, item)}
                    disabled={loadingState}
                />
            )}
            <div className={classNames(styles[`label`], styles["name"], loadingState && Classes.SKELETON)}>
                {item?.name}
            </div>
            <div className={classNames(styles[`label`], styles["count"], loadingState && Classes.SKELETON)}>
                {item?.count}
            </div>
            <div className={classNames(styles[`label`], loadingState && Classes.SKELETON)}>
                {item?.type === "static" ? "Static" : "Active"}
            </div>
            <div className={classNames(styles[`label`], loadingState && Classes.SKELETON)}>
                {moment(item?.updated_at).format("MMMM DD, YYYY") + " at " + moment(item?.updated_at).format("HH:mm A")}
            </div>
            <div className={classNames(styles[`label`], loadingState && Classes.SKELETON)}>
                <Avatar
                    user={item?.created_by_admin}
                    avatarUrl={item?.created_by_admin?.profile_pic?.url}
                    className={styles["admin-avatar"]}
                />
                <span>{item?.created_by_admin.first_name + " " + item?.created_by_admin.last_name}</span>
            </div>
            {!loadingState && (
                <div className={styles[`more-dropdown-wrapper`]}>
                    <Dropdown
                        disabled={loadingState}
                        id={"MOREDROPDOWN"}
                        staticOptions={[
                            {
                                label: "Edit List Name",
                                value: null,
                                onClick: () => {
                                    onOpenEditNameDialog(item);
                                }
                            },
                            {
                                label: "Delete permanently",
                                value: null,
                                onClick: () => onDeleteContactsList([item.id]),
                                type: TSelectItemTypes.delete
                            }
                        ]}
                        usePortal
                        targetClassName={classNames(DropdownTargetClasses.SELECT, DropdownTargetClasses.MORE)}
                        disableArrowAnimation
                        ArrowIconComponent={VerticalTripleLineIcon}
                        position={Position.BOTTOM_LEFT}
                        portalStopPropagationEvents={["click"]}
                        contentBlockWidth={220}
                    />
                </div>
            )}
        </td>
    );
};
