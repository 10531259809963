// tslint:disable jsx-no-lambda
import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BreadCrumbs, IToastType, ToastTypes } from "src/components";

import SocialSettings from "./channels/social-settings/social-settings";

import { IDetailView } from "../../../";

import { Route, Switch } from "react-router-dom";

import "./styles.scss";
import { AccountSettingsState } from "src/store";
import { SocialNetworksChannelsList } from "./components/social-networks-channels-list/social-networks-channels-list";
import ChannelInstallCallback from "src/containers/account-settings/general/channel-settings/components/channel-install-callback/channel-install-callback";
import { useReauthChannel } from "src/containers/conversations/components/chat/hooks/useReauthChannel";
import { onUpdateToolBar, onUpdateToast } from "src/actions/global/global";

interface IChannelSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    getChannelsArray: () => void;
    onUpdateToast: (toast: IToastType | ToastTypes) => void;
}

const accountTypes: Array<"facebook" | "twitter" | "instagram" | "sms_voice"> = [
    "facebook",
    "twitter",
    "instagram",
    "sms_voice"
];

const componentPathname = "/account-settings/channels";

const classes = "channel-settings";

const ChannelSettings = ({
    routeProps,
    onToggleRightPanel,
    onCloseRightPanel,
    onUpdateChangesMade
}: IChannelSettingsProps) => {
    const dispatch = useDispatch();

    const { onInstall, authChannelProcessing } = useReauthChannel();
    
    useEffect(() => {
        if (routeProps.location.pathname === componentPathname) {
            updateToolBar();
        }
    }, [routeProps]);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        {
                            text: "Channels",
                            pathname: "/account-settings/channels"
                        }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: onToggleRightPanel,
                        onClose: onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    return (
        <div className={classNames(classes)}>
            <Switch>
                <Route exact path="/account-settings/channels" render={() => <SocialNetworksChannelsList />} />
                {accountTypes.map((accountType) => {
                    return (
                        <Route
                            path={`/account-settings/channels/${accountType}/callback`}
                            render={() => (
                                <ChannelInstallCallback
                                    routeProps={routeProps}
                                    onUpdateToast={() => dispatch(onUpdateToast)}
                                    accountType={accountType}
                                />
                            )}
                        />
                    );
                })}
                {accountTypes.map((accountType) => {
                    return (
                        <Route
                            key={accountType}
                            path={`/account-settings/channels/${accountType}`}
                            render={() => (
                                <SocialSettings
                                    accountType={accountType}
                                    routeProps={routeProps}
                                    previousRoute={{
                                        text: "Channels",
                                        pathname: "/account-settings/channels"
                                    }}
                                    onCloseRightPanel={onCloseRightPanel}
                                    onToggleRightPanel={onToggleRightPanel}
                                    onUpdateChangesMade={onUpdateChangesMade}
                                    onInstallClick={onInstall}
                                    authChannelProcessing={authChannelProcessing}
                                />
                            )}
                        />
                    );
                })}
            </Switch>
        </div>
    );
};

export default ChannelSettings;
