import { Spinner } from "@blueprintjs/core";
import React, { memo } from "react";
import { Avatar } from "src/components";
import { MPDSelectItem } from "src/mpd-library";
import styles from "./styles.module.scss";
const classname = "user-dropdown-item";

interface UserDropdownItemProps {
    className: string;
    mention: any;
    selected?: boolean;
    assignableAdminId?: string;
    onClick: (event: React.SyntheticEvent) => void;
}

export const UserDropdownItem = memo(
    ({ className, mention, selected, assignableAdminId, onClick }: UserDropdownItemProps) => {
        return (
            <MPDSelectItem
                data-role-id={`${mention.id}`}
                className={`${className} ${styles[`${classname}-entry-component`]}`}
                onClick={onClick}
                key={mention.id}
                selected={selected}
            >
                <div className={styles[`${classname}-left-wrapper`]}>
                    <Avatar
                        avatarUrl={mention.avatar || mention.profile_pic?.url}
                        user={mention}
                        className={styles[`${classname}-avatar`]}
                    />
                    {mention.last_name && mention.first_name && (
                        <span className={`${styles[`mention-name`]} ${selected && styles["selected"]}`}>
                            {mention.first_name + " " + mention.last_name}
                        </span>
                    )}
                </div>
                {mention.id === assignableAdminId && <Spinner size={20} />}
            </MPDSelectItem>
        );
    }
);
