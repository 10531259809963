import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance } from "src/actions";
import { getConfig } from "src/common";
import { CONTACTS_MOCKED } from "../containers/contacts/contacts-list/contacts-list";
import moment from "moment";

type GetContactsRequestParams = {
    q?: string;
};

// export const getContactsRequest = async () => {
//     const res = await new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve({ data: { contacts: CONTACTS_MOCKED.slice(0, 50), has_more: true } });
//             // resolve({ data: { contacts: [], has_more: false } });
//         }, 3000);
//     });
//     return res;
//     // return await axios
// };

export const getContactsRequest = async (params?: GetContactsRequestParams) => {
    return await axiosInstance.post("/contacts", params);
};

type CreateContactParams = Partial<{
    first_name: string;
    last_name: string;
    channels: Array<{ type: "email" | "phone_number"; phone_type?: "mobile" | "landline"; identifier: string }>;
    tag_ids: Array<string>;
    list_ids: Array<string>;
}>;

export const createContact = async (body: CreateContactParams) => {
    return await axiosInstance.post(`/contacts/create`, body);
};

export const updateContact = async (id: string, body: CreateContactParams) => {
    return await axiosInstance.patch(`/contacts/${id}`, body);
};

export const getContactsLists = async (params) => {
    return await axiosInstance.get("/contacts/lists", { params });
};

export const getContactsSummary = () => {
    return axiosInstance.get("/contacts/summary");
};

export type ContactsFilterType = "personal_data" | "email" | "phone" | "tags" | "date" | "location" | "lists" | "place";

export type ContactsFilter = {
    field: string;
    label: string;
    type: ContactsFilterType;
};

export const getContactsFilters = async (): Promise<AxiosResponse<Array<ContactsFilter>>> => {
    try {
        const res = await axiosInstance.get("/contacts/filters");
        const match = res.data.filters.reduce((acc, filter) => {
            acc[filter.field] = filter;
            return acc;
        }, {});

        localStorage.setItem("contactsFiltersData", JSON.stringify(match));
        return res;
    } catch (err) {
        console.log("ERR >>>>", err);
    }
};

export type TCreateContactsListRequestParams = {
    name: string;
};

export const createContactsList = async (
    params: TCreateContactsListRequestParams
): Promise<AxiosResponse<Required<{ id: string }>>> => {
    return await axiosInstance.post("/contacts/lists/create", params);
};

export const assignTags = async (params: {
    contact_ids?: Array<string>;
    tag_ids: Array<string>;
    filters?: TFilter;
}) => {
    return await axiosInstance.post("/contacts/tags/assign", params);
};

export type AssignToListParams = {
    contact_ids?: Array<string>;
    list_ids: Array<string>;
    filters?: TFilter;
};

export const assignToList = async (params: AssignToListParams) => {
    return await axiosInstance.post("/contacts/lists/assign", params);
};

export type TCreateNewContactTagParams = {
    name: string;
};
export const createContactsTag = async (params: TCreateNewContactTagParams) => {
    return await axiosInstance.post("/contacts/tags/create", params);
};

export type CreateContactsViewParams = {
    name: string;
    filters: string;
};

export const createContactsView = async (params: CreateContactsViewParams) => {
    return await axiosInstance.post("/contacts/views/create", params);
};

export const importContacts = async (body: any): Promise<AxiosResponse<any>> => {
    return await axiosInstance.post("/contacts/import", body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const importContactFinal = async (body): Promise<AxiosResponse<any>> => {
    return await axiosInstance.post("/contacts/import", body);
};

export const getContact = async (id: string) => {
    return await axiosInstance.get(`/contacts/${id}`, {
        transformResponse: (data) => {
            data.contact.channels = data.contact.channels.map((channel) => ({ ...channel, id: uuidv4() }));
            return data;
        }
    });
};

export const starContact = async (id: string, value: boolean) => {
    return await axiosInstance.post(`/contacts/${id}/star`, { starred: value });
};

let count = 0;
let hours = 1;

export const getContactsActivities = async (params, id) => {
    return await axiosInstance.get(`/contacts/${id}/activities`);
};

export const deleteContacts = async ({ ids, filters }: { ids: Array<string>; filters: TFilter }) => {
    return await axiosInstance.delete(`/contacts`, {
        data: {
            ...(ids?.length ? { contact_ids: ids } : {}),
            ...(filters ? { filters } : {})
        }
    });
};

export const deleteView = async (id: string) => {
    return await axiosInstance.delete(`/contacts/views/${id}`);
};

export const getImports = async (params) => {
    return await axiosInstance.get("/contacts/imports", { params });
};

export const getContactsImportSpreadSheet = async () => {
    try {
        const response = await axiosInstance.get("/contacts/sample_spreadsheet.csv");
        const binaryData = [response.data];
        const href = URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "spreadsheet.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    } catch (err) {
        console.log("SPREADSHEET ERR >>>>", err);
    }
};

export const getContactsList = async (id: string) => {
    return await axiosInstance.get(`/contacts/lists/${id}`);
};

type TComparison =
    | "eq"
    | "neq"
    | "starts_with"
    | "ends_with"
    | "contains"
    | "not_contains"
    | "is_null"
    | "is_not_null"
    | "inside"
    | "outside"
    | "lt"
    | "gt";

export type TCondition = {
    comparison: TComparison;
};

export type TConditionGroup = {
    operator: "or" | "and";
    conditions: Array<TCondition>;
};

export type TFilter = {
    operator: "or" | "and";
    conditions: TConditionGroup;
};

export const deleteContactsLists = async (list_ids: Array<string> | TFilter) => {
    await axiosInstance.delete("/contacts/lists", { params: { list_ids } });
};

type UpdatebleListType = {
    id: string;
    name: string;
    filters: any;
};

export const updateContactList = async (list: UpdatebleListType) => {
    const body: Partial<UpdatebleListType> = {};
    const { name, filters, id } = list;

    name && (body.name = name);
    filters && (body.filters = filters);

    return await axiosInstance.patch(`/contacts/lists/${id}`, body);
};

type SavedViewType = {
    id: string;
    filters: any;
};

export const updateContactsSavedView = async (updatedView: SavedViewType) => {
    return await axiosInstance.patch(`/contacts/views/${updatedView.id}`, updatedView);
};

export const getContactsSettings = async () => {
    return await axiosInstance.get("/contacts/settings");
};

export const updateContactsSettings = async (body) => {
    return await axiosInstance.patch("/contacts/settings", body);
};
