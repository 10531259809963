import { Text } from "@blueprintjs/core";
import { MPDIcon } from "../../../../../../mpd-library";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as DownArrowIcon } from "src/mpd-library/icon/assets/down-arrow.svg";

type TitleWIthDeviderProps = {
    title: string;
    index: number;
    prevStickyElemTop: number;
    height: number;
    id: string;
    isDetailsExpanded: boolean;
};

export const TitleWIthDevider = ({
    title,
    index = 0,
    height = 54,
    prevStickyElemTop,
    id,
    onClick,
    arrowState,
    isDetailsExpanded
}: TitleWIthDeviderProps) => {
    const onTitleClick = () => {
        onClick();
        const ref = document.getElementById(id);
        if (ref) {
            ref.scrollIntoView({
                block: "end"
            });
        }
    };
    return (
        <>
            {isDetailsExpanded && (
                <div
                    className={styles[`block-label`]}
                    style={{ top: `${prevStickyElemTop + index * height}px` }}
                    onClick={onTitleClick}
                >
                    <Text>{title}</Text>
                    <MPDIcon
                        IconComponent={DownArrowIcon}
                        className={classNames(styles["arrow"], arrowState && styles["arrow_active"])}
                    />
                </div>
            )}
            <div className={styles[`devider`]} id={id} />
        </>
    );
};
