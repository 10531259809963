import { Classes, Text } from "@blueprintjs/core";
import moment from "moment";
import { MPDIcon } from "../../../../mpd-library";
import styles from "./RecentItem.module.scss";
import classNames from "classnames";
import { Tag } from "../../../../components";
import { TagTypes } from "../../../../components/tag/tag";
import { ReactComponent as ConversationTextIcon } from "../../../../mpd-library/icon/assets/conversation-text.svg";
import { ReactComponent as AlertFlashIcon } from "src/mpd-library/icon/assets/RecentAlertFlashIcon.svg";
import { useHistory } from "react-router";

interface RecentItemProps {
    item: any;
    type: "conversations" | "alerts";
}

const COLORS_MATCH = {
    assigned: "#1ACAA0",
    opened: "#4D4AE9",
    closed: "#FF1C52",
    resolved: "#FF1C52"
};

const TAG_LABEL_MATCH = {
    assigned: "OPEN",
    opened: "ENQUEUED",
    resolved: "CLOSED",
    closed: "CLOSED"
};

const ALERT_ICON_WRAPPER_STYLES = {
    extreme: {
        backgroundColor: "rgba(255, 46, 121, 1)"
    },
    severe: {
        backgroundColor: "#F98028"
    },
    minor: {
        backgroundColor: "rgba(25, 183, 117, 1)"
    },
    moderate: {
        backgroundColor: "rgba(52, 172, 224, 1)"
    }
};

const getFormattedData = (entity: any) => {
    switch (entity.type) {
        case "conversations": {
            const { admin, status, inserted_at } = entity;
            let title;

            // const tags = [<Tag label={TAG_LABEL_MATCH[status]} color={COLORS_MATCH[status]} type={TagTypes.tag} />];
            const tags = [
                { type: TagTypes.tag, label: TAG_LABEL_MATCH[status], color: COLORS_MATCH[status] },
                ...(entity?.tags?.map((tag) => {
                    return {
                        type: TagTypes.tag,
                        label: tag.name
                    };
                }) || [])
            ];
            if (admin) {
                title = (
                    <span>
                        Conversation with{" "}
                        <span className={styles["highlighted"]}>{admin.first_name + " " + admin.last_name}</span>
                    </span>
                );
            } else {
                title = <span>Unassigned Conversation</span>;
            }
            return {
                title,
                tags,
                subtitle: entity.last_message?.body || "",
                leftIcon: (
                    <div className={classNames(styles["left-icon-wrapper"], styles["left-icon-wrapper_convo"])}>
                        <MPDIcon IconComponent={ConversationTextIcon} />
                    </div>
                ),
                inserted_at: moment(inserted_at).fromNow()
            };
        }
        case "alerts":
            const { title, description, severity, inserted_at } = entity;
            return {
                title,
                subtitle: description,
                leftIcon: (
                    <div
                        className={classNames(styles["left-icon-wrapper"], styles["left-icon-wrapper_convo"])}
                        style={ALERT_ICON_WRAPPER_STYLES[severity]}
                    >
                        <MPDIcon IconComponent={AlertFlashIcon} />
                    </div>
                ),
                inserted_at: moment(inserted_at).fromNow()
            };
        default:
            return {};
    }
};

export const RecentItem = ({ item, loading, type }: RecentItemProps) => {
    const { title, subtitle, tags, leftIcon, inserted_at } = getFormattedData(item);
    const history = useHistory();

    const onClick = () => {
        if (type === "alerts") {
            history.push(`/${type}/id-${item.id}`);
        } else {
            history.push(`/${type}/${item.id}`);
        }
    };
    return (
        <div className={styles["root"]} onClick={onClick}>
            {!!loading ? (
                <div className={classNames(styles["left-icon-wrapper"], loading && Classes.SKELETON)} />
            ) : (
                leftIcon
            )}
            <div className={styles["main-content"]}>
                <div className={styles["main-content__top-wrapper"]}>
                    <Text
                        className={classNames(styles["main-content__top-wrapper__title"], loading && Classes.SKELETON)}
                    >
                        {title}
                        <div className={styles["tags-wrapper"]}>
                            {tags?.map((tagProps) => {
                                return <Tag {...tagProps} />;
                            })}
                        </div>
                        <div className={styles["ago"]}>{inserted_at}</div>
                    </Text>
                </div>
                <Text className={classNames(styles["main-content__subtitle"], loading && Classes.SKELETON)}>
                    {subtitle}
                </Text>
            </div>
        </div>
    );
};
