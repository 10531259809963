// tslint:disable jsx-no-lambda
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { Classes } from "src/mpd-library";

import { IProps } from "@blueprintjs/core";

import {
    StyledFloatingInput,
    StyledFloatingInputTypes,
    ImageUpload,
    SettingsTitle,
    StyledTextArea,
    StyledTextAreaTypes,
    StyledFloatingPhoneInputTypes,
    StyledFloatingPhoneInput
} from "src/components";

import "./styles.scss";
import { AccountOrganzationDetails, AccountOrganizationError } from "src/store";
import { useFormik } from "formik";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { StyledAlert, StyledAlertTypes } from "../../../../../components";
import { ChooseNumber } from "../../../general/channel-settings/channels/sms-voice-flow/components/choose-number/choose-number";
import * as PhoneInputClasses from "src/mpd-library/floating-phone-input/classes";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store";
import * as Yup from "yup";
import { PHONE_REGEXP } from "src/common/constants";

export interface AccountBrandingSettingsEditProps extends IProps {
    currentPlan: string | undefined;
    details: AccountOrganzationDetails;
    errors: AccountOrganizationError;
    onPhoneNumberChange: (value: any, type: string) => void;
    onBlur: (type: string) => void;
    onChangeInput: (event: React.SyntheticEvent, type: string) => void;
    onUploadImage: (event: React.SyntheticEvent) => void;
    onSave: (event: Object) => void;
}

export const AccountBrandingSettingsEdit: React.FunctionComponent<AccountBrandingSettingsEditProps> = (props) => {
    const {
        details,
        details: { description, url, phone, email, twitter, logo, shortTitle },
        errors,
        className,
        onUploadImage,
        onChangeInput,
        onPhoneNumberChange,
        onBlur,
        onSave
    } = props;

    const { mainCountries } = useSelector((state: AppState) => state.accountSettings);
    const { branded_phone_number, vanity_url_subdomain } = useSelector(
        (state: AppState) => state.accountSettings.organization.details
    );
    const [brandingPhoneNumber, setBrandingPhoneNumber] = useState<string | null>(branded_phone_number || null);
    const brandingPhoneNumberTemp = useRef(null);

    const formik = useFormik({
        initialValues: { ...details, branded_phone_number, vanity_url_subdomain },
        enableReinitialize: true,
        validationSchema: Yup.object({
            description: Yup.string(),
            url: Yup.string().required("Can't be blank"),
            phone: Yup.string().matches(PHONE_REGEXP, "Phone number is not valid"),
            email: Yup.string().email("Enter valid email").nullable(),
            twitter: Yup.string().required("Can't be blank"),
            shortTitle: Yup.string().required("can't be blank"),
            branded_phone_number: Yup.string().nullable(),
            vanity_url_subdomain: Yup.string().nullable()
        }),
        onSubmit: async (values, { setSubmitting }) => {
            await onSave(values);
            setSubmitting(false);
        }
    });

    const brandedPhoneNumberFormik = useFormik({
        initialValues: {
            branded_phone_number
        },
        validationSchema: Yup.object({
            branded_phone_number: Yup.object().shape({
                iso_country: Yup.string(),
                phone_number: Yup.string(),
                phone_number_formatted: Yup.string(),
                region: Yup.string()
            })
        }),
        onSubmit: () => null
    });

    const addPhoneNumber = async () => {
        const account = await onSave({
            ...details,
            branded_phone_number: brandingPhoneNumberTemp.current.phone_number
        });
        setBrandingPhoneNumber(account.branded_phone_number);
    };

    const { handleSubmit, touched, getFieldProps } = formik;

    const phoneNumberChange = (value) => {
        onPhoneNumberChange(value, "phone");
        formik.setFieldValue("phone", value);
    };

    const selectNumber = (value: any) => {
        brandingPhoneNumberTemp.current = value;
        brandedPhoneNumberFormik.setFieldValue("branded_phone_number", value);
        brandedPhoneNumberFormik.setFieldTouched("branded_phone_number");
    };

    const confirmButtonProps = useMemo(
        () => ({
            disabled: !brandedPhoneNumberFormik.values.branded_phone_number
        }),
        [brandedPhoneNumberFormik.values.branded_phone_number]
    );

    const classes = classNames("account-branding-edit", className);

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className={classNames(Classes.CONTAINER_PADDING, Classes.ROW, Classes.RESPONSIVE)}
            >
                <div className={Classes.COLUMN}>
                    <div className={classes + "-agency-details"}>
                        <SettingsTitle title="Agency Details" />
                        <div
                            className={classNames(
                                classes + "-agency-details-item-container",
                                Classes.ASSIGN_BLOCK_PADDING
                            )}
                        >
                            <div className={classes + "-info-wrapper"}>
                                <ImageUpload
                                    className={classes + "-icon-block"}
                                    src={logo.src}
                                    title="Edit Profile Picture"
                                    onUploadImage={onUploadImage}
                                />

                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={shortTitle || ""}
                                    onChange={(e) => onChangeInput(e, "shortTitle")}
                                    // onBlur={() => this.props.validator("title")}
                                    placeholder="Short Title"
                                    tabIndex={1}
                                    error={touched.shortTitle && errors.shortTitle}
                                    {...getFieldProps("shortTitle")}
                                />
                                <StyledTextArea
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    value={description || ""}
                                    disabled={false}
                                    onChange={(e) => onChangeInput(e, "description")}
                                    type={StyledTextAreaTypes.primaryStatic}
                                    placeholder="About"
                                    tabIndex={2}
                                    rows={5}
                                    maxCounter={320}
                                    {...formik.getFieldProps("description")}
                                    // onBlur={type === "place" ? () => validator(address || "", "address") : () => null}
                                    // error={type === "place" ? error && error.address : ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes + "-agency-details"}>
                        <SettingsTitle title="BRANDED PHONE NUMBER" />
                        {brandingPhoneNumber ? (
                            <StyledFloatingPhoneInput
                                floatingType={StyledFloatingPhoneInputTypes.primary}
                                placeholder="PHONE NUMBER"
                                tabIndex={3}
                                value={brandingPhoneNumber || ""}
                                disabled
                                // {...formik.getFieldProps("phone")}
                                onChange={phoneNumberChange}
                                className={PhoneInputClasses.FLOATING_PHONE_INPUT_CONTAINER}
                                customCountriesList={mainCountries}
                                // onBlur={() => validator(action.phone_number, "phone_number")}
                                // error={action.error && action.error.phone_number}
                            />
                        ) : (
                            <StyledAlert
                                Target={(props) => (
                                    <div className={"branded-phone-number"}>
                                        <div
                                            className={"branded-phone-number__title"}
                                            // onClick={() => setSelectPhoneModalOpen(true)}
                                            {...props}
                                        >
                                            + Add Branded Phone Number
                                        </div>
                                        <div className={"hint"}>
                                            Add a branded phone line with a local area code that is recognizable to your
                                            community.
                                        </div>
                                    </div>
                                )}
                                childrenWrapperClassName={"branding-phone-number-modal-children"}
                                // isOpen={selectPhoneModalOpen}
                                type={StyledAlertTypes.primary}
                                canOutsideClickCancel={true}
                                canEscapeKeyCancel={false}
                                onConfirm={addPhoneNumber}
                                confirmButtonProps={confirmButtonProps}
                                confirmButtonText={"Create"}
                                cancelButtonText={"Cancel"}
                                // className={styles["create-convo-popup"]}
                                canEscapeKeyClose={false}
                                fullScreen
                                globalContentProps={{
                                    title: "Add Branded Phone Number",
                                    hideDescription: true
                                    // onCancel: () => setSelectPhoneModalOpen(false)
                                }}
                            >
                                <div className={`account-branding-chose-number-wrapper`}>
                                    <ChooseNumber
                                        formik={brandedPhoneNumberFormik}
                                        hideCapabilityInput
                                        selectNumber={selectNumber}
                                        formValueKey={"branded_phone_number"}
                                    />
                                </div>
                            </StyledAlert>
                        )}
                    </div>
                </div>
                <div className={Classes.COLUMN}>
                    <div className={classes + "-details-info"}>
                        <SettingsTitle title="Contact Information" />
                        <div
                            className={classNames(
                                classes + "-details-info-item-container",
                                Classes.ASSIGN_BLOCK_PADDING
                            )}
                        >
                            <div className={classes + "-info-wrapper"}>
                                <StyledFloatingPhoneInput
                                    floatingType={StyledFloatingPhoneInputTypes.primary}
                                    placeholder="PHONE NUMBER"
                                    tabIndex={3}
                                    value={phone || ""}
                                    {...formik.getFieldProps("phone")}
                                    onChange={phoneNumberChange}
                                    className={PhoneInputClasses.FLOATING_PHONE_INPUT_CONTAINER}
                                    customCountriesList={mainCountries}
                                    error={formik.errors.phone}
                                    // onBlur={() => validator(action.phone_number, "phone_number")}
                                    // error={action.error && action.error.phone_number}
                                />
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={email || ""}
                                    disabled={false}
                                    onChange={(e) => onChangeInput(e, "email")}
                                    placeholder="EMAIL"
                                    tabIndex={4}
                                    {...formik.getFieldProps("email")}
                                    error={formik.errors.email}
                                />
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={twitter || ""}
                                    disabled={false}
                                    onChange={(e) => onChangeInput(e, "twitter")}
                                    placeholder="TWITTER"
                                    hint={"@username"}
                                    tabIndex={5}
                                    {...formik.getFieldProps("twitter")}
                                />{" "}
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={url || ""}
                                    disabled={false}
                                    onChange={(e) => onChangeInput(e, "url")}
                                    placeholder="WEBSITE URL"
                                    tabIndex={1}
                                    error={errors.url}
                                    {...formik.getFieldProps("url")}
                                    onBlur={() => onBlur("url")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes + "-details-info"}>
                        <SettingsTitle title="vanity url" />
                        <div className={"vanity"}>
                            <StyledFloatingInput
                                className={"vanity__input"}
                                autoCapitalize="none"
                                autoCorrect="none"
                                floatingType={StyledFloatingInputTypes.primary}
                                value={email || ""}
                                disabled={false}
                                // placeholder={"vanity url"}
                                tabIndex={4}
                                onChange={(e) => onChangeInput(e, "vanity_url_subdomain")}
                                {...formik.getFieldProps("vanity_url_subdomain")}
                            />
                            <span className={"vanity__tail"}>{`.${process.env.REACT_APP_API_HOSTNAME}`}</span>
                        </div>
                        <div className={"hint"}>
                            A vanity URL helps brand your organization when sharing Atlas One links with your residents.
                            Must be at least 5 characters.
                        </div>
                    </div>
                </div>
            </form>
            <FormWasChanged formik={formik} />
        </>
    );
};

export default AccountBrandingSettingsEdit;
