import { ReactNode, useMemo, ComponentType, memo, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { AppState } from "src/store";
import { PageLoadingSpinner } from "../page-loading-spinner/page-loading-spinner";

type ProtectedRouteProps = {
    path: string;
    exact?: boolean;
    component?: ComponentType;
    children?: ReactNode;
    forceSignout?: boolean;
    onCheckPermissionsToRoute?: () => boolean;
};

export function isAuthenticated(forceSignout?: boolean) {
    if (typeof localStorage !== "undefined" || localStorage !== null) {
        const auth = localStorage.getItem("auth");

        if (auth === null || forceSignout) {
            return false;
        }
        return true;
    }
    return false;
}

const ProtectedRoute = memo((props: ProtectedRouteProps) => {
    const { path, exact = false, component, children, onCheckPermissionsToRoute, ...rest } = props;
    const redirectAddress = useSelector((state: AppState) => state.global.firstAccessibleRoute);
    const privileges = useSelector((state: AppState) => state.userSettings?.privileges);

    const WrappedComponent: any = useMemo((): any => {
        if (!isAuthenticated()) {
            return () => <Redirect to={"/signin"} />;
        }

        if (path === "/") {
            return () => <Redirect to={redirectAddress} />;
        }

        if (!onCheckPermissionsToRoute) {
            return component;
        }
        return onCheckPermissionsToRoute() ? component : () => <Redirect to={redirectAddress} />;
    }, [privileges, component, children]);

    return (
        <Suspense fallback={<PageLoadingSpinner />}>
            <Route
                path={path}
                exact={exact}
                render={(routeProps) => {
                    return <WrappedComponent routeProps={routeProps} {...rest} />;
                }}
            >
                {children}
            </Route>
        </Suspense>
    );
});

export default ProtectedRoute;
