import {
    INCIDENT_MANAGEMENT_TOGGLE_RIGHTPANEL,
    INCIDENT_MANAGEMENT_TOGGLE_CHAT,
    INCIDENT_MANAGEMENT_PREVENT_TOGGLE,
    INCIDENT_MANAGEMENT_UPDATE,
    INCIDENT_MANAGEMENT_UPDATE_TABS,
    INCIDENT_MANAGEMENT_UPDATE_OPENED_ROUTE,
    IncidentManagementActionTypes
} from "../actions/incident-management/types";
import { IncidentManagementState, defaultIncidentManagementState } from "../store";

export const incidentManagementReducer = (
    state: IncidentManagementState = defaultIncidentManagementState,
    action: IncidentManagementActionTypes
) => {
    switch (action.type) {
        case INCIDENT_MANAGEMENT_TOGGLE_RIGHTPANEL:
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        case INCIDENT_MANAGEMENT_TOGGLE_CHAT:
            return {
                ...state,
                chatActive: action.payload
            };
        case INCIDENT_MANAGEMENT_PREVENT_TOGGLE:
            return {
                ...state,
                preventToggle: action.payload
            };
        case INCIDENT_MANAGEMENT_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        case INCIDENT_MANAGEMENT_UPDATE_TABS:
            return {
                ...state,
                tabs: action.payload
            };
        case INCIDENT_MANAGEMENT_UPDATE_OPENED_ROUTE:
            return {
                ...state,
                openedRoute: action.payload
            };
        default:
            return state;
    }
};
