import classNames from "classnames";
import React from "react";

import { IProps } from "@blueprintjs/core";

import { StyledButton, StyledButtonTypes } from "src/components";

import { StyledDuoButtonsTypes } from "./types";

import "./styles.scss";
import { IMPDButtonProps } from "src/mpd-library";

export interface IStyledDuoButtonsProps extends IProps {
    firstButtonText?: string;
    secondButtonText?: string;
    firstButtonProps?: IMPDButtonProps;
    secondButtonProps?: IMPDButtonProps;
    type?: Array<StyledDuoButtonsTypes> | StyledDuoButtonsTypes;
    firstButtonClick?(event: React.SyntheticEvent): void;
    secondButtonClick?(event: React.SyntheticEvent): void;
}

export const StyledDuoButtons: React.FunctionComponent<IStyledDuoButtonsProps> = (props) => {
    const {
        className,
        firstButtonClick,
        secondButtonClick,
        firstButtonText,
        secondButtonText,
        type,
        firstButtonProps,
        secondButtonProps
    } = props;

    const classes = "styled-duo-buttons";

    return (
        <div className={classNames(classes, type, className)}>
            {firstButtonText && (
                <StyledButton
                    className={classes + "-first-button"}
                    onClick={firstButtonClick}
                    text={firstButtonText}
                    type={StyledButtonTypes.secondary}
                    {...firstButtonProps}
                />
            )}
            {secondButtonText && (
                <StyledButton
                    className={classes + "-second-button"}
                    onClick={secondButtonClick}
                    text={secondButtonText}
                    type={StyledButtonTypes.primary}
                    {...secondButtonProps}
                />
            )}
        </div>
    );
};
