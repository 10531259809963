import { useCallback, useContext, useMemo } from "react";
import classNames from "classnames";
import { Position } from "@blueprintjs/core";

import styles from "../ContactsFilters.module.scss";
import { FiltersContext } from "../context";
import { Dropdown } from "src/mpd-library/dropdown/dropdown";
import { Tooltip, TooltipClsasses } from "../../tooltip/tooltip";
import { GroupContext } from "./FilterConditionComponent";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { ERROR_TOOTLTIP_TEXT, ICONS_TYPE_MATCH, LOGICAL_OPERATIONS_ITEMS } from "../constants";
import { ReactComponent as FilterDropdownPlusIcon } from "src/mpd-library/icon/assets/FilterDropdownPlus.svg";
import { FilterDropdownContent } from "src/containers/contacts/contacts-list/components/filter-dropdown-content/filter-dropdown-content";
import { getDropdownTargetLabel } from "../utils";

export const FiltersGroupComponent = ({ condition, conditionIndex, conditionsArray }) => {
    console.log("OOOOOO >>>>>");
    const { filterGroup, groupIndex } = useContext(GroupContext);
    const {
        disabled,
        isComparisonDropdownOpen,
        deleteFilter,
        setIsComparisonDropdownOpen,
        onSomethingClick,
        hasErrors,
        filtersList,
        onLogicalOptionClick,
        addFilterToGroup
    } = useContext(FiltersContext);

    const onDeleteFilter = () => {
        deleteFilter(groupIndex, conditionIndex);
    };

    const renderCustomOptionsComponent = useCallback(
        (props) => {
            return (
                <FilterDropdownContent
                    key={`condition-content-${conditionIndex}`}
                    {...props}
                    valueKey={condition.field}
                    setValue={(option, aplied) => onSomethingClick(groupIndex, conditionIndex, option, aplied)}
                    alreadySelectedValue={condition}
                    dataType={condition.type}
                    error={condition.error}
                />
            );
        },
        [conditionIndex, condition]
    );

    const selectedValue = useMemo(() => ({ label: getDropdownTargetLabel(condition, condition.error) }), [condition]);

    const onClose = () => {
        onSomethingClick(
            groupIndex,
            conditionIndex,
            {
                ...condition,
                error: condition.isComparisonValueEditable && !condition.value
            },
            false
        );
        setIsComparisonDropdownOpen({
            ...isComparisonDropdownOpen,
            [groupIndex]: {
                ...isComparisonDropdownOpen[groupIndex],
                [conditionIndex]: false
            }
        });
    };

    return (
        <div className={styles["filter-unit"]}>
            <Dropdown
                disabled={disabled}
                key={`condition-${conditionIndex}`}
                isOpenProp={isComparisonDropdownOpen?.[groupIndex]?.[conditionIndex]}
                selectedValue={selectedValue}
                hasBackdrop
                targetClassName={DropdownTargetClasses.FILTER_SELECT}
                CustomOptionsComponent={renderCustomOptionsComponent}
                LeftIconComponent={ICONS_TYPE_MATCH[condition.type]}
                arrowIcon={false}
                position={Position.BOTTOM_LEFT}
                onDelete={onDeleteFilter}
                error={condition.error}
                onClose={onClose}
                staticOptions={[]}
            />
            {conditionIndex === conditionsArray.length - 1 && (
                <Tooltip
                    popoverClassName={TooltipClsasses.error}
                    disabled={!hasErrors || disabled}
                    content={ERROR_TOOTLTIP_TEXT}
                    tootltipTarget={
                        <Dropdown
                            contentBlockWidth={250}
                            contentBlockHeight={600}
                            staticOptions={filtersList}
                            entityKey={"filters"}
                            fieldNameForId={"value"}
                            targetClassName={classNames(
                                DropdownTargetClasses.FILTER_SELECT,
                                styles["plus-dropdown-target"]
                            )}
                            LeftIconComponent={FilterDropdownPlusIcon}
                            position={Position.BOTTOM}
                            onStaticOptionClick={(option) => addFilterToGroup(groupIndex, conditionIndex, option)}
                            disabled={hasErrors || disabled}
                        />
                    }
                />
            )}
            {conditionIndex < conditionsArray.length - 1 && (
                <Dropdown
                    disabled={disabled}
                    // disabled={filterValues.type === "static_logical"}
                    staticOptions={LOGICAL_OPERATIONS_ITEMS}
                    targetClassName={classNames(
                        DropdownTargetClasses.FILTER_SELECT,
                        styles["operator-dropdown-between-units"]
                    )}
                    position={Position.BOTTOM}
                    onStaticOptionClick={(option) => onLogicalOptionClick(groupIndex, option)}
                    selectedValue={{
                        label: filterGroup.operator,
                        value: filterGroup.operator
                    }}
                />
            )}
        </div>
    );
};
