import {
    GET_CHANNELS,
    ACCOUNT_SETTINGS_TOGGLE_RIGHTPANEL,
    ACCOUNT_SETTINGS_UPDATE,
    ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE,
    ACCOUNT_SETTINGS_SELECT_LAYER_IN_MAP_LAYERS_UPDATE,
    ACCOUNT_SETTINGS_ACTIVITY_LOG_UPDATE,
    ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_LOG_UPDATE,
    ACCOUNT_SETTINGS_ACTIVITY_TYPES_UPDATE,
    ACCOUNT_SETTINGS_IS_ACTIVITIES_LOADING_UPDATE,
    ACCOUNT_SETTINGS_USERS_ACTIVITIES_BY_ID_UPDATE,
    UPDATE_ALL_PRIVILEGES,
    UPDATE_ACCOUNT_JURISDICTION,
    UPDATE_ACCOUNT_SETTINGS_CURRENT_USER,
    UPDATE_ACCOUNT_SETTINGS_GENERAL,
    UPDATE_ACCOUNT_SETTINGS_ORGANIZATION,
    UPDATE_ACCOUNT_SETTINGS_GENERAL_USERS,
    UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES,
    UPDATE_ACCOUNT_SETTINGS_ALERTS,
    CHANGE_GET_CHANNELS_PROCESSING_STATUS,
    UPDATE_NEW_API_REQUEST_FROM_SOCKET,
    UPDATE_CHANNEL,
    ADD_CHANNEL,
    AccountSettingsActionTypes,
    UPDATE_ACCOUNT_SETTINGS,
    SET_MAIN_COUNTRY_CODES_LIST
} from ".";
import { ArrayChannel } from "../../store";

export const accountSettingsToggleRightpanel = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_TOGGLE_RIGHTPANEL,
    payload
});

export const accountSettingsUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_UPDATE,
    payload
});

export const getChannels = (payload): AccountSettingsActionTypes => ({
    type: GET_CHANNELS,
    payload
});

export const addChannel = (
    accountTypeKey: "facebook" | "twitter" | "sms_voice" | "instagram",
    channel: ArrayChannel
) => ({
    type: ADD_CHANNEL,
    payload: {
        channel,
        type: accountTypeKey
    }
});

export const accountSettingsCreatingUserErrorsUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE,
    payload
});

export const accountSettingsSelectLayerInMapLayersUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_SELECT_LAYER_IN_MAP_LAYERS_UPDATE,
    payload
});

export const accountSettingsActivityLogUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_ACTIVITY_LOG_UPDATE,
    payload
});

export const accountSettingsIsFullActivityLogUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_LOG_UPDATE,
    payload
});

export const accountSettingsActivityTypesUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_ACTIVITY_TYPES_UPDATE,
    payload
});

export const accountSettingsIsActivitiesLoadingUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_IS_ACTIVITIES_LOADING_UPDATE,
    payload
});

export const accountSettingsUsersActivitiesByIdUpdate = (payload): AccountSettingsActionTypes => ({
    type: ACCOUNT_SETTINGS_USERS_ACTIVITIES_BY_ID_UPDATE,
    payload
});

export const updateAllPrivileges = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ALL_PRIVILEGES,
    payload
});

export const updateAccountJurisdiction = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_JURISDICTION,
    payload
});

export const updateAccountSettingsCurrentUser = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS_CURRENT_USER,
    payload
});

export const updateAccountSettingsGeneral = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS_GENERAL,
    payload
});

export const updateAccountSettingsOrganization = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS_ORGANIZATION,
    payload
});

export const updateAccountSettings = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS,
    payload
});

export const updateAccountSettingsGeneralUsers = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS_GENERAL_USERS,
    payload
});

export const updateAccountSettingsGeneralRoles = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES,
    payload
});

export const updateAccountSettingsAlerts = (payload): AccountSettingsActionTypes => ({
    type: UPDATE_ACCOUNT_SETTINGS_ALERTS,
    payload
});

export const onChangeGetChannelProcessingStatus = (status: boolean) => {
    return {
        type: CHANGE_GET_CHANNELS_PROCESSING_STATUS,
        payload: status
    };
};

export const updateNewApiRequestFromSocket = (data) => {
    return {
        type: UPDATE_NEW_API_REQUEST_FROM_SOCKET,
        payload: data
    };
};

export const updateChannel = (channel: any) => {
    return {
        type: UPDATE_CHANNEL,
        payload: channel
    };
};

export const setMainCountriesList = (payload: Array<{ value: string; label: string }>) => {
    return {
        type: SET_MAIN_COUNTRY_CODES_LIST,
        payload
    };
};
