// import { ShapeCoords } from "src/requests";
// import { GeoFence, IIncidentLocation, JurisdictionFeature } from "./commonInterfaces";

// export interface MapGeoFence extends GeoFence {
//     list: Array<any>;
//     search: {
//         value: any;
//     };
// }

// export type MapStateFields = "geoFence" | "mapLayers" | "center" | "incidentLocation" | "jurisdiction";

export interface MapState {
    mapLayersListActive: boolean;
}

export const defaultMapState: MapState = {
    mapLayersListActive: false,
};
