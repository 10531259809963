import { uploadFile } from "../global/global-api";

export const onUpdateMultimediaCommon = async (
    multiMedia: any,
) => {
    const { currentMediaFiles } = multiMedia;
    const promisses = currentMediaFiles.map(async (currentMediaFile: any) => {
        const uploadedFileData = await uploadFile(currentMediaFile.file);
        const { uuid, url } = uploadedFileData;

        return {
            ...currentMediaFile,
            url,
            uuid,
        };
    });
    return await Promise.all(promisses);
};
