/* eslint-disable */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import omit from "lodash/omit";
import { AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { onUpdateToast } from "../actions/global/global";
import { useDebounce } from "./useDebounce";
import { generatePath, useHistory } from "react-router-dom";

type EntityRequired = Required<{ id: string }>;

type EntityExtended<EntityType> = EntityRequired & EntityType;

interface UseDataFetch<EntityType, Filters> {
    hasMore: boolean;
    loading: boolean;
    searchValue: string;
    entities: NormalizedEntities<EntityExtended<EntityType>>;
    initialised: boolean;
    count: number;
    filters: Filters | undefined;
    isListEmpty: boolean;
    atBottomOfList: boolean;
    setFilters: (value: any) => void;
    setSearchValue: (value: string) => void;
    updateEntites: (updatedEntity: any) => void;
    deleteEntity: (entityId: string) => void;
    getData: (searchValue: string, getMore: boolean) => void;
    getMoreData: () => void;
    setAtBottomOfList: (value: boolean) => void;
}

export type FetchDataParamsType = Partial<{
    order: string;
    limit: number;
    offset: number;
    filter: string;
    feature: string;
}>;

type FetchDataParamsState = {
    [key: string]: string | { value: string | number | Required<{ id: string }>; label: string };
};

export type FetchDataParamsKeys = "order" | "limit" | "offset" | "filter" | "feature";

// Partial<{ offset?: number; limit?: number; q?: string }>;

type UseDataFetchProps<EntityType, Filters> = {
    canGetInitialData: boolean;
    fetchDataParams: FetchDataParamsState;
    entityKey: string;
    skeletonLoading: boolean;
    listItemHeight: number;
    fieldNameForId: string;
    refresh?: boolean;
    maxLoadingItemsLength?: number;
    initialEntites?: Array<EntityType>;
    disableBackEndSearch?: boolean;
    shouldSetFiltersToUrl?: boolean;
    initialFilters?: Filters | undefined;
    // groupedEntitesByKey?: string;
    fetchDataRequest: (params: FetchDataParamsType) => Promise<AxiosResponse<EntityExtended<EntityType>>>;
};

type NormalizedEntities<Entity> = {
    allIds: Array<string>;
    byId: { [key: string]: Entity };
};

export const denormalize = (normilized) => {
    return normilized.allIds.map((id) => normilized.byId[id]);
};

const INITIAL_ENTITES_STATE = { allIds: [], byId: {} };

const windowInnerHeight = window.innerHeight;

const convertFetchDataParams = (fetchDataParams: FetchDataParamsState) => {
    return Object.keys(fetchDataParams).reduce<FetchDataParamsState>((acc: FetchDataParamsState, key) => {
        let transformedValue;
        if (typeof fetchDataParams[key] === "object") {
            if (typeof fetchDataParams[key].value === "object") {
                transformedValue = fetchDataParams[key].value.id;
            } else {
                transformedValue = fetchDataParams[key].value;
            }
        } else {
            transformedValue = fetchDataParams[key];
        }
        if (!transformedValue) {
            return acc;
        }
        return {
            ...acc,
            [key]: transformedValue
        };
    }, {});
};

export const normalize = <Entity>(
    items: Array<Entity> = [],
    accumulator: NormalizedEntities<Entity>,
    fieldNameForId: string = "id"
) => {
    if (Array.isArray(items)) {
        return items?.reduce(
            (accumulator: NormalizedEntities<Entity>, item: Entity): NormalizedEntities<Entity> => {
                return {
                    allIds: [...accumulator.allIds, item[fieldNameForId]],
                    byId: {
                        ...accumulator.byId,
                        [item[fieldNameForId]]: { ...item, ...(accumulator.byId?.[item[fieldNameForId]] || {}) }
                    }
                };
            },
            { ...accumulator }
        );
    } else {
        return INITIAL_ENTITES_STATE;
    }
    // return res;
};

export const useDataFetch = <EntityType, Filters = undefined>({
    fetchDataParams,
    entityKey,
    skeletonLoading,
    listItemHeight,
    canGetInitialData = true, // used for dropdowns (make data loading only if dropdown opened)
    refresh,
    fieldNameForId,
    maxLoadingItemsLength,
    initialEntites,
    disableBackEndSearch,
    shouldSetFiltersToUrl,
    initialFilters,
    allOption,
    fetchDataRequest
}: UseDataFetchProps<EntityType, Filters>): UseDataFetch<EntityType, Filters> => {
    const dispatch = useDispatch();

    const [filters, setFilters] = useState<Filters | undefined>(initialFilters || undefined);
    const [entities, setEntites] = useState<NormalizedEntities<EntityType>>(
        initialEntites || allOption
            ? normalize(
                  allOption ? [allOption, ...(initialEntites || [])] : initialEntites,
                  INITIAL_ENTITES_STATE,
                  fieldNameForId
              )
            : skeletonLoading
            ? {
                  ...INITIAL_ENTITES_STATE,
                  allIds: Array(Math.ceil(maxLoadingItemsLength || windowInnerHeight / listItemHeight)).fill("loading")
              }
            : INITIAL_ENTITES_STATE
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [initialised, setInitialised] = useState<boolean>(!!initialEntites);
    const [searchValue, setSearchValue] = useState<string>("");
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [atBottomOfList, setAtBottomOfList] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [fetchDataParamsLocal, setFetchDataParams] = useState<FetchDataParamsState>(fetchDataParams);
    const wasMount = useRef<boolean>(false);
    const offset = useRef<number>(initialEntites?.length || 0);

    useEffect(() => {
        if (wasMount.current) {
            setFetchDataParams(fetchDataParams);
        }
    }, [fetchDataParams]);

    const onChageDataFetchParams = (changedParams: FetchDataParamsState) => {
        setFetchDataParams({
            ...fetchDataParamsLocal,
            ...changedParams
        });
    };

    const debouncedSearch = useDebounce(() => {
        if (!disableBackEndSearch) {
            getData(searchValue, false);
        }
    });

    const loadingRef = useRef<boolean>(false);

    const setLoadingStates = (value: boolean) => {
        loadingRef.current = value;
        setLoading(value);
    };

    const addLoadingItems = (entities) => {
        if (skeletonLoading) {
            const entitesWithLoading = {
                ...entities,
                allIds: [
                    ...entities.allIds,
                    ...Array(maxLoadingItemsLength || Math.ceil(windowInnerHeight / listItemHeight)).fill("loading")
                ]
            };
            setEntites(entitesWithLoading);
        }
    };

    const getMoreData = useCallback(async () => {
        setAtBottomOfList(false);
        await getData(searchValue, true);
    }, [searchValue, loading, entities]);

    useEffect(() => {
        if (refresh) {
            getData("", false);
        }
    }, [refresh]);

    useEffect(() => {
        if (!loadingRef.current && atBottomOfList && wasMount.current) {
            getMoreData();
        }
    }, [loading, atBottomOfList]);

    const getData = useCallback(
        async (searchValue: string, getMore?: boolean) => {
            if (!fetchDataRequest) {
                return;
            }
            const entitesWithoutLoading = getMore ? entities.allIds.filter((item) => item !== "loading") : [];
            offset.current = getMore ? entitesWithoutLoading.length : 0;
            !getMore && setCount(0);
            try {
                if (loadingRef.current || (!hasMore && getMore)) {
                    return;
                }

                setLoadingStates(true);
                addLoadingItems(getMore ? entities : INITIAL_ENTITES_STATE);
                const res = await fetchDataRequest?.({
                    limit: Math.ceil(windowInnerHeight / listItemHeight),
                    ...(searchValue ? { q: searchValue } : {}),
                    offset: offset.current,
                    ...(!isEmpty(filters) ? { filters } : {}),
                    ...(!isEmpty(fetchDataParamsLocal) ? convertFetchDataParams(fetchDataParamsLocal) : {})
                });

                const { data } = res || {};
                const items = data?.[entityKey];
                const { has_more, count } = data || {};

                setCount(count);

                setHasMore(has_more);
                !initialised && setInitialised(true);
                if (getMore) {
                    setEntites({
                        ...normalize(
                            items,
                            {
                                ...entities,
                                allIds: entitesWithoutLoading
                            },
                            fieldNameForId
                        )
                    });
                } else {
                    setEntites(normalize(items, { ...INITIAL_ENTITES_STATE, byId: entities.byId }, fieldNameForId));
                }
            } catch (err) {
                !initialised && setInitialised(true);
                setHasMore(false);
                setEntites(
                    normalize(
                        [],
                        {
                            ...entities,
                            allIds: entitesWithoutLoading
                        },
                        fieldNameForId
                    )
                );
                dispatch(
                    onUpdateToast({
                        type: "error",
                        value: "Something get wrong with list loading. Try Again a bit later"
                    })
                );
            } finally {
                setLoadingStates(false);
            }
        },
        [fetchDataParamsLocal, loading, entities, initialised, filters]
    );

    useEffect(() => {
        if (wasMount.current) {
            getData(searchValue, false);
        }
    }, [fetchDataParamsLocal]);

    useEffect(() => {
        if (wasMount.current) {
            getData("", false);
        }
    }, [filters]);

    useEffect(() => {
        if (wasMount.current && canGetInitialData) {
            getData("", false);
        }
    }, [canGetInitialData]);

    useEffect(() => {
        wasMount.current = true;

        if (canGetInitialData && !initialEntites) {
            getData("", false);
        }
    }, []);

    const updateEntites = (updatedEntities: { [key: string]: Entity }, replaceUpdatedToTop: boolean) => {
        const entitesToSet = Object.keys(updatedEntities).reduce(
            (accum, entityId) => {
                if (entities.byId[entityId]) {
                    return {
                        ...accum,
                        allIds: replaceUpdatedToTop
                            ? [entityId, ...entities.allIds.filter((id) => entityId !== id)]
                            : entities.allIds,
                        byId: {
                            ...accum.byId,
                            [entityId]: { ...accum.byId[entityId], ...updatedEntities[entityId] }
                        }
                    };
                } else {
                    return {
                        ...accum,
                        allIds: [entityId, ...entities.allIds],
                        byId: {
                            ...accum.byId,
                            [entityId]: { ...accum.byId[entityId], ...updatedEntities[entityId] }
                        }
                    };
                }
            },
            { ...entities }
        );

        setEntites(entitesToSet);

        // setEntites({ ...entities, byId: { ...entities.byId, ...updatedEntities } });
    };

    const addEntityToList = (entity: Required<{ id: string }>, position: "end" | "begin") => {
        if (position === "begin") {
            setEntites({
                allIds: [entity.id, ...entities.allIds],
                byId: {
                    ...entities.byId,
                    [entity.id]: entity
                }
            });
        } else {
            setEntites({
                allIds: [...entities.allIds, entity.id],
                byId: {
                    ...entities.byId,
                    [entity.id]: entity
                }
            });
        }
    };

    const deleteEntity = useCallback(
        (deletetaleEntityId: string | Array<string>) => {
            if (deletetaleEntityId === "all") {
                setEntites({
                    allIds: [],
                    byId: {}
                });
            }
            if (typeof deletetaleEntityId !== "string") {
                const byId = { ...entities.byId };
                const allIds = entities.allIds.filter((id) => {
                    omit(byId, id);
                    return !deletetaleEntityId.includes(id);
                });
                setEntites({
                    allIds,
                    byId
                });
                return;
            }
            setEntites({
                allIds: entities.allIds.filter((entityId: string) => entityId !== deletetaleEntityId),
                byId: { ...omit(entities.byId, deletetaleEntityId) }
            });
        },
        [entities]
    );

    const onSearchValueChange = (value) => {
        // addLoadingItems(INITIAL_ENTITES_STATE);
        setSearchValue(value);
        debouncedSearch();
    };

    const isListEmpty = useMemo(() => {
        const value = !hasMore && !entities.allIds.length && wasMount.current && !loading;
        return value;
    }, [hasMore, loading, entities.allIds, loading]);

    const filteredEntites = useMemo(() => {
        if (disableBackEndSearch && searchValue) {
            return {
                ...entities,
                allIds: entities?.allIds.filter((id) => {
                    const entity = entities.byId[id];
                    return entity.label.toLowerCase().includes(searchValue.toLocaleLowerCase());
                })
            };
        }

        return entities;
    }, [searchValue, entities]);

    const history = useHistory();

    const onSetFilters = (value) => {
        setFilters(value);
        if (shouldSetFiltersToUrl) {
            history.push(`${history.location.pathname}?filter=${btoa(JSON.stringify(value))}`);
        }
    };

    const entitiesToReturn = useMemo(() => {
        if (!searchValue && isEmpty(filters) && allOption) {
            return { allIds: ["all", ...entities.allIds], byId: { all: allOption, ...entities.byId } };
        } else if (disableBackEndSearch && searchValue) {
            return filteredEntites;
        } else {
            return entities;
        }
    }, [searchValue, entities, allOption, filters]);

    return {
        count,
        searchValue,
        hasMore,
        loading,
        entities: entitiesToReturn,
        initialised,
        filters,
        isListEmpty,
        fetchDataParams: fetchDataParamsLocal,
        atBottomOfList,
        addEntityToList,
        setFilters: onSetFilters,
        setSearchValue: onSearchValueChange,
        updateEntites,
        deleteEntity,
        getData,
        getMoreData,
        setAtBottomOfList,
        setFetchDataParams: onChageDataFetchParams
    };
};
