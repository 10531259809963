const workercode = () => {
    let heartBeatInterval;
    /* eslint-disable-next-line no-restricted-globals */
    self.onmessage = function (e) {
        // self.importScripts("./testModule"); // eslint-disable-line no-restricted-globals
        /* eslint-disable-next-line no-restricted-globals */
        if (e.data === "heartbeat") {
            heartBeatInterval = setInterval(() => {
                const workerResult = "Received from main: " + e.data;
                /* eslint-disable-next-line no-restricted-globals */
                self.postMessage(workerResult); // eslint-disable-line no-restricted-globals
            }, 20000);
            return;
        }
        if (e.data === "offline") {
            clearInterval(heartBeatInterval);
        }
    };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

export default worker_script;
