import { useEffect } from "react";
import classNames from "classnames";
import { GroupedVirtuoso, Virtuoso } from "react-virtuoso";
import { Classes as BPClasses, Text } from "@blueprintjs/core";
import groupBy from "lodash/groupBy";
import { EmptyBlock, SearchInput, SearchInputClasses } from "../../../../components";
import { useDataFetch } from "../../../../hooks/useDataFetch";
import { getContactsActivities } from "../../../../requests/contactsRequests";
import { ContactActivityListItem } from "../contact-activity-list-item/ContactActivityListItem";
import styles from "./ContactActivity.module.scss";
import { Classes } from "../../../../mpd-library";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { removeWsData } from "../../../../actions/websocket/websocket";

const contact_activity_created = "contact_activity_created";
const topic = "portal_admin_general";

export const ContactActivity = ({ contact }) => {
    let {
        // entities,
        entities: activitiesList,
        searchValue,
        setFilters,
        filters,
        setSearchValue,
        addEntityToList,
        getMoreData: getMoreContactActivities,
        setAtBottomOfList
    } = useDataFetch({
        fetchDataRequest: async (params) => await getContactsActivities(params, contact.id),
        entityKey: "activities",
        canGetInitialData: true,
        skeletonLoading: true,
        listItemHeight: 74
    });

    const { contact_activity } = useSelector(
        (state: AppState) => state.websocketReducer.wsData?.[topic]?.[contact_activity_created] || {}
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (contact_activity) {
            addEntityToList(contact_activity, "begin");
            dispatch(removeWsData(`${topic}.${contact_activity_created}`));
        }
    }, [contact_activity]);

    // activitiesList.allIds.push(activitiesList.allIds?.[0]);

    const { groups, groupCounts } = (() => {
        const groups = groupBy(activitiesList.allIds, (activityId) => {
            if (activityId === "loading") {
                return activityId;
            }
            return moment(activitiesList.byId[activityId].inserted_at).format("DD-MM-YYYY");
        });
        return { groups: Object.keys(groups), groupCounts: Object.keys(groups).map((key) => groups[key].length) };
    })();

    return (
        <div className={styles["root"]}>
            <header className={styles["header"]}>
                <SearchInput
                    placeholder={"Search activities..."}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className={classNames(SearchInputClasses.list, styles["search-input"])}
                />
                {/* <ContactsFilters
                    setPresettedFilter={() => null}
                    appliedFilters={filters}
                    setAppliedFilters={setFilters}
                    filtersList={ACTIVITIES_FILTERS_LIST}
                /> */}
            </header>

            <div className={classNames(styles["main-content"], Classes.CONTAINER_PADDING)}>
                <GroupedVirtuoso
                    style={{ height: "100%" }}
                    groupCounts={groupCounts}
                    endReached={getMoreContactActivities}
                    components={{
                        Item: (props) => {
                            const index = props["data-item-index"];
                            const item = activitiesList.allIds[index];
                            return (
                                <ContactActivityListItem
                                    {...props}
                                    itemId={item}
                                    item={activitiesList.byId[item] || item}
                                />
                            );
                        },
                        EmptyPlaceholder: () => <EmptyBlock title={"No activities"} />
                    }}
                    groupContent={(index) => {
                        return (
                            <div sticky-header={"true"} className={styles["sticky-header"]}>
                                <Text className={groups[index] === "loading" && BPClasses.SKELETON}>
                                    {moment(groups[index], "DD-MM-YYYY").format("DD MMMM YYYY")}
                                </Text>
                            </div>
                        );
                    }}
                    atBottomStateChange={setAtBottomOfList}
                    // components={{
                    //     ScrollSeekPlaceholder: ({ index }) => <SettingsUsersListItem loading index={index} />
                    // }}
                    // increaseViewportBy={INCREASE_VIEWPORT_BY}
                    // scrollSeekConfiguration={SCROLL_VELOCITY_CONFIG}
                />
            </div>
        </div>
    );
};
