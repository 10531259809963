import classNames from "classnames";
import React from "react";
// import * as myClasses from "../";

import { IProps } from "@blueprintjs/core";

import { MPDButton } from "src/mpd-library";

import "./styles.scss";
import { StyledButton } from "../styled-button";
import { ReactComponent as BreadCrumbsIcon } from "../../mpd-library/icon/assets/bread-crumbs.svg"
import { ReactComponent as CloseIcon } from "../../mpd-library/icon/assets/close.svg"
import { ReactComponent as LeftArrowBlackIcon } from "../../mpd-library/icon/assets/left-arrow-black.svg"

export interface IToggleButtonsProps extends IProps {
    label?: string;
    hideExitButton?: boolean;
    leftIcon?: boolean;
    onToggle?(event: React.SyntheticEvent): void;
    onClose?(event: React.SyntheticEvent): void;
}

export const ToggleButtons: React.FunctionComponent<IToggleButtonsProps> = (props) => {
    const { className, onToggle, onClose, leftIcon } = props;
    const classes = classNames("toggle-buttons-container", className);

    return (
        <div className={classes}>
            {leftIcon ? (
                <MPDButton className={classNames("leftbutton")} onClick={onClose} IconComponent={LeftArrowBlackIcon} />
            ) : (
                <StyledButton className={classNames("closebutton")} onClick={onClose} IconComponent={CloseIcon} />
            )}
            <MPDButton className="togglebutton" IconComponent={BreadCrumbsIcon} onClick={onToggle} />
        </div>
    );
};
