import {
    Card,
    CardStateCards,
    CardStateCardList,
    CardsListTypes,
    CardStateSelectedCard,
    ActionData,
    Panel,
    AddCardData
} from "src/store";

export const UPDATE_EDIT_CARD = "update-edit-card";
export const UPDATE_CARDS = "update-cards";
export const UPDATE_CARD_LIST = "update-card-list";
export const UPDATE_SELECTED_CARD = "update-selected-card";
export const UPDATE_SELECTED_CARD_CURRENT_CARD = "update-selected-card-current-card";
export const UPDATE_SELECTED_CARD_PANELS = "update-selected-card-panels";
export const UPDATE_SELECTED_CARD_CURRENT_PANEL = "update-selected-card-current-panel";
export const UPDATE_SELECTED_CARD_CURRENT_ACTION = "update-selected-card-current-action";
export const SELECTED_CARD_CURRENT_ACTION_VALUES_CHANGE = "selected-card-current-action-value-change";
export const SELECT_ACTION_TYPE = "select-action-type";
export const ADD_NEW_TEMP_PANEL = "add-new-temp-panel";
export const RESET_CURRENT_ACTION = "reset-current-action";

interface EditCardAction {
    type: typeof UPDATE_EDIT_CARD;
    payload: AddCardData;
}

interface UpdateCardsAction {
    type: typeof UPDATE_CARDS;
    payload: {
        cards: CardStateCards;
        selectedCard: any;
    };
}

interface UpdateCardListAction {
    type: typeof UPDATE_CARD_LIST;
    payload: { listType: CardsListTypes; listData: Partial<CardStateCardList> };
}

interface UpdateSelectedCardAction {
    type: typeof UPDATE_SELECTED_CARD;
    payload: Partial<CardStateSelectedCard>;
}

interface UpdateSelectedCardCurrentCardAction {
    type: typeof UPDATE_SELECTED_CARD_CURRENT_CARD;
    payload: Card;
}
interface UpdateSelectedCardPanelsAction {
    type: typeof UPDATE_SELECTED_CARD_PANELS;
    payload: Array<Panel>;
}
interface UpdateSelectedCardCurrentPanelAction {
    type: typeof UPDATE_SELECTED_CARD_CURRENT_PANEL;
    payload: Panel;
}
interface UpdateSelectedCardCurrentActionAction {
    type: typeof UPDATE_SELECTED_CARD_CURRENT_ACTION;
    payload: ActionData;
}

interface SelectedCardCurrentActionValuesChange {
    type: typeof SELECTED_CARD_CURRENT_ACTION_VALUES_CHANGE;
    fieldName: string;
    payload: string;
}

interface SelectActionType {
    type: typeof SELECT_ACTION_TYPE;
    payload: string;
}

interface AddNewTempPanel {
    type: typeof ADD_NEW_TEMP_PANEL;
    payload: Array<Panel>;
}

interface ResetCurrentAction {
    type: typeof RESET_CURRENT_ACTION;
}

export type CardActionTypes =
    | EditCardAction
    | UpdateCardsAction
    | UpdateCardListAction
    | UpdateSelectedCardAction
    | UpdateSelectedCardCurrentCardAction
    | UpdateSelectedCardPanelsAction
    | UpdateSelectedCardCurrentPanelAction
    | UpdateSelectedCardCurrentActionAction
    | SelectedCardCurrentActionValuesChange
    | SelectActionType
    | AddNewTempPanel
    | ResetCurrentAction;
