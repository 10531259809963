import { SyntheticEvent, useState } from "react";
import { useHistory } from "react-router";
import { axiosInstance } from "src/actions";
import { getConfig } from "src/common";

const config = getConfig();

const linksToConnect = {
    twitter: `${config.API_URL}/channels/twitter/authenticate_url`,
    facebook: `${config.API_URL}/channels/facebook/authenticate_url`,
    instagram: `${config.API_URL}/channels/instagram/authenticate_url`
};

export type AccountTypeEnum = "facebook" | "instagram" | "twitter" | "sms_voice";

type UseReauthChannel = {
    authChannelProcessing: boolean;
    authChannelKey: AccountTypeEnum | null;
    onInstall: (e: SyntheticEvent, socialNetworkName: AccountTypeEnum) => Promise<undefined>;
    onGoToChannelPage: (channelId: string, socialNetworkName: AccountTypeEnum) => void;
};

export const useReauthChannel = (): UseReauthChannel => {
    const history = useHistory();
    const [authChannelProcessing, setAuthChannelProcessing] = useState(false);
    const [authChannelKey, setAuthChannelKey] = useState<AccountTypeEnum | null>(null);

    const onInstall: UseReauthChannel["onInstall"] = async (
        e: SyntheticEvent,
        socialNetworkName: AccountTypeEnum
    ): Promise<undefined> => {
        try {
            e.stopPropagation();
            setAuthChannelKey(socialNetworkName);

            setAuthChannelProcessing(true);
            const result = await axiosInstance.get(linksToConnect[socialNetworkName]);
            window.location.href = result.data.redirect_url;
        } finally {
            setAuthChannelProcessing(false);
        }
    };

    const onGoToChannelPage: UseReauthChannel["onGoToChannelPage"] = (
        channelId: string,
        accountType: AccountTypeEnum
    ): void => {
        history.push(`/account-settings/channels/${accountType}/edit/${channelId}`);
    };

    return { authChannelProcessing, authChannelKey, onGoToChannelPage, onInstall };
};
