// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IDetailView } from "../../../../";

import {
    BreadCrumbs,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    SettingsTitle,
    StyledButtonTypes,
    StyledButton
} from "src/components";

import { Classes } from "src/mpd-library";

import "./styles.scss";
import {
    AppState,
    AccountSettingsGeneralCurrentUser,
    AccountSettingsGeneralRoles,
    AccountSettingsGeneralCurrentRole
} from "src/store";
import { RouteComponentProps } from "react-router";
import { AccountPrivilege } from "src/requests";
import { bindActionCreators, Dispatch } from "redux";
import {
    clearCurrentRole,
    createAccountSettingsRoles,
    editAccountSettingsRoles
} from "src/actions/account-settings/account-settings-api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { ReactComponent as CloseIcon } from "../../../../../mpd-library/icon/assets/close.svg";
import { Permissions } from "../../../../../components/permissions/Permissions";

interface IRoleSettingsAddProps extends IDetailView {
    currentUser?: AccountSettingsGeneralCurrentUser;
    roles: AccountSettingsGeneralRoles;
    currentRole: AccountSettingsGeneralCurrentRole;
    privileges: Array<AccountPrivilege> | null;
    title?: string;
    conversations_enabled: boolean;
    onCreateAccountSettingsRoles: (role: AccountSettingsGeneralCurrentRole, routeProps: RouteComponentProps) => void;
    onEditAccountSettingsRoles: (role: AccountSettingsGeneralCurrentRole, routeProps: RouteComponentProps) => void;
    onClearCurrentRole: () => void;
}

const RoleSettingsAdd = ({ currentRole, ...props }) => {
    const [createRoleProcessing, setCreatingRoleProcessing] = useState<boolean>(false);
    const { className, RoleSettingsEdit, routeProps } = props;

    const formik = useFormik({
        initialValues: {
            id: currentRole?.id,
            title: currentRole?.title,
            privileges: currentRole?.privileges
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string().required("Can't be blank")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            await onSaveChanges(values);
            setSubmitting(false);
        }
    });
    const { handleSubmit, touched, errors, getFieldProps } = formik;

    useEffect(() => {
        if (!currentRole?.id) {
            props.onClearCurrentRole();
        }
    }, []);

    useEffect(() => {
        return () => {
            props.onClearCurrentRole();
        };
    }, []);

    useEffect(() => {
        if (formik.dirty && props?.onUpdateChangesMade) {
            props?.onUpdateChangesMade(true);
        }
    }, [formik.dirty]);

    const updateToolBar = (props: IRoleSettingsAddProps) => {
        const { title } = props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Roles", pathname: "/account-settings/roles" },
                        {
                            text: title ? title : currentRole?.title,
                            pathname: "account-settings/users/add/"
                        }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: props.onToggleRightPanel,
                        onClose: props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: !RoleSettingsEdit && (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primarySimple}
                    IconComponent={CloseIcon}
                    onClick={() => routeProps.history.push("/account-settings/roles")}
                />
            )
        };
        props.onUpdateToolBar(topBarProps);
    };
    useEffect(() => {
        updateToolBar(props);
    }, [createRoleProcessing]);

    useEffect(() => {
        if (currentRole.id) {
            console.log("IM HERE >>>>>", currentRole);
            updateToolBar(props);
        }
    }, [currentRole.id]);

    const onSaveChanges = async (values) => {
        setCreatingRoleProcessing(true);
        const id = values.id;
        if (!!id) {
            await props.onEditAccountSettingsRoles(values, routeProps);
        } else {
            await props.onCreateAccountSettingsRoles(values, routeProps);
        }
        if (props?.onUpdateChangesMade) {
            props?.onUpdateChangesMade(false);
        }
        setCreatingRoleProcessing(false);
    };

    const classes = "roles-settings-add";
    return (
        <div className={classes + "-main-container"}>
            <form onSubmit={handleSubmit}>
                <div className={classNames(classes, Classes.CONTAINER_PADDING, Classes.COLUMN, className)}>
                    <div className={classes + "-role-details"}>
                        <SettingsTitle className={classes + "-title"} title="ROLE DETAILS" />
                        <StyledFloatingInput
                            className={classNames(classes + "-role-name-input", Classes.BLOCK_MARGIN_TOP)}
                            autoCapitalize="none"
                            autoCorrect="none"
                            floatingType={StyledFloatingInputTypes.primary}
                            value={formik.values.title}
                            placeholder="ROLE NAME"
                            tabIndex={1}
                            error={touched.title && errors.title}
                            {...getFieldProps("title")}
                        />
                    </div>
                    <div>
                        <SettingsTitle className={classes + "title"} title="ACCESS & PERMISSIONS" />
                        <div className={Classes.ASSIGN_BLOCK_PADDING}>
                            <Permissions formik={formik} />
                        </div>
                    </div>
                </div>
            </form>
            <FormWasChanged formik={formik} disableCounter={!RoleSettingsEdit} />
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;
    const roles = accountSettings.general.roles;
    const privileges = accountSettings.general.privileges;
    const { conversations_enabled } = accountSettings.organization;

    return {
        currentPlan,
        roles,
        privileges,
        conversations_enabled
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCreateAccountSettingsRoles: createAccountSettingsRoles,
            onEditAccountSettingsRoles: editAccountSettingsRoles,
            onClearCurrentRole: clearCurrentRole
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RoleSettingsAdd);
