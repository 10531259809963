import userJson from "./user.json";
import { formatRecievedServerDataToGeoJson } from "../functions";
import { IToastType, ToastTypes } from "src/components";
import { navigate, updateToast, updateChangesMade, uploadFile } from "../global/global-api";
import {
    getAccountSettingsDetailsRequest,
    getAccountSettingsJurisdictionRequest,
    getAccountSettingsMapLayersRequest,
    patchAccountSettingsDetailsRequest,
    patchAccountSettingsTeamAgentRequest,
    patchAccountSettingsJurisdictionRequest,
    postAccountSettingsMapLayersRequest,
    deleteAccountSettingsMapLayersRequest,
    postUserInAccountSettingsRequest,
    patchAccountSettingsUserByIdRequest,
    getAccountSettingsUserByIdRequest,
    getAccountSettingsUsersRequest,
    getAccountSettingsRolesRequest,
    getAccountSettingsPrivilegesRequest,
    getAccountSettingsRoleByIdRequest,
    postAccountSettingsRoleRequest,
    patchAccountSettingsRoleByIdRequest,
    deleteAccountSettingsRoleByIdRequest,
    deleteAccountSettingsRoleUserRequest,
    TeamAgent,
    AccountUser,
    AccountRole,
    postAccountSettingsResendInvitationRequest,
    AccountSettingsJurisdictionResponse,
    AccountSettingsUsersExtendingParams,
    AccountSettingsRolesRequestExtendingParams,
    getAccountSettingsActivitiesRequest,
    getAccountSettingsActivityTypesRequest,
    getAccountSettingsActivitiesByUserIdRequest,
    GetAccountSettingsMapLayersParams,
    patchAccountSettingsRoleUsersRequest,
    // postCheckIntersectionRequest,
    // postCheckIntersectionWithGeoFenceRequest,
    AccountSettingsActivitiesParams,
    AccountSettingsActivitiesByUserIdParams
} from "../../requests";
import {
    AppState,
    AccountSettingsState,
    AccountSettingsGeneralChannels,
    AccountSettingsBillingOverviewState,
    AccountSettingsGeneralAlertGroups,
    AccountSettingsGeneralTeams,
    JurisdictionFeature,
    AccountSettingsGeneralCurrentUser,
    AccountSettingsGeneralCurrentRole,
    AccountSettingsUserState,
    UserActivityByIdState,
    Channels,
    ArrayChannel
} from "../../store";
import { History } from "history";
import { RouteComponentProps } from "react-router";
import { IMPDSortTypes } from "src/mpd-library";
import { getListItemIndex } from "src/selectors";
import { getConfig } from "src/common";
import { axiosInstance } from "../common";
import { formatServerToUserSettings } from "../user-settings/user-settings-api";
import {
    getChannels,
    accountSettingsToggleRightpanel,
    accountSettingsUpdate,
    accountSettingsCreatingUserErrorsUpdate,
    accountSettingsSelectLayerInMapLayersUpdate,
    accountSettingsActivityLogUpdate,
    accountSettingsIsFullActivityLogUpdate,
    accountSettingsActivityTypesUpdate,
    accountSettingsIsActivitiesLoadingUpdate,
    accountSettingsUsersActivitiesByIdUpdate,
    updateAllPrivileges,
    updateAccountJurisdiction,
    updateAccountSettingsCurrentUser,
    updateAccountSettingsGeneral,
    updateAccountSettingsOrganization,
    updateAccountSettingsGeneralUsers,
    updateAccountSettingsGeneralRoles,
    updateAccountSettingsAlerts,
    onChangeGetChannelProcessingStatus
} from "./account-settings";
import { AppThunk, AppThunkDispatch } from "../types";
import { UPDATE_ACCOUNT_SETTINGS_ALERTS, UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES } from "./types";
import { updateAccountSettings as updateAccountSettingsAction } from "./account-settings";
import { onUpdateToast } from "src/actions/global/global";

const TOAST_SUCCESS = "Success! Your changes were saved";
const TOAST_ERROR = "Error! Try again a bit later";

export const onToggleRightPanel = (): AppThunk => (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const { accountSettings } = getState();
    const { rightPanelExpanded } = accountSettings;
    dispatch(accountSettingsToggleRightpanel(!rightPanelExpanded));
};

export const onSaveChanges =
    (channels: AccountSettingsGeneralChannels, pathname: string, history: History) => (dispatch: AppThunkDispatch) => {
        dispatch(updateAccountSettingsGeneral({ channels }));
        dispatch(updateAccountSettings());
        dispatch(navigate({ pathname, history }));
    };

export const onSaveChannelsChanges =
    (channel: ArrayChannel, pathname: string, history: History) => async (dispatch: AppThunkDispatch) => {
        try {
            const config = getConfig();

            const body = {
                // track_likes: channels[index].track_likes,
                alerts_pre_selected: channel?.alerts_pre_selected,
                alerts_enabled: channel?.alerts_enabled,
                conversations_enabled: channel?.conversations_enabled,
                conversations_settings: channel?.conversations_enabled
                    ? {
                          queue_id: channel?.conversations_settings?.queue_id,
                          admin_id: channel?.conversations_settings?.admin_id,
                          tag_ids: channel?.conversations_settings?.tag_ids
                      }
                    : null
            };
            const res = await axiosInstance.patch(`${config.API_URL}/channels/${channel.id}`, body);
            dispatch(navigate({ pathname, history }));
            dispatch(onUpdateToast({ value: TOAST_SUCCESS, type: "saved" }));
            return res;
        } catch (err) {
            console.log("ERRRRRR >>>>>", err);
            dispatch(onUpdateToast({ value: TOAST_ERROR, type: "error" }));
        }
    };

export const addTeam = (history: History) => (dispatch: AppThunkDispatch) => {
    const pathname = `/account-settings/teams/add`;
    dispatch(navigate({ history, pathname }));
};

const updateAccountSettings = () => (dispatch: AppThunkDispatch) => {
    const toast: IToastType = {
        value: "Success! Your changes were saved",
        type: ToastTypes.saved
    };

    dispatch(updateToast({ toast }));
    const changesMade = false;
    dispatch(updateChangesMade({ changesMade }));
};

export const onConfirmInviteTeamMembers =
    (teamMembers: Array<string>, id: string) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings,
            accountSettings: {
                general: {
                    teams: { list: teamsList }
                }
            }
        } = getState();

        dispatch(
            updateAccountSettingsGeneral({
                teams: {
                    ...accountSettings.general.teams,
                    list: teamsList.map((item) => {
                        if (item.id === id) {
                            item.members = teamMembers;
                        }

                        return item;
                    })
                }
            })
        );
        dispatch(updateAccountSettings());
    };

export const onUpdateSettingsChannels = (channels: AccountSettingsGeneralChannels) => (dispatch: AppThunkDispatch) => {
    dispatch(updateAccountSettingsGeneral({ channels }));
    dispatch(updateAccountSettings());
};

export const onUpdateSettingsArrayChannels = (channelsArray: Channels) => (dispatch: AppThunkDispatch) => {
    dispatch(updateAccountSettingsGeneral({ channelsArray }));
    dispatch(updateAccountSettings());
};

export const onUpdateBillingOverview =
    (overview: AccountSettingsBillingOverviewState) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { accountSettings } = getState();
        const changesMade = false;

        dispatch(updateChangesMade({ changesMade }));
        dispatch(
            updateAccountSettingsOrganization({
                billing: {
                    ...accountSettings.organization.billing,
                    overview
                }
            })
        );
    };
export const updateGroups = (groups: AccountSettingsGeneralAlertGroups) => (dispatch: AppThunkDispatch) => {
    const changesMade = false;

    dispatch(updateChangesMade({ changesMade }));
    dispatch(
        updateAccountSettingsAlerts({
            groups
        })
    );
};

export const updateTeams = (teams: AccountSettingsGeneralTeams) => (dispatch: AppThunkDispatch) => {
    const changesMade = false;
    dispatch(updateChangesMade({ changesMade }));
    dispatch(updateAccountSettingsGeneral({ teams }));
};

export const getAccountSettingsDetails = () => async (dispatch: AppThunkDispatch) => {
    const res = await getAccountSettingsDetailsRequest();
    const data = { ...res.data.account, ...res.data.settings, default_queue: res.data.default_queue };

    dispatch(formatUpdateAccountSettingsData(data, false));

    return res.data;
};

export const getAccountSettingsJurisdiction =
    (strictUpdate?: boolean) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                organization: { jurisdiction }
            }
        } = getState();

        if (!strictUpdate && jurisdiction) {
            return;
        }

        const res = await getAccountSettingsJurisdictionRequest();
        const { coordinates, type } = res.data.jurisdiction;
        const newJurisdiction = formatRecievedServerDataToGeoJson(coordinates, type);

        dispatch(updateAccountJurisdiction(newJurisdiction));
    };

export interface GetAccountSettingsMapLayersProps {
    withoutLoading?: boolean;
    isRefresh?: boolean;
    sortType?: IMPDSortTypes;
    query?: string;
}
export const getAccountSettingsMapLayers =
    ({ withoutLoading, isRefresh, sortType, query }: GetAccountSettingsMapLayersProps) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                alerts,
                alerts: { isMapLayersFull, mapLayers }
            }
        } = getState();
        const newAccountSettingsAlerts = { ...alerts };
        if (isMapLayersFull && !isRefresh) {
            return;
        }

        if (!withoutLoading) {
            dispatch(updateAccountSettingsAlerts({ loadingMapLayers: true }));
        }

        const params: GetAccountSettingsMapLayersParams = {
            limit: 15,
            offset: isRefresh ? 0 : mapLayers.length,
            sortTitle: sortType || "desc",
            query
        };

        const res = await getAccountSettingsMapLayersRequest(params);

        if (res.data.layers.length) {
            newAccountSettingsAlerts.mapLayers = isRefresh
                ? res.data.layers
                : newAccountSettingsAlerts.mapLayers.concat(res.data.layers);
            newAccountSettingsAlerts.isMapLayersFull = false;
        } else {
            newAccountSettingsAlerts.isMapLayersFull = true;
        }

        newAccountSettingsAlerts.loadingMapLayers = false;

        dispatch(updateAccountSettingsAlerts(newAccountSettingsAlerts));
    };

export interface SearchAccountSettingsMapLayersProps {
    withoutLoading?: boolean;
    isRefresh?: boolean;
    sortType?: IMPDSortTypes;
    query?: string;
}
export const searchAccountSettingsMapLayers =
    ({ withoutLoading, isRefresh, sortType, query }: SearchAccountSettingsMapLayersProps) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                alerts,
                alerts: { isSearchMapLayersFull, searchMapLayers }
            }
        } = getState();
        const newAccountSettingsAlerts = { ...alerts };
        if (isSearchMapLayersFull && !isRefresh) {
            return;
        }

        if (!withoutLoading) {
            dispatch(updateAccountSettingsAlerts({ loadingMapLayers: true }));
        }

        const params: GetAccountSettingsMapLayersParams = {
            limit: 15,
            offset: isRefresh ? 0 : searchMapLayers.length,
            sortTitle: sortType || "asc",
            query
        };

        const res = await getAccountSettingsMapLayersRequest(params);

        if (res.data.layers.length) {
            newAccountSettingsAlerts.searchMapLayers = isRefresh
                ? res.data.layers
                : newAccountSettingsAlerts.searchMapLayers.concat(res.data.layers);
            newAccountSettingsAlerts.isSearchMapLayersFull = false;
        } else {
            newAccountSettingsAlerts.isSearchMapLayersFull = true;
        }

        newAccountSettingsAlerts.loadingMapLayers = false;

        dispatch(updateAccountSettingsAlerts(newAccountSettingsAlerts));
    };

export const removeAccountSettingsMapLayers =
    (mapLayer: any) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: { alerts }
        } = getState();
        const newAccountSettingsAlerts = { ...alerts };
        const id = mapLayer.id;
        deleteAccountSettingsMapLayersRequest(id);

        const mapLayers = newAccountSettingsAlerts.mapLayers;
        const index = getListItemIndex(mapLayers, id);
        mapLayers.splice(index, 1);

        dispatch(updateAccountSettingsAlerts(newAccountSettingsAlerts));

        const toast: IToastType = {
            value: "Success! Map layer was deleted",
            type: ToastTypes.saved
        };
        dispatch(updateToast({ toast }));
    };

export const updateAccountSettingsDetails =
    (updatedObj: AccountSettingsState, pathname: string, history: History) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { userSettings } = getState();
        const details = updatedObj.organization.details;

        const {
            primaryAddress: address1,
            accountName: title,
            country,
            city,
            populationServed: population,
            postalCode: zip,
            totalEmployees: employees,
            state,
            firstName,
            lastName,
            center,
            logo,
            description,
            shortTitle,
            url: brandingUrl,
            twitter,
            email,
            phone,
            teamAgentEmail,
            teamAgentPhone,
            teamAgentFirstName,
            teamAgentLastName,
            teamAgentProfilePic
        } = details;
        let profilePic;
        if (logo.url) {
            profilePic = {
                type: logo.type,
                url: logo.url
            };
        }

        const newTeamAgentProfilePic: { url?: string; type?: string } = {
            url: teamAgentProfilePic.url ? teamAgentProfilePic.url : "",
            type: teamAgentProfilePic.type || ""
        };

        const isTeamAgent = teamAgentProfilePic.file.length !== 0;
        const isLogo = logo.file.length !== 0;
        if (isTeamAgent || isLogo) {
            let file;
            if (isTeamAgent) {
                file = teamAgentProfilePic.file;
            } else if (isLogo) {
                file = logo.file;
            }

            const { type: fileType } = file;

            const uploadedFileData = await uploadFile(file);
            const { url } = uploadedFileData;

            if (isTeamAgent) {
                newTeamAgentProfilePic.url = url;
                newTeamAgentProfilePic.type = fileType;
            } else if (isLogo) {
                profilePic = {
                    type: fileType,
                    url
                };
            }
        }

        const accountOwner = {
            first_name: firstName,
            last_name: lastName
        };

        const location =
            center && center.lng && center.lat
                ? {
                      coordinates: [center.lng, center.lat],
                      type: "Point"
                  }
                : null;

        const remaining = {
            address1,
            profile_pic: profilePic,
            location,
            employees,
            country,
            city,
            population,
            state,
            zip,
            title,
            description,
            short_title: shortTitle,
            url: brandingUrl,
            twitter,
            email,
            phone_number: phone,
            team_agent: {
                first_name: teamAgentFirstName,
                last_name: teamAgentLastName,
                phone_number: teamAgentPhone,
                email: teamAgentEmail,
                profile_pic: {
                    type: newTeamAgentProfilePic?.type,
                    url: newTeamAgentProfilePic?.url
                }
            }
        };

        if (pathname === "/account-settings/branding") {
            delete remaining.team_agent;
        }

        const res = await patchAccountSettingsDetailsRequest(remaining);

        const data = res.data.account;

        dispatch(formatUpdateAccountSettingsData(data, true));
        if (userSettings.myInfo.email === data.team_agent.email) {
            const innerRes = await patchAccountSettingsTeamAgentRequest(accountOwner);
            const accountOwnerData = innerRes.data.team_agent;
            dispatch(formatUpdateAccountSettingsOwnerData(accountOwnerData, true));
            dispatch(navigate({ pathname, history }));
        } else {
            dispatch(navigate({ pathname, history }));
        }
        return;
    };

export const updateAccountSettingsBranding =
    (updatedObj: AccountSettingsState) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { accountSettings } = getState();
        const details = updatedObj.organization.details;
        const {
            logo,
            description,
            shortTitle,
            url: brandingUrl,
            twitter,
            email,
            phone,
            branded_phone_number,
            vanity_url_subdomain
        } = details;

        let profilePic;
        if (logo.url) {
            profilePic = {
                type: logo.type,
                url: logo.url
            };
        }

        const isLogo = logo.file.length !== 0;
        if (isLogo) {
            const file = logo.file;

            const { type: fileType } = file;

            const uploadedFileData = await uploadFile(file);
            const { url } = uploadedFileData;

            if (isLogo) {
                profilePic = {
                    type: fileType,
                    url
                };
            }
        }

        const {
            organization: {
                details: { center, country, city, populationServed: population, totalEmployees: employees }
            }
        } = accountSettings;
        const location =
            center && center.lng && center.lat
                ? {
                      coordinates: [center.lng, center.lat],
                      type: "Point"
                  }
                : null;

        const remaining = {
            country,
            city,
            population,
            employees,
            profile_pic: profilePic,
            location,
            description,
            short_title: shortTitle,
            url: brandingUrl,
            twitter,
            email,
            phone_number: phone,
            branded_phone_number,
            vanity_url_subdomain
        };

        const res = await patchAccountSettingsDetailsRequest(remaining);

        const data = res.data.account;

        dispatch(formatUpdateAccountSettingsData(data, true));
        return res;
    };

export const updateAccountSettingsJurisdiction =
    (updatedObj: JurisdictionFeature) => async (dispatch: AppThunkDispatch) => {
        const geometry = updatedObj.geometry;
        const res = await patchAccountSettingsJurisdictionRequest({ geometry });

        const data = res.data;

        dispatch(formatUpdateAccountSettingsJurisdiction(data, true));
    };

export const createAccountSettingsMapLayers = (mapItem: any) => async (dispatch: AppThunkDispatch) => {
    const geometry = mapItem.geometry;
    const title = mapItem.title;
    const sendObj = {
        title,
        geometry
    };

    const res = await postAccountSettingsMapLayersRequest(sendObj);
    const data = res.data;

    dispatch(formatUpdateAccountSettingsOwnerData(data, true));
    await dispatch(getAccountSettingsMapLayers({ withoutLoading: true, isRefresh: true }));

    const createdLayerId = data.layer ? data.layer.id : null;
    dispatch(selectMapLayerById({ id: createdLayerId }));

    return res.data.layer;
};

export const updateAccountSettingsMapLayers =
    (data: any, update: boolean) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: { alerts }
        } = getState();
        const newAccountSettingsAlerts = { ...alerts };
        const layer = data.layer;
        layer.active = true;
        const mapLayers = newAccountSettingsAlerts.mapLayers;
        mapLayers.unshift(layer);

        dispatch({
            type: UPDATE_ACCOUNT_SETTINGS_ALERTS,
            payload: newAccountSettingsAlerts
        });

        if (update) {
            dispatch(updateAccountSettings());
        }
    };

export const formatUpdateAccountSettingsData =
    (data: any, update: boolean) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { accountSettings } = getState();
        const constuctAddress = (text: string, addComma?: boolean) => {
            if (text !== undefined && text !== null) {
                if (addComma) {
                    text = text + ", ";
                }
                return text + " ";
            } else {
                return "";
            }
        };

        const logo = data.profile_pic;
        logo.file = [];
        if (logo && logo.url) {
            logo.src = logo.url;
        } else {
            logo.src = "";
        }

        const primaryAddress = data.address1;
        const city = data.city;
        const state = data.state;
        const postalCode = data.zip;
        const country = data.country;
        const address =
            constuctAddress(primaryAddress, false) +
            constuctAddress(city) +
            constuctAddress(state) +
            constuctAddress(postalCode) +
            constuctAddress(country);
        const teamAgentFirstName = data.team_agent.first_name;
        const teamAgentLastName = data.team_agent.last_name;
        const teamAgentPhone = data.team_agent.phone_number;
        const teamAgentEmail = data.team_agent.email;
        const teamAgentProfilePic = {
            type: data.team_agent.profile_pic?.type || "",
            url: data.team_agent.profile_pic?.url || "",
            src: data.team_agent.profile_pic?.src || "",
            file: []
        };
        const accountName = data.title;
        const center = {
            lat: data.location.coordinates[1],
            lng: data.location.coordinates[0]
        };

        const details = {
            accountName,
            logo,
            center,
            teamAgentFirstName,
            teamAgentLastName,
            teamAgentPhone,
            teamAgentEmail,
            teamAgentProfilePic,
            postalCode,
            address,
            id: data.id,
            uuid: data.uuid,
            country: data.country,
            domain: data.domain,
            totalEmployees: data.employees,
            populationServed: data.population,
            primaryAddress: data.address1,
            secondaryAddress: data.address2,
            city: data.city,
            timeZone: "locale",
            state: data.state,
            description: data.description,
            twitter: data.twitter,
            shortTitle: data.short_title,
            url: data.url,
            email: data.email,
            phone: data.phone_number,
            branded_phone_number: data.branded_phone_number,
            vanity_url_subdomain: data.vanity_url_subdomain
        };

        dispatch(
            updateAccountSettingsOrganization({
                contacts_enabled: data.contacts_enabled,
                conversations_enabled: data.conversations_enabled,
                default_queue: data.default_queue,
                details: {
                    ...accountSettings.organization.details,
                    ...details
                }
            })
        );

        dispatch(updateAccountSettingsAction({ events_matching_table_version: data.events_matching_table_version }));

        dispatch(
            updateAccountSettingsAction({
                events_matching_table_version: data.events_matching_table_version
            })
        );

        if (update) {
            dispatch(updateAccountSettings());
        }
    };

const formatUpdateAccountSettingsOwnerData =
    (data: TeamAgent, update: boolean) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { accountSettings } = getState();
        const firstName = data.first_name;
        const lastName = data.last_name;

        const details = {
            firstName,
            lastName
        };

        dispatch(
            updateAccountSettingsOrganization({
                details: {
                    ...accountSettings.organization.details,
                    ...details
                }
            })
        );

        if (update) {
            dispatch(updateAccountSettings());
        }
    };

const formatUpdateAccountSettingsJurisdiction =
    (data: AccountSettingsJurisdictionResponse, update: boolean) => (dispatch: AppThunkDispatch) => {
        const { coordinates, type } = data.jurisdiction;
        const jurisdiction = formatRecievedServerDataToGeoJson(coordinates, type);

        dispatch(updateAccountSettingsOrganization({ jurisdiction }));

        if (update) {
            dispatch(updateAccountSettings());
        }
    };

export const createAccountSettingsUsers =
    (user: AccountSettingsGeneralCurrentUser, routeProps: RouteComponentProps) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        try {
            const {
                accountSettings: {
                    general: { users }
                }
            } = getState();
            const newGeneralUsers = { ...users };
            const {
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                role,
                invitationChannels,
                privileges
            } = user;

            const invitationChannelsMass = [];

            for (const key in invitationChannels) {
                if (invitationChannels[key]) {
                    //@ts-ignore
                    invitationChannelsMass.push(key);
                }
            }

            const updatedObj: any = {
                user: {
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phone,
                    invitation_channels: invitationChannelsMass
                }
            };

            if (role !== undefined && role !== null) {
                const id = role.id;
                updatedObj.role_id = id;
            } else {
                const privilegesIds = privileges?.map((privilege) => privilege.id);
                updatedObj.privileges_ids = privilegesIds || [];
            }

            const res = await postUserInAccountSettingsRequest(updatedObj);
            newGeneralUsers.list.push(res.data.user);

            dispatch(updateAccountSettingsGeneralUsers({ newGeneralUsers }));

            const toast: IToastType = {
                value: "Success! User was created",
                type: ToastTypes.saved
            };
            dispatch(updateToast({ toast }));
            routeProps.history.push(`/account-settings/users`);
        } catch (err) {
            console.log("ERR valid >>>>", err);
            return err;
        }
    };

export const editAccountSettingsUsers =
    (user: AccountSettingsGeneralCurrentUser, routeProps: RouteComponentProps) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: { users }
            }
        } = getState();
        const newGeneralUsers = { ...users };
        const {
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            id,
            role,
            invitationChannels,
            profile_pic,
            privileges
        } = user;

        const invitationChannelsMass = [];

        for (const key in invitationChannels) {
            if (invitationChannels[key]) {
                //@ts-ignore
                invitationChannelsMass.push(key);
            }
        }

        const updatedObj: any = {
            user: {
                email,
                first_name: firstName,
                last_name: lastName,
                phone_number: phone,
                invitation_channels: invitationChannelsMass,
                profile_pic
            }
        };

        if (role !== undefined && role !== null) {
            updatedObj.role_id = role.id;
        } else {
            const privilegesIds = privileges?.map((privilege) => privilege.id);
            updatedObj.privileges_ids = privilegesIds || [];
        }

        const res = await patchAccountSettingsUserByIdRequest(id, updatedObj);

        const data = res.data;
        const index = getListItemIndex(newGeneralUsers.list, id);
        newGeneralUsers.list[index] = data.user;
        dispatch(updateAccountSettingsGeneralUsers({ newGeneralUsers }));
        dispatch(formatUpdateAccountSettingsUser(data.user));
        dispatch(updateAccountSettings());
        routeProps.history.push(`/account-settings/users/edit/id-${id}`);
    };

export const viewUser = (id: string, routeProps: RouteComponentProps) => async (dispatch: AppThunkDispatch) => {
    if (id !== "-1") {
        const res = await getAccountSettingsUserByIdRequest(id);

        const user = res.data.user;
        dispatch(formatUpdateAccountSettingsUser(user));
    }
};

export const addUser = (routeProps: RouteComponentProps) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
        accountSettings: {
            general: {
                users: { currentUser }
            }
        }
    } = getState();

    dispatch(updateAccountSettingsCurrentUser({ ...currentUser, ...userJson }));

    routeProps.history.push("/account-settings/users/add");
};

const formatUpdateAccountSettingsUser =
    (data: AccountUser) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: {
                    users: { currentUser }
                }
            }
        } = getState();
        const {
            id,
            email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            role,
            profile_pic,
            privileges
        } = data;

        const newCurrentUser = { ...currentUser };
        newCurrentUser.id = id;
        newCurrentUser.email = email;
        newCurrentUser.firstName = firstName;
        newCurrentUser.lastName = lastName;
        newCurrentUser.phone = phone;
        newCurrentUser.role = role;
        newCurrentUser.profile_pic = profile_pic;
        newCurrentUser.privileges = privileges;

        dispatch(updateAccountSettingsCurrentUser({ ...newCurrentUser }));
    };

export const initiateUsers =
    (id: string, params: AccountSettingsUsersExtendingParams) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        // const { sortFirstName, sortLastName, query } = params;
        const {
            accountSettings: {
                general: { users }
            }
        } = getState();
        const newGeneralUsers = { ...users };

        // dispatch(updateAccountSettingsGeneralUsers({ usersLoading: true }));

        // const res = await getAccountSettingsUsersRequest({
        //     limit: 25,
        //     offset: 0,
        //     sortFirstName,
        //     sortLastName: sortLastName || "asc",
        //     q: query
        // });
        // const newUsers = res.data.users;
        // newGeneralUsers.list = newUsers;

        if (id !== "-1") {
            const innerRes = await getAccountSettingsUserByIdRequest(id);
            const user = innerRes.data.user;
            newGeneralUsers.list.push(user);

            dispatch(updateAccountSettingsGeneralUsers({ usersLoading: false }));

            await dispatch(formatUpdateAccountSettingsUser(user));
        } else {
            newGeneralUsers.usersLoading = false;
        }

        dispatch(updateAccountSettingsGeneralUsers(newGeneralUsers));

        dispatch(getAllPrivileges());

        // dispatch(
        //     updateAccountSettingsGeneralUsers({
        //         isFullUsersList: false
        //     })
        // );
    };

// export const getAccountSettingsUsers =
//     (params: AccountSettingsUsersExtendingParams) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
//         dispatch(updateAccountSettingsGeneralUsers({ usersLoading: true }));
//         const { sortFirstName, sortLastName, query } = params;
//         const {
//             accountSettings: {
//                 general: {
//                     users: { list: usersList }
//                 }
//             }
//         } = getState();

//         const usersLenght = usersList.length;

//         const res = await getAccountSettingsUsersRequest({
//             limit: 25,
//             offset: usersLenght || 0,
//             sortFirstName,
//             sortLastName: sortLastName || "asc",
//             q: query
//         });

//         const resUsers = res.data.users;
//         const hasMore = res.data.has_more;

//         const teamAgent = res.data.team_agent;

//         if (usersList.every((user: AccountUser) => user.id !== teamAgent.id)) {
//             resUsers.push(teamAgent as any);
//         }

//         dispatch(
//             updateAccountSettingsGeneralUsers({
//                 list: [...usersList, ...resUsers],
//                 isFullUsersList: !hasMore,
//                 usersLoading: false
//             })
//         );
//     };

export const getAccountSettingsRoles =
    (params: AccountSettingsRolesRequestExtendingParams) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        dispatch(updateAccountSettingsGeneralRoles({ rolesLoading: true }));
        const { sortTitle, query } = params;
        const {
            accountSettings: {
                general: {
                    roles: { list: rolesList }
                }
            }
        } = getState();
        const rolesLength = rolesList.length;

        const res = await getAccountSettingsRolesRequest({
            limit: 15,
            offset: rolesLength || 0,
            sortTitle,
            query
        });

        const resRoles = res.data.roles;

        if (resRoles && resRoles.length) {
            const innerRes = await getAccountSettingsUsersRequest({
                limit: 100,
                offset: 0,
                order: "first_name:asc,last_name:asc"
            });
            const users = innerRes.data.users;

            dispatch(updateAccountSettingsGeneralRoles({ list: rolesList.concat(resRoles) }));
            dispatch(updateAccountSettingsGeneralUsers({ list: users }));
        } else {
            dispatch(updateAccountSettingsGeneralRoles({ isFullRolesList: true }));
        }

        dispatch(updateAccountSettingsGeneralRoles({ rolesLoading: false }));
    };

export const initiateRoles =
    (id: string, params: AccountSettingsRolesRequestExtendingParams) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { sortTitle, query } = params;
        const {
            accountSettings: {
                general: { roles }
            }
        } = getState();
        const newGeneralRoles = { ...roles };

        dispatch(updateAccountSettingsGeneralRoles({ rolesLoading: true }));

        const accountSettingsUsersRes = await getAccountSettingsUsersRequest({
            limit: 100,
            offset: 0,
            order: "first_name:asc,last_name:asc"
        });
        const users = accountSettingsUsersRes.data.users;

        dispatch(updateAccountSettingsGeneralUsers({ list: users }));

        dispatch(getAllPrivileges());

        const accountSettingsRolesRes = await getAccountSettingsRolesRequest({
            limit: 15,
            offset: 0,
            sortTitle,
            query
        });

        const resRoles = accountSettingsRolesRes.data.roles;

        if (id !== "-1") {
            const accountSettingsRoleByIdRes = await getAccountSettingsRoleByIdRequest(id);
            const role = accountSettingsRoleByIdRes.data.role;
            resRoles.push(role);

            newGeneralRoles.list = resRoles;
            newGeneralRoles.rolesLoading = false;
            await dispatch({
                type: UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES,
                payload: newGeneralRoles
            });

            dispatch(formatUpdateAccountSettingsRole(role));
        } else {
            newGeneralRoles.list = resRoles;
            newGeneralRoles.rolesLoading = false;

            dispatch(updateAccountSettingsGeneralRoles(newGeneralRoles));
        }

        dispatch(
            updateAccountSettingsGeneralRoles({
                isFullRolesList: false
            })
        );
    };

const formatUpdateAccountSettingsRole =
    (data: AccountSettingsGeneralCurrentRole) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: {
                    roles: { currentRole }
                }
            }
        } = getState();
        const { id, title, privileges, users } = data;
        const newCurrentRole = { ...currentRole };

        newCurrentRole.title = title;
        newCurrentRole.id = id;
        newCurrentRole.users = users;
        newCurrentRole.privileges = privileges;

        dispatch(
            updateAccountSettingsGeneralRoles({
                currentRole: newCurrentRole
            })
        );
    };

export const createAccountSettingsRoles =
    (role: AccountSettingsGeneralCurrentRole, routeProps: RouteComponentProps) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: { roles }
            }
        } = getState();
        const newGeneralRoles = { ...roles };
        const { title, privileges = [] } = role;

        const updatedObj = {
            title,
            privileges_ids: privileges.map((privilege) => privilege.id)
        };

        const res = await postAccountSettingsRoleRequest(updatedObj);

        const data = res.data;
        const list = newGeneralRoles.list;
        list.push(data.role);

        dispatch(updateAccountSettingsGeneralRoles(newGeneralRoles));

        routeProps.history.push(`/account-settings/roles`);

        const toast: IToastType = {
            value: "Success! Role was created",
            type: ToastTypes.saved
        };
        dispatch(updateToast({ toast }));
    };

export const editAccountSettingsRoles =
    (role: AccountSettingsGeneralCurrentRole, routeProps: RouteComponentProps) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: { roles }
            }
        } = getState();
        const newGeneralRoles = { ...roles };
        const { title, id, privileges = [] } = role;

        const updatedObj = {
            title,
            privileges_ids: privileges.map((privilege) => privilege.id)
        };

        const res = await patchAccountSettingsRoleByIdRequest(id, updatedObj);

        const data = res.data;
        const list = newGeneralRoles.list;
        const index = getListItemIndex(list, id);
        list[index] = data.role;

        newGeneralRoles.currentRole = data.role;

        dispatch(updateAccountSettingsGeneralRoles(newGeneralRoles));
        dispatch(updateAccountSettings());
        routeProps.history.push(`/account-settings/roles/edit/id-${id}`);
    };

export const addRole = (routeProps: RouteComponentProps) => (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
        accountSettings: {
            general: { roles }
        }
    } = getState();
    const newGeneralRoles = { ...roles };
    // let currentRole = newGeneralRoles.currentRole;
    // currentRole = Object.assign(currentRole, roleJson);

    dispatch(updateAccountSettingsGeneralRoles(newGeneralRoles));
    routeProps.history.push("/account-settings/roles/add");
};

export const viewRole =
    (id: string, routeProps: RouteComponentProps) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { accountSettings } = getState();
        if (id !== "-1") {
            const res = await getAccountSettingsRoleByIdRequest(id);

            const role = res.data.role;
            dispatch(formatUpdateAccountSettingsRole(role));
            routeProps.history.push(`/account-settings/roles/edit/id-${id}`);

            const innerRes = await getAccountSettingsUsersRequest({
                limit: 100,
                offset: 0,
                order: "first_name:asc,last_name:asc"
            });

            dispatch(
                updateAccountSettingsGeneral({
                    users: {
                        ...accountSettings.general.users,
                        list: innerRes.data.users
                    }
                })
            );
        }
    };

export const confirmInviteUsers =
    (users: any, id: string) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: {
                    roles: { currentRole }
                }
            }
        } = getState();

        const usersIds = users.map((user: any) => user.id);
        const currentRoleUsersIds = currentRole.users.map((user) => Number(user.id));

        await patchAccountSettingsRoleUsersRequest({ roleId: id }, { users_ids: currentRoleUsersIds.concat(usersIds) });
        const deleteRes = await getAccountSettingsRoleByIdRequest(id);

        dispatch(formatUpdateAccountSettingsRole(deleteRes.data.role));

        const toastText = users.length > 1 ? "Users were" : "User was";
        const toast: IToastType = {
            value: `${toastText} assigned`,
            type: ToastTypes.saved
        };
        dispatch(updateToast({ toast }));
    };

export const removeUserFromRole =
    (user: AccountUser, role: AccountSettingsGeneralCurrentRole) => async (dispatch: AppThunkDispatch) => {
        await deleteAccountSettingsRoleUserRequest({ roleId: role.id, userId: user.id });
        const deleteRes = await getAccountSettingsRoleByIdRequest(role.id);

        dispatch(formatUpdateAccountSettingsRole(deleteRes.data.role));

        const toast: IToastType = {
            value: `User "${user.first_name} ${user.last_name}" was removed`,
            type: ToastTypes.saved
        };
        dispatch(updateToast({ toast }));
    };

export const changeUserStatus =
    (status: string, user: AccountSettingsUserState) =>
    async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { accountSettings } = getState();
        const id = user.id;

        if (status === "resend invitation") {
            postAccountSettingsResendInvitationRequest(id);
        } else {
            const updatedObj = {
                user: {
                    status
                }
            };

            const res = await patchAccountSettingsUserByIdRequest(id, updatedObj);
            const data = res.data;
            user.status = data.user.status;

            dispatch(accountSettingsUpdate({ ...accountSettings }));

            let actionOnUser = "updated";
            if (status === "activate") {
                actionOnUser = "activated";
            } else if (status === "deactivate") {
                actionOnUser = "deactivated";
            } else if (status === "archive") {
                actionOnUser = "archived";
            }

            const toast: IToastType = {
                value: `User "${user.first_name} ${user.last_name}" was ${actionOnUser}`,
                type: ToastTypes.saved
            };
            dispatch(updateToast({ toast }));
        }
    };

export const removeRole = (role: AccountRole) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
        accountSettings: {
            general: { roles }
        }
    } = getState();
    const newGeneralRoles = { ...roles };
    const id = role.id;
    await deleteAccountSettingsRoleByIdRequest(id);

    const index = getListItemIndex(newGeneralRoles.list, id);
    newGeneralRoles.list.splice(index, 1);

    dispatch(updateAccountSettingsGeneralRoles(newGeneralRoles));

    const toast: IToastType = {
        value: `Role "${role.title}" was deleted`,
        type: ToastTypes.saved
    };
    dispatch(updateToast({ toast }));
};

export const clearCurrentRole = () => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
        accountSettings: {
            general: {
                roles,
                roles: {
                    currentRole: { users: currentRoleUsers }
                }
            }
        }
    } = getState();

    dispatch(
        updateAccountSettingsGeneralRoles({
            ...roles,
            currentRole: {
                id: "",
                title: "",
                description: "",
                users: currentRoleUsers,
                privileges: [],
                inserted_at: null,
                updated_at: null
            }
        })
    );
};

export const deleteCreatingUsersError =
    (errorName: string) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            general: {
                users: { userCreationErrors }
            }
        } = getState().accountSettings;

        if (!errorName || !userCreationErrors || !userCreationErrors[errorName]) {
            return;
        }

        const newErrors = { ...userCreationErrors };
        delete newErrors[errorName];

        dispatch(accountSettingsCreatingUserErrorsUpdate(newErrors));
    };

interface GetActivitiesLogProps {
    isOnAdd?: boolean;
    from?: string;
    to?: string;
    types?: Array<string>;
    users?: Array<string>;
}
export const getActivitiesLog =
    (ref: GetActivitiesLogProps) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { isOnAdd, from, to, types, users } = ref;
        const { accountSettings } = getState();
        const auditLog = accountSettings.general.auditLog;
        const limit = 15;

        if (!isOnAdd) {
            dispatch(accountSettingsActivityLogUpdate([]));
        }
        if (isOnAdd && auditLog.isFullActivityLog) {
            return;
        }

        let offset = 0;

        if (isOnAdd) {
            offset = auditLog.activities.length || 0;
        }

        dispatch(accountSettingsIsActivitiesLoadingUpdate(true));

        const convertedTypes: string | undefined = types && types.toString();
        const usersTypes: string | undefined = users && users.toString();

        const getAccountSettingsActivitiesRequestParams: AccountSettingsActivitiesParams = {
            limit,
            offset,
            from,
            to
        };

        convertedTypes && (getAccountSettingsActivitiesRequestParams.types = convertedTypes);
        usersTypes && (getAccountSettingsActivitiesRequestParams.users = usersTypes);

        const res = await getAccountSettingsActivitiesRequest(getAccountSettingsActivitiesRequestParams);
        const activities = offset === 0 ? res.data.activities : [...auditLog.activities, ...res.data.activities];

        dispatch(accountSettingsActivityLogUpdate(activities));
        dispatch(
            accountSettingsIsFullActivityLogUpdate(activities.length % limit !== 0 || res.data.activities.length === 0)
        );
        dispatch(accountSettingsIsActivitiesLoadingUpdate(false));
    };

export const getActivityTypes = () => async (dispatch: AppThunkDispatch) => {
    const res = await getAccountSettingsActivityTypesRequest();

    dispatch(accountSettingsActivityTypesUpdate(res.data));
};

export const getAllUsers = () => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const res = await getAccountSettingsUsersRequest();
    const newUsers = res.data.users;
    const { accountSettings } = getState();

    dispatch(
        updateAccountSettingsGeneral({
            users: {
                ...accountSettings.general.users,
                list: newUsers
            }
        })
    );
};

interface GetActivitiesByIdLogProps {
    id: string;
    isOnAdd?: boolean;
    from?: string;
    to?: string;
    types?: Array<string>;
}
export const getActivitiesByIdLog =
    (ref: GetActivitiesByIdLogProps) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const { id, isOnAdd, from, to, types } = ref;
        const { accountSettings } = getState();
        const usersActivities = accountSettings.general.users.usersActivities;
        const currentActivitiesById: UserActivityByIdState =
            usersActivities && usersActivities[id]
                ? usersActivities[id]
                : { isFullList: false, list: [], loadingListStatus: false };

        if (isOnAdd && currentActivitiesById.isFullList) {
            return;
        }

        let offset = 0;

        if (isOnAdd) {
            offset = currentActivitiesById.list.length || 0;
        }

        dispatch(
            accountSettingsUsersActivitiesByIdUpdate({
                id,
                data: {
                    ...currentActivitiesById,
                    loadngListStatus: true
                }
            })
        );

        const convertedTypes: string | undefined = types && types.toString();

        const getAccountSettingsActivitiesByUserIdRequestParams: AccountSettingsActivitiesByUserIdParams = {
            id,
            limit: 15,
            offset,
            from,
            to
        };

        convertedTypes && (getAccountSettingsActivitiesByUserIdRequestParams.types = convertedTypes);

        const res = await getAccountSettingsActivitiesByUserIdRequest(
            getAccountSettingsActivitiesByUserIdRequestParams
        );

        const activities = offset === 0 ? res.data.activities : [...currentActivitiesById.list, ...res.data.activities];

        if (res.data.activities.length) {
            dispatch(
                accountSettingsUsersActivitiesByIdUpdate({
                    id,
                    data: {
                        isFullList: false,
                        list: activities,
                        loadingListStatus: false
                    }
                })
            );
        } else {
            dispatch(
                accountSettingsUsersActivitiesByIdUpdate({
                    id,
                    data: {
                        isFullList: true,
                        list: activities,
                        loadingListStatus: false
                    }
                })
            );
        }
    };

export interface SelectMapLayerByIdProps {
    id: string | null;
}
export const selectMapLayerById =
    ({ id }: SelectMapLayerByIdProps) =>
    (dispatch: AppThunkDispatch) => {
        dispatch(accountSettingsSelectLayerInMapLayersUpdate({ id }));
    };

export const getAllPrivileges =
    (strictUpdate?: boolean) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const {
            accountSettings: {
                general: { privileges }
            }
        } = getState();

        if (!strictUpdate && privileges.length) {
            return;
        }

        const res = await getAccountSettingsPrivilegesRequest();

        const resPrivileges = res?.data?.privileges || [];

        dispatch(updateAllPrivileges(resPrivileges));
    };

export const resetCurrentUser = () => (dispatch: AppThunkDispatch) => {
    dispatch(
        updateAccountSettingsCurrentUser({
            id: "",
            description: "",
            firstName: "",
            initials: "",
            lastName: "",
            email: "",
            phone: "",
            privileges: [],
            invitationChannels: {
                email: true,
                sms: false
            }
        })
    );
};

export const onGetChannelsArray = () => async (dispatch: AppThunkDispatch) => {
    try {
        dispatch(onChangeGetChannelProcessingStatus(true));
        const config = getConfig();
        const response: any = await axiosInstance.get(`${config.API_URL}/channels`);

        const { channels, channels_available } = response.data;
        dispatch(
            getChannels({
                channels,
                channels_available
            })
        );
        dispatch(onChangeGetChannelProcessingStatus(false));
        return { channels, channels_available };
    } catch (err) {
        dispatch(getChannels({}));
        dispatch(onChangeGetChannelProcessingStatus(false));
        return {};
    }
};

export const disconnectChannel =
    (channelId: string, type: string) => async (dispatch: AppThunkDispatch, getState: () => AppState) => {
        const config = getConfig();
        const channelsArray = JSON.parse(JSON.stringify(getState().accountSettings.general.channelsArray));
        const channels_available = JSON.parse(JSON.stringify(getState().accountSettings.general.channelsAvailable));
        channelsArray[type] = channelsArray[type].filter((channel) => {
            return channel.id !== channelId;
        });

        await axiosInstance.delete(`${config.API_URL}/channels/${channelId}`);

        dispatch(getChannels({ channels: channelsArray, channels_available }));
    };

export const updateCurrentUserFromWebSocket = (data) => (dispatch: AppThunkDispatch) => {
    dispatch(formatServerToUserSettings(data.admin));
};
