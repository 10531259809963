import {
    ACCOUNT_SETTINGS_TOGGLE_RIGHTPANEL,
    ACCOUNT_SETTINGS_UPDATE,
    AccountSettingsActionTypes,
    ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE,
    ACCOUNT_SETTINGS_SELECT_LAYER_IN_MAP_LAYERS_UPDATE,
    ACCOUNT_SETTINGS_ACTIVITY_LOG_UPDATE,
    ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_LOG_UPDATE,
    ACCOUNT_SETTINGS_ACTIVITY_TYPES_UPDATE,
    ACCOUNT_SETTINGS_IS_ACTIVITIES_LOADING_UPDATE,
    ACCOUNT_SETTINGS_USERS_ACTIVITIES_BY_ID_UPDATE,
    ACCOUNT_SETTINGS_IS_MAP_LAYERS_FULL_UPDATE,
    ACCOUNT_SETTINGS_SEARCH_MAP_LAYERS_UPDATE,
    ACCOUNT_SETTINGS_IS_SEARCH_MAP_LAYERS_FULL_UPDATE,
    ACCOUNT_SETTINGS_LOADING_MAP_LAYERS_UPDATE,
    UPDATE_ALL_PRIVILEGES,
    UPDATE_ACCOUNT_JURISDICTION,
    UPDATE_ACCOUNT_SETTINGS_CURRENT_USER,
    UPDATE_ACCOUNT_SETTINGS_GENERAL,
    UPDATE_ACCOUNT_SETTINGS_ORGANIZATION,
    UPDATE_ACCOUNT_SETTINGS_GENERAL_USERS,
    UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES,
    UPDATE_ACCOUNT_SETTINGS_ALERTS,
    GET_CHANNELS,
    CHANGE_GET_CHANNELS_PROCESSING_STATUS,
    UPDATE_NEW_API_REQUEST_FROM_SOCKET,
    UPDATE_CHANNEL,
    ADD_CHANNEL,
    UPDATE_ACCOUNT_SETTINGS,
    SET_MAIN_COUNTRY_CODES_LIST
} from "../actions/account-settings/types";
import { AccountSettingsState, defaultAccountSettingsState } from "../store";

export const accountSettingsReducer = (
    state: AccountSettingsState = defaultAccountSettingsState,
    action: AccountSettingsActionTypes
): AccountSettingsState => {
    switch (action.type) {
        case SET_MAIN_COUNTRY_CODES_LIST:
            return {
                ...state,
                mainCountries: action.payload
            };
        case ACCOUNT_SETTINGS_TOGGLE_RIGHTPANEL:
            return {
                ...state,
                rightPanelExpanded: action.payload
            };
        case ACCOUNT_SETTINGS_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        case ACCOUNT_SETTINGS_CREATING_USER_ERRORS_UPDATE:
            return {
                ...state,
                general: {
                    ...state.general,
                    users: {
                        ...state.general.users,
                        userCreationErrors: action.payload
                    }
                }
            };
        case ACCOUNT_SETTINGS_SELECT_LAYER_IN_MAP_LAYERS_UPDATE:
            const newLayers = state.alerts.mapLayers.map((layer) => {
                if (layer.active === undefined || layer.active) {
                    layer.active = false;
                }
                if (action.payload && layer.id === action.payload) {
                    layer.active = true;
                }

                return layer;
            });

            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    mapLayers: newLayers
                }
            };
        case ACCOUNT_SETTINGS_ACTIVITY_LOG_UPDATE:
            return {
                ...state,
                general: {
                    ...state.general,
                    auditLog: {
                        ...state.general.auditLog,
                        activities: action.payload
                    }
                }
            };
        case ACCOUNT_SETTINGS_IS_FULL_ACTIVITY_LOG_UPDATE:
            return {
                ...state,
                general: {
                    ...state.general,
                    auditLog: {
                        ...state.general.auditLog,
                        isFullActivityLog: action.payload
                    }
                }
            };
        case ACCOUNT_SETTINGS_ACTIVITY_TYPES_UPDATE:
            return {
                ...state,
                general: {
                    ...state.general,
                    activityTypes: action.payload
                }
            };
        case ACCOUNT_SETTINGS_IS_ACTIVITIES_LOADING_UPDATE:
            return {
                ...state,
                general: {
                    ...state.general,
                    auditLog: {
                        ...state.general.auditLog,
                        isActivitiesLoading: action.payload
                    }
                }
            };
        case ACCOUNT_SETTINGS_USERS_ACTIVITIES_BY_ID_UPDATE:
            return {
                ...state,
                general: {
                    ...state.general,
                    users: {
                        ...state.general.users,
                        usersActivities: {
                            ...state.general.users.usersActivities,
                            [action.payload.id]: action.payload.data
                        }
                    }
                }
            };
        case ACCOUNT_SETTINGS_IS_MAP_LAYERS_FULL_UPDATE:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    isMapLayersFull: action.payload
                }
            };
        case ACCOUNT_SETTINGS_SEARCH_MAP_LAYERS_UPDATE:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    searchMapLayers: action.payload
                }
            };
        case ACCOUNT_SETTINGS_IS_SEARCH_MAP_LAYERS_FULL_UPDATE:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    isSearchMapLayersFull: action.payload
                }
            };
        case ACCOUNT_SETTINGS_LOADING_MAP_LAYERS_UPDATE:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    loadingMapLayers: action.payload
                }
            };
        case UPDATE_ALL_PRIVILEGES:
            return {
                ...state,
                general: {
                    ...state.general,
                    privileges: action.payload
                }
            };
        case UPDATE_ACCOUNT_JURISDICTION:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    jurisdiction: action.payload
                }
            };
        case UPDATE_ACCOUNT_SETTINGS_CURRENT_USER:
            return {
                ...state,
                general: {
                    ...state.general,
                    users: {
                        ...state.general.users,
                        currentUser: action.payload
                    }
                }
            };
        case UPDATE_ACCOUNT_SETTINGS_GENERAL:
            return {
                ...state,
                general: {
                    ...state.general,
                    ...action.payload
                }
            };
        case UPDATE_ACCOUNT_SETTINGS_ORGANIZATION:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    ...action.payload
                }
            };
        case UPDATE_ACCOUNT_SETTINGS:
            return {
                ...state,
                settings: action.payload
            };
        case UPDATE_ACCOUNT_SETTINGS_GENERAL_USERS:
            return {
                ...state,
                general: {
                    ...state.general,
                    users: {
                        ...state.general.users,
                        ...action.payload,
                        currentUser: { ...state.general.users.currentUser }
                    }
                }
            };
        case UPDATE_ACCOUNT_SETTINGS_GENERAL_ROLES:
            return {
                ...state,
                general: {
                    ...state.general,
                    roles: {
                        ...state.general.roles,
                        ...action.payload
                    }
                }
            };
        case UPDATE_ACCOUNT_SETTINGS_ALERTS:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    ...action.payload
                }
            };

        case GET_CHANNELS:
            return {
                ...state,
                general: {
                    ...state.general,
                    channelsArray: {
                        ...state.general.channelsArray,
                        ...action.payload.channels
                    },
                    channelsAvailable: action.payload.channels_available
                }
            };
        case ADD_CHANNEL:
            return {
                ...state,
                general: {
                    ...state.general,
                    channelsArray: {
                        ...state.general.channelsArray,
                        [action.payload.type]: [
                            ...state.general.channelsArray[action.payload.type],
                            action.payload.channel
                        ]
                    }
                }
            };
        case CHANGE_GET_CHANNELS_PROCESSING_STATUS:
            return {
                ...state,
                getChannelsProcessingStatus: action.payload
            };
        case UPDATE_NEW_API_REQUEST_FROM_SOCKET:
            return {
                ...state,
                newApiKeysRecord: action.payload?.external_api_request || null
            };
        case UPDATE_CHANNEL:
            return {
                ...state,
                general: {
                    ...state.general,
                    channelsArray: {
                        ...state.general.channelsArray,
                        [action.payload.type]: [
                            ...state.general.channelsArray[action.payload.type].map((channel) => {
                                if (channel.id === action.payload.id) {
                                    return {
                                        ...channel,
                                        ...action.payload
                                    };
                                }
                                return channel;
                            })
                        ]
                    }
                }
            };
        default:
            return state;
    }
};
