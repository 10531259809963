import { FormikValues } from "formik";
import { StyledFloatingInput, StyledFloatingInputTypes } from "src/components";
import {
    StyledCheckbox,
    StyledFloatingPhoneInput,
    StyledFloatingPhoneInputTypes
} from "../../../../../../../../components";
import styles from "./SmsVoiceChannelOverview.module.scss";
import * as PhoneInputClasses from "src/mpd-library/floating-phone-input/classes";

type SmsVoiceChannelCreateOverviewProps = { formik: FormikValues; showNumber: boolean };

export const SmsVoiceChannelCreateOverview = ({ formik, showNumber }: SmsVoiceChannelCreateOverviewProps) => {
    const { touched, errors, getFieldProps } = formik;

    return (
        <div className={styles["main-wrapper"]}>
            <StyledFloatingInput
                hint={"Enter a name for your SMS account..."}
                placeholder={"NAME"}
                floatingType={StyledFloatingInputTypes.primary}
                error={touched.lineName && errors.lineName}
                {...getFieldProps("lineName")}
            />
            <StyledFloatingInput
                hint={"Enter a description for your SMS account (optional)..."}
                placeholder={"DESCRIPTION"}
                floatingType={StyledFloatingInputTypes.primary}
                error={touched.description && errors.description}
                {...getFieldProps("description")}
            />
            {showNumber && (
                <StyledFloatingPhoneInput
                    floatingType={StyledFloatingPhoneInputTypes.primary}
                    placeholder="NUMBER"
                    error={touched?.phone_number && errors?.phone_number}
                    {...getFieldProps("phone_number")}
                    disabled
                    className={PhoneInputClasses.FLOATING_PHONE_INPUT_CONTAINER}
                />
            )}
            <StyledCheckbox
                className={styles["checkbox-wrapper"]}
                {...getFieldProps("user_identity_hidden")}
                checked={getFieldProps("user_identity_hidden").value}
            >
                {/* <div className={styles["checkbox-wrapper"]}> */}
                <div className={styles["checkbox-description"]}>
                    <div className={styles["checkbox-description__title"]}>Hide user identity (anonymous)</div>
                    <div className={styles["checkbox-description__subtitle"]}>
                        Choosing this optional will remove profile details of any users that submit information over
                        this channel. You will still be able to message users back.
                    </div>
                </div>
                {/* </div> */}
            </StyledCheckbox>
        </div>
    );
};
