// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps } from "@blueprintjs/core";

import { StyledSelect, StyledSelectTypes } from "src/components";

import { Classes, IMPDSelectProps } from "src/mpd-library";

import "./styles.scss";

export interface IStartEndTimerProps extends IProps {
    startTimeProps?: IMPDSelectProps;
    endTimeProps?: IMPDSelectProps;
}

export const StartEndTimer: React.FunctionComponent<IStartEndTimerProps> = (props) => {
    const { className, startTimeProps, endTimeProps } = props;
    const classes = "start-end-timer";
    return (
        <div className={classNames(classes, Classes.ROW, className)}>
            {startTimeProps && <StyledSelect type={StyledSelectTypes.primary} {...(startTimeProps as any)} />}
            {startTimeProps && endTimeProps && <hr className={classes + "-seperator"} />}
            {endTimeProps && <StyledSelect type={StyledSelectTypes.primary} {...(endTimeProps as any)} />}
        </div>
    );
};

export default StartEndTimer;
