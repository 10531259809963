// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { TabId } from "@blueprintjs/core";

import { IDetailView } from "../../../";

import { Route, Switch } from "react-router-dom";
import { BreadCrumbs, ContainerEmpty, StyledButton, StyledButtonTypes, SimpleList } from "src/components";
import { Classes } from "src/mpd-library";

import { SettingsGroupsListItem } from "../../components";

import GroupsSettingsAdd from "./group-settings-add/groups-settings-add";
import GroupsSettingsEdit from "./group-settings-edit/groups-settings-edit";

import "./styles.scss";
import { AppState, AccountSettingsGeneralAlertGroups } from "src/store";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { updateGroups } from "src/actions/account-settings/account-settings-api";
import { ReactComponent as GroupBlueIcon } from "../../../../mpd-library/icon/assets/group-blue.svg"

interface IGroupsSettingsProps extends IDetailView {
    routeProps: RouteComponentProps;
    groups: AccountSettingsGeneralAlertGroups;
    onSaveChanges: (groups: AccountSettingsGeneralAlertGroups) => void;
}

interface IGroupsSettingsState {
    listHeaderChecked: boolean;
    groupToEdit: any;
    filter?: string;
    sort?: Array<string> | string;
    selectedTabNum: TabId;
    tabs: Array<string>;
}

const componentPathname = "/account-settings/groups";

class GroupsSettings extends React.Component<IGroupsSettingsProps, IGroupsSettingsState> {
    constructor(props: IGroupsSettingsProps) {
        super(props);
        this.onChangeManageItemStatus = this.onChangeManageItemStatus.bind(this);
        this.onAddGroup = this.onAddGroup.bind(this);
        this.onGroupCheckBoxChanged = this.onGroupCheckBoxChanged.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onGroupsSelectChange = this.onGroupsSelectChange.bind(this);
        this.onGroupsSortChange = this.onGroupsSortChange.bind(this);

        this.updateToolBar("groups");

        this.state = {
            listHeaderChecked: false,
            groupToEdit: null,
            selectedTabNum: 0,
            sort: ["title", "description"],
            tabs: ["account", "settings"]
        };
    }

    public componentDidUpdate(prevProps: IGroupsSettingsProps) {
        const { routeProps } = this.props;
        if (
            prevProps.routeProps.location.pathname !== routeProps.location.pathname &&
            routeProps.location.pathname === componentPathname
        ) {
            this.updateToolBar("groups");
        }
    }

    public render() {
        const {
            className,
            groups,
            isOrSmallerTabletPortraitSize,
            onCloseRightPanel,
            onToggleRightPanel,
            routeProps
        } = this.props;
        const { groupToEdit, listHeaderChecked } = this.state;
        const classes = "group-settings";

        return (
            <div className={classNames(classes, className)}>
                <Switch location={routeProps.history.location}>
                    <Route
                        exact={true}
                        path={"/account-settings/groups"}
                        render={() =>
                            groups.list.length > 0 ? (
                                <SimpleList
                                    isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                                    list={groups.list}
                                    showOptions={listHeaderChecked}
                                    renderedComponent={this.renderList}
                                    placeholder="Search groups…"
                                />
                            ) : (
                                <ContainerEmpty
                                    className={classNames(classes + "-empty", Classes.CONTAINER_PADDING)}
                                    title="No Groups Found"
                                    subtitle="Looks like you haven’t added any groups yet. Click on the “Add Groups” button above to add your first group."
                                    IconComponent={GroupBlueIcon}
                                />
                            )
                        }
                    />
                    <Route
                        path={"/account-settings/groups/add/"}
                        render={() => (
                            <GroupsSettingsAdd
                                routeProps={routeProps}
                                onCloseRightPanel={onCloseRightPanel}
                                onToggleRightPanel={onToggleRightPanel}
                                onUpdateChangesMade={this.props.onUpdateChangesMade}
                            />
                        )}
                    />
                    <Route
                        path={"/account-settings/groups/edit/"}
                        render={() => (
                            <GroupsSettingsEdit
                                groupToEdit={groupToEdit}
                                routeProps={routeProps}
                                onCloseRightPanel={onCloseRightPanel}
                                onToggleRightPanel={onToggleRightPanel}
                                onUpdateChangesMade={this.props.onUpdateChangesMade}
                            />
                        )}
                    />
                </Switch>
            </div>
        );
    }

    private onGroupsSortChange(event: any) {
        let sort = event.currentTarget.textContent;
        if (sort.includes("A-Z")) {
            sort = ["title", "description"];
        } else if (sort.includes("Private")) {
            sort = "type";
        } else if (sort.includes("Public")) {
            sort = "-type";
        }
        this.setState({ sort });
    }

    private onGroupsSelectChange(event: React.ChangeEvent) {
        const { groups, onSaveChanges } = this.props;
        const list = groups.list;
        const select = event.currentTarget.textContent;
        if (select && select.includes("All")) {
            for (let i = 0; i < list.length; i++) {
                list[i].checked = true;
            }
        } else if (select && select.includes("None")) {
            for (let i = 0; i < list.length; i++) {
                list[i].checked = false;
            }
        }
        onSaveChanges(groups);
    }

    private onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
        const filter = event.currentTarget.value;
        this.setState({ filter });
    }

    private renderList = (props: any) => (
        <React.Fragment>
            {props.list.length === 0 ? (
                <ContainerEmpty
                    className={"group-settings-list-container-empty"}
                    title="No Groups Found"
                    subtitle="Looks like you haven’t added any groups yet. Click on the “Add Groups” button above to add your first group."
                    IconComponent={GroupBlueIcon}
                />
            ) : (
                props.list.map((group: any, index: number) => (
                    <SettingsGroupsListItem
                        className={"group-settings-container-empty"}
                        key={index}
                        onCheckChanged={() => this.onGroupCheckBoxChanged(index)}
                        onSelectChanged={(e) => this.onChangeManageItemStatus(e, index)}
                        active={group.active}
                        checked={group.checked}
                        description={group.description}
                        members={group.members}
                        title={group.title}
                        type={group.type}
                        onClick={(e) => this.onChangeManageItemStatus(e, index, "Edit")}
                        selectValue={group.active ? "Active Since " + group.date : "Deactive"}
                        options={group.active ? ["Edit", "Delete", "Deactivate"] : ["Edit", "Delete", "Activate"]}
                    />
                ))
            )}
        </React.Fragment>
    );

    private onChangeManageItemStatus(event: any, index: number, action?: string) {
        const { groups, onSaveChanges, routeProps } = this.props;
        const status = event.currentTarget.textContent;
        const list = groups.list;
        const accountId = list[index].id;
        if ((status && status.includes("Edit")) || (action && action.includes("Edit"))) {
            this.setState({ groupToEdit: list[index] }, () => {
                const pathname = "/account-settings/groups" + `/edit/${accountId}`;
                routeProps.history.push(pathname);
            });
        } else if (status && (status.includes("Deactivate") || status.includes("Activate"))) {
            list[index].active = !list[index].active;
        } else if (status && status.includes("Delete")) {
            list.splice(index, 1);
        }
        onSaveChanges(groups);
    }

    private onGroupCheckBoxChanged(index: number) {
        const { groups, onSaveChanges } = this.props;
        const list = groups.list;
        list[index].checked = !list[index].checked;
        if (list.some((element: any) => element.checked)) {
            groups.showListDetails = true;
        }
        if (list.every((element: any) => element.checked)) {
            this.setState({ listHeaderChecked: true });
        } else if (list.every((element: any) => !element.checked)) {
            this.setState({ listHeaderChecked: false });
        }

        onSaveChanges(groups);
    }

    private onAddGroup() {
        const { routeProps } = this.props;
        routeProps.history.push("/account-settings/groups/add");
    }

    private updateToolBar(text: string) {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: text.charAt(0).toUpperCase() + text.slice(1) }]}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: <StyledButton text="Add Group" onClick={this.onAddGroup} type={StyledButtonTypes.primary} />
        };
        if (this.props.onUpdateToolBar) {
            this.props.onUpdateToolBar(topBarProps);
        }
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings, global } = state;
    const { isOrSmallerTabletPortraitSize } = global;
    const groups = accountSettings.alerts.groups;
    return {
        groups,
        isOrSmallerTabletPortraitSize
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onSaveChanges: updateGroups
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSettings);
