import { ToastTypes } from "src/components";
import { ConversationsSettings } from "src/store/profile-automations";
import { axiosInstance } from "../common"
import { onUpdateToast } from "../global/global";
import { SET_PROFILE_AUTOMATIONS_SETTINGS } from "./types";

export const setProfileAutomationsSettings = (settings: ConversationsSettings) => ({
    type: SET_PROFILE_AUTOMATIONS_SETTINGS,
    payload: settings
});

export const getProfileAutomationsSettings = () => async (dispatch) => {
    const res = await axiosInstance.get(`/conversations/settings`);
    dispatch(setProfileAutomationsSettings(res.data.conversations_settings));
}

export const updateProfileAutomationsSetting = (updatedSetting) => async (dispatch) => {
    const res = await axiosInstance.patch("/conversations/settings", { settings: updatedSetting });
    dispatch(setProfileAutomationsSettings(res.data.conversations_settings));
    dispatch(onUpdateToast({ value: "Success! Your changes were saved", type: ToastTypes.saved }));
};