import { ShapeCoords } from "src/requests";

import { CollectionState, defaultCollectionState } from "./collection";
import { GeoFence, StudioAttachment, IIncidentLocation } from "./commonInterfaces";

export interface ILocationMarker {
    status: boolean;
    marker: any;
}

export interface FinishingTouchGeoFence extends GeoFence {
    list: Array<any>;
    search: {
        value: any;
    };
}

export interface FinishingTouchGeoFenceData {
    geoFence: FinishingTouchGeoFence;
    center: ShapeCoords | null;
    mapLayers: Array<any>;
    incidentLocation: IIncidentLocation;
}

export interface StudioState {
    loading: boolean;
    collection: CollectionState;
    attachmentLoading: boolean;
    isFullList: {
        banner: boolean;
        icon: boolean;
    };
    rightPanelExpanded: boolean;
    myMap: {};
    finishingTouchData: FinishingTouchGeoFenceData;
    attachments: { banner: Array<StudioAttachment>; icon: Array<StudioAttachment> };
}

export const defaultStudioState: StudioState = {
    loading: false,
    rightPanelExpanded: false,
    attachmentLoading: false,
    collection: {
        ...defaultCollectionState
    },
    myMap: {
        incidentLocation: {
            status: false,
            marker: null
        }
    },
    finishingTouchData: {
        geoFence: {
            status: false,
            list: [],
            currentShape: null,
            search: {
                value: null
            }
        },
        center: null,
        mapLayers: [],
        incidentLocation: {
            status: false,
            marker: null
        }
    },
    isFullList: {
        icon: false,
        banner: false
    },
    attachments: { banner: [], icon: [] }
};
