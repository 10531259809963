// tslint:disable jsx-no-lambda
import React, { useEffect, useState } from "react";
import { string, object, boolean } from "yup";
import classNames from "classnames";
import { Collapse, Alignment } from "@blueprintjs/core";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps, matchPath, useHistory, useParams } from "react-router";

import { globalActions, phoneFormat } from "src/actions";
import { IDetailView } from "../../../../";
import { PHONE_REGEXP } from "src/common/constants";

import {
    StyledCheckbox,
    StyledFloatingPhoneInput,
    StyledFloatingPhoneInputTypes,
    StyledButtonTypes,
    StyledButton
} from "src/components";

import { Permissions } from "src/components/permissions";

import { Classes } from "src/mpd-library";

import {
    BreadCrumbs,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    SettingsControlProperty,
    SettingsTitle
} from "src/components";

import "./styles.scss";
import { AppState, AccountSettingsState, AccountSettingsGeneralCurrentUser, UserCreationErrors } from "src/store";

import { AccountRole, AccountSettingsRolesRequestExtendingParams, AccountPrivilege } from "src/requests";
import {
    createAccountSettingsUsers,
    deleteCreatingUsersError,
    editAccountSettingsUsers,
    getAccountSettingsRoles,
    resetCurrentUser
} from "src/actions/account-settings/account-settings-api";
import { MPDSelect, MPDSelectTargetBorderRoundingClasses } from "../../../../../mpd-library";
import * as PhoneInputClasses from "src/mpd-library/floating-phone-input/classes";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { ReactComponent as CloseIcon } from "../../../../../mpd-library/icon/assets/close.svg";
import { ReactComponent as DownArrowIcon } from "../../../../../mpd-library/icon/assets/down-arrow.svg";
import { setFormikFieldErrors } from "../../../../conversations/components/chat/hooks/useExtendedFormik";
import isEmpty from "lodash/isEmpty";
import { PageLoadingSpinner } from "../../../../../components/page-loading-spinner/page-loading-spinner";

interface IUserSettingsAddProps extends IDetailView {
    accountSettings: AccountSettingsState;
    privileges: Array<AccountPrivilege> | null;
    currentUser: AccountSettingsGeneralCurrentUser;
    title?: string;
    roles: Array<AccountRole>;
    errors: UserCreationErrors;
    conversations_enabled: boolean;
    onGetAccountSettingsRoles: (params: AccountSettingsRolesRequestExtendingParams) => void;
    onCreateAccountSettingsUsers: (user: AccountSettingsGeneralCurrentUser, routeProps: RouteComponentProps) => void;
    onEditAccountSettingsUsers: (user: AccountSettingsGeneralCurrentUser, routeProps: RouteComponentProps) => void;
    onDeleteCreatingUsersError: (errorName: string) => void;
    onResetCurrentUser: () => void;
    addUser: () => void;
}

interface IUserSettingsAddState {
    currentUser: AccountSettingsGeneralCurrentUser;
    isCustomRoleSelected: boolean;
    scopes: {
        [key: string]: boolean;
    };
}

const UserSettingsAdd = (props) => {
    const { addUser, routeProps } = props;
    const { id: idFromUrl } = useParams<any>();
    const [isCustomRoleSelected, setCustomRoleSelected] = useState<boolean>(false);
    const history = useHistory();

    console.log("props.currentUser", props.currentUser);
    const formik = useFormik({
        initialValues: {
            id: props.currentUser.id,
            first_name: props.currentUser.firstName,
            last_name: props.currentUser.lastName,
            email: props.currentUser.email,
            phone: props.currentUser.phone,
            privileges: props.currentUser.privileges,
            role: props.currentUser.role,
            invitationChannels: {}
        },
        enableReinitialize: true,
        validationSchema: object(
            {
                first_name: string().required("Can't be blank"),
                last_name: string().required("Can't be blank"),
                ...(!addUser
                    ? {
                          email: string().required("Can't be blank").email("Enter valid email"),
                          phone: string().when("invitationChannels", {
                              is: (invitationChannels: boolean) => {
                                  return invitationChannels.sms;
                              },
                              then: string()
                                  .matches(PHONE_REGEXP, "Phone number is not valid")
                                  .required("Selected as invitation method. Can't be blank")
                          }),
                          invitationChannels: object()
                              .shape(
                                  {
                                      email: boolean().when("sms", {
                                          is: (sms) => !sms,
                                          then: boolean().oneOf([true])
                                      }),
                                      sms: boolean().when("email", {
                                          is: (email) => !email,
                                          then: boolean().oneOf([true])
                                      })
                                  },
                                  ["email", "sms"]
                              )
                              .test({
                                  name: "one-true",
                                  message: "At least one should be selected",
                                  test: (invitationChannels) => {
                                      return Object.keys(invitationChannels).some((key) => invitationChannels[key]);
                                  }
                              })
                      }
                    : {})
            },
            ["invitationChannels", "phone"]
        ),
        onSubmit: async (values, { setSubmitting }) => {
            const res = await onSaveChanges(values);
            const hasErrors = !isEmpty(res?.response?.data?.errors);
            if (hasErrors) {
                setFormikFieldErrors(res, formik);
            }
            setSubmitting(false);
            if (!addUser && !hasErrors) {
                history.replace("/account-settings/users");
            }
        }
    });

    console.log("FORMIK >>>>1", formik.values?.role?.title, formik.values?.role?.title || "");

    const { handleSubmit, setFieldValue, setValues, getFieldProps } = formik;

    useEffect(() => {
        updateToolBar(props);
        const { routeProps, onGetAccountSettingsRoles, onResetCurrentUser } = props;

        onGetAccountSettingsRoles({ sortTitle: "asc" });

        const isNewUserPage = matchPath(routeProps.location.pathname, {
            path: ["/account-settings/users/add"],
            exact: false,
            strict: false
        });

        if (isNewUserPage) {
            onResetCurrentUser();
        }
    }, []);

    useEffect(() => {
        if (!!props.currentUser.id) {
            setCustomRoleSelected(!props.currentUser?.role?.id);
        }
    }, [props.currentUser.id]);

    useEffect(() => {
        const { errors, onDeleteCreatingUsersError, onResetCurrentUser } = props;
        if (errors) {
            Object.keys(errors).forEach((errorName) => onDeleteCreatingUsersError(errorName));
        }

        return () => {
            onResetCurrentUser();
        };
    }, []);

    const onSelectRole = (event: any) => {
        const { roles } = props;
        const value = event.currentTarget.textContent;
        const role = roles.find((element: AccountRole) => element.title === value);
        setFieldValue("role", role);
        props.onDeleteCreatingUsersError("role_id");
    };

    const onCustomRoleSwitchChange = (event) => {
        setCustomRoleSelected(!isCustomRoleSelected);
        setValues({
            ...formik.values,
            role: !isCustomRoleSelected ? null : formik.values.role,
            privileges: !isCustomRoleSelected ? formik.values.privileges : []
        });

        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    };

    const onChangeInvitationChannels = (channel) => {
        const newInvitationChannels = {
            ...formik.values?.invitationChannels,
            [channel]: !formik.values?.invitationChannels[channel]
        };
        setFieldValue("invitationChannels", newInvitationChannels);
    };

    useEffect(() => {
        if (formik.dirty && props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    }, [formik.dirty]);

    const onChangePhoneInput = (value) => {
        setFieldValue("phone", value);

        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    };

    const updateToolBar = (props) => {
        const { routeProps, currentUser, title, addUser } = props;
        const isUserSettingsEditUserPath = matchPath(routeProps.location.pathname, {
            path: ["/account-settings/users/edit", `/account-settings/users/add`],
            exact: false,
            strict: false
        });

        if (!isUserSettingsEditUserPath) {
            return;
        }

        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Users", pathname: "/account-settings/users" },
                        {
                            text: title ? title : `${currentUser.firstName} ${currentUser.lastName}`
                        }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: props.onToggleRightPanel,
                        onClose: props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: !addUser && (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primarySimple}
                    IconComponent={CloseIcon}
                    onClick={() => routeProps.history.goBack()}
                />
            )
        };
        props.onUpdateToolBar(topBarProps);
    };

    useEffect(() => {
        updateToolBar(props);
    }, [props.currentUser]);

    const onSaveChanges = async (values) => {
        const id = values.id;
        let res;
        if (id) {
            res = await props.onEditAccountSettingsUsers(values, routeProps);
        } else {
            res = await props.onCreateAccountSettingsUsers({
                ...values,
                phone: values.phone ? values.phone : undefined,
                email: values.email ? values.email : undefined
            });
        }

        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(false);
        }

        return res;
    };

    const { className, roles, currentUser, errors, privileges, conversations_enabled } = props;
    const id = currentUser.id;
    const roleTitles = roles.map((role: AccountRole) => role.title);

    const classes = "user-settings-add";

    if (idFromUrl && !currentUser.id) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={classes + "-main-container"}>
            <form onSubmit={handleSubmit}>
                <div
                    className={classNames(
                        classes,
                        Classes.CONTAINER_PADDING,
                        Classes.ROW,
                        Classes.RESPONSIVE,
                        className
                    )}
                >
                    <div className={classNames(Classes.COLUMN)}>
                        <div className={classes + "-user-info"}>
                            <SettingsTitle title="User Info" />
                            <div className={Classes.ASSIGN_BLOCK_PADDING}>
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        placeholder="FIRST NAME"
                                        tabIndex={1}
                                        error={formik.touched.first_name && formik.errors.first_name}
                                        {...getFieldProps("first_name")}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        placeholder="LAST NAME"
                                        tabIndex={1}
                                        error={formik.touched.last_name && formik.errors.last_name}
                                        {...getFieldProps("last_name")}
                                    />
                                </div>
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    disabled={id !== undefined && id !== null && id !== ""}
                                    placeholder="EMAIL"
                                    tabIndex={1}
                                    error={formik.touched.email && formik.errors.email}
                                    {...getFieldProps("email")}
                                />
                                {!addUser && (
                                    <StyledCheckbox
                                        className={classNames(classes + "-checkbox", "show-switch")}
                                        label="Invite user via Email"
                                        checked={formik.values.invitationChannels?.email}
                                        onChange={() => onChangeInvitationChannels("email")}
                                        alignIndicator={Alignment.RIGHT}
                                        disabled={id !== undefined && id !== null && id !== ""}
                                        error={formik.errors.invitationChannels}
                                    />
                                )}
                                <StyledFloatingPhoneInput
                                    floatingType={StyledFloatingPhoneInputTypes.primary}
                                    className={PhoneInputClasses.FLOATING_PHONE_INPUT_CONTAINER}
                                    placeholder="PHONE"
                                    disabled={id !== undefined && id !== null && id !== ""}
                                    tabIndex={1}
                                    error={formik.errors.phone}
                                    {...getFieldProps("phone")}
                                    onChange={(value) => onChangePhoneInput(value)}
                                />
                                {!addUser && (
                                    <StyledCheckbox
                                        className={classNames(classes + "-checkbox", "show-switch")}
                                        label="Invite user via SMS"
                                        checked={formik.values.invitationChannels?.sms}
                                        onChange={() => onChangeInvitationChannels("sms")}
                                        alignIndicator={Alignment.RIGHT}
                                        disabled={id !== undefined && id !== null && id !== ""}
                                        error={formik.errors.invitationChannels}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classNames(Classes.COLUMN)}>
                        <div className={classes + "-user-role"}>
                            <SettingsTitle title="USER ROLE" />
                            <div
                                className={classNames(Classes.ASSIGN_BLOCK_MARGIN, classes + "-user-role-select-block")}
                            >
                                <MPDSelect
                                    title={"ROLE"}
                                    className={classNames(
                                        // classes + "-styled-select",
                                        MPDSelectTargetBorderRoundingClasses.semiRounded
                                    )}
                                    defaultOptionText="Select a Role"
                                    onChange={onSelectRole}
                                    options={roleTitles}
                                    value={formik.values?.role?.title || ""}
                                    error={errors && errors.role_id && errors.role_id[0]}
                                    disabled={isCustomRoleSelected}
                                    ArrowIconComponent={DownArrowIcon}
                                />
                                <SettingsControlProperty
                                    className={classNames(classes + "-control-property")}
                                    title="Custom Role"
                                    subtitle="Select custom role for users with specific access and permissions."
                                    switchProps={{
                                        onChange: () => onCustomRoleSwitchChange(isCustomRoleSelected),
                                        checked: isCustomRoleSelected
                                    }}
                                />
                                <Collapse isOpen={isCustomRoleSelected}>
                                    <div className={classes + "-access-permissions"}>
                                        <SettingsTitle title="ACCESS &amp; PERMISSIONS" />
                                        <div className={Classes.ASSIGN_BLOCK_MARGIN}>
                                            <Permissions formik={formik} />
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <FormWasChanged disableCounter={!addUser} formik={formik} />
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;
    const roles = accountSettings.general.roles.list;
    const errors = accountSettings.general.users.userCreationErrors;
    const privileges = accountSettings.general.privileges;
    const { conversations_enabled } = accountSettings.organization;

    return {
        accountSettings,
        currentPlan,
        roles,
        errors,
        privileges,
        conversations_enabled
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCreateAccountSettingsUsers: createAccountSettingsUsers,
            onEditAccountSettingsUsers: editAccountSettingsUsers,
            onGetAccountSettingsRoles: getAccountSettingsRoles,
            onDeleteCreatingUsersError: deleteCreatingUsersError,
            onResetCurrentUser: resetCurrentUser
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsAdd);
