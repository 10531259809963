import classNames from "classnames";
import React from "react";

import { Collapse, Props, TabId } from "@blueprintjs/core";

import * as MPDClasses from "../";

import { MPDIOSSelectableDiv } from "src/mpd-library";

import "./styles.scss";

export interface IMPDTreeNode<T = {}> extends Props {
    childNodes?: Array<IMPDTreeNode>;
    id: TabId;
    parentId?: TabId;
    isExpanded?: boolean;
    isSelected?: boolean;
    shouldCollapse?: boolean;
    hasChild?: boolean;
    nodeData?: T;
}

export interface IMPDTreeNodeProps<T = {}> extends IMPDTreeNode<T> {
    children?: React.ReactNode;
    NodeItem: React.ComponentClass<any> | React.StatelessComponent<any>;
    contentRef?: (node: MPDTreeNode, element: HTMLDivElement | null) => void;
    returnNode?: boolean;
    depth: number;
    key?: string | number;
    loading?: boolean;
    onClick?: (node: MPDTreeNode, e: React.MouseEvent<HTMLDivElement>) => void;
    onGetNode?: (node: MPDTreeNode) => void;
    path: Array<number>;
    title?: string;
    description?: string;
    selectedId?: string | number;
}

export class MPDTreeNode<T = {}> extends React.Component<IMPDTreeNodeProps<T>> {
    public static ofType<T>() {
        return MPDTreeNode as new (props: IMPDTreeNodeProps<T>) => MPDTreeNode<T>;
    }

    state = {
        returnNode: false
    };

    public componentDidMount() {
        const { returnNode } = this.props;
        if (returnNode) {
            this.props.onGetNode?.(this.props.children);
        }
    }

    public render() {
        const {
            children,
            className,
            hasChild,
            id,
            isExpanded,
            isSelected,
            loading,
            NodeItem,
            selectedId,
            ...remaining
        } = this.props;

        const classes = classNames(
            MPDClasses.TREE_NODE,
            {
                [MPDClasses.SELECTED]: isSelected || (selectedId && selectedId === id),
                [MPDClasses.EXPANDED]: isExpanded,
                [MPDClasses.HAS_CHILD]: hasChild,
                [MPDClasses.NO_CHILD]: !hasChild
            },
            className
        );

        const contentClasses = classNames(
            MPDClasses.TREE_NODE_CONTENT,
            `${MPDClasses.TREE_NODE_CONTENT}-${this.props.depth}`
        );

        return (
            <div className={classes} onClick={this.handleCaretClick}>
                <MPDIOSSelectableDiv
                    className={contentClasses}
                    onClick={this.handleClick}
                    onContextMenu={this.handleContextMenu}
                    onDoubleClick={this.handleDoubleClick}
                    ref={this.handleContentRef}
                >
                    <NodeItem
                        className={classNames({
                            [MPDClasses.SELECTED]: isSelected || (selectedId && selectedId === id),
                            [MPDClasses.EXPANDED]: isExpanded,
                            [MPDClasses.HAS_CHILD]: hasChild
                        })}
                        id={id}
                        loading={loading}
                        {...remaining}
                    />
                    <div
                        className={classNames(MPDClasses.TREE_NODE_CHILD, {
                            [MPDClasses.EXPANDED]: isExpanded,
                            [MPDClasses.HAS_CHILD]: hasChild
                        })}
                    />
                </MPDIOSSelectableDiv>
                <Collapse isOpen={isExpanded}>{children}</Collapse>
            </div>
        );
    }

    private handleClick = (e: React.MouseEvent<any>) => {
        this.props.onClick?.(this, e);
    };

    private handleContentRef = (element: any | null) => {
        this.props.contentRef?.(this, element);
    };

    private handleContextMenu = (e: React.MouseEvent<any>) => {
        this.props.onContextMenu?.(this, e);
    };

    private handleDoubleClick = (e: React.MouseEvent<any>) => {
        this.props.onDoubleClick?.(this, e);
    };
}
