import { useEffect } from "react";

export const useOutsideAlerter = (ref, onOutsideClick) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target instanceof HTMLInputElement) {
                return;
            }
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            } else {
            }
        };
        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [ref, onOutsideClick]);

    return ref;
};
