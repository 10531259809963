// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { IProps, Position } from "@blueprintjs/core";
import { CheckmarkSelect, StyledSelect, SearchInput, StyledSelectTypes } from "src/components";

import { Classes, IMPDSortTypes } from "src/mpd-library";

import "./styles.scss";
import { SearchInputClasses } from "../search-input/search-input";
import { ReactComponent as TripleLineVerticalIcon } from "../../mpd-library/icon/assets/triple-line-vertical.svg"
import { ReactComponent as UpArrowIcon } from "../../mpd-library/icon/assets/up-arrow.svg"
import { ReactComponent as DownArrowIcon } from "../../mpd-library/icon/assets/down-arrow.svg"

export interface IListHeaderProps extends IProps {
    hideCheckMarkSelect?: boolean;
    hideSortSelect?: boolean;
    placeholder?: string;
    searchValue?: string;
    leftElement?: JSX.Element;
    rightElement?: JSX.Element;
    selectOptions?: Array<string>;
    sortOptions?: Array<string>;
    checked?: boolean;
    leftIcon?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    label?: string;
    sortOrder?: IMPDSortTypes;
    customHeader?: any;
    selectChange?(event: any | string): void;
    sortChange?(event: React.SyntheticEvent): void;
    onSearchChange?(event: React.SyntheticEvent): void;
}

export const ListHeader: React.FunctionComponent<IListHeaderProps> = (props) => {
    const {
        className,
        selectChange,
        leftElement,
        sortOrder,
        hideCheckMarkSelect,
        hideSortSelect,
        rightElement,
        checked,
        selectOptions,
        sortOptions,
        placeholder,
        onSearchChange,
        searchValue,
        sortChange,
        leftIcon,
        IconComponent,
        label,
        customHeader
    } = props;
    const classes = classNames("list-header-container", className);
    return (
        <>
            <div className={classNames(classes)}>
                <div className={Classes.LEFT}>
                    {!hideCheckMarkSelect && (
                        <CheckmarkSelect
                            className={className + "-checkmark-select"}
                            checkboxProps={{
                                checked,
                                onChange: (e) =>
                                    selectChange &&
                                    selectChange({
                                        currentTarget: {
                                            textContent: e.currentTarget.checked ? "All" : "None"
                                        }
                                    })
                            }}
                            selectProps={{
                                options: selectOptions || ["All", "None"],
                                cannotChange: true,
                                value: " ",
                                onChange: selectChange,
                                popOverClassName: className + "-checkmark-select-popover"
                            }}
                        />
                    )}
                    {!hideSortSelect && (
                        <StyledSelect
                            className={className + "-styled-select"}
                            type={[StyledSelectTypes.image]}
                            options={sortOptions || ["A-Z", "Z-A"]}
                            popOverClassName={className + "-styled-select-popover"}
                            cannotChange={true}
                            ArrowIconComponent={sortOrder === IMPDSortTypes.asc ? UpArrowIcon : DownArrowIcon}
                            value=" "
                            label={label}
                            LeftIconComponent={IconComponent || TripleLineVerticalIcon }
                            onChange={sortChange}
                            position={Position.BOTTOM_LEFT}
                        />
                    )}
                    {leftElement}
                </div>
                <div className={Classes.RIGHT}>
                    {rightElement}
                    <SearchInput placeholder={placeholder} value={searchValue} onChange={onSearchChange} className={SearchInputClasses.list} />
                </div>
            </div>
            {customHeader}
        </>
    );
};
