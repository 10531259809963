import {
    UPDATE_ALERT_COMPOSE_DATA,
    UPDATE_ALERT_COMPOSE_DATA_ERRORS,
    UPDATE_SOCIAL_MEDIA_DESIGN,
    UPDATE_SOCIAL_MEDIA_THEME,
    SET_ALERT_GENERATED_PREVIEW,
    UPDATE_SAVE_STEP_PROCESSING,
    UPDATE_ALERT_LIST,
    UPDATE_GENERATE_PREVIEW_PROCESSING_STATUS,
    AlertActionTypes,
    SET_EVENTS_MATHING_TABLE
} from "./types";

import { GOOGLE_API_KEY } from "../../App/globals";
import findIndex from "lodash/findIndex";

import {
    changedTabs,
    updateSelectedAlert,
    changeAlertStatus,
    removeFromList,
    getAlertsList,
    selectAlertInList,
    resetComposeDataToInitialState
} from "./alerts-list-api";

import { updateGlobalSpinnerStatus } from "../global/global-api";

import { IToastType, ToastTypes } from "src/components";
import { updateToast, updateChangesMade } from "../global/global-api";

import moment from "moment";

import {
    deleteAlertRequest,
    postAlertRequest,
    deleteMediaRequest,
    patchChangeAlertStatusByIdRequest
} from "../../requests";
import {
    AppState,
    ComposeDataChooseAudience,
    AlertComposeData,
    ComposeDataAlertDetails,
    AlertStateData,
    AlertStateAlertData,
    ComposeDataComposeMessage,
    defaultAlertsState
} from "../../store";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router";
import { OnUpdateComposeBottomToolBarProps, OnUpdateMessageDetailsProps } from "./alerts-compose";

import { geometryCollection, GeometryCollection } from "@turf/helpers";
import { ListsIdTypes } from "src/containers";
import { getListItem } from "src/selectors";
import { axiosInstance } from "src/actions";
import { subscribeToTopic } from "../websocket/websocket";
import { onUpdateMultimediaCommon } from "./update-multimedia";
import { setAlertRepliesSettings } from "./alerts";
import { getEventsMatchingTable } from "src/requests/settingsRequests";

export const updateGlobalAndSaveStepProcessingStatus = (status: boolean, dispatch: Dispatch<AlertActionTypes>) => {
    dispatch(updateSaveStepProcessing(status));
    dispatch(updateGlobalSpinnerStatus(status));
};

export const addShapeImages = (shape: any) => {
    const size = "110x60";
    const smallSize = "277x120";
    const mediumSize = "324x207";
    const largeSize = "840x173";
    const pathStyle = "weight:4|fillcolor:0xa7a296|color:0x1f76ff";
    const encodedPath = getEncodedPath(shape);
    shape.src = `https://maps.googleapis.com/maps/api/staticmap?path=${pathStyle}|enc:${encodedPath}&size=${size}&key=${GOOGLE_API_KEY}`;
    shape.small = `https://maps.googleapis.com/maps/api/staticmap?path=${pathStyle}|enc:${encodedPath}&size=${smallSize}&key=${GOOGLE_API_KEY}`;
    shape.medium = `https://maps.googleapis.com/maps/api/staticmap?path=${pathStyle}|enc:${encodedPath}&size=${mediumSize}&key=${GOOGLE_API_KEY}`;
    shape.large = `https://maps.googleapis.com/maps/api/staticmap?path=${pathStyle}|enc:${encodedPath}&size=${largeSize}&key=${GOOGLE_API_KEY}`;
    return shape;
};

const getEncodedPath = (shape: any) => {
    let encodedPath;
    if (shape.type === "rectangle") {
        const bounds = shape.bounds;
        let northEast = JSON.parse(JSON.stringify(bounds.getNorthEast()));
        let southWest = JSON.parse(JSON.stringify(bounds.getSouthWest()));
        const southEast = new google.maps.LatLng(northEast.lat, southWest.lng);
        const northWest = new google.maps.LatLng(southWest.lat, northEast.lng);
        northEast = new google.maps.LatLng(northEast.lat, northEast.lng);
        southWest = new google.maps.LatLng(southWest.lat, southWest.lng);
        const paths = new google.maps.MVCArray();
        paths.push(northWest);
        paths.push(northEast);
        paths.push(southEast);
        paths.push(southWest);
        paths.push(northWest);
        encodedPath = google.maps.geometry.encoding.encodePath(paths as any);
    } else if (shape.type === "polygon") {
        const paths = shape.paths;
        paths.push(paths[0]);
        encodedPath = google.maps.geometry.encoding.encodePath(paths);
    } else if (shape.type === "circle") {
        const radius = shape.radius;
        const center = shape.center;
        const paths = new google.maps.MVCArray();
        let d = 0;
        const points = 360;
        const p = 360 / points;
        for (let i = 0; i < points; ++i, d += p) {
            paths.push(google.maps.geometry.spherical.computeOffset(center, radius, d));
        }
        encodedPath = google.maps.geometry.encoding.encodePath(paths as any);
    }
    return encodedPath;
};

interface UpdateComposeBottomToolBarProps {
    bottomBarProps: OnUpdateComposeBottomToolBarProps | null;
    composeData: AlertComposeData;
}
export const updateComposeBottomToolBar =
    (ref: UpdateComposeBottomToolBarProps) => (dispatch: Dispatch<AlertActionTypes>) => {
        const { bottomBarProps, composeData } = ref;
        composeData.bottomBarProps = bottomBarProps;
        dispatch(updateComposeData({ composeData }));
    };

export interface UpdateMessageDetailsRefProps {
    messageDetails: OnUpdateMessageDetailsProps;
    composeData: AlertComposeData;
}

export const updateAlertReplySettings = (settings: RepliesSettings) => (dispatch: Dispatch<AlertActionTypes>) => {
    dispatch(setAlertRepliesSettings(settings));
};

export const updateMessageDetails = (ref: UpdateMessageDetailsRefProps) => (dispatch: Dispatch<AlertActionTypes>) => {
    const { messageDetails, composeData } = ref;
    composeData.composeMessage.messageDetails = messageDetails;
    const changesMade = true;
    dispatch(updateChangesMade({ changesMade }));
    dispatch(updateComposeData({ composeData }));
};

export const putThumbnails =
    (thumbnail: any) => async (dispatch: Dispatch<AlertActionTypes>, getState: () => AppState) => {
        const { composeData } = getState().alerts;
        const { multiMedia } = composeData.composeMessage;
        const { files } = multiMedia;
        if (files.length && thumbnail.uuid) {
            const fileIndex = findIndex(files, (file) => {
                return file.uuid === thumbnail.uuid;
            });
            files[fileIndex] = {
                ...files[fileIndex],
                thumbnail: {
                    ...thumbnail.thumbnail
                },
                loadingPreview: files[fileIndex].loadingPreview === undefined ? undefined : false
            };
            composeData.composeMessage.multiMedia.files = files;
            dispatch(updateComposeData({ composeData }));
        }
    };

interface UpdateMultiMediaProps {
    multiMedia: any;
    composeData: AlertComposeData;
    customParams?: {
        id: string;
        setFinishedStatusForOrderedFile: (id: string) => void;
    };
    upload?: boolean;
}

export const updateMultiMedia = (ref: UpdateMultiMediaProps) => async (dispatch: Dispatch<AlertActionTypes>) => {
    const { multiMedia, composeData, customParams, upload } = ref;
    let files: Array<any> = [];

    if (upload) {
        files = await onUpdateMultimediaCommon(multiMedia);

        if (customParams) {
            customParams.setFinishedStatusForOrderedFile(customParams.id);
        }
    }

    const newComposeData = {
        composeData: {
            ...composeData,
            composeMessage: {
                ...composeData.composeMessage,
                multiMedia: upload
                    ? {
                          ...composeData.composeMessage.multiMedia,
                          files: [...composeData.composeMessage.multiMedia.files, ...files]
                      }
                    : { ...multiMedia }
            }
        }
    };

    dispatch(updateChangesMade({ changesMade: true }));
    dispatch(updateComposeData(newComposeData));
};

export const deleteMultiMedia = async (uuid: string) => {
    await deleteMediaRequest(uuid);
};

interface UpdateComposeMessageProps {
    composeMessage: ComposeDataComposeMessage;
    composeData: AlertComposeData;
}
export const updateComposeMessage = (ref: UpdateComposeMessageProps) => (dispatch: Dispatch<AlertActionTypes>) => {
    const { composeMessage, composeData } = ref;
    composeData.composeMessage = composeMessage;
    const changesMade = true;
    dispatch(updateChangesMade({ changesMade }));
    dispatch(updateComposeData({ composeData }));
};

interface UpdateAlertDetailsProps {
    alertDetails: ComposeDataAlertDetails;
}
export const updateAlertDetails =
    (ref: UpdateAlertDetailsProps) => (dispatch: Dispatch<AlertActionTypes>, getState: () => AppState) => {
        const { alertDetails } = ref;
        const {
            alerts: { composeData }
        } = getState();
        const changesMade = true;
        dispatch(updateChangesMade({ changesMade }));
        dispatch(
            updateComposeData({
                composeData: {
                    ...composeData,
                    alertDetails
                }
            })
        );
    };

interface UpdateChooseAudienceProps {
    chooseAudience: ComposeDataChooseAudience;
    composeData: AlertComposeData;
}
export const updateChooseAudience = (ref: UpdateChooseAudienceProps) => (dispatch: Dispatch<AlertActionTypes>) => {
    const { chooseAudience, composeData } = ref;
    composeData.chooseAudience = { ...chooseAudience };
    const changesMade = true;
    dispatch(updateChangesMade({ changesMade }));
    dispatch(updateComposeData({ composeData }));
};

interface UpdateSelectChannelsArrayProps {
    selectChannelsArray: Array<string>;
    composeData: AlertComposeData;
    changesMade: boolean;
}

export const updateSelectChannelsArray =
    (ref: UpdateSelectChannelsArrayProps) => (dispatch: Dispatch<AlertActionTypes>) => {
        const { selectChannelsArray, composeData, changesMade } = ref;
        composeData.channelIdsObject.channelIds = selectChannelsArray;
        dispatch(updateChangesMade({ changesMade }));
        dispatch(updateComposeData({ composeData }));
    };

interface UpdateComposeDataProps {
    composeData: AlertComposeData;
}
export const updateComposeData = (ref: UpdateComposeDataProps) => (dispatch: Dispatch<AlertActionTypes>) => {
    const { composeData } = ref;
    dispatch({
        type: UPDATE_ALERT_COMPOSE_DATA,
        payload: composeData
    });
};

interface DeleteAlertProps {
    id: string;
    routeProps: RouteComponentProps;
    alert: AlertStateData;
    tabId: ListsIdTypes;
}
export const deleteAlert = (ref: DeleteAlertProps) => async (dispatch: Dispatch<AlertActionTypes>) => {
    const { id, routeProps, tabId } = ref;
    if (id === "-1") {
        routeProps.history.push("/alerts");
    } else {
        await deleteAlertRequest(id);
        dispatch(removeFromList({ id, tabId }));
        routeProps.history.push("/alerts");
    }
    resetComposeDataToInitialState();
    dispatch(updateChangesMade({ changesMade: false }));
};

interface HideComposeProps {
    routeProps: RouteComponentProps;
    id: string;
    alert: AlertStateData;
}
export const hideCompose = (ref: HideComposeProps) => {
    const { routeProps, id, alert } = ref;
    const lists = alert.lists;
    const allAlertLists = [
        ...lists.active.alertsList,
        ...lists.expired.alertsList,
        ...lists.archived.alertsList,
        ...lists.draft.alertsList
    ];
    const selectedAlert = getListItem(allAlertLists, id);
    if (selectedAlert !== undefined) {
        selectedAlert.isSelected = false;
    }
    routeProps.history.push("/alerts");
};

interface CreateAlertProps {
    id: string;
    routeProps: RouteComponentProps;
    status: ListsIdTypes;
    launchCodes?: Array<string>;
    nextStepperRoute?: string;
    navToAnotherAlert?: boolean;
    callBack?: any;
}
export const createAlert =
    (ref: CreateAlertProps) => async (dispatch: Dispatch<AlertActionTypes>, getState: () => AppState) => {
        updateGlobalAndSaveStepProcessingStatus(true, dispatch);
        const { id, routeProps, status, nextStepperRoute, navToAnotherAlert, callBack } = ref;
        const state = getState();
        const { accountSettings, alerts } = state;
        const { composeData } = alerts;
        let { alert } = alerts;

        const center = accountSettings.organization.details.center;
        const updatedComposeData = { ...composeData, status, center };
        const data: AlertStateAlertData = formatAlertFromComposeData(updatedComposeData);
        const res = id !== "-1" ? await patchChangeAlertStatusByIdRequest(id, data) : await postAlertRequest(data);
        const selectedAlert = res.data.alert as AlertStateAlertData;
        // selectedAlert.isSelected = true;

        if (selectedAlert.location) {
            const reverseGeocode = await axiosInstance.post("/account/geo/reverse_geocode", {
                location: { ...selectedAlert.location }
            });
            selectedAlert.address.address_formatted = reverseGeocode.data.address_formatted;
        }
        if (id !== "-1" && status !== "draft" && alert.selectedAlert && alert.selectedAlert.status === "draft") {
            dispatch(getAlertsList({ params: { status: "draft" }, offset: 0 }));
        }

        await dispatch(getAlertsList({ params: { status }, offset: 0 }));

        alert = getState().alerts.alert;

        if (callBack) {
            dispatch(updateChangesMade({ changesMade: false }));
            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: composeData.status,
                    listData: { alertsList: alert.lists["draft"].alertsList }
                }
            });
            callBack();
            updateGlobalAndSaveStepProcessingStatus(false, dispatch);
            return;
        }

        dispatch(
            updateSelectedAlert({
                selectedAlert,
                composeData: updatedComposeData
            })
        );

        await dispatch(selectAlertInList({ listName: status, alertId: selectedAlert.id }));

        let toastMessage;

        if (id === "-1") {
            toastMessage = "Draft alert was successfully created";
        } else {
            toastMessage = "Alert was successfully sent";
        }
        const toast: IToastType = {
            value: toastMessage,
            type: ToastTypes.saved
        };

        updateGlobalAndSaveStepProcessingStatus(false, dispatch);

        if (navToAnotherAlert) {
            const matchResult = nextStepperRoute?.match(/(?:id-)([^#]+)(?=\/)/);
            const anotherAlertId = matchResult ? matchResult[1] : 1;
            const anotherAlert = alert.lists["draft"].alertsList.filter((alert: any) => alert.id == anotherAlertId)[0];
            anotherAlert && (anotherAlert.isSelected = true);
            const updatedAlertList = alert.lists["draft"].alertsList.map((alert) => {
                if (alert.id == selectedAlert.id) {
                    return alert;
                }
                if (alert.id == anotherAlertId) {
                    return alert;
                }
                return alert;
            });
            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: composeData.status,
                    listData: { alertsList: updatedAlertList }
                }
            });
            dispatch(updateSelectedAlert({ selectedAlert: anotherAlert, composeData, isOnSaveDraft: true }));
            dispatch(selectAlertInList({ listName: status, alertId: anotherAlert.id }));
        }

        dispatch(updateToast({ toast }));
        dispatch(updateChangesMade({ changesMade: false }));
        dispatch(changedTabs({ tabId: composeData.status }));
        updateGlobalAndSaveStepProcessingStatus(false, dispatch);
        if (routeProps) {
            if (status === "draft") {
                const newRoute =
                    id === "-1" ? nextStepperRoute?.replace("/id/", `/id-${selectedAlert.id}/`) : nextStepperRoute;
                if (navToAnotherAlert && nextStepperRoute) {
                    routeProps.history.replace(nextStepperRoute);
                    updateGlobalAndSaveStepProcessingStatus(false, dispatch);
                    return;
                }
                routeProps.history.push(newRoute || "/alerts/compose");
            } else {
                routeProps.history.push(`/alerts/id-${selectedAlert.id}/`);
                dispatch(subscribeToTopic("portal_alerts"));
                dispatch({
                    type: UPDATE_ALERT_COMPOSE_DATA,
                    payload: {
                        ...defaultAlertsState.composeData
                    }
                });
            }
        }
    };

const formatShape = (currentShape: any) => {
    let geoFence: GeometryCollection | null = null;
    if (currentShape) {
        if (currentShape.type === "FeatureCollection") {
            const geoFenceFeatures = (currentShape as any).features;
            const featuresGeometry = geoFenceFeatures.map((gFeature: any) => gFeature.geometry);
            geoFence = geometryCollection(featuresGeometry).geometry;
        } else {
            geoFence = currentShape.geometry;
        }
    }
    return geoFence;
};

const formatAlertFromComposeData = (composeData: AlertComposeData) => {
    const { alertDetails, composeMessage, chooseAudience, channelIdsObject } = composeData;
    const { severity, incidentLocation, expiration, location_centered, perimeter_equals_geo_fence } = alertDetails;
    const { marker } = incidentLocation;

    const { messageDetails, multiMedia } = composeMessage;

    const { files } = multiMedia;
    const attachments: Array<any> = [];

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { thumbnail } = file;
        const obj = {
            name: file.name,
            size: file.size,
            url: file.url,
            type: file.type,
            thumbnail
        };
        if (file.type.includes("video")) {
            obj.thumbnail.url = file.thumbnail.url;
        }
        attachments.push(obj);
    }

    const { description, instructions, headline } = messageDetails;
    const { currentShape, jurisdiction } = chooseAudience.geoFence;
    const status = composeData.status ? composeData.status : "active";
    let geoFence;
    let location;

    if (currentShape) {
        if (currentShape.type === "FeatureCollection") {
            const geoFenceFeatures = (currentShape as any).features;
            const featuresGeometry = geoFenceFeatures.map((gFeature: any) => gFeature.geometry);
            geoFence = geometryCollection(featuresGeometry).geometry;
        } else {
            geoFence = currentShape.geometry;
        }

        // const geoFenceCenterCoordinates = (TurfCenter(currentShape as FeatureCollection | Feature).geometry as any)
        //     .coordinates;

        // location = {
        //     coordinates: geoFenceCenterCoordinates,
        //     type: "Point",
        // };
    } else if (jurisdiction) {
        geoFence = (jurisdiction as any).geometry;

        //@ts-ignore
        // const geoFenceCenterCoordinates = (TurfCenter(jurisdiction as FeatureCollection | Feature).geometry as any)
        //     .coordinates;
    }

    if (marker) {
        location = marker.geometry;
    }

    const { perimeter } = alertDetails;
    let perimeterToFormat;
    if (perimeter && perimeter.status && perimeter.currentShape) {
        perimeterToFormat = perimeter.currentShape;
    }

    const formattedPerimeter = !perimeter_equals_geo_fence ? formatShape(perimeterToFormat) : null;
    const data: any = {
        description,
        title: headline,
        instructions,
        severity,
        status,
        attachments,
        channel_ids: channelIdsObject.channelIds,
        og_image_style: composeData.og_image_style,
        og_image_theme: composeData.og_image_theme,
        address: {
            components: alertDetails.address.components,
            custom_text: alertDetails.address.custom_text,
            address_formatted: alertDetails.address.address_formatted
        },
        perimeter: formattedPerimeter,
        location_centered,
        perimeter_equals_geo_fence,
        allow_replies: composeData.allow_replies
    };

    if (composeData.allow_replies) {
        data.replies_settings = { tag_ids: [] };
        if (composeData.replies_settings.queue) {
            data.replies_settings.queue_id = composeData.replies_settings.queue.id;
        }
        if (composeData.replies_settings.tags?.length) {
            data.replies_settings.tag_ids = composeData.replies_settings.tags.map((tag) => tag.id);
        }
        if (composeData.replies_settings.admin) {
            data.replies_settings.admin_id = composeData.replies_settings?.admin?.id;
        }
    }

    if (expiration.value === "0") {
        const date: Date = expiration.custom.date || new Date();
        const time = expiration.custom.time;
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const m = moment(year + "-" + month + "-" + day + " " + time, "YYYY-MM-DD hh:mm a");
        const unixTime = m.unix();
        data.expires_at = unixTime;
    } else {
        // const values = [1, 6, 12, 24, 48, 72, 0];
        data.expires_interval_hours = expiration.value;
    }

    if (location !== undefined) {
        data.location = location;
    }
    if (geoFence !== undefined) {
        data.geo_fence = geoFence;
    }
    return data;
};

export const updateSaveStepProcessing = (value: boolean) => {
    return {
        type: UPDATE_SAVE_STEP_PROCESSING,
        payload: value
    };
};

interface SaveDraftProps {
    id: string;
    routeProps: RouteComponentProps;
    nextStepperRoute?: string;
    navToAnotherAlert?: boolean;
    callBack?: any;
}
export const saveDraft =
    (ref: SaveDraftProps) => async (dispatch: Dispatch<AlertActionTypes>, getState: () => AppState) => {
        const { id, routeProps, nextStepperRoute, navToAnotherAlert, callBack } = ref;
        const state = getState();
        const { alerts } = state;
        const { composeData } = alerts;
        updateGlobalAndSaveStepProcessingStatus(true, dispatch);
        // tslint:disable-next-line:triple-equals
        if (id == "-1") {
            dispatch(
                createAlert({
                    id,
                    routeProps,
                    status: "draft",
                    nextStepperRoute,
                    navToAnotherAlert,
                    callBack
                })
            );
        } else {
            composeData.status = "draft";
            const data = formatAlertFromComposeData(composeData);
            dispatch(
                changeAlertStatus({
                    data,
                    id,
                    tabId: "draft",
                    routeProps,
                    nextStepperRoute,
                    navToAnotherAlert,
                    callBack
                })
            );
        }
    };

interface UpdatePreviewSendProps {
    composeData: AlertComposeData;
}
export const updatePreviewSend = (ref: UpdatePreviewSendProps) => (dispatch: Dispatch<AlertActionTypes>) => {
    const { composeData } = ref;
    const { chooseAudience, center, alertDetails, composeMessage, logo } = composeData;

    const launchCodes: Array<string> = [];

    const geoFence = chooseAudience.geoFence;
    let audience;

    for (const key of Object.keys(chooseAudience.audiences)) {
        if (chooseAudience.audiences[key].status) {
            audience = key;
        }
    }

    const expiration = alertDetails.expiration.value;
    let expirationDate = "";

    if (expiration === "custom") {
        const customExpirationDate = new Date(alertDetails.expiration.custom.date);
        const month = customExpirationDate.getMonth();
        const day = customExpirationDate.getDate();
        const year = customExpirationDate.getFullYear();
        expirationDate = month + "/" + day + "/" + year + " " + alertDetails.expiration.custom.time;
    }

    const incidentLocation = alertDetails.incidentLocation;
    const headline = composeMessage.messageDetails.headline;
    const description = composeMessage.messageDetails.description;
    const mediaFiles = composeMessage.multiMedia.files;
    const images: Array<any> = [];
    const files: Array<any> = [];

    for (let i = 0; i < mediaFiles.length; i++) {
        if (mediaFiles[i].id.includes("image")) {
            images.push(mediaFiles[i]);
        } else {
            files.push(mediaFiles[i]);
        }
    }

    const date = "December 31, 2018  04:32 AM";
    const severity = alertDetails.severity;
    const previewSend = {
        logo,
        center,
        date,
        headline,
        files,
        description,
        images,
        expiration,
        expirationDate,
        launchCodes,
        incidentLocation,
        audience,
        severity,
        geoFence
    };

    composeData.previewSend = previewSend;
    dispatch(updateComposeData({ composeData }));
};

export const showIncidentLocationWarning = () => (dispatch: Dispatch<AlertActionTypes>) => {
    const toast: IToastType = {
        value: "Your incident marker is outside of jurisdiction",
        type: ToastTypes.error
    };

    dispatch(updateToast({ toast }));
};

export interface UpdateComposeDataErrorsProps {
    errorBlock: "messageDetails" | "alertDetails";
    errorField: string;
    errorValue: string | null;
}
export const updateComposeDataErrors =
    ({ errorBlock, errorField, errorValue }: UpdateComposeDataErrorsProps) =>
    (dispatch: Dispatch<AlertActionTypes>, getState: () => AppState) => {
        const {
            alerts: {
                composeData: { composeDataErrors }
            }
        } = getState();
        const newErrors = {
            ...composeDataErrors,
            [errorBlock]: {
                ...composeDataErrors[errorBlock],
                [errorField]: errorValue
            }
        };

        dispatch({
            type: UPDATE_ALERT_COMPOSE_DATA_ERRORS,
            payload: newErrors
        });
    };

export const updateSocialMediaDesign = (value: string) => (dispatch: Dispatch<AlertActionTypes>) => {
    dispatch({
        type: UPDATE_SOCIAL_MEDIA_DESIGN,
        payload: value
    });
    dispatch(updateChangesMade({ changesMade: true }));
};

export const updateSocialMediaTheme = (value: string) => (dispatch: Dispatch<AlertActionTypes>) => {
    dispatch({
        type: UPDATE_SOCIAL_MEDIA_THEME,
        payload: value
    });
    dispatch(updateChangesMade({ changesMade: true }));
};

export const setGeneratedPreview = (value: any) => (dispatch: Dispatch<AlertActionTypes>) => {
    if (value.preview?.url) {
        dispatch({
            type: SET_ALERT_GENERATED_PREVIEW,
            payload: value.preview.url
        });
    }
    if (!value) {
        dispatch({
            type: SET_ALERT_GENERATED_PREVIEW,
            payload: value
        });
    }
};

export const updateGeneratePreviewProcessingStatus = (status: boolean) => (dispatch: Dispatch<AlertActionTypes>) => {
    dispatch({
        type: UPDATE_GENERATE_PREVIEW_PROCESSING_STATUS,
        payload: status
    });
};

export const onGetEventsMatchingTable =
    () =>
    async (dispatch: Dispatch<AlertActionTypes>): Promise<void> => {
        const res = await getEventsMatchingTable();
        dispatch({
            type: SET_EVENTS_MATHING_TABLE,
            payload: res.data.events_matching_table
        });
    };
