/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export interface IMPDFilterType {
    keys?: Array<string>;
    value?: string;
}

export const IMPDSortTypes = {
    asc: "asc" as const,
    desc: "desc" as const
};

export type IMPDSortTypes = typeof IMPDSortTypes[keyof typeof IMPDSortTypes];
