// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";

interface ISettingsTitleProps extends IProps {
    title?: string;
}

export const SettingsTitle: React.FunctionComponent<ISettingsTitleProps> = (props) => {
    const { className, title } = props;
    const classes = "settings-title";
    return <Text className={classNames(classes, className)}> {title} </Text>;
};

export default SettingsTitle;
