import { History } from "history";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { ToastTypes, ProgressToastCustomParams, IToastType } from "src/components";
import { GlobalActionTypes, SET_INITIAL_LOADING_PROCESSING_STATUS, SET_FIRST_ACCESSIBLE_ROUTE } from ".";
import { AppState, TopBarProps } from "../../store";
import {
    navigate,
    toggleMobileSideBar,
    toggleRightPanel,
    updateAlert,
    updateChangesMade,
    updateDeviceType,
    updateLoading,
    updateNavPath,
    updateToast,
    updateToolBar,
    updateWindowHeight,
    updateWindowWidth,
    updateProgressToast,
    updateGlobalSpinnerStatus
} from "./global-api";

export const onUpdateWindowWidth = (width: number) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateWindowWidth({ width }));
    };
};

export const onUpdateWindowHeight = (height: number) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateWindowHeight({ height }));
    };
};

export const onUpdateLoading = (loading: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateLoading({ loading }));
    };
};

export const onUpdateDeviceType = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateDeviceType());
    };
};

export const onUpdateToolBar = (topBarProps: TopBarProps) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateToolBar({ topBarProps }));
    };
};

export const onToggleMobileSideBar = () => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const state = getState();
        const mobileSideBarActive = state.global.mobileSideBarActive;
        dispatch(toggleMobileSideBar({ mobileSideBarActive }));
    };
};

export const onUpdateAlert = (alertOpen: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateAlert({ alertOpen }));
    };
};

export const onToggleRightPanel = () => (dispatch: any, getState: () => AppState) => {
    const global = getState().global;
    const { rightPanelExpanded } = global;
    dispatch(toggleRightPanel({ rightPanelExpanded }));
};

export const onCancelChanges = (pathname: string, history: History) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(navigate({ pathname, history }));
    };
};

export const onNavigate = (pathname: string, history: History, alertOpen?: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(navigate({ pathname, history, alertOpen }));
    };
};

export const onUpdateToast = (toast: IToastType | ToastTypes) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateToast({ toast }));
    };
};

export const onUpdateChangesMade = (changesMade: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateChangesMade({ changesMade }));
    };
};

export const onUpdateNavPath = (navPath: string, alertOpen?: boolean, func?: any) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateNavPath({ navPath, alertOpen, func }));
    };
};

export const getSelectedId = (routeProps: RouteComponentProps) => {
    const pathname = routeProps.history.location.pathname;
    let pathArr = pathname.split("/");
    const index = pathArr.findIndex((element: string) => element.includes("id-"));
    if (index !== -1) {
        pathArr = pathArr[index].split("-");
    }

    let id = "-1";
    if (pathArr[0] === "id") {
        id = pathArr.length > 1 ? pathArr.slice(1).join("-") : pathArr[1];
    }
    return id;
};

export const onUpdateProgressToast = (text: string, customParams: ProgressToastCustomParams) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateProgressToast({ text, customParams }));
    };
};

export const onUpdateGlobalSpinnerStatus = (status: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateGlobalSpinnerStatus(status));
    };
};

export const setInitialLoadingProcessingStatus = (value: boolean): GlobalActionTypes => ({
    type: SET_INITIAL_LOADING_PROCESSING_STATUS,
    value
});

export const setFirstAccessibleRoute = (value: string): GlobalActionTypes => ({
    type: SET_FIRST_ACCESSIBLE_ROUTE,
    value
});
