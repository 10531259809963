/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export interface ProgressToastReceivedParams {
    id: string;
    isFinished: boolean;
    percentage: number;
}

export interface ProgressToastCustomParams {
    id: string;
    getProgress: (id: string) => ProgressToastReceivedParams | null;
    removeUploadingOrder: (id: string) => void;
}

export interface IToastType {
    value: string;
    type?: ToastTypes;
    active?: boolean;
    customParams?: ProgressToastCustomParams;
}

export const ToastTypes = {
    saved: "saved" as const,
    error: "error" as const,
    progress: "progress" as const,
    notification: "notification" as const
};

export type ToastTypes = typeof ToastTypes[keyof typeof ToastTypes];
