// import { Text } from "@blueprintjs/core";
// import classNames from "classnames";
import styles from "./styles.module.scss";

export const TextClasses = {
    LIST_SUBTITLE: styles["list-subtitle"],
    SETTINGS_SHADOWED_CARD_TITLE: styles["settings-shadowed-card-title"],
    SETTINGS_SHADOWED_CARD_SUBTITLE: styles["settings-shadowed-card-subtitle"],
    BOTTOM_MARGIN_40: styles["bottom-margin-40"],
    MARGIN_VERTICAL_40: styles["margin-vertical-40"],
    MARGIN_BOTTOM_40: styles["margin-bottom-40"],
    MARGIN_TOP_40: styles["margin-top-40"]
};

// const classname = "styled-text";

// type StyledTextProps = {
//     className: string;
// };
// export const StyledText = ({ className }: StyledTextProps) => {
//     <Text className={classNames(classname, className)}></Text>;
// };
