import { createContext } from "react";
import { Route } from "react-router-dom";
import { useQueues, UseQueues } from "src/containers/conversations/components/chat/hooks/useQueues";
import { QueuesAdd } from "./queues-add/queues-add";
import { QueuesList } from "./queus-list/queues-list";

export const QueuesContext = createContext<UseQueues>({} as any);

export const QueuesSettings = () => {

    const queuesProps = useQueues({ myQueues: false });

    return (
        <>
            <QueuesContext.Provider value={queuesProps}>
                <Route path={"/account-settings/queues"} exact render={() => <QueuesList />} />
                <Route path={"/account-settings/queues/create"} render={() => <QueuesAdd />} />
                <Route path={"/account-settings/queues/:id/details"} render={() => <QueuesAdd />} />
            </QueuesContext.Provider>
        </>
    );
};
