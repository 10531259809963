import { AnalyticsData } from "src/store";

export const ANALYTICS_TOGGLE_RIGHTPANEL = "analytics-toggle-rightpanel";
export const ANALYTICS_UPDATE = "analytics-update";

interface AnalyticsToggleRightpanelAction {
    type: typeof ANALYTICS_TOGGLE_RIGHTPANEL;
    payload: boolean;
}

interface AnalyticsUpdate {
    type: typeof ANALYTICS_UPDATE;
    payload: AnalyticsData;
}

export type AnalyticsActionTypes = AnalyticsToggleRightpanelAction | AnalyticsUpdate;
