import { Text } from "@blueprintjs/core";
import styles from "./MapColumns.module.scss";
import classNames from "classnames";
import { MapColumnsTableItem } from "../map-columns-table-item/MapColumnsTableItem";

export const MapColumns = ({ formik }) => {
    const { mapped_fields } = formik.values;
    return (
        <div className={styles["root"]}>
            <div className={styles["table"]}>
                <div className={styles["table__header-wrapper"]}>
                    <div className={classNames(styles["table__item-grid"], styles["table__header"])}>
                        <Text className={styles["table__header__label"]}>FILE HEADER</Text>
                        <Text className={styles["table__header__label"]}>PREVIEW</Text>
                        <Text className={styles["table__header__label"]}>ATLAS ONE PROPERTY</Text>
                        <Text className={styles["table__header__label"]}>EXISTING RECORD</Text>
                    </div>
                </div>
                <div className={styles["table__items-wrapper"]}>
                    {mapped_fields.map((field) => {
                        return (
                            <MapColumnsTableItem
                                className={classNames(styles["table__item-grid"], styles["table__item"])}
                                item={field}
                                formik={formik}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
