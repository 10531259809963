import { Dispatch } from "react";
import { AppState, Notification, NotificationsSettings } from "src/store";
import { axiosInstance } from "../common";
import { onUpdateToast } from "../global/global";
import {
    setHasMoreNotifications,
    setNotifications,
    setNotificationsSettings,
    updateNotificationsCounter
} from "./notifications";
import { NotificationsActionTypes } from "./types";
import notificationSound from "src/common/sounds/notificationsSound.mp3";

const notificationAudio = new Audio(notificationSound);

export const getNotifications =
    (filter: "newest" | "oldest" | "unread", offset?: number) => async (dispatch, getState: () => AppState) => {
        const params: any = {};
        offset ? (params.offset = offset) : (params.offset = 0);
        !!filter && (params.filter = filter);
        const res = await axiosInstance.get(`/notifications`, { params });
        const notificationsList = getState().notifications.notificationsList;
        dispatch(setNotifications(offset ? [...notificationsList, ...res.data.notifications] : res.data.notifications));
        dispatch(setHasMoreNotifications(res.data.has_more));
    };

export const onDeleteNotifications =
    (notification_ids: Array<string>, isAllChecked?: boolean) => async (dispatch, getState: () => AppState) => {
        const { notificationsList } = getState().notifications;

        let payload;

        if (isAllChecked) {
            payload = { apply_to_all: true };
        } else {
            payload = {
                notification_ids
            };
        }

        const res = await axiosInstance.delete(`/notifications`, { params: payload });

        dispatch(updateNotificationsCounter(res.data.unread_count));

        let withoutRemovedIds: Array<Notification> = [...notificationsList];

        notification_ids.forEach((notificationId: string) => {
            withoutRemovedIds = withoutRemovedIds.filter((notification: Notification) => {
                return notification.id !== notificationId;
            });
        });

        dispatch(setHasMoreNotifications(false));
        dispatch(setNotifications(isAllChecked ? [] : withoutRemovedIds));
    };

export const onMarkNotificationsAsRead =
    (notification_ids: Array<string>, isAllChecked?: boolean) =>
    async (dispatch: Dispatch<NotificationsActionTypes>, getState: () => AppState) => {
        const { notificationsList } = getState().notifications;

        let payload;

        if (isAllChecked) {
            payload = { is_read: true, apply_to_all: true };
        } else {
            payload = {
                is_read: true,
                notification_ids
            };
        }

        const res = await axiosInstance.patch(`/notifications`, payload);

        dispatch(updateNotificationsCounter(res.data.unread_count));

        let updatedNotifications: Array<Notification> = notificationsList;

        isAllChecked
            ? (updatedNotifications = updatedNotifications.map((notification: Notification) => {
                  return {
                      ...notification,
                      is_read: true
                  };
              }))
            : notification_ids.forEach((notificationId: string) => {
                  updatedNotifications = notificationsList.map((notification: Notification) => {
                      if (notification.id === notificationId) {
                          return {
                              ...notification,
                              is_read: true
                          };
                      }
                      return notification;
                  });
              });

        dispatch(setNotifications(updatedNotifications));
    };

export const getNotificationsSettings = () => async (dispatch: Dispatch<NotificationsActionTypes>) => {
    const res = await axiosInstance.get(`/settings/notifications`);
    dispatch(setNotificationsSettings(res.data));
};

export const onGetPushNotification =
    (data) => (dispatch: Dispatch<NotificationsActionTypes>, getState: () => AppState) => {
        const isInAppNotificationsAllowed = getState().notifications.notificationsSettings?.types[`in-app`];
        const { selectedConversationId } = getState().conversations;
        const { play_sound } = getState().notifications.notificationsSettings;
        const { isAppTabInFocus } = getState().global;

        const notitifcationsList = getState().notifications.notificationsList;

        if (isInAppNotificationsAllowed) {
            if (
                (selectedConversationId !== data.notification.object.id && data.notification.type === "reply") ||
                data.notification.type !== "reply"
            ) {
                dispatch(onUpdateToast({ type: "notification", notification: data.notification }));
                play_sound &&
                    notificationAudio
                        .play()
                        .then(() => null)
                        .catch(() => null);
            }

            if (!isAppTabInFocus && data.notification.type === "reply") {
                play_sound &&
                    notificationAudio
                        .play()
                        .then(() => null)
                        .catch(() => null);
            }
        }

        dispatch(setNotifications([data.notification, ...notitifcationsList]));
        dispatch(updateNotificationsCounter(data.unread_notifications_count));
    };

export const onUpdateNotificationsSettings =
    (notificationsSettings: NotificationsSettings) => async (dispatch: Dispatch<NotificationsActionTypes>) => {
        const res = await axiosInstance.patch(`/settings/notifications`, notificationsSettings);
        dispatch(setNotificationsSettings(res.data));
    };

export const muteNotifications = () => async (dispatch: Dispatch<NotificationsActionTypes>) => {
    const res = await axiosInstance.post(`/settings/notifications/mute`);
    dispatch(setNotificationsSettings(res.data));
    const notificationsUnmuted = res.data.types["in-app"] && res.data.types.email;
    dispatch(
        onUpdateToast({
            value: !notificationsUnmuted
                ? "Notifications were successfully muted"
                : "Notifications were successfully unmuted",
            type: "saved"
        })
    );
};
