// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";
import { TextClasses } from "src/components/styled-text/styled-text";

export const ListHeader: React.FunctionComponent<IProps> = (props) => {
    const { className } = props;
    const classes = "audit-log-settings-list-header";
    return (
        <div className={classNames(classes, className)}>
            <Text className={classNames(classes + "-item", "activity", TextClasses.LIST_SUBTITLE)}>Activity</Text>
            <div className={classes + "-inner-container"}>
                <Text className={classNames(classes + "-item", "date", TextClasses.LIST_SUBTITLE)}>Date</Text>
                <Text className={classNames(classes + "-item", "ip", TextClasses.LIST_SUBTITLE)}>IP</Text>
                <Text className={classNames(classes + "-item", "browser", TextClasses.LIST_SUBTITLE)}>Browser</Text>
            </div>
        </div>
    );
};

export default ListHeader;
