import { cloneElement, FC, ReactElement, SyntheticEvent, useState, FunctionComponent, SVGProps } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { MPDIcon } from "src/mpd-library";
import { Collapse, Text } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { StyledSwitch } from "..";
import { TextClasses } from "../styled-text/styled-text";
import { ReactComponent as DownArrowIcon } from "../../mpd-library/icon/assets/down-arrow.svg"

const classname = "shadowed-setting-list-item";

type ShadowedListItemProps = {
    // -----main props-----
    title: string | ReactElement;
    subtitle: string | ReactElement;
    leftBigIconName: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    customRightElement: ReactElement;
    disabled?: boolean;
    showDownArrow?: boolean;
    className?: string;
    notScaleOnHover?: boolean;
    // -----collapse-------
    collapsiableBlock?: ReactElement;
    isCollapsed?: boolean | undefined;
    //---------------------
    // isCollapsedByDefault?: boolean;
    navToRoute?: string;
    customLeftIconComponent?: ReactElement;
    onClick?: (event: SyntheticEvent) => void;
    // -----------------------
    // ----switch props----
    isSwitchChecked?: boolean;
    onToggleSwitch?: (checked: boolean) => void;
    // ----------------------
};

export const ShadowedListItem: FC<ShadowedListItemProps> = ({
    title,
    customLeftIconComponent,
    subtitle,
    leftBigIconName,
    IconComponent,
    customRightElement,
    collapsiableBlock,
    isCollapsed,
    // isCollapsedByDefault,
    navToRoute,
    isSwitchChecked,
    disabled = false,
    showDownArrow = false,
    className,
    notScaleOnHover = false,
    onClick,
    onToggleSwitch
}: ShadowedListItemProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(isCollapsed);
    const history = useHistory();
    const [processing, setProcessing] = useState<boolean>(false);

    const onClickMainBlock = (event) => {
        if (onClick) {
            onClick(event);
            return;
        }
        if (onToggleSwitch) {
            onToggleSwitch(!isSwitchChecked);
            setIsOpen(!isSwitchChecked);
            return;
        }
        if (navToRoute) {
            history.push(navToRoute);
            return;
        }

        setIsOpen(!isOpen);
    };

    const onSwitchChange = async (event: SyntheticEvent) => {
        try {
            setProcessing(true);
            event.stopPropagation();
            if (onToggleSwitch) {
                await onToggleSwitch(!isSwitchChecked);
            }
            setIsOpen(!isSwitchChecked);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <div
            className={classNames(
                styles[classname],
                !onToggleSwitch && isCollapsed && styles["active"],
                className,
                notScaleOnHover && styles["not-scale-on-hover"]
                // processing && styles["processing"]
            )}
        >
            {disabled && <div className={styles[`${classname}-disable-mask`]} />}
            <div className={classNames(styles[`${classname}-top-wrapper`], "top-wrapper")} onClick={onClickMainBlock}>
                <div className={classNames(styles[`${classname}-left-wrapper`], `${classname}-left-wrapper`)}>
                    {leftBigIconName ||
                        (IconComponent && (
                            <MPDIcon
                                IconComponent={IconComponent}
                                name={leftBigIconName}
                                className={classNames(styles[`${classname}-left-icon`], "left-icon")}
                            />
                        ))}
                    {customLeftIconComponent}
                    <div
                        className={classNames(styles[`${classname}-title-subtitle-wrapper`], "title-subtitle-wrapper")}
                    >
                        <Text className={TextClasses.SETTINGS_SHADOWED_CARD_TITLE}>{title}</Text>
                        {!!subtitle && <Text className={TextClasses.SETTINGS_SHADOWED_CARD_SUBTITLE}>{subtitle}</Text>}
                    </div>
                    {customRightElement}
                </div>
                {!!onToggleSwitch && (
                    <div onClick={(e) => e.stopPropagation()} className={styles[`${classname}-toggle-wrapper`]}>
                        <StyledSwitch onChange={onSwitchChange} checked={isSwitchChecked} />
                    </div>
                )}
                {!onToggleSwitch && showDownArrow && (
                    <MPDIcon
                        IconComponent={DownArrowIcon}
                        className={classNames(
                            styles[`${classname}-right-arrow`],
                            (isOpen || isCollapsed) && styles["opened"],
                            "right-arrow"
                        )}
                    />
                )}
            </div>
            {collapsiableBlock && (
                <Collapse
                    isOpen={!disabled && (isOpen || isCollapsed)}
                    className={classNames(styles[`${classname}-collapse`], "collapse")}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        className={classNames(
                            styles[`${classname}-collapse-padding-wrapper`],
                            `${classname}-collapse-padding-wrapper`
                        )}
                    >
                        {cloneElement(collapsiableBlock, { onCollapseAfterApply: () => null })}
                    </div>
                </Collapse>
            )}
        </div>
    );
};
