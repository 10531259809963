// tslint:disable jsx-no-lambda

import classNames from "classnames";
import React from "react";

import { StyledButton, StyledToolBar, StyledButtonTypes, IStyledToolBarProps } from "src/components";

import { Classes } from "src/mpd-library/";

import "./styles.scss";
import { ReactComponent as LeftArrowBlackIcon } from "../../mpd-library/icon/assets/left-arrow-black.svg"

interface IBottomNavigationBarProps extends IStyledToolBarProps {
    loading: boolean;
    frontText: string;
    showBack?: boolean;
    showExtraButton?: boolean;
    extraButtonText?: string;
    onNextButtonPressed?(event: React.MouseEvent<HTMLElement>): void;
    onExtraButtonPressed?(event: React.MouseEvent<HTMLElement>): void;
    onBackButtonPressed?(event: React.MouseEvent<HTMLElement>): void;
}

export class BottomNavigationBar extends React.Component<IBottomNavigationBarProps, {}> {
    public render() {
        const { className, loading, ...props } = this.props;
        const classes = classNames("bottom-navigation-bar", className, loading && Classes.LOADING);
        return (
            <StyledToolBar
                className={classes}
                leftElement={this.renderLeftElement()}
                rightElement={this.renderRightElement()}
                {...props}
            />
        );
    }

    private renderLeftElement() {
        const { onBackButtonPressed, showBack } = this.props;
        return (
            <StyledButton
                text="back"
                LeftIconComponent={LeftArrowBlackIcon}
                onClick={onBackButtonPressed}
                className={classNames(!showBack && Classes.HIDE)}
            />
        );
    }

    private renderRightElement() {
        const { frontText, onNextButtonPressed, onExtraButtonPressed, showExtraButton, extraButtonText } = this.props;

        return (
            <React.Fragment>
                {showExtraButton && (
                    <StyledButton className="extra-button" text={extraButtonText} onClick={onExtraButtonPressed} />
                )}
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primary}
                    text={frontText}
                    onClick={onNextButtonPressed}
                />
            </React.Fragment>
        );
    }
}

export default BottomNavigationBar;
