import { FC } from "react";
import styles from "./ColorSelector.module.scss";

// const classname: string = "color-select";

type ColorSelectorProps = {
    label: string;
    colors: Array<string>;
    selectedColor: string;
    changeColor: (value: string) => void;
};

export const ColorSelector: FC<ColorSelectorProps> = ({
    colors,
    label = "ICON COLOR",
    selectedColor = "#FFFFFF",
    changeColor
}: ColorSelectorProps): JSX.Element => {
    const onColorChange = (value: string): void => {
        changeColor(value);
    };

    return (
        <div className={styles["color-selector"]}>
            <span className={styles["label"]}>{label}</span>
            <ul className={styles["color-list"]}>
                {colors.map((color: string): JSX.Element => {
                    return (
                        <li
                            onClick={() => onColorChange(color)}
                            key={color}
                            className={color === selectedColor && styles["active"]}
                        >
                            <span
                                className={color === "transparent" && styles["transparent-bg"]}
                                style={{
                                    ...(color !== "transparent" ? { backgroundColor: color } : {}),
                                    border:
                                        color === "#FFFFFF" || color === "white"
                                            ? "1px solid rgba(0, 0, 0, 0.25)"
                                            : "unset"
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
