// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

// import { GroupsListItem, IGroupsListItemProps } from 'src/components';
import { GroupsListItem, IGroupsListItemProps, StyledSelect, StyledSelectTypes } from "src/components";

import { Classes } from "src/mpd-library";

import "./styles.scss";

export interface ISettingsGroupsListItemProps extends IGroupsListItemProps {
    active?: boolean;
    selectValue?: string;
    loading?: boolean;
    options?: Array<string>;
    onSelectChanged?: (event: React.ChangeEvent) => void;
    onClick?: (event: React.SyntheticEvent) => void;
    ArrowIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const SettingsGroupsListItem: React.FunctionComponent<ISettingsGroupsListItemProps> = (props) => {
    const {
        className,
        options,
        active,
        selectValue,
        onSelectChanged,
        loading,
        onClick,
        ArrowIconComponent,
        ...groupslistItemProps
    } = props;
    const classes = "settings-groups-list-item";
    return (
        <div className={classNames(classes + "-container", active && Classes.ACTIVE, className)} onClick={onClick}>
            {loading ? (
                <div className={classNames(classes + "-left-loading-wrapper")}>
                    <div className={classNames(classes + "-left-loading", Classes.LIST_LOADER_ANIMATED)} />
                </div>
            ) : (
                <GroupsListItem {...groupslistItemProps} />
            )}
            {loading ? (
                <div className={classNames(classes + "-right-loading", Classes.LIST_LOADER_ANIMATED)} />
            ) : (
                <div className="settings-select-wrapper" onClick={(e) => e.stopPropagation()}>
                    <StyledSelect
                        className={classes + "-select"}
                        popOverClassName={classes + "-select-popover"}
                        type={StyledSelectTypes.noLine}
                        value={selectValue}
                        options={options}
                        onChange={onSelectChanged}
                        ArrowIconComponent={ArrowIconComponent}
                    />
                </div>
            )}
        </div>
    );
};

export default SettingsGroupsListItem;
