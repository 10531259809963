import { Position, Text } from "@blueprintjs/core";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { axiosInstance } from "src/actions";
import { updateToast } from "src/actions/global/global-api";
import {
    IToastType,
    StyledAlert,
    StyledAlertGlobalContent,
    StyledAlertTypes,
    StyledButton,
    StyledButtonTypes,
    ToastTypes
} from "src/components";
import { MPDSelect, MPDSelectItem } from "src/mpd-library";
import styles from "./styles.module.scss";
import { MPDSelectTargetBorderRoundingClasses } from "../../../../../mpd-library";
import { ReactComponent as DownArrowIcon } from "../../../../../mpd-library/icon/assets/down-arrow.svg";

// type RightApiKeyItemComponentProps = {
//     apiKey: string | null;
//     setApiKey: (value: string) => void;
//     setApiKeyState: (value: any) => void;
// };

const classname = "right-api-key-item";

export const RightApiKeyItemComponent = ({ apiKey, setApiKey, setApiKeyState, apiKeyState }) => {
    const [apiActionProcessingIndex, setApiActionProcessingIndex] = useState<number | null>(null);
    const [confirmProcessing, setConfirmProcessing] = useState<boolean>(false);
    const [enableProcessing, setEnableProcessing] = useState<boolean>(false);
    const [showApiKey, setShowApiKey] = useState<boolean>(false);
    const [alertProps, setAlertProps] = useState(null);
    const [alertContentProps, setAlertContentProps] = useState(null);
    const [isActionsDropdownOpened, setIsActionsDropdownOpened] = useState(false);

    const dispatch = useDispatch();

    const onEnableClick = async () => {
        try {
            setEnableProcessing(true);
            const res = await axiosInstance.post("/account/settings/api/api_key");
            setShowApiKey(true);
            setApiKey(res.data.api_key);
            setApiKeyState(res.data.api_key_state);
        } finally {
            setEnableProcessing(false);
        }
    };

    const onRotateClick = async () => {
        try {
            setApiActionProcessingIndex(0);
            const res = await axiosInstance.patch("/account/settings/api/api_key", {
                operation: "rotate"
            });
            setShowApiKey(true);
            setApiKey(res.data.api_key);
            setApiKeyState(res.data.api_key_state);
        } finally {
            setApiActionProcessingIndex(null);
            setIsActionsDropdownOpened(false);
        }
    };

    const onSuspendClick = async () => {
        try {
            setConfirmProcessing(true);
            const res = await axiosInstance.patch("/account/settings/api/api_key", {
                operation: "suspend"
            });
            setApiKeyState(res.data.api_key_state);
        } finally {
            setConfirmProcessing(false);
            setAlertProps(null);
        }
    };

    const onDeleteClick = async () => {
        try {
            setConfirmProcessing(true);
            const res = await axiosInstance.delete("/account/settings/api/api_key");
            setApiKeyState(res.data.api_key_state);
            setApiKey(null);
        } finally {
            setConfirmProcessing(false);
            setAlertProps(null);
        }
    };

    const actionsForApiKey = useMemo(() => {
        return [
            {
                value: "rotate",
                name: "Rotate Key",
                onClick: onRotateClick
            },
            ...(apiKeyState !== "suspended"
                ? [
                      {
                          value: "suspend",
                          name: "Suspend Key",
                          onClick: () => {
                              setIsActionsDropdownOpened(false);
                              setAlertProps({
                                  confirmButtonText: "Suspend",
                                  confirmButtonProps: {
                                      type: StyledButtonTypes.delete
                                  },
                                  onConfirm: onSuspendClick,
                                  onCancel: () => setAlertProps(null)
                              });
                              setAlertContentProps({
                                  description: "Are you sure you want to suspend your API key?",
                                  title: "Suspend Key"
                              });
                          }
                      }
                  ]
                : []),
            {
                value: "delete",
                name: "Delete Key",
                onClick: () => {
                    setIsActionsDropdownOpened(false);
                    setAlertProps({
                        confirmButtonText: "Delete",
                        confirmButtonProps: {
                            type: StyledButtonTypes.delete
                        },
                        onConfirm: onDeleteClick,
                        onCancel: () => setAlertProps(null)
                    });
                    setAlertContentProps({
                        description: "Are you sure you want to delete your API key?",
                        title: "Delete Key"
                    });
                }
            }
        ];
    }, [apiKeyState]);

    const onCopyClick = () => {
        navigator.clipboard.writeText(apiKey);
        const toast: IToastType = {
            value: "API key succesfully copied",
            type: ToastTypes.saved
        };
        dispatch(updateToast({ toast }));
    };

    return (
        <div className={styles[classname]}>
            <StyledAlert
                className={classname + "-styled-alert"}
                isOpen={alertProps}
                type={StyledAlertTypes.warning}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={true}
                cancelButtonText={"Cancel"}
                {...alertProps}
                confirmButtonProps={{
                    ...alertProps?.confirmButtonProps,
                    processing: confirmProcessing
                }}
                globalContentProps={{
                    ...alertContentProps,
                    onCancel: () => setAlertProps(null)
                }}
            >
                {/* <StyledAlertGlobalContent onCancel={() => setAlertProps(null)} {...alertContentProps} /> */}
            </StyledAlert>
            {apiKey === null && (
                <>
                    <Text className={styles[`${classname}-value`]}>
                        {Array.from(Array(36)).map(() => {
                            return (
                                <span
                                    className={classNames(
                                        styles[`${classname}-black-dot`],
                                        apiKeyState === "disabled" && styles[`${classname}-black-dot-inactive`]
                                    )}
                                />
                            );
                        })}
                    </Text>

                    <StyledButton
                        text={"Enable"}
                        type={StyledButtonTypes.primary}
                        onClick={onEnableClick}
                        processing={enableProcessing}
                        className={styles[`${classname}-enable-button`]}
                    />
                </>
            )}
            {!!apiKey && (
                <>
                    <div className={styles[`${classname}-output`]}>
                        <Text className={styles[`${classname}-value`]}>
                            {showApiKey
                                ? apiKey
                                : Array.from(Array(36)).map(() => {
                                      return (
                                          <span
                                              className={classNames(
                                                  styles[`${classname}-black-dot`],
                                                  apiKeyState === "disabled" &&
                                                      styles[`${classname}-black-dot-inactive`]
                                              )}
                                          />
                                      );
                                  })}
                        </Text>
                        {!!showApiKey && (
                            <div className={styles[`${classname}-buttons-wrapper`]}>
                                {/* <StyledButton
                                type={StyledButtonTypes.primarySimple}
                                text={showApiKey ? "Hide" : "Show"}
                                onClick={onShowClick}
                            /> */}

                                <StyledButton
                                    type={StyledButtonTypes.primarySimple}
                                    text={"Copy"}
                                    onClick={onCopyClick}
                                />
                            </div>
                        )}
                    </div>
                    <MPDSelect
                        className={classNames(
                            styles[`${classname}-actions-select-target`],
                            MPDSelectTargetBorderRoundingClasses.rounded
                        )}
                        value={"Actions"}
                        isOpenFromParent={isActionsDropdownOpened}
                        onToggleOptions={setIsActionsDropdownOpened}
                        position={Position.BOTTOM_RIGHT}
                        menuClassName={styles[`${classname}-popover`]}
                        customOptions={
                            <>
                                {actionsForApiKey.map((action, index) => {
                                    return (
                                        <MPDSelectItem
                                            onClick={action.onClick}
                                            processing={index === apiActionProcessingIndex}
                                        >
                                            {action.name}
                                        </MPDSelectItem>
                                    );
                                })}
                            </>
                        }
                        ArrowIconComponent={DownArrowIcon}
                    />
                </>
            )}
        </div>
    );
};
