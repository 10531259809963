// tslint:disable jsx-no-lambda
import classNames from "classnames";

import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Text } from "@blueprintjs/core";
import { matchPath } from "react-router-dom";

import { globalActions } from "src/actions";

import { IDetailView } from "../../../../../";

import {
    BreadCrumbs,
    IBreadCrumbObj,
    ToastTypes,
    IToastType,
    StyledTabs,
    StyledTabsTypes,
    StyledRadioGroup,
    StyledRadioGroupTypes
} from "src/components";
import { ReactComponent as AlarmBellRingIcon } from "../../../../../../mpd-library/icon/assets/alarm-bell-ring.svg";
import { ReactComponent as SettingToggleHorizontalIcon } from "../../../../../../mpd-library/icon/assets/settings-toggle-horizontal.svg";
import { ReactComponent as InboxBigIconBoldIcon } from "../../../../../../mpd-library/icon/assets/inbox-big-icon-bold.svg";
import { ReactComponent as DiagramSplitHorizontalIcon } from "../../../../../../mpd-library/icon/assets/diagram-split-horizontal.svg";

import { MPDScroll } from "src/mpd-library";
import { useFormik } from "formik";
import { FormWasChanged } from "../../../../../../components/form-was-changed/FormWasChanged";
import "./styles.scss";
import { AppState, AccountSettingsGeneralChannels, Channels, ChannelsAvailable } from "src/store";
import { History } from "history";
import { RouteComponentProps, useHistory } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { TextClasses } from "src/components/styled-text/styled-text";
import { ShadowedListItem } from "src/components/shadowed-settings-list-item/shadowed-settings-list-item";
import {
    disconnectChannel,
    onGetChannelsArray,
    onSaveChannelsChanges
} from "src/actions/account-settings/account-settings-api";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { ReauthCahnnelMessage } from "src/components/reauth-channel-message/reauth-channel-message";
import { ConversationsRoutingSettings } from "src/components/conversations-routing-settings/conversations-routing-settings";
import { StyledAlert, StyledAlertTypes, StyledButton, StyledButtonTypes } from "../../../../../../components";
import { UseQueues, useQueues } from "src/containers/conversations/components/chat/hooks/useQueues";

interface ISocialSettingsEditProps extends IDetailView {
    accountType: string;
    currentSocialSetting: any;
    previousRoute?: IBreadCrumbObj;
    routeProps: RouteComponentProps;
    channels: AccountSettingsGeneralChannels;
    onCancelChanges: (pathname: string, history: History) => void;
    channelsArray: Channels;
    channelsAvailable: ChannelsAvailable;
    onSaveChannelsChanges: (
        channelId: string,
        channels: Channels,
        pathname: string,
        history: History,
        index: number,
        accountType: string
    ) => void;
    onDisconnectChannel: (channelId: string, accountType: string) => void;
    onUpdateToast: (toast: IToastType | ToastTypes) => void;
    getChannelsArray: () => void;
    onInstallClick: (e: SyntheticEvent, key: string) => void;
}

const tabsSequence = ["outbound", "inbound"];

const SocialSettingsEdit = (props: ISocialSettingsEditProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { default_queue: defaultQueue } = useSelector((state: AppState) => state.accountSettings.organization);
    const [currentSocialSetting, setCurrentSocialSetting] = useState(null);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [selectedTabId, setSelectedTabId] = useState(0);
    const [reauthProcessing, setReauthProcessing] = useState(false);
    const { accountType, getChannelsArray } = props;
    const { className, channelsAvailable, loading, channelsArray, routeProps, onDisconnectChannel, onUpdateToast } =
        props;

    const initialValues = useMemo(() => {
        if (currentSocialSetting) {
            return {
                alerts_enabled: currentSocialSetting?.alerts_enabled,
                conversations_enabled: currentSocialSetting?.conversations_enabled,
                alerts_pre_selected: currentSocialSetting?.alerts_pre_selected,
                queue: currentSocialSetting?.conversations_settings?.queue || defaultQueue,
                admin: currentSocialSetting?.conversations_settings?.admin,
                tags: currentSocialSetting?.conversations_settings?.tags
            };
        } else return undefined;
    }, [currentSocialSetting]);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const match = matchPath(location.pathname, {
                    path: "/account-settings/channels/:accountType/edit/:id"
                });
                const channelId = match?.params?.id;
                const pathname = `/account-settings/channels/${accountType}/edit/${channelId}`;
                const res = await dispatch(
                    onSaveChannelsChanges(
                        {
                            id: channelId,
                            alerts_enabled: values?.alerts_enabled,
                            conversations_enabled: values?.conversations_enabled,
                            alerts_pre_selected: values?.alerts_pre_selected,
                            conversations_settings: {
                                queue_id: values?.queue?.id,
                                admin_id: values?.admin?.id,
                                tag_ids: values?.tags?.map((tag) => tag.id)
                            }
                        },
                        pathname,
                        history,
                        0,
                        accountType
                    )
                );

                setCurrentSocialSetting(res.data.channel);
                setSubmitting(false);
            } catch (err) {
                console.log("FFF formik >>> err", err);
            }
        }
    });
    const { handleSubmit, touched, errors, setFieldValue } = formik;

    const getSelectedTab = (channelsAvailable) => {
        const { accountType } = props;
        if (channelsAvailable) {
            return tabsSequence
                .map((tabKey) => {
                    if (channelsAvailable?.[accountType]?.[tabKey]) {
                        return tabKey;
                    }
                    return null;
                })
                .findIndex((tabKey) => tabKey !== null);
        }
        return selectedTabId;
    };

    const fetchChannelsArray = async () => {
        if (!currentSocialSetting) {
            try {
                setInitialLoading(true);
                const match = matchPath(location.pathname, {
                    path: "/account-settings/channels/:accountType/edit/:id"
                });
                const channelId = match?.params?.id;

                const res = await getChannelsArray();
                const { channels: channelsArray } = res;
                const current = channelsArray[accountType].find((channel) => channel.id === channelId);
                setCurrentSocialSetting(current);
                setSelectedTabId(getSelectedTab(res.channels_available));
            } finally {
                setInitialLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchChannelsArray();
    }, [accountType, currentSocialSetting, getChannelsArray, location.pathname]);

    const onSelectTab = (tabID) => {
        setSelectedTabId(tabID);
    };

    const onReauthClick = async (e) => {
        try {
            const { accountType } = props;
            setReauthProcessing(true);
            await props.onInstallClick(e, accountType);
        } catch (err) {
            setReauthProcessing(false);
        }
    };

    const onChangeEnableForAlerts = (checked) => {
        setFieldValue("alerts_enabled", !formik.values.alerts_enabled);
        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    };

    const onChangeEnableForConversations = (checked) => {
        setFieldValue("conversations_enabled", !formik.values?.conversations_enabled);
        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    };
    const onChangePreselectOption = (event: any) => {
        setFieldValue("alerts_pre_selected", event.target.value == 1);

        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    };

    const setSelectedSettings = (value, key) => {
        formik.setFieldValue(key, value);

        if (props.onUpdateChangesMade) {
            props.onUpdateChangesMade(true);
        }
    };

    const availableTabs = tabsSequence.map((tabKey) => {
        if (channelsAvailable?.[accountType]?.[tabKey]) {
            return tabKey;
        }
        return null;
    });

    const disconnectChannel = async () => {
        try {
            await onDisconnectChannel(currentSocialSetting?.id, accountType);
            const toast: IToastType = {
                value: "Success! Channel disconnected",
                type: ToastTypes.saved
            };
            onUpdateToast(toast);
            routeProps.history.replace(
                channelsArray?.[accountType]?.length > 1
                    ? `/account-settings/channels/${accountType}`
                    : "/account-settings/channels"
            );
        } catch (err) {
            const toast: IToastType = {
                value: "Something get wrong. Try again a bit later",
                type: ToastTypes.error
            };
            onUpdateToast(toast);
        }
    };

    const onUpdateToolBar = (title: string) => {
        const { accountType, previousRoute, routeProps } = props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={
                        previousRoute
                            ? [
                                  previousRoute,
                                  {
                                      text: accountType.charAt(0).toUpperCase() + accountType.slice(1),
                                      pathname: `/account-settings/channels/${accountType}`
                                  },
                                  { text: title }
                              ]
                            : [
                                  {
                                      text: accountType.charAt(0).toUpperCase() + accountType.slice(1),
                                      pathname: `/account-settings/channels/${accountType}`
                                  },
                                  { text: title }
                              ]
                    }
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: props.onToggleRightPanel,
                        onClose: props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledAlert
                    Target={(props) => (
                        <StyledButton {...props} type={StyledButtonTypes.delete} minimal={true} text={"Delete"} />
                    )}
                    type={StyledAlertTypes.warning}
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={false}
                    confirmButtonProps={{
                        type: StyledButtonTypes.delete
                    }}
                    confirmButtonText={"Delete"}
                    cancelButtonText={"Cancel"}
                    onConfirm={disconnectChannel}
                    fullScreen
                    globalContentProps={{
                        title: "Delete Channel",
                        description: `Are you sure you want to delete your channel "${title}"?`
                    }}
                />
            )
        };
        if (props.onUpdateToolBar) {
            props.onUpdateToolBar(topBarProps);
        }
    };

    useEffect(() => {
        const { currentSocialSetting } = props;
        if (currentSocialSetting) {
            onUpdateToolBar(currentSocialSetting.name || currentSocialSetting.page_name);
        }
    }, [currentSocialSetting]);

    const routingSettings = useMemo(
        () => ({ tags: formik.values?.tags, admin: formik.values?.admin, queue: formik.values?.queue }),
        [formik.values]
    );

    if (loading || initialLoading || !currentSocialSetting) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className="main-container">
            <form onSubmit={handleSubmit}>
                <div className={classNames("social-settings-edit", className)}>
                    {currentSocialSetting?.status === "offline" && (
                        <div className={"reauth-warning-wrapper"}>
                            <ReauthCahnnelMessage channel={currentSocialSetting} />
                        </div>
                    )}
                    <div className="social-settings-edit-header">
                        <div className="social-settings-edit-header-left">
                            <img src={currentSocialSetting.profile_pic} className="profile-pic" alt="" />
                            <div className="names-wrapper">
                                <span className="name">
                                    {currentSocialSetting.name || currentSocialSetting.page_name}
                                </span>
                                <span className="screen-name">
                                    {currentSocialSetting.screen_name && `@${currentSocialSetting.screen_name}`}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="social-settings-edit-settings">
                        <StyledTabs
                            type={StyledTabsTypes.freeBlue}
                            className={"tabs"}
                            tabs={availableTabs}
                            notShowTabs={availableTabs.length < 2}
                            tabsProps={{
                                id: "social-settings-tabs",
                                onChange: onSelectTab,
                                selectedTabId: selectedTabId
                            }}
                            panel={
                                <MPDScroll yDirection={true} className={"panel-wrapper"}>
                                    {selectedTabId === 0 && (
                                        <>
                                            <Text
                                                className={classNames(
                                                    TextClasses.LIST_SUBTITLE,
                                                    TextClasses.MARGIN_BOTTOM_40
                                                )}
                                            >
                                                ENABLE FOR ALERTS
                                            </Text>
                                            <ShadowedListItem
                                                title={"Alerts"}
                                                subtitle={`Automatically publish Atlas One Alerts to your ${currentSocialSetting.type} acccount`}
                                                IconComponent={AlarmBellRingIcon}
                                                onToggleSwitch={onChangeEnableForAlerts}
                                                isSwitchChecked={formik.values?.alerts_enabled}
                                                error={touched.alerts_enabled && errors.alerts_enabled}
                                                // {...getFieldProps("alerts_enabled")}
                                            />
                                            <Text
                                                className={classNames(
                                                    TextClasses.LIST_SUBTITLE,
                                                    TextClasses.MARGIN_VERTICAL_40
                                                )}
                                            >
                                                ALERTS SETTINGS
                                            </Text>
                                            <ShadowedListItem
                                                showDownArrow
                                                title={"Preselect for publishing"}
                                                subtitle={
                                                    "Automatically preselect this account when sending an alert so users don’t have to manually enable it each time"
                                                }
                                                IconComponent={SettingToggleHorizontalIcon}
                                                collapsiableBlock={
                                                    // <div style={{ width: '100%', height: "100px", background: 'pink'}} />
                                                    <StyledRadioGroup
                                                        size={"large"}
                                                        className={"restyled-radio-group"}
                                                        onChange={onChangePreselectOption}
                                                        type={StyledRadioGroupTypes.primary}
                                                        selectedValue={formik.values?.alerts_pre_selected ? 1 : 0}
                                                        list={[
                                                            { label: "Do not preselect", value: 0 },
                                                            {
                                                                label: "Preselect account for quick publishing",
                                                                value: 1
                                                            }
                                                        ]}
                                                        error={
                                                            errors.alerts_pre_selected && touched.alerts_pre_selected
                                                        }
                                                    />
                                                }
                                            />
                                        </>
                                    )}
                                    {selectedTabId === 1 && (
                                        <>
                                            <Text
                                                className={classNames(
                                                    TextClasses.LIST_SUBTITLE,
                                                    TextClasses.MARGIN_BOTTOM_40
                                                )}
                                            >
                                                ENABLE FOR CONVERSATIONS
                                            </Text>
                                            <ShadowedListItem
                                                title={"Conversations"}
                                                subtitle={`Automatically create a new conversation when a user sends a ${currentSocialSetting.type} message`}
                                                IconComponent={InboxBigIconBoldIcon}
                                                onToggleSwitch={onChangeEnableForConversations}
                                                isSwitchChecked={formik.values?.conversations_enabled}
                                                error={touched.conversations_enabled && errors.conversations_enabled}
                                            />
                                            <Text
                                                className={classNames(
                                                    TextClasses.LIST_SUBTITLE,
                                                    TextClasses.MARGIN_VERTICAL_40
                                                )}
                                            >
                                                CONVERSATIONS SETTINGS
                                            </Text>
                                            <ShadowedListItem
                                                showDownArrow
                                                disabled={!formik?.values?.conversations_enabled}
                                                title={"Routing"}
                                                subtitle={`Automatically route direct messages from ${currentSocialSetting.type} to a specfic queue or agent`}
                                                IconComponent={DiagramSplitHorizontalIcon}
                                                collapsiableBlock={
                                                    <ConversationsRoutingSettings
                                                        setSelectedSettings={setSelectedSettings}
                                                        routingSettings={routingSettings}
                                                    />
                                                }
                                            />
                                        </>
                                    )}
                                </MPDScroll>
                            }
                        />
                    </div>
                </div>
            </form>
            <FormWasChanged formik={formik} />
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const { channels, channelsArray, channelsAvailable } = accountSettings.general;

    return {
        channels,
        channelsArray,
        channelsAvailable
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCancelChanges: globalActions.onCancelChanges,
            onDisconnectChannel: disconnectChannel,
            onUpdateToast: globalActions.onUpdateToast,
            getChannelsArray: onGetChannelsArray
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SocialSettingsEdit);
