import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";
// import * as myClasses from "../";

import { ISwitchProps, Spinner, Switch } from "@blueprintjs/core";
import "./styles.scss";

interface IStyledSwitchProps extends ISwitchProps {
    types?: string;
}

export const StyledSwitch: React.FunctionComponent<IStyledSwitchProps> = (props) => {
    const { className, type, ...switchProps } = props;
    const [processing, setProcessing] = useState<boolean>(false);
    const classes = classNames("styled-switch", type, className);

    const onChangeHandler = async (e: SyntheticEvent) => {
        try {
            setProcessing(true);
            await switchProps.onChange(e);
        } finally {
            setProcessing(false);
        }
    };

    if (!!processing) {
        return <Spinner size={20} />;
    }

    return <Switch className={classes} {...switchProps} onChange={onChangeHandler} />;
};
