import {
    CardActionTypes,
    UPDATE_EDIT_CARD,
    UPDATE_CARD_LIST,
    UPDATE_SELECTED_CARD,
    UPDATE_SELECTED_CARD_CURRENT_ACTION,
    UPDATE_SELECTED_CARD_CURRENT_PANEL,
    UPDATE_SELECTED_CARD_PANELS,
    UPDATE_SELECTED_CARD_CURRENT_CARD,
    UPDATE_CARDS,
    SELECTED_CARD_CURRENT_ACTION_VALUES_CHANGE,
    SELECT_ACTION_TYPE,
    ADD_NEW_TEMP_PANEL,
    RESET_CURRENT_ACTION
} from "../actions/studio/cards/card-types";
import { CardState, defaultCardState } from "../store";

export const cardReducer = (state: CardState = defaultCardState, action: CardActionTypes): CardState => {
    switch (action.type) {
        case UPDATE_CARDS:
            return {
                ...state,
                cards: action.payload.cards,
                selectedCard: action.payload.selectedCard
            };
        case UPDATE_CARD_LIST:
            const updatedState = {
                ...state.cards,
                [action.payload.listType]: {
                    ...state.cards[action.payload.listType],
                    ...action.payload.listData
                }
            };
            return {
                ...state,
                // cards: {
                //     ...state.cards,
                //     [action.payload.listType]: {
                //         ...state.cards[action.payload.listType],
                //         ...action.payload.listData
                //     }
                // }
                cards: { ...updatedState }
            };
        case UPDATE_EDIT_CARD:
            return {
                ...state,
                addCard: action.payload
            };
        case UPDATE_SELECTED_CARD:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    ...action.payload
                }
            };
        case UPDATE_SELECTED_CARD_CURRENT_CARD:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    currentCard: {
                        ...state.selectedCard.currentCard,
                        ...action.payload
                    }
                }
            };
        case UPDATE_SELECTED_CARD_PANELS:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    panels: action.payload
                }
            };
        case UPDATE_SELECTED_CARD_CURRENT_PANEL:
            const editedAction = action.payload.action;
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    currentPanel: action.payload,
                    currentAction: editedAction ? { ...editedAction } : { ...state.selectedCard.currentAction }
                }
            };
        case UPDATE_SELECTED_CARD_CURRENT_ACTION:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    currentAction: action.payload
                }
            };
        case SELECTED_CARD_CURRENT_ACTION_VALUES_CHANGE:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    currentAction: {
                        ...state.selectedCard.currentAction,
                        [action.fieldName]: action.payload
                    }
                }
            };
        case SELECT_ACTION_TYPE:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    currentAction: {
                        ...state.selectedCard.currentAction,
                        type: action.payload
                    }
                }
            };
        case ADD_NEW_TEMP_PANEL:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    panels: action.payload
                }
            };
        case RESET_CURRENT_ACTION:
            return {
                ...state,
                selectedCard: {
                    ...state.selectedCard,
                    currentAction: {
                        ...defaultCardState.selectedCard.currentAction
                    }
                }
            };
        default:
            return state;
    }
};
