import { Collapse, Text } from "@blueprintjs/core";
import classNames from "classnames";
import moment from "moment";
import { useEffect } from "react";
import {
    StyledRadioGroup,
    StyledRadioGroupTypes,
    StyledSwitch,
    StyledTextArea,
    StyledTextAreaTypes
} from "src/components";
import { MPDSelect } from "src/mpd-library";
import styles from "./styles.module.scss";

const classname = "business-hours";

const weekArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const createHalfHourIntervals = (format: string) => {
    const startDate = moment().startOf("day");
    const dates: Array<string> = [];
    const hourDivider = 2;
    let t = hourDivider * 24;
    while (t--) {
        dates.push(startDate.format(format));
        startDate.add(60 / hourDivider, "minute");
    }
    return dates;
};

const dates = createHalfHourIntervals("h:mm A");

export const BusinessHours = ({
    customAwayMessage,
    businesHoursState,
    selectedRadioValue,
    getFieldProps,
    setFormikState,
    businessHoursFormik,
    onRadioClick,
    onSwitchDay,
    onSelectTime,
    errors,
    touched
}) => {
    useEffect(() => {
        if (businessHoursFormik) {
            setFormikState(businessHoursFormik);
        }
    }, [businessHoursFormik]);

    return (
        <form>
            <div
                className={classNames(
                    styles[classname],
                    selectedRadioValue !== "custom" && styles[`custom-collapsed`],
                    "profile-automations-item-wrapper"
                )}
            >
                <StyledRadioGroup
                    size={"large"}
                    className={"restyled-radio-group"}
                    onChange={onRadioClick}
                    type={StyledRadioGroupTypes.primary}
                    selectedValue={selectedRadioValue}
                    list={[
                        { label: "Open 24/7", value: "1" },
                        { label: "Custom business hours", value: "custom" }
                    ]}
                />
                <Collapse
                    isOpen={selectedRadioValue === "custom"}
                    className={classNames(
                        styles[`${classname}-collapse`],
                        selectedRadioValue !== "custom" && styles["collapse-without-padding"]
                    )}
                >
                    <>
                        <Text className={styles[`${classname}-subtitle`]}>CUSTOM BUSINESS HOURS</Text>
                        {weekArray.map((weekDay, index: number) => {
                            return (
                                <div className={styles[`${classname}-day-wrapper`]}>
                                    <Text className={styles[`${classname}-day-label`]}>{weekDay}</Text>
                                    <StyledSwitch
                                        checked={businesHoursState[index].is_opened}
                                        onChange={(event) => onSwitchDay(event, index)}
                                        alignIndicator={"left"}
                                        labelElement={
                                            <Text
                                                className={classNames(
                                                    styles[`${classname}-switch-label`],
                                                    !businesHoursState[index].is_opened &&
                                                        styles[`${classname}-switch-label-incative`]
                                                )}
                                            >
                                                {!businesHoursState[index].is_opened ? "Closed" : "Open"}
                                            </Text>
                                        }
                                    />
                                    <div className={styles[`${classname}-datepicker-wrappers`]}>
                                        {businesHoursState[index].is_opened && (
                                            <>
                                                <MPDSelect
                                                    className={styles[`${classname}-content-time`]}
                                                    options={dates}
                                                    // type={StyledSelectTypes.primary}
                                                    cannotChange={true}
                                                    value={businesHoursState[index].opened_from}
                                                    onChange={(event, time) => {
                                                        onSelectTime(index, time, "opened_from");
                                                    }}
                                                    position={"bottom"}
                                                    // error={timeError}
                                                    usePortal={true}
                                                />
                                                <Text className={styles["to"]}>to</Text>
                                                <MPDSelect
                                                    className={styles[`${classname}-content-time`]}
                                                    options={dates}
                                                    cannotChange={true}
                                                    value={businesHoursState[index].opened_till}
                                                    onChange={(event, time) => onSelectTime(index, time, "opened_till")}
                                                    position={"bottom"}
                                                    usePortal={true}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        <StyledTextArea
                            autoCapitalize="none"
                            autoCorrect="none"
                            type={StyledTextAreaTypes.primaryStatic}
                            placeholder="CUSTOM AWAY MESSAGE"
                            tabIndex={1}
                            rows={5}
                            isMaxLengthRequire={false}
                            value={customAwayMessage}
                            showErrorText={true}
                            className={"profile-automations-section-textarea"}
                            error={touched.customAwayMessage && errors.customAwayMessage}
                            {...getFieldProps("customAwayMessage")}
                        />
                    </>
                </Collapse>
            </div>
        </form>
    );
};
