import { MapState, defaultMapState } from "../store/map";
import { MapActionTypes, CHANGE_MAP_LAYERS_LIST_VISIBILITY } from "src/actions/map";

export const mapReducer = (state: MapState = defaultMapState, action: MapActionTypes): MapState => {
    switch (action.type) {
        case CHANGE_MAP_LAYERS_LIST_VISIBILITY:
            return {
                ...state,
                mapLayersListActive: action.payload,
            };
        default:
            return state;
    }
};
