import { ReactComponent as ContactsFilterPersonIcon } from "../../mpd-library/icon/assets/ContactsFilterPerson.svg";
import { ReactComponent as ContactsFilterEmailIcon } from "../../mpd-library/icon/assets/ContactsFilterEmail.svg";
import { ReactComponent as ContactsFilterPhoneIcon } from "../../mpd-library/icon/assets/ContactsFilterPhone.svg";
import { ReactComponent as TagIcon } from "../../mpd-library/icon/assets/tag.svg";
import { ReactComponent as CalendarFigmaIcon } from "../../mpd-library/icon/assets/calendar-figma.svg";
import { ReactComponent as ContactsFilterMarkerIcon } from "../../mpd-library/icon/assets/ContactsFilterMarker.svg";
import { ReactComponent as DrawerDownloadIcon } from "src/mpd-library/icon/assets/drawer-download.svg";
import { ReactComponent as TwoPersonsCheckmarkIcon } from "src/mpd-library/icon/assets/TwoPersonsCheckmarkIcon.svg";

export const LOGICAL_OPERATIONS_ITEMS = [
    { value: "and", label: "and" },
    { value: "or", label: "or" }
];

export const SORT_BY_VALUE = [
    { value: "first_name", label: "First Name" },
    { value: "last_name", label: "Last Name" },
    { value: "last_contacted_at", label: "Last Contacted" },
    { value: "updated_at", label: "Last Updated" },
    { value: "inserted_at", label: "Last Created" },
    { value: "channel", label: "Channel" }
];

export const ERROR_TOOTLTIP_TEXT = "One of your filter is missing a value. Finfish editing it before adding another";

export const STRING_COMPARISON_LABEL_MATCH = {
    eq: "is",
    neq: "is not",
    starts_with: "starts with",
    ends_with: "ends with",
    contains: "contains",
    not_contains: "not contains",
    is_null: "is unknown",
    is_not_null: "has any value"
};

export const DATE_COMPARISON_LABEL_MATCH = {
    gt: "more than",
    eq: "exactly",
    lt: "less than",
    after: "after",
    before: "before",
    on: "on",
    is_null: "is unknown",
    is_not_null: "has any value"
};

export const TAGS_COMPARISON_LABEL_MATCH = {
    eq: "is",
    neq: "is not"
};

export const PLACE_COMPARISON_LABEL_MATCH = {
    inside: "inside",
    outside: "outside",
    is_null: "is unknown",
    is_not_null: "has any value"
};

export const COMPARISON_LABEL_MATCH = {
    personal_data: STRING_COMPARISON_LABEL_MATCH,
    email: STRING_COMPARISON_LABEL_MATCH,
    phone: STRING_COMPARISON_LABEL_MATCH,
    date: DATE_COMPARISON_LABEL_MATCH,
    tags: TAGS_COMPARISON_LABEL_MATCH,
    lists: TAGS_COMPARISON_LABEL_MATCH,
    import_id: TAGS_COMPARISON_LABEL_MATCH,
    place: PLACE_COMPARISON_LABEL_MATCH
};

export const ICONS_TYPE_MATCH = {
    personal_data: ContactsFilterPersonIcon,
    email: ContactsFilterEmailIcon,
    phone: ContactsFilterPhoneIcon,
    tags: TagIcon,
    date: CalendarFigmaIcon,
    location: ContactsFilterMarkerIcon,
    place: ContactsFilterMarkerIcon,
    lists: TwoPersonsCheckmarkIcon,
    import_id: DrawerDownloadIcon,

    name: ContactsFilterPersonIcon,
    string: ContactsFilterPersonIcon,
    activity: ContactsFilterPersonIcon,
    channels: ContactsFilterPersonIcon
};
