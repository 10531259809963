export const ICON_PATHS = {
    AlertBellNotification: "AlertBellNotification",
    LocationHome: "LocationHome",
    LocationWork: "LocationWork",
    LocationGeneral: "LocationGeneral",
    EditLocationPin: "EditLocationPin",
    EditChannelsCountrySelectDownArrow: "EditChannelsCountrySelectDownArrow",
    InputCopyActionIcon: "InputCopyActionIcon",
    InputEditActionIcon: "InputEditActionIcon",
    InputRemoveActionIcon: "InputRemoveActionIcon",
    FileXLSX: "FileXLSX",
    FileCSV: "FileCSV",
    BigBlueDrawer: "BigBlueDrawer",
    rotationArrow: "rotationArrow",
    SelectedPersons: "SelectedPersons",
    NoSearchResultsFor: "NoSearchResultsFor",
    CloseIcon: "close-icon",
    ContactsFilterPerson: "ContactsFilterPerson",
    ContactsFilterMarker: "ContactsFilterMarker",
    ContactsFilterEmail: "ContactsFilterEmail",
    ContactsFilterPhone: "ContactsFilterPhone",
    ButtonChevronRight: "ButtonChevronRight",
    ButtonChevronLeft: "ButtonChevronLeft",
    SidebarContactsIcon: "SidebarContactsIcon",
    ContactsEmptyTwoPersons: "ContactsEmptyTwoPersons",
    FilterDropdownPlus: "FilterDropdownPlus",
    ChannelsAtlasOne: "ChannelsAtlasOne",
    ChannelsFacebook: "ChannelsFacebook",
    ChannelsInstagram: "ChannelsInstagram",
    ChannelsMail: "ChannelsMail",
    ChannelsPhone: "ChannelsPhone",
    ChannelsSms: "ChannelsSms",
    ChannelsTwitter: "ChannelsTwitter",
    removeCircle: "remove-circle",
    qrSquares: "qr-squares",
    qrRounded: "qr-rounded-squares",
    qrExtraRounded: "qr-extra-rounded-squares",
    qrDots: "qr-dots",
    copy: "copy",
    bigWhitePhone: "big-white-phone",
    usaFlag: "usa-flag",
    filterIconBig: "filter-icon-big",
    refreshArrow: "refresh-arrow",
    plusInSquare: "plus-in-square",
    errorCircle: "error-circle",
    alarmBell: "alarm-bell",
    analyticsBars: "analytics-bars",
    attachment: "attachment",
    attachmentBlue: "attachment-blue",
    audioBlue: "audio-blue",
    back: "back",
    ballBlueSmall: "ball-blue-small",
    photo: "photo",
    bookOpenBookmark: "book-open-bookmark",
    breadCrumbs: "bread-crumbs",
    userApproveCheck: "user-approve-check",
    caution: "caution",
    cautionEmpty: "caution-empty",
    camcorder: "camcorder",
    camera: "camera",
    checkmarkBlue: "checkmark-blue",
    checkmark: "checkmark",
    thinCheckmark: "thin-checkmark",
    circleShape: "circle-shape",
    clockBlueSmall: "clock-blue-small",
    closeWhiteSmall: "close-white-small",
    close: "close",
    cloudUploadBlue: "cloud-upload-blue",
    colorPalette: "color-palette",
    conversationTextNoFill: "conversation-text-no-fill",
    conversationText: "conversation-text",
    compass: "compass",
    document: "document",
    dropDownArrowBlack: "drop-down-arrow-black",
    dropDownArrowBlue: "drop-down-arrow-blue",
    dropDown: "drop-down",
    drawerDownload: "drawer-download",
    emailBlueSmall: "email-blue-small",
    emailBlue: "email-blue",
    everyone: "everyone",
    film: "film",
    facebook: "facebook",
    favouriteBlue: "favourite-blue",
    flash: "flash",
    gearWhite: "gear-white",
    gears: "gears",
    globeBlueSmall: "globe-blue-small",
    groupBlackSmall: "group-black-small",
    groupBlueSmall: "group-blue-small",
    groupBlue: "group-blue",
    group: "group",
    layersBlue: "layers-blue",
    leftArrowBlack: "left-arrow-black",
    listBreadCrumb: "list-bread-crumb",
    listBullets: "list-bullets",
    lock: "lock",
    loginLogo: "login-logo",
    atlasOneLogo: "atlas-one-logo",
    markerBlueSmall: "marker-blue-small",
    microphone: "microphone",
    minus: "minus",
    mobile: "mobile",
    moreInfoBlue: "more-info-blue",
    moreInfo: "more-info",
    navigation: "navigation",
    twoPersons: "two-persons",
    onboardingLogo: "onboarding-logo",
    pause: "pause",
    personBlue: "person-blue",
    person: "person",
    profileSettings: "profile-settings",
    personLaughingHeadphones: "person-laughing-headphones",
    personLockBlueSmall: "person-lock-blue-small",
    personSpeakingBlueSmall: "person-speaking-blue-small",
    phoneBlue: "phone-blue",
    phoneBlueSmall: "phone-blue-small",
    play: "play",
    plusWhite: "plus-white",
    plus: "plus",
    polyHorizontalRectangles: "poly-horizontal-rectangles",
    polyTwoSquares: "poly-two-squares",
    profile: "profile",
    questionHelpMessage: "question-help-message",
    realEstateHouse: "real-estate-house",
    record: "record",
    rectangleShape: "rectangle-shape",
    rectangle: "rectangle",
    rightArrow: "right-arrow",
    search: "search",
    smsBlue: "sms-blue",
    smsPhoneUrgent: "sms-phone-urgent",
    stop: "stop",
    timCalendarBlue: "tim-calendar-blue",
    timClockBlue: "tim-clock-blue",
    timClock: "tim-clock",
    trash: "trash",
    treasureChestBlue: "treasure-chest-blue",
    tripleLineVertical: "triple-line-vertical",
    triplePlane: "triple-plane",
    twitter: "twitter",
    verticalTripleLine: "vertical-triple-line",
    video: "video",
    sendEmail: "send-email",
    lightBulb2: "light-bulb-2",
    serverCheck: "server-check",
    powerOff: "power-off",
    synchronizeArrowClock: "synchronize-arrow-clock",
    filter: "filter",
    globe: "globe",
    status: "status",
    tag: "tag",
    expand: "expand",
    sendEmailEnvelope: "send-email-envelope",
    smallMobilePhone: "small-mobile-phone",
    temp: "temp",
    send: "send",
    phoneLandline: "phone-landline",
    emailUnread: "email-unread",
    locationTarget: "location-target",
    profileImage: "profile-image",
    statusArchived: "status-archived",
    statusActivated: "status-activated",
    statusInvited: "status-invited",
    statusDeactivated: "status-deactivated",
    pencil: "pencil",
    employeeTag: "employee-tag",
    lockSquare: "lock-square",
    fileCodeUpload: "file-code-upload",
    layers: "layers",
    compassArrow: "compass-arrow",
    handDrag: "hand-drag",
    downArrow: "down-arrow",
    upArrow: "up-arrow",
    oneTimePassword: "one-time-password",
    calendarFigma: "calendar-figma",
    tick: "tick",
    greyLock: "lock-1",
    studio: "design-tool-wireframe",
    twoTabsBlue: "two-tabs-blue",
    updateBlue: "update-blue",
    sinkArrowBlue: "synchronize-arrow-clock-blue",
    settingSlider: "settings-slider",
    personWhite: "person-white",
    personDirectory: "person-directory",
    hollowMarkerGreen: "hollow-marker-green",
    phoneDevice: "phone-device",
    newsPink: "news-pink",
    textFormatting: "text-formatting",
    arrow: "arrow",
    imagePhotography: "image-photography",
    imagePhotographySun: "image-photography-sun",
    emailEnvelopes: "envelope-email",
    phone: "phone",
    ballSmall: "ball-small",
    listBreadCrumbWhite: "list-bread-crumb-white",
    hollowMarkerwhite: "hollow-marker-white",
    networkArrowWhite: "network-arrow-white",
    phoneWhiteSmall: "phone-white-small",
    imagePhoto: "image-photo",
    crossMarkerBlue: "cross-marker",
    vectorLine: "vector-line",
    textBold: "text-bold",
    textItalic: "text-italic",
    textUnderLine: "text-underline",
    leftAlignText: "left-align-text",
    textAlignCenter: "text-align-center",
    textAlignRight: "right-align-text",
    numberList: "number-list",
    closeQuote: "close-quote",
    angleBracket: "angle-bracket",
    hyperlink: "hyperlink",
    rectangleBox: "rectangle-box",
    touchFinger: "touch-finger",
    sendSMS: "send-sms",
    openUrl: "open-url",
    phoneBlue1: "phone-blue-1",
    rightBlue: "right-blue",
    calendarBlue: "calendar-figma-blue",
    clockBlue: "clock-figma-blue",
    email: "email-white",
    sms: "sms",
    url: "url",
    gradient: "gradient",
    navigationIncident: "navigation-incident",
    angleBrackets: "angle-brackets",
    richTextListBullet: "rich-text-list-bullets",
    twitterBlueSmall: "twitter-blue-small",
    facebookIcon: "facebook-icon",
    earthBlue: "earth-blue",
    composeSetLocationIcon: "compose-set-location-icon",
    composeSetLocationPerimeterIcon: "compose-set-location-perimeter-icon",
    onUploadSplashIcon: "on-upload-splash-icon",
    removeMultimedia: "remove-multimedia",
    clipIcon: "clip-icon",
    alertPreviewLogo: "alert-preview-logo",
    setPerimeterIcon: "set-perimeter",
    setMarkerIcon: "set-marker",
    twitterRounded: "twitter-rounded",
    twitterAttachmentIcon: "twitter-attachment-icon",
    twitterPreviewComment: "twitter-preview-comment",
    twitterPreviewRetwit: "twitter-preview-retweet",
    twitterPreviewLike: "twitter-preview-like",
    twitterPreviewShare: "twitter-preview-share",
    twitterPreviewChevronDown: "twitter-preview-chevron-down",
    facebookPlanet: "facebook-planet",
    facebookPreviewMore: "facebook-preview-more",
    facebookPreviewLike: "facebook-preview-like",
    facebookPreviewBottomLike: "facebook-preview-bottom-like",
    facebookPreviewComment: "facebook-preview-comment",
    facebookPreviewShare: "facebook-preview-bottom-share",
    hourglass: "hourglass",
    atlasOneChannelsLogo: "atlas-one-channels-logo",
    atalsOneChannelLogo: "atlas-one-channel-logo",
    linkToChannelPostDropdownIcon: "link-to-channel-post-dropdown-icon",
    hourglassBlack: "hourglass-black",
    multiMediaPlayIcon: "multimedia-play",
    conversations: "conversations-icon",
    conversationsFilter: "conversations-filter",
    inboxIcon: "inbox-icon",
    headsetIcon: "headset-icon",
    checklistIcon: "checklist",
    filtersIconBlue: "filters-icon-blue",
    viewsSelectIcon: "views-select-icon",
    noSelectedConversation: "no-selected-conversation-icon",
    expandIcon: "expand-icon",
    rollUpIcon: "roll-up-icon",
    videoCallIcon: "video-call-icon",
    messageIcon: "message-icon",
    call: "call",
    contactPhoneNumber: "contact-phone-number",
    returnIcon: "return",
    emojiIcon: "emoji",
    conversationRateIcon: "conversation-rate",
    snippetsIcon: "snippets-icon",
    snippetsIconBig: "snippets-icon-big",
    plusRounded: "plus-rounded",
    tagsDouble: "tags-double",
    enterIcon: "enter-icon",
    pauseMediaIcon: "pause-media-icon",
    ratingStar: "rating-star",
    shopIcon: "shop-icon",
    watchIcon: "watch-icon",
    greetingIcon: "greeting-icon",
    autoResponseIcon: "auto-response",
    inboxBigIcon: "inbox-big",
    assignNotificationIcon: "assign-notification-icon",
    atIcon: "at-icon",
    gear: "gear",
    alertMessageLaptop: "alert-message-laptop",
    emailActionReadDocument: "email-action-read-document",
    alarmBellSettings: "alarm-bell-settings",
    alarmBellMute: "alarm-bell-mute",
    alarmBellMuted: "alarm-bell-muted",
    notificationsEmptyAlarmBell: "notifications-empty-alarm-bell",
    snoozedEmpty: "snoozedEmpty",
    openedEmpty: "openedEmpty",
    spamEmpty: "spamEmpty",
    resolvedEmpty: "resolvedEmpty",
    searchConversationsEmty: "searchConversationsEmty",
    emptyMentions: "emptyMentions",
    emptyTags: "emptyTags",
    secondaryQueuesEmpty: "secondaryQueuesEmpty",
    dropdownSearchNoResults: "dropdownSearchNoResults",
    searchInputCancel: "searchInputCancel",
    filterEmpty: "filterEmpty",
    adminRatingNegative: "adminRatingNegative",
    adminRatingPositive: "adminRatingPositive",
    citizenRatingPositive: "citizenRatingPositive",
    citizenRatingNegative: "citizenRatingNegative",
    smallBoldPlus: "smallBoldPlus",
    downloadIcon: "download-icon",
    twoPeople: "two-people",
    conversationSharedLocation: "conversation-shared-location-marker",
    messageBubble: "message-bubble",
    settingToggleHorizontal: "settings-toggle-horizontal",
    alarmBellRing: "alarm-bell-ring",
    inboxBigIconBold: "inbox-big-icon-bold",
    diagramSplitHorizontal: "diagram-split-horizontal",
    programmingBrowser: "programming-browser",
    programmingBrowserThin: "programmingBrowserThin",
    serverSettings: "server-settings",
    smartLinksEmpty: "smart-link-empty",
    inviteEmail: "inviteEmail",
    inviteSMS: "inviteSMS"
};
