import { Notification, NotificationsSettings } from "src/store";

export const SET_NOTIFICATIONS = "set-notifications";

export const SET_HAS_MORE_NOTIFICATIONS = "set-has-more-notififcations";

export const SET_NOTIFICATION_SETTINGS = "set-notifacations-settings";

export const UPDATE_NOTIFICATIONS_COUNTER = "update-notifications-counter";

type SetNotifications = {
    type: typeof SET_NOTIFICATIONS;
    payload: Array<Notification>;
};

type SetHasMoreNotifications = {
    type: typeof SET_HAS_MORE_NOTIFICATIONS;
    payload: boolean;
};

type SetNotififcationsSettings = {
    type: typeof SET_NOTIFICATION_SETTINGS;
    payload: NotificationsSettings;
}

type UpdateNotificationsCounter = {
    type: typeof UPDATE_NOTIFICATIONS_COUNTER;
    payload: number;
}

export type NotificationsActionTypes =
    | SetNotifications
    | SetHasMoreNotifications
    | SetNotififcationsSettings
    | UpdateNotificationsCounter;
 