/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */ 
export interface IStyledNavBarItemType {
    className?: string;
    disableRouting?: boolean;
    toolTipClassName?: string;
    toolTipText?: string;
    pathname?: string;
    id?: string;
    notSelectable?: boolean;
    element?: JSX.Element;
}

export const StyledNavbarTypes = {
    sidebar: "sidebar" as const,
    topbar: "topbar" as const
};

export type StyledNavbarTypes = typeof StyledNavbarTypes[keyof typeof StyledNavbarTypes];
