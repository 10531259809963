export type NotificationTypesEnum = "incoming" | "mention" | "reply" | "assign";

export type Notification = {
    body: {
        summary: string;
    },
    id: string;
    is_read: boolean;
    title: string;
    type: NotificationTypesEnum;
};

export interface NotificationsState {
    notificationsList: Array<Notification>;
    hasMoreNotifications: boolean;
    notificationsSettings: NotificationsSettings | null;
    unreadNotificationsCounter: number | null;
}

export type NotificationsSettings = {
    converstions: {
        assing: boolean;
        incoming: boolean;
        mention: boolean;
        reply: boolean;
    };
    types: {
        email: boolean;
        "in-app": boolean;
    };
    play_sound: boolean;
};

export const defaultNotificationsState = (): NotificationsState => ({
    notificationsList: [],
    hasMoreNotifications: true,
    notificationsSettings: null,
    unreadNotificationsCounter: null,
});
