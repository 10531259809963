import { FormikValues } from "formik";
import { useMemo } from "react";
import { ConversationsRoutingSettings } from "src/components/conversations-routing-settings/conversations-routing-settings";
import styles from "./styles.module.scss";

type CustomizeProps = {
    formik: FormikValues;
};

export const Customize = ({ formik }: CustomizeProps) => {
    const { setFieldValue, values }: FormikValues = formik;

    const onSetSettings = (value: string, key: string) => {
        setFieldValue(key, value);
    };

    const routingSettings = useMemo(() => ({ tags: values.tags, admin: values.admin, queue: values.queue }), [values]);

    return (
        <div className={styles["root"]}>
            <ConversationsRoutingSettings setSelectedSettings={onSetSettings} routingSettings={routingSettings} />
        </div>
    );
};
