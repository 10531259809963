import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";
import {
    WebSocketTypes,
    UPDATE_WEB_SOCKET,
    UPDATE_TOPICS_LIST,
    UPDATE_GOT_DATA_FROM_WEBSOCKET,
    UPDATE_WEB_SOCKET_STATUS,
    RESET_ALERT_PREVIEW,
    SET_WS_DATA,
    REMOVE_WS_DATA
} from "../actions/websocket/types";

export interface WebSocketState {
    webSocket: WebSocket | null;
    webSocketData: any;
    webSocketTopics: Array<string>;
    dataFromSocket: any;
    webSocketStatus: number | string | undefined;
    wsData: {
        [key: string]: {
            [key: string]: any;
        };
    };
}

export const defaultWebSocketState: WebSocketState = {
    webSocket: null,
    webSocketData: {},
    webSocketTopics: [],
    dataFromSocket: {},
    webSocketStatus: undefined,
    wsData: {}
};

export const websocketReducer = (
    state: WebSocketState = defaultWebSocketState,
    action: WebSocketTypes
): WebSocketState => {
    switch (action.type) {
        case UPDATE_WEB_SOCKET:
            return {
                ...state,
                webSocket: action.payload,
                webSocketStatus: action.payload?.readyState
            };
        case UPDATE_TOPICS_LIST:
            return {
                ...state,
                webSocketTopics: [...state.webSocketTopics, action.payload]
            };
        case UPDATE_GOT_DATA_FROM_WEBSOCKET:
            return {
                ...state,
                dataFromSocket: {
                    ...state.dataFromSocket,
                    [action.payload.topic]: {
                        ...action.payload.payload
                    }
                }
            };
        case UPDATE_WEB_SOCKET_STATUS:
            return {
                ...state,
                webSocketStatus: action.payload
            };
        case RESET_ALERT_PREVIEW:
            return {
                ...state,
                dataFromSocket: {
                    ...omit(state.dataFromSocket, action.topic)
                }
            };
        case SET_WS_DATA:
            const { topic: fullTopic, event, data } = action.payload;
            const topic = fullTopic.match(/^.*(?=:)/g)?.[0];
            if (topic) {
                return {
                    ...state,
                    wsData: {
                        ...state.wsData,
                        [topic]: {
                            ...(state.wsData[topic] ? state.wsData[topic] : {}),
                            [event]: data
                        }
                    }
                };
            } else {
                return state;
            }
        case REMOVE_WS_DATA:
            const updated = {
                ...state,
                wsData: {
                    ...omit(state.wsData, action.payload)
                }
            };
            return updated;
        default:
            return state;
    }
};
