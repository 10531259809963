import { useCallback, useEffect, useMemo, useState } from "react";
import {
    getConversationsSnippets,
    GetConversationsSnippetsResponseItem,
    ConversationsSnippet,
    deleteSnippetRequest,
    updateSnippet,
    createSnippet
} from "src/requests/conversationsRequests";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { onUpdateToast } from "src/actions/global/global";
import { useHistory } from "react-router-dom";

export type UseConversationsSnippets = {
    getSnippetsProcessing: boolean;
    searchValue: string;
    snippets: Array<GetConversationsSnippetsResponseItem>;
    setAllSnippets: (snippets: Array<GetConversationsSnippetsResponseItem>) => void;
    setSearchValue: (searchValue: string) => void;
    getSnippets: (searchValue?: string) => void;
    deleteSnippetFromList: (snippetId: string) => void;
    updateSnippet: (snippetId: string, data: ConversationsSnippet) => void;
    createSnippet: (data: any) => void;
    deleteSnippetFromDetail: (snippetId: string) => void;
};

export const useSnippets = (): UseConversationsSnippets => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const [allSnippets, setAllSnippets] = useState<Array<GetConversationsSnippetsResponseItem>>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [getSnippetsProcessing, setGetSippetsProcessing] = useState<boolean>(true);

    useEffect(() => {
        getSnippets();

        return () => {
            setAllSnippets([]);
        };
    }, []);

    const deleteSnippetFromList = useCallback(
        async (snippetId) => {
            await deleteSnippetRequest(snippetId);
            const res = allSnippets.map((snippetsGroup: GetConversationsSnippetsResponseItem) => {
                return {
                    ...snippetsGroup,
                    snippets: snippetsGroup.snippets.filter((snippet: ConversationsSnippet) => snippet.id !== snippetId)
                };
            });
            setAllSnippets(res);
        },
        [allSnippets]
    );
    const deleteSnippetFromDetail = useCallback(
        async (snippetId) => {
            try {
                await deleteSnippetRequest(snippetId);
                const res = allSnippets.map((snippetsGroup: GetConversationsSnippetsResponseItem) => {
                    return {
                        ...snippetsGroup,
                        snippets: snippetsGroup.snippets.filter(
                            (snippet: ConversationsSnippet) => snippet.id !== snippetId
                        )
                    };
                });
                setAllSnippets(res);
                dispatch(onUpdateToast({ value: "Snippet was successfully deleted", type: "saved" }));
                history.push("/account-settings/snippets");
            } catch (err) {
                dispatch(onUpdateToast({ value: "Something get wrong. Try again a bit later", type: "error" }));
            }
        },
        [allSnippets]
    );

    const onCreateSnippet = useCallback(
        async (data) => {
            try {
                const {
                    data: { snippet: res }
                } = await createSnippet(data);
                const updatedSnippets = allSnippets.map((snippetsGroup: GetConversationsSnippetsResponseItem) => {
                    if (snippetsGroup.category.id === res.category.id) {
                        return {
                            ...snippetsGroup,
                            snippets: [...snippetsGroup.snippets, res]
                        };
                    }
                    return snippetsGroup;
                });
                setAllSnippets(updatedSnippets);
                dispatch(onUpdateToast({ value: `Snippets: ${res.name} was created`, type: "saved" }));
                history.push("/account-settings/snippets");
                return res;
            } catch (err) {
                dispatch(onUpdateToast({ value: "Something get wrong. Try again a bit later", type: "error" }));
            }
        },
        [allSnippets]
    );

    const onUpdateSnippet = useCallback(
        async (snippetId: string, data: ConversationsSnippet) => {
            try {
                const {
                    data: { snippet: updateRes }
                } = await updateSnippet(snippetId, data);
                const res = allSnippets.map((snippetsGroup: GetConversationsSnippetsResponseItem) => {
                    return {
                        ...snippetsGroup,
                        snippets: snippetsGroup.snippets.map((snippet: ConversationsSnippet) => {
                            if (snippet.id === snippetId) {
                                return updateRes;
                            }

                            return snippet;
                        })
                    };
                });
                setAllSnippets(res);
                dispatch(onUpdateToast({ value: `Snippets ${updateRes.name} was updated`, type: "saved" }));
                return updateRes;
            } catch (err) {
                dispatch(onUpdateToast({ value: "Something get wrong. Try again a bit later", type: "error" }));
            }
        },
        [allSnippets]
    );

    const getSnippets = useCallback(async () => {
        try {
            setGetSippetsProcessing(true);
            const {
                data: { snippets }
            } = await getConversationsSnippets();
            setAllSnippets(snippets);
        } finally {
            setGetSippetsProcessing(false);
        }
    }, [allSnippets]);

    const snippets: any = useMemo(() => {
        const accumulator: Array<ConversationsSnippet> = [];
        return allSnippets.reduce(
            (accumulator: Array<ConversationsSnippet>, snippet: GetConversationsSnippetsResponseItem) => {
                const filteredSnippets = snippet.snippets.filter((snippetData: ConversationsSnippet) => {
                    return snippetData.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                if (filteredSnippets.length) {
                    return [...accumulator, { ...snippet, snippets: filteredSnippets }];
                }
                return accumulator;
            },
            accumulator
        );
    }, [searchValue, allSnippets]);

    return {
        snippets,
        searchValue,
        getSnippetsProcessing,
        updateSnippet: onUpdateSnippet,
        createSnippet: onCreateSnippet,
        getSnippets,
        setAllSnippets,
        setSearchValue,
        deleteSnippetFromList,
        deleteSnippetFromDetail
    };
};
