// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IStyledCheckboxProps, StyledSelect, StyledSelectTypes } from "src/components";

import { IProps, Text, Classes as BlueprintClasses } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

import "./styles.scss";

export interface ISettingsRolesListItemProps extends IProps {
    active?: boolean;
    checkBoxProps?: IStyledCheckboxProps;
    date?: string;
    firstName: string;
    lastName: string;
    selectValue?: string;
    selectOptions?: Array<string>;
    onClick?: (event: React.SyntheticEvent) => void;
    onSelectChanged?: (event: React.SyntheticEvent) => void;
}

export const SettingsRolesListItem: React.FunctionComponent<ISettingsRolesListItemProps> = (props) => {
    const {
        className,
        date,
        active,
        selectValue,
        selectOptions,
        firstName,
        lastName,
        loading,
        onClick,
        onSelectChanged
    } = props;
    const classes = "settings-roles-list-item";

    const initials = firstName?.charAt(0) + lastName?.charAt(0);
    const title = firstName + " " + lastName;

    return (
        <div
            className={classNames(classes, active && Classes.ACTIVE, Classes.BLOCK_PADDING, className)}
            onClick={onClick}
        >
            <div className={classNames(classes + "-left-wrapper", loading && BlueprintClasses.SKELETON)}>
                {/* <div className={classes + '-checkbox-wrapper'}  onClick={e => e.stopPropagation()}>
                    <StyledCheckbox
                        className={classes + '-checkbox'}
                        {...checkBoxProps}
                    />
                </div> */}
                <div className={classes + "-label-wrapper"}>
                    <Text className={classes + "-label-initials"}> {initials} </Text>
                    <Text className={classes + "-label-title"}> {title} </Text>
                </div>
            </div>
            <div className={classNames(classes + "-" + Classes.RIGHT, loading && BlueprintClasses.SKELETON)}>
                <div className={classes + "-select-wrapper"}>
                    <Text className={classes + "-status-text"}> {date} </Text>
                    <div onClick={(e) => e.stopPropagation()}>
                        <StyledSelect
                            className={classes + "-select"}
                            popOverClassName={classes + "-select-popover"}
                            type={StyledSelectTypes.noLine}
                            onChange={onSelectChanged}
                            options={selectOptions}
                            cannotChange={true}
                            value={selectValue}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsRolesListItem;
