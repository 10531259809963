import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { onUpdateToolBar } from "src/actions/global/global";
import { BreadCrumbs, EmptyBlock, StyledButton, StyledButtonTypes } from "src/components";
import { SnippetItem } from "../components/snippet-item/snippet-item";
import styles from "./styles.module.scss";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { navigate } from "src/actions/global/global-api";
import { SnippetsContext } from "../snippets";
import { ReactComponent as SnippetsBigIcon } from "../../../../../mpd-library/icon/assets/snippets-icon-big.svg"

const classname = "snippets-settings";

export const SnippetsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { snippets, getSnippetsProcessing: getSnippetsProcessingStatus, deleteSnippetFromList } = useContext(
        SnippetsContext
    );

    useEffect(() => {
        updateToolBar();
    }, []);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Snippets" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            ),
            rightElement: (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primary}
                    text={"New Snippet"}
                    onClick={onNewSnippetClick}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    const onNewSnippetClick = () => {
        history.push("/account-settings/snippets/create");
    };

    if (getSnippetsProcessingStatus) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[classname]}>
            <div className={styles[`${classname}-content`]}>
                {!snippets.length && (
                    <EmptyBlock
                        IconComponent={SnippetsBigIcon}
                        title={"Looks like you haven’t created any Snippets yet"}
                        subtitle={"To create your first snippet, tap the blue “New Snippet” button"}
                    />
                )}
                {snippets.length && (
                    <div className={styles[`${classname}-snippet-items-wrapper`]}>
                        {snippets.map((snippetGroup: ConversationSnippet) => {
                            return snippetGroup.snippets.map((snippet: ConversationSnippetData) => {
                                return (
                                    <SnippetItem
                                        key={snippet.id}
                                        category={snippetGroup.category}
                                        snippet={snippet}
                                        deleteSnippetFromList={deleteSnippetFromList}
                                    />
                                );
                            });
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
