import { UserSettingsState } from "src/store";
import { LoginState } from "src/containers";

export const LOGOUT_USER = "logout-user";
export const LOGIN_USER = "login-user";
export const LOGIN_USER_SUCCESS = "login-user-success";
export const LOGIN_USER_ERROR = "login-user-error";
export const LOGIN_STATE_ACTION = "login-state-action";
export const USERNAME_CHANGED = "username_changed";
export const PASSWORD_CHANGED = "password_changed";
export const FORGOT_PASSWORD_CHANGED = "forgot_password_changed";
export const REMEMBER_ME_CHANGED = "remember_me_changed";
export const ONE_TIME_PASSWORD_CHANGED = "one_time_password_changed";
export const OTP_TOKEN_CHANGED = "otp_token_changed";
export const OTP_EMAIL_CHANGED = "otp_email_changed";
export const OTP_PHONE_NUMBER_CHANGED = "otp_phone_number_changed";
export const OTP_CHANNEL_CHANGED = "otp_channel_changed";

interface LogoutUserAction {
    type: typeof LOGOUT_USER;
    payload: null;
}

interface LoginUserAction {
    type: typeof LOGIN_USER;
    payload: null;
}

interface LoginUserSuccessAction {
    type: typeof LOGIN_USER_SUCCESS;
    payload: UserSettingsState;
}

interface LoginUserErrorAction {
    type: typeof LOGIN_USER_ERROR;
    payload: string;
}

interface LoginStateActionAction {
    type: typeof LOGIN_STATE_ACTION;
    payload: typeof LoginState;
}

interface UsernameChangedAction {
    type: typeof USERNAME_CHANGED;
    payload: string;
}

interface PasswordChangedAction {
    type: typeof PASSWORD_CHANGED;
    payload: string;
}

interface ForgotPasswordChangedAction {
    type: typeof FORGOT_PASSWORD_CHANGED;
    payload: string;
}

interface RememberMeChangedAction {
    type: typeof REMEMBER_ME_CHANGED;
    payload: boolean;
}

interface OneTimePasswordChangedAction {
    type: typeof ONE_TIME_PASSWORD_CHANGED;
    payload: string;
}

interface OTPTokenChangedAction {
    type: typeof OTP_TOKEN_CHANGED;
    payload: string;
}

interface OTPEmailChangedAction {
    type: typeof OTP_EMAIL_CHANGED;
    payload: string;
}

interface OTPPhoneNumberChangedAction {
    type: typeof OTP_PHONE_NUMBER_CHANGED;
    payload: string;
}

interface OTPChannelChangedAction {
    type: typeof OTP_CHANNEL_CHANGED;
    payload: string;
}

export type AuthActionTypes =
    | LogoutUserAction
    | LoginUserAction
    | LoginUserSuccessAction
    | LoginUserErrorAction
    | LoginStateActionAction
    | UsernameChangedAction
    | PasswordChangedAction
    | ForgotPasswordChangedAction
    | RememberMeChangedAction
    | OneTimePasswordChangedAction
    | OTPTokenChangedAction
    | OTPEmailChangedAction
    | OTPPhoneNumberChangedAction
    | OTPChannelChangedAction;
