// tslint:disable jsx-no-lambda
import React from "react";
import { connect } from "react-redux";
import { doesStringExistInArr, globalActions } from "../../actions";

import { MPDAnimations } from "src/mpd-library/";

import {
    RightPanelContainer,
    NavigationListItems,
    SettingsLeftPanel,
    StyledToolBar,
    StyledButtonTypes
} from "src/components";

import { IDefaultView } from "../";

import AccountDetailsSettings from "./organization/account-details-settings/account-details-settings";
import BillingSettings from "./organization/billing-settings/billing-settings";
import InvoicesSettings from "./organization/invoices-settings/invoices-settings";
import JurisdictionSettings from "./organization/jurisdiction-settings/jurisdiction-settings";
import SubAccountSettings from "./organization/sub-accounts-settings/sub-accounts-settings";
import SubscriptionSettings from "./organization/subscription-settings/subscription-settings";

import AuditLogSettings from "./general/audit-log-settings/audit-log-settings";
import ChannelSettings from "./general/channel-settings/channel-settings";
import DataExportSettings from "./general/data-export-settings/data-export-settings";
import DevelopersSettings from "./general/developers-settings/developers-settings";
import RolesSettings from "./general/roles-settings/roles-settings";
import SecuritySettings from "./general/security-settings/security-settings";
import TeamSettings from "./general/team-settings/team-settings";
import UsersSettings from "./general/users-settings/users-settings";

import GroupsSettings from "./alerts/groups-settings/groups-settings";
import MapLayers from "./alerts/map-layers/map-layers";

import { Redirect, Route, Switch } from "react-router-dom";

import "./styles.scss";
import { AppState } from "../../store";
import { AccountPrivilege } from "src/requests";
import AccountBrandingSettings from "./organization/branding-settings/branding-settings";
import { bindActionCreators, Dispatch } from "redux";
import { SnippetsSettings } from "./conversations/snippets/snippets";
import { TagsSettings } from "./conversations/tags-settings/tags-settings";
import { QueuesSettings } from "./conversations/queues/queues";
import { ProfileAutomations } from "./conversations/profile-automations/profile-automations";
import { getAccountSettingsDetails, onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { navigate } from "src/actions/global/global-api";
import { ApiKeysSettings } from "./api-keys-settings/api-keys-settings";
import { SmartLinks } from "./conversations/smart-links/SmartLinks";
import { StyledAlertTypes } from "../../components";
import { ContactsListsSettings } from "./contacts-lists/ContactsListsSettings";

export interface IAccountSettingsProps extends IDefaultView {
    privileges: Array<AccountPrivilege>;
    conversations_enabled: boolean;
    onGetAccountSettingsDetails: () => void;
}

class AccountSettings extends React.PureComponent<IAccountSettingsProps> {
    public componentDidMount() {
        this.props.onGetAccountSettingsDetails();
        setTimeout(() => {
            window.scrollTo(0, 0);
            const divHeight = document.getElementById("app-container");
            if (divHeight) {
                divHeight.scrollTo(0, 1);
            }
        }, 500);
    }

    public render() {
        const {
            currentPlan,
            isMobileSize,
            routeProps,
            mobileSideBarActive,
            privileges = [],
            onUpdateAlert,
            onNavigate,
            navPath,
            loading,
            rightPanelExpanded,
            toast,
            topBarProps,
            alertOpen
        } = this.props;
        const pathname = routeProps.history.location.pathname;

        const privilegesFeaturesHrids = privileges.map((privilege) => privilege.feature_hrid);

        const commonRouteProps = {
            routeProps,
            onCloseRightPanel: this.onCloseRightPanel,
            onToggleRightPanel: this.onToggleRightPanel,
            onUpdateChangesMade: this.props.onUpdateChangesMade
        };

        return (
            <div className="app-controller">
                <SettingsLeftPanel
                    title="Settings"
                    rightPanelExpanded={rightPanelExpanded}
                    mobileSideBarActive={mobileSideBarActive}
                    onToggleMobileSideBar={this.props.onToggleMobileSideBar}
                    isIn={Boolean(pathname === "/account-settings")}
                    enter={Boolean(pathname === "/account-settings")}
                    exit={Boolean(pathname !== "/account-settings")}
                    isMobileSize={isMobileSize}
                >
                    {this.renderSettings()}
                </SettingsLeftPanel>
                <RightPanelContainer
                    expanded={rightPanelExpanded}
                    animationType={
                        pathname !== "/account-settings" ? MPDAnimations.swipeLeft : MPDAnimations.swipeRight
                    }
                    loading={loading}
                    styledAlertProps={{
                        type: StyledAlertTypes.warning,
                        onCancel: () => onUpdateAlert(false),
                        onConfirm: () => onNavigate(navPath, routeProps.history, false),
                        confirmButtonProps: {
                            type: StyledButtonTypes.delete,
                            text: "Leave"
                        },
                        isOpen: alertOpen,
                        // children: (
                        //     <StyledAlertGlobalContent
                        //         onCancel={() => onUpdateAlert(false)}
                        //         title={"Confirm"}
                        //         description={"Are you sure you want to leave without finishing?"}
                        //     />
                        // )
                        globalContentProps: {
                            onCancel: () => onUpdateAlert(false),
                            title: "Confirm",
                            description: "Are you sure you want to leave without finishing?"
                        }
                    }}
                    toast={toast}
                    appear={true}
                    isIn={Boolean(pathname !== "/account-settings")}
                    enter={Boolean(pathname !== "/account-settings")}
                    exit={Boolean(pathname === "/account-settings")}
                    isMobileSize={isMobileSize}
                >
                    <StyledToolBar {...topBarProps} key={`${routeProps.history.location}`} />
                    <Switch location={routeProps.history.location}>
                        {!isMobileSize && (
                            <Redirect exact={true} from="/account-settings" to="/account-settings/account-details/" />
                        )}
                        <Route
                            path={"/account-settings/account-details/"}
                            render={() => <AccountDetailsSettings {...commonRouteProps} />}
                        />
                        {privilegesFeaturesHrids.includes("admin_manage_api_settings") && (
                            <Route path={"/account-settings/api-key"} render={() => <ApiKeysSettings />} />
                        )}

                        {privilegesFeaturesHrids.includes("admin_jurisdiction_read_write") && (
                            <Route
                                exact={true}
                                path={"/account-settings/jurisdiction"}
                                render={() => <JurisdictionSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                exact={true}
                                path={"/account-settings/sub-accounts"}
                                render={() => <SubAccountSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/subscription"}
                                render={() => <SubscriptionSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/billing"}
                                render={() => <BillingSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                exact={true}
                                path={"/account-settings/invoices"}
                                render={() => <InvoicesSettings {...commonRouteProps} />}
                            />
                        )}
                        {privilegesFeaturesHrids.includes("admin_users_read_write") && (
                            <Route
                                path={"/account-settings/users/"}
                                render={() => <UsersSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/teams/"}
                                render={() => <TeamSettings {...commonRouteProps} />}
                            />
                        )}
                        {privilegesFeaturesHrids.includes("admin_channels_read_write") && (
                            <Route
                                path={"/account-settings/channels/"}
                                render={() => <ChannelSettings {...commonRouteProps} />}
                            />
                        )}
                        <Route
                            path={"/account-settings/branding"}
                            render={() => <AccountBrandingSettings {...commonRouteProps} />}
                        />
                        {/* )} */}
                        {privilegesFeaturesHrids.includes("can_view_audit_logs") && (
                            <Route
                                path={"/account-settings/audit-log/"}
                                render={() => <AuditLogSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/security/"}
                                render={() => <SecuritySettings {...commonRouteProps} />}
                            />
                        )}
                        {privilegesFeaturesHrids.includes("admin_roles_read_write") && (
                            <Route
                                path={"/account-settings/roles"}
                                render={() => <RolesSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/data-export/"}
                                render={() => <DataExportSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/developers/"}
                                render={() => <DevelopersSettings {...commonRouteProps} />}
                            />
                        )}
                        {currentPlan !== "essential" && (
                            <Route
                                path={"/account-settings/groups/"}
                                render={() => <GroupsSettings {...commonRouteProps} />}
                            />
                        )}
                        {privilegesFeaturesHrids.includes("admin_geo_layers_read_write") && (
                            <Route
                                path={"/account-settings/map-layers/"}
                                render={() => (
                                    <MapLayers
                                        routeProps={routeProps}
                                        isMobileSize={isMobileSize}
                                        onCloseRightPanel={this.onCloseRightPanel}
                                        onToggleRightPanel={this.onToggleRightPanel}
                                        onUpdateChangesMade={this.props.onUpdateChangesMade}
                                    />
                                )}
                            />
                        )}
                        {/* {currentPlan !== "essential" && ( */}

                        {true && (
                            <>
                                {" "}
                                <Route
                                    path={"/account-settings/profile-details/"}
                                    render={() => (
                                        <ProfileAutomations
                                            // routeProps={routeProps}
                                            // onCloseRightPanel={this.onCloseRightPanel}
                                            onToggleRightPanel={this.onToggleRightPanel}
                                            onUpdateChangesMade={this.props.onUpdateChangesMade}
                                        />
                                    )}
                                />
                                <Route
                                    path={"/account-settings/snippets/"}
                                    render={() => <SnippetsSettings {...commonRouteProps} />}
                                />
                                <Route path={"/account-settings/smart-links/"} render={() => <SmartLinks />} />
                                <Route
                                    path={"/account-settings/tags/"}
                                    render={() => (
                                        <TagsSettings
                                            // routeProps={routeProps}
                                            // onCloseRightPanel={this.onCloseRightPanel}
                                            onToggleRightPanel={this.onToggleRightPanel}
                                            onUpdateChangesMade={this.props.onUpdateChangesMade}
                                        />
                                    )}
                                />
                                <Route
                                    path={"/account-settings/queues/"}
                                    render={() => (
                                        <QueuesSettings
                                            onToggleRightPanel={this.onToggleRightPanel}
                                            onUpdateChangesMade={this.props.onUpdateChangesMade}
                                        />
                                    )}
                                />
                                <Route path={"/account-settings/lists"} render={() => <ContactsListsSettings />} />
                            </>
                        )}
                        {/* )} */}
                    </Switch>
                </RightPanelContainer>
            </div>
        );
    }

    private getEssentialItems = () => {
        const { privileges = [], conversations_enabled, contacts_enabled } = this.props;
        const topItems = [];
        const middleItems: Array<string> = [];
        const bottomItems: Array<string> = [];
        let conversationsItems: Array<string> = [];
        let contactsItems: Array<string> = [];

        const privilegesFeaturesHrids = privileges.map((privilege) => privilege.feature_hrid);
        if (privilegesFeaturesHrids.includes("admin_settings_read_write")) {
            topItems.push("Account Details", "Branding");
        }
        if (privilegesFeaturesHrids.includes("admin_jurisdiction_read_write")) {
            topItems.push("Jurisdiction");
        }
        if (privilegesFeaturesHrids.includes("admin_manage_api_settings")) {
            topItems.push("API Key");
        }
        if (conversations_enabled || contacts_enabled) {
            topItems.push("Tags");
        }
        if (privilegesFeaturesHrids.includes("admin_geo_layers_read_write")) {
            bottomItems.push("Map Layers");
        }
        if (privilegesFeaturesHrids.includes("admin_users_read_write")) {
            middleItems.push("Users");
        }
        if (privilegesFeaturesHrids.includes("admin_roles_read_write")) {
            middleItems.push("Roles");
        }
        if (privilegesFeaturesHrids.includes("admin_channels_read_write")) {
            middleItems.push("Channels");
        }
        if (privilegesFeaturesHrids.includes("can_view_audit_logs")) {
            // TODO: SELECT CORRECT PRIVILEGE
            middleItems.push("Audit Log");
        }
        if (privilegesFeaturesHrids.includes("conversations_manage_settings") && conversations_enabled) {
            conversationsItems = conversationsItems.concat(["Profile Details", "Queues", "Snippets", "Smart Links"]);
        }
        if (contacts_enabled) {
            contactsItems = contactsItems.concat(["Lists"]);
        }
        const items = {
            topItems,
            conversationsItems,
            contactsItems,
            middleItems,
            bottomItems
        };

        return items;
    };

    private getProEssentialItems = () => {
        const { conversations_enabled, contacts_enabled } = this.props;
        const items = {
            topItems: [
                "Account Details",
                "Branding",
                "Jurisdiction",
                "Sub Accounts",
                "Subscription",
                "Billing",
                "Invoices",
                "API Key",
                "Tags"
            ],
            conversationsItems: conversations_enabled ? ["Profile Details", "Queues", "Snippets", "Smart Links"] : [],
            contactsItems: contacts_enabled ? ["Lists"] : [],
            middleItems: ["Users", "Roles", "Teams", "Channels", "Security", "Audit Log", "Data Export", "Developers"],
            bottomItems: ["Groups", "Map Layers"]
        };
        return items;
    };

    private renderSettings = () => {
        const { routeProps } = this.props;
        const pathname = routeProps.history.location.pathname;
        let items: any = {};

        if (this.props.currentPlan === "essential") {
            items = this.getEssentialItems();
        } else {
            items = this.getProEssentialItems();
        }

        const { topItems, middleItems, bottomItems, conversationsItems, contactsItems } = items;

        const formattedConversationsItems = conversationsItems.map((item) => item.replace(/& /g, ""));

        const activeTopIndex = doesStringExistInArr(pathname.replace(/-/g, " "), topItems);
        const activeMiddleIndex = doesStringExistInArr(pathname.replace(/-/g, " "), middleItems);
        const activeBottomIndex = doesStringExistInArr(pathname.replace(/-/g, " "), bottomItems);
        const activeConversationsIndex = doesStringExistInArr(
            pathname.replace(/-|&-/g, " "),
            formattedConversationsItems
        );
        const activeContactsIndex = doesStringExistInArr(pathname.replace(/-/g, " "), contactsItems);

        console.log("activeContactsIndex", activeContactsIndex);

        return (
            <div className="account-settings-container">
                {topItems.length > 0 && (
                    <NavigationListItems
                        activeIndex={activeTopIndex}
                        title="Organization"
                        items={topItems}
                        onItemClick={this.onSettingsItemClick}
                    />
                )}
                {!!conversationsItems.length && (
                    <NavigationListItems
                        activeIndex={activeConversationsIndex}
                        title="Conversations"
                        items={conversationsItems}
                        onItemClick={this.onSettingsItemClick}
                    />
                )}
                {!!contactsItems.length && (
                    <NavigationListItems
                        activeIndex={activeContactsIndex}
                        title="Contacts"
                        items={contactsItems}
                        onItemClick={this.onSettingsItemClick}
                    />
                )}
                {middleItems.length > 0 && (
                    <NavigationListItems
                        activeIndex={activeMiddleIndex}
                        title="General"
                        items={middleItems}
                        onItemClick={this.onSettingsItemClick}
                    />
                )}
                {bottomItems.length > 0 && (
                    <NavigationListItems
                        activeIndex={activeBottomIndex}
                        title="Alerts"
                        items={bottomItems}
                        onItemClick={this.onSettingsItemClick}
                    />
                )}
            </div>
        );
    };

    private onToggleRightPanel = () => {
        this.props.onToggleRightPanel();
    };

    private onCloseRightPanel = () => {
        const { history } = this.props.routeProps;
        const { onCloseRightPanel } = this.props;
        const pathname = "/account-settings";
        onCloseRightPanel({ pathname, history });
    };

    private onSettingsItemClick = (item: string) => {
        const { routeProps, changesMade } = this.props;

        if (changesMade) {
            this.props.onUpdateNavPath("/account-settings/" + item.replace(/\s+/g, "-").replace(/&-/g, ""), true);
        } else {
            this.props.onNavigate(
                "/account-settings/" + item.replace(/\s+/g, "-").replace(/&-/g, ""),
                routeProps.history
            );
        }
    };
}

const mapStateToProps = (state: AppState): Partial<IAccountSettingsProps> => {
    const { global, accountSettings, userSettings } = state;
    const { privileges } = userSettings;

    const { rightPanelExpanded } = accountSettings;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;
    const { conversations_enabled, contacts_enabled } = accountSettings.organization;
    const {
        isMobileSize,
        topBarProps,
        windowHeight,
        mobileSideBarActive,
        navPath,
        changesMade,
        alertOpen,
        toast,
        loading
    } = global;
    return {
        privileges,
        loading,
        isMobileSize,
        topBarProps,
        rightPanelExpanded,
        mobileSideBarActive,
        windowHeight,
        navPath,
        changesMade,
        alertOpen,
        toast,
        currentPlan,
        conversations_enabled,
        contacts_enabled
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onToggleRightPanel,
            onCloseRightPanel: navigate,
            onToggleMobileSideBar: globalActions.onToggleMobileSideBar,
            onUpdateChangesMade: globalActions.onUpdateChangesMade,
            onUpdateAlert: globalActions.onUpdateAlert,
            onNavigate: globalActions.onNavigate,
            onUpdateNavPath: globalActions.onUpdateNavPath,
            onGetAccountSettingsDetails: getAccountSettingsDetails
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
