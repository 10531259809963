// tslint:disable
import React from "react";

import { HTMLDivProps } from "@blueprintjs/core";

import "./styles.scss";

export interface IMPDIOSSelectableDivState {
    iosSelectable?: boolean;
}

export class MPDIOSSelectableDiv extends React.Component<HTMLDivProps, IMPDIOSSelectableDivState> {
    constructor(props: HTMLDivProps) {
        super(props);

        let iosSelectable = false;

        if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
            iosSelectable = true;
        }

        this.state = {
            iosSelectable
        };
    }

    public componentDidMount() {
        if (this.state.iosSelectable) {
            document.addEventListener("touchstart", () => null);
        }
    }

    public render() {
        const { children, className, ...newProps } = this.props;

        return (
            <div className={className} {...newProps}>
                {children}
            </div>
        );
    }
}
