// tslint:disable jsx-no-lambda
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import styles from "./StyledAlert.module.scss";

import { Alert, AlertProps } from "@blueprintjs/core";

import { StyledAlertTypes, StyledButton, StyledButtonTypes } from "src/components";

import "./styles.scss";
import { StyledAlertGlobalContent, IStyledAlertGlobalContentProps } from "./content-types";
import { IStyledFloatingInputProps, StyledFloatingInput, StyledFloatingInputTypes } from "../styled-floating-input";

export interface IStyledAlertProps extends AlertProps {
    actionConfirmationProps: {
        confirmInputProps: IStyledFloatingInputProps;
        valueToMatch: string;
    };
    children?: JSX.Element;
    hideCancelButton?: boolean;
    hideConfirmButton?: boolean;
    type?: StyledAlertTypes;
    confirmButtonProps?: any;
    fullScreen?: boolean;
    globalContentProps: IStyledAlertGlobalContentProps;
    formSubmitting?: boolean;
    formErrors?: any;
    notCloseAfterConfirm?: boolean;
    Target?: ({ onClick }: { onClick: any }) => JSX.Element;
}

export const StyledAlertChildrenWrapperClasses = {
    withoutPadding: styles["children-wrapper_without-padding"]
};

export const StyledAlert: React.FunctionComponent<IStyledAlertProps> = (props) => {
    const {
        actionConfirmationProps,
        formErrors,
        className,
        children,
        hideCancelButton,
        hideConfirmButton,
        type,
        confirmButtonProps,
        onCancel,
        onConfirm,
        cancelButtonText,
        confirmButtonText,
        leftFooterComponent,
        fullScreen = false,
        globalContentProps,
        isOpen,
        Target,
        childrenWrapperClassName,
        formSubmitting,
        notCloseAfterConfirm,
        ...remaining
    } = props;
    const classes = "styled-alert";
    const wasMount = useRef<MutableRefObject<boolean>>(false);
    const formSubmittingRef = useRef(formSubmitting);

    const [isOpenState, setIsOpen] = useState<boolean>(false);
    const [confirmProcessing, setConfirmProcessing] = useState<boolean>(false);
    const [confirmInputValue, setConfirmInputValue] = useState<string>("");

    // refactor. Implement better solution to show form submit processing spinner
    useEffect(() => {
        if (formSubmittingRef.current && !formSubmitting && wasMount.current && !!isEmpty(formErrors)) {
            setIsOpen(false);
        }
        formSubmittingRef.current = formSubmitting;
    }, [formSubmitting, formErrors]);

    useEffect(() => {
        wasMount.current = true;
    }, []);

    useEffect(() => {
        if (typeof isOpen !== undefined) {
            setIsOpen(isOpen);
        }
    }, [isOpen]);

    const onConfitmInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setConfirmInputValue(value);
    };

    const onConfirmClick = async () => {
        try {
            setConfirmProcessing(true);
            await onConfirm?.();
            if (typeof formSubmitting !== "undefined") {
                return;
            } else {
                !notCloseAfterConfirm && setIsOpen(false);
            }
        } finally {
            setConfirmProcessing(false);
            setConfirmInputValue("");
        }
    };

    const onCancelClick = () => {
        onCancel?.();
        setIsOpen(false);
    };

    const onTargetClick = () => {
        setIsOpen(true);
    };

    return (
        <>
            {Target && <Target onClick={onTargetClick} />}
            <Alert
                className={classNames(
                    styles[classes],
                    styles[type],
                    className,
                    fullScreen && styles[`${classes}_fullscreen`]
                )}
                portalClassName={styles["styled-alert-portal"]}
                canEscapeKeyCancel={true}
                canOutsideClickCancel={true}
                onCancel={onCancel}
                {...remaining}
                isOpen={isOpenState}
            >
                <div className={styles[classes + "-content-wrapper"]}>
                    <StyledAlertGlobalContent {...globalContentProps} onCancel={onCancelClick} />
                    {actionConfirmationProps && (
                        <div className={styles["confirm-input-wrapper"]}>
                            <StyledFloatingInput
                                value={confirmInputValue}
                                floatingType={StyledFloatingInputTypes.primary}
                                onChange={onConfitmInputChange}
                                {...actionConfirmationProps?.confirmInputProps}
                            />
                        </div>
                    )}
                    {children && (
                        <div className={classNames(styles["children-wrapper"], childrenWrapperClassName)}>
                            {children}
                        </div>
                    )}
                    {(leftFooterComponent || (!hideCancelButton && !hideConfirmButton)) && (
                        <div className={classNames(styles[classes + "-footer"], styles[className + "-footer"])}>
                            {leftFooterComponent && leftFooterComponent}
                            <div className={styles[classes + "-footer-right-wrapper"]}>
                                {!hideCancelButton && (
                                    <StyledButton
                                        className={styles[classes + "-cancel"]}
                                        text={cancelButtonText || "Cancel"}
                                        onClick={onCancelClick}
                                        type={StyledButtonTypes.secondary}
                                    />
                                )}
                                {!hideConfirmButton && (
                                    <StyledButton
                                        className={styles[classes + "-confirm"]}
                                        text={confirmButtonText || "Ok"}
                                        onClick={onConfirmClick}
                                        type={StyledButtonTypes.primary}
                                        processing={confirmProcessing}
                                        disabled={
                                            actionConfirmationProps &&
                                            actionConfirmationProps?.valueToMatch !== confirmInputValue
                                        }
                                        {...confirmButtonProps}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Alert>
        </>
    );
};
