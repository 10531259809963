import React from "react";
import App from "./App/App";
// import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";

import * as ReactDOM from "react-dom";

// import "@blueprintjs/core/lib/css/blueprint.css";
// import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { AppState, rootReducer } from "./store";
import { errorMiddleware } from "./middlewares";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadInitialData } from "./actions/global/global-api";
import { createRoot } from "react-dom/client";

/* tslint:disable:no-string-literal */
const composeEnhancers = composeWithDevTools({ trace: false });
// const composeEnhancers = (window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
/* tslint:enable:no-string-literal */
export const store = createStore<AppState>(
    rootReducer,
    composeEnhancers(applyMiddleware(errorMiddleware, thunkMiddleware))
);

// export const store = createStore<AppState>(
//     rootReducer,
//     composeEnhancers(composeWithDevTools({ trace: true }))
// );

store.dispatch(loadInitialData());

// ReactDOM.render(
//     <Provider store={store as any}>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     </Provider>,
//     document.getElementById("root")
// );
// registerServiceWorker();
unregister();

createRoot(document.getElementById("root")).render(
    <Provider store={store as any}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
