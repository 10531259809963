// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { MPDScroll } from "src/mpd-library";

import { TabId } from "@blueprintjs/core";

import { BreadCrumbs, StyledTabs, StyledTabsTypes } from "src/components";

import BillingSettingsDetails from "./billing-settings-details/billing-settings-details";

import BillingSettingsOverview from "./billing-settings-overview/billing-settings-overview";

import { Redirect, Route, Switch } from "react-router-dom";

import { IDetailView } from "../../..";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { bindActionCreators, Dispatch } from "redux";

export interface IBillingSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
}

interface IBillingSettingsState {
    selectedTabNum: TabId;
    tabs: Array<string>;
}

class BillingSettings extends React.Component<IBillingSettingsProps, IBillingSettingsState> {
    constructor(props: IBillingSettingsProps) {
        super(props);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.updatedSelectedTabNum = this.updatedSelectedTabNum.bind(this);
        this.onUpdateToolBar = this.onUpdateToolBar.bind(this);
        this.onUpdateToolBar();

        this.state = {
            selectedTabNum: 0,
            tabs: ["overview", "details"]
        };
    }

    public render() {
        const { className, onToggleRightPanel, onCloseRightPanel, onUpdateChangesMade, routeProps } = this.props;
        const { selectedTabNum, tabs } = this.state;
        const classes = "billing-settings";

        return (
            <div className={classNames("billing-settings", className)}>
                <Redirect exact={true} from="/account-settings/billing" to="/account-settings/billing/overview" />
                <StyledTabs
                    tabs={tabs}
                    type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                    tabsProps={{
                        id: "billing-settings-tabs",
                        onChange: this.onChangeTab,
                        selectedTabId: selectedTabNum
                    }}
                />
                <MPDScroll className={classNames(classes + "-scroll-container")} yDirection={true}>
                    <Switch location={routeProps.history.location}>
                        <Route
                            path={"/account-settings/billing/overview"}
                            exact={true}
                            render={() => (
                                <BillingSettingsOverview
                                    onCloseRightPanel={onCloseRightPanel}
                                    onToggleRightPanel={onToggleRightPanel}
                                    onUpdateChangesMade={onUpdateChangesMade}
                                    routeProps={routeProps}
                                />
                            )}
                        />
                        <Route
                            exact={true}
                            path={"/account-settings/billing/details"}
                            render={() => (
                                <React.Fragment>
                                    <BillingSettingsDetails />
                                </React.Fragment>
                            )}
                        />
                    </Switch>
                </MPDScroll>
            </div>
        );
    }

    private updatedSelectedTabNum() {
        const { routeProps } = this.props;
        const { tabs } = this.state;
        const pathname = routeProps.history.location.pathname;
        const pathArr = pathname.split("/");
        const selectedTabNum = tabs.findIndex((tab: string) => tab === pathArr[pathArr.length - 1]);
        this.setState({ selectedTabNum });
    }

    private onChangeTab(newTabNum: TabId) {
        const { routeProps } = this.props;
        const { tabs } = this.state;
        const tabId = tabs[newTabNum].toLowerCase();
        this.setState({ selectedTabNum: newTabNum });
        routeProps.history.push(`/account-settings/billing/${tabId}`);
    }

    private onUpdateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Billing" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: null
        };
        this.props.onUpdateToolBar(topBarProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettings);
