import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import "./styles.scss";

export const DotStatus = ({ status, label }) => {
    return (
        <Text className={classNames("channels-status-label", `channels-status-label-${status}`)}>
            {/* {account.status === "offline" ? "Needs Reauthorization" : status} */}
            {label}
        </Text>
    );
};
