// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text, Classes as BPClasses } from "@blueprintjs/core";
import styles from "./settings-audit.module.scss";
import "./styles.scss";
import { Tooltip2 } from "@blueprintjs/popover2";
import moment from "moment";

interface IActivityType {
    user: string;
    caseID?: string;
    action: string;
    actionDetails?: string;
}

interface IPType {
    address: string;
    location: string;
}

interface IBrowserType {
    browser: string;
    OS: string;
}

type ActivityLogLevelType = "info" | "warning" | "error";

const activityLogLevelTooltipContent: { [key in ActivityLogLevelType]: string } = {
    info: "Info",
    warning: "Warning",
    error: "Error"
};

export interface ISettingsAuditLogListItemProps extends IProps {
    activityType: IActivityType;
    browserType: IBrowserType;
    item: any;
    ip: IPType;
    loading: boolean;
    activityLogLevel: ActivityLogLevelType;
}

export const SettingsAuditLogListItem: React.FunctionComponent<ISettingsAuditLogListItemProps> = (props) => {
    const { className, activityType, ip, browserType, activityLogLevel, loading, item } = props;
    const classes = "settings-audit-log-list-item";

    return (
        <div className={classNames(classes + "-container", className)}>
            <div className={classNames(classes, "activity-type")}>
                <Tooltip2
                    placement={"bottom"}
                    content={activityLogLevelTooltipContent[activityLogLevel]}
                    portalClassName={"mpd-simple-tooltip"}
                    hoverOpenDelay={500}
                    className={"activity-log-tooltip-target"}
                >
                    <div
                        className={classNames(
                            "activity-log-level-dot",
                            `activity-log-level-dot_${activityLogLevel}`,
                            loading && `${BPClasses.SKELETON} ${styles["skeleton-icon"]}`
                        )}
                    />
                </Tooltip2>
                {activityType.actionDetails ? (
                    <div className={classNames(loading && `${BPClasses.SKELETON} ${styles["skeleton-summary"]}`)}>
                        {/* <span className="user"> {activityType.user} </span> */}
                        <span className="action-type"> {activityType.action} </span>
                        {/* <span className="case-id"> {" Case " + activityType.caseID} </span> */}
                        <span className="action-details"> {activityType.actionDetails} </span>
                    </div>
                ) : (
                    <div className={classNames(loading && `${BPClasses.SKELETON} ${styles["skeleton-summary"]}`)}>
                        {/* <span className="user"> {activityType.user} </span> */}
                        <span className="action-type"> {activityType.action} </span>
                        {/* <span className="case-id"> {" Case " + activityType.caseID} </span> */}
                    </div>
                )}
            </div>
            <div className={classNames(classes + "-inner-container")}>
                <Text className={classNames(classes, "date")}>
                    <div
                        className={classNames(
                            classes + "-row",
                            loading && `${BPClasses.SKELETON} ${styles["skeleton-title"]}`
                        )}
                    >
                        <span className="date"> {moment(item?.published).format("MMMM D, YYYY")} </span>
                        <span className="time"> {moment(item?.published).format("hh:mm:ss a zz")} </span>
                    </div>
                </Text>
                <Text className={classNames(classes, "ip")}>
                    <div
                        className={classNames(
                            classes + "-row",
                            loading && `${BPClasses.SKELETON} ${styles["skeleton-title"]}`
                        )}
                    >
                        <span className="ip"> {ip.address} </span>
                        <span className="location"> {ip.location} </span>
                    </div>
                </Text>
                <Text className={classNames(classes, "browser-type")}>
                    <div
                        className={classNames(
                            classes + "-row",
                            loading && `${BPClasses.SKELETON} ${styles["skeleton-title"]}`
                        )}
                    >
                        <span className="browser"> {browserType.browser} </span>
                        <span className="os"> {browserType.OS} </span>
                    </div>
                </Text>
            </div>
        </div>
    );
};

export default SettingsAuditLogListItem;
