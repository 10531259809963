// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IDetailView } from "../../../../";

import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { Classes, MPDScroll } from "src/mpd-library";

import { BreadCrumbs, StyledSelect, StyledSelectTypes, StartEndDateTimer, ContainerEmpty } from "src/components";

import "./styles.scss";
import { AppState, AccountSettingsState, AccountSettingsGeneralCurrentUser } from "src/store";
import { ListHeader } from "../../audit-log-settings/components";
import { SettingsAuditLogListItem } from "src/containers/account-settings/components";
import moment from "moment";
import { Spinner } from "@blueprintjs/core";
import { bindActionCreators, Dispatch } from "redux";
import { getActivitiesByIdLog, getActivityTypes } from "src/actions/account-settings/account-settings-api";
import { ReactComponent as CalendarFigmaIcon } from "../../../../../mpd-library/icon/assets/calendar-figma.svg";

interface IUserSettingsViewDetailsActivityProps extends IDetailView {
    currentUser: AccountSettingsGeneralCurrentUser;
    accountSettings: AccountSettingsState;
    onGetActivitiesByIdLog: ({
        id,
        isOnAdd,
        from,
        to,
        types
    }: {
        id: string;
        isOnAdd?: boolean;
        from?: string;
        to?: string;
        types?: Array<string>;
    }) => void;
    onGetActivityTypes: () => void;
}

interface IUserSettingsViewDetailsActivityState {
    filters: {
        startDate: Date;
        endDate: Date;
        selectedActivity: "All Activity" | string;
    };
}

class UserSettingsViewDetailsActivity extends React.Component<
    IUserSettingsViewDetailsActivityProps,
    IUserSettingsViewDetailsActivityState
> {
    constructor(props: IUserSettingsViewDetailsActivityProps) {
        super(props);
        this.state = {
            filters: {
                startDate: new Date(),
                endDate: new Date(),
                selectedActivity: "All Activity"
            }
        };

        this.updateToolBar = this.updateToolBar.bind(this);
        this.onExportActivity = this.onExportActivity.bind(this);
        this.updateToolBar();
    }

    public componentDidMount() {
        const {
            onGetActivitiesByIdLog,
            onGetActivityTypes,
            currentUser: { id }
        } = this.props;

        onGetActivitiesByIdLog({ id });
        onGetActivityTypes();
    }

    public render() {
        const {
            className,
            accountSettings: {
                general: {
                    users: { usersActivities },
                    activityTypes
                }
            },
            currentUser: { id }
        } = this.props;
        const {
            filters: { startDate, endDate, selectedActivity }
        } = this.state;
        const classes = "user-settings-view-details-activity";

        const currentUserActivities = usersActivities && usersActivities[id] ? usersActivities[id] : null;

        const activities = currentUserActivities && currentUserActivities.list;
        const activityTypesOptions = activityTypes ? ["All Activity"].concat(activityTypes) : ["All Activity"];
        const loadingListStatus = currentUserActivities && currentUserActivities.loadingListStatus;

        const minSatartDate = new Date();
        minSatartDate.setFullYear(minSatartDate.getFullYear() - 1);

        return (
            <div className={classNames(className, classes + "-wrapper")}>
                <div className={classNames(classes + "-header", Classes.ROW, Classes.CONTAINER_MARGIN_HORIZONTAL)}>
                    <StartEndDateTimer
                        className={classes + "-styled-dates-picker"}
                        startTimeProps={{
                            className: classes + "-styled-date-inputs",
                            onDateChange: (val: Date) => this.onTimeChanged(val, "startDate"),
                            date: startDate,
                            withoutTime: true,
                            LeftIconComponent: CalendarFigmaIcon,
                            minDate: minSatartDate
                        }}
                        endTimeProps={{
                            className: classes + "-styled-date-inputs",
                            onDateChange: (val: Date) => this.onTimeChanged(val, "endDate"),
                            date: endDate,
                            withoutTime: true,
                            LeftIconComponent: CalendarFigmaIcon,
                            minDate: startDate
                        }}
                    />

                    <StyledSelect
                        className={classes + "-select"}
                        type={StyledSelectTypes.primary}
                        value={selectedActivity}
                        options={activityTypesOptions}
                        onChange={this.onActivityFilterChange}
                    />
                </div>
                <div className={classes + "-list"}>
                    <ListHeader />
                    <MPDScroll
                        className={classNames(classes + "-scrolled-data", Classes.CONTAINER_MARGIN_VERTICAL)}
                        xDirection={true}
                        onScrollBottom={this.onScrollBottom}
                    >
                        {activities && activities.length
                            ? activities.map((activity) => (
                                  <SettingsAuditLogListItem
                                      key={activity.id}
                                      date={this.getActivityDate(activity.published)}
                                      time={this.getActivityTime(activity.published)}
                                      ip={{
                                          address: activity?.geo_ip?.ip || "",
                                          location: activity?.geo_ip?.city || ""
                                      }}
                                      browserType={{
                                          browser: activity?.user_agent?.client?.name || "",
                                          OS: `${activity?.user_agent?.os?.name || ""} ${
                                              activity?.user_agent?.os?.version || ""
                                          }`
                                      }}
                                      activityType={{
                                          user: `${activity?.actor?.first_name || ""} ${
                                              activity?.actor?.last_name || ""
                                          }`,
                                          action: activity?.summary || ""
                                          // caseID: activity.id
                                      }}
                                  />
                              ))
                            : !loadingListStatus && (
                                  <ContainerEmpty
                                      className={classes + "-list-container-empty"}
                                      title="No Activities Found"
                                  />
                              )}
                        {loadingListStatus && <Spinner className={classes + "-spinner"} intent="primary" size={50} />}
                    </MPDScroll>
                </div>
            </div>
        );
    }

    private updateToolBar() {
        const { routeProps, currentUser } = this.props;

        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Users", pathname: "/account-settings/users" },
                        {
                            text: currentUser.firstName + " " + currentUser.lastName,
                            pathname: "account-settings/users/add/details"
                        }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    }

    private onExportActivity() {
        //
    }

    private getActivityDate = (date: Date): string => {
        return moment(date).format("MMMM D, YYYY");
    };

    private getActivityTime = (date: Date): string => {
        return moment(date).format("hh:mm:ss a zz");
    };

    private onScrollBottom = () => {
        this.onGetActivitiesLogByIdWithFilters(true);
    };

    private onTimeChanged = (value: Date, type: "startDate" | "endDate") => {
        if (type === "startDate" && this.state.filters.endDate < value) {
            this.setState(
                {
                    ...this.state,
                    filters: {
                        ...this.state.filters,
                        [type]: value,
                        endDate: value
                    }
                },
                () => this.onGetActivitiesLogByIdWithFilters()
            );
        } else {
            this.setState(
                {
                    ...this.state,
                    filters: {
                        ...this.state.filters,
                        [type]: value
                    }
                },
                () => this.onGetActivitiesLogByIdWithFilters()
            );
        }
    };

    private onActivityFilterChange = (e: any) => {
        this.setState(
            {
                ...this.state,
                filters: {
                    ...this.state.filters,
                    selectedActivity: e.currentTarget.textContent
                }
            },
            () => this.onGetActivitiesLogByIdWithFilters()
        );
    };

    private onGetActivitiesLogByIdWithFilters = (isOnAdd?: boolean) => {
        const {
            onGetActivitiesByIdLog,
            currentUser: { id }
        } = this.props;
        const {
            filters: { startDate, endDate }
        } = this.state;

        onGetActivitiesByIdLog({
            id,
            isOnAdd,
            from: moment(startDate).format("MM/DD/YYYY"),
            to: moment(endDate).format("MM/DD/YYYY"),
            types: this.getActivityTypesForRequest()
        });
    };

    private getActivityTypesForRequest = () => {
        const {
            filters: { selectedActivity }
        } = this.state;

        return selectedActivity !== "All Activity"
            ? [selectedActivity]
            : this.props.accountSettings.general.activityTypes;
    };
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    return {
        accountSettings
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onGetActivityTypes: getActivityTypes,
            onGetActivitiesByIdLog: getActivitiesByIdLog
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsViewDetailsActivity);
