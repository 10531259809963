// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Classes, MPDAnimations, MPDAnimationContainer } from "src/mpd-library/";

import { IStyledAlertProps, StyledAlertTypes, StyledAlert } from "src/components";

import { IProps } from "@blueprintjs/core";

import "./styles.scss";
import { CommonToast, ICommonToastProps } from "../toast";

export interface IToast {
    value: string;
    type: string;
}

export interface IRightPanelContainerProps extends IProps, ICommonToastProps {
    animationType: MPDAnimations;
    isIn: boolean;
    expanded?: boolean;
    timeout?: number;
    loading?: boolean;
    exit?: boolean;
    appear?: boolean;
    enter?: boolean;
    isMobileSize?: boolean;
    styledAlertProps?: IStyledAlertProps;
}

export const RightPanelContainer: React.FunctionComponent<IRightPanelContainerProps> = (props) => {
    const {
        className,
        animationType,
        isIn,
        timeout,
        appear,
        exit,
        enter,
        isMobileSize,
        children,
        expanded,
        loading,
        styledAlertProps
    } = props;
    const classes = "right-panel-container";

    return (
        <React.Fragment>
            <MPDAnimationContainer
                className={classNames(classes, expanded && Classes.EXPANDED, loading && Classes.LOADING, className)}
                classNames={isMobileSize ? animationType : MPDAnimations.none}
                appear={isMobileSize ? appear : true}
                in={isMobileSize ? isIn : true}
                exit={isMobileSize ? exit : false}
                enter={isMobileSize ? enter : true}
                transitionDuration={timeout !== undefined ? timeout : 150}
                timeout={timeout !== undefined ? timeout : 150}
                unmountOnExit={true}
            >
                <StyledAlert
                    className={classes + "-styled-alert"}
                    {...styledAlertProps}
                    isOpen={styledAlertProps && styledAlertProps.isOpen !== undefined ? styledAlertProps.isOpen : false}
                    type={
                        styledAlertProps && styledAlertProps.type !== undefined
                            ? styledAlertProps.type
                            : StyledAlertTypes.primary
                    }
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={true}
                    cancelButtonText={
                        styledAlertProps && styledAlertProps.cancelButtonText !== undefined
                            ? styledAlertProps.cancelButtonText
                            : "Cancel"
                    }
                    confirmButtonText={
                        styledAlertProps && styledAlertProps.confirmButtonText !== undefined
                            ? styledAlertProps.confirmButtonText
                            : "Okay"
                    }
                >
                    {styledAlertProps && styledAlertProps.children}
                </StyledAlert>
                <CommonToast />
                {children}
                <div className={classNames(classes + "-loader-moving-wrapper", loading && Classes.ACTIVE)}>
                    <div className={classes + "-loader-moving"} />
                </div>
            </MPDAnimationContainer>
        </React.Fragment>
    );
};
