// tslint:disable
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions, userSettingsActions, conversationsActions } from "../../actions";

import {
    NotificationsItem,
    StyledNavbarTypes,
    StyledNavBar,
    IStyledNavBarItemType,
    StyledButton
} from "src/components";

import { ProfileItem, SideBarAccount } from "./components";

import { IProps, Position, Text } from "@blueprintjs/core";

import "./styles.scss";
import { AppState, UserSettingsState } from "src/store";
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router";
import { AccountPrivilege } from "src/requests";
import { bindActionCreators, Dispatch } from "redux";
import NotificationsPopover from "src/components/notifications-popover/notifications-popover";
import {
    getFirstAccessibleRoute,
    hasAccessToAccountSettings,
    hasAccessToAlerts,
    hasAccessToAnalytics,
    hasAccessToConversations,
    hasAccessToStudio
} from "src/actions/common/utils";
import { ReactComponent as AtlasOneLogoIcon } from "../../mpd-library/icon/assets/atlas-one-logo.svg";
import { ReactComponent as FlashIcon } from "../../mpd-library/icon/assets/flash.svg";
import { ReactComponent as ConversationsIcon } from "../../mpd-library/icon/assets/conversations-icon.svg";
import { ReactComponent as SidebarContactsIcon } from "../../mpd-library/icon/assets/SidebarContactsIcon.svg";
import { ReactComponent as StudioIcon } from "../../mpd-library/icon/assets/design-tool-wireframe.svg";
import { ReactComponent as AnalyticsBarsIcon } from "../../mpd-library/icon/assets/analytics-bars.svg";
import { ReactComponent as AlarmBellIcon } from "../../mpd-library/icon/assets/alarm-bell.svg";
import { ReactComponent as GearWhiteIcon } from "../../mpd-library/icon/assets/gear-white.svg";
import { ReactComponent as ProfileSettingsIcon } from "../../mpd-library/icon/assets/profile-settings.svg";
import { ReactComponent as ServerCheckIcon } from "../../mpd-library/icon/assets/server-check.svg";
import { ReactComponent as PowerOffIcon } from "../../mpd-library/icon/assets/power-off.svg";
import { ReactComponent as BackIcon } from "../../mpd-library/icon/assets/back.svg";
import { ReactComponent as CompassIcon } from "../../mpd-library/icon/assets/compass.svg";
import { ReactComponent as BookOpenBookmarkIcon } from "../../mpd-library/icon/assets/book-open-bookmark.svg";
import { ReactComponent as QuestionHelpMessageIcon } from "../../mpd-library/icon/assets/question-help-message.svg";
import { ReactComponent as CautionIcon } from "../../mpd-library/icon/assets/caution.svg";
import { hasAccessToContacts } from "../../actions/common/utils";

interface ISideBarProps extends IProps {
    privileges: Array<AccountPrivilege>;
    changesMade: boolean;
    currentPlan: string;
    mobileActive?: boolean;
    userSettings: UserSettingsState;
    isMobileSize: boolean;
    mobileSideBarActive: boolean;
    routeProps: RouteComponentProps;
    history: History;
    unreadNotificationsCounter: number;
    conversationsCounter: number;
    onNavigate: (navPath: string | null, history: History, alertOpen?: boolean) => void;
    onUpdateNavPath: (navPath: string, alertOpen?: boolean) => void;
    onToggleMobileSideBar: () => void;
    onToggleProfileStatus: () => void;
    setDefaultState: () => void;
}

interface ISideBarState {
    items: Array<Array<IStyledNavBarItemType>>;
    showProfile: boolean;
    showNotifications: boolean;
}

class SideBar extends React.Component<ISideBarProps, ISideBarState> {
    constructor(props: ISideBarProps) {
        super(props);
        this.onToggleAccount = this.onToggleAccount.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onProfileClick = this.onProfileClick.bind(this);
        this.renderSideBar = this.renderSideBar.bind(this);
        this.renderMobileAccount = this.renderMobileAccount.bind(this);

        this.state = {
            showProfile: false,
            items: [],
            showNotifications: false
        };
    }

    public componentDidUpdate(prevProps: ISideBarProps, prevState: ISideBarState) {
        const { isMobileSize, userSettings, unreadNotificationsCounter, conversationsCounter } = this.props;
        const { showProfile } = this.state;
        if (
            prevState.showProfile !== showProfile ||
            prevProps.userSettings !== userSettings ||
            prevProps.isMobileSize !== isMobileSize ||
            unreadNotificationsCounter !== prevProps.unreadNotificationsCounter ||
            conversationsCounter !== prevProps.conversationsCounter
        ) {
            this.renderItems(this.props);
        }
    }

    public componentDidMount() {
        this.renderItems(this.props);
    }

    private renderItems(props: ISideBarProps) {
        const { showProfile } = this.state;
        const { isMobileSize } = props;
        if (isMobileSize && showProfile) {
            this.renderMobileAccount(props);
        } else {
            this.renderSideBar(props);
        }
    }

    private onNotificationsClick = () => {
        const { isMobileSize } = this.props;
        if (!isMobileSize) {
            this.setState(
                (state) => ({ showNotifications: !state.showNotifications }),
                () => {
                    this.renderItems(this.props);
                }
            );
        } else {
            this.onItemClick("/notifications");
        }
    };

    private renderSideBar(props: ISideBarProps) {
        let items: any;
        if (this.props.currentPlan === "essential") {
            items = this.renderEssentialItems(props);
        } else {
            items = this.renderProItems(props);
        }
        this.setState({ items });
    }

    private renderEssentialItems(props: ISideBarProps) {
        const {
            userSettings,
            routeProps,
            isMobileSize,
            privileges = [],
            unreadNotificationsCounter,
            conversationsCounter
        } = props;
        const { firstName, lastName, logo } = userSettings.myInfo || {};
        const { updatedSrc } = logo || {};
        const { showProfile, showNotifications } = this.state;
        const notSelectable = true;
        let bottomItems: any;
        const middleItems: any = [];
        const topItems = [
            {
                className: "home-side-item",
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Home",
                disableRouting: true,
                onItemClick: () => this.onItemClick(getFirstAccessibleRoute(privileges)),
                notSelectable,
                element: (
                    <div className="testing">
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            text="ATLAS ONE"
                            IconComponent={AtlasOneLogoIcon}
                        />
                    </div>
                )
            }
        ];

        const privilegesFeaturesHrids = privileges.map((privilege) => privilege.feature_hrid);

        if (privilegesFeaturesHrids && hasAccessToAlerts(privileges)) {
            middleItems.push({
                className: "customers-side-item",
                disableRouting: true,
                toolTipClassName: "default-tool-tip",
                toolTipText: "Alerts",
                pathname: "/alerts",
                onItemClick: () => this.onItemClick("/alerts"),
                element: (
                    <div className="testing">
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            text="Alerts"
                            IconComponent={FlashIcon}
                        />
                    </div>
                )
            });
        }
        if (hasAccessToConversations(privileges)) {
            middleItems.push({
                className: "maps-side-item",
                toolTipClassName: "default-tool-tip",
                toolTipText: "Conversations",
                pathname: "/conversations",
                disableRouting: true,
                onItemClick: () => this.onItemClick("/conversations"),
                element: (
                    //   <StyledButton className={"atlas-side-item-styled-button"} text="Alerts" name="conversations" />
                    <div className={"conversations-menu-item-wrapper"}>
                        <StyledButton
                            className={classNames("atlas-side-item-styled-button", "notifications-side-item-button")}
                            text="Conversations"
                            IconComponent={ConversationsIcon}
                        />
                        {conversationsCounter > 0 && <Text className={"sidebar-counter"}>{conversationsCounter}</Text>}
                    </div>
                )
            });
        }
        if (hasAccessToContacts()) {
            middleItems.push({
                className: "maps-side-item",
                toolTipClassName: "default-tool-tip",
                toolTipText: "Contacts",
                pathname: "/contacts",
                disableRouting: true,
                onItemClick: () => this.onItemClick("/contacts"),
                element: (
                    <div className={"testing"}>
                        <StyledButton
                            className={classNames("atlas-side-item-styled-button", "notifications-side-item-button")}
                            text="Contacts"
                            IconComponent={SidebarContactsIcon}
                        />
                    </div>
                )
            });
        }
        if (hasAccessToStudio(privileges)) {
            middleItems.push({
                className: "studio-side-item",
                disableRouting: true,
                toolTipClassName: "default-tool-tip",
                toolTipText: "Studio",
                pathname: "/studio",
                onItemClick: () => this.onItemClick("/studio"),
                element: (
                    <div className={"testing"}>
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            text="Studio"
                            IconComponent={StudioIcon}
                        />
                    </div>
                )
            });
        }

        if (hasAccessToAnalytics(privileges)) {
            middleItems.push({
                className: "analytics-side-item",
                disableRouting: true,
                toolTipClassName: "default-tool-tip",
                toolTipText: "Analytics",
                pathname: "/analytics",
                onItemClick: () => this.onItemClick("/analytics"),
                element: (
                    <div className={"testing"}>
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            text="Analytics"
                            IconComponent={AnalyticsBarsIcon}
                        />
                    </div>
                )
            });
        }

        const notificationsTabObject = {
            element: (
                <div
                    className={classNames("notifications-sidebar-tab", "conversations-menu-item-wrapper")}
                    onClick={this.onNotificationsClick}
                >
                    <StyledButton
                        className={classNames(
                            "atlas-side-item-styled-button",
                            "notifications-side-item-button",
                            showNotifications && "notifications-side-item-button-active"
                        )}
                        text="Notifications"
                        IconComponent={AlarmBellIcon}
                    />
                    {unreadNotificationsCounter > 0 && (
                        <Text className={"sidebar-counter"}>{unreadNotificationsCounter}</Text>
                    )}
                </div>
            ),
            popOverProps: isMobileSize
                ? undefined
                : {
                      backdropProps: {
                          className: "styled-sidebar-backdrop",
                          onClick: this.onNotificationsClick
                      },
                      //   popoverClassName: "styled-sidebar-account-popover",
                      position: Position.RIGHT_TOP,
                      content: <NotificationsPopover onCloseClick={this.onNotificationsClick} />,
                      isOpen: showNotifications,
                      canEscapeKeyClose: true,
                      hasBackdrop: true,
                      portalClassName: "notifications-popover-portal"
                  },
            className: "",
            // toolTipClassName: "default-tool-tip",
            toolTipText: "Notifications"
            // onItemClick: this.onNotificationsClick
            // onItemClick: this.onNotificationsClick
        };

        if (hasAccessToAccountSettings(privileges)) {
            bottomItems = [
                notificationsTabObject,
                {
                    className: "settings-side-item",
                    disableRouting: true,
                    toolTipClassName: "default-tool-tip",
                    toolTipText: "Settings",
                    pathname: "/account-settings",
                    onItemClick: () => this.onItemClick("/account-settings"),
                    element: (
                        <div className={"testing"}>
                            <StyledButton
                                className={"atlas-side-item-styled-button"}
                                text="Settings"
                                IconComponent={GearWhiteIcon}
                            />
                        </div>
                    )
                },
                {
                    element: (
                        <div className={"testing"}>
                            <ProfileItem
                                src={updatedSrc}
                                firstName={firstName}
                                lastName={lastName}
                                title="My Profile"
                            />
                        </div>
                    ),
                    popOverProps: isMobileSize
                        ? undefined
                        : {
                              backdropProps: {
                                  className: "styled-sidebar-backdrop",
                                  onClick: this.onToggleAccount
                              },
                              popoverClassName: "styled-sidebar-account-popover",
                              position: Position.LEFT,
                              content: (
                                  <SideBarAccount
                                      firstName={firstName}
                                      lastName={lastName}
                                      src={updatedSrc}
                                      // status={status}
                                      routeProps={routeProps}
                                      onToggleProfileStatus={this.props.onToggleProfileStatus}
                                      onItemClick={this.onProfileClick}
                                  />
                              ),
                              isOpen: showProfile,
                              canEscapeKeyClose: true,
                              hasBackdrop: true
                          },
                    className: "my-profile-sidebar-item",
                    toolTipClassName: "default-tool-tip",
                    toolTipText: "My Profile",
                    onItemClick: this.onProfileClick
                }
            ];
        } else {
            bottomItems = [
                notificationsTabObject,
                {
                    element: (
                        <ProfileItem src={updatedSrc} firstName={firstName} lastName={lastName} title="My Profile" />
                    ),
                    popOverProps: isMobileSize
                        ? undefined
                        : {
                              backdropProps: {
                                  className: "styled-sidebar-backdrop",
                                  onClick: () => {
                                      this.onToggleAccount();
                                  }
                              },
                              popoverClassName: "styled-sidebar-account-popover",
                              position: Position.LEFT,
                              content: (
                                  <SideBarAccount
                                      firstName={firstName}
                                      lastName={lastName}
                                      src={updatedSrc}
                                      // status={status}
                                      routeProps={routeProps}
                                      onToggleProfileStatus={this.props.onToggleProfileStatus}
                                      onItemClick={this.onProfileClick}
                                  />
                              ),
                              isOpen: showProfile,
                              canEscapeKeyClose: true,
                              hasBackdrop: true
                          },
                    className: "my-profile-sidebar-item",
                    // toolTipClassName: "default-tool-tip",
                    toolTipText: "My Profile",
                    onItemClick: this.onProfileClick
                }
            ];
        }

        const items = [topItems, middleItems, bottomItems];
        return items;
    }

    private renderProItems(props: ISideBarProps) {
        const { userSettings, routeProps, isMobileSize } = props;
        const { firstName, lastName, logo } = userSettings.myInfo;
        const { showProfile } = this.state;
        const notSelectable = true;
        const topItems = [
            {
                className: "home-side-item",
                disableRouting: true,
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Home",
                pathname: "/alerts",
                onItemClick: () => this.onItemClick("/alerts"),
                notSelectable,
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="ATLAS ONE"
                        IconComponent={AtlasOneLogoIcon}
                    />
                )
            }
        ];
        const middleItems = [
            {
                className: "customers-side-item",
                toolTipClassName: "default-tool-tip",
                toolTipText: "Alerts",
                disableRouting: true,
                pathname: "/alerts",
                onItemClick: () => this.onItemClick("/alerts"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Customers"
                        IconComponent={FlashIcon}
                    />
                )
            },
            {
                className: "sidebar-notifications-item",
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Incidents",
                pathname: "/incidents",
                disableRouting: true,
                onItemClick: () => this.onItemClick("/incidents"),
                element: <NotificationsItem title="Incidents" IconComponent={CautionIcon} numOfNotifications={2} />
            },
            {
                className: "maps-side-item",
                toolTipClassName: "default-tool-tip",
                toolTipText: "Conversations",
                pathname: "/conversations",
                disableRouting: true,
                onItemClick: () => this.onItemClick("/conversations"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Maps"
                        IconComponent={ConversationsIcon}
                    />
                )
            },
            {
                className: "maps-side-item",
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Maps",
                pathname: "/maps",
                disableRouting: true,
                onItemClick: () => this.onItemClick("/maps"),
                element: (
                    <StyledButton className={"atlas-side-item-styled-button"} text="Maps" IconComponent={CompassIcon} />
                )
            },
            {
                className: "book-open-bookmark-side-item",
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Content Library",
                pathname: "/content-library",
                disableRouting: true,
                onItemClick: () => this.onItemClick("/content-library"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Content Library"
                        IconComponent={BookOpenBookmarkIcon}
                    />
                )
            },
            {
                className: "analytics-side-item",
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Analytics",
                disableRouting: true,
                pathname: "/analytics",
                onItemClick: () => this.onItemClick("/analytics"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Analytics"
                        IconComponent={AnalyticsBarsIcon}
                    />
                )
            },
            {
                className: "studio-side-item",
                disableRouting: true,
                toolTipClassName: "default-tool-tip",
                toolTipText: "Studio",
                pathname: "/studio",
                onItemClick: () => this.onItemClick("/studio"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Studio"
                        IconComponent={StudioIcon}
                    />
                )
            }
        ];
        const bottomItems = [
            {
                className: "settings-side-item",
                disableRouting: true,
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Settings",
                pathname: "/account-settings",
                onItemClick: () => this.onItemClick("/account-settings"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Settings"
                        IconComponent={GearWhiteIcon}
                    />
                )
            },
            {
                className: classNames("sidebar-notifications-item", "notifications-item"),
                disableRouting: true,
                element: (
                    <NotificationsItem title="Notifications" IconComponent={AlarmBellIcon} numOfNotifications={3} />
                ),
                pathname: "/notifications",
                onItemClick: () => this.onItemClick("/notifications"),
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Notifications"
            },
            {
                className: "get-help-sidebar-item",
                disableRouting: true,
                // toolTipClassName: "default-tool-tip",
                toolTipText: "Get Help",
                pathname: "/get-help",
                onItemClick: () => this.onItemClick("/get-help"),
                element: (
                    <StyledButton
                        className={"atlas-side-item-styled-button"}
                        text="Get Help"
                        IconComponent={QuestionHelpMessageIcon}
                    />
                )
            },
            {
                element: (
                    <ProfileItem
                        src={logo ? logo.src : null}
                        firstName={firstName}
                        lastName={lastName}
                        title="My Profile"
                    />
                ),
                popOverProps: isMobileSize
                    ? undefined
                    : {
                          backdropProps: {
                              className: "styled-sidebar-backdrop"
                          },
                          popoverClassName: "styled-sidebar-account-popover",
                          position: Position.LEFT,
                          content: (
                              <SideBarAccount
                                  awayModeActive={true}
                                  firstName={firstName}
                                  lastName={lastName}
                                  src={logo ? logo.src : null}
                                  // status={status}
                                  routeProps={routeProps}
                                  onToggleProfileStatus={this.props.onToggleProfileStatus}
                                  onItemClick={this.onProfileClick}
                              />
                          ),
                          isOpen: showProfile,
                          canEscapeKeyClose: true,
                          hasBackdrop: true
                      },
                className: "my-profile-sidebar-item",
                toolTipClassName: "default-tool-tip",
                toolTipText: "My Profile",
                onItemClick: this.onProfileClick
            }
        ];

        const items = [topItems, middleItems, bottomItems];
        return items;
    }

    private renderMobileAccount(props: ISideBarProps) {
        const { userSettings } = props;
        const { myInfo } = userSettings;

        const { firstName, lastName, logo } = myInfo;
        const { updatedSrc } = logo || {};

        const topItems = [
            {
                className: "mobile-account-home-side-item",
                onItemClick: this.onToggleAccount,
                element: (
                    <div className="testing">
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            text="My Profile"
                            IconComponent={BackIcon}
                        />
                    </div>
                )
            }
        ];
        const middleItems = [
            {
                className: "mobile-account-profile-info-side-item",
                // onItemClick: this.onItemClick,
                element: (
                    <div className="testing">
                        <div className={"mobile-account-profile-info"}>
                            {updatedSrc ? (
                                <div
                                    className={"mobile-account-profile-icon"}
                                    style={{
                                        backgroundImage: `url(${updatedSrc})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover"
                                    }}
                                />
                            ) : (
                                firstName &&
                                lastName && (
                                    <Text className={"mobile-account-profile-initials"}>
                                        {" "}
                                        {firstName.charAt(0) + "" + lastName.charAt(0)}{" "}
                                    </Text>
                                )
                            )}
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Text className={"mobile-account-profile-name"}> {firstName + " " + lastName} </Text>
                                {/* <Text className={'mobile-account-profile-status'}> { status ? 'Away' : 'Active' } </Text> */}
                            </div>
                        </div>
                    </div>
                )
            },
            // {
            //   className: 'mobile-account-status-side-item',
            //   element:
            //   <div className={'mobile-account-profile-status-control'}>
            //     <StyledSwitch checked={status} onChange={this.props.onToggleProfileStatus} label="Away Mode" alignIndicator={Alignment.RIGHT} />
            //    </div>
            // },
            {
                className: "mobile-account-profile-side-item",
                onItemClick: this.onItemClick,
                pathname: "/user-settings",
                element: (
                    <div className="testing">
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            text="Profile Settings"
                            IconComponent={ProfileSettingsIcon}
                        />
                    </div>
                )
            }
        ];
        const bottomItems = [
            {
                className: "mobile-account-status-page-side-item",
                onItemClick: () => this.onItemClick("/status-page"),
                path: "/status-page",
                element: (
                    <div className="testing">
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            IconComponent={ServerCheckIcon}
                            text="Status Page"
                        />
                    </div>
                )
            },
            {
                className: "mobile-account-logout-side-item",
                onItemClick: () => this.onItemClick("/signout"),
                path: "/signout",
                element: (
                    <div className="testing">
                        <StyledButton
                            className={"atlas-side-item-styled-button"}
                            IconComponent={PowerOffIcon}
                            text="Logout"
                        />
                    </div>
                )
            }
        ];

        const items = [topItems, middleItems, bottomItems];

        this.setState({ items });
    }

    private onItemClick(pathname: string) {
        if (this.props.history.location.pathname.includes(pathname)) {
            return;
        }
        const { changesMade, onUpdateNavPath, onNavigate, onToggleMobileSideBar, mobileSideBarActive, history } =
            this.props;

        if (changesMade) {
            onUpdateNavPath(pathname, true);
        } else {
            onNavigate(pathname, history);
        }

        if (mobileSideBarActive) {
            onToggleMobileSideBar();
        }
    }

    private onProfileClick() {
        const { showProfile } = this.state;
        this.setState({ showProfile: !showProfile }, () => {
            this.renderItems(this.props);
        });
    }

    public render() {
        const { className, mobileActive, isMobileSize, history } = this.props;
        const { items } = this.state;

        const classes = "sidebar";

        if (history.location.pathname.includes("signin") || history.location.pathname.includes("login")) {
            return null;
        }

        return (
            <StyledNavBar
                className={classNames(classes, className)}
                mobileActive={mobileActive}
                history={history}
                items={items}
                isMobileSize={isMobileSize}
                scrollGroup={isMobileSize ? 1 : undefined}
                type={StyledNavbarTypes.sidebar}
            />
        );
    }

    private onToggleAccount() {
        this.setState({ showProfile: !this.state.showProfile }, () => {
            this.renderItems(this.props);
        });
    }
}

const mapStateToProps = (state: AppState) => {
    const { global, userSettings, accountSettings, notifications, conversations } = state;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;
    const { isMobileSize, mobileSideBarActive, changesMade } = global;
    const { unreadNotificationsCounter } = notifications;
    const { conversationsCounter } = conversations;

    const { privileges } = userSettings;

    return {
        privileges,
        changesMade,
        currentPlan,
        userSettings,
        isMobileSize,
        mobileSideBarActive,
        unreadNotificationsCounter,
        conversationsCounter
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onToggleProfileStatus: userSettingsActions.onToggleProfileStatus,
            onToggleMobileSideBar: globalActions.onToggleMobileSideBar,
            onUpdateNavPath: globalActions.onUpdateNavPath,
            onNavigate: globalActions.onNavigate,
            setDefaultState: conversationsActions.setDefaultState
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBar));
