import { axiosInstance } from "../actions";

import { getConfig } from "../common";
import {
    AccountSettingsBody,
    AccountSettingsJurisdictionBody,
    AccountSettingsMapLayersBody,
    AccountSettingsTeamAgentBody,
    UserInAccountSettingsWithRole,
    UserInAccountSettingsWithId,
    AccountSettingsRoleBody,
    AccountSettingsUserWithStatusBody,
    AccountSettingsDetailsResponse,
    AccountSettingsJurisdictionResponse,
    AccountSettingsMapLayersResponse,
    AccountSettingsUsersResponse,
    AccountSettingsRolesResponse,
    AccountSettingsPrivilegesResponse,
    AccountSettingsRolesPatchResponse,
    AccountSettingsTeamAgentPatchResponse,
    AccountSettingsDetailsPatchResponse,
    UserInAccountSettingsPostResponse,
    AccountSettingsUserByIdPatchResponse,
    AccountSettingsUserByIdResponse,
    AccountSettingsRoleByIdResponse,
    AccountSettingsRolePostResponse,
    AccountSettingsUsersParams,
    AccountSettingsRolesRequestParams,
    AccountSettingsActivitiesResponse,
    AccountSettingsActivitiesParams,
    AccountSettingsActivitiesByUserIdParams,
    AccountSettingsActivitiesByUserIdResponse,
    GetAccountSettingsMapLayersParams
} from "./accountSettingsApi";
import { AxiosResponse, CancelToken } from "axios";
import { GeoJSONObject } from "@turf/helpers";

export async function getAccountSettingsDetailsRequest(): Promise<AxiosResponse<AccountSettingsDetailsResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/account`);
}

export async function patchAccountSettingsDetailsRequest(
    body: AccountSettingsBody
): Promise<AxiosResponse<AccountSettingsDetailsPatchResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/account`, body);
}

export async function getAccountSettingsJurisdictionRequest(): Promise<
    AxiosResponse<AccountSettingsJurisdictionResponse>
> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/account/geo/jurisdiction`);
}

/**
 * TO DO: Response for patchAccountSettingsJurisdictionRequest
 */
export async function patchAccountSettingsJurisdictionRequest(body: AccountSettingsJurisdictionBody) {
    const config = getConfig();

    return await axiosInstance.patch(`${config.API_URL}/account/geo/jurisdiction`, body);
}

export async function getAccountSettingsMapLayersRequest({
    limit,
    offset,
    sortTitle,
    q
}: GetAccountSettingsMapLayersParams): Promise<AxiosResponse<AccountSettingsMapLayersResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/account/geo/layers`, {
        params: { limit, offset, order: `updated_at:${sortTitle}`, q }
    });
}

/**
 * TO DO: Response for postAccountSettingsMapLayersRequest
 */
export async function postAccountSettingsMapLayersRequest(body: AccountSettingsMapLayersBody) {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/account/geo/layers/create`, body);
}

/**
 * TO DO: Response for deleteAccountSettingsMapLayersRequest
 */
export async function deleteAccountSettingsMapLayersRequest(id: string) {
    const config = getConfig();
    return await axiosInstance.delete(`${config.API_URL}/account/geo/layers/${id}`);
}

export const getMapLayerDetails = async (id: string) => {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/account/geo/layers/${id}`);
};

export async function patchAccountSettingsTeamAgentRequest(
    body: AccountSettingsTeamAgentBody
): Promise<AxiosResponse<AccountSettingsTeamAgentPatchResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/account/team_agent`, body);
}

export const getAccountSettingsUsersRequest = async (
    props?: AccountSettingsUsersParams
): Promise<AxiosResponse<AccountSettingsUsersResponse>> => {
    const config = getConfig();
    let params = {};
    if (props) {
        const { limit, offset, q, filter, with_invited, order } = props;
        params = {
            limit,
            offset,
            order,
            q,
            filter,
            with_invited
        };
    }
    return await axiosInstance.get(`${config.API_URL}/account/users`, { params });
};

export async function postUserInAccountSettingsRequest(
    body: UserInAccountSettingsWithRole | UserInAccountSettingsWithId
): Promise<AxiosResponse<UserInAccountSettingsPostResponse>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/account/users/create`, body);
}

export async function getAccountSettingsUserByIdRequest(
    id: string
): Promise<AxiosResponse<AccountSettingsUserByIdResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/account/users/${id}`);
}

export async function patchAccountSettingsUserByIdRequest(
    id: string,
    body: UserInAccountSettingsWithRole | UserInAccountSettingsWithId | AccountSettingsUserWithStatusBody
): Promise<AxiosResponse<AccountSettingsUserByIdPatchResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/account/users/${id}`, body);
}

export async function patchAccountSettingsRoleUsersRequest(
    params: { roleId: string },
    body: { users_ids: Array<number> }
): Promise<AxiosResponse<any>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/roles/${params.roleId}/users`, body);
}

export async function postAccountSettingsRoleUserRequest(params: {
    roleId: string;
    userId: string;
}): Promise<AxiosResponse<any>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/roles/${params.roleId}/users/${params.userId}`);
}

export async function deleteAccountSettingsRoleUserRequest(params: {
    roleId: string;
    userId: string;
}): Promise<AxiosResponse<any>> {
    const config = getConfig();
    return await axiosInstance.delete(`${config.API_URL}/roles/${params.roleId}/users/${params.userId}`);
}

export async function postAccountSettingsResendInvitationRequest(id: string): Promise<AxiosResponse<any>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/account/users/${id}/resend_invitation`);
}

export async function getAccountSettingsRolesRequest({
    limit,
    offset,
    sortTitle,
    query
}: AccountSettingsRolesRequestParams): Promise<AxiosResponse<AccountSettingsRolesResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/roles`, {
        params: {
            limit,
            offset,
            order: `title:${sortTitle}`,
            q: query
        }
    });
}

export async function getAccountSettingsRoleByIdRequest(
    id: string
): Promise<AxiosResponse<AccountSettingsRoleByIdResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/roles/${id}`);
}

export async function postAccountSettingsRoleRequest(
    body: AccountSettingsRoleBody
): Promise<AxiosResponse<AccountSettingsRolePostResponse>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/roles/create`, body);
}

export async function patchAccountSettingsRoleByIdRequest(
    id: string,
    body: AccountSettingsRoleBody
): Promise<AxiosResponse<AccountSettingsRolesPatchResponse>> {
    const config = getConfig();
    return await axiosInstance.patch(`${config.API_URL}/roles/${id}`, body);
}

/**
 * TO DO: Response for deleteAccountSettingsRoleByIdRequest
 */
export async function deleteAccountSettingsRoleByIdRequest(id: string) {
    const config = getConfig();
    return await axiosInstance.delete(`${config.API_URL}/roles/${id}`);
}

export async function getAccountSettingsPrivilegesRequest(): Promise<AxiosResponse<AccountSettingsPrivilegesResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/privileges`);
}

export async function getAccountSettingsActivitiesRequest({
    limit,
    offset,
    from,
    to,
    types,
    users
}: AccountSettingsActivitiesParams): Promise<AxiosResponse<AccountSettingsActivitiesResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/activities`, {
        params: { limit, offset, from, to, types, users }
    });
}

export async function getAccountSettingsActivityTypesRequest(): Promise<AxiosResponse<Array<string>>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/activities/types`);
}

export async function getAccountSettingsActivitiesByUserIdRequest({
    id,
    limit,
    offset,
    from,
    to,
    types
}: AccountSettingsActivitiesByUserIdParams): Promise<AxiosResponse<AccountSettingsActivitiesByUserIdResponse>> {
    const config = getConfig();
    return await axiosInstance.get(`${config.API_URL}/account/users/${id}/activity`, {
        params: { limit, offset, from, to, types }
    });
}

export async function postCheckIntersectionRequest(
    geometry: GeoJSONObject,
    cancelToken: CancelToken
): Promise<AxiosResponse<{ intersections: Array<GeoJSONObject> }>> {
    const config = getConfig();
    return await axiosInstance.post(
        `${config.API_URL}/account/geo/jurisdiction/intersection`,
        {
            geometry
        },
        { cancelToken }
    );
}

export async function postCheckIntersectionWithGeoFenceRequest(
    geoFence: GeoJSONObject,
    geometry: GeoJSONObject
): Promise<AxiosResponse<{ intersections: Array<GeoJSONObject> }>> {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/account/geo/intersection`, {
        geometry_a: geoFence,
        geometry_b: geometry
    });
}

export const getSettingsCountries = async () => {
    return await axiosInstance.get("/settings/countries", {
        transformResponse: (data) => {
            const parsed = JSON.parse(data);
            parsed.countries = parsed.countries.map((item) => {
                return {
                    value: item.code,
                    label: item.country
                };
            });
            return parsed;
        }
    });
};

export const getApiLog = async (params) => {
    return await axiosInstance.get("/account/settings/api/requests", {
        params
    });
};
