// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text, Classes as BPClasses } from "@blueprintjs/core";

import { INavigationListItem } from "./types";

import "./styles.scss";
import { Classes } from "src/mpd-library";

export interface INavigationListItemsProps extends IProps {
    activeIndex?: number;
    title?: string;
    items: Array<INavigationListItem | string>;
    onItemClick(item: string, index: number): void;
    loading: boolean;
}

export const NavigationListItems: React.FunctionComponent<INavigationListItemsProps> = (props) => {
    const { activeIndex, className, items, onItemClick, title, loading } = props;

    const classes = "navigation-list-items";
    return (
        <div className={classNames(classes, className)}>
            {!!items?.length && title && (
                <Text className={classes + "-heading"}>
                    {" "}
                    <span className={loading ? BPClasses.SKELETON : ""}>{title}</span>{" "}
                </Text>
            )}
            {!!items?.length &&
                items.map((item: any, index: number) => (
                    <div
                        className={classNames(
                            classes + "-inner-container",
                            typeof activeIndex !== "undefined" &&
                                (activeIndex === index || activeIndex === item?.id) &&
                                Classes.ACTIVE,
                            item === "loading" && BPClasses.DISABLED
                        )}
                        key={index}
                    >
                        <hr />
                        {item?.element !== undefined ? (
                            <div
                                className={classes + "-item"}
                                onClick={() => onItemClick(item?.title?.toLocaleLowerCase(), index)}
                            >
                                {" "}
                                {item?.element}{" "}
                            </div>
                        ) : (
                            <div
                                className={classes + "-item"}
                                onClick={() =>
                                    onItemClick(
                                        typeof item === "string" ? item?.toLocaleLowerCase() : item?.value,
                                        index,
                                        item
                                    )
                                }
                            >
                                {" "}
                                <span className={item === "loading" ? BPClasses.SKELETON : ""}>
                                    {item?.title || item}{" "}
                                </span>
                                {typeof item.count !== undefined && <span className={"counter"}>{item.count}</span>}
                            </div>
                        )}
                        {/* <MPDButton className={classes + "-dots"} name="dots-active" /> */}
                    </div>
                ))}
        </div>
    );
};

export default NavigationListItems;
