// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { Redirect, Route, Switch } from "react-router-dom";

import RoleSettingsAdd from "./role-settings-add/role-settings-add";
import RoleSettingsEdit from "./role-settings-edit/role-settings-edit";

import { IDetailView } from "../../../";

import { Classes, MPDScroll, MPDTree } from "src/mpd-library";

import { BreadCrumbs, ContainerEmpty, SimpleList, StyledButton, StyledButtonTypes } from "src/components";

import { SettingsGroupsListItem } from "../../components";

import "./styles.scss";
import { AppState, AccountSettingsGeneralCurrentRole, AccountSettingsState } from "src/store";
import { RouteComponentProps } from "react-router";
import { AccountRole, AccountSettingsRolesRequestExtendingParams } from "src/requests";
import { bindActionCreators, Dispatch } from "redux";
import {
    addRole,
    getAccountSettingsRoles,
    initiateRoles,
    removeRole,
    viewRole
} from "src/actions/account-settings/account-settings-api";
import { ReactComponent as GroupBlueIcon } from "../../../../mpd-library/icon/assets/group-blue.svg"
import { ReactComponent as DownArrowIcon } from "../../../../mpd-library/icon/assets/down-arrow.svg"

export interface IRoleSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    currentRole: AccountSettingsGeneralCurrentRole;
    routeProps: RouteComponentProps;
    rolesLoading: boolean;
    isFullRolesList: boolean;
    onViewRole: (id: string, routeProps: RouteComponentProps) => void;
    onAddRole: (routeProps: RouteComponentProps) => void;
    onInitiateRoles: (id: string, params: AccountSettingsRolesRequestExtendingParams) => void;
    onRemoveRole: (role: AccountRole) => void;
    onGetAccountSettingsRoles: (params: AccountSettingsRolesRequestExtendingParams) => void;
}

interface IRoleSettingsState {
    listHeaderChecked: boolean;
    filter?: string;
    sort?: Array<string> | string;
}

const pathname = "/account-settings/roles";

export class RoleSettings extends React.Component<IRoleSettingsProps, IRoleSettingsState> {
    public constructor(props: IRoleSettingsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.renderMain = this.renderMain.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onAddRole = this.onAddRole.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.renderList = this.renderList.bind(this);
        this.updateToolBar();

        this.state = {
            listHeaderChecked: false,
            filter: ""
        };
    }

    public componentDidMount() {
        const { routeProps, accountSettings } = this.props;
        const id = globalActions.getSelectedId(routeProps);
        if (!accountSettings.general.roles.list.length) {
            this.props.onInitiateRoles(id, { sortTitle: "asc" });
        }
    }

    public componentDidUpdate(prevProps: IRoleSettingsProps) {
        const { routeProps } = this.props;
        if (
            prevProps.routeProps.location.pathname !== routeProps.location.pathname &&
            pathname === routeProps.location.pathname
        ) {
            this.updateToolBar();
        }
    }

    public render() {
        const { routeProps, currentRole } = this.props;
        const classes = "role-settings";
        return (
            <React.Fragment>
                <Switch location={routeProps.history.location}>
                    <Route exact={true} path={"/account-settings/roles"} render={() => this.renderMain(classes)} />
                    <MPDScroll className={classes + "-inner-container"} yDirection={true}>
                        {currentRole === null && (
                            <Redirect from="/account-settings/roles/edit" to="/account-settings/roles" />
                        )}
                        <Route
                            exact={true}
                            path={`/account-settings/roles/add`}
                            render={() => (
                                <RoleSettingsAdd
                                    currentRole={currentRole}
                                    routeProps={routeProps}
                                    onCloseRightPanel={this.props.onCloseRightPanel}
                                    onToggleRightPanel={this.props.onToggleRightPanel}
                                    onUpdateChangesMade={this.props.onUpdateChangesMade}
                                    title="New Role"
                                />
                            )}
                        />
                        <Route
                            path={`/account-settings/roles/edit/`}
                            render={() => (
                                <RoleSettingsEdit
                                    routeProps={routeProps}
                                    onCloseRightPanel={this.props.onCloseRightPanel}
                                    onToggleRightPanel={this.props.onToggleRightPanel}
                                    onUpdateChangesMade={this.props.onUpdateChangesMade}
                                    currentRole={currentRole}
                                />
                            )}
                        />
                    </MPDScroll>
                </Switch>
            </React.Fragment>
        );
    }

    private onGetListItems = (params: AccountSettingsRolesRequestExtendingParams) => {
        const { rolesLoading, onGetAccountSettingsRoles, isFullRolesList } = this.props;

        if (!rolesLoading && !isFullRolesList) {
            onGetAccountSettingsRoles(params);
        }
    };

    private renderMain(classes: string) {
        const { listHeaderChecked, filter } = this.state;
        const {
            className,
            accountSettings,
            isOrSmallerTabletPortraitSize,
            rolesLoading,
            onInitiateRoles,
            routeProps,
            isFullRolesList
        } = this.props;
        const list = accountSettings.general.roles.list;
        const id = globalActions.getSelectedId(routeProps);
        return (
            <div className={classNames(classes, className)}>
                {!list || (list.length === 0 && !rolesLoading && !filter) ? (
                    <ContainerEmpty
                        className={classNames(classes + "-empty", Classes.CONTAINER_PADDING)}
                        title="No Roles Found"
                        subtitle="Looks like you haven’t added any roles yet. Click on the “Add Role” button above to add your first role."
                        IconComponent={GroupBlueIcon}
                    />
                ) : (
                    <SimpleList
                        isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                        isListFullyLoaded={isFullRolesList}
                        list={list}
                        loading={rolesLoading && !list.length}
                        showOptions={listHeaderChecked}
                        placeholder="Search roles…"
                        hideCheckMarkSelect={true}
                        sort="title"
                        listId="rolesList"
                        onGetListItems={this.onGetListItems}
                        onGetInitalList={(params: AccountSettingsRolesRequestExtendingParams) =>
                            onInitiateRoles(id, params)
                        }
                        handleFilterChange={this.handleFilterChange}
                        renderedComponent={this.renderList}
                        loadingMoreComp={
                            <MPDTree
                                NodeItem={SettingsGroupsListItem}
                                contents={["", "", "", "", ""] as any}
                                loading={true}
                            />
                        }
                    />
                )}
            </div>
        );
    }

    private renderList(props: any) {
        return (
            <React.Fragment>
                {props.list?.map((element: any, index: number) => (
                    <SettingsGroupsListItem
                        className={"role-settings-settings-groups-list-item"}
                        onSelectChanged={(e) => this.onSelectChanged(e, index)}
                        onCheckChanged={(e) => this.onCheckChanged(e, index)}
                        active={true}
                        loading={props.loading}
                        ArrowIconComponent={DownArrowIcon}
                        options={["edit", "delete"]}
                        checked={element.checked}
                        key={index}
                        title={element.title}
                        onClick={() => this.onViewRole(index)}
                    />
                ))}
                {/* {rolesLoading && (
                    <MPDTree NodeItem={SettingsGroupsListItem} contents={["", "", "", "", ""] as any} loading={true} />
                )} */}
            </React.Fragment>
        );
    }

    private handleFilterChange = (filter: string) => {
        this.setState({ ...this.state, filter });
    };

    private onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
        const filter = event.currentTarget.value;
        this.setState({ filter });
    }

    private onSelectChanged(event: any, index: number) {
        const { accountSettings, routeProps } = this.props;
        const list = accountSettings.general.roles.list;
        const role = list[index];
        const value = event.currentTarget.textContent.toLowerCase();
        if (value === "delete") {
            this.props.onRemoveRole(role);
        } else if (value === "edit") {
            const id = role.id;
            this.props.onViewRole(id, routeProps);
        }
    }

    private onViewRole(index: number) {
        const { routeProps, accountSettings } = this.props;
        const id = accountSettings.general.roles.list[index].id;
        this.props.onViewRole(id, routeProps);
    }

    private onCheckChanged() {
        // const { accountSettings } = this.state;
        // const list = accountSettings.general.roles.list;
        // list[index].checked = !list[index].checked;
        // accountSettings.general.roles.showListDetails = false;
        // if(list.some((element: any) => element.checked)) {
        //     accountSettings.general.roles.showListDetails = true;
        // }
        // if(list.every((element: any) => element.checked)) {
        //     this.setState({ listHeaderChecked: true })
        // }
        // else if(list.every((element: any) => !element.checked)) {
        //     this.setState({ listHeaderChecked: false })
        // }
        // this.setState({ accountSettings });
    }

    private updateToolBar() {
        const { routeProps } = this.props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Roles", pathname: "/account-settings/roles" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: <StyledButton type={StyledButtonTypes.primary} text="Add Role" onClick={this.onAddRole} />
        };
        this.props.onUpdateToolBar(topBarProps);
    }

    private onAddRole() {
        const { routeProps } = this.props;
        this.props.onAddRole(routeProps);
    }
}

const mapStateToProps = (state: AppState) => {
    const {
        accountSettings,
        accountSettings: {
            general: {
                roles: { rolesLoading, isFullRolesList, currentRole }
            }
        },
        global: { isOrSmallerTabletPortraitSize }
    } = state;

    return {
        accountSettings,
        currentRole,
        rolesLoading,
        isOrSmallerTabletPortraitSize,
        isFullRolesList
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onAddRole: addRole,
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onInitiateRoles: initiateRoles,
            onGetAccountSettingsRoles: getAccountSettingsRoles,
            onViewRole: viewRole,
            onRemoveRole: removeRole
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RoleSettings);
