// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";

export interface ISettingsDataExportSettingsListItemProps extends IProps {
    date: string;
    exportedBy: string;
    exportID: string;
    fileName: string;
    fileSize: string;
    result: string;
    status: string;
    time: string;
}

export const SettingsDataExportSettingsListItem: React.FunctionComponent<ISettingsDataExportSettingsListItemProps> = (
    props
) => {
    const { className, date, exportedBy, exportID, fileName, fileSize, result, status, time } = props;
    const classes = "settings-data-export-settings-list-item";

    return (
        <div className={classNames(classes + "-container", className)}>
            <div className={classNames(classes, "export")}>
                <React.Fragment>{fileName}</React.Fragment>
                <div>
                    <Text className="date-time">
                        {" "}
                        {date} at {time}
                    </Text>
                </div>
            </div>
            <div className={classNames(classes + "-inner-container")}>
                <Text className={classNames(classes, "file-size")}>{fileSize}</Text>
                <Text className={classNames(classes, "export-id")}>{exportID}</Text>
                <Text className={classNames(classes, "status")}>{status}</Text>
                <Text className={classNames(classes, "result")}>{result}</Text>
                <Text className={classNames(classes, "exported-by")}>{exportedBy}</Text>
            </div>
        </div>
    );
};

export default SettingsDataExportSettingsListItem;
