import React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";

import { getConfig } from "src/common";
import { axiosInstance } from "src/actions";

// import "./styles.scss";
import { IToastType, ToastTypes } from "src/components";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";

interface CahnnelInstallCallbackProps {
    routeProps: RouteComponentProps;
    accountType: "facebook" | "twitter" | "instagram";
    onUpdateToast: (toast: IToastType | ToastTypes) => void;
}

interface CahnnelInstallCallbackState {
    creatingChannelProcessing: boolean;
    error: boolean;
}

export default class ChannelInstallCallback extends React.Component<
    CahnnelInstallCallbackProps,
    CahnnelInstallCallbackState
> {
    public state = {
        creatingChannelProcessing: true,
        error: false
    };

    public componentDidMount() {
        this.createChannel();
    }

    public getAccountTypeBody = () => {
        const { routeProps } = this.props;
        const code = routeProps.location.search.match(/code=([^&]*)/);
        const body = {
            code: code && code[1]
        };

        return body;
    };

    public createChannel = async () => {
        const { accountType, onUpdateToast } = this.props;
        try {
            const config = getConfig();
            const body = this.getAccountTypeBody();

            await axiosInstance.post(`${config.API_URL}/channels/${accountType}/create`, body);

            this.setState({ creatingChannelProcessing: false });
            const toast: IToastType = {
                value: `Success! ${accountType} channel was created`,
                type: ToastTypes.saved
            };
            onUpdateToast(toast);
        } catch (err) {
            const toast: IToastType = {
                value: `Something get wrong. Try again a bit later`,
                type: ToastTypes.error
            };
            onUpdateToast(toast);
            this.setState({
                error: true
            });
        }
    };

    public render() {
        const { creatingChannelProcessing, error } = this.state;
        const { accountType } = this.props;
        if (creatingChannelProcessing && !error) {
            return <PageLoadingSpinner />;
        }
        if (error) {
            return <Redirect to="/account-settings/channels" />;
        }
        return <Redirect to={`/account-settings/channels/${accountType}`} />;
    }
}
