// tslint:disable jsx-no-lambda
import { FC, SyntheticEvent, useEffect, useRef, useState, FunctionComponent, SVGProps } from "react";
import { Dialog, IProps, Position, Text } from "@blueprintjs/core";
import { Popover2, Classes } from "@blueprintjs/popover2";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import * as MPDClasses from "../";
import { StyledButton, StyledButtonTypes } from "src/components";
import { MPDIcon } from "../";
import "./styles.scss";
import { ReactComponent as TickIcon } from "../icon/assets/tick.svg";
import { ReactComponent as LeftArrowBlackIcon } from "../icon/assets/left-arrow-black.svg";
import { ReactComponent as DropdownArrowBlackIcon } from "src/mpd-library/icon/assets/down-arrow.svg";

interface IMPDSelect {
    arrowIcon?: string | null;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    ArrowIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>> | boolean;
    leftIcon?: string;
    leftIconElement?: JSX.Element;
    defaultValue?: string;
    value?: string;
    placeholder?: string;
    emptyElement?: JSX.Element;
    nullText?: string;
    disabled?: boolean;
    stopPropagation?: boolean;
    stopScrolling?: boolean;
    inputWithSearch?: boolean;
    modifiers: any;
}

export interface IMPDSelectProps extends IMPDSelect, IProps {
    popOverClassName?: string;
    options?: Array<any>;
    customElement?: any;
    customOptions?: JSX.Element;
    cannotChange?: boolean;
    label?: string;
    defaultOptionText?: string;
    usePortal?: boolean;
    position?: Position;
    title?: string;
    notShowArrow?: boolean;
    isOpenFromParent?: boolean;
    portalClassName?: string;
    minimized?: boolean;
    popoverClassName?: string;
    error?: string;
    showErrorText?: boolean;
    menuClassName?: string;
    idToJump?: string;
    hasBackdrop?: boolean;
    disableArrowAnimation?: boolean;
    onChange?: (event: any, time?: string) => void;
    onToggleOptions?: (optionsOpen: boolean) => void;
    onMouseDown?: (e: SyntheticEvent) => void;
    onMouseEnter?: (e?: SyntheticEvent) => void;
    onMouseLeave?: (e?: SyntheticEvent) => void;
    LeftIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    ArrowIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const MPDSelectTargetBorderRoundingClasses = {
    rounded: "rounded-dropdown",
    semiRounded: "semi-rounded-dropdown"
};

const classes = MPDClasses.SELECT;

export const MPDSelect: FC<IMPDSelectProps> = ({
    className,
    disabled,
    placeholder,
    emptyElement,
    // customElement,
    customOptions,
    label,
    defaultOptionText,
    // stopScrolling,
    leftIconElement,
    position,
    title,
    isOpenFromParent,
    notShowArrow,
    usePortal,
    portalClassName,
    minimized,
    popoverClassName,
    error,
    menuClassName,
    showErrorText = true,
    idToJump,
    modifiers,
    options,
    leftIcon,
    LeftIconComponent,
    ArrowIconComponent,
    // arrowIcon = "downArrow",
    value: valueFromProps,
    hasBackdrop = true,
    stopPropagation,
    defaultValue,
    cannotChange,
    disableArrowAnimation,
    onToggleOptions: onToggleOptionsFromParent,
    onChange,
    onMouseEnter,
    onMouseLeave
}: IMPDSelectProps) =>
    // ref
    {
        const selectRef = useRef(null);
        const [optionsOpen, setOptionsOpen] = useState(false);
        const [value, setValue] = useState(defaultValue);

        useEffect(() => {
            addEventListener("resize touchmove", (event) => {
                // console.log("RESIZE >>>>>", event);
            });
        }, []);

        // const onBackDropClick = () => {
        //     setOptionsOpen(false);
        // };

        const isSmallWindowInnerHeight = () => {
            return window.innerHeight < 800;
        };

        const onToggleOptions = (event: any, setOption?: boolean) => {
            if (event && stopPropagation) {
                event.stopPropagation();
            }

            if (disabled) {
                return;
            }

            if (onToggleOptionsFromParent) {
                onToggleOptionsFromParent(setOption === undefined ? !isOpenFromParent : setOption);
            } else {
                setOptionsOpen(setOption === undefined ? !optionsOpen : setOption);
            }
            isSmallWindowInnerHeight() && getElementByIdAndJump(idToJump);
        };

        const onOptionItemClick = async (event: SyntheticEvent, option) => {
            if (option?.onClick) {
                option.onClick();
            }
            event.currentTarget.textContent = event.currentTarget.textContent.trim();

            if (stopPropagation) {
                event.stopPropagation();
            }

            const newValue = event.currentTarget.textContent ? event.currentTarget.textContent : defaultValue;

            if (newValue !== value && onChange) {
                await onChange(event, newValue);
            }

            if (!cannotChange) {
                setValue(newValue);
            }

            onToggleOptions(event, false);
            setOptionsOpen(false);
        };

        const getElementByIdAndJump = (id: string) => {
            const elem = document.getElementById(id);
            if (elem) {
                setTimeout(() => elem.scrollIntoView({ block: "start", behavior: "auto" }), 300);
            }
        };

        if (isMobile) {
            return (
                <>
                    <div
                        className={classNames(
                            `${classes}-target-wrapper`,
                            title && `${classes}-target-wrapper_with-title`
                        )}
                    >
                        <div
                            className={classNames(
                                classes,
                                disabled && MPDClasses.DISABLED,
                                (isOpenFromParent === undefined ? optionsOpen : isOpenFromParent) && MPDClasses.ACTIVE,
                                value && MPDClasses.FULL,
                                minimized && `${classes}-minimized`,
                                className,
                                error && MPDClasses.ERROR
                            )}
                            onClick={onToggleOptions}
                            ref={selectRef}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        >
                            {title && !minimized && (
                                <div
                                    className={classNames(
                                        `${classes}-title`,
                                        (isOpenFromParent === undefined ? optionsOpen : isOpenFromParent)
                                            ? "active-title"
                                            : "",
                                        error && "error-title",
                                        disabled && MPDClasses.DISABLED
                                    )}
                                >
                                    {title}
                                </div>
                            )}
                            <div className={"mpd-select-left-wrapper"}>
                                {(leftIcon || LeftIconComponent) && (
                                    <MPDIcon
                                        className={classNames(classes + "-left-icon", minimized && "with-only-icon")}
                                        name={leftIcon}
                                        IconComponent={LeftIconComponent}
                                    />
                                )}
                                {leftIconElement}
                                {placeholder && (
                                    <span className={classes + "-placeholder"} tabIndex={-1}>
                                        {placeholder}
                                    </span>
                                )}
                                {!!value || !!valueFromProps
                                    ? null
                                    : defaultOptionText &&
                                      !minimized && (
                                          <div className={classes + "-label"} onClick={onToggleOptions}>
                                              {" "}
                                              {defaultOptionText}{" "}
                                          </div>
                                      )}
                                {(valueFromProps || value) && !minimized && (
                                    <div
                                        className={classNames(classes + "-label", disabled && MPDClasses.DISABLED)}
                                        onClick={onToggleOptions}
                                    >
                                        {" "}
                                        {valueFromProps || value}{" "}
                                    </div>
                                )}
                                {label && !minimized && <span>{label}</span>}
                            </div>
                            {ArrowIconComponent && !notShowArrow && !minimized && (
                                <MPDIcon
                                    className={classNames(
                                        classes + "-arrow-icon",
                                        disableArrowAnimation && "mpd-select-arrow-icon_animation-disabled"
                                    )}
                                    IconComponent={
                                        typeof ArrowIconComponent === "boolean"
                                            ? DropdownArrowBlackIcon
                                            : ArrowIconComponent
                                    }
                                />
                            )}
                        </div>
                        {error && showErrorText && (
                            <Text className={classNames(classes + "-error")}>
                                {error.charAt(0).toUpperCase() + error.slice(1)}
                            </Text>
                        )}
                    </div>
                    <Dialog
                        isOpen={isOpenFromParent === undefined ? optionsOpen : isOpenFromParent}
                        portalClassName={"mpd-select-modal"}
                    >
                        <div
                            className={classNames(MPDClasses.SELECT_OPTIONS, !customOptions && "options-wrapper")}
                            // style={{
                            // minWidth: selectRef.current ? selectRef.current.clientWidth : "initial"
                            // }}
                        >
                            <div className={"back-button-wrapper"}>
                                <StyledButton
                                    type={StyledButtonTypes.primarySimple}
                                    IconComponent={LeftArrowBlackIcon}
                                    className={"back-button"}
                                    onClick={(e) => onToggleOptions(e, false)}
                                />
                            </div>
                            {customOptions ? (
                                customOptions
                            ) : (
                                <div className={"just-options"}>
                                    {options && options.length > 0 ? (
                                        options.map((option, index) => (
                                            <div
                                                className={classNames(
                                                    MPDClasses.SELECT_OPTION,
                                                    option === value ? MPDClasses.SELECTED : ""
                                                )}
                                                key={index}
                                                onClick={onOptionItemClick}
                                            >
                                                {" "}
                                                {typeof option === "string" ? option : option.name}{" "}
                                                {option === value ? (
                                                    <MPDIcon
                                                        className={MPDClasses.SELECT + "-icon"}
                                                        IconComponent={TickIcon}
                                                    />
                                                ) : null}
                                            </div>
                                        ))
                                    ) : emptyElement ? (
                                        emptyElement
                                    ) : (
                                        <div className={MPDClasses.SELECT_EMPTY}> No results </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Dialog>
                </>
            );
        }

        return (
            <div id={idToJump} className={"select-main-wrapper"}>
                <Popover2
                    modifiers={modifiers}
                    isOpen={isOpenFromParent === undefined ? optionsOpen : isOpenFromParent}
                    position={position || Position.TOP}
                    canEscapeKeyClose={true}
                    usePortal={usePortal || false}
                    hasBackdrop={hasBackdrop}
                    enforceFocus={false}
                    onClose={(e) => onToggleOptions(e, false)}
                    interactionKind={"click"}
                    portalClassName={portalClassName}
                    portalContainer={document.body}
                    transitionDuration={300}
                    className={popoverClassName}
                    popoverClassName={classNames(Classes.POPOVER2, menuClassName)}
                    backdropProps={
                        {
                            // onClick: onBackDropClick,
                        }
                    }
                    content={
                        <div
                            className={classNames(MPDClasses.SELECT_OPTIONS, !customOptions && "options-wrapper")}
                            style={{
                                minWidth: selectRef.current ? selectRef.current.clientWidth : "initial"
                            }}
                        >
                            {customOptions ? (
                                customOptions
                            ) : (
                                <div className={"just-options"}>
                                    {options && options.length > 0 ? (
                                        options.map((option, index) => (
                                            <div
                                                className={classNames(
                                                    MPDClasses.SELECT_OPTION,
                                                    option === value ? MPDClasses.SELECTED : ""
                                                )}
                                                key={index}
                                                onClick={onOptionItemClick}
                                            >
                                                {" "}
                                                {option}{" "}
                                                {option === value ? (
                                                    <MPDIcon
                                                        className={MPDClasses.SELECT + "-icon"}
                                                        IconComponent={TickIcon}
                                                    />
                                                ) : null}
                                            </div>
                                        ))
                                    ) : emptyElement ? (
                                        emptyElement
                                    ) : (
                                        <div className={MPDClasses.SELECT_EMPTY}> No results </div>
                                    )}
                                </div>
                            )}
                        </div>
                    }
                >
                    <div
                        className={classNames(
                            `${classes}-target-wrapper`,
                            title && `${classes}-target-wrapper_with-title`
                        )}
                    >
                        <div
                            className={classNames(
                                classes,
                                disabled && MPDClasses.DISABLED,
                                (isOpenFromParent === undefined ? optionsOpen : isOpenFromParent) && MPDClasses.ACTIVE,
                                value && MPDClasses.FULL,
                                minimized && `${classes}-minimized`,
                                className,
                                error && MPDClasses.ERROR
                            )}
                            onClick={onToggleOptions}
                            ref={selectRef}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        >
                            {title && !minimized && (
                                <div
                                    className={classNames(
                                        `${classes}-title`,
                                        (isOpenFromParent === undefined ? optionsOpen : isOpenFromParent)
                                            ? "active-title"
                                            : "",
                                        error && "error-title",
                                        disabled && MPDClasses.DISABLED
                                    )}
                                >
                                    {title}
                                </div>
                            )}
                            <div className={"mpd-select-left-wrapper"}>
                                {(leftIcon || LeftIconComponent) && (
                                    <MPDIcon
                                        className={classNames(classes + "-left-icon", minimized && "with-only-icon")}
                                        name={leftIcon}
                                        IconComponent={LeftIconComponent}
                                    />
                                )}
                                {leftIconElement}
                                {placeholder && (
                                    <span className={classes + "-placeholder"} tabIndex={-1}>
                                        {placeholder}
                                    </span>
                                )}
                                {!!value || !!valueFromProps
                                    ? null
                                    : defaultOptionText &&
                                      !minimized && (
                                          <div className={classes + "-label"} onClick={onToggleOptions}>
                                              {" "}
                                              {defaultOptionText}{" "}
                                          </div>
                                      )}
                                {(valueFromProps || value) && !minimized && (
                                    <div
                                        className={classNames(classes + "-label", disabled && MPDClasses.DISABLED)}
                                        onClick={onToggleOptions}
                                    >
                                        {" "}
                                        {valueFromProps || value}{" "}
                                    </div>
                                )}
                                {label && !minimized && <span>{label}</span>}
                            </div>
                            {ArrowIconComponent && !notShowArrow && !minimized && (
                                <MPDIcon
                                    className={classNames(
                                        classes + "-arrow-icon",
                                        disableArrowAnimation && "mpd-select-arrow-icon_animation-disabled"
                                    )}
                                    IconComponent={
                                        typeof ArrowIconComponent === "boolean"
                                            ? DropdownArrowBlackIcon
                                            : ArrowIconComponent
                                    }
                                />
                            )}
                        </div>
                        {error && showErrorText && (
                            <Text className={classNames(classes + "-error")}>
                                {error.charAt(0).toUpperCase() + error.slice(1)}
                            </Text>
                        )}
                    </div>
                </Popover2>
            </div>
        );
    };
