// tslint:disable jsx-no-lambda
import { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { globalActions, mapActions } from "src/actions";

import { Route, Switch } from "react-router-dom";

import { ToastTypes, IToastType } from "src/components";

import { IDetailView } from "src/containers";

import { BreadCrumbs, ContainerEmpty, StyledButton, StyledButtonTypes } from "src/components";

import "./styles.scss";
import { AppState, JurisdictionFeature } from "src/store";
import { RouteComponentProps } from "react-router";
import { ShapeCoords } from "src/requests";
import {
    createAccountSettingsMapLayers,
    getAccountSettingsJurisdiction,
    getAccountSettingsMapLayers,
    GetAccountSettingsMapLayersProps,
    removeAccountSettingsMapLayers,
    searchAccountSettingsMapLayers,
    SearchAccountSettingsMapLayersProps,
    selectMapLayerById,
    SelectMapLayerByIdProps
} from "src/actions/account-settings/account-settings-api";
import { bindActionCreators, Dispatch } from "redux";
import StyledMap from "src/components/styled-map";
import { ReactComponent as LayersBlueIcon } from "../../../../mpd-library/icon/assets/layers-blue.svg";

interface IMapLayersSettingsProps extends IDetailView {
    center: ShapeCoords;
    mapLayers: Array<any>;
    routeProps: RouteComponentProps;
    isMapLayersFull: boolean;
    searchMapLayers: Array<any>;
    isSearchMapLayersFull: boolean;
    loadingMapLayers?: boolean;
    jurisdiction: JurisdictionFeature;
    onGetAccountSettingsMapLayers: (params: GetAccountSettingsMapLayersProps) => void;
    onSearchAccountSettingsMapLayers: (params: SearchAccountSettingsMapLayersProps) => void;
    onCreateAccountSettingsMapLayers: (currentShape: any) => void;
    onRemoveAccountSettingsMapLayer: (mapLayer: any) => void;
    onGetAccountSettingsJurisdiction: () => void;
    onSelectMapLayerById: (props: SelectMapLayerByIdProps) => void;
    onUpdateToast: (toast: IToastType | ToastTypes) => void;
    changeMapLayersListVisibility: (status: boolean) => void;
}

interface IMapLayersSettingsState {
    shapeEditable: boolean;
    mapLayerOption: string;
    addLayer: boolean;
    currentShape?: any;
    toggleList?: boolean;
    // listActive: boolean;
}

class MapLayersSettings extends Component<IMapLayersSettingsProps, IMapLayersSettingsState> {
    constructor(props: IMapLayersSettingsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.onAddLayerToList = this.onAddLayerToList.bind(this);
        this.onRemoveLayerFromList = this.onRemoveLayerFromList.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
        this.onShapeComplete = this.onShapeComplete.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onAddLayer = this.onAddLayer.bind(this);

        this.state = {
            shapeEditable: false,
            addLayer: false,
            mapLayerOption: "drawLayer"
        };

        this.updateToolBar(this.props);
        props.changeMapLayersListVisibility(true);
    }

    public componentDidMount() {
        const { onGetAccountSettingsJurisdiction, jurisdiction, mapLayers, onGetAccountSettingsMapLayers } = this.props;
        if (mapLayers.length === 0) {
            onGetAccountSettingsMapLayers({ isRefresh: true });
        }

        if (!jurisdiction) {
            onGetAccountSettingsJurisdiction();
        }
    }

    public componentDidUpdate(prevProps: IMapLayersSettingsProps) {
        if (this.props.routeProps !== prevProps.routeProps) {
            this.updateToolBar(this.props);
        }
    }

    public componentWillUnmount() {
        this.props.onSelectMapLayerById({ id: null });
    }

    public render() {
        const {
            className,
            mapLayers,
            onSelectMapLayerById,
            routeProps,
            center,
            searchMapLayers,
            loadingMapLayers,
            jurisdiction,
            onUpdateToast,
            isMobileSize
        } = this.props;
        const { addLayer, currentShape } = this.state;
        const classes = "map-layers-settings";
        const drawingMode = addLayer ? "polygon" : null;

        return (
            <div className={classNames(classes, className)}>
                {loadingMapLayers ? (
                    <div className={classes + "-loading"} />
                ) : (
                    <Switch location={routeProps.history.location}>
                        <Route
                            exact={true}
                            path={"/account-settings/map-layers/"}
                            render={() =>
                                mapLayers.length === 0 && !loadingMapLayers && !addLayer ? (
                                    <ContainerEmpty
                                        className={classes + "-empty"}
                                        title={`No Layers Found`}
                                        subtitle={
                                            "Looks like you haven’t added any map layers  yet. Click on the “New Layer” button above to create your first layer."
                                        }
                                        IconComponent={LayersBlueIcon}
                                    />
                                ) : (
                                    <StyledMap
                                        showInfoBox={true}
                                        showZoomControls={true}
                                        showGps={true}
                                        showDrawControls={addLayer}
                                        showListToggle={!addLayer}
                                        showList={true}
                                        center={center}
                                        geoJsonData={currentShape}
                                        listActive={!isMobileSize}
                                        onSelectMapLayerById={onSelectMapLayerById}
                                        searchLayers={searchMapLayers}
                                        drawingMode={drawingMode}
                                        showUploadFile={addLayer}
                                        onShapeComplete={this.onShapeComplete}
                                        onRemoveLayerFromList={this.onRemoveLayerFromList}
                                        onAddLayerToList={this.onAddLayerToList}
                                        onScrollBottom={this.onScrollBottom}
                                        onSearchScrollBottom={(query: string) => this.onSearchScrollBottom(query)}
                                        onSearchAccountSettingsMapLayers={(query: string) =>
                                            this.onSearchAccountSettingsMapLayers(query)
                                        }
                                        loadingMapLayers={loadingMapLayers}
                                        jurisdictionGeoJsonData={jurisdiction}
                                        zoom={10}
                                        onUpdateToast={onUpdateToast}
                                    />
                                )
                            }
                        />
                    </Switch>
                )}
            </div>
        );
    }

    private onShapeComplete() {
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    }

    private onUploadFile(event: any) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => {
            let currentShape = reader.result;
            if (typeof currentShape === "string") {
                currentShape = JSON.parse(currentShape);
            }
            this.setState({ currentShape }, () => {
                this.updateToolBar(this.props);
            });
        };
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    }

    private async onAddLayerToList(currentShape: any) {
        const addedLayer = await this.props.onCreateAccountSettingsMapLayers(currentShape);
        this.setState({ addLayer: false }, () => {
            this.updateToolBar(this.props);
        });

        return addedLayer;
    }

    private onRemoveLayerFromList(mapLayer: any) {
        this.setState({ currentShape: null }, () => {
            this.props.onRemoveAccountSettingsMapLayer(mapLayer);
        });
    }

    private onCancelClick() {
        const { onUpdateChangesMade } = this.props;
        this.onToggle(false);

        if (onUpdateChangesMade) {
            onUpdateChangesMade(false);
        }
    }

    private onAddLayer() {
        if (this.state.currentShape === undefined) {
            this.setState({ currentShape: null });
        } else if (this.state.currentShape === null) {
            this.setState({ currentShape: undefined });
        }
        this.onToggle(true);
    }

    private onToggle(status: boolean) {
        const { changeMapLayersListVisibility } = this.props;
        this.setState({ addLayer: status }, () => {
            this.updateToolBar(this.props);
            changeMapLayersListVisibility(!status);
        });
    }

    private updateToolBar() {
        const { addLayer } = this.state;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Map Layers" }]}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: !addLayer ? (
                <StyledButton text="Add Layer" type={StyledButtonTypes.primary} onClick={this.onAddLayer} />
            ) : (
                <StyledButton text="Cancel" onClick={this.onCancelClick} type={StyledButtonTypes.secondary} />
            )
        };
        if (this.props.onUpdateToolBar) {
            this.props.onUpdateToolBar(topBarProps);
        }
    }

    private onScrollBottom = () => {
        const { onGetAccountSettingsMapLayers, isMapLayersFull } = this.props;

        if (isMapLayersFull) {
            return;
        }

        onGetAccountSettingsMapLayers({ withoutLoading: true });
    };

    private onSearchAccountSettingsMapLayers = (query: string) => {
        const { onSearchAccountSettingsMapLayers } = this.props;
        onSearchAccountSettingsMapLayers({
            withoutLoading: true,
            isRefresh: true,
            query
        });
    };

    private onSearchScrollBottom = (query: string) => {
        const { onSearchAccountSettingsMapLayers, isSearchMapLayersFull } = this.props;

        if (isSearchMapLayersFull) {
            return;
        }

        onSearchAccountSettingsMapLayers({ withoutLoading: true, query });
    };
}

const mapStateToProps = (state: AppState) => {
    const {
        accountSettings: {
            alerts: { mapLayers, isMapLayersFull, searchMapLayers, isSearchMapLayersFull, loadingMapLayers },
            organization: {
                jurisdiction,
                details: { center }
            }
        }
    } = state;

    return {
        mapLayers,
        center,
        isMapLayersFull,
        searchMapLayers,
        isSearchMapLayersFull,
        loadingMapLayers,
        jurisdiction
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onCreateAccountSettingsMapLayers: createAccountSettingsMapLayers,
            onGetAccountSettingsMapLayers: getAccountSettingsMapLayers,
            onSearchAccountSettingsMapLayers: searchAccountSettingsMapLayers,
            onRemoveAccountSettingsMapLayer: removeAccountSettingsMapLayers,
            onGetAccountSettingsJurisdiction: getAccountSettingsJurisdiction,
            onSelectMapLayerById: selectMapLayerById,
            onUpdateToast: globalActions.onUpdateToast,
            changeMapLayersListVisibility: mapActions.changeMapLayersListVisibility
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapLayersSettings);
