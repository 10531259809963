import classNames from "classnames";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import styles from "./SelectedContacts.module.scss";

export const SelectedContacts = ({ selectedContacts }) => {
    return (
        <div className={classNames(DropdownTargetClasses.FORM_SELECT, styles["root"])}>
            <div className={styles["selected-contacts"]}>
                <span className={styles["selected-contacts-label"]}>CONTACTS</span>
                {selectedContacts === "all" ? (
                    <span className={styles["selected-contact"]}>All</span>
                ) : (
                    <>
                        {Object.keys(selectedContacts).map((contactId: string) => {
                            return (
                                <span
                                    className={styles["selected-contact"]}
                                >{`${selectedContacts[contactId].first_name} ${selectedContacts[contactId].last_name}`}</span>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};
