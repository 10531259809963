// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { useMemo } from "react";

import { IStyledCheckboxProps, StyledSelect, StyledSelectTypes, ImagePlusText, ImageSource } from "src/components";

import { IProps, Text } from "@blueprintjs/core";

import { Classes, MPDIcon } from "src/mpd-library";

import "./styles.scss";
import { ProfilePic } from "src/store";
import { useHistory } from "react-router";
import { MPDSelect, MPDSelectTargetBorderRoundingClasses } from "../../../../../mpd-library";
import { ReactComponent as StatusActivatedIcon } from "../../../../../mpd-library/icon/assets/status-activated.svg"
import { ReactComponent as StatusArchivedIcon } from "../../../../../mpd-library/icon/assets/status-archived.svg"
import { ReactComponent as StatusInvitedIcon } from "../../../../../mpd-library/icon/assets/status-invited.svg"
import { ReactComponent as StatusDeactivatedIcon } from "../../../../../mpd-library/icon/assets/status-deactivated.svg"
import { ReactComponent as VerticalTripleLineIcon } from "../../../../../mpd-library/icon/assets/vertical-triple-line.svg"

export interface ISettingsUsersListItemProps extends IProps {
    active?: boolean;
    checkBoxProps?: IStyledCheckboxProps;
    roles?: Array<string>;
    accountType?: string;
    firstName: string;
    lastName: string;
    profilePic: ProfilePic;
    status?: string;
    statusOptions?: Array<string>;
    loading?: boolean;
    userId: string;
    profile_pic_color?: string;
    onClick?: (event: React.SyntheticEvent) => void;
    onStatusChanged?: (event: React.SyntheticEvent) => void;
}

export const SettingsUsersListItem: React.FunctionComponent<ISettingsUsersListItemProps> = (props) => {
    const {
        className,
        active,
        loading,
        onClick,
        onStatusChanged,
        account,
        account: { id, status, first_name, last_name, roles, profile_pic, profile_pic_color }
    } = props;

    const history = useHistory();

    const onClickItem = () => {
        onClick(id);
    };

    const classes = "settings-users-list-item";

    const statusOptions = useMemo(() => {
        let statusOptions = ["invited", "activated", "deactivated", "archived"];
        if (status === "activated") {
            statusOptions = ["edit", "deactivate", "archive"];
        } else if (status === "invited") {
            statusOptions = ["edit", "resend invitation", "deactivate", "archive"];
        } else if (status === "deactivated") {
            statusOptions = ["edit", "activate", "archive"];
        }

        return statusOptions;
    }, [status]);

    const changeStatus = (event, value) => {
        onStatusChanged(event, value, account);
    };

    return (
        <div
            className={classNames(classes, active && Classes.ACTIVE, className)}
            onClick={onClickItem}
        >
            {loading ? (
                <div className={classNames(classes + "-left-loading", Classes.LIST_LOADER_ANIMATED)} />
            ) : (
                <div className={classes + "-left-wrapper"}>
                    {/* <div className={classes + '-checkbox-wrapper'}  onClick={e => e.stopPropagation()}>
                    <StyledCheckbox
                        className={classes + '-checkbox'}
                        {...checkBoxProps as any} 
                    />
                </div> */}
                    <div className={classes + "-label-wrapper"}>
                        <ImagePlusText
                            element={
                                <ImageSource
                                    initials={(first_name?.charAt(0) || "") + (last_name?.charAt(0) || "")}
                                    src={profile_pic?.url || ""}
                                    userId={id}
                                    profile_pic_color={profile_pic_color}
                                />
                            }
                            text={first_name + " " + last_name}
                        />
                    </div>
                </div>
            )}
            {loading ? (
                <div className={classNames(classes + "-right-loading", Classes.LIST_LOADER_ANIMATED)} />
            ) : (
                <div className={classes + "-" + Classes.RIGHT}>
                    {roles && (
                        <div className={classes + "-teams"}>
                            {roles.map((team: any, index: number) => (
                                <Text className={classes + "-teams-items"} key={index}>
                                    {team.title}
                                </Text>
                            ))}
                        </div>
                    )}
                    <div className={classes + "-right-wrapper"}>
                        {/* <Text className={classes + "-status-text"}> {accountType} </Text> */}
                        <div className={classes + "-status-image-wrapper"}>
                            <div className={classNames(classes + "-status-image", status)}>
                                {status === "activated" ? (
                                    <MPDIcon IconComponent={StatusActivatedIcon} />
                                ) : status === "archived" ? (
                                    <MPDIcon IconComponent={StatusArchivedIcon} />
                                ) : status === "invited" ? (
                                    <MPDIcon IconComponent={StatusInvitedIcon} />
                                ) : (
                                    <MPDIcon IconComponent={StatusDeactivatedIcon} />
                                )}
                            </div>
                        </div>
                        <div className={classes + "-select-wrapper"} onClick={(e) => e.stopPropagation()}>
                            {/* <StyledSelect
                                className={classes + "-select"}
                                popOverClassName={classes + "-select-popover"}
                                type={StyledSelectTypes.noLine}
                                onChange={changeStatus}
                                options={statusOptions}
                                cannotChange={true}
                                value={""}
                                defaultValue={status}
                            /> */}

                            <MPDSelect
                                arrowIcon={null}
                                LeftIconComponent={VerticalTripleLineIcon}
                                minimized
                                className={classNames(MPDSelectTargetBorderRoundingClasses.rounded, "more-dropdown")}
                                onChange={changeStatus}
                                options={statusOptions}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SettingsUsersListItem;
