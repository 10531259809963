import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { IProps, Text } from "@blueprintjs/core";

import { Classes, MPDIcon, MPDIOSSelectableDiv } from "src/mpd-library";

import "./styles.scss";

interface ISubscriptionAddonsBoxProps extends IProps {
    active?: string;
    name?: string;
    title?: string;
    subtitle?: string;
    content?: JSX.Element;
    onClick?(event: any): void;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export class SubscriptionAddonsBox extends React.Component<ISubscriptionAddonsBoxProps> {
    public render() {
        const { className, active, name, title, subtitle, onClick, IconComponent } = this.props;

        const classes = "subscription-addons-box";

        return (
            <MPDIOSSelectableDiv className={classNames(classes, className, active && Classes.ACTIVE)} onClick={onClick}>
                <MPDIcon className={classes + "-icon"} IconComponent={IconComponent} />
                <Text className={classes + "-title"}> {title} </Text>
                <Text className={classes + "-subtitle"}> {subtitle} </Text>
            </MPDIOSSelectableDiv>
        );
    }
}
