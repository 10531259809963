// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { Classes, IMPDFloatingTextAreaProps, MPDFloatingTextArea } from "src/mpd-library";

import { StyledTextAreaTypes } from "./types";

import { Text } from "@blueprintjs/core";

import "./styles.scss";

export interface IStyledTextAreaProps extends IMPDFloatingTextAreaProps {
    error?: string;
    type?: StyledTextAreaTypes;
    moreInfo?: string;
    growVertically?: boolean;
    showErrorText?: boolean;
}

export const StyledTextArea: React.FunctionComponent<IStyledTextAreaProps> = (props) => {
    const { className, error, showErrorText = true, type, moreInfo, ...remaining } = props;

    const classes = "styled-textarea";

    return (
        <div className={classNames(classes, type, className, error && Classes.ERROR)}>
            <MPDFloatingTextArea className={classes + "-floating-textarea"} {...remaining} />
            <Text className={classNames(classes + "-more-info", "more-info", moreInfo && Classes.ACTIVE)}>
                {moreInfo}
            </Text>
            {error && showErrorText && (
                <Text className={classNames("error", error && Classes.ACTIVE)}>
                    {error.charAt(0).toUpperCase() + error.slice(1)}
                </Text>
            )}
        </div>
    );
};
