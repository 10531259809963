import { FC, useContext, useMemo, createContext, useRef, useEffect, useLayoutEffect } from "react";
import { Classes } from "@blueprintjs/core";
import { MPDSelectItem } from "../../../select-item";
import styles from "./GroupedDropdownContent.module.scss";
import { CustomOptionsComponentProps as CustomOptionsComponentCommonProps } from "../../dropdown";
import groupBy from "lodash/groupBy";
import classNames from "classnames";
import { Option } from "../../types";

interface GroupedDropdownContentProps extends CustomOptionsComponentCommonProps {
    hasHeaderSearch?: boolean;
    firstGroupName?: string;
    secondGroupName: string;
    existingEntities?: any;
    selectedItems: { allIds: Array<any>; byId: { [key: string]: any } };
    CustomItemComponent?: FC;
}

export const GroupedDropdownContentSpecial = ({
    hasHeaderSearch,
    initialised,
    options,
    firstGroupName,
    secondGroupName,
    onCheckBoxClick,
    updateEntites
}: // selectedItems
GroupedDropdownContentProps) => {
    const {
        true: existingEntities,
        false: unselectedOptions,
        loading: loadingEntities
    } = useMemo(() => {
        return groupBy(
            options.allIds,
            (itemId) => (itemId === "loading" && "loading") || !!options.byId[itemId]?.checked
        );
    }, [options]);

    const onCheckboxClick = (entity, checked) => {
        updateEntites({ [entity.id]: { ...entity, checkedUpdated: checked } });
        onCheckBoxClick?.(entity, checked);
    };

    return (
        <>
            {firstGroupName && (
                <>
                    {!!initialised && (
                        <div
                            className={classNames(
                                styles["content-sticky-title"],
                                styles["content-sticky-title_first"],
                                hasHeaderSearch && styles["content-sticky-title_first-with-header-search"]
                            )}
                        >
                            {firstGroupName}
                        </div>
                    )}
                    {existingEntities?.map((entityId: string) => {
                        const entity = options.byId[entityId];
                        return (
                            <MPDSelectItem
                                key={entityId}
                                disabled={entity?.disabled}
                                leftIcon={entity?.leftIcon}
                                option={entity}
                                withCheckbox
                                label={entity?.name || entity?.label}
                                onCheckBoxClick={onCheckboxClick}
                                checked={entity?.checkedUpdated}
                                children={entity?.children}
                                className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
                            />
                        );
                    })}
                </>
            )}
            {!!unselectedOptions?.length && !!initialised && (
                <div
                    className={classNames(
                        styles["content-sticky-title"],
                        unselectedOptions?.length
                            ? styles["content-sticky-title_second"]
                            : hasHeaderSearch
                            ? styles["content-sticky-title_first-with-header-search"]
                            : styles["content-sticky-title_first"]
                    )}
                >
                    {secondGroupName}
                </div>
            )}
            {!!unselectedOptions?.length &&
                unselectedOptions?.map((entityId: string, index) => {
                    const entity = options.byId[entityId];
                    return (
                        <MPDSelectItem
                            disabled={entity?.disabled}
                            leftIcon={entity?.leftIcon}
                            key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
                            checked={entity?.checkedUpdated}
                            label={entity?.name || entity?.label}
                            option={entity}
                            withCheckbox
                            className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
                            onCheckBoxClick={onCheckboxClick}
                            children={entity?.children}
                        />
                    );
                })}
            {loadingEntities?.length &&
                loadingEntities.map((id, index) => {
                    return <MPDSelectItem loading key={`${id}-${index}`} className={Classes.SKELETON} />;
                })}
        </>
    );
};

type ContextType = {
    initialised: boolean;
    searchValue: string;
    onCheckboxClick: (entity: Option, checked: boolean) => void;
};

const GroupedDropdownContentContext = createContext<ContextType>({} as ContextType);

const Item = ({ entity, CustomItemComponent }) => {
    const { initialised, onCheckboxClick, searchValue }: ContextType =
        useContext<ContextType>(GroupedDropdownContentContext);
    const entityId = entity?.id;

    const commonProps = {
        LeftIconComponent: entity?.LeftIconComponent,
        disabled: entity?.disabled,
        leftIcon: entity?.leftIcon,
        checked: entity?.checkedUpdated,
        label: entity?.name || entity?.label,
        option: entity,
        withCheckbox: true,
        children: entity?.children,
        onCheckBoxClick: onCheckboxClick,
        searchValue: searchValue,
        className: entityId === "loading" || !initialised ? Classes.SKELETON : ""
    };

    if (CustomItemComponent) {
        return <CustomItemComponent {...commonProps} />;
    }
    return <MPDSelectItem {...commonProps} />;
};

export const GroupedDropdownContent = ({
    hasHeaderSearch,
    initialised,
    options,
    firstGroupName,
    secondGroupName,
    onCheckBoxClick,
    updateEntites,
    selectedItems,
    unselectedItems,
    searchValue,
    CustomItemComponent
}: GroupedDropdownContentProps) => {
    const onCheckboxClick = (entity, checked) => {
        const updated = { [entity.id]: { ...entity, checkedUpdated: checked } };
        updateEntites(updated);
        onCheckBoxClick?.({ ...entity, checkedUpdated: checked }, checked);
    };

    const contextValue = useMemo(() => {
        return {
            initialised,
            searchValue,
            onCheckboxClick
        };
    }, [initialised, onCheckboxClick, searchValue]);

    return (
        <GroupedDropdownContentContext.Provider value={contextValue}>
            <div id={"id-for-get-width"}>
                {firstGroupName && !!selectedItems?.allIds.length && (
                    <>
                        {!!initialised && (
                            <div
                                className={classNames(
                                    styles["content-sticky-title"],
                                    styles["content-sticky-title_first"],
                                    hasHeaderSearch && styles["content-sticky-title_first-with-header-search"]
                                )}
                            >
                                {firstGroupName}
                            </div>
                        )}
                        {selectedItems?.allIds?.map((entityId: string, index: number) => {
                            const entity = selectedItems.byId[entityId];
                            return (
                                <Item
                                    key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
                                    entity={entity}
                                    CustomItemComponent={CustomItemComponent}
                                />
                            );
                        })}
                    </>
                )}
                {!!unselectedItems?.allIds?.length && !!initialised && (
                    <div
                        className={classNames(
                            styles["content-sticky-title"],
                            unselectedItems?.allIds?.length && !!selectedItems?.allIds.length
                                ? styles["content-sticky-title_second"]
                                : hasHeaderSearch
                                ? styles["content-sticky-title_first-with-header-search"]
                                : styles["content-sticky-title_first"]
                        )}
                    >
                        {secondGroupName}
                    </div>
                )}
                {!!unselectedItems?.allIds.length &&
                    unselectedItems?.allIds?.map((entityId: string, index) => {
                        const entity = unselectedItems.byId[entityId];
                        return (
                            <Item
                                key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
                                entity={entity}
                                CustomItemComponent={CustomItemComponent}
                            />
                        );
                    })}
            </div>
        </GroupedDropdownContentContext.Provider>
    );
};

// export const TagsGroupedContent = ({
//     initialised,
//     options,
//     firstGroupName,
//     secondGroupName,
//     onCheckBoxClick,
//     updateEntites,
//     selectedItems: tempName,
//     unselectedItems,
//     searchValue,
//     contentBlockWidth
// }: GroupedDropdownContent) => {
//     const onCheckboxClick = (entity, checked) => {
//         const updated = { [entity.id]: { ...entity, checkedUpdated: checked } };
//         updateEntites(updated);
//         onCheckBoxClick?.({ ...entity, checkedUpdated: checked }, checked);
//     };

//     // console.log("options", options, selectedItems);

//     const selectedItems = useMemo(() => {
//         const updated = { ...tempName };
//         console.log("updated <<<<", updated);

//         if (updated.byId?.["1625f094-2bc4-4f9d-8025-945ca8b8b613"]) {
//             updated.byId["1625f094-2bc4-4f9d-8025-945ca8b8b613"].ancestors_formatted = ["Some other tips"];
//         }
//         return updated;
//     }, [tempName]);

//     console.log("selectedItems", selectedItems, unselectedItems);

//     return (
//         <>
//             {firstGroupName && !!selectedItems?.allIds.length && !searchValue && (
//                 <>
//                     {!!initialised && (
//                         <div className={styles["content-sticky-title"]} style={{ top: "89px", marginTop: "-10px" }}>
//                             {firstGroupName}
//                         </div>
//                     )}
//                     {selectedItems?.allIds?.map((entityId: string) => {
//                         const entity = selectedItems.byId[entityId];
//                         return (
//                             <MPDSelectItem
//                                 key={entity?.id}
//                                 LeftIconComponent={() => {
//                                     if (entity?.ancestors_formatted?.[0]) {
//                                         return (
//                                             <>
//                                                 <span className={styles["ancestors"]}>
//                                                     {contentBlockWidth > 320 ? (
//                                                         `${entity?.ancestors_formatted?.[0]}`
//                                                     ) : (
//                                                         <Tooltip
//                                                             position={Position.BOTTOM}
//                                                             tootltipTarget={"..."}
//                                                             content={`${entity?.ancestors_formatted?.[0]} > ${entity?.name}`}
//                                                         />
//                                                     )}
//                                                 </span>
//                                                 &gt;
//                                             </>
//                                         );
//                                     }
//                                     return null;
//                                 }}
//                                 className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
//                                 disabled={entity?.disabled}
//                                 leftIcon={entity?.leftIcon}
//                                 option={entity}
//                                 withCheckbox
//                                 label={entity?.name}
//                                 checked={entity?.checkedUpdated}
//                                 children={entity?.children}
//                                 onCheckBoxClick={onCheckboxClick}
//                                 searchValue={searchValue}
//                             />
//                         );
//                     })}
//                 </>
//             )}
//             {!!unselectedItems?.allIds?.length && !!initialised && (
//                 <div
//                     className={styles["content-sticky-title"]}
//                     style={{
//                         top: unselectedItems?.allIds?.length && !!selectedItems?.allIds.length ? "26px" : "-10px"
//                     }}
//                 >
//                     {secondGroupName}
//                 </div>
//             )}
//             {!!unselectedItems?.allIds.length &&
//                 unselectedItems?.allIds?.map((entityId: string, index) => {
//                     const entity = unselectedItems.byId[entityId];
//                     return (
//                         <MPDSelectItem
//                             key={entity.id}
//                             disabled={entity?.disabled}
//                             LeftIconComponent={!!entity?.ancestors_formatted?.length && EnterIcon}
//                             key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
//                             checked={entity?.checkedUpdated}
//                             label={entity?.name || entity?.label}
//                             option={entity}
//                             withCheckbox
//                             className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
//                             onCheckBoxClick={onCheckboxClick}
//                             children={entity?.children}
//                             searchValue={searchValue}
//                         />
//                     );
//                 })}
//         </>
//     );
// };

// export const NotGroupedDropdownContent = ({
//     initialised,
//     options,
//     firstGroupName,
//     // secondGroupName,
//     onCheckBoxClick,
//     updateEntites,
//     // selectedItems,
//     // unselectedItems,
//     searchValue
// }) => {
//     const onCheckboxClick = (entity, checked) => {
//         const updated = { [entity.id]: { ...entity, checkedUpdated: checked } };
//         updateEntites(updated);
//         onCheckBoxClick?.({ ...entity, checkedUpdated: checked }, checked);
//     };
//     return (
//         <>
//             {firstGroupName && !!options?.allIds.length && (
//                 <>
//                     {!!initialised && (
//                         <div className={styles["content-sticky-title"]} style={{ top: "90px", marginTop: "-10px" }}>
//                             {firstGroupName}
//                         </div>
//                     )}
//                     {options?.allIds?.map((entityId: string) => {
//                         const entity = options.byId[entityId];
//                         return (
//                             <MPDSelectItem
//                                 key={entity?.id}
//                                 LeftIconComponent={entity?.LeftIconComponent}
//                                 disabled={entity?.disabled}
//                                 leftIcon={entity?.leftIcon}
//                                 option={entity}
//                                 withCheckbox
//                                 label={entity?.name || entity?.label}
//                                 checked={entity?.checkedUpdated}
//                                 children={entity?.children}
//                                 onCheckBoxClick={onCheckboxClick}
//                                 searchValue={searchValue}
//                                 className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
//                             />
//                         );
//                     })}
//                 </>
//             )}
//         </>
//     );
// };
