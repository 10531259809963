import { toggleRightPanel, closeRightPanel } from "./alerts-api";

import { Dispatch } from "redux";
import { AppState, RepliesSettings } from "../../store";
import { History } from "history";
import {
    UPDATE_SELECTED_ALERT,
    SET_ALLOW_REPLIES,
    SET_ALERT_REPLIES_SETTINGS,
    SET_EVENTS_MATHING_TABLE,
    SET_EVENTS_MATCHING_TABLE_LOADING
} from "./types";
import { store } from "src";

export const onToggleRightPanel = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(toggleRightPanel());
    };
};

export const onCloseRightPanel = (pathname: string, history: History, id: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(closeRightPanel({ pathname, history, id }));
    };
};

export const updateChannel = (dataFromSocket) => {
    const selectedAlert = store.getState().alerts.alert.selectedAlert;
    if (selectedAlert) {
        const socialNetworkName = dataFromSocket.type;
        const chnnelForUpdate = selectedAlert.channels[socialNetworkName].find(
            (item: any) => item.account_channel_id === dataFromSocket.account_channel_id
        );
        if (chnnelForUpdate) {
            chnnelForUpdate.url = dataFromSocket.url;
            chnnelForUpdate.status = dataFromSocket.status;
            updateSelectedAlertAction({
                ...selectedAlert,
                channels: { ...selectedAlert.channels }
            });
        }
    }
};

export const updateSelectedAlertAction = (dataFromSocket) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        // const { uuid } = getState().accountSettings.organization.details;
        const { selectedAlert } = getState().alerts.alert;
        // const webSocketTopic = `portal_alerts:${uuid}`;

        if (selectedAlert) {
            const socialNetworkName = dataFromSocket.type;
            const chnnelForUpdate = selectedAlert.channels[socialNetworkName].find(
                (item: any) => item.account_channel_id === dataFromSocket.account_channel_id
            );
            if (chnnelForUpdate) {
                chnnelForUpdate.url = dataFromSocket.url;
                chnnelForUpdate.status = dataFromSocket.status;
                dispatch({
                    type: UPDATE_SELECTED_ALERT,
                    payload: { ...selectedAlert, channels: { ...selectedAlert.channels } }
                });
            }
        }
    };
};

export const setAllowReplies = (value: boolean) => {
    return {
        type: SET_ALLOW_REPLIES,
        payload: value
    };
};

export const setAlertRepliesSettings = (settings: RepliesSettings) => {
    return {
        type: SET_ALERT_REPLIES_SETTINGS,
        payload: settings
    };
};

export const setEventsMatchingTable = (payload) => ({
    type: SET_EVENTS_MATHING_TABLE,
    payload
});

export const setEventsMatchingTableLoading = (status: boolean) => ({
    type: SET_EVENTS_MATCHING_TABLE_LOADING,
    payload: status
});
