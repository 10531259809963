import { IToastType, ToastTypes } from "src/components";
import { updateToast } from "src/actions/global/global-api";
import { authErrorHandler, authChallengeErrorHandler, authLoginUserConfirmHandler } from "./errorHandlers/authHandlers";
import { getConfig } from "src/common";
import { userCreatingErrorHandler } from "./errorHandlers/usersHandlers";
import {
    userEmailOrPassChangeErrorHandler,
    userEmailOrPassSendForOtpErrorHandler,
    userResetPasswordErrorHandler
} from "./errorHandlers/userHandler";
import { alertComposeDataErrorHandler } from "./errorHandlers/alertsHandlers";
import { collectionDeleteErrorHandler } from "./errorHandlers/collectionHandlers";
import { updateGlobalSpinnerStatus } from "src/actions/global/global-api";
import { updateSaveStepProcessing } from "src/actions/alerts/alerts-compose-api";
import { createCardHanlder } from "./errorHandlers/cardsHandlers";
import { setAccessErrorToConversation } from "src/actions/conversations/conversations";

export const errorMiddleware = (store: any) => (next: any) => (action: any) => {
    if (action instanceof Promise) {
        return next(
            action.catch((e) => {
                console.log("myErrorHandler instanceof Promise:", e);
            })
        );
    }

    if (typeof action === "function") {
        const wrapper = async (...args: Array<any>) => {
            try {
                const res = await action(...args);
                if (res instanceof Promise) {
                    return res.catch((err) => {
                        console.log("myErrorHandler action === function:", err);
                        const toast: IToastType = {
                            value: "Something get wrong. Try again a bit later",
                            type: ToastTypes.error
                        };
                        store.dispatch(updateToast({ toast }));
                    });
                } else {
                    return res;
                }
            } catch (err) {
                const { response } = err;
                let data: any;
                if (response) {
                    data = response.data;
                    const requestUrl = response.config.url;
                    const config = getConfig();
                    if (requestUrl.includes("/conversations/snippets/create") && response.status === 422) {
                        const toasts = Object.keys(response.data.errors).map((errorKey) => {
                            return {
                                value: response.data.errors[errorKey],
                                type: ToastTypes.error
                            };
                        });

                        toasts.forEach((toast) => store.dispatch(updateToast({ toast })));
                        throw new Error();
                    }
                    if (requestUrl.includes("/conversations") && (response.status === 404 || response.status === 403)) {
                        // History.push('/conversations');
                        const convId = requestUrl.match(/[^/]+$/g)[0];
                        // const viewType = store.getState().conversations.viewType;
                        // store.dispatch(setViewType(viewType || "opened"));
                        store.dispatch(setAccessErrorToConversation(convId));
                        return;
                    }

                    // if (
                    //     requestUrl !== `${config.API_URL}/login/otp` &&
                    //     requestUrl !== `${config.API_URL}/me/email` &&
                    //     requestUrl !== `${config.API_URL}/me/phone_number` &&
                    //     requestUrl !== `${config.API_URL}/users/signup` &&
                    //     requestUrl !== `${config.API_URL}/me` &&
                    //     response.status === 401
                    // ) {
                    //     // console.log("ERROR", requestUrl, response.status);

                    //     // AUTH ERRORS
                    //     console.log("HERE I AM >>>>", requestUrl);
                    //     store.dispatch(setInitialLoadingProcessingStatus(false));
                    //     return unauthorizedErrorHandler();
                    // }

                    if (requestUrl === `${config.API_URL}/login/otp`) {
                        return authErrorHandler(store, response.status, data);
                    }

                    if (requestUrl === `${config.API_URL}/login/otp/challenge`) {
                        return authChallengeErrorHandler(store);
                    }

                    if (requestUrl === `${config.API_URL}/users/signup`) {
                        return authLoginUserConfirmHandler(store, data);
                    }

                    if (requestUrl === `${config.API_URL}/account/users/create` && response.status === 422) {
                        // USERS ERRORS
                        return userCreatingErrorHandler(store, data);
                    }

                    // USER ERRORS
                    if (
                        (requestUrl === `${config.API_URL}/me/email` && response.status === 422) ||
                        (requestUrl === `${config.API_URL}/me/phone_number` && response.status === 422)
                    ) {
                        return userEmailOrPassChangeErrorHandler(store, "password");
                    }

                    if (
                        (requestUrl === `${config.API_URL}/me/email` && response.status === 401) ||
                        (requestUrl === `${config.API_URL}/me/phone_number` && response.status === 401)
                    ) {
                        return userEmailOrPassChangeErrorHandler(store, "code");
                    }

                    if (
                        (requestUrl === `${config.API_URL}/me/email` && response.status === 400) ||
                        (requestUrl === `${config.API_URL}/me/phone_number` && response.status === 400)
                    ) {
                        return userEmailOrPassSendForOtpErrorHandler(store, data);
                    }

                    if (requestUrl === `${config.API_URL}/reset_password`) {
                        return userResetPasswordErrorHandler(store, data);
                    }

                    if (requestUrl === `${config.API_URL}/studio/cards/create`) {
                        return createCardHanlder(store, data);
                    }

                    // ALERTS ERRORS
                    if (requestUrl === `${config.API_URL}/alerts/create`) {
                        if (response.status === 422) {
                            return alertComposeDataErrorHandler(store, data.errors);
                        }
                        store.dispatch(updateSaveStepProcessing(false));
                    }
                    // COLLECTIONS ERRORS

                    if (
                        requestUrl.includes(`${config.API_URL}/studio/collections`) &&
                        response.config.method === "delete" &&
                        response.status === 422
                    ) {
                        return collectionDeleteErrorHandler(store, data);
                    }

                    if (response.status === 403) {
                        const toast: IToastType = {
                            value: "Error! Permission denied",
                            type: ToastTypes.error
                        };
                        store.dispatch(updateToast({ toast }));
                        return;
                    }

                    if (response.status === 400 && response.data.reason === "invalid_params") {
                        const toast: IToastType = {
                            value: "Wrong request parameters",
                            type: ToastTypes.error
                        };
                        store.dispatch(updateToast({ toast }));
                        return;
                    }

                    // COMMON ERRORS
                    const toast: IToastType = {
                        value: "Something get wrong. Try again a bit later",
                        type: ToastTypes.error
                    };
                    store.dispatch(updateToast({ toast }));
                    store.dispatch(updateGlobalSpinnerStatus(false));
                }
            }
        };

        return next(wrapper);
    }

    return next(action);
};
