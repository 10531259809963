// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { IProps, Text } from "@blueprintjs/core";

import { MPDIcon } from "src/mpd-library";

import "./styles.scss";

export interface IImagePlusTextProps extends IProps {
    icon?: string;
    text?: string | number;
    element?: any;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const ImagePlusText: React.FunctionComponent<IImagePlusTextProps> = (props) => {
    const { className, icon, text, element, IconComponent } = props;
    const classes = "image-plus-text";
    return (
        <div className={classNames(classes, className)}>
            {(icon || IconComponent) && (
                <div className={classes + "-image"}>
                    <MPDIcon IconComponent= {IconComponent} name={icon} className={classes + "-icon"}/>
                </div>
            )}
            {element}
            {text && <Text className={classes + "-title"}> {text} </Text>}
        </div>
    );
};

export default ImagePlusText;
