import {
    FORGOT_PASSWORD_CHANGED,
    LOGIN_STATE_ACTION,
    LOGIN_USER,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    PASSWORD_CHANGED,
    USERNAME_CHANGED,
    REMEMBER_ME_CHANGED,
    ONE_TIME_PASSWORD_CHANGED,
    AuthActionTypes,
    OTP_TOKEN_CHANGED,
    OTP_PHONE_NUMBER_CHANGED,
    OTP_EMAIL_CHANGED,
    OTP_CHANNEL_CHANGED
} from "../actions";
import { AuthState, defaultAuthState } from "../store";

export const authReducer = (state: AuthState = defaultAuthState, action: AuthActionTypes) => {
    switch (action.type) {
        case FORGOT_PASSWORD_CHANGED:
            return { ...state, forgotPasswordValue: action.payload };
        case PASSWORD_CHANGED:
            return { ...state, passwordValue: action.payload };
        case ONE_TIME_PASSWORD_CHANGED:
            return { ...state, oneTimePasswordValue: action.payload };
        case OTP_TOKEN_CHANGED:
            return { ...state, otpTokenValue: action.payload };
        case OTP_EMAIL_CHANGED:
            return { ...state, otpEmail: action.payload };
        case OTP_PHONE_NUMBER_CHANGED:
            return { ...state, otpPhoneNumber: action.payload };
        case OTP_CHANNEL_CHANGED:
            return { ...state, selectedOTPChannel: action.payload };
        case USERNAME_CHANGED:
            return { ...state, usernameValue: action.payload };
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_ERROR:
            return { ...state, error: action.payload, loading: false };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                authenticated: true,
                error: "",
                loading: false
            };
        case LOGIN_STATE_ACTION:
            return { ...state, error: "", loginState: action.payload };
        case REMEMBER_ME_CHANGED:
            return { ...state, rememberMe: action.payload };

        case LOGOUT_USER:
            return { ...state, ...defaultAuthState };
        default:
            return state;
    }
};
