import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Classes, Position, Text } from "@blueprintjs/core";
import groupBy from "lodash/groupBy";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToast, onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import { BreadCrumbs, EmptyBlock, StyledButton, StyledButtonTypes } from "src/components";
import { ShadowedListItem } from "src/components/shadowed-settings-list-item/shadowed-settings-list-item";
import styles from "./styles.module.scss";
import { Tooltip2 } from "@blueprintjs/popover2";
import { TOOLTIP_DELAY } from "../../../../../conversations/constants";
import { SmartLink, updateSmartLink } from "../../../../../../requests/smartLinksRequests";
import { SmartLinkItemInfo } from "../smart-link-item-info/smart-link-item-info";
import classNames from "classnames";
import { StyledSwitch } from "../../../../../../components";
import { ReactComponent as SmartLinksEmptyIcon } from "../../../../../../mpd-library/icon/assets/smart-link-empty.svg"
import { ReactComponent as CopyIcon } from "../../../../../../mpd-library/icon/assets/copy.svg"

const GROUP_KEYS = ["true", "false", "loading"];

const classname = "smart-links-list";
const smartLinkStatusMatch = {
    true: "ACTIVE SMART LINKS",
    false: "DEACTIVE SMART LINKS"
};

type SmartLinksListProps = {
    smartLinks: { allIds: Array<string>; byId: { [key: string]: SmartLink } };
    setSelectedSmartLink: (smartLink: SmartLink) => void;
    onSmartLinkUpdate: (smartLink: SmartLink) => void;
};

export const SmartLinksList = ({ smartLinks, setSelectedSmartLink, onSmartLinkUpdate }: SmartLinksListProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [processingSmartLinkId, setProcessingSmartLinkId] = useState<string | null>(null);

    const groupedSmartLinks = useMemo(() => {
        return groupBy(smartLinks.allIds, (id: string) => {
            if (id === "loading") {
                return "loading";
            }
            return smartLinks.byId[id]?.enabled;
        });
    }, [smartLinks]);

    useEffect(() => {
        updateToolBar();
    }, []);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Smart Links" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            ),
            rightElement: (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primary}
                    text={"New Smart Link"}
                    onClick={() => history.push("/account-settings/smart-links/create")}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    const onListItemClick = (smartLinkId: string) => {
        history.push(`/account-settings/smart-links/edit/${smartLinkId}`);
    };

    const onToggleSmartLinkSwitch = async (checked: boolean, smartLink: SmartLink) => {
        try {
            const smartLinkId = smartLink.id;
            setProcessingSmartLinkId(smartLinkId);
            const res = await updateSmartLink(smartLinkId, { enabled: checked });
            onSmartLinkUpdate(res.data.smart_link);
            dispatch(
                onUpdateToast({
                    type: "saved",
                    value: `Smart Link was successfully ${checked ? "enabled" : "disabled"}`
                })
            );
        } catch (err) {
            dispatch(onUpdateToast({ type: "error", value: "Something get wrong. Try again a bit later" }));
            onSmartLinkUpdate({ ...smartLink, enabled: !checked });
        } finally {
            setProcessingSmartLinkId(null);
        }
    };

    const onCopyClick = (e: SyntheticEvent<HTMLButtonElement>, link: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(link);
        dispatch(onUpdateToast({ type: "saved", value: "Link was successfully copied" }));
    };

    return (
        <div className={styles[classname]}>
            {smartLinks.allIds.length === 0 && (
                <EmptyBlock
                    title={"Looks like you haven’t created any Smart Links yet"}
                    subtitle={
                        "Smart Links allow you to structure and categorize incoming conversations from the public. Click on the “New Smart Link” button above to get started."
                    }
                    IconComponent={SmartLinksEmptyIcon}
                />
            )}
            {GROUP_KEYS.map((key: any) => {
                if (!groupedSmartLinks[key]) return null;
                return (
                    <div className={styles[`${classname}-group`]}>
                        <Text className={styles[`${classname}-group-title`]}>{smartLinkStatusMatch[key]}</Text>
                        {groupedSmartLinks[key]?.map((smartLinkId) => {
                            const smartLink = smartLinks.byId[smartLinkId];
                            const loading = smartLinkId === "loading";
                            const { name, thumbnail_color, enabled, id, public_url } =
                                smartLinks.byId[smartLinkId] || {};
                            return (
                                <ShadowedListItem
                                    disabled={processingSmartLinkId && processingSmartLinkId !== id}
                                    key={id}
                                    title={
                                        <div
                                            className={classNames(
                                                styles[`${classname}-name`],
                                                loading && Classes.SKELETON
                                            )}
                                        >
                                            {name}
                                        </div>
                                    }
                                    subtitle={
                                        <div
                                            className={classNames(
                                                styles[`${classname}-link-wrapper`],
                                                loading && Classes.SKELETON
                                            )}
                                        >
                                            <a
                                                target={"_blank"}
                                                onClick={(e) => e.stopPropagation()}
                                                href={public_url}
                                                className={styles[`${classname}-link`]}
                                            >
                                                {public_url}
                                            </a>
                                            <Tooltip2
                                                portalClassName={"mpd-simple-tooltip"}
                                                content={"Copy Link"}
                                                className={styles[`${classname}-tooltip-copy-button`]}
                                                position={Position.BOTTOM}
                                                placement={"bottom"}
                                                hoverOpenDelay={TOOLTIP_DELAY}
                                            >
                                                <StyledButton
                                                    type={StyledButtonTypes.primarySimple}
                                                    IconComponent={CopyIcon}
                                                    className={styles[`${classname}-copy-button`]}
                                                    onClick={(e) => onCopyClick(e, public_url)}
                                                />
                                            </Tooltip2>
                                        </div>
                                    }
                                    customLeftIconComponent={
                                        <div
                                            className={classNames(
                                                styles[`${classname}-item-circle`],
                                                loading && Classes.SKELETON
                                            )}
                                            style={{ backgroundColor: thumbnail_color }}
                                        >
                                            <Text className={styles[`${classname}-item-circle-initials`]}>
                                                {name?.charAt(0).toUpperCase()}
                                            </Text>
                                        </div>
                                    }
                                    isSwitchChecked={enabled}
                                    customRightElement={!loading ? <SmartLinkItemInfo smartLink={smartLink} /> : null}
                                    onToggleSwitch={
                                        !loading
                                            ? async (checked: boolean) =>
                                                  await onToggleSmartLinkSwitch(checked, smartLink)
                                            : undefined
                                    }
                                    onClick={() => {
                                        setSelectedSmartLink(smartLink);
                                        onListItemClick(smartLink.id);
                                    }}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};
