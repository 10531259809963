import { memo, useEffect, useMemo } from "react";
import { object, string } from "yup";
import { StyledButton, StyledButtonTypes } from "../../../../../components";
import { DialogStepper } from "../../../../../components/dialog-stpper/DialogStepper";
import { CreateListContactsPreview } from "../CreateListContactsPreview/CreateListContactsPreview";
import { ListDetails } from "../ListDetails/ListDetails";
import { FormikConfig } from "formik";
import styles from "./CreateNewContactsListStepper.module.scss";
import { ContactsContextProvider } from "../../../../contacts/contacts-list/context";
import { createContactsList } from "../../../../../requests/contactsRequests";

const VALIDATION_SCHEMA = [
    object().shape({
        name: string().required("File is required")
    }),
    object().shape({
        filters: object().nullable()
    })
];

// const STEPS_FIELDS_MATCH_INDEX = {
// name: 2
// };

const FORMIK_CONFIG: Partial<FormikConfig<any>> = {
    initialValues: {
        name: null,
        type: "static"
    },
    validationSchema: VALIDATION_SCHEMA
};

export const CreateNewContactsListStepper = memo(({ setListRefresh }) => {
    const stepsPanels = useMemo(() => {
        return [
            {
                title: "Enter list details",
                stepLabel: "details",
                component: ListDetails
            },
            {
                stepLabel: "contacts",
                getTitle: (formik) => {
                    return (
                        <div className={styles["title"]}>
                            <div>{formik.values.name}</div>
                            <div className={styles["list-type"]}>{`${formik.values?.type} list`}</div>
                        </div>
                    );
                },
                subtitle: "Start adding filters below to create your contact list",
                component: CreateListContactsPreview
            }
        ];
    }, []);

    const submit = async (values) => {
        try {
            const params = {
                name: values.name,
                filters: values.filters,
                type: values.type
            };
            await createContactsList(params);
        } finally {
            setListRefresh(true);
        }
    };

    return (
        <ContactsContextProvider>
            <DialogStepper
                stepsPanels={stepsPanels}
                title={"Create a List"}
                formikConfig={FORMIK_CONFIG}
                renderTarget={(onModaOpen) => (
                    <StyledButton
                        className="actions-button"
                        type={StyledButtonTypes.primary}
                        text={"Create List"}
                        onClick={onModaOpen}
                    />
                )}
                submit={submit}
            />
        </ContactsContextProvider>
    );
});
