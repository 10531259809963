import { FC, createContext, useCallback, useContext, useMemo, useEffect, useState, useRef } from "react";
import "@blueprintjs/table/src/table.scss";
import { TableVirtuoso } from "react-virtuoso";
import omit from "lodash/omit";
import classNames from "classnames";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import styles from "./ContactsList.module.scss";
import { EmptyBlock, IStyledAlertProps, StyledAlert, StyledAlertTypes, StyledButtonTypes } from "../../../components";
import { ContactsListHeader, ContactsListItem } from "./components/contacts-list-item/contacts-list-item";
import { ContactsSearchSort } from "./components/contacts-search-sort";
import { useDataFetch } from "../../../hooks/useDataFetch";
import { deleteContacts, deleteView, getContactsFilters, getContactsRequest } from "../../../requests/contactsRequests";
import { CreateContactForm } from "../components/create-contact-form/create-contact-form";
import { ContactsFilters, EditFiltersButtonProps } from "../../../components/contacts-filters/ContactsFilters";
import { ContactsDetails } from "./components/contact-details/ContactDetails";
import { convertFiltersCallback } from "../../../components/contacts-filters/utils";
import { SummaryContext } from "../contacts";
import { ReactComponent as NoSearchResultsForIcon } from "src/mpd-library/icon/assets/NoSearchResultsFor.svg";
import { ReactComponent as ContactsEmptyTwoPersonsIcon } from "src/mpd-library/icon/assets/ContactsEmptyTwoPersons.svg";
import { ContactsContext } from "./context";

import { ReactComponent as FiltersEmptyIcon } from "src/mpd-library/icon/assets/filterEmpty.svg";
import { onUpdateToast } from "src/actions/global/global";

type ContactsListProps = {
    confirmDeletingAlertProps: IStyledAlertProps;
    contactsList: Array<any>;
    // shouldSetFiltersToUrl?: boolean;
    className?: string;
    listItemConfig: { [key: string]: boolean };
    showListIfFilterAppleid?: boolean;
    disableFilters?: boolean;
    onUserSelect: (value: any) => void;
    filtersProps: {
        editView?: (value: any) => void;
        saveView?: (value: any) => void;
        editFiltersButtonProps: {
            defaultText: string;
            editModeText: string;
        };
        disableFilters?: boolean;
    };
    setParentFilter?: (value: any) => void;
};

export const Classes = {
    PRIMARY: styles["primary"],
    PREVIEW: styles["preview"]
};

// type FiltersType = Array<
//     | {
//           type: "is" | "is_not" | "starts_with" | "ends_with" | "contains" | "not_contains" | "unknown" | "any" | "";
//           valueKey:
//               | "name"
//               | "email"
//               | "phone"
//               | "tags"
//               | "created_date"
//               | "updated_date"
//               | "contacted_date"
//               | "address"
//               | "postal_code"
//               | "city"
//               | "state";
//           value?: string;
//           iconName: string;
//       }
//     | {
//           type: "logical";
//           value: "and" | "or";
//       }
// >;

type FilterConditionType = {
    field: string;
    comparison: "eq" | "neq" | "starts_with" | "ends_with" | "contains" | "not_contains" | "is_null" | "is_not_null";
    value: string | { [key: string]: string };
};

type FiltersType = {
    operator: "and" | "or";
    conditions: Array<{
        operator: "and" | "or";
        conditions: Array<FilterConditionType>;
    }>;
};

export const INCREASE_VIEWPORT_BY = { top: 1000, bottom: 1000 };

export const SCROLL_VELOCITY_CONFIG = {
    enter: (velocity) => Math.abs(velocity) > 500,
    exit: (velocity) => Math.abs(velocity) < 200,
    change: (_, range) => console.log({ range })
};

const FETCH_DATA_PARAMS_INIT_VALUE = { order: "first_name:asc" };

export const ContactsListContext = createContext({});

export const ContactsList: FC<ContactsListProps> = ({
    // shouldSetFiltersToUrl,
    confirmDeletingAlertProps,
    className = Classes.PRIMARY,
    isCreateNewContactPopupOpen,
    setIsCreateNewContactPopupOpen,
    listItemConfig,
    showListIfFilterAppleid = false,
    setFiltersToParent,
    optionsForSelectedContacts,
    filtersProps,
    onSetContactsSettings,
    contactsSettings,
    setParentFilter
}: ContactsListProps): JSX.Element => {
    const [contactListForceReload, setContactsListForceReload] = useState<boolean>(false);
    // const [selectedContacts, setSelectedContacts] = useState<{ [key: string]: { [key: string] } } | "all">({});
    // const [fetchDataParams, setFetchDataParams] = useState<{ [key: string]: any }>({ order: "first_name:asc" });
    const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
    const [contactsFiltersList, setContactsFiltersList] = useState<Array<any>>([]);
    const { setSummary, summary } = useContext(SummaryContext);
    const {
        setSavedView,
        setPresettedFilter,
        presettedFilter,
        savedView,
        deletableContacts,
        setDeletableContacts,
        selectedContacts,
        setSelectedContacts
    } = useContext(ContactsContext);
    const history = useHistory();
    const filtersRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedContacts({});
        if (presettedFilter) {
            setFiltersCommon(presettedFilter);
        }
    }, [presettedFilter]);

    const {
        entities: contacts,
        initialised,
        searchValue,
        count,
        filters,
        deleteEntity,
        setFilters,
        setAtBottomOfList,
        setSearchValue,
        updateEntites,
        setFetchDataParams
    } = useDataFetch({
        fetchDataRequest: getContactsRequest,
        entityKey: "contacts",
        skeletonLoading: true,
        listItemHeight: 60,
        refresh: contactListForceReload,
        fetchDataParams: FETCH_DATA_PARAMS_INIT_VALUE,
        // shouldSetFiltersToUrl,
        initialFilters: presettedFilter
    });

    useEffect(() => {
        if (JSON.stringify(filters) !== JSON.stringify(filtersRef.current)) {
            setParentFilter?.(filters);
            filtersRef.current = filters;
        }
    }, [filters]);

    const setFiltersCommon = (value) => {
        setFilters(value);
        setFiltersToParent?.(value);
        setSelectedContacts({});
    };

    const onDeleteView = useCallback(
        async (viewId: string) => {
            await deleteView(viewId);
            setSummary({ ...summary, views: summary?.views.filter((view: any) => view.id !== viewId) });
            setFiltersCommon(null);
            setSavedView(null);
            history.push(`/contacts`);
        },
        [summary]
    );

    const selectedContact = useMemo(() => {
        if (selectedContactId) {
            return contacts.byId[selectedContactId];
        }
    }, [selectedContactId, contacts.byId[selectedContactId]]);

    useEffect(() => {
        if (contactListForceReload) {
            setContactsListForceReload(false);
        }
    }, [contactListForceReload]);

    const onGetContactsFilters = async () => {
        const res = await getContactsFilters();
        setContactsFiltersList(res.data.filters.map(convertFiltersCallback));
    };

    useEffect(() => {
        onGetContactsFilters();
    }, []);

    const onContactWasCretaed = () => {
        setContactsListForceReload(true);
    };

    const onSelectContact = (e, contact) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedContacts({ ...(selectedContacts !== "all" ? selectedContacts : {}), [contact.id]: contact });
        } else {
            setSelectedContacts(omit(selectedContacts !== "all" ? selectedContacts : {}, contact.id));
        }
    };

    const onSelectAll = useCallback(
        (value) => {
            setSelectedContacts(value ? "all" : {});
        },
        [selectedContacts]
    );

    const onCloseContactDetails = () => {
        setSelectedContactId(null);
    };

    const onDeleteContact = useCallback(async () => {
        const ids = deletableContacts !== "all" ? deletableContacts : null;
        await deleteContacts({ ids, filters });
        deleteEntity(deletableContacts);
        if (deletableContacts.length > 1) {
            setSelectedContacts({});
        } else {
            setSelectedContacts(omit(selectedContacts, deletableContacts[0]));
        }
        setDeletableContacts(null);
        setContactsListForceReload(true);
        dispatch(onUpdateToast({ type: "saved", value: "Tag was successfully removed" }));
    }, [selectedContacts, contacts, deletableContacts]);

    const onDeleteContactFromList = (ids) => {
        deleteEntity(ids);
    };

    if (!filters && !searchValue && initialised && contacts.allIds.length === 0 && contactsFiltersList.length) {
        return (
            <>
                <EmptyBlock
                    title={"No contacts found"}
                    subtitle={"Add a new contact or import contacts above"}
                    IconComponent={ContactsEmptyTwoPersonsIcon}
                />
                <CreateContactForm
                    isOpen={isCreateNewContactPopupOpen}
                    setIsOpen={setIsCreateNewContactPopupOpen}
                    onContactWasCretaed={onContactWasCretaed}
                />
            </>
        );
    }

    return (
        <ContactsListContext.Provider value={{ updateContacts: updateEntites }}>
            <ContactsDetails user={selectedContact} onCloseClick={onCloseContactDetails} onDelete={onDeleteContact} />
            <StyledAlert onConfirm={onDeleteContact} {...confirmDeletingAlertProps} />
            <div className={classNames(styles["contacts-list"], className)}>
                {initialised && (
                    <header className={styles["header"]}>
                        <ContactsFilters
                            presettedFilter={presettedFilter}
                            appliedFilters={filters}
                            setPresettedFilter={setPresettedFilter}
                            setAppliedFilters={setFiltersCommon}
                            filtersList={contactsFiltersList}
                            savedView={savedView}
                            deleteSavedView={onDeleteView}
                            {...filtersProps}
                        />
                        {className === Classes.PRIMARY && (
                            <ContactsSearchSort
                                filters={filters}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                setFilters={setFiltersCommon}
                                count={count}
                                onSortChange={setFetchDataParams}
                                selectedContacts={selectedContacts}
                                presettedFilter={presettedFilter}
                                setPresettedFilter={setPresettedFilter}
                                optionsForSelectedContacts={optionsForSelectedContacts}
                                onDeleteContactFromList={onDeleteContactFromList}
                                setContactsListForceReload={setContactsListForceReload}
                                contactsSettings={contactsSettings}
                                onSetContactsSettings={onSetContactsSettings}
                            />
                        )}
                    </header>
                )}
                <CreateContactForm
                    isOpen={isCreateNewContactPopupOpen}
                    setIsOpen={setIsCreateNewContactPopupOpen}
                    onContactWasCretaed={onContactWasCretaed}
                />
                <div className={styles["list-wrapper"]}>
                    {filters && (
                        <div className={styles["preview-warning"]}>
                            <div className={styles["preview-warning_bold"]}>This is only a preview of your list.</div>
                            <div className={styles["preview-warning_thin"]}>
                                Save your list to begin processing the full results.
                            </div>
                        </div>
                    )}
                    {showListIfFilterAppleid && !filters && (
                        <EmptyBlock
                            IconComponent={FiltersEmptyIcon}
                            title={"Add filters to start building your list"}
                            subtitle={"Your filtered results will appear here"}
                        />
                    )}
                    {((showListIfFilterAppleid && filters) || !showListIfFilterAppleid) && (
                        <>
                            <TableVirtuoso
                                style={{ height: contacts.allIds.length === 0 ? "60px" : "100%", minHeight: "60px" }}
                                className={classNames(
                                    contacts.allIds.length === 0 && styles["no-results"],
                                    styles["contacts-list-table"]
                                )}
                                initialItemCount={30}
                                fixedItemHeight={60}
                                data={contacts.allIds}
                                itemContent={(index, data, ...args) => {
                                    return (
                                        <ContactsListItem
                                            listItemConfig={listItemConfig}
                                            index={index}
                                            data={contacts.byId[data]}
                                            checked={selectedContacts[data] || selectedContacts === "all"}
                                            onSelectContact={onSelectContact}
                                            onUserSelect={setSelectedContactId}
                                            onDelete={onDeleteContact}
                                            checkboxDisabled={selectedContacts === "all"}
                                        />
                                    );
                                }}
                                fixedHeaderContent={() =>
                                    initialised ? (
                                        <ContactsListHeader
                                            listItemConfig={listItemConfig}
                                            onSelectAll={onSelectAll}
                                            indeterminate={!isEmpty(selectedContacts) && selectedContacts !== "all"}
                                            checked={selectedContacts === "all"}
                                            disableCheckbox={!count}
                                        />
                                    ) : null
                                }
                                atBottomStateChange={setAtBottomOfList}
                                components={{
                                    ScrollSeekPlaceholder: ({ index, data }) => (
                                        <ContactsListItem loading index={index} listItemConfig={listItemConfig} />
                                    )
                                }}
                                increaseViewportBy={INCREASE_VIEWPORT_BY}
                                scrollSeekConfiguration={SCROLL_VELOCITY_CONFIG}
                            />
                            {contacts.allIds.length === 0 && (
                                <EmptyBlock
                                    title={filters ? `No users match filters` : `No results for ‘${searchValue}’`}
                                    subtitle={filters ? "" : "Please try another query"}
                                    IconComponent={NoSearchResultsForIcon}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </ContactsListContext.Provider>
    );
};
