import { confirmUser, updateChanges, uploadLogo } from "./user-onboarding-api";
import { Dispatch } from "redux";
import { AppState, UserOnBoardingState } from "../../store";
import { RouteComponentProps } from "react-router";

export const onUpdateChanges = (updatedObj: UserOnBoardingState) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateChanges({ updatedObj }));
    };
};

export const onUpdateLogo = (file: any, src: string, routeProps: RouteComponentProps) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(uploadLogo({ file, src, routeProps }));
    };
};

export const onConfirmUser = (routeProps: RouteComponentProps, forgotPassword: boolean) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const userOnBoarding = getState().userOnBoarding;
        dispatch(confirmUser({ userOnBoarding, routeProps, forgotPassword }));
    };
};
