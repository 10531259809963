/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export const Justify = {
    CENTER: "center" as const,
    TOP: "top" as const,
    TOP_MIDDLE: "top-middle" as const,
    BOTTOM: "bottom" as const,
    BOTTOM_MIDDLE: "bottom-middle" as const
};

export type Justify = typeof Justify[keyof typeof Justify];

export const Alignment = {
    CENTER: "center" as const,
    LEFT: "left" as const,
    LEFT_MIDDLE: "left-middle" as const,
    RIGHT: "right" as const,
    RIGHT_MIDDLE: "right-middle" as const
};

export type Alignment = typeof Alignment[keyof typeof Alignment];

export interface IMPDStates {
    itemState?: States;
}

export const States = {
    hover: "hover" as const,
    selected: "selected" as const,
    unselected: "unselected" as const,
    default: "default" as const
};

export type States = typeof States[keyof typeof States];

export const Languages = {
    english: "english" as const,
    spanish: "spanish" as const
};

export type Languages = typeof Languages[keyof typeof Languages];

export const Utilities = {
    success: "success" as const,
    error: "error" as const,
    loading: "loading" as const,
    warning: "warning" as const
};

export type Utilities = typeof Utilities[keyof typeof Utilities];

export const Severities = {
    severe: "severe" as const,
    moderate: "moderate" as const,
    minor: "minor" as const,
    extreme: "extreme" as const
};

export type Severities = typeof Severities[keyof typeof Severities];

export const CardEvents = {
    incident: "incident" as const,
    event: "event" as const,
    guide: "guide" as const,
    place: "place" as const
};

export type CardEvents = typeof CardEvents[keyof typeof CardEvents];

export const Priorities = {
    p1: "p1" as const,
    p2: "p2" as const,
    p3: "p3" as const,
    p4: "p4" as const
};

export type Priorities = typeof Priorities[keyof typeof Priorities];

export const Incidents = {
    new: "new" as const,
    open: "open" as const,
    pending: "pending" as const,
    closed: "closed" as const
};

export type Incidents = typeof Incidents[keyof typeof Incidents];
