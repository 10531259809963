// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { userOnBoardingActions } from "../../actions";

import { Text } from "@blueprintjs/core";

import { Route, Switch } from "react-router-dom";

import {
    StyledFloatingInput,
    ImageUpload,
    StyledFloatingInputTypes,
    BottomNavigationBar,
    CommonToast,
} from "src/components";

import { MPDIcon, Classes, MPDScroll } from "src/mpd-library";

import { IDefaultView } from "..";

import "./styles.scss";
import { AppState, UserOnBoardingState } from "src/store";
import { RouteComponentProps } from "react-router";
import { History } from "history";
import { bindActionCreators, Dispatch } from "redux";
import { ReactComponent as OnboardingLogoIcon } from "../../mpd-library/icon/assets/onboarding-logo.svg"
import { ReactComponent as UserApproveCheckIcon } from "../../mpd-library/icon/assets/user-approve-check.svg"
import { ReactComponent as ProfileImageIcon } from "../../mpd-library/icon/assets/profile-image.svg"

export interface IUserOnBoardingProps extends IDefaultView {
    currentPlan: string;
    userOnBoarding: UserOnBoardingState;
    isMobileSize: boolean;
    onUpdateChanges: (updatedObj: UserOnBoardingState, pathname?: string, history?: History) => void;
    onConfirmUser: (routeProps: RouteComponentProps, forgotPassword: boolean) => void;
    onUpdateLogo: (file: any, src: any, routeProps: RouteComponentProps) => void;
}

class UserOnBoardingProps extends React.Component<IUserOnBoardingProps> {
    constructor(props: IUserOnBoardingProps) {
        super(props);

        this.onInputChanged = this.onInputChanged.bind(this);
        this.renderMain = this.renderMain.bind(this);
        this.onUploadLogo = this.onUploadLogo.bind(this);
        this.onConfirmUser = this.onConfirmUser.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
    }

    public render() {
        const { isMobileSize, routeProps } = this.props;
        const pathname = routeProps.location.pathname;
        const classes = "user-onboarding";
        const MPDIconComponent =
            (routeProps.location.pathname.includes("agency-details") && "realEstateHouse") ||
            (routeProps.location.pathname.includes("platform-setup") && "smsPhoneUrgent") ||
            (routeProps.location.pathname.includes("branding") && "colorPalette") ||
            "";

        return (
            <div className={classes}>
                <div
                    className={classNames(
                        classes + "-inner-container",
                        (pathname.includes("edit-jurisdiction-boundaries") || pathname.includes("finish")) &&
                            "full-screen"
                    )}
                >
                    <div
                        className={classNames(
                            classes + "-image-container",
                            (pathname.includes("edit-jurisdiction-boundaries") || pathname.includes("finish")) && "hide"
                        )}
                    >
                        <div className={classes + "-logo"}>
                            <MPDIcon IconComponent={OnboardingLogoIcon} />
                        </div>
                        <div className={classes + "-icon"}>
                            <MPDIcon IconComponent={UserApproveCheckIcon} />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            classes + "-content-container",
                            (pathname.includes("edit-jurisdiction-boundaries") || pathname.includes("finish")) && "hide"
                        )}
                    >
                        {isMobileSize && !pathname.includes("finish") && (
                            <div className={classes + "-icon"}>
                                <MPDIcon IconComponent={MPDIconComponent} />
                            </div>
                        )}
                        <Switch location={routeProps.history.location}>
                            <Route
                                path={"/login/confirm_account"}
                                render={() => (
                                    <React.Fragment>
                                        {this.renderMain(classes)}
                                        <BottomNavigationBar
                                            className={classes + "-bottom-navigation-bar"}
                                            loading={false}
                                            showBack={false}
                                            frontText={"next"}
                                            onNextButtonPressed={this.onConfirmUser}
                                        />
                                    </React.Fragment>
                                )}
                            />
                            <Route
                                path={"/login/restore_password"}
                                render={() => (
                                    <React.Fragment>
                                        {this.renderForgotPassword(classes)}
                                        {!pathname.includes("edit-jurisdiction-boundaries") &&
                                            !pathname.includes("finish") && (
                                                <BottomNavigationBar
                                                    className={classes + "-bottom-navigation-bar"}
                                                    loading={false}
                                                    showBack={false}
                                                    frontText={"next"}
                                                    onNextButtonPressed={this.onResetPassword}
                                                />
                                            )}
                                    </React.Fragment>
                                )}
                            />
                        </Switch>
                    </div>
                </div>
                <CommonToast />
            </div>
        );
    }

    private onConfirmUser() {
        this.props.onConfirmUser(this.props.routeProps, false);
    }

    private onResetPassword() {
        this.props.onConfirmUser(this.props.routeProps, true);
    }

    private renderMain(classes: string) {
        const { userOnBoarding } = this.props;
        const { errors } = userOnBoarding;
        return (
            <div className={classes + "-main"}>
                <div className={classes + "-content"}>
                    <Text className={classes + "-title"}> Welcome to Atlas One. </Text>
                    <Text className={classes + "-subtitle"}>
                        {" "}
                        To accept your invite, please create a password below{" "}
                    </Text>
                </div>
                <div className={classes + "-body-logo-container"}>
                    <ImageUpload
                        IconComponent={ProfileImageIcon}
                        src={userOnBoarding.logo.src}
                        title="UPLOAD PROFILE PICTURE"
                        onUploadImage={this.onUploadLogo}
                    />
                </div>
                <MPDScroll
                    className={classNames(
                        classes + "-body-form",
                        Classes.BLOCK_PADDING_HORIZONTAL,
                        Classes.ASSIGN_BLOCK_MARGIN
                    )}
                    yDirection={true}
                >
                    <div
                        className={classNames(
                            Classes.ROW,
                            classes + "-body-form-row",
                            Classes.RESPONSIVE,
                            Classes.BLOCK_MARGIN_BOTTOM
                        )}
                    >
                        <StyledFloatingInput
                            autoCapitalize="none"
                            autoCorrect="none"
                            floatingType={StyledFloatingInputTypes.primary}
                            value={userOnBoarding.password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onInputChanged(e, "password")}
                            placeholder="PASSWORD"
                            type="password"
                            tabIndex={1}
                            error={errors.password}
                        />
                        <StyledFloatingInput
                            autoCapitalize="none"
                            autoCorrect="none"
                            floatingType={StyledFloatingInputTypes.primary}
                            value={userOnBoarding.confirmPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.onInputChanged(e, "confirmPassword")
                            }
                            placeholder="CONFIRM PASSWORD"
                            type="password"
                            error={errors.confirmPassword}
                            tabIndex={1}
                        />
                    </div>
                </MPDScroll>
            </div>
        );
    }

    private renderForgotPassword(classes: string) {
        const { userOnBoarding } = this.props;
        const { errors } = userOnBoarding;
        return (
            <div className={classes + "-main"}>
                <div className={classes + "-content"}>
                    <Text className={classes + "-title"}> Forgot Password? </Text>
                    <Text className={classes + "-subtitle"}>
                        {" "}
                        To reset your account, please enter your new password{" "}
                    </Text>
                </div>
                <MPDScroll
                    className={classNames(
                        classes + "-body-form",
                        Classes.BLOCK_PADDING_HORIZONTAL,
                        Classes.ASSIGN_BLOCK_MARGIN
                    )}
                    yDirection={true}
                >
                    <div
                        className={classNames(
                            Classes.ROW,
                            classes + "-body-form-row",
                            Classes.RESPONSIVE,
                            Classes.BLOCK_MARGIN_BOTTOM
                        )}
                    >
                        <StyledFloatingInput
                            autoCapitalize="none"
                            autoCorrect="none"
                            floatingType={StyledFloatingInputTypes.primary}
                            value={userOnBoarding.password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onInputChanged(e, "password")}
                            placeholder="PASSWORD"
                            type="password"
                            tabIndex={1}
                            error={errors.password}
                        />
                        <StyledFloatingInput
                            autoCapitalize="none"
                            autoCorrect="none"
                            floatingType={StyledFloatingInputTypes.primary}
                            value={userOnBoarding.confirmPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.onInputChanged(e, "confirmPassword")
                            }
                            placeholder="CONFIRM PASSWORD"
                            type="password"
                            error={errors.confirmPassword}
                            tabIndex={1}
                        />
                    </div>
                </MPDScroll>
            </div>
        );
    }

    private onUploadLogo(files: any) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = () => {
            const src = reader.result;
            this.props.onUpdateLogo(files[0], src, this.props.routeProps);
        };
    }

    private onInputChanged(event: React.ChangeEvent<HTMLInputElement>, type: string) {
        const { userOnBoarding } = this.props;
        const updatedValues = { ...userOnBoarding };
        const value = event.currentTarget.value;
        updatedValues[type] = value;
        this.props.onUpdateChanges(updatedValues);
    }
}

const mapStateToProps = (state: AppState) => {
    const { userOnBoarding, global } = state;
    const { isMobileSize } = global;

    return {
        userOnBoarding,
        isMobileSize,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateChanges: userOnBoardingActions.onUpdateChanges,
            onConfirmUser: userOnBoardingActions.onConfirmUser,
            onUpdateLogo: userOnBoardingActions.onUpdateLogo
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserOnBoardingProps);
