import { defaultNotificationsState, NotificationsState } from "src/store/notifications";
import { NotificationsActionTypes, SET_HAS_MORE_NOTIFICATIONS, SET_NOTIFICATIONS, SET_NOTIFICATION_SETTINGS, UPDATE_NOTIFICATIONS_COUNTER } from "../actions/notifications/types";

export const notificationsReducer = (
    state: NotificationsState = defaultNotificationsState(),
    action: NotificationsActionTypes
): NotificationsState => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notificationsList: action.payload
            };
        case SET_HAS_MORE_NOTIFICATIONS:
            return {
                ...state,
                hasMoreNotifications: action.payload
            }
        case SET_NOTIFICATION_SETTINGS:
            return {
                ...state,
                notificationsSettings: action.payload
            }
        case UPDATE_NOTIFICATIONS_COUNTER:
            return {
                ...state,
                unreadNotificationsCounter: action.payload
            }
        default:
            return state;
    }
};
