import { Text } from "@blueprintjs/core";
import { RecentItem } from "../components/recent-item/RecentItem";
import styles from "./Recent.module.scss";
import { EmptyBlock, StyledButton, StyledButtonTypes } from "../../../components";
import { useEffect, useMemo, useState } from "react";
import { useDataFetch } from "../../../hooks/useDataFetch";
import { ReactComponent as AlertBellNotificationIcon } from "src/mpd-library/icon/assets/AlertBellNotification.svg";
import { ReactComponent as NoSelectedConversationIcon } from "src/mpd-library/icon/assets/no-selected-conversation-icon.svg";

export const MOCKED_CONVERSATIONS = [
    {
        type: "conversation",
        title: "Conversation with Jason Smith",
        subtitle: "Hi there, I want to report an issue. There have been loud noises coming from",
        inserted_at: "",
        status: "open",
        tags: [{ id: "sgfoh;ipjusdfg", name: "VIP" }]
    },
    {
        type: "conversation",
        title: "Conversation with Jason Smith",
        subtitle: "Hi there, I want to report an issue. There have been loud noises coming from",
        inserted_at: "",
        status: "open",
        tags: [{ id: "sgfoh;ipjusdfg", name: "VIP" }]
    },
    {
        type: "conversation",
        title: "Conversation with Jason Smith",
        subtitle: "Hi there, I want to report an issue. There have been loud noises coming from",
        inserted_at: "",
        status: "open",
        tags: [{ id: "sgfoh;ipjusdfg", name: "VIP" }]
    }
];

export const MOCKED_ALERT = [
    {
        type: "alert",
        title: "Pickup Delay",
        subtitle: "Due to technical issues with our vehicles, garbage pickup will be delayed today...",
        lists: [{ id: "jlsdhfjlsdfhjl", name: "Route 2" }]
    }
];

const getEmptyBlockProps = (contact = {}) => {
    return {
        conversations: {
            IconComponent: NoSelectedConversationIcon,
            title: `No conversations for ${contact.first_name} ${contact.last_name}`,
            subtitle: "Conversations will show here once they occur"
        },
        alerts: {
            IconComponent: AlertBellNotificationIcon,
            title: `No alerts for ${contact.first_name} ${contact.last_name}`,
            subtitle: "Alerts will show here once they occur"
        }
    };
};

export const Recent = ({ title, fetchData, recentEntityKey, contact, onSeeAllClick }) => {
    let { entities } = useDataFetch({
        maxLoadingItemsLength: 3,
        fetchDataRequest: async (params) => await fetchData(params),
        entityKey: recentEntityKey,
        canGetInitialData: contact.user_id,
        skeletonLoading: true,
        listItemHeight: 75
    });

    // const getEntities = async () => {
    //     const res = await fetchData();
    //     setEntities(res.data[recentEntityKey].map((entity) => ({ ...entity, type: recentEntityKey })));
    // };

    // useEffect(() => {
    //     getEntities();
    // }, []);

    const emptyBlockProps = useMemo(() => getEmptyBlockProps(contact), [contact]);

    return (
        <div className={styles["root"]}>
            <div className={styles["header"]}>
                <Text className={styles["title"]}>{title}</Text>
                {recentEntityKey !== "alerts" && (
                    <StyledButton
                        type={StyledButtonTypes.primarySimple}
                        text={"See All"}
                        onClick={onSeeAllClick}
                        className={styles["see-all-btn"]}
                    />
                )}
            </div>
            {entities.allIds.map((entityId) => {
                return (
                    <RecentItem
                        type={recentEntityKey}
                        item={{ ...entities.byId[entityId], type: recentEntityKey }}
                        loading={entityId === "loading"}
                    />
                );
            })}
            {entities.allIds.length === 0 && <EmptyBlock {...emptyBlockProps[recentEntityKey]} />}
        </div>
    );
};
