import { Spinner } from "@blueprintjs/core";
// import "@blueprintjs/core/src/components/spinner/_spinner.scss";
import classNames from "classnames";
import styles from "./styles.module.scss";

const classname = "page-loading";

export const PageLoadingSpinner = () => {
    return (
        <div className={classNames(styles[`${classname}-wrapper`], `${classname}-wrapper`)}>
            <Spinner size={50} />
        </div>
    );
};
