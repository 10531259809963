// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { CSSTransition } from "react-transition-group";

import { IProps } from "@blueprintjs/core";

import "./styles.scss";

export const MPDAnimations = {
    growOut: "mpd-grow-out" as const,
    swipeLeft: "mpd-swipe-left" as const,
    swipeRight: "mpd-swipe-right" as const,
    none: "none" as const
};

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export type MPDAnimations = typeof MPDAnimations[keyof typeof MPDAnimations];

export interface IMPDAnimationContainerProps extends IProps, CSSTransition.CSSTransitionProps {
    transitionDuration?: number;
}

export class MPDAnimationContainer extends React.Component<IMPDAnimationContainerProps> {
    constructor(props: IMPDAnimationContainerProps) {
        super(props);
    }

    public render() {
        const { className, children, transitionDuration, ...transitionProps } = this.props;
        const classes = "animation-content";

        return (
            <CSSTransition style={{ transitionDuration: transitionDuration + "ms" }} {...transitionProps}>
                <div className={classNames(classes, className)}>{children}</div>
            </CSSTransition>
        );
    }
}
