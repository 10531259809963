// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IStyledSelectProps, StyledSelect, StyledSelectTypes } from "src/components";

import { Checkbox, ICheckboxProps, IProps, Classes } from "@blueprintjs/core";

import "./styles.scss";

export interface ICheckmarkSelectProps extends IProps {
    popoverClassName?: string;
    selectProps: IStyledSelectProps;
    checkboxProps: ICheckboxProps;
    type?: string;
}

export interface ICheckmarkSelectState extends IProps {
    hover?: boolean;
}

export class CheckmarkSelect extends React.Component<ICheckmarkSelectProps, ICheckmarkSelectState> {
    constructor(props: ICheckmarkSelectProps) {
        super(props);
        this.state = { hover: false };
    }

    public render() {
        const { className, selectProps, type, checkboxProps } = this.props;
        const { hover } = this.state;

        if (selectProps) {
            const { ...remaningSelectProps } = selectProps;
            const classes = "checkmark-select";
            return (
                <div className={classNames(classes, hover && Classes.ACTIVE, type, className)}>
                    <Checkbox {...checkboxProps} checked={checkboxProps.checked} onChange={this.onChangeCheckBox} />
                    <StyledSelect
                        type={StyledSelectTypes.image}
                        {...remaningSelectProps}
                        onChange={this.onChangeSelect}
                    />
                </div>
            );
        }
        return null;
    }

    private onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.checkboxProps.onChange) {
            this.props.checkboxProps.onChange(event);
        }
    };

    private onChangeSelect = (event: React.SyntheticEvent) => {
        if (this.props.selectProps.onChange) {
            this.props.selectProps.onChange(event);
        }
    };
}
