// tslint:disable
import classNames from "classnames";
import React from "react";

import * as myClasses from "src/mpd-library";
import { HTMLDivProps } from "@blueprintjs/core";

import "./styles.scss";

export interface IMPDScrollProps extends HTMLDivProps {
    yDirection?: boolean;
    xDirection?: boolean;
    onScroll?: (event: React.SyntheticEvent) => void;
    onScrollTop?: (event: React.SyntheticEvent) => void;
    onScrollBottom?: (event: React.SyntheticEvent) => void;
    scrollRef?: any;
}

interface IMPDScrollState {
    contentRect?: any;
    scrollWidth?: number;
    scrollHeight?: number;
}

export class MPDScroll extends React.Component<IMPDScrollProps, IMPDScrollState> {
    constructor(props: IMPDScrollProps) {
        super(props);

        this.onScroll = this.onScroll.bind(this);

        this.state = {
            scrollWidth: 0,
            scrollHeight: 0
        };
    }

    public render() {
        const { children, className, xDirection, yDirection, style, scrollRef } = this.props;

        const classes = classNames(
            myClasses.SCROLLABLE_DIV_CONTAINER,
            className,
            xDirection && "x-direction",
            yDirection && "y-direction"
        );

        const defaultStyle = {
            marginRight: yDirection ? this.state.scrollWidth : undefined,
            marginBottom: xDirection ? this.state.scrollHeight : undefined
        };

        return (
            <div
                className={classes}
                ref={scrollRef}
                onTouchMove={this.onTouchMove}
                onScroll={this.onScroll}
                style={{
                    ...defaultStyle,
                    ...style
                }}
            >
                {children}
            </div>
        );
    }

    private onTouchMove(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    private onScroll(e: any) {
        const { onScrollBottom, onScrollTop, onScroll } = this.props;
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight;
        const top = e.target.scrollTop === 0;
        if (onScroll) {
            onScroll(e);
        }

        if (onScrollBottom && bottom) {
            onScrollBottom(e);
        } else if (onScrollTop && top) {
            onScrollTop(e);
        }
    }
}
