import isEmpty from "lodash/isEmpty";
import { ChangeEvent, useContext, useMemo, useState } from "react";
import {
    StyledAlert,
    StyledAlertGlobalContent,
    StyledAlertTypes,
    StyledButton,
    StyledButtonTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes
} from "../../../../../components";
import styles from "./SaveViewDialog.module.scss";
import { createContactsView } from "../../../../../requests/contactsRequests";
import { ContactsContext } from "../../context";

const Target = ({ onClick }) => {
    return <StyledButton type={StyledButtonTypes.primarySimple} text={"Save View"} onClick={onClick} />;
};

export const SaveViewDialog = ({ filters, appliedFilters, saveView }) => {
    const [saveViewModalIsOpen, setSaveViewModalIsOpen] = useState<boolean>(false);
    const [saveViewProcessing, setSaveViewProcessing] = useState<boolean>(false);
    const [viewName, setViewName] = useState<string>("");

    const onSaveClick = async () => {
        try {
            setSaveViewProcessing(true);
            const res = await createContactsView({ name: viewName, filters: appliedFilters });
            saveView(res.data.view);
            return;
        } catch (err) {
            return;
        } finally {
            setSaveViewProcessing(false);
        }
    };

    const closeDialog = () => {
        setSaveViewModalIsOpen(false);
    };

    const onViewNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setViewName(e.target.value);
    };

    if (filters?.conditions?.[0].conditions?.length === 0) {
        return null;
    }

    return (
        <>
            <StyledAlert
                Target={!isEmpty(filters) ? Target : null}
                className={styles["save-view-dialog"]}
                isOpen={saveViewModalIsOpen}
                type={StyledAlertTypes.primary}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={true}
                cancelButtonText={"Cancel"}
                confirmButtonText={"Save View"}
                confirmButtonProps={{
                    processing: saveViewProcessing,
                    type: StyledButtonTypes.primary,
                    disabled: !viewName
                }}
                onCancel={closeDialog}
                onConfirm={onSaveClick}
                globalContentProps={{
                    onCancel: closeDialog,
                    title: "Save View",
                    hideDescription: true
                }}
            >
                <StyledFloatingInput
                    hint={"Enter a new view name..."}
                    placeholder={"NAME"}
                    floatingType={StyledFloatingInputTypes.primary}
                    value={viewName}
                    onChange={onViewNameChange}
                />
            </StyledAlert>
        </>
    );
};
