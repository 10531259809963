// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";

import { IProps } from "@blueprintjs/core";

import { StyledButton, StyledButtonTypes, StyledSelect, StyledSelectTypes } from "src/components";

import "./styles.scss";
import { ReactComponent as TrashIcon } from "../../mpd-library/icon/assets/trash.svg"
import { ReactComponent as GroupBlackSmallIcon } from "../../mpd-library/icon/assets/group-black-small.svg"
import { ReactComponent as LockIcon } from "../../mpd-library/icon/assets/lock.svg"
import { ReactComponent as VerticalTripleLineIcon } from "../../mpd-library/icon/assets/vertical-triple-line.svg"

interface IListHeaderIconsProps extends IProps {
    hideDetails?: boolean;
    onClick?: (event: React.SyntheticEvent, type: string) => void;
}

export const ListHeaderIcons: React.FunctionComponent<IListHeaderIconsProps> = (props) => {
    const { className, hideDetails, onClick } = props;
    const classes = "list-header-icons";
    return (
        <div className={classNames(classes, className)}>
            {!hideDetails && (
                <React.Fragment>
                    <StyledButton
                        className={classes + "-button"}
                        type={[StyledButtonTypes.secondary, StyledButtonTypes.fillSvg]}
                        IconComponent={TrashIcon}
                        onClick={(e) => onClick && onClick(e, "trash")}
                    />
                    <StyledButton
                        className={classes + "-button"}
                        type={[StyledButtonTypes.secondary, StyledButtonTypes.fillSvg]}
                        IconComponent={GroupBlackSmallIcon}
                        onClick={(e) => onClick && onClick(e, "profile")}
                    />
                    <StyledButton
                        className={classes + "-button"}
                        type={[StyledButtonTypes.secondary, StyledButtonTypes.fillSvg]}
                        IconComponent={LockIcon}
                        onClick={(e) => onClick && onClick(e, "lock")}
                    />
                </React.Fragment>
            )}
            <StyledSelect
                className={classes + "-select"}
                LeftIconComponent={VerticalTripleLineIcon}
                options={hideDetails ? ["Trash", "Lock"] : [""]}
                type={[StyledSelectTypes.image, StyledSelectTypes.fillSvg, StyledSelectTypes.hideArrow]}
                onChange={(e) => onClick && onClick(e, "more")}
                popOverClassName={classes + "-popover"}
            />
        </div>
    );
};

export default ListHeaderIcons;
