import moment from "moment";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { IToastType, ToastTypes } from "src/components";
import {
    getAlertByIdRequest,
    getAlertByQueryRequest,
    getAlertsListRequest,
    patchChangeAlertStatusByIdRequest,
    postUpdateAlertByIdRequest,
    AlertsListExtendingParams,
    AlertsSearchExtendingParams
} from "../../requests";
import {
    AlertComposeData,
    AlertStateAlertData,
    AlertStateMultimediaData,
    AppState,
    defaultAlertsState
} from "../../store";
import { formatRecievedServerDataToGeoJson } from "../functions";
import { updateChangesMade, updateToast } from "../global/global-api";
import {
    UPDATE_ALERT_STATE,
    UPDATE_ALERT_COMPOSE_DATA,
    UPDATE_ALERT_LIST,
    UPDATE_ALERT_SEARCH_LIST,
    UPDATE_ALERT_TAB_ID,
    UPDATE_SELECTED_ALERT,
    CHANGE_CREATE_ALERT_PROCESSING,
    ADD_FILES_FOR_UPDATE
} from "./types";
import { ListsIdTypes } from "src/containers";
import { updateComposeData } from "./alerts-compose-api";
import { globalActions } from "../global";
import { getListItemIndex } from "src/selectors";
import { feature } from "@turf/helpers";
import { updateGlobalAndSaveStepProcessingStatus } from "./alerts-compose-api";
import { onUpdateMultimediaCommon } from "./update-multimedia";
import _findIndex from "lodash/findIndex";

interface ChangedTabsProps {
    tabId: ListsIdTypes;
}
export const changedTabs = (ref: ChangedTabsProps) => (dispatch: Dispatch<AppState>) => {
    const { tabId } = ref;

    dispatch({
        type: UPDATE_ALERT_TAB_ID,
        payload: tabId
    });
};

interface InitiateAlertProps {
    routeProps: RouteComponentProps;
    params: AlertsListExtendingParams;
}
export const initiateAlert =
    (ref: InitiateAlertProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const alert = getState().alerts.alert;
        const {
            routeProps,
            params: { status }
        } = ref;
        const id = globalActions.getSelectedId(routeProps);

        // if (alert.lists[status].alertsList.length) {
        //     return;
        // }
        const newAlert = { ...alert };
        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName: status,
                listData: {
                    alertsListLoading: true
                }
            }
        });
        const res = await getAlertsListRequest({ limit: 15, offset: 0, status, sortTitle: "desc" });

        const newAlertList = res.data.alerts as Array<AlertStateAlertData>;
        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName: status,
                listData: {
                    alertsList: newAlertList,
                    alertsListLoading: false
                }
            }
        });

        if (id !== "-1") {
            const selectedAlert = newAlert.selectedAlert;
            if (selectedAlert !== undefined) {
                // const { status: listItemStatus } = selectedAlert;
                // if (listItemStatus === "draft") {
                // routeProps.history.push(`/alerts/compose/id-${id}/`);
                // }
                // dispatch(selectListItem({ id, tabId: status }));
            } else {
                // const innerRes = await getAlertByIdRequest(id);
                // const data = innerRes.data;

                if (data.reason === "not_found") {
                    // routeProps.history.push("/alerts");
                } else {
                    // const selectedListItem = data.alert as AlertStateAlertData;
                    // selectedListItem.isSelected = true;
                    // dispatch(
                    //     changedTabs({
                    //         tabId: data.alert.status
                    //     })
                    // );
                    // dispatch(updateSelectedAlert({ selectedAlert: selectedListItem }));
                    // dispatch(
                    //     selectListItem({
                    //         id,
                    //         tabId: status
                    //     })
                    // );
                }
            }
        }

        // dispatch({
        //     type: UPDATE_ALERT_LIST,
        //     payload: {
        //         listName: status,
        //         listData: {
        //             alertsListLoading: false
        //         }
        //     }
        // });

        // dispatch({
        //     type: UPDATE_IS_FULL_ALERT_LIST_STATUS,
        //     payload: {
        //         active: false,
        //         archived: false,
        //         draft: false,
        //         expired: false
        //     }
        // });
    };

interface GetAlertsListProps {
    params: AlertsListExtendingParams;
    offset?: number;
}
export const getAlertsList =
    (ref: GetAlertsListProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const alert = getState().alerts.alert;
        const {
            params: { status },
            offset
        } = ref;

        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName: status,
                listData: {
                    alertsListLoading: true
                }
            }
        });

        const offsetLength = offset !== undefined ? offset : alert.lists[status].alertsList.length;

        const res = await getAlertsListRequest({ limit: 15, offset: offsetLength, status, sortTitle: "desc" });

        const list = res.data.alerts;

        if (list && list.length) {
            const newAlert = { ...alert };
            const newList =
                offset === 0 ? list : newAlert.lists[status].alertsList.concat(list as Array<AlertStateAlertData>);

            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: status,
                    listData: { alertsList: newList }
                }
            });
        } else {
            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: status,
                    listData: {
                        isFullAlertsList: true
                    }
                }
            });
        }

        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName: status,
                listData: {
                    alertsListLoading: false
                }
            }
        });
    };

export const updateSelectedAlertAction = (selectedAlert, shouldResetPrevData = false) => ({
    type: UPDATE_SELECTED_ALERT,
    payload: selectedAlert,
    shouldResetPrevData
});

interface UpdateListItemProps {
    selectedAlert: any;
    composeData?: AlertComposeData;
    isOnSaveDraft?: boolean;
}
export const updateSelectedAlert = (ref: UpdateListItemProps) => (dispatch: Dispatch<AppState>) => {
    const { selectedAlert, composeData, isOnSaveDraft } = ref;
    const { status } = selectedAlert;
    if (status !== "draft") {
        const {
            inserted_at: created,
            sent_at: startTime,
            expires_at: endTime,
            geo_fence,
            sent_by: sentBy,
            location,
            replies_settings
        } = selectedAlert;
        
        if (geo_fence && geo_fence.type === "GeometryCollection") {
            const geoFence = geo_fence.geometries[0];
            selectedAlert.geoFence = feature(geoFence);
        } else if (geo_fence && geo_fence.coordinates) {
            const coordinates = geo_fence.coordinates;
            const type = geo_fence.type;
            const geoFence = formatRecievedServerDataToGeoJson(coordinates, type);
            selectedAlert.geoFence = geoFence;
        }

        if (location && location.coordinates) {
            const coordinates = location.coordinates;
            const center = {
                lat: coordinates[1],
                lng: coordinates[0]
            };
            selectedAlert.location = formatRecievedServerDataToGeoJson(coordinates, "Point");
            selectedAlert.center = center;
        }

        if (sentBy) {
            selectedAlert.sentBy = {
                firstName: sentBy.first_name,
                lastName: sentBy.last_name,
                profilePic: sentBy.profile_pic
            };
        }

        selectedAlert.created = moment(created).unix();
        selectedAlert.startTime = moment(startTime).unix();
        selectedAlert.endTime = moment(endTime).unix();

        if (composeData) {
            dispatch(updateComposeData({ composeData: { ...composeData, replies_settings } }));
        }
    } else {
        const {
            expires_interval_hours,
            expires_at,
            title,
            severity,
            description,
            instructions,
            location,
            geo_fence,
            attachments,
            perimeter,
            address,
            channels,
            jurisdiction_wide,
            location_centered,
            perimeter_equals_geo_fence,
            allow_replies,
            replies_settings
        } = selectedAlert;
        const channelIds: Array<string> = [];
        Object.keys(channels).forEach((socialNetworkName: string) => {
            if (socialNetworkName !== "atlasone") {
                channels[socialNetworkName].forEach((channel: any) => {
                    channelIds.push(channel.account_channel_id);
                });
            }
        });

        if (composeData) {
            const newComposeData: AlertComposeData = {
                ...composeData,
                channelIdsObject: {
                    status: true,
                    channelIds
                },
                alertDetails: {
                    ...composeData.alertDetails,
                    expiration: {
                        ...composeData.alertDetails.expiration,
                        value: expires_at ? "0" : expires_interval_hours.toString(),
                        custom: {
                            date: (expires_at && moment(expires_at).toDate()) || new Date(),
                            time: (expires_at && moment(expires_at).format("hh:mm A")) || "12:00 AM"
                        }
                    },
                    severity,
                    address,
                    location_centered: location_centered,
                    perimeter_equals_geo_fence: perimeter_equals_geo_fence
                },
                allow_replies,
                replies_settings,
                composeMessage: {
                    ...composeData.composeMessage,

                    messageDetails: {
                        ...composeData.composeMessage.messageDetails,
                        headline: title,
                        instructions,
                        description
                    },
                    multiMedia: {
                        ...composeData.composeMessage.multiMedia,
                        files: isOnSaveDraft
                            ? composeData.composeMessage.multiMedia.files
                            : attachments.map((el: any) => ({ ...el.attachment, id: el.id }))
                    }
                },
                chooseAudience: {
                    ...composeData.chooseAudience,
                    audiences: jurisdiction_wide ? "everyone" : "",
                    geoFence: {
                        ...composeData.chooseAudience.geoFence,
                        status: !jurisdiction_wide
                    }
                }
            };
            if (location && location.coordinates) {
                const coordinates = location.coordinates;
                const marker = formatRecievedServerDataToGeoJson(coordinates, "Point");
                newComposeData.alertDetails.incidentLocation.status = true;
                newComposeData.alertDetails.incidentLocation.marker = marker;
            } else if (!location) {
                newComposeData.alertDetails.incidentLocation.marker = null;
            }

            if (geo_fence && geo_fence.type === "GeometryCollection") {
                const geoFence = geo_fence.geometries[0];
                newComposeData.chooseAudience.geoFence.currentShape = feature(geoFence);
                newComposeData.chooseAudience.geoFence.status = !jurisdiction_wide;
            } else if (geo_fence && geo_fence.coordinates) {
                const coordinates = geo_fence.coordinates;
                const type = geo_fence.type;
                const geoFence = formatRecievedServerDataToGeoJson(coordinates, type);
                newComposeData.chooseAudience.geoFence.currentShape = geoFence;
                newComposeData.chooseAudience.geoFence.status = !jurisdiction_wide;
            } else if (!geo_fence) {
                newComposeData.chooseAudience.geoFence.currentShape = null;
                newComposeData.chooseAudience.geoFence.status = false;
            }

            if (perimeter && perimeter.type === "GeometryCollection") {
                const geoFence = perimeter.geometries[0];
                newComposeData.alertDetails.perimeter.currentShape = feature(geoFence);
                newComposeData.alertDetails.perimeter.status = true;
            } else if (perimeter && perimeter.coordinates) {
                const coordinates = perimeter.coordinates;
                const type = perimeter.type;
                const geoFence = formatRecievedServerDataToGeoJson(coordinates, type);
                newComposeData.alertDetails.perimeter.currentShape = geoFence;
                newComposeData.alertDetails.perimeter.status = true;
            } else if (!perimeter && perimeter_equals_geo_fence) {
                newComposeData.alertDetails.perimeter.currentShape =
                    newComposeData.chooseAudience.geoFence.currentShape;
                newComposeData.alertDetails.perimeter.status = true;
            } else {
                newComposeData.alertDetails.perimeter.currentShape = null;
                newComposeData.alertDetails.perimeter.status = false;
            }
            dispatch(updateComposeData({ composeData: newComposeData }));
        }
    }

    dispatch(updateSelectedAlertAction(selectedAlert));
};

interface ListItemClickProps {
    routeProps: RouteComponentProps;
    nodeData: AlertStateAlertData;
    isSearchListClick?: boolean;
}
export const listItemClick = (ref: ListItemClickProps) => (dispatch: Dispatch<AppState>) => {
    // const {
    //     alerts: { alert }
    // } = getState();
    // const newAlert = { ...alert };
    // const { selectedAlert, lists, searchList } = newAlert;
    // const { routeProps, nodeData, isSearchListClick } = ref;
    dispatch(updateSelectedAlertAction({ id: ref.nodeData.id }, true));
    dispatch(resetComposeDataToInitialState());

    // if (selectedAlert && selectedAlert.id === nodeData.id) {
    //     return;
    // }

    // if (selectedAlert) {
    //     let updatedList = lists[selectedAlert.status].alertsList;
    //     updatedList = updatedList.map((listAlert) => {
    //         if (listAlert.id === selectedAlert.id) {
    //             listAlert.isSelected = false;
    //         }

    //         return listAlert;
    //     });

    //     dispatch({
    //         type: UPDATE_ALERT_LIST,
    //         payload: {
    //             listName: selectedAlert.status,
    //             listData: { alertsList: updatedList }
    //         }
    //     });
    // }

    // if (selectedAlert && searchList.alertsList.length) {
    //     const updatedSearchList = searchList.alertsList.map((listAlert) => {
    //         if (listAlert.id === selectedAlert.id) {
    //             listAlert.isSelected = false;
    //         }

    //         return listAlert;
    //     });

    //     dispatch({
    //         type: UPDATE_ALERT_SEARCH_LIST,
    //         payload: { alersList: updatedSearchList }
    //     });
    // }

    // if (isSearchListClick) {
    //     const updatedSearchList = searchList.alertsList.map((listAlert) => {
    //         if (listAlert.id === nodeData.id) {
    //             listAlert.isSelected = true;
    //         }

    //         return listAlert;
    //     });

    //     dispatch({
    //         type: UPDATE_ALERT_SEARCH_LIST,
    //         payload: { alersList: updatedSearchList }
    //     });
    // }

    // const alertsList = !isSearchListClick
    //     ? [
    //           ...lists.active.alertsList,
    //           ...lists.expired.alertsList,
    //           ...lists.archived.alertsList,
    //           ...lists.draft.alertsList
    //       ]
    //     : searchList.alertsList;
    // const foundListItem = getListItem(alertsList, nodeData.id);
    // const { status } = foundListItem;
    // let additionalString = "";
    // if (status === "draft") {
    //     additionalString = "compose/";
    // }
    // if (nodeData.id !== undefined) {
    // let path = `/alerts/${additionalString}id-${nodeData.id}`;
    // if (status === "draft") {
    //     path = `/alerts/compose/id-${nodeData.id}/`;
    // }

    // if (foundListItem) {
    // foundListItem.isSelected = true;

    //     dispatch(updateSelectedAlertAction(foundListItem));
    // }
    // dispatch(
    //     navigate({
    //         pathname: path,
    //         history: routeProps.history
    //     })
    // );
    // }
    // dispatch(updateSelectedAlert({ selectedAlert: nodeData }));
};

interface SelectListItemProps {
    id: string;
    tabId?: ListsIdTypes;
}
export const selectListItem =
    (ref: SelectListItemProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const alerts = getState().alerts;
        const { composeData } = alerts;
        const { id, tabId } = ref;

        if (id === "-1") {
            dispatch(updateSelectedAlertAction(undefined));
            return;
        }

        const res = await getAlertByIdRequest(id);

        const { alert: selectedAlert } = res.data;

        if (selectedAlert) {
            dispatch(updateSelectedAlert({ selectedAlert, composeData }));
            dispatch(
                selectAlertInList({ listName: selectedAlert.status, alertId: selectedAlert.id, refreshAllLists: true })
            );
            dispatch(changedTabs({ tabId: tabId || selectedAlert.status }));
        }
    };

export const showCompose = () => (dispatch: Dispatch<AppState>) => {
    dispatch(updateSelectedAlertAction(undefined));

    dispatch(resetComposeDataToInitialState());
};

interface ChangeAlertStatusProps {
    id: string;
    data: { status: ListsIdTypes };
    tabId: ListsIdTypes;
    routeProps: RouteComponentProps;
    nextStepperRoute?: string;
    navToAnotherAlert?: boolean;
    callBack?: any;
}
export const changeAlertStatus =
    (ref: ChangeAlertStatusProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const {
            alerts: { alert, composeData },
            global: { isMobileSize }
        } = getState();
        const { id, data, tabId, routeProps, nextStepperRoute, navToAnotherAlert, callBack } = ref;

        const newAlert = { ...alert };

        const res = await patchChangeAlertStatusByIdRequest(id, data);

        const itemData = res.data.alert;

        if (!itemData) {
            return;
        }

        const selectedAlert: Partial<AlertStateAlertData> = itemData;

        if (isMobileSize) {
            // selectedAlert.isSelected = false;
            routeProps.history.push("/alerts");
        } else {
            // selectedAlert.isSelected = true;
        }

        if (tabId !== data.status) {
            const list = newAlert.lists[tabId].alertsList.filter(
                (listAlert) => listAlert.id.toString() !== id.toString()
            );
            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: tabId,
                    listData: { alertsList: list }
                }
            });
            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: data.status,
                    listData: { alertsList: [] }
                }
            });
        }

        if (callBack) {
            callBack();
            dispatch(updateChangesMade({ changesMade: false }));
            updateGlobalAndSaveStepProcessingStatus(false, dispatch);
            const updatedList = alert.lists[tabId].alertsList.map((alert: any) => {
                if (alert.id === selectedAlert.id) {
                    // selectedAlert.isSelected = false;
                    return selectedAlert;
                }
                return alert;
            });
            dispatch({
                type: UPDATE_ALERT_LIST,
                payload: {
                    listName: composeData.status,
                    listData: { alertsList: updatedList }
                }
            });
            return;
        }

        dispatch(updateSelectedAlert({ selectedAlert, composeData, isOnSaveDraft: true }));
        const matchResult = nextStepperRoute?.match(/(?:id-)([^#]+)(?=\/)/);
        const anotherAlertId = matchResult ? matchResult[1] : 1;
        const updatedList = alert.lists[tabId].alertsList.map((alert: any) => {
            if (alert.id === selectedAlert.id) {
                if (navToAnotherAlert) {
                    // selectedAlert.isSelected = false;
                }
                dispatch(
                    updateSelectedAlert({
                        selectedAlert: { ...alert, ...selectedAlert },
                        composeData,
                        isOnSaveDraft: false
                    })
                );
                return selectedAlert;
            }
            if (navToAnotherAlert && alert.id === anotherAlertId) {
                // alert.isSelected = true;
                dispatch(updateSelectedAlert({ selectedAlert: alert, composeData, isOnSaveDraft: false }));
                return alert;
            }
            return alert;
        });

        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName: composeData.status,
                listData: { alertsList: updatedList }
            }
        });

        const toast: IToastType = {
            value: data.status === "archived" ? "Success! Your alert was archived" : "Draft alert changes were saved",
            type: ToastTypes.saved
        };

        dispatch(updateToast({ toast }));
        dispatch(updateChangesMade({ changesMade: false }));

        if (data.status === "archived") {
            routeProps.history.push(`/alerts/id-${id}/`);
        } else if (navToAnotherAlert) {
            routeProps.history.replace(nextStepperRoute || `/alerts/compose/id-${id}/`);
        } else {
            routeProps.history.push(nextStepperRoute || `/alerts/compose/id-${id}/`);
        }

        dispatch(
            selectAlertInList({
                listName: tabId,
                alertId: navToAnotherAlert && anotherAlertId ? `${anotherAlertId}` : id
            })
        );
        dispatch(changedTabs({ tabId: data.status }));
        updateGlobalAndSaveStepProcessingStatus(false, dispatch);
    };

interface UpdateAlertProps {
    id: string;
    data: AlertStateAlertData;
    tabId: ListsIdTypes;
}
export const updateAlert =
    (ref: UpdateAlertProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const alert = getState().alerts.alert;
        const { id, data, tabId } = ref;
        const updatedObj = {
            attachments: data.multiMedia && data.multiMedia.files,
            body: data.body,
            generate_notification: data.generatePushNotif
        };

        const res = await postUpdateAlertByIdRequest(id, updatedObj);

        const list = alert.lists[tabId].alertsList;
        const index = getListItemIndex(list, id);
        const selectedAlert = list[index];
        selectedAlert.updates.push(res.data.update);
        dispatch(updateSelectedAlert({ selectedAlert }));
    };

interface InitSearchAlertsProps {
    params: AlertsSearchExtendingParams;
}
export const initSearchAlerts = (ref: InitSearchAlertsProps) => async (dispatch: Dispatch<AppState>) => {
    const {
        params: { query, status }
    } = ref;

    dispatch({
        type: UPDATE_ALERT_SEARCH_LIST,
        payload: {
            alertsListLoading: true,
            isFullAlertsList: false
        }
    });

    const res = await getAlertByQueryRequest({
        query,
        limit: 15,
        offset: 0,
        status
    });
    const list = res.data.alerts;

    if (list && list.length) {
        const newSearchList = list as Array<AlertStateAlertData>;
        dispatch({
            type: UPDATE_ALERT_SEARCH_LIST,
            payload: { alertsList: newSearchList }
        });
    } else {
        dispatch({
            type: UPDATE_ALERT_SEARCH_LIST,
            payload: { isFullAlertsList: true }
        });
    }

    dispatch({
        type: UPDATE_ALERT_SEARCH_LIST,
        payload: {
            alertsListLoading: false
        }
    });
};

interface SearchAlertsProps {
    params: AlertsSearchExtendingParams;
}
export const searchAlerts =
    (ref: SearchAlertsProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const alert = getState().alerts.alert;
        const {
            params: { query, status }
        } = ref;
        const newAlert = { ...alert };

        const newOffset = alert.searchList.alertsList.length;
        dispatch({
            type: UPDATE_ALERT_SEARCH_LIST,
            payload: { alertsListLoading: true }
        });

        const res = await getAlertByQueryRequest({
            query,
            limit: 15,
            offset: newOffset,
            status
        });
        const list = res.data.alerts;

        if (list && list.length) {
            const newSearchList = newAlert.searchList.alertsList.concat(list as Array<AlertStateAlertData>);
            dispatch({
                type: UPDATE_ALERT_SEARCH_LIST,
                payload: { alertsList: newSearchList }
            });
        } else {
            dispatch({
                type: UPDATE_ALERT_SEARCH_LIST,
                payload: { isFullAlertsList: true }
            });
        }

        dispatch({
            type: UPDATE_ALERT_SEARCH_LIST,
            payload: { alertsListLoading: false }
        });
    };

interface RemoveFromListProps {
    id: string;
    tabId: ListsIdTypes;
}
export const removeFromList =
    (ref: RemoveFromListProps) => (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const alert = getState().alerts.alert;
        const { id, tabId } = ref;
        const newAlert = { ...alert };
        const list = newAlert.lists[tabId].alertsList;
        const index = getListItemIndex(list, id);
        list.splice(index, 1);
        newAlert.selectedAlert = undefined;

        dispatch({
            type: UPDATE_ALERT_STATE,
            payload: newAlert
        });
    };

interface UpdateMultiMediaProps {
    multiMedia: AlertStateMultimediaData;
    upload: boolean;
    setFinishedStatusForOrderedFile: () => void;
}

export const updateMultiMedia = (ref: UpdateMultiMediaProps) => async (dispatch: Dispatch<AppState>) => {
    try {
        const { multiMedia, upload, setFinishedStatusForOrderedFile } = ref;
        let files: Array<any> = [];
        dispatch({ type: ADD_FILES_FOR_UPDATE, payload: [...multiMedia.files] });
        if (upload) {
            files = await onUpdateMultimediaCommon(multiMedia);
            setFinishedStatusForOrderedFile();
            dispatch({ type: ADD_FILES_FOR_UPDATE, payload: [...multiMedia.files, ...files] });
        } else {
            dispatch({ type: ADD_FILES_FOR_UPDATE, payload: [...multiMedia.files] });
        }
    } catch (err) {
        console.error("ERROR FILE UPLOAD >>>>", err);
    }
};

export const putThumbnails = (thumbnail: any) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const {
        alert: { multimediaForUpdate: files }
    } = getState().alerts;
    if (files.length && thumbnail.uuid) {
        const fileIndex = _findIndex(files, (file: any) => {
            return file.uuid === thumbnail.uuid;
        });
        files[fileIndex] = {
            ...files[fileIndex],
            thumbnail: {
                ...thumbnail.thumbnail
            },
            loadingPreview: files[fileIndex].loadingPreview === undefined ? undefined : false
        };
        dispatch({ type: ADD_FILES_FOR_UPDATE, payload: [...files] });
    }
};

export interface SelectAlertInListProps {
    listName: ListsIdTypes;
    alertId?: string;
    refreshAllLists?: boolean;
}
export const selectAlertInList =
    ({ listName, alertId, refreshAllLists }: SelectAlertInListProps) =>
    (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const {
            alerts: {
                alert: { lists }
            }
        } = getState();

        if (refreshAllLists) {
            Object.keys(lists).forEach((key) => {
                if (key === listName) {
                    return;
                }

                let flag = false;

                const newList = lists[key].alertsList.map((listAlert: AlertStateAlertData) => {
                    if (listAlert.isSelected) {
                        flag = true;
                        return { ...listAlert, isSelected: false };
                    }

                    return listAlert;
                });

                if (flag) {
                    dispatch({
                        type: UPDATE_ALERT_LIST,
                        payload: {
                            listName: key,
                            listData: { alertsLists: newList }
                        }
                    });
                }
            });
        }

        const updatedList = lists[listName].alertsList.map((alert) => {
            if (alertId && alertId.toString() === alert.id.toString()) {
                // alert.isSelected = true;
            } else if (alert.isSelected) {
                // alert.isSelected = false;
            }

            return alert;
        });

        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName,
                listData: { alertsList: updatedList }
            }
        });
    };

export const unselectListItem = () => (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const {
        alerts: {
            alert: { selectedAlert, lists }
        }
    } = getState();

    if (selectedAlert) {
        const listName = selectedAlert.status;
        const updatedList = lists[listName].alertsList.map((alert) => {
            // alert.isSelected = false;
            return alert;
        });

        dispatch(updateSelectedAlertAction(undefined));

        dispatch({
            type: UPDATE_ALERT_LIST,
            payload: {
                listName: selectedAlert.status,
                listData: { alertsList: updatedList }
            }
        });
    }
};

export const updateCreateAlertProcessingStatus = (status: boolean) => {
    return {
        type: CHANGE_CREATE_ALERT_PROCESSING,
        payload: status
    };
};

export const resetComposeDataToInitialState = () => (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const jurisdiction = getState().accountSettings.organization.jurisdiction;
    dispatch({
        type: UPDATE_ALERT_COMPOSE_DATA,
        payload: {
            ...defaultAlertsState.composeData,
            alertDetails: {
                ...defaultAlertsState.composeData.alertDetails,
                incidentLocation: {
                    status: false,
                    marker: null
                }
            },
            chooseAudience: {
                audiences: "",
                geoFence: {
                    jurisdiction,
                    status: false,
                    list: [],
                    currentShape: null,
                    search: {
                        value: null
                    }
                }
            }
        }
    });
};
