import classNames from "classnames";
import React from "react";

import { IProps, ITabsProps, Tab, TabId, Tabs } from "@blueprintjs/core";

import { Classes } from "src/mpd-library";

import { StyledTabsTypes } from "./types";

import "./styles.scss";

export interface IStyledTabsProps extends IProps {
    panel?: JSX.Element;
    tabs: Array<string | IStyledTabsTabType>;
    tabsProps?: ITabsProps;
    type?: StyledTabsTypes | Array<StyledTabsTypes>;
    tabClassName?: string;
    notShowTabs?: boolean;
}

interface IStyledTabsState {
    selectedTabId: TabId;
}

export interface IStyledTabsTabType {
    title?: string;
    disable?: boolean;
    className?: string;
}

export const TAB_UNDERLINED_WITH_YELLOW_CLASSNAME = "tab-underlined-with-yellow";
export const TAB_WITH_DOT_CLASSNAME = "tab-with-dot";

export class StyledTabs extends React.PureComponent<IStyledTabsProps, IStyledTabsState> {
    constructor(props: IStyledTabsProps) {
        super(props);
        const { tabsProps } = this.props;
        this.onChange = this.onChange.bind(this);

        const selectedTabId = tabsProps && tabsProps.selectedTabId;

        this.state = {
            selectedTabId: selectedTabId === undefined || selectedTabId === -1 ? 0 : selectedTabId
        };
    }

    public render() {
        const { className, tabs, tabsProps, type, panel, children, tabClassName, notShowTabs, tabsWithErrors } =
            this.props;
        const { selectedTabId } = this.state;
        const classes = classNames("styled-tabs", type, className);

        if (notShowTabs) {
            return panel;
        }

        return (
            <Tabs className={classes} id={"styled-tabs"} {...tabsProps} onChange={this.onChange}>
                {tabs &&
                    tabs.map((element: any, index: number) => {
                        if (element) {
                            return (
                                <Tab
                                    className={classNames(
                                        selectedTabId === index - 1 && "next-tab",
                                        selectedTabId === index + 1 && "previous-tab",
                                        element.disable && Classes.DISABLED,
                                        tabClassName,
                                        element.className,
                                        tabsWithErrors?.[index] &&
                                            selectedTabId !== index &&
                                            "tab-with-validation-error"
                                    )}
                                    id={index}
                                    key={index}
                                    title={element.title !== undefined ? element.title : element}
                                    disabled={element.disable}
                                    panel={tabsProps && tabsProps.selectedTabId === index ? panel : undefined}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            );
                        }
                        return null;
                    })}
                {children}
            </Tabs>
        );
    }

    private onChange(newTabId: TabId, prevTabId: TabId, event: React.MouseEvent<HTMLElement>) {
        const { tabsProps } = this.props;
        this.setState({ selectedTabId: newTabId });
        if (tabsProps && tabsProps.onChange) {
            tabsProps.onChange(newTabId, prevTabId, event);
        }
    }
}
