import { createContext } from "react";
import { Route, Switch } from "react-router";
import { UseConversationsTags, useConversationsTags } from "src/containers/conversations/components/chat/hooks/useConversationsTags";
import { AddNewTag } from "./tags-add/tags-add";
import { TagsList } from "./tags-list/tags-list";

export const TagsContext = createContext<UseConversationsTags>({} as UseConversationsTags);

export const TagsSettings = () => {
    const tagsProps: UseConversationsTags = useConversationsTags({});
    return (
        <TagsContext.Provider value={tagsProps}>
            <Switch>
                <Route path={"/account-settings/tags"} exact render={() => <TagsList />} />
                <Route path={"/account-settings/tags/create"} render={() => <AddNewTag />} />
                <Route path={"/account-settings/tags/:id/edit"} render={() => <AddNewTag />} />
            </Switch>
        </TagsContext.Provider>
    );
};
