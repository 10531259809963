import { useCallback, useState } from "react";
import { Position, Text, Popover2TargetProps, Classes as BPClasses, TagInput, Classes } from "@blueprintjs/core";
import { motion, AnimatePresence } from "framer-motion";
import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import styles from "./ContactEdit.module.scss";
import { Avatar, StyledButton, Tag } from "../../../../components";
import { MPDIcon } from "../../../../mpd-library";
import { Input, InputTypes } from "../../../../mpd-library/input";
import { CollapseWIthTitle } from "../collapse-with-title/CollapseWithTitle";
import { CustomOptionsComponentProps, Dropdown } from "../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { EditChannelsPhoneInput } from "../../../../mpd-library/edit-channels-phone-input/EditChannelsPhoneInput";
import { EditPlace } from "../EditPlace/EditPlace";
import { getContactsLists } from "../../../../requests/contactsRequests";
import { getTags } from "src/requests/tagsRequests";
import { GroupedDropdownContent } from "../../../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import { TagTypes } from "../../../../components/tag/tag";
import { TIMEZONES } from "./constants";
import { NotesCard } from "../notes-card/NotesCard";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import moment from "moment";
import { ReactComponent as PlaceHomeIcon } from "src/mpd-library/icon/assets/PlaceHome.svg";
import { ReactComponent as PlaceWorkIcon } from "src/mpd-library/icon/assets/PlaceWork.svg";
import { ReactComponent as PlaceGeneralIcon } from "src/mpd-library/icon/assets/PlaceGeneral.svg";
import { ReactComponent as ChannelsPhoneIcon } from "src/mpd-library/icon/assets/ChannelsPhone.svg";
import { ReactComponent as ChannelsSmsIcon } from "src/mpd-library/icon/assets/ChannelsSms.svg";
import { ReactComponent as ChannelsMailIcon } from "src/mpd-library/icon/assets/ChannelsMail.svg";
import { ReactComponent as ChannelsFacebookIcon } from "src/mpd-library/icon/assets/ChannelsFacebook.svg";
import { ReactComponent as ChannelsInstagramIcon } from "src/mpd-library/icon/assets/ChannelsInstagram.svg";
import { ReactComponent as ChannelsTwitterIcon } from "src/mpd-library/icon/assets/ChannelsTwitter.svg";
import { CHANNEL_TYPE_MATCH_ICON } from "../../contacts-list/components/contacts-list-item/contacts-list-item";
import { TagDropdownListItem } from "../../../../mpd-library/dropdown/components/tag-dropdown-list-item/TagDropdownListItem";

const FETCH_TAGS_DATA_PARAMS = {
    feature: "contacts"
};

const AddValueTarget = (props: Popover2TargetProps) => {
    const { onTitleClick, onClick } = props;
    return (
        <StyledButton
            text={"+ Add"}
            {...props}
            onClick={(e) => {
                onTitleClick();
                onClick(e);
            }}
            className={classNames(props.className && props.className, styles["add-button"])}
        />
    );
};

const EMPTY_LABEL_VARIABLES = {
    visible: { display: "block", opacity: 1, scale: 1 },
    hidden: {
        display: "none",
        opacity: 0,
        scale: 0,
        transition: {
            ease: "easeInOut",
            duration: 0.3,
            display: {
                delay: 0.3
            }
        }
    }
};
const PLACE_TYPES_OPTIONS = [
    {
        label: "Home",
        value: "home",
        LeftIconComponent: () => <MPDIcon IconComponent={PlaceHomeIcon} className={styles["select-item-left-icon"]} />
    },
    {
        label: "Work",
        value: "work",
        LeftIconComponent: () => <MPDIcon IconComponent={PlaceWorkIcon} className={styles["select-item-left-icon"]} />
    },
    {
        label: "General Place",
        value: "general",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={PlaceGeneralIcon} className={styles["select-item-left-icon"]} />
        )
    }
];

const CHANNEL_TYPES_OPTIONS = [
    {
        label: "Home Phone",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={ChannelsPhoneIcon} className={styles["select-item-left-icon"]} />
        ),
        value: { type: "phone_number", phone_type: "landline" }
    },
    {
        label: "Mobile Phone",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={ChannelsSmsIcon} className={styles["select-item-left-icon"]} />
        ),
        value: { type: "phone_number", phone_type: "mobile" }
    },
    {
        label: "Email",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={ChannelsMailIcon} className={styles["select-item-left-icon"]} />
        ),
        value: "email"
    },
    {
        label: "Twitter",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={ChannelsTwitterIcon} className={styles["select-item-left-icon"]} />
        ),
        value: "twitter"
    },
    {
        label: "Facebook",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={ChannelsFacebookIcon} className={styles["select-item-left-icon"]} />
        ),
        value: "facebook"
    },
    {
        label: "Instagram",
        LeftIconComponent: () => (
            <MPDIcon IconComponent={ChannelsInstagramIcon} className={styles["select-item-left-icon"]} />
        ),
        value: "instagram"
    }
];

const INPUT_ACTIONS = ["copy", "edit", "remove"];

export const ContactEdit = ({ contact, formik }) => {
    const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState<boolean>(false);
    const [isListsDropdownOpened, setIsListsDropdownOpened] = useState<boolean>(false);
    const { myInfo: me } = useSelector((state: AppState) => state.userSettings);

    const onChannelAddClick = (option) => {
        const channel = { type: option.value, label: "", added: true };
        if (option.value?.type === "phone_number") {
            channel.phone_type = option.value?.phone_type;
            channel.type = option.value.type;
        }
        formik.setFieldValue("channels", [channel, ...formik.values.channels]);
    };

    const onPlaceAddClick = (option) => {
        formik.setFieldValue("places", [
            {
                type: option.value,
                label: "",
                added: true,
                LeftIconComponent: option.LeftIconComponent,
                address: { city: "", state: "", zip_code: "", country: "", street_address: "" }
            },
            ...(formik.values.places || [])
        ]);
    };

    const onChangeChannelValue = (value, index) => {
        const updatedChannles = [
            ...formik.values.channels.slice(0, index),
            { ...formik.values.channels[index], value },
            ...formik.values.channels.slice(index + 1)
        ];
        formik.setFieldValue("channels", updatedChannles);
    };

    const onNoteAddClick = useCallback(() => {
        try {
            formik.setFieldValue("notes", [
                {
                    note: "",
                    added: true,
                    id: uuidv4(),
                    created_by_admin: {
                        first_name: me.firstName,
                        last_name: me.lastName,
                        id: me.uuid
                    },
                    inserted_at: moment().utc()
                },
                ...(formik.values.notes || [])
            ]);
        } catch (err) {
            console.log("EEEEEERRR >>>>", err);
        }
    }, [me]);

    const onRemoveClick = (removableChannelId) => {
        const updatedChannles = formik.values.channels.filter((channel) => {
            return channel.id !== removableChannelId;
        });
        formik.setFieldValue("channels", updatedChannles);
    };

    const loading = typeof contact === "undefined";

    return (
        <>
            <div className={styles["root"]}>
                <section className={styles["user-main-info"]}>
                    <Avatar
                        user={contact}
                        className={classNames(styles["user-main-info__avatar"], loading && Classes.SKELETON)}
                    />
                    <div className={styles["user-main-info__left-wrapper"]}>
                        <Text
                            className={classNames(
                                styles["user-main-info__left-wrapper__name"],
                                loading && Classes.SKELETON
                            )}
                        >{`${contact?.first_name} ${contact?.last_name}`}</Text>
                        <Text
                            className={classNames(
                                styles["user-main-info__left-wrapper__active-since"],
                                loading && Classes.SKELETON
                            )}
                        >
                            {`Active since ${moment(contact?.inserted_at).format("MMMM YYYY")}`}
                        </Text>
                    </div>
                </section>
                <hr />
                <CollapseWIthTitle title={"CONTACT DETAILS"}>
                    <Input
                        type={InputTypes.edit}
                        placeholder={"first name"}
                        actions={INPUT_ACTIONS}
                        {...formik.getFieldProps("contact.first_name")}
                    />
                    <Input type={InputTypes.edit} placeholder={"MIDDLE INITIAL"} actions={INPUT_ACTIONS} />
                    <Input
                        type={InputTypes.edit}
                        placeholder={"LAST NAME"}
                        actions={INPUT_ACTIONS}
                        {...formik.getFieldProps("contact.last_name")}
                    />
                    <Input
                        type={InputTypes.edit}
                        placeholder={"CONTACT ID"}
                        actions={INPUT_ACTIONS}
                        {...formik.getFieldProps("contact.external_id")}
                        disabled
                    />
                    <Input
                        type={InputTypes.edit}
                        placeholder={"ATLAS ONE ID"}
                        actions={INPUT_ACTIONS}
                        {...formik.getFieldProps("contact.user_id")}
                        disabled
                    />
                    <Dropdown
                        title="LANGUAGE"
                        matchTargetWidth
                        targetClassName={DropdownTargetClasses.EDIT_SELECT}
                        selectedValue={formik.values?.contact?.language}
                        contentBlockHeight={233}
                        staticOptions={[
                            { label: "English", value: "English" },
                            { label: "Spanish", value: "Spanish" },
                            { label: "French", value: "French" },
                            { label: "Italian", value: "Italian" }
                        ]}
                        position={Position.BOTTOM}
                        onStaticOptionClick={(value) => {
                            formik.setFieldValue("contact.language", value);
                        }}
                        searcHeaderProps
                        ArrowIconComponent
                    />
                    <Dropdown
                        title="TIMEZONE"
                        matchTargetWidth
                        targetClassName={DropdownTargetClasses.EDIT_SELECT}
                        selectedValue={formik.values?.contact?.timezone}
                        contentBlockHeight={233}
                        staticOptions={TIMEZONES.map((timezone) => {
                            return {
                                value: timezone.value,
                                label: timezone.value
                            };
                        })}
                        position={Position.BOTTOM}
                        searcHeaderProps
                        onStaticOptionClick={(value) => {
                            formik.setFieldValue("contact.timezone", value);
                        }}
                        ArrowIconComponent
                    />
                    <Dropdown
                        title="OPT-IN"
                        matchTargetWidth
                        targetClassName={DropdownTargetClasses.EDIT_SELECT}
                        selectedValue={formik.values?.opt_in}
                        contentBlockHeight={233}
                        staticOptions={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" }
                        ]}
                        position={Position.BOTTOM}
                        onStaticOptionClick={(value) => {
                            formik.setFieldValue("opt_in", value);
                        }}
                        ArrowIconComponent
                    />
                </CollapseWIthTitle>
                <hr />
                <CollapseWIthTitle
                    title={"CHANNELS"}
                    AddDropdown={({ onTitleClick }) => (
                        <Dropdown
                            CustomTarget={(props) => <AddValueTarget {...props} onTitleClick={onTitleClick} />}
                            selectedValue={{}}
                            staticOptions={CHANNEL_TYPES_OPTIONS}
                            onStaticOptionClick={onChannelAddClick}
                            usePortal={false}
                            position={Position.BOTTOM}
                            searcHeaderProps={{ title: "Add Channel" }}
                        />
                    )}
                >
                    {formik.values?.channels?.map((channel, index) => {
                        const key = channel.type;
                        const { identifier: value, id, phone_type } = channel;
                        if (channel.type === "phone_number") {
                            return (
                                <Input
                                    key={id}
                                    // autoFocus={channel.added}
                                    type={InputTypes.edit}
                                    outerLeftIcon={
                                        <MPDIcon
                                            IconComponent={CHANNEL_TYPE_MATCH_ICON[phone_type]}
                                            className={styles["outer-left-icon"]}
                                        />
                                    }
                                    actions={INPUT_ACTIONS}
                                >
                                    {/* <StyledFloatingPhoneInput
                                        {...formik.getFieldProps(key)}
                                        targetClassName={FloatingPhoneInputClasses.edit}
                                    /> */}
                                    <EditChannelsPhoneInput
                                        key={`${id}-phone-input`}
                                        value={value}
                                        onChange={(e) => onChangeChannelValue(e, index)}
                                        formik={formik}
                                        autoFocus={channel.added}
                                        id={id}
                                        onRemoveClick={onRemoveClick}
                                    />
                                </Input>
                            );
                        }
                        return (
                            <Input
                                key={id}
                                type={InputTypes.edit}
                                {...formik.getFieldProps(key)}
                                outerLeftIcon={
                                    <MPDIcon
                                        className={styles["outer-left-icon"]}
                                        IconComponent={CHANNEL_TYPE_MATCH_ICON[key]}
                                        style={{ flexShrink: 0 }}
                                    />
                                }
                                onChange={(e) => onChangeChannelValue(e.target.value, index)}
                                value={value}
                                autoFocus={channel.added}
                                actions={INPUT_ACTIONS}
                                onRemoveClick={onRemoveClick}
                                id={id}
                            />
                        );
                    })}
                </CollapseWIthTitle>
                <hr />
                <CollapseWIthTitle
                    title={"PLACES"}
                    showAdd
                    AddDropdown={({ onTitleClick }) => (
                        <Dropdown
                            searcHeaderProps={{ title: "Add place", withoutSearch: true }}
                            CustomTarget={(props) => <AddValueTarget {...props} onTitleClick={onTitleClick} />}
                            staticOptions={PLACE_TYPES_OPTIONS}
                            usePortal={false}
                            position={Position.BOTTOM_LEFT}
                            onStaticOptionClick={onPlaceAddClick}
                        />
                    )}
                >
                    <AnimatePresence>
                        <motion.span
                            initial={{ display: "none" }}
                            variants={EMPTY_LABEL_VARIABLES}
                            animate={formik.values?.places === null ? "visible" : "hidden"}
                            className={styles["empty-label"]}
                        >
                            No places
                        </motion.span>
                    </AnimatePresence>
                    {formik.values.places?.map((place, index) => {
                        return <EditPlace place={place} formik={formik} index={index} />;
                    })}
                </CollapseWIthTitle>
                <hr />
                <CollapseWIthTitle
                    title={"TAGS"}
                    showAdd
                    AddDropdown={({ onTitleClick, ...props }) => (
                        <AddValueTarget
                            {...props}
                            onTitleClick={() => {
                                formik.setFieldValue("tags", []);
                                onTitleClick();
                                setIsTagsDropdownOpen(true);
                            }}
                        />
                    )}
                >
                    <AnimatePresence>
                        <motion.span
                            initial={{ display: "none" }}
                            variants={EMPTY_LABEL_VARIABLES}
                            animate={formik.values?.tags === null ? "visible" : "hidden"}
                            className={styles["empty-label"]}
                        >
                            No tags
                        </motion.span>
                    </AnimatePresence>
                    {formik.values?.tags !== null && (
                        <Dropdown
                            isOpenProp={isTagsDropdownOpen}
                            onIntercationCustom={setIsTagsDropdownOpen}
                            matchTargetWidth
                            withSearch
                            targetClassName={DropdownTargetClasses.EDIT_SELECT}
                            SelectedItemComponent={({ item, remove }) => (
                                <Tag
                                    label={item?.name}
                                    onDeleteClick={(arg) => {
                                        remove(arg);
                                        if (formik.values.tags?.length === 1 && !isTagsDropdownOpen) {
                                            formik.setFieldValue("tags", null);
                                        }
                                    }}
                                />
                            )}
                            values={formik.values?.tags || []}
                            fetchDataRequest={getTags}
                            fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                            entityKey="tags"
                            contentBlockHeight={233}
                            CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => {
                                return (
                                    <GroupedDropdownContent
                                        {...props}
                                        CustomItemComponent={(props) => <TagDropdownListItem {...props} />}
                                        secondGroupName={"Add tags"}
                                        firstGroupName="Selected tags"
                                    />
                                );
                            }}
                            onClosed={() => {
                                if (!formik.values?.tags?.length) {
                                    formik.setFieldValue("tags", null);
                                }
                            }}
                            position={Position.BOTTOM}
                            onChange={(values) => {
                                formik.setFieldValue("tags", values);
                            }}
                        />
                    )}
                </CollapseWIthTitle>
                <hr />
                <CollapseWIthTitle
                    title={"LISTS"}
                    showAdd
                    AddDropdown={({ onTitleClick, ...props }) => (
                        <AddValueTarget
                            {...props}
                            onTitleClick={() => {
                                formik.setFieldValue("lists", []);
                                onTitleClick();
                                setIsListsDropdownOpened(true);
                            }}
                        />
                    )}
                >
                    <AnimatePresence>
                        <motion.span
                            initial={{ display: "none" }}
                            variants={EMPTY_LABEL_VARIABLES}
                            animate={formik.values?.lists === null ? "visible" : "hidden"}
                            className={styles["empty-label"]}
                        >
                            No lists
                        </motion.span>
                    </AnimatePresence>
                    {formik.values?.lists !== null && (
                        <Dropdown
                            isOpenProp={isListsDropdownOpened}
                            onIntercationCustom={setIsListsDropdownOpened}
                            matchTargetWidth
                            withSearch
                            targetClassName={DropdownTargetClasses.EDIT_SELECT}
                            SelectedItemComponent={({ item, remove }) => (
                                <Tag
                                    label={item?.name}
                                    onDeleteClick={(arg) => {
                                        remove(arg);
                                        if (formik.values.lists?.length === 1 && !isListsDropdownOpened) {
                                            formik.setFieldValue("lists", null);
                                        }
                                    }}
                                    type={TagTypes.list}
                                />
                            )}
                            values={formik.values?.lists || []}
                            fetchDataRequest={getContactsLists}
                            entityKey="lists"
                            contentBlockHeight={233}
                            CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => {
                                return (
                                    <GroupedDropdownContent
                                        {...props}
                                        secondGroupName={"Add to list"}
                                        firstGroupName="Selected lists"
                                    />
                                );
                            }}
                            onChange={(values) => {
                                formik.setFieldValue("lists", values);
                            }}
                            position={Position.BOTTOM}
                        />
                    )}
                </CollapseWIthTitle>
                <hr />
                <CollapseWIthTitle
                    title={"NOTES"}
                    showAdd
                    AddDropdown={({ onTitleClick, ...props }) => (
                        <AddValueTarget
                            {...props}
                            onTitleClick={() => {
                                onNoteAddClick();
                                onTitleClick();
                            }}
                        />
                    )}
                >
                    <AnimatePresence>
                        <motion.span
                            initial={{ display: "none" }}
                            variants={EMPTY_LABEL_VARIABLES}
                            animate={formik.values?.notes === null ? "visible" : "hidden"}
                            className={styles["empty-label"]}
                        >
                            No Notes
                        </motion.span>
                    </AnimatePresence>
                    {formik.values.notes?.map((note, index) => {
                        if (note.added) {
                            return (
                                <Input
                                    type={InputTypes.edit}
                                    placeholder={"NOTE"}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        const notes = [...formik.values.notes];
                                        notes[index].note = value;
                                        formik.setFieldValue("notes", notes);
                                    }}
                                    autoFocus
                                    onBlur={() => {
                                        try {
                                            if (!note.note) {
                                                const updatedNotes = formik.values.notes?.slice(1);
                                                formik.setFieldValue(
                                                    "notes",
                                                    updatedNotes.length > 0 ? updatedNotes : null
                                                );
                                            } else {
                                                const updatedNotes = [
                                                    ...formik.values.notes?.slice(0, index),
                                                    { ...omit(formik.values.notes?.[index], "added") },
                                                    ...formik.values.notes?.slice(index + 1)
                                                ];
                                                formik.setFieldValue("notes", updatedNotes);
                                            }
                                        } catch (err) {
                                            console.log("'FFF ERR >>>>'", err);
                                        }
                                    }}
                                    actions={INPUT_ACTIONS}
                                />
                            );
                        }
                        return <NotesCard note={note} />;
                    })}
                </CollapseWIthTitle>
            </div>
        </>
    );
};
