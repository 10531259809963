import { useMemo } from "react";
import { BarChart, Bar, ResponsiveContainer } from "recharts";
import pluralize from "pluralize";
import { TooltipClsasses, Tooltip } from "../tooltip/tooltip";
import { Position } from "@blueprintjs/core";
import styles from "./contact-activity-chart.module.scss";

export const ContactActivityChart = ({ data }) => {
    const alertConvosCounter = useMemo(() => {
        let val = { conversations: 0, alerts: 0 };
        if (data) {
            Object.keys(data).forEach((key) => {
                const stats = data[key];
                val = {
                    conversations: val.conversations + stats?.conversations,
                    alerts: val.alerts + stats?.alerts
                };
            });
        }

        return val;
    }, [data]);

    const formattedData = useMemo(() => {
        if (data) {
            return Object.keys(data).map((key) => {
                return data[key];
            });
        }

        return [];
    }, [data]);
    return (
        <Tooltip
            lazy
            hoverOpenDelay={1000}
            portalClassName={TooltipClsasses.primary}
            position={Position.BOTTOM}
            content={
                <div className={styles[`activity-tooltip-content`]}>
                    <h5 className={styles[`activity-tooltip-title`]}>Activity in last 12 months</h5>
                    <div className={styles[`activity-tooltip-item`]}>
                        {" "}
                        {`${alertConvosCounter?.conversations} Inbound ${pluralize(
                            "Conversation",
                            alertConvosCounter?.conversations
                        )}`}
                    </div>
                    <div className={styles[`activity-tooltip-item`]}>{`${
                        alertConvosCounter?.alerts
                    } Outbound ${pluralize("Alert", alertConvosCounter?.alerts)}`}</div>
                </div>
            }
            tootltipTarget={
                <div>
                    <ResponsiveContainer width={110} height={34}>
                        <BarChart width={110} height={34} barGap={2} data={formattedData} barSize={4}>
                            {/* <Tooltip /> */}
                            <Bar
                                isAnimationActive={false}
                                dataKey="alerts"
                                stackId="a"
                                fill="#1F76FF"
                                background={{ fill: "#D9D9D9" }}
                                margin={{
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0
                                }}
                                // margin={0}
                                // barGap={2}
                            />
                            <Bar dataKey="conversations" stackId="a" fill="#004AC7" isAnimationActive={false} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                // <div style={{ width: "50px", height: "50px", background: "lime" }} />
            }
        />
    );
};

// import React, { memo } from "react";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
// import { Bar } from "react-chartjs-2";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export const options = {
//     plugins: {
//         // title: {
//         //     display: true,
//         //     text: "Chart.js Bar Chart - Stacked"
//         // }
//     },
//     responsive: true,
//     scales: {
//         x: {
//             stacked: true
//         },
//         y: {
//             stacked: true
//         }
//     },
//     animation: false
// };

// const labels = ["January", "February"];

// function randomIntFromInterval(min, max) {
//     // min and max included
//     return Math.floor(Math.random() * (max - min + 1) + min);
// }

// export const data1 = {
//     labels,
//     datasets: [
//         {
//             label: "",
//             data: labels.map(() => 100),
//             backgroundColor: "rgb(255, 99, 132)"
//         },
//         {
//             label: "",
//             data: labels.map(() => 700),
//             backgroundColor: "rgb(75, 192, 192)"
//         },
//         {
//             label: "",
//             data: labels.map(() => 300),
//             backgroundColor: "rgb(53, 162, 235)"
//         }
//     ]
// };

// export const ContactActivityChart = memo(({ data }) => {
//     return (
//         <div style={{ width: "110px" }}>
//             <Bar options={options} data={data1} />
//         </div>
//     );
// });
