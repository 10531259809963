import { Route, Switch } from "react-router";
import { ProfileAutomationsList } from "./profile-automations-list/profile-automations-list";

export const ProfileAutomations = () => {
    return (
        <Switch>
            <Route
                path={"/account-settings/profile-details"}
                exact
                render={() => <ProfileAutomationsList />}
            />
        </Switch>
    );
};
