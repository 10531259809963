import { Text } from "@blueprintjs/core";
import { MPDIcon } from "src/mpd-library";
import styles from "./styles.module.scss";
import { ReactComponent as CautionEmptyIcon } from "../../mpd-library/icon/assets/caution-empty.svg"

export const NotFound = () => {
    return (
        <div className={styles[`not-found-wrapper`]}>
            <MPDIcon IconComponent={CautionEmptyIcon} />
            <Text className={styles["title"]}>404</Text>
            <Text className={styles["subtitle"]}>Not found</Text>
        </div>
    );
};
