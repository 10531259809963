import { Position, Spinner, Text, Toaster } from "@blueprintjs/core";
import classNames from "classnames";
import { SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    onDeleteNotifications,
    onMarkNotificationsAsRead,
    muteNotifications
} from "src/actions/notifications/notifications-api";
import { Classes, MPDIcon, MPDSelect, MPDSelectItem } from "src/mpd-library";
import { getSelectedConversationAndId } from "src/selectors";
import { Notification } from "src/store";
import styles from "./styles.module.scss";
import { ReactComponent as IncomingIcon } from "src/mpd-library/icon/assets/drawer-download.svg";
import { ReactComponent as AutoResponseIcon } from "src/mpd-library/icon/assets/auto-response.svg";
import { ReactComponent as AssignNotificationIcon } from "src/mpd-library/icon/assets/assign-notification-icon.svg";
import { ReactComponent as AtIcon } from "src/mpd-library/icon/assets/at-icon.svg";
import { ReactComponent as VerticalTripleLineIcon } from "src/mpd-library/icon/assets/vertical-triple-line.svg";
// assign-notification-icon

const classname = "push-notification";

const eventIcons = {
    incoming: IncomingIcon,
    reply: AutoResponseIcon,
    assign: AssignNotificationIcon,
    mention: AtIcon
};

type ConversationsPushNotificationProps = {
    notification: Notification;
    toast: Toaster;
};

export const ConversationsPushNotification = ({ notification, toast }: ConversationsPushNotificationProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [archiveNotificationProcessing, setArchiveNotificationProcessing] = useState(false);
    const [markNotificationAsReadProcessing, setMarkNotificationAsReadProcessing] = useState(false);
    const [muteNotificationsProcessing, setMuteNotificationsProcessing] = useState(false);
    const { selectedConversationId } = useSelector(getSelectedConversationAndId);

    const onPushNotificationClick = () => {
        if (notification.object.type === "Conversation") {
            toast.dismiss(notification.id);
            if (selectedConversationId === notification.object.id) {
                return;
            }
            dispatch(onMarkNotificationsAsRead([notification.id]));
            history.push(`/conversations/${notification.object.id}`);
        }
    };

    const onMarkAsReadClick = async (e: SyntheticEvent) => {
        e.stopPropagation();
        try {
            setMarkNotificationAsReadProcessing(true);
            await dispatch(onMarkNotificationsAsRead([notification.id]));
        } finally {
            setMarkNotificationAsReadProcessing(false);
            toast.dismiss(notification.id);
        }
    };

    const onArchiveClick = async (e: SyntheticEvent) => {
        e.stopPropagation();
        try {
            setArchiveNotificationProcessing(true);
            await dispatch(onDeleteNotifications([notification.id]));
        } finally {
            setArchiveNotificationProcessing(false);
            toast.dismiss(notification.id);
        }
    };

    const onMuteClick = async (e: SyntheticEvent) => {
        e.stopPropagation();
        try {
            setMuteNotificationsProcessing(true);
            await dispatch(muteNotifications());
        } finally {
            setMuteNotificationsProcessing(false);
            toast.dismiss(notification.id);
        }
    };

    return (
        <div className={styles[classname]} onClick={onPushNotificationClick}>
            <div className={styles[`${classname}-more-dropdown-wrapper`]}>
                <MPDSelect
                    className={classNames(
                        "rounded-dropdown",
                        "only-icon",
                        styles[`${classname}-dropdown`],
                        styles[`${classname}-dropdown-more`],
                        "more-dropdown"
                    )}
                    stopPropagation
                    customOptions={
                        <div className={`simple-dropdwon`}>
                            <MPDSelectItem className={styles[`${classname}-item`]} onClick={onArchiveClick}>
                                <>
                                    <Text>Archive </Text>
                                    {!!archiveNotificationProcessing && <Spinner size={20} intent={"primary"} />}
                                </>
                            </MPDSelectItem>
                            <MPDSelectItem className={styles[`${classname}-item`]} onClick={onMarkAsReadClick}>
                                <>
                                    <Text>Mark as Read</Text>
                                    {!!markNotificationAsReadProcessing && <Spinner size={20} intent={"primary"} />}
                                </>
                            </MPDSelectItem>
                            <MPDSelectItem className={styles[`${classname}-item`]} onClick={onMuteClick}>
                                <>
                                    <Text>Mute </Text>
                                    {!!muteNotificationsProcessing && <Spinner size={20} intent={"primary"} />}
                                </>
                            </MPDSelectItem>
                        </div>
                    }
                    arrowIcon={null}
                    LeftIconComponent={VerticalTripleLineIcon}
                    position={Position.BOTTOM_RIGHT}
                    value={""}
                    cannotChange={true}
                // onChange={onThreeDotButtonSelectOption}
                />
            </div>
            <div className={styles[`left-wrapper`]}>
                <div className={styles[`icon-wrapper`]}>
                    <MPDIcon
                        IconComponent={eventIcons[notification.type]}
                        className={styles["event-icon"]}
                        width={22}
                        height={22}
                    />
                </div>
                <div className={Classes.JUSTIFY_CENTER}>
                    <Text className={styles[`event-type`]}> {notification.type} </Text>
                    <Text className={styles[`event-title`]}> {notification.title} </Text>
                    <Text className={styles[`event-description`]}> {notification.body.summary} </Text>
                </div>
            </div>
        </div>
    );
};
