import { StyledMapMarker as StyledMarkerType } from "./styled-map";
import { lazy, Suspense } from "react";

const StyledMapComponent = lazy(() => import("./styled-map") /* webpackChunkName: "styled-map" */);

const StyledMap = (props) => {
    return (
        <Suspense
            fallback={<div style={{ backgroundColor: "#E6E3DF", display: "flex", width: "100%", height: "100%" }} />}
        >
            <StyledMapComponent {...props} />
        </Suspense>
    );
};

export type StyledMapMarker = StyledMarkerType;
export default StyledMap;
