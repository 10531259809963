import { useSelector } from "react-redux";
import { AccessPermissions } from "../../containers/account-settings/components";
import { AppState } from "../../store";

import { ReactComponent as GearsIcon } from "src/mpd-library/icon/assets/gears.svg";
import { ReactComponent as FlashIcon } from "src/mpd-library/icon/assets/flash.svg";
import { ReactComponent as StudioIcon } from "src//mpd-library/icon/assets/design-tool-wireframe.svg";
import { ReactComponent as ConversationsIcon } from "src/mpd-library/icon/assets/conversations-icon.svg";
import { ReactComponent as SidebarContactsIcon } from "src/mpd-library/icon/assets/SidebarContactsIcon.svg";

import styles from "./Permissions.module.scss";
import { FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";

type PremissionsProps = {
    formik: FormikValues;
    disabledCheckboxes?: boolean;
};

const test = ["Administrator Settings", "Alerts", "Studio", "Conversations Settings", "Contacts Settings"];

export const Permissions = ({ formik, disabledCheckboxes }: PremissionsProps) => {
    const privileges = useSelector((state: AppState) => state.accountSettings.general.privileges);
    const { conversations_enabled, contacts_enabled } = useSelector(
        (state: AppState) => state.accountSettings.organization
    );

    const { setFieldValue } = formik;

    const getAllScopes = () => {
        if (!privileges) {
            return [];
        }

        const allScopes = privileges.map((privilage) => privilage.scope);
        const scopes = new Set(allScopes);

        const currentRoleAllScopes = formik.values?.privileges?.map(
            (currentUserPrivilage) => currentUserPrivilage.scope
        );
        const currentRoleScopes = new Set(currentRoleAllScopes);

        const res = {};
        scopes.forEach((scope) => {
            res[scope] = currentRoleScopes.has(scope);
        });

        return res;
    };

    useEffect(() => {
        if (privileges.length) {
            setSelectedScopes(getAllScopes());
        }
    }, [privileges.length, formik.values?.privileges]);

    const [selectedScopes, setSelectedScopes] = useState(getAllScopes());

    console.log("getAllScopes()", privileges, selectedScopes, getAllScopes());

    const onSwitchChange = (scope) => {
        const isScopeAdded = !selectedScopes[scope];
        setSelectedScopes({ ...selectedScopes, [scope]: !selectedScopes[scope] });
        let privilegesToState = [];
        if (!isScopeAdded) {
            privilegesToState = formik.values.privileges?.filter((privilege) => privilege.scope !== scope);
            setFieldValue("privileges", privilegesToState);
        }
    };

    const onCheckBoxChange = (privilege, event) => {
        let privilegesToState = [...formik.values.privileges];

        if (event.target.checked) {
            privilegesToState.push(privilege);
        } else {
            privilegesToState =
                formik.values.privileges?.filter((currentUserPrivilege) => currentUserPrivilege.id !== id) || undefined;
        }

        setFieldValue("privileges", privilegesToState);
    };

    const onRadioButtonClick = (id, event) => {
        const privilege = privileges.find((privilege) => privilege.id === id);
        const updatedValue = [
            ...formik.values.privileges.filter((existingPrivilege) => existingPrivilege.scope !== privilege.scope),
            privilege
        ];
        setFieldValue("privileges", updatedValue);
    };

    console.log("selectedScopes[test[0]]", selectedScopes, selectedScopes[test[0]]);
    return (
        <>
            <AccessPermissions
                disabledCheckboxes={disabledCheckboxes}
                className={styles["access-permissions"]}
                IconComponent={GearsIcon}
                onCheckBoxChange={onCheckBoxChange}
                onSwitchChange={() => onSwitchChange("Administrator Settings")}
                showSimple={selectedScopes[test[0]]}
                title="Administrator Settings"
                showSwitch={true}
                checkBoxList={
                    privileges
                        ?.filter((privilege) => {
                            if (privilege.scope === "Administrator Settings") {
                                if (
                                    !conversations_enabled &&
                                    privilege.feature_hrid === "conversations_manage_settings"
                                ) {
                                    return false;
                                }
                                return true;
                            }

                            return false;
                        })
                        .map((privilege) => ({
                            ...privilege,
                            text: privilege.title,
                            id: privilege.id,
                            checked: formik.values.privileges?.some(
                                (currentUserPrivilege) => currentUserPrivilege.id === privilege.id
                            )
                        })) || []
                }
            />
            <AccessPermissions
                disabledCheckboxes={disabledCheckboxes}
                className={styles["access-permissions"]}
                IconComponent={FlashIcon}
                onCheckBoxChange={onCheckBoxChange}
                onSwitchChange={() => onSwitchChange("Alerts")}
                showSimple={selectedScopes[test[1]]}
                checkBoxList={
                    privileges
                        ?.filter((privilege) => privilege.scope === "Alerts")
                        .map((privilege) => ({
                            ...privilege,
                            text: privilege.title,
                            id: privilege.id,
                            checked: formik.values.privileges?.some(
                                (currentUserPrivilege) => currentUserPrivilege.id === privilege.id
                            )
                        })) || []
                }
                title="Alerts"
                showSwitch={true}
            />
            <AccessPermissions
                disabledCheckboxes={disabledCheckboxes}
                className={styles["access-permissions"]}
                IconComponent={StudioIcon}
                onCheckBoxChange={onCheckBoxChange}
                onSwitchChange={() => onSwitchChange("Studio")}
                showSimple={selectedScopes[test[2]]}
                title="Studio"
                showSwitch={true}
                checkBoxList={
                    privileges
                        ?.filter((privilege) => privilege.scope === "Studio")
                        .map((privilege) => ({
                            ...privilege,
                            text: privilege.title,
                            id: privilege.id,
                            checked: formik.values.privileges?.some(
                                (currentUserPrivilege) => currentUserPrivilege.id === privilege.id
                            )
                        })) || []
                }
            />
            {conversations_enabled && (
                <AccessPermissions
                    disabledCheckboxes={disabledCheckboxes}
                    className={styles["access-permissions"]}
                    IconComponent={ConversationsIcon}
                    onRadioButtonClick={onRadioButtonClick}
                    onSwitchChange={() => onSwitchChange("Conversations Settings")}
                    showSimple={selectedScopes[test[3]]}
                    title="Conversations Inbox"
                    showSwitch={true}
                    selectedRadioValue={
                        formik.values.privileges?.find((privilege) => privilege.scope === "Conversations Settings")?.id
                    }
                    radioButtonList={
                        privileges
                            ?.filter((privilege) => privilege.scope === "Conversations Settings")
                            .map((privilege) => ({
                                ...privilege,
                                text: privilege.title,
                                id: privilege.id,
                                checked: formik.values.privileges?.some(
                                    (currentRolePrivilege) => currentRolePrivilege.id === privilege.id
                                )
                            })) || []
                    }
                />
            )}
            {contacts_enabled && (
                <AccessPermissions
                    disabledCheckboxes={disabledCheckboxes}
                    className={styles["access-permissions"]}
                    IconComponent={SidebarContactsIcon}
                    onCheckBoxChange={onCheckBoxChange}
                    onSwitchChange={() => onSwitchChange("Contacts Settings")}
                    showSimple={selectedScopes[test[4]]}
                    title="Contacts Settings"
                    showSwitch={true}
                    checkBoxList={
                        privileges
                            ?.filter((privilege) => privilege.scope === "Contacts Settings")
                            .map((privilege) => ({
                                ...privilege,
                                text: privilege.title,
                                id: privilege?.id,
                                checked: formik.values.privileges.some(
                                    (currentRolePrivilege) => currentRolePrivilege?.id === privilege?.id
                                )
                            })) || []
                    }
                />
            )}
        </>
    );
};
