import {
    AdminMention,
    Conversation,
    ConversationFilter,
    ConversationListItem,
    ConversationQueue,
    ConversationsCounts,
    ViewData,
    ViewEnum,
    GlobalViewEnum
} from "src/store/conversations";

export const GET_CONVERSATIONS = "get-conversations";
export const SET_SELECTED_CONVERSATION_ID = "set-selected-conversation-id";

export const SET_GETTING_CONVERSATIONS_MESSAGES_STATUS = "set-getting-conversations-messages-status";

export const UPDATE_CONVERSATION = "update-conversation";

export const REMOVE_CONVERSATION_FROM_LIST = "remove-conversation-from-list";

export const UPDATE_GET_CONVVERSATIONS_TAGS_STATUS = "update-getconversations-tags-status";

export const SET_GETTING_QUEUES_STATUS = "set-getting-queues-status";

export const SET_SELECTED_CONVERSATION = "set-selected-conversation";

export const SET_VIEW_TYPE = "set-view-type";

export const SET_ADMINS = "set-admins";

export const SET_SELECTED_QUEUE = "set-selected-queue";

export const SET_CONVERSATIONS_COUNT = "set-conversations-count";

export const SET_DEFAULT_STATE = "set-default-state";

export const ACCEPT_FILTER_VALUES = "accept-filter-values";

export const SET_CONVERSATIONS_VIEWS = "set-conversations-views";

export const SET_SELECTED_VIEW_DATA = "set-view-id";

export const UPDATE_CONVERSATIONS_LOADING_STATUS = "update-conversations-loading-status";

export const SET_HAS_MORE_CONVERSATIONS = "set-has-more-conversations";

export const SET_QUEUE_DETAILS = "set-queue-details";

export const UPDATE_ACCOUNT_SETTINGS_QUEUE = "update-account-settings-queue";

export const DELETE_SAVED_VIEW = "delete-saved-view";

export const SET_ACCESS_ERROR_TO_CONVERSATION = "set-access-error-to-conversation";

export const SET_CONVERSATIONS_COUNTER = "set-conversations-counter";

export const SET_PREVIOUS_CONVERSATIONS_WITH_SENDER = "set-previous-conversations-with-sender";

export const SET_HAS_MORE_CONVERSATIONS_WITH_SENDER = "set-has-more-prev-conversations-with-sender";

export const SET_BASED_ON_PERMISSIONS_ADMINS = "set-based-on-permissions-admins";

export const SET_GLOBAL_VIEW = "set-global-view";

export const SET_TO_ZERO_CONVERSATION_UNREAD_COUNT = "set-to-zero-conversation-unread-count";

export const UPDATE_CONVERSATIONS_IDS_LIST = "update-conversations-ids-list";

export const UDPATE_CONVERSATIONS_USER = "update-conversations-user";

export type UpdateConversationsUser = {
    type: typeof UDPATE_CONVERSATIONS_USER;
    payload: any;
};

export type UpdateConversationsIdsList = {
    type: typeof UPDATE_CONVERSATIONS_IDS_LIST;
    payload: {
        viewType?: ViewEnum;
        conversationsIds: Array<string>;
    };
};
export type RemoveConversationFromList = {
    type: typeof REMOVE_CONVERSATION_FROM_LIST;
    payload: {
        conversationId: string;
        viewType?: ViewEnum;
    };
};

export type UpdateConversation = {
    type: typeof UPDATE_CONVERSATION;
    payload: Conversation;
};

export type SetToZeroConversationUnreadCount = {
    type: typeof SET_TO_ZERO_CONVERSATION_UNREAD_COUNT;
    payload: {
        conversationId: string;
    };
};

export type SetGlobalView = {
    type: typeof SET_GLOBAL_VIEW;
    payload: GlobalViewEnum;
};

export type SetBasedOnPermissionsAdmins = {
    type: typeof SET_BASED_ON_PERMISSIONS_ADMINS;
    payload: any;
};

export type SetHasMoreConversationsWithSender = {
    type: typeof SET_HAS_MORE_CONVERSATIONS_WITH_SENDER;
    payload: boolean;
};

export type SetPreviousConversationsWithSender = {
    type: typeof SET_PREVIOUS_CONVERSATIONS_WITH_SENDER;
    payload: Array<Conversation>;
};

export type SetConversationsCounter = {
    type: typeof SET_CONVERSATIONS_COUNTER;
    payload: number;
};

type SetAccessErrorToConversation = {
    type: typeof SET_ACCESS_ERROR_TO_CONVERSATION;
    payload: string;
};

type DeleteSavedView = {
    type: typeof DELETE_SAVED_VIEW;
    payload: string;
};

type UpdateAccountSettingsQueue = {
    type: typeof UPDATE_ACCOUNT_SETTINGS_QUEUE;
    payload: ConversationQueue;
};

type SetQueueDetails = {
    type: typeof SET_QUEUE_DETAILS;
    payload: ConversationQueue;
};

type SetHasMoreConversations = {
    type: typeof SET_HAS_MORE_CONVERSATIONS;
    payload: boolean;
};

type UpdateConversationLoadingStatus = {
    type: typeof UPDATE_CONVERSATIONS_LOADING_STATUS;
    payload: boolean;
};

type GetConversationsAction = {
    type: typeof GET_CONVERSATIONS;
    payload: Array<ConversationListItem>;
    isSearching: boolean;
    refresh: boolean;
    conversationsGroupName?: string;
};

type SetSelectedConversationId = {
    type: typeof SET_SELECTED_CONVERSATION_ID;
    payload: string;
};

type SetGettingConversationsMessagesStatus = {
    type: typeof SET_GETTING_CONVERSATIONS_MESSAGES_STATUS;
    payload: boolean;
};

type UpdateGetConversationsTagsStatus = {
    type: typeof UPDATE_GET_CONVVERSATIONS_TAGS_STATUS;
    payload: boolean;
};

type SetGettingQueuesStatus = {
    type: typeof SET_GETTING_QUEUES_STATUS;
    payload: boolean;
};

type SetSelectedConversation = {
    type: typeof SET_SELECTED_CONVERSATION;
    payload: Conversation;
};

type SetViewType = {
    type: typeof SET_VIEW_TYPE;
    payload: ViewEnum;
};

type SetAdmins = {
    type: typeof SET_ADMINS;
    adminsList: Array<AdminMention>;
    hasMoreAdmins: boolean;
};

type SetSelectedQueueId = {
    type: typeof SET_SELECTED_QUEUE;
    payload: string;
};

type SetConversationsCount = {
    type: typeof SET_CONVERSATIONS_COUNT;
    payload: Partial<ConversationsCounts>;
};

type SetDefaultState = {
    type: typeof SET_DEFAULT_STATE;
    viewType: ViewEnum;
};

type AcceptFilterValues = {
    type: typeof ACCEPT_FILTER_VALUES;
    payload: ConversationFilter;
};

type SetConversationsViews = {
    type: typeof SET_CONVERSATIONS_VIEWS;
    payload: any;
};

type SetViewId = {
    type: typeof SET_SELECTED_VIEW_DATA;
    payload: ViewData;
};

export type ConversationsActionTypes =
    | GetConversationsAction
    | SetSelectedConversationId
    | SetGettingConversationsMessagesStatus
    | UpdateGetConversationsTagsStatus
    | SetGettingQueuesStatus
    | SetSelectedConversation
    | SetViewType
    | SetAdmins
    | SetSelectedQueueId
    | SetConversationsCount
    | SetDefaultState
    | AcceptFilterValues
    | SetConversationsViews
    | SetViewId
    | UpdateConversationLoadingStatus
    | SetHasMoreConversations
    | SetQueueDetails
    | UpdateAccountSettingsQueue
    | DeleteSavedView
    | SetAccessErrorToConversation
    | SetConversationsCounter
    | SetPreviousConversationsWithSender
    | SetHasMoreConversationsWithSender
    | SetBasedOnPermissionsAdmins
    | UpdateConversation
    | RemoveConversationFromList
    | UpdateConversationsIdsList
    | UpdateConversationsUser;
