export const formattedTextTypes = {
    default: "default" as const,
    detailed: "detailed" as const,
    short: "short" as const
};

// export type formattedTextTypes = typeof formattedTextTypes[keyof typeof formattedTextTypes];

export const dateTypes = {
    unix: "unix" as const,
    dateTime: "dateTime" as const
};

// export type dateTypes = typeof dateTypes[keyof typeof dateTypes];
