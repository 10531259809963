import classNames from "classnames";
import React from "react";
// import * as myClasses from "../";

import { IProps, Text } from "@blueprintjs/core";

import { StyledButton } from "src/components";

import "./styles.scss";
import { ReactComponent as CloseIcon } from "../../../../mpd-library/icon/assets/close.svg"

export interface IStyledAlertGlobalContentProps extends IProps {
    hideTitle?: boolean;
    hideDescription?: boolean;
    title?: string;
    description?: string;
    onCancel?: (event: React.SyntheticEvent) => void;
}

export const StyledAlertGlobalContent: React.FunctionComponent<IStyledAlertGlobalContentProps> = (props) => {
    const { className, description, onCancel, title, hideDescription, hideTitle } = props;

    const classes = "styled-alert-global-content";

    return (
        <>
            <div className={classNames(classes + "-top", hideDescription && "add-margin")}>
                <div style={{ display: "flex", flex: "1", maxWidth: '32px' }} />
                {!hideTitle && (
                    <Text className={classes + "-title"}> {title ? title : "Are You Sure You Want To Leave?"} </Text>
                )}
                {onCancel && <StyledButton className={classes + "-close"} IconComponent={CloseIcon} onClick={onCancel} />}
            </div>
            <div className={classNames(classes, className)}>
                {!hideDescription && (
                    <Text className={classes + "-description"}>
                        {" "}
                        {description ? description : "All your changes will be discarded"}{" "}
                    </Text>
                )}
            </div>
        </>
    );
};
