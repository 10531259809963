import {
    addShapeImages,
    saveDraft,
    createAlert,
    updateComposeBottomToolBar,
    updateChooseAudience,
    updateAlertDetails,
    updateMessageDetails,
    updateMultiMedia,
    updatePreviewSend,
    deleteAlert,
    hideCompose,
    showIncidentLocationWarning,
    updateComposeDataErrors,
    UpdateComposeDataErrorsProps,
    updateSelectChannelsArray,
    putThumbnails,
    updateSocialMediaDesign,
    updateSocialMediaTheme,
    setGeneratedPreview,
    updateGeneratePreviewProcessingStatus,
} from "./alerts-compose-api";
import { Dispatch } from "redux";
import { AppState, ComposeDataAlertDetails, ComposeDataChooseAudience } from "../../store";
import { RouteComponentProps } from "react-router";
import { ReactElement } from "react";
import { ListsIdTypes } from "src/containers";

export interface OnUpdateComposeBottomToolBarProps {
    leftElement: ReactElement;
    rightElement: ReactElement;
}
export const onUpdateComposeBottomToolBar = (bottomBarProps: OnUpdateComposeBottomToolBarProps | null) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const composeData = getState().alerts.composeData;
        dispatch(updateComposeBottomToolBar({ bottomBarProps, composeData }));
    };
};

export interface OnUpdateMessageDetailsProps {
    moreInfo: boolean;
    description: string;
    headline: string;
    instructions: string;
    wea: string;
}
export const onUpdateMessageDetails = (messageDetails: OnUpdateMessageDetailsProps) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const composeData = getState().alerts.composeData;
        dispatch(updateMessageDetails({ messageDetails, composeData }));
    };
};

export const onPutThumbnails = (file: any) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(putThumbnails(file));
    };
}

export const onUpdateMultiMedia = (
    multiMedia: any,
    customParams?: {
        id: string;
        setFinishedStatusForOrderedFile: (id: string) => void;
    },
    upload?: boolean
) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const composeData = getState().alerts.composeData;
        dispatch(updateMultiMedia({ multiMedia, composeData, customParams, upload }));
    };
};

export const onUpdateAlertDetails = (alertDetails: ComposeDataAlertDetails) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateAlertDetails({ alertDetails }));
    };
};

export const onUpdateChooseAudience = (chooseAudience: ComposeDataChooseAudience) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const { composeData } = getState().alerts;
        dispatch(updateChooseAudience({ chooseAudience, composeData }));
    };
};

export const onUpdateSelectChannelsArray = (selectChannelsArray: Array<string>, changesMade?: boolean) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const composeData = getState().alerts.composeData;
        dispatch(updateSelectChannelsArray({ selectChannelsArray, composeData, changesMade: changesMade ? changesMade : false }));
    };
};

export const onCreateAlert = (launchCodes: Array<string>, id: string, routeProps: RouteComponentProps) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(
            createAlert({
                id,
                launchCodes,
                routeProps,
                status: "active",
            })
        );
    };
};

export const onDeleteAlert = (id: string, routeProps: RouteComponentProps, tabId: ListsIdTypes) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const state = getState();
        const { alerts } = state;
        const { alert } = alerts;
        dispatch(deleteAlert({ alert, id, routeProps, tabId }));
    };
};

export const onAddShapeImages = (shape: any) => {
    return addShapeImages(shape);
};

export const onSaveDraft = (
    id: string,
    routeProps: RouteComponentProps,
    nextStepperRoute: string,
    navToAnotherAlert?: boolean,
    callBack?: any
) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(
            saveDraft({
                id,
                routeProps,
                nextStepperRoute,
                navToAnotherAlert,
                callBack,
            })
        );
    };
};

export const onUpdatePreviewSend = () => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const state = getState();
        const { alerts } = state;
        const { composeData } = alerts;
        dispatch(updatePreviewSend({ composeData }));
    };
};

export const onHideCompose = (id: string, routeProps: RouteComponentProps) => {
    return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const state = getState();
        const { alerts } = state;
        const { alert } = alerts;
        hideCompose({ routeProps, alert, id });
    };
};

export const onShowIncidentLocationWarning = () => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(showIncidentLocationWarning());
    };
};

export const onUpdateComposeDataErrors = ({ errorBlock, errorField, errorValue }: UpdateComposeDataErrorsProps) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateComposeDataErrors({ errorBlock, errorField, errorValue }));
    };
};

export const onUpdateSocialMediaDesign = (value: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateSocialMediaDesign(value));
    };
};

export const onUpdateSocialMediaTheme = (value: string) => {
 return (dispatch: Dispatch<AppState>) => {
     dispatch(updateSocialMediaTheme(value));
 };
};

export const onSetGeneratedPreview = (payload: any) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(setGeneratedPreview(payload));
    };
};

export const onUpdateGeneratePreviewProcessingStatus = (status: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch(updateGeneratePreviewProcessingStatus(status));
    };
}