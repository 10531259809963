import { ONBOARDING_UPDATE, OnboardingActionTypes } from "../actions/on-boarding/types";
import { OnBoardingState, defaultOnBoardingState } from "../store";

export const onBoardingReducer = (state: OnBoardingState = defaultOnBoardingState, action: OnboardingActionTypes) => {
    switch (action.type) {
        case ONBOARDING_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
