import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import moment from "moment";
import { FormEvent, FormEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { onMarkNotificationsAsRead } from "src/actions/notifications/notifications-api";
import { StyledCheckbox } from "src/components/styled-checkbox/styled-checkbox";
import { useReauthChannel } from "src/containers/conversations/components/chat/hooks/useReauthChannel";
import { Classes, MPDIcon } from "src/mpd-library";
import { ConversationsNotification } from "src/store";
import styles from "./styles.module.scss";
import { ReactComponent as DrawerDownloadIcon } from "../../../..//mpd-library/icon/assets/NotificationItemDrawer.svg";
import { ReactComponent as AutoResponseIcon } from "../../../..//mpd-library/icon/assets/auto-response.svg";
import { ReactComponent as AssignNotificationIcon } from "../../../..//mpd-library/icon/assets/assign-notification-icon.svg";
import { ReactComponent as AtIcon } from "../../../..//mpd-library/icon/assets/at-icon.svg";
import { ReactComponent as ServerSettingIcon } from "../../../..//mpd-library/icon/assets/server-settings.svg";

const classname = "notifications-item";

moment.defineLocale("en-cust1", {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        ss: "%d secs",
        m: "a min ago",
        mm: "%d mins ago",
        h: "an hour",
        hh: "%dh ago",
        d: "a day ago",
        dd: "%d days ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
    }
});

type NotificationsPopoverItemProps = {
    isLoadingNotificationsList: boolean;
    notification: ConversationsNotification;
    isSettingsModeOn: boolean;
    checked?: boolean;
    isSelectAllChecked?: boolean;
    onClickChckbox?: (event: FormEvent<HTMLInputElement>, id: string) => void;
    onClosePopover?: () => void;
};

const iconsTypes = {
    incoming: DrawerDownloadIcon,
    reply: AutoResponseIcon,
    assign: AssignNotificationIcon,
    mention: AtIcon,
    account_channel_offline: ServerSettingIcon
};

export const NotificationsPopoverItem = ({
    isLoadingNotificationsList = true,
    notification,
    isSettingsModeOn,
    checked,
    isSelectAllChecked,
    onClickChckbox,
    onClosePopover
}: NotificationsPopoverItemProps) => {
    const [shouldShowCheckbox, setShouldShowCheckbox] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { onGoToChannelPage } = useReauthChannel();

    const selectCheckbox: FormEventHandler<HTMLInputElement> = (event: FormEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        onClickChckbox(event, notification.id);
    };

    const skeltonClass = isLoadingNotificationsList ? "bp4-skeleton" : "";

    const onNotificationClick = () => {
        if (notification.object.type === "Conversation") {
            history.push(`/conversations/${notification.object?.id}`);
            dispatch(onMarkNotificationsAsRead([notification.id]));
            onClosePopover();
        }

        if (notification.type === "account_channel_offline") {
            const { object } = notification;
            onGoToChannelPage(object.id, object.channel_type);
            dispatch(onMarkNotificationsAsRead([notification.id]));
            onClosePopover();
        }
    };

    return (
        <div
            className={classNames(
                styles[classname],
                typeof notification.is_read !== "undefined" && !notification.is_read && styles["unread"]
            )}
            onClick={onNotificationClick}
            onMouseEnter={() => setShouldShowCheckbox(true)}
            onMouseLeave={() => setShouldShowCheckbox(false)}
        >
            <div className={styles[`left-wrapper`]}>
                <div
                    className={classNames(
                        styles[`icon-wrapper`],
                        notification.type === "account_channel_offline" && styles["account-channel-offline"],
                        skeltonClass,
                        (isSettingsModeOn || checked) && styles[`settings-mode-icon-wrapper`]
                    )}
                >
                    {!shouldShowCheckbox && notification?.type && !isSettingsModeOn && !checked && (
                        <MPDIcon IconComponent={iconsTypes[notification?.type]} width={22} height={22} />
                    )}
                    {(shouldShowCheckbox || isSettingsModeOn || checked) && (
                        <StyledCheckbox
                            checked={checked || isSelectAllChecked}
                            onChange={selectCheckbox}
                            className={styles["checkbox"]}
                        />
                    )}
                </div>
                <div className={classNames(Classes.JUSTIFY_CENTER, styles[`${classname}-main-info-wrapper`])}>
                    <div className={classNames(styles[`${classname}-type-date-wrapper`], skeltonClass)}>
                        <Text className={styles[`event-type`]}>{notification?.type_formatted}</Text>
                        <Text className={styles[`from-now`]}>
                            {moment(notification?.inserted_at).locale("en-cust1").fromNow()}
                        </Text>
                    </div>
                    <Text className={classNames(styles[`event-title`], skeltonClass)}>{notification?.title}</Text>
                    <Text className={classNames(styles[`event-description`], skeltonClass)}>
                        {notification?.body?.summary}
                    </Text>
                </div>
            </div>
        </div>
    );
};
