import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { IDetailView } from "../../../";
import { MPDSelect, MPDSelectItem, MPDSelectTargetBorderRoundingClasses } from "src/mpd-library";
import { SettingsAuditLogListItem } from "../../components";
import { ListHeader } from "./components";
import { BreadCrumbs, ContainerEmpty, StartEndDateTimer } from "../../../../components";
import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { RouteComponentProps } from "react-router";
import moment, { Moment } from "moment";
import { Position, Text } from "@blueprintjs/core";
import { UsersFilterOption } from "./types";
import { PERIODS } from "./constants";
import { CSSTransition } from "react-transition-group";
import { AssignDropdown } from "src/containers/conversations/components/chat/components/chat-sub-header/components/assign-dropdown/assign-dropdown";
import { Avatar } from "src/components/avatar/avatar";
import { getActivitiesLog, getActivityTypes, getAllUsers } from "src/actions/account-settings/account-settings-api";
import { ReactComponent as CalendarFigmaIcon } from "../../../../mpd-library/icon/assets/calendar-figma.svg";
import { ReactComponent as DownArrowIcon } from "../../../../mpd-library/icon/assets/down-arrow.svg";
import { TableVirtuoso } from "react-virtuoso";
import { useDataFetch } from "../../../../hooks/useDataFetch";
import { getAccountSettingsActivitiesRequest } from "../../../../requests";
import { onUpdateToolBar } from "../../../../actions/global/global";
import { ReactComponent as NoSearchResultsForIcon } from "../../../../mpd-library/icon/assets/NoSearchResultsFor.svg";

export interface IAuditLogSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    routeProps: RouteComponentProps;
    getActivitiesLog: ({
        isOnAdd,
        from,
        to,
        types,
        users
    }: {
        isOnAdd?: boolean;
        from?: string;
        to?: string;
        types?: Array<string>;
        users?: Array<string>;
    }) => void;
    getActivityTypes: () => void;
    getAllUsers: () => void;
}
const FETCH_DATA_PARAMS_INIT_VALUE = {
    from: moment().startOf("month").format("MM/DD/YYYY"),
    to: moment().endOf("month").format("MM/DD/YYYY")
};

const AuditLogSettings: React.FC<IAuditLogSettingsProps> = (props) => {
    const [isCustomDateSelected, setIsCustomDateSelected] = useState<boolean>(false);
    const [isPeriodDropdownOpened, setIsPeriodDropdownOpened] = useState<boolean>(false);
    const [selectedPeriodName, setSelectedPeriodName] = useState<string>("This Month");
    const [isUsersDropdownOpened, setIsUsersDropdownOpened] = useState<boolean>(false);
    const [isActivitiesDropdownOpened, setIsActivitiesDropdownOpened] = useState<boolean>(false);
    const { routeProps } = props;
    const dispatch = useDispatch();

    const activityTypes = useSelector((state: AppState) => state.accountSettings?.general?.activityTypes);

    const activityTypesOptions = activityTypes ? ["All Activity"].concat(activityTypes) : ["All Activity"];

    const {
        entities: activityList,
        setAtBottomOfList,
        getMoreData: getMoreAuditActivities,
        setFetchDataParams,
        fetchDataParams
    } = useDataFetch({
        entityKey: "activities",
        fetchDataRequest: getAccountSettingsActivitiesRequest,
        skeletonLoading: true,
        listItemHeight: 60,
        fetchDataParams: FETCH_DATA_PARAMS_INIT_VALUE
    });
    useEffect(() => {
        dispatch(getActivityTypes());
    }, []);

    useEffect(() => {
        async function fetchData() {
            await getAllUsers();
            await getActivitiesLog({});
            await getActivityTypes();
        }

        fetchData();
    }, []);
    useEffect(() => {
        const updateToolBar = () => {
            const topBarProps = {
                leftElement: (
                    <BreadCrumbs
                        breadCrumbsList={[{ text: "Audit Log" }]}
                        history={routeProps.history}
                        toggleButtonProps={{
                            onToggle: props.onToggleRightPanel,
                            onClose: props.onCloseRightPanel
                        }}
                    />
                )
            };
            dispatch(onUpdateToolBar(topBarProps));
        };

        updateToolBar();
    }, [routeProps.history, props.onToggleRightPanel, props.onCloseRightPanel, props.onUpdateToolBar]);

    const onSelectPeriod = (value: { from: Moment; to: Moment } | "custom", name: string) => {
        if (value !== "custom") {
            setIsCustomDateSelected(false);
            setSelectedPeriodName(name);
            setFetchDataParams({
                from: moment(value.from.toDate()).format("MM/DD/YYYY"),
                to: moment(value.to.toDate()).format("MM/DD/YYYY")
            });
        } else {
            setIsCustomDateSelected(true);
            setSelectedPeriodName(name);
        }
    };

    const onTimeChanged = (value: Date, type: "startDate" | "endDate") => {
        if (type === "startDate" && fetchDataParams?.from < value) {
            setFetchDataParams({
                from: moment(value).format("MM/DD/YYYY"),
                to: moment(value).format("MM/DD/YYYY")
            });
        } else {
            if (type === "startDate") {
                setFetchDataParams({
                    from: moment(value).format("MM/DD/YYYY")
                });
            } else {
                setFetchDataParams({
                    to: moment(value).format("MM/DD/YYYY")
                });
            }
        }
    };

    const onActivityFilterChange = (value) => {
        setFetchDataParams({ types: value === "All Activity" ? undefined : value });
        setIsActivitiesDropdownOpened(false);
    };

    const onUserFilterChange = (admin: UsersFilterOption) => {
        setIsUsersDropdownOpened(false);
        setFetchDataParams({
            users: admin?.value !== "All Users" ? { ...admin, value: admin?.id } : undefined
        });
    };

    const { className } = props;

    const classes = "audit-log-settings";

    const minStartDate = new Date();
    minStartDate.setFullYear(minStartDate.getFullYear() - 1);

    return (
        <div className={classNames(className, classes + "-wrapper")}>
            <div className={classes + "-header"}>
                <MPDSelect
                    isOpenFromParent={isPeriodDropdownOpened}
                    onToggleOptions={(value: boolean) => setIsPeriodDropdownOpened(value)}
                    position={Position.BOTTOM_RIGHT}
                    className={classNames(
                        `${classes}-period-dropdown`,
                        `${classes}-admin-dropdown-target`,
                        MPDSelectTargetBorderRoundingClasses.rounded
                    )}
                    menuClassName={"period-popover"}
                    value={selectedPeriodName}
                    customOptions={PERIODS.map((period) => {
                        return (
                            <MPDSelectItem
                                selected={period.name === selectedPeriodName}
                                key={period.key}
                                onClick={() => {
                                    const { name, value } = period;
                                    onSelectPeriod(value, name);
                                    setIsPeriodDropdownOpened(false);
                                }}
                            >
                                <Text>{period.name}</Text>
                            </MPDSelectItem>
                        );
                    })}
                    ArrowIconComponent={DownArrowIcon}
                />
                <CSSTransition
                    in={isCustomDateSelected}
                    out={!isCustomDateSelected}
                    unmountOnExit
                    mountOnEnter
                    timeout={2000}
                    classNames="date-pickers"
                >
                    <StartEndDateTimer
                        className={classes + "-styled-dates-picker"}
                        startTimeProps={{
                            className: classes + "-styled-date-inputs",
                            onDateChange: (val: Date) => onTimeChanged(val, "startDate"),
                            date: moment(fetchDataParams?.from).toDate(),
                            withoutTime: true,
                            LeftIconComponent: CalendarFigmaIcon,
                            minDate: minStartDate
                        }}
                        endTimeProps={{
                            className: classes + "-styled-date-inputs",
                            onDateChange: (val: Date) => onTimeChanged(val, "endDate"),
                            date: moment(fetchDataParams?.to).toDate(),
                            withoutTime: true,
                            LeftIconComponent: CalendarFigmaIcon,
                            minDate: moment(fetchDataParams?.from).toDate()
                        }}
                    />
                </CSSTransition>

                <MPDSelect
                    position={Position.BOTTOM_LEFT}
                    className={classNames(
                        `${classes}-period-dropdown`,
                        `${classes}-admin-dropdown-target`,
                        MPDSelectTargetBorderRoundingClasses.rounded
                    )}
                    menuClassName={"activities-popover"}
                    value={fetchDataParams?.types || "All Activity"}
                    customOptions={activityTypesOptions.map((activity) => {
                        return (
                            <MPDSelectItem
                                onClick={() => onActivityFilterChange(activity)}
                                selected={fetchDataParams?.types === activity}
                            >
                                <Text>{activity}</Text>
                            </MPDSelectItem>
                        );
                    })}
                    isOpenFromParent={isActivitiesDropdownOpened}
                    onToggleOptions={(value) => setIsActivitiesDropdownOpened(value)}
                    ArrowIconComponent={DownArrowIcon}
                />

                <MPDSelect
                    className={classNames(
                        `${classes}-period-dropdown`,
                        `${classes}-admin-dropdown-target`,
                        MPDSelectTargetBorderRoundingClasses.rounded
                    )}
                    onToggleOptions={(value) => setIsUsersDropdownOpened(value)}
                    menuClassName={classes + "-users-dropdown"}
                    leftIconElement={
                        fetchDataParams?.users ? (
                            <Avatar
                                avatarUrl={fetchDataParams?.users?.profile_pic?.url}
                                className={`${classes}-users-dropdown-icon`}
                                user={fetchDataParams?.users}
                            />
                        ) : null
                    }
                    value={
                        fetchDataParams?.users
                            ? `${fetchDataParams?.users?.first_name} ${fetchDataParams?.users?.last_name}`
                            : "All Users"
                    }
                    position={Position.BOTTOM_LEFT}
                    usePortal={false}
                    isOpenFromParent={isUsersDropdownOpened}
                    customOptions={
                        <AssignDropdown
                            showAllAdmins
                            selectedAdmin={fetchDataParams?.users ? fetchDataParams?.users : null}
                            notShowTitles
                            adminsFilter={null}
                            onAdminItemClick={onUserFilterChange}
                            title={"Filter Users"}
                        />
                    }
                    ArrowIconComponent={DownArrowIcon}
                />
            </div>

            <div style={{ height: "100%" }} className={classes + "-table-virtuoso"}>
                {activityList?.allIds?.length > 0 ? (
                    <TableVirtuoso
                        style={{ height: "100%" }}
                        endReached={getMoreAuditActivities}
                        data={activityList.allIds}
                        fixedHeaderContent={() => <ListHeader />}
                        itemContent={(activity, id) => {
                            const isLoading = activityList.allIds?.includes("loading");
                            const item = activityList.byId[id];
                            return (
                                <SettingsAuditLogListItem
                                    loading={isLoading}
                                    item={item}
                                    ip={{
                                        address: activityList.byId[id]?.geo_ip?.ip || "",
                                        location: activityList.byId[id]?.geo_ip?.city || ""
                                    }}
                                    browserType={{
                                        browser: activityList.byId[id]?.user_agent?.client?.name || "",
                                        OS: `${activityList.byId[id]?.user_agent?.os?.name || ""} ${
                                            activityList.byId[id]?.user_agent?.os?.version || ""
                                        }`
                                    }}
                                    activityType={{
                                        user: `${activityList.byId[id]?.actor?.first_name || ""} ${
                                            activityList.byId[id]?.actor?.last_name || ""
                                        }`,
                                        action: activityList.byId[id]?.summary || ""
                                    }}
                                    activityLogLevel={activityList.byId[id]?.log_level}
                                />
                            );
                        }}
                        atBottomStateChange={setAtBottomOfList}
                    />
                ) : (
                    <ContainerEmpty
                        className={classes + "-list-container-empty"}
                        title="No Activities Found"
                        IconComponent={NoSearchResultsForIcon}
                    />
                )}
            </div>
        </div>
    );
};

export default AuditLogSettings;
