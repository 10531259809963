export const business_hours = [
    {
        day: "Monday",
        is_opened: true,
        opened_from: "9:00 AM",
        opened_till: "06:00 PM"
    },
    {
        day: "Tuesday",
        is_opened: true,
        opened_from: "9:00 AM",
        opened_till: "6:00 PM"
    },
    {
        day: "Wednesday",
        is_opened: true,
        opened_from: "9:00 AM",
        opened_till: "6:00 PM"
    },
    {
        day: "Thursday",
        is_opened: true,
        opened_from: "9:00 AM",
        opened_till: "6:00 PM"
    },
    {
        day: "Friday",
        is_opened: true,
        opened_from: "9:00 AM",
        opened_till: "6:00 PM"
    },
    {
        day: "Saturday",
        is_opened: false
    },
    {
        day: "Sunday",
        is_opened: false
    }
];
