import { Position, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToast, onUpdateToolBar } from "src/actions/global/global";
import {
    BreadCrumbs,
    StyledAlert,
    StyledAlertTypes,
    StyledButton,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    StyledTextArea,
    StyledTextAreaTypes,
    StyledButtonTypes
} from "src/components";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { TopToolbar } from "src/components/top-toolbar";
import { MPDSelect, MPDSelectTargetBorderRoundingClasses } from "src/mpd-library";
import { CategoriesDropdown } from "./categories-dropdown";
import styles from "./styles.module.scss";
import { navigate } from "src/actions/global/global-api";
import {
    createSnippetCategory,
    getSnippetDetails,
    GetSnippetDetailsResponse
} from "src/requests/conversationsRequests";
import { SnippetsContext } from "../snippets";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { ConversationsSnippet } from "src/requests/conversationsRequests";
import { getConversationsSnippets } from "src/requests/conversationsRequests";
import { UseConversationsSnippets } from "../../../../conversations/components/chat/hooks/useConversationsSnippets";
import { ReactComponent as CloseIcon } from "../../../../../mpd-library/icon/assets/close.svg"
import { ReactComponent as DownArrowIcon } from "../../../../../mpd-library/icon/assets/down-arrow.svg"
const classname = "add-snippet";

type RouterProps = {
    id: string;
};
type SnippetItemProps = {
    snippet: ConversationsSnippet;
};

const SNIPPET_GLOBAL_CONTENT_PROPS = {
    title: "Create new snippet category",
    hideDescription: true
};

export const SnippetAdd = ({ snippet }: SnippetItemProps) => {
    const [searchValue, setSearchValue] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [selectedCategory, selectCategory] = useState("Select Snippet Category");
    const [isCategoryPopoverOpen, setIsPopoverCategoryOpen] = useState(false);
    const [snippetText, setSnippetText] = useState("");
    const [createSnippetProcessing, setCreateSnippetProcessing] = useState(false);
    const [isEnterCategoryNameModalOpen, setIsEnterCategoryNameModalOpen] = useState(false);
    const [createCategoryProcessing, setCreateCategoryProcessing] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: idFromUrl } = useParams<RouterProps>();
    const [loadSnippetDetailsProcessing, setLoadingSnippetDetailsProcessing] = useState(false);

    const isEditPage = useMemo(() => history.location.pathname.includes("edit"), [history]);
    const [snippetDetails, setSnippetDetails] = useState<GetSnippetDetailsResponse | null>(null);
    const { updateSnippet, createSnippet, setAllSnippets } = useContext(SnippetsContext);
    const { deleteSnippetFromDetail }: UseConversationsSnippets = useContext(SnippetsContext);
    const [isFormValid, setIsFormValid] = useState(false);

    const formik = useFormik({
        initialValues: {
            snippetName: snippetDetails?.name,
            selectedCategoryId: snippetDetails?.category?.id,
            snippetText: snippetDetails?.text
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            snippetName: Yup.string().required("Can't be blank"),
            selectedCategoryId: Yup.string().required("Can't be blank"),
            snippetText: Yup.string().required("Can't be blank")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            await onSaveClick();
            // getFunction();
            setSubmitting(false);
        }
    });
    const {
        setErrors,
        validateForm,
        handleChange,
        handleSubmit,
        touched,
        errors,
        values,
        setTouched,
        setFieldValue,
        getFieldProps
    } = formik;

    useEffect(() => {
        const isFormFilled = values.snippetName && values.snippetText && values.selectedCategoryId;

        setIsFormValid(isFormFilled);
      }, [values.snippetName, values.snippetText, values.selectedCategoryId]);

    const getFunction = () => {
        if (isEditPage) {
            const onGetSnippetDetails = async () => {
                try {
                    setLoadingSnippetDetailsProcessing(true);
                    const {
                        data: { snippet }
                    } = await getSnippetDetails(idFromUrl);
                    setSnippetDetails(snippet);
                } finally {
                    setLoadingSnippetDetailsProcessing(false);
                }
            };
            onGetSnippetDetails();
            return;
        }
    };

    useEffect(() => {
        getFunction();
    }, []);

    useEffect(() => {
        if (snippetDetails) {
            setFieldValue("snippetName", snippetDetails?.name);
            setFieldValue("snippetText", snippetDetails?.text);
            setFieldValue("selectedCategoryId", snippetDetails?.category?.id);
            selectCategory(snippetDetails?.category?.name);
        }
    }, [snippetDetails]);

    const onSaveClick = useCallback(async () => {
        const errors = await validateForm();
        if (!isEmpty(errors)) {
            setErrors(errors);
            if (!touched.selectedCategoryId) {
                setTouched({ snippetName: true, selectedCategoryId: true, snippetText: true });
            }
            dispatch(onUpdateToast({ value: "Validation error. Please check the form", type: "error" }));
            return;
        }

        try {
            setCreateSnippetProcessing(true);
            const data = {
                name: values.snippetName,
                text: values.snippetText,
                snippet_category_id: values.selectedCategoryId
            };

            let res;
            if (isEditPage) {
                res = await updateSnippet(idFromUrl, data);
            } else {
                res = await createSnippet(data);
            }
            setSnippetDetails(res);
            const {
                data: { snippets }
            } = await getConversationsSnippets();
            await setAllSnippets(snippets);
        } finally {
            setCreateSnippetProcessing(false);
        }
    }, [values]);

    const onDeleteClick = async () => {
        await dispatch(deleteSnippetFromDetail(idFromUrl));
    };

    useEffect(() => {
        const breadCrumb = isEditPage && snippetDetails ? snippetDetails.name : "New Snippet";
        let topBarProps;
        if (!loadSnippetDetailsProcessing) {
            topBarProps = {
                leftElement: (
                    <BreadCrumbs
                        breadCrumbsList={[
                            { text: "Snippets", pathname: "/account-settings/snippets" },
                            { text: breadCrumb }
                        ]}
                        history={history}
                        toggleButtonProps={{
                            onToggle: () => dispatch(onToggleRightPanel()),
                            onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                        }}
                    />
                ),
                rightElement: !isEditPage ? (
                    <StyledButton
                        className="actions-button"
                        type={StyledButtonTypes.primarySimple}
                        IconComponent={CloseIcon}
                        onClick={() => history.push("/account-settings/snippets")}
                    />
                ) : (
                    <StyledAlert
                        Target={(props) => <StyledButton {...props} type={StyledButtonTypes.delete} text={"Delete"} />}
                        type={StyledAlertTypes.warning}
                        confirmButtonProps={{
                            type: StyledButtonTypes.delete
                        }}
                        confirmButtonText={"Delete"}
                        cancelButtonText={"Cancel"}
                        onConfirm={onDeleteClick}
                        globalContentProps={{
                            title: "Delete Snippets",
                            description: "Are you sure you want to delete this Snippet ?"
                        }}
                    />
                )
            };
        } else {
            topBarProps = null;
        }

        dispatch(onUpdateToolBar(topBarProps));
    }, [
        values.snippetName,
        values.selectedCategoryId,
        values.snippetText,
        createSnippetProcessing,
        snippetDetails,
        loadSnippetDetailsProcessing
    ]);

    const onClickCategory = (categoryName: string, categoryId: string) => {
        selectCategory(categoryName);
        setFieldValue("selectedCategoryId", categoryId);
        onOpenCloseCategoryDropdown(false);
        setSearchValue("");
    };

    const onOpenCloseCategoryDropdown = (status: boolean) => {
        if (status) {
            setTouched({ ...touched, selectedCategoryId: true });
        }
        setIsPopoverCategoryOpen(status);
    };

    const onSaveNewCategory = async () => {
        try {
            setCreateCategoryProcessing(true);
            const {
                data: { snippet_category }
            } = await createSnippetCategory(categoryName);
            if (snippet_category) {
                onClickCategory(snippet_category.name, snippet_category.id);
                dispatch(onUpdateToast({ value: "Category was successfully created", type: "saved" }));
            }
            setIsEnterCategoryNameModalOpen(false);
        } finally {
            setCreateCategoryProcessing(false);
        }
    };

    if (idFromUrl && loadSnippetDetailsProcessing) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[`${classname}-main-container`]}>
            <form onSubmit={handleSubmit}>
                <div className={styles[classname]}>
                    <StyledAlert
                        className={styles[`${classname}-alert`]}
                        isOpen={isEnterCategoryNameModalOpen}
                        type={StyledAlertTypes.primary}
                        canOutsideClickCancel={true}
                        canEscapeKeyCancel={true}
                        cancelButtonText="Cancel"
                        confirmButtonText="Save"
                        globalContentProps={SNIPPET_GLOBAL_CONTENT_PROPS}
                        children={
                            <div className={styles[`${classname}-alert-content`]}>
                                <StyledFloatingInput
                                    floatingType={StyledFloatingInputTypes.primary}
                                    placeholder="CATEGORY NAME"
                                    onChange={(event) => setCategoryName(event.target.value)}
                                    value={categoryName}
                                />
                            </div>
                        }
                        onCancel={() => setIsEnterCategoryNameModalOpen(false)}
                        onConfirm={onSaveNewCategory}
                    />

                    <div className={styles[`${classname}-name-and-category-wrapper`]}>
                        <div className={styles[`${classname}-input-wrapper`]}>
                            <StyledFloatingInput
                                autoCapitalize="none"
                                autoCorrect="none"
                                floatingType={StyledFloatingInputTypes.primary}
                                // value={snippetName}
                                onChange={handleChange}
                                placeholder="SNIPPET NAME"
                                tabIndex={1}
                                isMaxLengthRequire={false}
                                showErrorText={true}
                                id="snippetName"
                                error={touched.snippetName && errors.snippetName}
                                {...getFieldProps("snippetName")}
                            />
                        </div>
                        <div
                            className={`${styles[`${classname}-input-wrapper`]} ${
                                styles[`${classname}-categories-search-wrapper`]
                            }`}
                        >
                            <MPDSelect
                                className={classNames(
                                    "not-rounded-dropdown",
                                    styles[`${classname}-category-select`],
                                    MPDSelectTargetBorderRoundingClasses.semiRounded
                                )}
                                value={selectedCategory}
                                customOptions={
                                    <CategoriesDropdown
                                        searchValue={searchValue}
                                        onClickItem={onClickCategory}
                                        setIsEnterCategoryNameModalOpen={setIsEnterCategoryNameModalOpen}
                                        selectedCategoryId={formik?.values.selectedCategoryId}
                                    />
                                }
                                title={"SNIPPET CATEGORY"}
                                isOpenFromParent={isCategoryPopoverOpen}
                                onToggleOptions={onOpenCloseCategoryDropdown}
                                position={Position.BOTTOM}
                                showErrorText={true}
                                error={
                                    !isCategoryPopoverOpen && touched.selectedCategoryId && errors.selectedCategoryId
                                }
                                ArrowIconComponent={DownArrowIcon}
                            />
                        </div>
                    </div>
                    <div className={styles[`${classname}-text-input-wrapper`]}>
                        <StyledTextArea
                            autoCapitalize="none"
                            autoCorrect="none"
                            type={StyledTextAreaTypes.primaryStatic}
                            onChange={(event) => setSnippetText(event.target.value)}
                            placeholder="Snippet Text"
                            tabIndex={1}
                            rows={5}
                            isMaxLengthRequire={false}
                            value={snippetText}
                            id="snippetText"
                            error={touched.snippetText && errors.snippetText}
                            showErrorText={true}
                            {...getFieldProps("snippetText")}
                        />
                    </div>
                </div>
            </form>
            {isEditPage && <FormWasChanged formik={formik} />}
            {!isEditPage && (
                <StyledDuoButtons
                    className={styles[`${classname}-footer`]}
                    firstButtonClick={() => {
                        history.push("/account-settings/snippets");
                        setSnippetDetails(null);
                    }}
                    secondButtonClick={onSaveClick}
                    firstButtonText="Cancel"
                    secondButtonText="Save"
                    secondButtonProps={{ processing: createSnippetProcessing, disabled: !isFormValid }}
                    type={StyledDuoButtonsTypes.primary}
                />
            )}
        </div>
    );
};
