import classNames from "classnames";
import { StyledFloatingInput, StyledFloatingInputTypes, StyledRadioGroup } from "../../../../../components";
import styles from "./ListDetails.module.scss";

export const ListDetails = ({ formik }) => {
    const { getFieldProps } = formik;
    return (
        <div className={styles["root"]}>
            <StyledFloatingInput
                placeholder={"LIST NAME"}
                floatingType={StyledFloatingInputTypes.primary}
                // error={touched.lineName && errors.lineName}
                {...getFieldProps("name")}
            />
            <h4 className={styles["select-list-type-title"]}>Select list type </h4>
            <StyledRadioGroup
                className={classNames("restyled-radio-group")}
                {...formik.getFieldProps("type")}
                selectedValue={formik?.values?.type}
                list={[
                    {
                        className: styles["radio"],
                        value: "static",
                        label: (
                            <div className={styles["radio-option-label"]}>
                                <span className={styles["option-title"]}>Static List</span>
                                <span className={styles["option-description"]}>
                                    Static lists do not automatically update as your records change. A static list
                                    represents a single moment in time.
                                </span>
                            </div>
                        )
                    },
                    {
                        className: styles["radio"],
                        label: (
                            <div className={styles["radio-option-label"]}>
                                <span className={styles["option-title"]}>Active List</span>
                                <span className={styles["option-description"]}>
                                    Active lists automatically update over time. Records will join or leave the list as
                                    their properties change.
                                </span>
                            </div>
                        ),
                        value: "dynamic"
                    }
                ]}
            />
        </div>
    );
};
