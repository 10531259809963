import { OnBoardingState } from "src/store";

export const ONBOARDING_UPDATE = "onboarding-update";

interface OnboardingUpdateAction {
    type: typeof ONBOARDING_UPDATE;
    payload: OnBoardingState;
}

export type OnboardingActionTypes = OnboardingUpdateAction;
