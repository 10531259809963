import { AxiosResponse } from "axios";
import { axiosInstance } from "src/actions";

export type EventsMatchingTable = {
    [key: string]: {
        icon_color: string;
        icon_marker: string;
        type: string;
    };
};

export type GetEventsTypesResponse = {
    data: {
        events_matching_table: EventsMatchingTable;
    };
};

export const getEventsMatchingTable = async (): Promise<AxiosResponse<GetEventsTypesResponse>> => {
    return await axiosInstance.get(`/settings/events/types`);
};
