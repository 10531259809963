import { OnUpdateCollectionBottomToolBarProps } from "src/actions/studio/collections/add-update-collection";
import { GeoFence } from "./commonInterfaces";

export interface Collection {
    description: string;
    entries_count: number;
    author: {
        first_name: string;
        last_name: string;
        profile_pic: string;
    };
    icon?: {
        name: string;
        color: string;
        type: string;
        url: string;
        size: number;
    };
    id: number;
    inserted_at: Date;
    title: string;
    type: string;
    updated_at: Date;
}

export interface NewCollectionEntryProps {
    type: string;
    title: string;
    color?: string;
    address?: string;
    icon?: {
        name: string;
        url: string;
        type: string;
        size: number;
        color: string;
    };
    first_name?: string;
    icon_attachment_id?: string;
    subtitle?: string;
    last_name?: string;
    biography?: string;
    email?: string;
    body_raw?: string;
    phone_number?: string;
    photo?: {
        type: string;
        url: string;
        color: string;
    };
    twitter?: string;
    file?: any;
    website?: string;
    url?: string;
    banner?: {
        name: string;
        type: string;
        url: string;
    };
    description?: string;
    location?: {
        coordinates: Array<number>;
        type: string;
    };
    isFromCard?: boolean;
    body_processed?: string;
}

export interface Entries {
    icon: {
        color: string;
        type: string;
        url: string;
        name: string;
        size: number;
    };
    color: string;
    photo: {
        color: string;
        type: string;
        url: string;
    };
    first_name: string;
    last_name: string;
    biography: string;
    description: string;
    body_processed: string;
    url: string;
    body_raw: string;
    location: {
        coordinates: Array<number>;
        type: string;
    };
    id: number;
    inserted_at: string;
    position: number;
    subtitle: string;
    title: string;
    type: string;
    updated_at: string;
    phone_number: string;
    email: string;
    twitter: string;
    website: string;
    banner: {
        color: string;
        name: string;
        type: string;
        url: string;
    };
    author: {
        first_name: string;
        last_name: string;
        profile_pic: {
            url: string;
            type: string;
        };
    };
}

export interface CollectionStepper {
    active: number;
}

export interface AddCollectionData {
    bottomBarProps: OnUpdateCollectionBottomToolBarProps | null;
    loading: boolean;
    stepper: CollectionStepper;
    collection: CurrentCollection | PostCollectionResponse;
}

export interface CurrentCollection {
    id: number;
    name?: string;
    title: "";
    entries?: number;
    type: string;
    entries_count?: number;
    author?: {
        first_name: string;
        last_name: string;
        profile_pic: string;
    };
    icon?: {
        type: string;
        url: string;
        color: string;
        name: string;
        size: number;
    };
    description: string;
    updated?: Date;
    created?: string;
    checked?: boolean;
    inserted_at?: Date;
    updated_at?: Date;
}

export interface PostCollectionResponse {
    description: string;
    entries_count: number;
    author: {
        first_name: string;
        last_name: string;
        profile_pic: string;
    };
    icon?: {
        name: string;
        color: string;
        type: string;
        url: string;
        size: number;
    };
    id: number;
    inserted_at: Date;
    title: string;
    type: string;
    updated_at: Date;
}

export interface GeofenceMap extends GeoFence {
    list: Array<any>;
    search: {
        value: any;
    };
}

export interface SelectedCollection {
    currentCollection: Collection;
    entries: Array<Entries>;
}
export interface IsFullCollectionLists {
    collection: boolean;
    entry: boolean;
}

export interface EditNewEntry extends NewCollectionEntryProps {
    id?: number;
}

export interface CollectionState {
    loading: boolean;
    collectionLoading: boolean;
    entryLoading: boolean;
    collections: Array<Collection>;
    isFullLists: IsFullCollectionLists;
    addCollection: AddCollectionData;
    selectedCollection: SelectedCollection;
    addEntry: EditNewEntry;
    entryLoadingData: boolean;
    mapGeoFence: { geoFence: GeofenceMap };
}

export const defaultCollectionState: CollectionState = {
    loading: false,
    collectionLoading: false,
    entryLoading: false,
    isFullLists: {
        collection: false,
        entry: false
    },
    addCollection: {
        bottomBarProps: null,
        loading: false,
        stepper: {
            active: -1
        },
        collection: {
            id: 0,
            title: "",
            description: "",
            type: "person_directory"
        }
    },
    collections: [],
    selectedCollection: {
        currentCollection: {
            description: "",
            entries_count: 0,
            author: {
                first_name: "",
                last_name: "",
                profile_pic: ""
            },
            icon: {
                name: "",
                color: "",
                type: "",
                url: "",
                size: 0
            },
            id: 0,
            inserted_at: new Date(),
            title: "",
            type: "",
            updated_at: new Date()
        },
        entries: []
    },
    addEntry: { id: 0, type: "", title: "" },
    mapGeoFence: {
        geoFence: {
            status: false,
            list: [],
            currentShape: null,
            search: {
                value: null
            }
        }
    },
    entryLoadingData: false
};
