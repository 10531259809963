import { microtime } from "../common/utils";
import {
    SET_CONVERSATION_MESSAGES,
    UPDATE_SEND_MESSAGE_PROCESSING_STATUS,
    ADD_MESSAGE,
    UPDATE_CONVERSATION_MESSAGE_AFTER_SENT,
    ConversationMessagesActionTypes,
    SET_SHOULD_JUMP_TO_BOTTOM_OF_CHAT_LIST,
    SET_SELECTED_CONVERSATION_IS_BOTTOM,
    SET_SHOULD_SHOW_JUMP_TO_NEW_MESSAGES,
    UPDATE_MESSAGE,
    CLEAR_MESSAGES,
    REMOVE_TYPING,
    SET_HAS_MORE_MESSAGES
} from "./types";

export const setSelectedConversationIsBottom = (value: boolean) => {
    return {
        type: SET_SELECTED_CONVERSATION_IS_BOTTOM,
        payload: value
    };
};

export const setShouldShowJumpToNewMessages = (
    conversationId: string,
    value: boolean
): ConversationMessagesActionTypes => {
    return {
        type: SET_SHOULD_SHOW_JUMP_TO_NEW_MESSAGES,
        payload: {
            value,
            conversationId
        }
    };
};

export const updateMessage = (messageData, conversationId: string): ConversationMessagesActionTypes => {
    return {
        type: UPDATE_MESSAGE,
        payload: {
            messageData,
            conversationId
        }
    };
};

// =========================================================================================

export const setHasMoreMessages = (type: "hasMoreLater" | "hasMoreOlder", value: boolean, chatId: string) => {
    return {
        type: SET_HAS_MORE_MESSAGES,
        payload: {
            value,
            chatId,
            type
        }
    };
};

// =========================================================================================

export const updateSendMessageProcessingStatus = (status: boolean): ConversationMessagesActionTypes => {
    return {
        type: UPDATE_SEND_MESSAGE_PROCESSING_STATUS,
        payload: status
    };
};

export const addMessage = (
    message: ConversationMessage,
    conversationId: string,
    myInfo?: any
): ConversationMessagesActionTypes => {
    return {
        type: ADD_MESSAGE,
        payload: {
            messageData: {
                ...message,
                ts: message.ts ? message.ts : microtime(true, true),
                sender:
                    !message.sender && !message.event
                        ? {
                              ...message.sender,
                              profile_pic: {
                                  type: myInfo?.logo?.type,
                                  url: myInfo?.logo?.src || myInfo?.logo?.updatedSrc || message.profile_pic
                              },
                              id: myInfo.uuid,
                              status: "online",
                              first_name: myInfo.firstName,
                              last_name: myInfo.lastName
                          }
                        : message.sender
            },
            conversationId
        }
    };
};

export const removeTyping = (conversationId: string, typingId: string) => {
    return {
        type: REMOVE_TYPING,
        payload: {
            conversationId,
            typingId
        }
    };
};

export const updateMessageAfterSent = (messageData: any, chatId: string): ConversationMessagesActionTypes => {
    return {
        type: UPDATE_CONVERSATION_MESSAGE_AFTER_SENT,
        payload: {
            messageData,
            chatId
        }
    };
};

export const setConversationMessages = (payload): ConversationMessagesActionTypes => {
    return {
        type: SET_CONVERSATION_MESSAGES,
        payload
    };
};

export const clearMessages = (conversationId: string) => {
    return {
        type: CLEAR_MESSAGES,
        payload: { conversationId }
    };
};

export const setShouldJumpToBottom = (conversationId: string, value: boolean) => ({
    type: SET_SHOULD_JUMP_TO_BOTTOM_OF_CHAT_LIST,
    payload: {
        conversationId,
        value
    }
});
