import { useCallback, useEffect, useState } from "react";
import { getAccountSettingsUsersRequest } from "src/requests";

type useAdminsResult = {
    admins: any;
    hasMore: boolean;
    adminsLoads: boolean;
    onGetAdmins: (hetMore: boolean, searchValue: string) => void;
};

export const useAdmins = ({ limit = 10, withInvited, searchValue, filter }): useAdminsResult => {
    const [admins, setAdmins] = useState([]);
    const [adminsLoads, setAdminsLoads] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const onGetAdmins = useCallback(
        async (getMore: boolean, searchValue: string) => {
            try {
                setAdminsLoads(true);
                const res = await getAccountSettingsUsersRequest({
                    limit,
                    offset: admins.length,
                    order: "first_name:asc,last_name:asc",
                    q: searchValue,
                    filter,
                    with_invited: withInvited
                });

                const { users, has_more } = res.data;

                setHasMore(has_more);
                getMore ? setAdmins([...admins, ...users]) : setAdmins(users);
            } finally {
                setAdminsLoads(false);
            }
        },
        [admins, filter, searchValue]
    );

    useEffect(() => {
        // const onGetAdmins = async () => {
        //     try {
        //         await dispatch(getAdmins(adminsLimit, offset, "conversations", false, true, adminsSearchValue));
        //     } finally {
        //         setAdminsFirstLoading(false);
        //     }
        // };

        onGetAdmins(false, "");
    }, []);

    return {
        admins,
        adminsLoads,
        hasMore,
        onGetAdmins
    };
};
