import omit from "lodash/omit";
import { ConversationMessagesState, defaultConversationMessagesState } from "src/store/conversationMessages";
import {
    SET_CONVERSATION_MESSAGES,
    UPDATE_SEND_MESSAGE_PROCESSING_STATUS,
    ADD_MESSAGE,
    SET_SELECTED_CONVERSATION_IS_BOTTOM,
    SET_SHOULD_SHOW_JUMP_TO_NEW_MESSAGES,
    UPDATE_MESSAGE,
    CLEAR_MESSAGES,
    REMOVE_TYPING,
    ConversationMessagesActionTypes,
    SET_HAS_MORE_MESSAGES,
    SET_SHOULD_JUMP_TO_BOTTOM_OF_CHAT_LIST
} from "src/actions/conversationsMessages/types";
import { binarySearch } from "src/containers/conversations/components/chat/utils";
import moment from "moment";
import { compareMicroseconds } from "src/actions/common/utils";

const messagesDatesComporator = (candidateId, targetObj, conversationMessagesList) => {
    const candidateObj = conversationMessagesList.messages.byId[candidateId];
    const candidate = candidateObj.ts;
    const target = targetObj.ts;
    if (compareMicroseconds(target, candidate) < 0) {
        return -1;
    } else if (compareMicroseconds(target, candidate) > 0) {
        return 1;
    } else {
        return 0;
    }
};

export const conversationMessagesReducer = (
    state: ConversationMessagesState = defaultConversationMessagesState(),
    action: ConversationMessagesActionTypes
): ConversationMessagesState => {
    switch (action.type) {
        case SET_CONVERSATION_MESSAGES: {
            const res = {
                ...state,
                conversationMessagesList: {
                    ...state.conversationMessagesList,
                    [action.payload.selectedConversationId]: {
                        ...state.conversationMessagesList[action.payload.selectedConversationId],
                        ...action.payload.conversationMessagesList
                    }
                }
            };
            return res;
        }
        case SET_HAS_MORE_MESSAGES:
            return {
                ...state,
                conversationMessagesList: {
                    ...state.conversationMessagesList,
                    [action.payload.chatId]: {
                        ...state.conversationMessagesList[action.payload.chatId],
                        [action.payload.type]: action.payload.value
                    }
                }
            };
        case UPDATE_SEND_MESSAGE_PROCESSING_STATUS:
            return {
                ...state,
                getConversationsTagsProcessing: action.payload
            };
        case REMOVE_TYPING: {
            const {
                payload: { conversationId, typingId }
            } = action;
            const lastDate =
                state.conversationMessagesList[conversationId]?.dates?.allIds?.[0] ?? moment().format("YYYY-MM-DD");
            return {
                ...state,
                conversationMessagesList: {
                    ...state.conversationMessagesList,
                    [conversationId]: {
                        ...state.conversationMessagesList[conversationId],
                        messages: {
                            allIds: [
                                ...(state.conversationMessagesList[conversationId]?.messages?.allIds?.filter(
                                    (id: string) => typingId !== id
                                ) || [])
                            ],
                            byId: {
                                ...(omit(state.conversationMessagesList[conversationId]?.messages?.byId, typingId) ||
                                    {})
                            }
                        },
                        dates: {
                            ...state.conversationMessagesList[conversationId]?.dates,
                            byId: {
                                ...state.conversationMessagesList[conversationId]?.dates?.byId,
                                [lastDate]: [
                                    ...(state.conversationMessagesList[conversationId]?.dates?.byId[lastDate]?.filter(
                                        (id: string) => id !== typingId
                                    ) || [])
                                ]
                            }
                        },
                        typingIds: state.conversationMessagesList[conversationId]?.typingIds?.filter(
                            (id: string) => id !== typingId
                        )
                    }
                }
            };
        }
        case ADD_MESSAGE:
            const {
                messageData,
                messageData: { id: receivedMessageId, ts, type },
                conversationId
            } = action.payload;

            const conversationMessagesList = state.conversationMessagesList[conversationId];

            const {
                messages: { byId: messagesById = {}, allIds: messagesAllIds = [] } = {},
                dates: { byId: datesById = {}, allIds: datesAllIds = [] } = {}
            } = conversationMessagesList || {};

            const messageCurrentDate = moment(ts * 1000).format("YYYY-MM-DD");

            const oldestMessage = messagesById ? messagesById[messagesAllIds[messagesAllIds?.length - 1]] : {};

            const messagesAllIdsPosition = binarySearch(messagesAllIds, messageData, (candidateId, targetObj) =>
                messagesDatesComporator(candidateId, targetObj, conversationMessagesList)
            );
            const datesByIdPosition = datesById[messageCurrentDate]
                ? binarySearch(datesById[messageCurrentDate] || [], messageData, (candidateId, targetObj) =>
                      messagesDatesComporator(candidateId, targetObj, conversationMessagesList)
                  )
                : null;

            const res = {
                ...state,
                conversationMessagesList: {
                    ...state.conversationMessagesList,
                    [conversationId]: {
                        ...state.conversationMessagesList[conversationId],
                        messages: {
                            ...conversationMessagesList[conversationId]?.messages,
                            byId: {
                                ...messagesById,
                                [receivedMessageId]: messageData
                            },
                            allIds: [
                                ...messagesAllIds.slice(0, messagesAllIdsPosition),
                                receivedMessageId,
                                ...messagesAllIds.slice(messagesAllIdsPosition)
                            ]
                        },
                        dates: {
                            byId: {
                                ...datesById,
                                [messageCurrentDate]: datesById[messageCurrentDate]
                                    ? [
                                          ...datesById[messageCurrentDate].slice(0, datesByIdPosition),
                                          receivedMessageId,
                                          ...datesById[messageCurrentDate].slice(datesByIdPosition)
                                      ]
                                    : [receivedMessageId]
                            },
                            allIds:
                                !datesAllIds.includes(messageCurrentDate) &&
                                compareMicroseconds(ts, oldestMessage?.ts) > 0
                                    ? [messageCurrentDate, ...datesAllIds].sort((a, b) => {
                                          return moment(b).unix() - moment(a).unix();
                                      })
                                    : datesAllIds
                        },
                        typingIds:
                            type === "typing"
                                ? [
                                      ...(state.conversationMessagesList[conversationId].typingIds || []),
                                      receivedMessageId
                                  ]
                                : state.conversationMessagesList[conversationId].typingIds
                    }
                }
            };
            return res;
        case UPDATE_MESSAGE: {
            const {
                messageData,
                messageData: { id: updatableMessageId },
                conversationId
            } = action.payload;

            const conversationMessagesList = state.conversationMessagesList[conversationId];

            const {
                messages: { byId: messagesById }
            } = conversationMessagesList;

            // if (!!state.conversationMessagesList[conversationId].messages.byId[receivedMessageId]) {
            return {
                ...state,
                conversationMessagesList: {
                    ...state.conversationMessagesList,
                    [conversationId]: {
                        ...conversationMessagesList,
                        messages: {
                            ...conversationMessagesList.messages,
                            byId: {
                                ...messagesById,
                                [updatableMessageId]: {
                                    ...conversationMessagesList.messages[updatableMessageId],
                                    ...messageData
                                }
                            }
                        }
                    }
                }
            };
            // }
        }
        case CLEAR_MESSAGES:
            return {
                ...state,
                conversationMessagesList: {
                    [action.payload.conversationId]: null
                }
            };
        case SET_SELECTED_CONVERSATION_IS_BOTTOM:
            return {
                ...state,
                isBottom: action.payload
            };
        case SET_SHOULD_SHOW_JUMP_TO_NEW_MESSAGES:
            return {
                ...state,
                shouldShowJumpToNewMessages: {
                    ...state.shouldShowJumpToNewMessages,
                    [action.payload.conversationId]: action.payload.value
                }
            };

        case SET_SHOULD_JUMP_TO_BOTTOM_OF_CHAT_LIST:
            return {
                ...state,
                conversationMessagesList: {
                    ...state.conversationMessagesList,
                    [action.payload.conversationId]: {
                        ...state.conversationMessagesList[action.payload.conversationId],
                        shoulJumpToBottomOfChatlist: action.payload.value
                    }
                }
            };
        default:
            return state;
    }
};
