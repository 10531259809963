import { loginUserFail, sendAskOneTimePassword } from "src/actions/auth/auth";
import { Store } from "redux";
import { AppState } from "src/store";
import { OTP_TOKEN_CHANGED, LOGIN_STATE_ACTION, OTP_EMAIL_CHANGED, OTP_PHONE_NUMBER_CHANGED } from "src/actions";
import { LoginState } from "src/containers";
import { updateToast } from "src/actions/global/global-api";
import { ToastTypes, IToastType } from "src/components";
import { USER_ONBOARDING_CONFIRM } from "src/actions/user-onboarding";

export const unauthorizedErrorHandler = () => {
    localStorage.removeItem("auth");
    return window.location.replace("/signin");
};

export const authChallengeErrorHandler = (store: Store<AppState>) => {
    return store.dispatch(loginUserFail("Error! Try a bit later or choose another channel"));
};

export const authErrorHandler = async (store: Store<AppState>, responseStatus: number, data: any) => {
    let reason = "";

    if (data && data.reason === "invalid_login") {
        reason = "Invalid Login";
    } else if (data && data.reason === "user_disabled") {
        reason = "Current user is blocked";
    } else if (data && data.reason === "invalid_password") {
        reason = "Invalid Password";
    } else if (data && data.reason === "invalid_otp") {
        reason = "Invalid Code";
    }

    if (reason) {
        return store.dispatch(loginUserFail(reason));
    }

    if (responseStatus === 403 && data.status === "error") {
        store.dispatch({
            type: OTP_TOKEN_CHANGED,
            payload: data.otp_token
        });
        store.dispatch({
            type: OTP_EMAIL_CHANGED,
            payload: data.otp_email
        });
        store.dispatch({
            type: OTP_PHONE_NUMBER_CHANGED,
            payload: data.otp_phone_number
        });

        if (data.otp_email && data.otp_email.length && data.otp_phone_number && data.otp_phone_number.length) {
            return store.dispatch({
                type: LOGIN_STATE_ACTION,
                payload: LoginState.SelectingOTPChannel
            });
        }

        store.dispatch({
            type: LOGIN_STATE_ACTION,
            payload: LoginState.EnteringOneTimePassword
        });
        return store.dispatch(sendAskOneTimePassword("email"));
    }

    return store.dispatch(loginUserFail("Enter a valid Password"));
};

export const authLoginUserConfirmHandler = (store: Store<AppState>, error: any) => {
    const {
        userOnBoarding,
        userOnBoarding: { errors }
    } = store.getState();
    let errorMessage = "Validation error. Please check the form";

    if (error?.errors?.password) {
        errorMessage = error.errors.password[0];
        errors.password = error.errors.password[0];
        errors.confirmPassword = error.errors.password[0];
    }

    store.dispatch({
        type: USER_ONBOARDING_CONFIRM,
        payload: { ...userOnBoarding, errors }
    });

    const toast: IToastType = {
        value: errorMessage,
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));
};
