// import {} from "src/store/profile-automations";

import { ConversationsSettings } from "src/store/profile-automations";

export const SET_PROFILE_AUTOMATIONS_SETTINGS = "get-profile-automations-settings";

type SetProfileAutomationsSettings = {
    type: typeof SET_PROFILE_AUTOMATIONS_SETTINGS;
    payload: ConversationsSettings;
};


export type ProfileAutomationsSettingsActionTypes = SetProfileAutomationsSettings;
