import {
    AlertComposeData,
    AlertStateData,
    AlertComposeComposeDataErrors,
    AlertListState,
    RepliesSettings
} from "src/store";
import { ListsIdTypes } from "src/containers";
import { EventsMatchingTable } from "src/requests/settingsRequests";

export const UPDATE_ALERT_COMPOSE_DATA = "update-alert-compose-data";
export const UPDATE_ALERT_STATE = "update-alert-state";
export const UPDATE_ALERT_COMPOSE_DATA_ERRORS = "update-alert-compose-data-errors";
export const UPDATE_ALERT_LIST = "update-alert-list";
export const UPDATE_ALERT_SEARCH_LIST = "update-alert-search-list";
export const TOGGLE_ALERT_RIGHTPANEL = "toggle-alert-rightpanel";
export const UPDATE_ALERT_TAB_ID = "update-alert-tab-id";
export const UPDATE_SELECTED_ALERT = "update-selected-alert";
export const CHANGE_CREATE_ALERT_PROCESSING = "update-create-alert-processing";
export const UPDATE_SOCIAL_MEDIA_DESIGN = "update-social-media-design";
export const UPDATE_SOCIAL_MEDIA_THEME = "update-social-media-theme";
export const SET_ALERT_GENERATED_PREVIEW = "set-alert-generated-preview";
export const UPDATE_SAVE_STEP_PROCESSING = "update-save-step-processing";
export const UPDATE_GENERATE_PREVIEW_PROCESSING_STATUS = "update-generate-preview-processing-status";
export const ADD_FILES_FOR_UPDATE = "add-files-for-update";
export const SET_ALLOW_REPLIES = "set-allow-replies";
export const SET_ALERT_REPLIES_SETTINGS = "set-alert-replies-settings";

export const SET_EVENTS_MATHING_TABLE = "set-events-settings";
export const SET_EVENTS_MATCHING_TABLE_LOADING = "set-events-matching-table-loading";

type SetEventsMatchingTableLoading = {
    type: typeof SET_EVENTS_MATCHING_TABLE_LOADING;
    payload: boolean;
};

type SetEventsMatchingTable = {
    type: typeof SET_EVENTS_MATHING_TABLE;
    payload: EventsMatchingTable;
};

type SetAlertRepliesSettings = {
    type: typeof SET_ALERT_REPLIES_SETTINGS;
    payload: RepliesSettings;
};

interface SetAllowReplies {
    type: typeof SET_ALLOW_REPLIES;
    payload: boolean;
}
interface UpdateSelectedAlertAction {
    type: typeof UPDATE_SELECTED_ALERT;
    payload: any;
}
interface ToggleAlertRightpanelAction {
    type: typeof TOGGLE_ALERT_RIGHTPANEL;
    payload: boolean;
}

interface UpdateAlertComposeDataAction {
    type: typeof UPDATE_ALERT_COMPOSE_DATA;
    payload: AlertComposeData;
}

interface UpdateAlertStateAction {
    type: typeof UPDATE_ALERT_STATE;
    payload: AlertStateData;
}

interface UpdateAlertListAction {
    type: typeof UPDATE_ALERT_LIST;
    payload: {
        listName: ListsIdTypes;
        listData: Partial<AlertListState>;
    };
}

interface UpdateAlertSearchListAction {
    type: typeof UPDATE_ALERT_SEARCH_LIST;
    payload: Partial<AlertListState>;
}

interface UpdateAlertComposeDataErrorsAction {
    type: typeof UPDATE_ALERT_COMPOSE_DATA_ERRORS;
    payload: AlertComposeComposeDataErrors;
}

interface UpdateAlertTabIdAction {
    type: typeof UPDATE_ALERT_TAB_ID;
    payload: ListsIdTypes;
}

interface ChangeCreateAlertProcessing {
    type: typeof CHANGE_CREATE_ALERT_PROCESSING;
    payload: boolean;
}

interface UpdateSocialMediaDesign {
    type: typeof UPDATE_SOCIAL_MEDIA_DESIGN;
    payload: string;
}

interface UpdateSocialMediaTheme {
    type: typeof UPDATE_SOCIAL_MEDIA_THEME;
    payload: string;
}

interface SetAlertGeneratedPreviewAction {
    type: typeof SET_ALERT_GENERATED_PREVIEW;
    payload: any;
}
// UPDATE_SAVE_STEP_PROCESSING

interface UpdateSaveStepProcessing {
    type: typeof UPDATE_SAVE_STEP_PROCESSING;
    payload: boolean;
}

interface UpdateGeneratePreviewProcessingStatus {
    type: typeof UPDATE_GENERATE_PREVIEW_PROCESSING_STATUS;
    payload: boolean;
}

interface AddFilesForUpdateAction {
    type: typeof ADD_FILES_FOR_UPDATE;
    payload: any;
}

export type AlertActionTypes =
    | SetEventsMatchingTableLoading
    | SetEventsMatchingTable
    | UpdateSelectedAlertAction
    | ToggleAlertRightpanelAction
    | UpdateAlertComposeDataAction
    | UpdateAlertStateAction
    | UpdateAlertSearchListAction
    | UpdateAlertListAction
    | UpdateAlertComposeDataErrorsAction
    | UpdateAlertTabIdAction
    | ChangeCreateAlertProcessing
    | UpdateSocialMediaDesign
    | UpdateSocialMediaTheme
    | SetAlertGeneratedPreviewAction
    | UpdateSaveStepProcessing
    | UpdateGeneratePreviewProcessingStatus
    | AddFilesForUpdateAction
    | SetAllowReplies
    | SetAlertRepliesSettings;
