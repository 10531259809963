import EditorState from "draft-js/lib/EditorState";
import convertToRaw from "draft-js/lib/convertFromDraftStateToRaw";
import { substring } from "stringz";
export const getBubbleClassAndShowAvatar = (
    prevMessage,
    message,
    nextMessage
): { bubbleClass: string; showAvatar: boolean } => {
    let bubbleClass: string, showAvatar: boolean;

    const previousMessageSenderId = prevMessage?.sender?.id;
    const currentMessageSenderId = message?.sender?.id;
    const nextMessageSenderId = nextMessage?.sender?.id;
    const nextMessageType = nextMessage?.type;
    const prevMessageType = prevMessage?.type;
    const currentMessageType = message?.type;

    const isPreviousSenderAndCurrentAreSame = currentMessageSenderId === previousMessageSenderId; // sender id with prev
    const isNextSenderAndCurrentAreSame = currentMessageSenderId === nextMessageSenderId; // sender id with next
    const isPreviousAndCurrentMessageTypesAreSame = currentMessageType === prevMessageType; // type with prev
    const isNextAndCurrentMessageTypesAreSame = currentMessageType === nextMessageType; // type with next

    //first-bubble
    if (
        (!isPreviousSenderAndCurrentAreSame || !isPreviousAndCurrentMessageTypesAreSame) &&
        isNextSenderAndCurrentAreSame
    ) {
        bubbleClass = "first-bubble";
        showAvatar = true;
    }

    //middle-bublle
    if (
        isPreviousAndCurrentMessageTypesAreSame &&
        isNextAndCurrentMessageTypesAreSame &&
        isNextSenderAndCurrentAreSame &&
        isPreviousSenderAndCurrentAreSame
    ) {
        bubbleClass = "middle-bubble";
        showAvatar = false;
    }

    //last-bubble
    if (
        (!isNextAndCurrentMessageTypesAreSame && isPreviousSenderAndCurrentAreSame) ||
        !isNextSenderAndCurrentAreSame ||
        (nextMessageType === "note" && isPreviousSenderAndCurrentAreSame)
    ) {
        bubbleClass = "last-bubble";
        showAvatar = false;
    }

    //only-bubble
    if (
        (!isNextSenderAndCurrentAreSame && !isPreviousSenderAndCurrentAreSame) ||
        (!isNextAndCurrentMessageTypesAreSame && !isPreviousAndCurrentMessageTypesAreSame) ||
        (nextMessageType === "note" && !isPreviousSenderAndCurrentAreSame)
    ) {
        bubbleClass = "only-bubble";
        showAvatar = true;
    }

    return { bubbleClass, showAvatar };
};
export interface Item {
    key: number;
    value: string;
}

function defaultComparator(candidate, target) {
    if (target < candidate) {
        return -1;
    } else if (target > candidate) {
        return 1;
    } else {
        return 0;
    }
}

export const binarySearch = function (array, find, comparator = defaultComparator) {
    let low = 0,
        high = array.length - 1,
        i,
        comparison,
        prev_comparison;
    while (low <= high) {
        i = Math.floor((low + high) / 2);
        comparison = comparator(array[i], find);
        prev_comparison = comparison;
        if (comparison < 0) {
            low = i + 1;
            continue;
        }
        if (comparison > 0) {
            high = i - 1;
            continue;
        }
        return i;
    }
    let option_low, option_high;
    if (prev_comparison < 0) {
        option_low = i;
        option_high = i + 1;
    } else {
        option_low = i - 1;
        option_high = i;
    }
    const dist_a = find - array[option_low];
    const dist_b = array[option_high] - find;
    if (dist_a < dist_b) {
        return option_low;
    } else {
        return option_high;
    }
};

export const getConversationMessageEntities = (state: EditorState) => {
    const raw = convertToRaw(state.getCurrentContent());

    const { blocks, entityMap } = raw;
    let entities = [];

    entities = blocks.reduce((accumulator, block, parentIndex, blocksArray) => {
        const { text } = block;
        let startRange = 0;

        if (!block.entityRanges.length) {
            if (accumulator.length && accumulator[accumulator.length - 1].type === "text") {
                accumulator[accumulator.length - 1].text = accumulator[accumulator.length - 1].text.concat(`${text}`);
                if (blocksArray.length - 1 > parentIndex) {
                    accumulator[accumulator.length - 1].text = accumulator[accumulator.length - 1].text.concat(`\n`);

                    return accumulator;
                }
                return accumulator;
            }

            return [...accumulator, { type: "text", text: `${text}\n` }];
        } else {
            block.entityRanges.forEach((range, index) => {
                const entity = text.substring(startRange, range.offset);
                const mention = text.substring(range.offset, range.offset + range.length);

                startRange = range.offset + range.length;

                if (entity) {
                    if (parentIndex !== 0 && accumulator[parentIndex - 1].type === "text") {
                        accumulator[parentIndex - 1].text.concat(entity);
                    } else {
                        accumulator.push({ type: "text", text: entity });
                    }
                }
                if (entityMap[index]?.type === "mention") {
                    accumulator.push({
                        type: "mention",
                        text: mention,
                        admin_id: entityMap[index].data?.mention?.id
                    });
                }

                if (entityMap[index]?.type === "emoji") {
                    accumulator.push({
                        type: "text",
                        text: entityMap[index].data?.emojiUnicode
                    });
                }

                const remain = substring(text, startRange, text.length);

                remain &&
                    remain !== " " &&
                    accumulator.push({
                        type: "text",
                        text: remain
                    });
            });

            if (blocksArray.length - 1 !== parentIndex) {
                accumulator[accumulator.length - 1].text = `${accumulator[accumulator.length - 1].text} \n`;
                return accumulator;
            } else {
                return accumulator;
            }
        }
    }, []);

    entities[entities.length - 1].text = entities[entities.length - 1].text.replace(/\s+$/g, "");

    return entities;
};
