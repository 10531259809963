/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export const StepperStates = {
    hidden: "hidden" as const,
    finished: "finished" as const,
    active: "active" as const,
    default: "default" as const
};

export type StepperStates = typeof StepperStates[keyof typeof StepperStates];

export type StepperNode = {
    state?: StepperStates;
    num?: string;
    title: string;
    nav?: string;
};
