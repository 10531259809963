import { ShapeCoords } from "src/requests";

export interface OnBoardingState {
    name: string;
    imageSrc: string;
    agencyDetails: {
        agencyLogo: any;
        accountName: string;
        totalEmployees: string;
        populationServed: string;
        address: string;
        addressLine2: string;
        city: string;
        stateProvince: string;
        zipPostalCode: string;
        country: string;
        timeZone: string;
        timeFormat: string;
        language: string;
    };
    platformSetup: {
        addChannels: {
            spotNetwork: {
                status: boolean;
            };
            twitter: {
                status: boolean;
            };
            facebook: {
                status: boolean;
            };
        };
        jurisdiction: {
            censusData: {
                status: boolean;
            };
            uploadShapeFile: {
                status: boolean;
                files: Array<any>;
            };
            customDraw: {
                status: boolean;
                currentShape: {
                    center: ShapeCoords;
                    type?: any;
                };
            };
        };
    };
    branding: {
        primaryColor: any;
        customUrl: string;
    };
}

export const defaultOnBoardingState: OnBoardingState = {
    name: "Eric",
    imageSrc: "",
    agencyDetails: {
        agencyLogo: null,
        accountName: "",
        totalEmployees: "",
        populationServed: "",
        address: "",
        addressLine2: "",
        city: "",
        stateProvince: "",
        zipPostalCode: "",
        country: "",
        timeZone: "",
        timeFormat: "",
        language: ""
    },
    platformSetup: {
        addChannels: {
            spotNetwork: {
                status: true
            },
            twitter: {
                status: false
            },
            facebook: {
                status: false
            }
        },
        jurisdiction: {
            censusData: {
                status: true
            },
            uploadShapeFile: {
                status: false,
                files: []
            },
            customDraw: {
                status: false,
                currentShape: {
                    center: {
                        lat: 41.9,
                        lng: -87.624
                    }
                }
            }
        }
    },
    branding: {
        primaryColor: null,
        customUrl: ""
    }
};
