import { ReactComponent as ChannelsFacebookIcon } from "../../../../mpd-library/icon/assets/ChannelsFacebook.svg"
import { ReactComponent as ChannelsTwitterIcon } from "../../../../mpd-library/icon/assets/ChannelsTwitter.svg"
import { ReactComponent as ChannelsInstagramIcon } from "../../../../mpd-library/icon/assets/ChannelsInstagram.svg"
import { ReactComponent as ChannelsEmailIcon } from "../../../../mpd-library/icon/assets/ChannelsMail.svg"
import { ReactComponent as BigWhitePhoneIcon } from "../../../../mpd-library/icon/assets/big-white-phone.svg"

export const SOCIAL_NETWORKS_ITEMS_CONFIG = {
    facebook: {
        title: "Facebook",
        subtitle: "Post alerts to Facebook and recieve and respond to Messages",
        customLeftIconProps: {
            IconComponent: ChannelsFacebookIcon,
            size: 48
        },
        navToRoute: "/account-settings/channels/facebook"
    },
    twitter: {
        title: "Twitter",
        subtitle: "Post alerts to Twitter",
        customLeftIconProps: {
            IconComponent: ChannelsTwitterIcon,
            size: 48
        },
        navToRoute: "/account-settings/channels/twitter"
    },
    instagram: {
        title: "Instagram",
        subtitle: "Receive Instagram DM’s in the Conversations Inbox",
        customLeftIconProps: {
            IconComponent: ChannelsInstagramIcon,
            size: 48
        },
        navToRoute: "/account-settings/channels/instagram"
    },
    sms_voice: {
        title: "SMS/Voice",
        subtitle: "Take out a local phone number to recieve and respond to sms/voice messages",
        customLeftIconProps: {
            backgroundColor: "#2CC27A",
            size: 48,
            IconComponent: BigWhitePhoneIcon
        },
        navToRoute: "/account-settings/channels/sms_voice",
        disabled: false
    },
    email: {
        title: "Email",
        subtitle: "Recieve email messages in the Conversations Inbox",
        customLeftIconProps: {
            size: 48,
            // backgroundColor: "#7C55EC",
            IconComponent: ChannelsEmailIcon
        },
        navToRoute: "/account-settings/channels/email",
        disabled: true
    }
};
