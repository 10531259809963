import React from "react";

import { IMPDScrollProps, MPDScroll } from "src/mpd-library";

import { IProps } from "@blueprintjs/core";
import useInfiniteScroll from "src/containers/conversations/components/chat/useInfiniteScroll";

export interface IMPDListFullProps extends IProps {
    Component?: React.ComponentType<any>;
    list: Array<any>;
    style?: any;
    onScrollBottom?: (event: React.SyntheticEvent) => void;
    hiddenScrollProps?: IMPDScrollProps;
}

export const MPDListFull: React.FunctionComponent<IMPDListFullProps> = (props) => {
    const {
        className,
        Component,
        style,
        list,
        onScrollBottom,
        hiddenScrollProps,
        loadingMoreComp,
        ...componentProps
    } = props;
    // const [loading, setLoading] = useState(false);

    const [ref] = useInfiniteScroll({
        onLoadMore: onScrollBottom,
        loading: componentProps.loading,
        hasNextPage: !componentProps.isListFullyLoaded
    });

    return (
        <React.Fragment>
            {Component && (
                <MPDScroll
                    style={style}
                    className={className}
                    // onScrollBottom={onScrollBottom}
                    yDirection={true}
                    xDirection={true}
                    {...hiddenScrollProps}
                >
                    <Component list={list} onScrollBottom={onScrollBottom} {...componentProps} />

                    {/* <div ref={ref}>{loadingMoreComp}</div> */}
                    {(!componentProps.isListFullyLoaded || componentProps.loading) && loadingMoreComp && (
                        <div ref={ref}>{loadingMoreComp}</div>
                    )}
                </MPDScroll>
            )}
        </React.Fragment>
    );
};

// create on scroll down event to load next 10 elements
