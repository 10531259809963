import { ContactsList, Classes } from "../../../../contacts/contacts-list/contacts-list";

const LIST_ITEM_FIELDS = {
    allIds: ["name", "channels", "last_contacted_at", "tags", "lists"],
    byId: {
        name: {
            field: "name",
            enabled: true
        },
        channels: {
            field: "channels",
            enabled: true
        },
        last_contacted_at: {
            field: "last_contacted_at",
            enabled: true
        },
        tags: {
            field: "tags",
            enabled: true
        },
        lists: {
            field: "lists",
            enabled: true
        }
    }
};

export const CreateListContactsPreview = ({ formik }) => {
    const setIsCreateNewContactPopupOpen = () => null;

    const setFiltersToForm = (value: string) => {
        formik.setFieldValue("filters", value);
    };

    return (
        <ContactsList
            setFiltersToParent={setFiltersToForm}
            isCreateNewContactPopupOpen={false}
            setIsCreateNewContactPopupOpen={setIsCreateNewContactPopupOpen}
            className={Classes.PREVIEW}
            listItemConfig={LIST_ITEM_FIELDS}
            showListIfFilterAppleid
        />
    );
};
