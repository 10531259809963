// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions, validURL } from "src/actions";

import {
    BreadCrumbs,
    StyledDuoButtons,
    ImagePlusText,
    StyledDuoButtonsTypes,
    ImageUpload,
    SettingsTitle,
    StyledButton,
    StyledButtonTypes
} from "src/components";

import { formatPhoneNumber } from "src/actions";

import { Route, Switch, matchPath } from "react-router-dom";

import { Classes, MPDScroll } from "src/mpd-library";

import { IDetailView } from "../../../";

import { AccountBrandingSettingsEdit } from "./branding-settings-edit/branding-settings-edit";
import { History } from "history";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { bindActionCreators, Dispatch } from "redux";
import { updateAccountSettingsBranding } from "src/actions/account-settings/account-settings-api";

interface AccountBrandingSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    onCancelChanges: (pathname: string, history: History) => void;
    onUpdateAccountSettingsBranding: (updatedObj: AccountSettingsState, pathname: string, history: History) => void;
}

interface AccountBrandingSettingsState {
    accountSettings: AccountSettingsState;
}

class AccountBrandingSettings extends React.Component<AccountBrandingSettingsProps, AccountBrandingSettingsState> {
    constructor(props: AccountBrandingSettingsProps) {
        super(props);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onChangeDetailsInput = this.onChangeDetailsInput.bind(this);
        this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
        this.onUploadDetailsImage = this.onUploadDetailsImage.bind(this);
        this.onCancelChanges = this.onCancelChanges.bind(this);
        this.onBlurDetails = this.onBlurDetails.bind(this);
        this.onUpdateAccountSettingsBranding = this.onUpdateAccountSettingsBranding.bind(this);

        const { accountSettings } = this.props;

        this.state = {
            accountSettings: JSON.parse(JSON.stringify(accountSettings))
        };
    }

    public componentDidMount() {
        this.updateToolBar(this.props);
    }

    public componentDidUpdate(prevProps: AccountBrandingSettingsProps) {
        if (prevProps.accountSettings !== this.props.accountSettings) {
            this.setState({ accountSettings: this.props.accountSettings });
            this.updateToolBar(this.props);
        }
        if (prevProps.routeProps !== this.props.routeProps) {
            this.updateToolBar(this.props);
        }
    }

    public render() {
        const { currentPlan, routeProps } = this.props;
        const { accountSettings } = this.state;
        const classes = "account-branding";

        return (
            <React.Fragment>
                <MPDScroll className={classes + "-scroll-container"} yDirection={true}>
                    <Switch location={routeProps.history.location}>
                        {/* <Route
                            exact={true}
                            path={"/account-settings/branding"}
                            render={() => this.renderAccountDetails(classes)}
                        /> */}
                        <Route
                            path={`/account-settings/branding`}
                            render={() => (
                                <AccountBrandingSettingsEdit
                                    currentPlan={currentPlan}
                                    details={accountSettings.organization.details}
                                    errors={accountSettings.organization.accountOrganizationError}
                                    onPhoneNumberChange={this.onPhoneNumberChange}
                                    onBlur={this.onBlurDetails}
                                    onChangeInput={this.onChangeDetailsInput}
                                    onUploadImage={this.onUploadDetailsImage}
                                    onSave={this.onUpdateAccountSettingsBranding}
                                />
                            )}
                        />
                    </Switch>
                </MPDScroll>
            </React.Fragment>
        );
    }

    private onUploadDetailsImage(files: any) {
        const { accountSettings } = this.state;
        const reader = new FileReader();
        const file = files[0];
        reader.readAsDataURL(file);
        if (accountSettings.organization.details.logo.file) {
            accountSettings.organization.details.logo.file = file;
            reader.onloadend = () => {
                accountSettings.organization.details.logo.src = reader.result;
                this.setState({ accountSettings });
            };
        }
    }

    private onChangeDetailsInput(event: React.ChangeEvent<HTMLInputElement>, type: string) {
        const { accountSettings } = this.state;
        const value = event.currentTarget.value;

        accountSettings.organization.details[type] = value;

        this.setState({ accountSettings });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    }

    private onBlurDetails(field: string) {
        const { accountSettings } = this.state;
        const { url } = accountSettings.organization.details;
        let websiteUrlError = "";
        if (field === "url") {
            if (url?.length !== 0) {
                if (!validURL(url)) {
                    websiteUrlError = "Please enter valid websiteUrl.";
                }
            } else {
                websiteUrlError = "";
            }
            accountSettings.organization.accountOrganizationError.url = websiteUrlError;
            this.setState({ accountSettings });
        }
        return !!websiteUrlError;
    }

    private onPhoneNumberChange(value: any, type: string) {
        const { accountSettings } = this.state;
        accountSettings.organization.details[type] = value;
        this.setState({ accountSettings });
    }

    private updateToolBar(props: AccountBrandingSettingsProps) {
        const { routeProps } = props;

        const isBrandingEditPage = matchPath(routeProps.history.location.pathname, {
            path: "/account-settings/branding/edit",
            sensitive: false,
            strict: false
        });

        let topBarProps;
        if (!isBrandingEditPage) {
            topBarProps = {
                leftElement: (
                    <BreadCrumbs
                        breadCrumbsList={[{ text: "Branding" }]}
                        history={routeProps.history}
                        toggleButtonProps={{
                            onToggle: this.props.onToggleRightPanel,
                            onClose: this.props.onCloseRightPanel
                        }}
                    />
                )
            };
        } else {
            topBarProps = {
                leftElement: (
                    <BreadCrumbs
                        breadCrumbsList={[
                            { text: "Branding", pathname: "/account-settings/branding" },
                            { text: "Edit" }
                        ]}
                        history={routeProps.history}
                        toggleButtonProps={{
                            onToggle: this.props.onToggleRightPanel,
                            onClose: this.props.onCloseRightPanel
                        }}
                    />
                )
            };
        }

        props.onUpdateToolBar(topBarProps);
    }

    private onCancelChanges() {
        const { routeProps } = this.props;
        const { history } = routeProps;
        const pathname = `/account-settings/branding`;
        this.props.onCancelChanges(pathname, history);
    }

    private onUpdateAccountSettingsBranding = async (values) => {
        const valueForUpdate = {
            ...this.state.accountSettings,
            organization: {
                ...this.state.accountSettings?.organization,
                details: {
                    ...this.state.accountSettings?.organization?.details,
                    shortTitle: values?.shortTitle,
                    description: values?.description,
                    url: values?.url,
                    phone: values?.phone,
                    email: values?.email,
                    twitter: values?.twitter,
                    branded_phone_number: values?.branded_phone_number,
                    vanity_url_subdomain: values?.vanity_url_subdomain
                }
            }
        };
        const res = await this.props.onUpdateAccountSettingsBranding(
            valueForUpdate,
            "/account-settings/account-details",
            this.props.routeProps.history
        );
        return res.data.account;
    };

    private onEdit() {
        const { routeProps } = this.props;
        routeProps.history.push(`/account-settings/branding/edit`);
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;

    return {
        accountSettings,
        currentPlan
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onUpdateAccountSettingsBranding: updateAccountSettingsBranding,
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccountBrandingSettings);
