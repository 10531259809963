import { Store } from "redux";
import { AppState } from "src/store";
import { IToastType, ToastTypes } from "src/components";
import { updateToast } from "src/actions/global/global-api";
import { USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE, USER_SETTINGS_PASSWORD_UPDATE } from "src/actions";
import { changeEmailPassOTP } from "src/actions/user-settings/user-settings-api";

export const userEmailOrPassChangeErrorHandler = (store: Store<AppState>, errorType: "password" | "code") => {
    store.dispatch({
        type: USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE,
        payload: `Invalid current ${errorType}`
    });
    const toast: IToastType = {
        value: "Validation error. Please check the form",
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));

    return;
};

export const userEmailOrPassSendForOtpErrorHandler = (store: Store<AppState>, error?: any) => {
    store.dispatch(changeEmailPassOTP({ otpAlertOpen: false, otpAlertType: "password", otpToken: undefined }));
    const toast: IToastType = {
        value: error && error.message ? error.message : "Error on sending one time password! Try a bit later",
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));

    return;
};

export const userResetPasswordErrorHandler = (store: Store<AppState>, error?: any) => {
    const {
        userSettings: { password }
    } = store.getState();

    let errorMessage = "Error! Please check your form and try again";
    let errors = {};

    if (error?.errors?.password) {
        errorMessage = error.errors.password[0];
        errors = {
            newPassword: error.errors.password[0],
            confirmNewPassword: error.errors.password[0]
        };
    }

    if (error?.reason === "invalid_current_password") {
        errorMessage = "Error! Invalid current password";
        errors = { currentPassword: "Invalid current password" };
    }

    if (error?.reason === "new_password_should_not_match_current") {
        errorMessage = "Error! New password should not match current";
        errors = { currentPassword: "New password should not match current" };
    }

    store.dispatch({
        type: USER_SETTINGS_PASSWORD_UPDATE,
        payload: { ...password, errors }
    });

    const toast: IToastType = {
        value: errorMessage,
        type: ToastTypes.error
    };
    store.dispatch(updateToast({ toast }));

    return;
};
