// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { IDetailView } from "../../../../";

import { StyledFloatingInput, StyledFloatingInputTypes } from "src/components";

import {
    BreadCrumbs,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    SettingsControlProperty,
    SettingsTitle
} from "src/components";

import { Classes, MPDScroll } from "src/mpd-library";

import moment from "moment";

import "./styles.scss";
import { AppState, AccountSettingsGeneralAlertGroups } from "src/store";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { updateGroups } from "src/actions/account-settings/account-settings-api";

interface IGroupsSettingsAddProps extends IDetailView {
    routeProps: RouteComponentProps;
    groups: AccountSettingsGeneralAlertGroups;
    edit?: boolean;
    groupToEdit?: any;
    onSaveChanges: (groups: AccountSettingsGeneralAlertGroups) => void;
}

interface IGroupsSettingsAddState {
    groupToEdit: any;
}

class GroupsSettingsAdd extends React.Component<IGroupsSettingsAddProps, IGroupsSettingsAddState> {
    constructor(props: IGroupsSettingsAddProps) {
        super(props);
        let { groupToEdit } = this.props;
        this.updateToolBar();

        if (groupToEdit === undefined) {
            const date = moment(new Date()).format("L");
            const id = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();
            groupToEdit = {
                checked: false,
                active: true,
                date,
                id,
                title: "",
                description: "",
                members: [],
                membersStr: "",
                type: "public"
            };
        }

        this.state = {
            groupToEdit
        };
    }

    public componentDidUpdate = (prevProps: IGroupsSettingsAddProps) => {
        if (prevProps.routeProps !== this.props.routeProps) {
            this.updateToolBar();
        }
    };

    public render() {
        const { className } = this.props;
        const { groupToEdit } = this.state;

        const classes = "group-settings-add";

        return (
            <MPDScroll className={classNames(classes + "-scroll-container")} yDirection={true}>
                <div className={classNames(classes, Classes.CONTAINER_MARGIN, Classes.COLUMN, className)}>
                    <div className={classes + "-information"}>
                        <SettingsTitle title="Group Information" />
                        <div className={classNames(Classes.ASSIGN_BLOCK_PADDING)}>
                            <StyledFloatingInput
                                autoCapitalize="none"
                                autoCorrect="none"
                                floatingType={StyledFloatingInputTypes.primary}
                                value={groupToEdit.title}
                                onChange={(e) => this.onInputChanged(e, "title")}
                                placeholder="GROUP NAME"
                                tabIndex={1}
                            />
                            <StyledFloatingInput
                                autoCapitalize="none"
                                autoCorrect="none"
                                floatingType={StyledFloatingInputTypes.primary}
                                value={groupToEdit.description}
                                onChange={(e) => this.onInputChanged(e, "description")}
                                placeholder="GROUP DESCRIPTION"
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <div className={classes + "-additonal-settings"}>
                        <SettingsTitle title="Additional Settings" />
                        <SettingsControlProperty
                            className={classNames(Classes.BLOCK_PADDING_BOTTOM, Classes.BLOCK_PADDING_TOP)}
                            title="Private Group"
                            subtitle="Private groups can only be added by users that have your pivate group code"
                            switchProps={{
                                checked: groupToEdit.type === "private",
                                onChange: this.onChangeControl
                            }}
                        />
                    </div>
                </div>
            </MPDScroll>
        );
    }

    private onChangeControl = () => {
        const { groupToEdit } = this.state;
        const { type } = groupToEdit;
        if (type === "private") {
            groupToEdit.type = "public";
        } else {
            groupToEdit.type = "private";
        }
        this.setState({ groupToEdit });
    };

    private onInputChanged = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const { groupToEdit } = this.state;
        const text = event.currentTarget.value;
        groupToEdit[type] = text;
        this.setState({ groupToEdit });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    };

    private onCancelChanges = () => {
        const { routeProps } = this.props;
        routeProps.history.push(`/account-settings/groups`);
    };

    private onSaveChanges = () => {
        const { onSaveChanges, groups, edit, routeProps } = this.props;
        const { groupToEdit } = this.state;
        if (onSaveChanges) {
            const list = groups.list;
            const id = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();
            const index = list.findIndex((listItem: any) => listItem.id === id);
            if (edit) {
                const indexNew = list.findIndex((listItem: any) => listItem.id === groupToEdit.id);
                list[indexNew] = groupToEdit;
                onSaveChanges(groups);
            } else if (index === -1) {
                groupToEdit.id = id;
                list.push(groupToEdit);
                onSaveChanges(groups);
            }
            routeProps.history.push(`/account-settings/groups`);
        }
    };

    private updateToolBar = () => {
        const { routeProps, edit, groupToEdit } = this.props;
        const topBarProps = {
            className: "group-settings-add-top-bar",
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        {
                            text: "Groups",
                            pathname: "/account-settings/groups"
                        },
                        { text: edit ? groupToEdit.title : "Add Group" }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledDuoButtons
                    firstButtonClick={this.onCancelChanges}
                    secondButtonClick={this.onSaveChanges}
                    firstButtonText="Cancel"
                    secondButtonText="Save"
                    type={StyledDuoButtonsTypes.primary}
                />
            )
        };
        if (this.props.onUpdateToolBar) {
            this.props.onUpdateToolBar(topBarProps);
        }
    };
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const groups = accountSettings.alerts.groups;
    return {
        groups
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onSaveChanges: updateGroups
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSettingsAdd);
