import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import { BreadCrumbs, EmptyBlock, StyledButton, StyledButtonTypes } from "src/components";
import { InfiniteScroll } from "../../../../../components/infinite-scroll/infinite-scroll";
import { TagsSettingsListItem } from "../components/tag-list-item/tag-list-item";
import styles from "./styles.module.scss";
import { ReactComponent as TagsDoubleIcon } from "../../../../../mpd-library/icon/assets/tags-double.svg";
import { useDataFetch } from "../../../../../hooks/useDataFetch";
import { getTags } from "../../../../../requests/tagsRequests";
import { ListSort } from "../../../../../components/list-sort/ListSort";
import { SearchInput, SearchInputClasses } from "../../../../../components";
import classNames from "classnames";
import { AppState } from "../../../../../store";
import { removeWsData } from "../../../../../actions/websocket/websocket";

const classname = "tags-list";

export const SORT_BY_VALUE = [
    // { value: "name", label: "Name", paramName: "order" },
    { value: "inserted_at", label: "Last Created", paramName: "order" },
    { value: "updated_at", label: "Last Updated", paramName: "order" }
];

const FETCH_DATA_PARAMS_INIT_VALUE = {
    order: { value: "inserted_at", label: "Last Created", paramName: "order" }
};

const event = "contacts_tags_count_updated";
const topic = "portal_admin_general";

export const TagsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { tag_id, contacts_count } = useSelector(
        (state: AppState) => state.websocketReducer?.wsData?.[topic]?.[event] || {}
    );
    const { conversations_enabled, contacts_enabled } = useSelector(
        (state: AppState) => state.accountSettings.organization
    );

    const {
        entities: tags,
        hasMore: hasMoreTags,
        searchValue,
        setFetchDataParams,
        updateEntites,
        setSearchValue,
        deleteEntity
    } = useDataFetch({
        skeletonLoading: true,
        listItemHeight: 88,
        entityKey: "tags",
        fetchDataParams: FETCH_DATA_PARAMS_INIT_VALUE,
        fetchDataRequest: getTags
    });

    useEffect(() => {
        if (tag_id) {
            updateEntites({ [tag_id]: { contacts_count } });
            dispatch(removeWsData(`${topic}.${event}`));
        }
    }, [tag_id]);

    // const onFetchDataParamsChange = (option, fieldName) => {
    //     setFetchDataParams({
    //         ...fetchDataParamsState,
    //         [fieldName]: option
    //     });
    // };

    useEffect(() => {
        updateToolBar();
    }, []);

    const updateToolBar = () => {
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Tags" }]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: `/account-settings`, history }))
                    }}
                />
            ),
            rightElement: (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primary}
                    text={"New Tag"}
                    onClick={() => history.push("/account-settings/tags/create")}
                />
            )
        };
        dispatch(onUpdateToolBar(topBarProps));
    };

    return (
        <>
            <div className={styles["sub-header"]}>
                {/* <Dropdown
                    selectedValue={fetchDataParamsState.order}
                    targetClassName={DropdownTargetClasses.SELECT}
                    ArrowIconComponent
                    staticOptions={[
                        { label: "Date: Newest to Oldest", value: "inserted_at:desc" },
                        { label: "Date: Oldest to Newest", value: "inserted_at:asc" }
                        // { label: "Date: Newest to Oldest", value: "inserted_at:desc" }
                    ]}
                    onStaticOptionClick={(option) => onFetchDataParamsChange(option, "order")}
                />
                <Dropdown
                    ArrowIconComponent
                    selectedValue={fetchDataParamsState.sort}
                    position={Position.BOTTOM_LEFT}
                    targetClassName={DropdownTargetClasses.SELECT}
                    leftLabel="Sort By:"
                    staticOptions={[
                        { label: "All", value: "all" },
                        { label: "Contacts", value: "contacts" },
                        { label: "Conversations", value: "conversations" }
                    ]}
                    onStaticOptionClick={(option) => onFetchDataParamsChange(option, "sort")}
                /> */}
                <SearchInput
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={"Search tags..."}
                    className={classNames(SearchInputClasses.list, styles["search-input"])}
                />
                <ListSort sortByValues={SORT_BY_VALUE} onChange={setFetchDataParams} />
            </div>
            <InfiniteScroll onLoadMore={getTags} hasMore={hasMoreTags} listLength={tags.allIds.length}>
                <div className={styles[classname]}>
                    {!tags.allIds.length && (
                        <EmptyBlock
                            IconComponent={TagsDoubleIcon}
                            title={"Looks like you haven’t created any tags yet"}
                            subtitle={"To create your first tag, tap the blue “New Tag” button"}
                        />
                    )}
                    {tags.allIds.map((tagId: string, index) => {
                        return (
                            <TagsSettingsListItem
                                tag={tags.byId?.[tagId]}
                                key={tagId === "loading" ? `${tagId}${index}` : tagId}
                                loading={tagId === "loading"}
                                deleteEntity={deleteEntity}
                                conversations_enabled={conversations_enabled}
                                contacts_enabled={contacts_enabled}
                            />
                        );
                    })}
                </div>
            </InfiniteScroll>
        </>
    );
};
