import { Logo, ShapeCoords, AccountPrivilege, AccountUser, AccountRole } from "src/requests";
import { JurisdictionFeature, ProfilePic } from "./commonInterfaces";

// {"address1":"715 E 8th St","address2":null,"city":" Austin","country":"USA","description":"We are an unofficial account created for testing around Austin.","domain":null,"email":"admin@atlas1.dev","employees":3545,"id":1,"inserted_at":"2019-10-24T12:35:13Z","locale":"ru_RU","location":{"coordinates":[-97.737016,30.267923],"type":"Point"},"phone_number":null,"population":4698619,"profile_pic":{"type":"image/png","url":"https://s3.amazonaws.com/static.staging.atlas1.dev/ca588aee-ccc5-48db-b9e1-352b82ad8bf7Icon_512x512.png"},"short_title":"https://www.mobilepd.com","state":"Texas","subdomain":null,"team_agent":{"email":"admin@atlas1.dev","first_name":"Kushyar","id":1,"inserted_at":"2019-10-24T12:30:08Z","last_name":"Kasraie","phone_number":"(111) 111-1111","profile_pic":null,"status":"activated","type":"superadmin","updated_at":"2020-01-24T16:56:11Z"},"title":"Austin Police Department","twitter":"@twitter","url":"https://s3.amazonaws.com/static.staging.atlas1.dev/uploads/accounts/austin-police-department/2020-04-30/4f3cbcfd-8460-433b-9183-2b2038be3cb4_783px-Test-Logo.svg.png",}

export interface AccountOrganzationDetails {
    id: string;
    uuid: string;
    accountName?: string;
    totalEmployees: number | null;
    populationServed: number | null;
    primaryAddress: string;
    secondaryAddress: string;
    city: string;
    state: string;
    country: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    timeZone: string;
    timeFormat?: string;
    language?: string;
    address: string;
    url?: string;
    logo: Logo;
    center: ShapeCoords | null;
    postalCode?: string;
    description?: string;
    twitter?: string;
    shortTitle?: string;
    teamAgentFirstName: string;
    teamAgentLastName: string;
    teamAgentPhone: string;
    teamAgentEmail: string;
    teamAgentProfilePic: Logo;
}

export interface AccountSettingsSubscriptionOverviewState {
    currentPlan: string;
}

export interface SubscriptionDetailsListState {
    product: string;
    productSubtitle?: string;
    standardPrice: string;
    yourPrice: string;
}

export interface AccountSettingsSubscriptionDetailsState {
    list: Array<SubscriptionDetailsListState>;
}

export interface AccountSettingsSubscriptionState {
    details: AccountSettingsSubscriptionDetailsState;
    overview: AccountSettingsSubscriptionOverviewState;
}

export interface AccountSettingsBillingDetailsItem {
    invoiceID: string;
    dateCreated: string;
    dueDate: string;
    amount: string;
    terms: string;
    status: string;
}

export interface AccountSettingsBillingDetailsState {
    list: Array<AccountSettingsBillingDetailsItem>;
}

export interface AccountSettingsBillingOverviewState {
    billingAddress: {
        address: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
    };
    billingContact: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    };
}

export interface InvitationChannelsState {
    email: boolean;
    sms: boolean;
}

export interface AccountSettingsGeneralCurrentUser {
    uuid: string;
    id: string;
    description: string;
    firstName: string;
    initials: string;
    lastName: string;
    profile_pic?: ProfilePic;
    email: string;
    phone: string;
    privileges?: Array<AccountPrivilege>;
    invitationChannels: InvitationChannelsState;
    teams: Array<any>;
    role: {
        privileges: Array<AccountPrivilege>;
        id: string;
        title: string;
    } | null;
    activity: {
        list: Array<any>;
    };
}

export interface AccountSettingsUserState extends AccountUser {
    checked?: boolean;
}

export interface UserCreationErrors {
    [name: string]: Array<string>;
}

export interface UserActivityByIdState {
    list: Array<AccountSettingsActivity>;
    loadingListStatus: boolean;
    isFullList: boolean;
}

export interface AccountSettingsGeneralUsers {
    showListDetails: boolean;
    list: Array<AccountSettingsUserState>;
    usersLoading: boolean;
    isFullUsersList: boolean;
    currentUser: AccountSettingsGeneralCurrentUser;
    userCreationErrors?: UserCreationErrors;
    usersActivities?: {
        [id: string]: UserActivityByIdState;
    };
}

export interface AccountSettingsGeneralTeams {
    showListDetails: boolean;
    newTeam: {
        title: string;
        description: string;
    };
    list: Array<any>;
}

export interface AccountSettingsGeneralCurrentRole {
    id: string;
    title: string;
    description: string;
    users: Array<AccountUser>;
    privileges: Array<AccountPrivilege>;
    inserted_at: Date | null;
    updated_at: Date | null;
}

export interface AccountSettingsGeneralRoles {
    showListDetails: boolean;
    list: Array<AccountRole>;
    rolesLoading: boolean;
    isFullRolesList: boolean;
    currentRole: AccountSettingsGeneralCurrentRole;
}

export interface AccountSettingsGeneralChannels {
    facebook: {
        status: any | null;
        list: Array<any>;
    };
    twitter: {
        status: any | null;
        list: Array<any>;
    };
    google: {
        status: any | null;
    };
    voice: {
        status: any | null;
    };
    email: {
        status: any | null;
    };
    sms: {
        status: any | null;
    };
}

export interface ArrayChannel {
    id: string;
    name: string;
    page_name: string;
    profile_pic: string;
    type: string;
    consversations_enabled: boolean;
    conversations_settings: any;
    alerts_pre_selected: boolean;
    alerts_enabled: boolean;
    status: "offline" | "online";
    phone_number_formatted?: string;
    phone_number?: string;
}

export interface AccountSettingsGeneralAlertGroups {
    showListDetails: boolean;
    list: Array<any>;
}

export interface IsFullAccountSettingsLists {
    users: boolean;
    roles: boolean;
}

export interface ActivitiesActor {
    first_name: string;
    id: string;
    last_name: string;
    type: string;
}

export interface ActivitiesObject {
    id: string;
    title: string;
    type: string;
}

export interface ActivitiesOrigin {
    id: string;
    title: string;
    type: string;
}

export interface ActivitiesGeoIp {
    city: string;
    country_code: string;
    country_name: string;
    ip: string;
    latitude: number;
    longitude: number;
    region_code: string;
    region_name: string;
}

export interface ActivitiesUserAgent {
    client: {
        name: string;
        type: string;
        version: string;
    };
    device: {
        brand: string;
        type: string;
    };
    os: {
        name: string;
        version: string;
    };
    user_agent: string;
}

export interface AccountSettingsActivity {
    actor: ActivitiesActor;
    id: string;
    geo_ip: ActivitiesGeoIp;
    object?: ActivitiesObject;
    origin?: ActivitiesOrigin;
    published: Date;
    summary: string;
    type: string;
    user_agent: ActivitiesUserAgent;
}
export interface AccountOrganizationError {
    url: string;
}

export interface AccountSettingsOrganization {
    details: AccountOrganzationDetails;
    accountOrganizationError: AccountOrganizationError;
    jurisdiction: JurisdictionFeature | null;
    subscription: AccountSettingsSubscriptionState;
    conversations_enabled: boolean;
    contacts_enabled: boolean;
    billing: {
        details: AccountSettingsBillingDetailsState;
        overview: AccountSettingsBillingOverviewState;
    };
    branded_phone_number: string;
}

export interface Channels {
    facebook: Array<ArrayChannel>;
    twitter: Array<ArrayChannel>;
    atlasone: Array<ArrayChannel>;
}

export type ChannelsAvailable = {
    [key: string]: {
        inbound: boolean;
        outbound: boolean;
    };
};
export interface AccountSettingsGeneral {
    privileges: Array<AccountPrivilege>;
    users: AccountSettingsGeneralUsers;
    activityTypes?: Array<string>;
    teams: AccountSettingsGeneralTeams;
    roles: AccountSettingsGeneralRoles;
    channels: AccountSettingsGeneralChannels;
    channelsArray: Channels;
    channelsAvailable: ChannelsAvailable;
    security: {
        enable2FA: boolean;
        enableSSO: boolean;
    };
    auditLog: {
        hours: {
            startTime: any;
            endTime: any;
        };
        activities: Array<AccountSettingsActivity>;
        isFullActivityLog: boolean;
        isActivitiesLoading?: boolean;
    };
    manage: any;
}

export interface AccountSettingsAlerts {
    mapLayers: Array<any>;
    loadingMapLayers: boolean;
    isMapLayersFull: boolean;
    searchMapLayers: Array<any>;
    isSearchMapLayersFull: boolean;
    groups: AccountSettingsGeneralAlertGroups;
}

export interface AccountSettingsState {
    rightPanelExpanded: boolean;
    organization: AccountSettingsOrganization;
    general: AccountSettingsGeneral;
    alerts: AccountSettingsAlerts;
    google: any;
    sms: any;
    voice: any;
    getChannelsProcessingStatus: boolean;
    newApiKeysRecord: any;
    settings: any;
    mainCountries: Array<{ value: string; label: string }>;
}

export const defaultAccountSettingsState: AccountSettingsState = {
    mainCountries: [],
    rightPanelExpanded: false,
    settings: null,
    organization: {
        details: {
            id: "",
            uuid: "",
            accountName: "",
            totalEmployees: null,
            populationServed: null,
            primaryAddress: "",
            secondaryAddress: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            timeZone: "",
            timeFormat: "",
            language: "",
            address: "",
            url: "",
            logo: {
                src: "",
                url: "",
                type: ""
            },
            center: null,
            teamAgentFirstName: "",
            teamAgentLastName: "",
            teamAgentEmail: "",
            teamAgentPhone: "",
            teamAgentProfilePic: {
                src: "",
                type: "",
                url: ""
            }
        },
        accountOrganizationError: {
            url: ""
        },
        jurisdiction: null,
        subscription: {
            details: {
                list: [
                    {
                        product: "Spot Pro",
                        productSubtitle: "(Using 17/25 Accounts)",
                        standardPrice: "$9,000",
                        yourPrice: "$9,000"
                    },
                    {
                        product: "Voice Add-On",
                        standardPrice: "$.04/Call",
                        yourPrice: "$.04/Call"
                    },
                    {
                        product: "Email Add-On",
                        standardPrice: "Net 30",
                        yourPrice: "$.02/Text"
                    },
                    {
                        product: "15 Administrator Accounts",
                        standardPrice: "$11,250",
                        yourPrice: "$5,625"
                    },
                    {
                        product: "Branded App (One-Time)",
                        standardPrice: "Net 30",
                        yourPrice: "$5,000"
                    },
                    {
                        product: "Annual Subscription Fee",
                        standardPrice: "",
                        yourPrice: "$14,625 + fees"
                    }
                ]
            },
            overview: {
                currentPlan: "essential"
            }
        },
        billing: {
            details: {
                list: [
                    {
                        invoiceID: "INV-0249218291",
                        dateCreated: "12/31/2018",
                        dueDate: "2/2/2019",
                        amount: "$18,000",
                        terms: "Net 30",
                        status: "Pending"
                    },
                    {
                        invoiceID: "INV-0249218271",
                        dateCreated: "12/31/2017",
                        dueDate: "2/2/2018",
                        amount: "$22,000",
                        terms: "Net 30",
                        status: "Paid"
                    }
                ]
            },
            overview: {
                billingAddress: {
                    address: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    country: ""
                },
                billingContact: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: ""
                }
            }
        }
    },
    general: {
        users: {
            showListDetails: false,
            list: [],
            usersLoading: false,
            isFullUsersList: false,
            currentUser: {
                uuid: "",
                id: "",
                description: "",
                firstName: "",
                initials: "",
                lastName: "",
                email: "",
                phone: "",
                privileges: [],
                invitationChannels: {
                    email: true,
                    sms: false
                },
                teams: [],
                role: {
                    privileges: [],
                    id: "",
                    title: ""
                },
                activity: {
                    list: []
                }
            },
            userCreationErrors: undefined
        },
        roles: {
            showListDetails: false,
            list: [],
            rolesLoading: false,
            isFullRolesList: false,
            currentRole: {
                id: "",
                title: "",
                description: "",
                users: [],
                privileges: [],
                inserted_at: null,
                updated_at: null
            }
        },
        teams: {
            showListDetails: false,
            newTeam: {
                title: "",
                description: ""
            },
            list: []
        },
        security: {
            enable2FA: false,
            enableSSO: false
        },
        auditLog: {
            hours: {
                startTime: null,
                endTime: null
            },
            activities: [],
            isFullActivityLog: false
        },
        channelsArray: {
            atlasone: [],
            facebook: [],
            twitter: []
        },
        manage: null,
        privileges: []
    },
    google: null,
    sms: null,
    voice: null,
    alerts: {
        groups: {
            showListDetails: false,
            list: []
        },
        mapLayers: [],
        isMapLayersFull: false,
        searchMapLayers: [],
        isSearchMapLayersFull: false,
        loadingMapLayers: false
    },
    getChannelsProcessingStatus: false,
    newApiKeysRecord: null
};
