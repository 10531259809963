import { Classes, Position, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { UseConversationsTags } from "src/containers/conversations/components/chat/hooks/useConversationsTags";
import { MPDIcon, MPDSelect, MPDSelectItem } from "src/mpd-library";
import { ConversationTag } from "src/store";
import { TagsContext } from "../../tags-settings";
import styles from "./styles.module.scss";
import { ReactComponent as TagsDoubleIcon } from "../../../../../../mpd-library/icon/assets/tags-double.svg";
import { ReactComponent as VerticalTripleLineIcon } from "../../../../../../mpd-library/icon/assets/vertical-triple-line.svg";
import { Dropdown } from "../../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "../../../../../../mpd-library/dropdown/components/target/constants";

const classname = "tag-list-item";

type TagsSettingsListItemProps = {
    tag: ConversationTag;
};

export const TagsSettingsListItem = ({
    tag,
    loading,
    deleteEntity,
    conversations_enabled,
    contacts_enabled
}: TagsSettingsListItemProps) => {
    const {
        name,
        id,
        description,
        ancestors_formatted,
        conversations_count,
        created_by_admin,
        contacts_count,
        enable_for_contacts,
        enable_for_conversations
    } = tag || {};
    console.log("TAG >>>>", tag);
    const dispatch = useDispatch();
    const history = useHistory();
    const { removeTag }: UseConversationsTags = useContext(TagsContext);

    const onThreeDotButtonSelectOption = (event: any) => {
        const value = event.currentTarget.textContent.toLowerCase();
        if (value === "delete") {
            dispatch(removeTag(id));
        }
    };

    const onEditClick = () => {
        history.push(`/account-settings/tags/${id}/edit`);
    };

    const onDeleteClick = async () => {
        removeTag(id);
        deleteEntity(id);
    };

    const moreDropdownItems = useMemo(
        () => [
            {
                label: "Edit",
                value: "edit",
                onClick: onEditClick
            },
            {
                label: "Delete",
                value: "delete",
                onClick: onDeleteClick
            }
        ],
        [id]
    );

    const renderMoreDropdown = () => {
        return (
            <div className={styles[`${classname}-more-dropdown-wrapper`]} id={`${id}-dropdown`}>
                <Dropdown
                    targetClassName={classNames(DropdownTargetClasses.SELECT, DropdownTargetClasses.MORE)}
                    staticOptions={moreDropdownItems}
                    LeftIconComponent={VerticalTripleLineIcon}
                    position={Position.BOTTOM_RIGHT}
                />
            </div>
            // <div className={styles[`${classname}-more-dropdown-wrapper`]} id={`${id}-dropdown`}>
            //     <MPDSelect
            //         className={classNames(
            //             "rounded-dropdown",
            //             styles[`${classname}-dropdown`],
            //             styles[`${classname}-dropdown-more`],
            //             "more-dropdown"
            //         )}
            //         customOptions={
            //             <div className={`simple-dropdwon`}>
            //                 {moreDropdownItems.map((option: any, index: number) => {
            //                     return (
            //                         <MPDSelectItem
            //                             className={styles[`${classname}-item`]}
            //                             onClick={option.onClick}
            //                             key={index}
            //                         >
            //                             <>
            //                                 <Text>{option.label}</Text>
            //                             </>
            //                         </MPDSelectItem>
            //                     );
            //                 })}
            //             </div>
            //         }
            //         arrowIcon={null}
            //         LeftIconComponent={VerticalTripleLineIcon}
            //         position={Position.BOTTOM_RIGHT}
            //         value={""}
            //         cannotChange={true}
            //         onChange={onThreeDotButtonSelectOption}
            //     />
            // </div>
        );
    };

    return (
        <div className={styles[classname]}>
            <div className={styles["main-content"]}>
                <div className={styles[`${classname}-left-wrapper`]}>
                    <MPDIcon
                        IconComponent={TagsDoubleIcon}
                        className={classNames(styles[`${classname}-left-icon`], loading && Classes.SKELETON)}
                    />
                    <div className={styles[`${classname}-name-description-wrapper`]}>
                        <Text className={classNames(styles[`${classname}-name`], loading && Classes.SKELETON)}>
                            {!!ancestors_formatted?.length && (
                                <span
                                    className={styles[`${classname}-parent-tag-name`]}
                                >{`${ancestors_formatted} > `}</span>
                            )}
                            {name}
                        </Text>
                        <Text className={classNames(styles[`${classname}-description`], loading && Classes.SKELETON)}>
                            {description}
                        </Text>
                    </div>
                </div>
                {!loading && (
                    <>
                        <div className={styles[`${classname}-right-wrapper`]}>
                            <div className={styles[`${classname}-info-block`]}>
                                <Text className={styles[`${classname}-info-title`]}>created by</Text>
                                <img
                                    src={created_by_admin?.profile_pic?.url}
                                    className={styles[`${classname}-admin-avatar`]}
                                    alt=""
                                />
                            </div>
                            {conversations_enabled && (
                                <div
                                    className={classNames(
                                        styles[`${classname}-info-block`],
                                        !enable_for_conversations && styles["disabled"]
                                    )}
                                >
                                    <Text className={styles[`${classname}-info-title`]}>conversations</Text>
                                    <Text className={styles[`${classname}-info-value`]}>{conversations_count}</Text>
                                </div>
                            )}
                            {contacts_enabled && (
                                <div
                                    className={classNames(
                                        styles[`${classname}-info-block`],
                                        !enable_for_contacts && styles["disabled"]
                                    )}
                                >
                                    <Text className={styles[`${classname}-info-title`]}>contacts</Text>
                                    <Text className={styles[`${classname}-info-value`]}>{contacts_count}</Text>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            {renderMoreDropdown()}
        </div>
    );
};
