import { Position, Spinner, Text } from "@blueprintjs/core";
import classNames from "classnames";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setHasMoreConversations } from "src/actions/conversations/conversations";
import { onToggleMobileSideBar } from "src/actions/global/global";
import { setNotifications } from "src/actions/notifications/notifications";
import {
    getNotifications,
    muteNotifications,
    onDeleteNotifications,
    onMarkNotificationsAsRead
} from "src/actions/notifications/notifications-api";
import useInfiniteScroll from "src/containers/conversations/components/chat/useInfiniteScroll";
import { MPDSelect, MPDSelectItem } from "src/mpd-library";
import { AppState, Notification } from "src/store";
import { LeftPanelContainer } from "..";
import { EmptyBlock } from "../empty-block/empty-block";
import { StyledButton, StyledButtonTypes } from "../styled-button";
import { StyledCheckbox } from "../styled-checkbox/styled-checkbox";
import { TopToolbar } from "../top-toolbar";
import { NotificationsPopoverItem } from "./components/notifications-popover-item/notifications-popover-item";
import styles from "./styles.module.scss";
import { ReactComponent as NotificationsEmptyAlarmBellIcon } from "../../mpd-library/icon/assets/notifications-empty-alarm-bell.svg";
import { ReactComponent as GearIcon } from "../../mpd-library/icon/assets/gear.svg";
import { ReactComponent as AlarmBellMuteIcon } from "../../mpd-library/icon/assets/alarm-bell-mute.svg";
import { ReactComponent as AlarmBellMutedIcon } from "../../mpd-library/icon/assets/alarm-bell-muted.svg";
import { ReactComponent as CloseIcon } from "../../mpd-library/icon/assets/close.svg";
import { ReactComponent as BreadCrumbsIcon } from "../../mpd-library/icon/assets/bread-crumbs.svg";

const classname = "notifications-popover";

const arrayOfEmptyObjects = () => {
    const arr: Array<{}> = [];
    for (let i = 0; i <= 20; i++) {
        arr.push({});
    }
    return arr;
};

type SelectedNotififcations = { [key: string]: boolean };

type FilterObject = { name: string; value: FiltersEnum };

const filterValues: Array<FilterObject> = [
    {
        name: "Newest",
        value: "newest"
    },
    {
        name: "Oldest",
        value: "oldest"
    },
    {
        name: "Unread",
        value: "unread"
    }
];

type FiltersEnum = "newest" | "oldest" | "unread";

const NotificationsPopover = ({ onCloseClick }) => {
    const dispatch = useDispatch();
    const [getNotificartionsProcessing, setGetNotificationsProcessing] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState<SelectedNotififcations>({});
    const [deleteNotificationsProcessing, setDeleteNotififcationsProcessing] = useState(false);
    const [markAsReadNotififcationsProcessing, setMarkAsReadNotififcationsProcessing] = useState(false);
    const [isFilterDropdownOpened, setIsFilterDropdownOpened] = useState(false);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<FiltersEnum>(null);
    const [muteNotificationsProcessing, setMuteNotificationsProcessing] = useState<boolean>(false);
    const inAppNotificationsUnmuted = useSelector(
        (state: AppState) => state.notifications.notificationsSettings?.types["in-app"]
    );
    const isMobileSize = useSelector((state: AppState) => state.global.isMobileSize);
    const mobileSideBarActive = useSelector((state: AppState) => state.global.mobileSideBarActive);
    const emailNotificationsUnuted = useSelector(
        (state: AppState) => state.notifications.notificationsSettings?.types.email
    );
    const notificationsUnmuted = inAppNotificationsUnmuted || emailNotificationsUnuted;
    const notifications = useSelector((state: AppState) => state.notifications.notificationsList);
    const history = useHistory();

    const hasMoreNotifications = useSelector((state: AppState) => state.notifications.hasMoreNotifications);

    const [bottomSentryRef] = useInfiniteScroll({
        loading: isLoadingMore,
        hasNextPage: hasMoreNotifications,
        onLoadMore: async () => {
            try {
                setIsLoadingMore(true);
                await dispatch(getNotifications(null, notifications.length));
            } finally {
                setIsLoadingMore(false);
            }
        }
    });

    useEffect(() => {
        const onGetNotifications = async () => {
            try {
                setGetNotificationsProcessing(true);
                await dispatch(getNotifications(null));
            } finally {
                setGetNotificationsProcessing(false);
            }
        };

        onGetNotifications();

        return () => {
            dispatch(setNotifications([]));
        };
    }, []);

    const onSettingsClick = () => {
        onCloseClick();
        history.push(`/user-settings/notifications`);
    };

    const onClickChckbox = useCallback(
        (event: FormEvent<HTMLInputElement>, id: string) => {
            const { checked } = event.target;
            const updated = { ...selectedNotifications };
            if (checked) {
                updated[id] = true;
            } else {
                delete updated[id];
            }
            setSelectedNotifications(updated);
        },
        [selectedNotifications]
    );

    const onMarkAsReadClick = async () => {
        try {
            setMarkAsReadNotififcationsProcessing(true);
            const notificationsIds = Object.keys(selectedNotifications);
            await dispatch(onMarkNotificationsAsRead(notificationsIds, isSelectAllChecked));
            setSelectedNotifications({});
        } finally {
            setMarkAsReadNotififcationsProcessing(false);
        }
    };

    const onArchiveClick = async () => {
        try {
            setDeleteNotififcationsProcessing(true);
            const notificationsIds = Object.keys(selectedNotifications);
            await dispatch(onDeleteNotifications(notificationsIds, isSelectAllChecked));
        } finally {
            setDeleteNotififcationsProcessing(false);
            setSelectedNotifications({});
        }
    };

    const onChangeSelectAllCheckbox = (event: FormEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setIsSelectAllChecked(checked);
        !checked && setSelectedNotifications({});
    };

    const isSettingsMode = useMemo(() => !!Object.keys(selectedNotifications).length, [selectedNotifications]);

    const onFilterSelect = useCallback(
        async (value: FiltersEnum) => {
            try {
                setSelectedFilter(value);
                onToggleFiltersDropdown(false);
                dispatch(setNotifications([]));
                setGetNotificationsProcessing(true);
                dispatch(setHasMoreConversations(true));
                await dispatch(getNotifications(value));
            } finally {
                setGetNotificationsProcessing(false);
            }
        },
        [selectedFilter]
    );

    const valueForFiltersDropdown = useMemo(() => {
        if (selectedFilter) {
            return filterValues.find((filterObject: FilterObject) => selectedFilter === filterObject.value).name;
        }

        return "Filter";
    }, [selectedFilter]);

    const onToggleFiltersDropdown = (status: boolean) => {
        setIsFilterDropdownOpened(status);
    };

    const onMuteClick = async () => {
        try {
            setMuteNotificationsProcessing(true);
            await dispatch(muteNotifications());
        } finally {
            setMuteNotificationsProcessing(false);
        }
    };

    const component = (
        <div className={styles[classname]}>
            <TopToolbar
                className={styles[`${classname}-top-toolbar`]}
                centerElement={!isMobileSize && <Text className={styles[`${classname}-title`]}>Notifications</Text>}
                rightElement={!isMobileSize && <StyledButton IconComponent={CloseIcon} onClick={onCloseClick} />}
                leftElement={
                    isMobileSize ? (
                        <>
                            <StyledButton
                                className={styles[`${classname}-collapse-button`]}
                                onClick={() => dispatch(onToggleMobileSideBar())}
                                IconComponent={BreadCrumbsIcon}
                            />
                            <div className={"list-searchbar-title"}>Notificationss</div>
                        </>
                    ) : (
                        <div style={{ display: "flex", flex: 1, width: "32px", maxWidth: "32px" }} />
                    )
                }
            />
            <div className={styles[`${classname}-sub-header`]}>
                {!isSettingsMode && (
                    <>
                        <MPDSelect
                            ArrowIconComponent
                            isOpenFromParent={isFilterDropdownOpened}
                            onToggleOptions={onToggleFiltersDropdown}
                            position={Position.BOTTOM_LEFT}
                            stopPropagation
                            className={classNames("rounded-dropdown", styles[`${classname}-dropdown`])}
                            defaultValue={"Filter"}
                            value={valueForFiltersDropdown}
                            usePortal
                            portalClassName={styles[`${classname}-filter-dropdown-portal`]}
                            customOptions={
                                <div className={"simple-dropdown"}>
                                    {filterValues.map((option) => {
                                        return (
                                            <MPDSelectItem
                                                onClick={() => onFilterSelect(option.value)}
                                                selected={valueForFiltersDropdown === option.name}
                                            >
                                                <Text>{option.name}</Text>
                                            </MPDSelectItem>
                                        );
                                    })}
                                </div>
                            }
                        />
                        <div>
                            <StyledButton
                                onClick={onMuteClick}
                                type={StyledButtonTypes.secondary}
                                IconComponent={notificationsUnmuted ? AlarmBellMuteIcon : AlarmBellMutedIcon}
                                className={classNames(
                                    styles[`${classname}-mute-button`],
                                    notificationsUnmuted ? styles[`unmuted`] : styles[`muted`]
                                )}
                                processing={muteNotificationsProcessing}
                            />
                            <StyledButton
                                type={StyledButtonTypes.secondary}
                                IconComponent={GearIcon}
                                onClick={onSettingsClick}
                                className={"only-icon"}
                            />
                        </div>
                    </>
                )}
                {isSettingsMode && (
                    <>
                        <StyledCheckbox checked={isSelectAllChecked} onChange={onChangeSelectAllCheckbox} />
                        <div className={styles[`${classname}-sub-header-right-wrapper`]}>
                            <StyledButton
                                text={"Mark as Read"}
                                type={StyledButtonTypes.secondary}
                                className={styles[`${classname}-read-button`]}
                                processing={markAsReadNotififcationsProcessing}
                                onClick={onMarkAsReadClick}
                            />
                            <StyledButton
                                text={"Archive"}
                                type={StyledButtonTypes.delete}
                                processing={deleteNotificationsProcessing}
                                onClick={onArchiveClick}
                            />
                        </div>
                    </>
                )}
            </div>
            {!notifications.length && !getNotificartionsProcessing && (
                <EmptyBlock
                    IconComponent={NotificationsEmptyAlarmBellIcon}
                    title={selectedFilter === "unread" ? "No unread notifications" : "No notifications"}
                    subtitle={
                        selectedFilter === "unread"
                            ? "You don’t have any unread notifications"
                            : "You haven’t recieved any notifications yet"
                    }
                />
            )}
            {!notifications.length &&
                getNotificartionsProcessing &&
                arrayOfEmptyObjects().map((fakeNotification: {}, index: number) => {
                    return (
                        <NotificationsPopoverItem
                            isLoadingNotificationsList={true}
                            notification={{}}
                            key={index}
                            isSettingsModeOn={false}
                        />
                    );
                })}
            {!!notifications.length &&
                notifications.map((notification: Notification) => {
                    return (
                        <NotificationsPopoverItem
                            isLoadingNotificationsList={false}
                            notification={notification}
                            key={notification.id}
                            isSettingsModeOn={isSettingsMode}
                            onClickChckbox={onClickChckbox}
                            checked={!!selectedNotifications[notification.id]}
                            isSelectAllChecked={isSelectAllChecked}
                            onClosePopover={onCloseClick}
                        />
                    );
                })}
            {!getNotificartionsProcessing && (isLoadingMore || hasMoreNotifications) && (
                <div ref={bottomSentryRef} className={styles[`${classname}-load-more-spinner-wrapper`]}>
                    <Spinner intent="primary" size={20} />
                </div>
            )}
        </div>
    );

    if (isMobileSize) {
        return (
            <LeftPanelContainer
                className={styles["notifications-list"]}
                collapsed={false}
                mobileSideBarActive={mobileSideBarActive}
                onOverlayClick={() => dispatch(onToggleMobileSideBar())}
                overlayVisible={mobileSideBarActive}
            >
                {component}
            </LeftPanelContainer>
        );
    }

    return component;
};

export default NotificationsPopover;
