// tslint:disable jsx-no-lambda
import React from "react";
import { connect } from "react-redux";
// import { bindActionCreators, Dispatch } from "redux";
import { globalActions } from "src/actions";

import { Redirect, Route, Switch } from "react-router-dom";

import UserSettingsAdd from "./user-settings-add/user-settings-add";
import UserSettingsViewDetailsActivity from "./user-settings-view-details-activity/user-settings-view-details-activity";
import UserSettingsViewDetails from "./user-settings-view-details/user-settings-view-details";

import { BreadCrumbs, StyledButton, StyledButtonTypes, StyledTabs, StyledTabsTypes } from "src/components";

import { MPDScroll, MPDTree } from "src/mpd-library";

import { TabId } from "@blueprintjs/core";

import { SettingsUsersListItem } from "../../components";

import { IDetailView } from "../../../";

import "./styles.scss";
import { AppState, AccountSettingsState, AccountSettingsGeneralCurrentUser, AccountSettingsUserState } from "src/store";
import { RouteComponentProps, matchPath } from "react-router";
import { AccountSettingsUsersExtendingParams } from "src/requests";
import { bindActionCreators, Dispatch } from "redux";
import {
    addUser,
    changeUserStatus,
    // getAccountSettingsUsers,
    initiateUsers,
    viewUser
} from "src/actions/account-settings/account-settings-api";
import { UsersList } from "./users-list";

interface IUserSettingsProps extends IDetailView {
    currentUser: AccountSettingsGeneralCurrentUser;
    routeProps: RouteComponentProps;
    accountSettings: AccountSettingsState;
    usersLoading: boolean;
    isFullUsersList: boolean;
    onInitiateUsers: (id: string, params: AccountSettingsUsersExtendingParams) => void;
    onViewUser: (id: string, routeProps: RouteComponentProps) => void;
    onAddUser: (routeProps: RouteComponentProps) => void;
    onChangeUserStatus: (status: string, user: AccountSettingsUserState) => void;
    // onGetAccountSettingsUsers: (params: AccountSettingsUsersExtendingParams) => void;
}

interface IUserSettingsState {
    listHeaderChecked: boolean;
    selectedTabNum: TabId;
    tabs: Array<string>;
    filter?: string;
    teamAgentEmail: string;
}

class UserSettings extends React.Component<IUserSettingsProps, IUserSettingsState> {
    constructor(props: IUserSettingsProps) {
        super(props);
        const teamAgentEmail = this.props.accountSettings.organization.details.email;

        this.state = {
            teamAgentEmail,
            listHeaderChecked: false,
            selectedTabNum: 0,
            tabs: ["details", "user activity"],
            filter: ""
        };
        this.updateToolBar(props);
    }

    public componentDidMount() {
        const { routeProps, accountSettings } = this.props;
        const id = globalActions.getSelectedId(routeProps);

        if (!accountSettings.general?.users?.list?.length) {
            this.props.onInitiateUsers(id, { sortFirstName: "asc" });
        }
    }

    public componentDidUpdate(prevProps: IUserSettingsProps) {
        if (prevProps.routeProps !== this.props.routeProps) {
            this.updateToolBar(this.props);
        }
        if (
            prevProps.accountSettings.organization.details.email !==
                this.props.accountSettings.organization.details.email &&
            this.props.accountSettings.organization.details.email
        ) {
            const teamAgentEmail = this.props.accountSettings.organization.details.email;
            this.setState({ teamAgentEmail });
        }
    }

    public render() {
        const { routeProps, currentUser, usersLoading } = this.props;
        const { selectedTabNum, tabs } = this.state;
        const classes = "user-settings";
        return (
            <div className={classes}>
                <Switch location={routeProps.history.location}>
                    <Route path={"/account-settings/users/"} exact={true} render={this.renderMain as any} />
                    <MPDScroll className={classes + "-inner-container"} yDirection={true}>
                        <Route
                            path={"/account-settings/users/view"}
                            render={() => (
                                <>
                                    <StyledTabs
                                        className={classes + "-tabs"}
                                        tabs={tabs}
                                        type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                                        tabsProps={{
                                            id: "social-settings-tabs",
                                            onChange: this.onChangeTab,
                                            selectedTabId: selectedTabNum
                                        }}
                                        panel={
                                            selectedTabNum === 0 ? (
                                                <UserSettingsViewDetails
                                                    routeProps={routeProps}
                                                    currentUser={currentUser}
                                                    onCloseRightPanel={this.props.onCloseRightPanel}
                                                    onToggleRightPanel={this.props.onToggleRightPanel}
                                                />
                                            ) : (
                                                <UserSettingsViewDetailsActivity
                                                    routeProps={routeProps}
                                                    currentUser={currentUser}
                                                    onToggleRightPanel={this.props.onToggleRightPanel}
                                                />
                                            )
                                        }
                                    />
                                </>
                            )}
                        />
                        {currentUser === null && (
                            <Redirect from="/account-settings/users/view" to="/account-settings/users/" />
                        )}
                        <Route
                            path={`/account-settings/users/add`}
                            render={() => (
                                <UserSettingsAdd
                                    routeProps={routeProps}
                                    onToggleRightPanel={this.props.onToggleRightPanel}
                                    onCloseRightPanel={this.props.onCloseRightPanel}
                                    onUpdateChangesMade={this.props.onUpdateChangesMade}
                                    currentUser={currentUser}
                                    title="New User"
                                />
                            )}
                        />
                        {!usersLoading && (
                            <Route
                                path={`/account-settings/users/edit/:id`}
                                render={() => (
                                    <UserSettingsAdd
                                        routeProps={routeProps}
                                        onToggleRightPanel={this.props.onToggleRightPanel}
                                        currentUser={currentUser}
                                        addUser={addUser}
                                    />
                                )}
                            />
                        )}
                    </MPDScroll>
                </Switch>
            </div>
        );
    }

    private renderList = (props: { list: Array<AccountSettingsUserState>; loading: boolean }) => {
        return (
            <React.Fragment>
                {/* {props.list.map((account: any, index: number) => (
                    <SettingsUsersListItem
                        className={"user-settings-list-item"}
                        key={index}
                        firstName={account.first_name}
                        lastName={account.last_name}
                        roles={account.roles}
                        loading={props.loading}
                        status={account.status}
                        profilePic={account.profile_pic}
                        accountType={account.email === this.state.teamAgentEmail ? "Team Agent" : "Administrator"}
                        statusOptions={this.renderOption(account.status)}
                        onClick={() => this.onViewUser(index)}
                        userId={account.id}
                        checkBoxProps={{
                            checked: account.checked,
                            onChange: (e) => this.onCheckUserList(e, index)
                        }}
                        onStatusChanged={(e) => this.onListStatusChange(e, index)}
                        profile_pic_color={account.profile_pic_color}
                    />
                ))} */}
                <UsersList />
            </React.Fragment>
        );
    };

    private onChangeTab = (newTabNum: TabId) => {
        this.setState({ selectedTabNum: newTabNum });
    };

    private renderMain = (classes = "user-settings") => {
        const {
            accountSettings,
            isOrSmallerTabletPortraitSize,
            usersLoading,
            onInitiateUsers,
            routeProps,
            isFullUsersList
        } = this.props;
        const { listHeaderChecked, filter } = this.state;

        const list = accountSettings.general.users.list;
        const id = globalActions.getSelectedId(routeProps);

        return (
            <React.Fragment>
                {/* {!list || (list.length === 0 && !usersLoading && !filter) ? (
                    <ContainerEmpty
                        className={classes + "-empty"}
                        title="No Users Found"
                        subtitle="Looks like you haven’t added any users yet. Click on the “Add User” button above to add your first user."
                        iconName="groupBlue"
                    />
                ) : (
                    <SimpleList
                        isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                        renderedComponent={this.renderList}
                        list={list}
                        showOptions={listHeaderChecked}
                        placeholder="Search users…"
                        hideCheckMarkSelect={true}
                        loading={usersLoading && !list.length}
                        sort={["first_name", "last_name"]}
                        listId="usersList"
                        onGetListItems={this.onGetListItems}
                        onGetInitalList={(props: AccountSettingsUsersExtendingParams) => onInitiateUsers(id, props)}
                        handleFilterChange={this.handleFilterChange}
                        isListFullyLoaded={isFullUsersList}
                        loadingMoreComp={
                            <MPDTree
                                NodeItem={SettingsUsersListItem}
                                contents={
                                    [
                                        { firstName: "", lastName: "" },
                                        { firstName: "", lastName: "" },
                                        { firstName: "", lastName: "" },
                                        { firstName: "", lastName: "" },
                                        { firstName: "", lastName: "" }
                                    ] as any
                                }
                                loading={usersLoading}
                            />
                        }
                    />
                )} */}
                <UsersList />
            </React.Fragment>
        );
    };

    private onCheckUserList = (event: React.SyntheticEvent, index: number) => {
        const { accountSettings } = this.props;
        const list = accountSettings.general.users.list;
        list[index].checked = !list[index].checked;
        if (list.some((element: AccountSettingsUserState) => element.checked)) {
            this.setState({ listHeaderChecked: true });
        } else if (list.every((element: AccountSettingsUserState) => !element.checked)) {
            this.setState({ listHeaderChecked: false });
        }
    };

    private onAddUser = () => {
        const { routeProps } = this.props;
        this.props.onAddUser(routeProps);
    };

    private updateToolBar = (props: IUserSettingsProps) => {
        const { routeProps } = props;

        const isUserSettingsPath = matchPath(routeProps.location.pathname, {
            path: ["/account-settings/users", "/account-settings/users/"],
            exact: true,
            strict: false
        });

        if (!isUserSettingsPath) {
            return;
        }

        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Users", pathname: "/account-settings/users" }]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: <StyledButton type={StyledButtonTypes.primary} text="ADD USER" onClick={this.onAddUser} />
        };
        this.props.onUpdateToolBar(topBarProps);
    };
}

interface UserSettingsConnectProps {
    routeProps: RouteComponentProps;
    onUpdateChangesMade: (changesMade: boolean) => void;
    onToggleRightPanel: () => void;
    onCloseRightPanel: () => void;
}

const mapStateToProps = (state: AppState, ownProps: UserSettingsConnectProps): Partial<IUserSettingsProps> => {
    const {
        accountSettings,
        accountSettings: {
            general: {
                users: { usersLoading, isFullUsersList, currentUser }
            }
        },
        global: { isOrSmallerTabletPortraitSize }
    } = state;

    return {
        usersLoading,
        accountSettings,
        currentUser,
        isOrSmallerTabletPortraitSize,
        routeProps: ownProps.routeProps,
        isFullUsersList
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onInitiateUsers: initiateUsers,
            onAddUser: addUser,
            onViewUser: viewUser,
            // onGetAccountSettingsUsers: getAccountSettingsUsers,
            onChangeUserStatus: changeUserStatus
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
