import classNames from "classnames";
import styles from "./MapColumnsTableItem.module.scss";
import { Dropdown } from "../../../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { Position, Text } from "@blueprintjs/core";

const LABEL_VALUE_MATCH = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone_number: "Phone Number",
    address: "Street Address"
};

const LABEl_VALUE_ON_CONFLICT_MATCH = {
    overwrite: "Overwrite",
    skip: "Skip",
    update_missing: "Update Missing"
};

const CONFLICT_OPTIONS = [
    { label: "Overwrite", value: "overwrite" },
    { label: "Skip", value: "skip" },
    { label: "Update Missing Field", value: "update_missing" }
];

const ACTION_OPTIONS = [
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Email", value: "email" },
    { label: "Mobile Phone", value: "phone_number" },
    { label: "Home Phone", value: "sms" },
    { label: "Street Address", value: "address" }
];

export const MapColumnsTableItem = ({ className, item, formik }) => {
    const { file_header, preview, field, on_conflict } = item;

    const onConflictSelect = (option) => {
        formik.setFieldValue(
            "mapped_fields",
            formik.values.mapped_fields.map((field) => {
                if (field.file_header === item.file_header) {
                    return {
                        ...field,
                        on_conflict: option.value
                    };
                }
                return field;
            })
        );
    };

    const onMapSelect = (option) => {
        formik.setFieldValue(
            "mapped_fields",
            formik.values.mapped_fields.map((field) => {
                if (option.value === field.field) {
                    return {
                        ...field,
                        field: null
                    };
                }
                if (field.file_header === item.file_header) {
                    return {
                        ...field,
                        field: option.value
                    };
                }
                return field;
            })
        );
    };

    return (
        <div className={classNames(styles["root"], className)}>
            <Text className={styles["file-header"]}>{file_header}</Text>
            <Text className={styles["preview"]}>{preview}</Text>
            <Dropdown
                targetClassName={classNames(DropdownTargetClasses.FORM_SELECT_TABLE, styles["dropdown-target"])}
                usePortal={false}
                position={Position.BOTTOM}
                selectedValue={
                    field
                        ? { label: LABEL_VALUE_MATCH[field], value: field, onClick: () => null }
                        : { label: "Not Mapped", value: null, isNotSelected: true, onClick: () => null }
                }
                staticOptions={ACTION_OPTIONS}
                matchTargetWidth
                onStaticOptionClick={onMapSelect}
                ArrowIconComponent
            />
            <Dropdown
                usePortal={false}
                targetClassName={classNames(DropdownTargetClasses.FORM_SELECT_TABLE, styles["dropdown-target"])}
                position={Position.BOTTOM}
                staticOptions={CONFLICT_OPTIONS}
                selectedValue={{ label: LABEl_VALUE_ON_CONFLICT_MATCH[on_conflict], value: on_conflict }}
                matchTargetWidth
                onStaticOptionClick={onConflictSelect}
                ArrowIconComponent
            />
        </div>
    );
};
