import { AxiosResponse } from "axios";
import { axiosInstance } from "src/actions";
import { ArrayChannel } from "src/store";

export type SmartLink = {
    id: string;
    description: string;
    enabled: boolean;
    keyword: string;
    name: string;
    qr_code_url: string;
    thumbnail_color: string;
    public_url: string;
};

export const getSmartLinks = async (): Promise<AxiosResponse<Array<SmartLink>>> => {
    return await axiosInstance.get("/smart_links");
};

export const updateSmartLink = async (smartLinkId: string, params: any): Promise<AxiosResponse<SmartLink>> => {
    return await axiosInstance.patch(`/smart_links/${smartLinkId}`, params);
};

export const createSmartLink = async (body): Promise<AxiosResponse<SmartLink>> => {
    return await axiosInstance.post("/smart_links/create", body);
};

export const deleteSmartLink = async (smartLinkId: string) => {
    return await axiosInstance.delete(`/smart_links/${smartLinkId}`);
};

export const getSmartLinksPublicUrl = async (id: string) => {
    return await axiosInstance.get("/smart_links/public_url", { params: { id } });
};

export const getSmartLink = async (id: string) => {
    return axiosInstance.get(`/smart_links/${id}`);
};
