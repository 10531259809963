import styles from "./Footer.module.scss";

export const Footer = ({ leftElement, rightElement }) => {
    return (
        <div className={styles["footer"]}>
            {leftElement || <div className={styles["fake-block"]} />}
            {rightElement || <div className={styles["fake-block"]} />}
        </div>
    );
};
