import { Avatar } from "../avatar/avatar";
import styles from "./DropdownAdminsSelectedItems.module.scss";

export const DropdownAdminsSelectedSingleItem = ({ admin, size }) => {
    if (admin?.id) {
        return (
            <div className={styles["single-select-value-wrapper"]}>
                <Avatar
                    avatarUrl={admin.avatar || admin.profile_pic?.url}
                    user={admin}
                    size={size || 24}
                    className={styles[`avatar-wrapper_list-item`]}
                />
                <span>{`${admin.first_name} ${admin.last_name}`}</span>
            </div>
        );
    }
    return null;
};
