export const COLEECTIONS_COLORS = [
    "#747D8C",
    "#6C5CE7",
    "#0652DD",
    "#1E90FF",
    "#0ABDE3",
    "#16A085",
    "#D63031",
    "#EE5253",
    "#E67E22",
    "#FFA502"
];

export const QR_CODE_COLORS = ["#FFFFFF", "#000000", "#C553B9", "#3C6EED", "#D24E4E", "#F8E53F", "#EEA619", "#63E6CE"];

export const QR_CODE_BACKGROUND_COLORS = ["#000000", "#FFFFFF", "transparent"];
