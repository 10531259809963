/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type */
export const LoginState = {
    EnteringUsername: "enteringusername" as const,
    EnteringPassword: "enteringpassword" as const,
    EnteringForgotPassword: "enteringforgotpassword" as const,
    EnteringOneTimePassword: "enteringonetimepassword" as const,
    SelectingOTPChannel: "selectingotpchannel" as const,
    ThankYou: "thankyou" as const,
    TwoWayAuth: "twoauthauth" as "twowayauth"
};

export type LoginState = typeof LoginState[keyof typeof LoginState];
