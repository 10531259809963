import { ConversationMessage } from "src/requests/conversationsRequests";
import { SocketSender } from "src/store/conversationMessages";

export const SET_CONVERSATION_MESSAGES = "get-conversation-messages";
export const UPDATE_SEND_MESSAGE_PROCESSING_STATUS = "update-send-message-processing-status";
export const ADD_MESSAGE = "add-message";
export const SET_HAS_MORE_MESSAGES = "set-has-more-messages";
export const UPDATE_CONVERSATION_MESSAGE_AFTER_SENT = "update-last-seen-message-after-sent";
export const SET_TYPINGS = "set-typings";
export const SET_SELECTED_CONVERSATION_IS_BOTTOM = "set-selected-conversation-is-bottom";
export const SET_SHOULD_SHOW_JUMP_TO_NEW_MESSAGES = "set-should-show-jump-to-new-messages";
export const UPDATE_MESSAGE = "update-message";
export const SET_DEFAULT_STATE = "set-default-state";
export const CLEAR_MESSAGES = "clear-messages";
export const SET_SHOULD_JUMP_TO_BOTTOM_OF_CHAT_LIST = "set-should-jump-to-bottom";
export const SET_TYPING_SHOULD_DISAPPEAR = "set-typing-should-disappear";
export const REMOVE_TYPING = "remove-typing";

export type RemoveTyping = {
    type: typeof REMOVE_TYPING;
    payload: {
        conversationId: string;
        data: Array<SocketSender>;
        typingId: string;
    };
};

type SetShouldJumpToBottomOfChatlist = {
    type: typeof SET_SHOULD_JUMP_TO_BOTTOM_OF_CHAT_LIST;
    payload: {
        conversationId: string;
        value: boolean;
    };
};

type ClearMessages = {
    type: typeof CLEAR_MESSAGES;
    payload: {
        conversationId: string;
    };
};

type GetConversationsMessages = {
    type: typeof SET_CONVERSATION_MESSAGES;
    payload: {
        conversationMessages: {
            [key: string]: {
                messages: {
                    [key: string]: ConversationMessage;
                };
                messagesSequnce: Array<string>;
            };
        };
        selectedConversationId: string;
    };
};

type UpdateSendMessageProcessingStatus = {
    type: typeof UPDATE_SEND_MESSAGE_PROCESSING_STATUS;
    payload: boolean;
};

type AddMessage = {
    type: typeof ADD_MESSAGE;
    payload: { messageData: ConversationMessage; conversationId: string };
};

type SetHasMoreMessages = {
    type: typeof SET_HAS_MORE_MESSAGES;
    payload: { value: boolean; chatId: string; type: "hasMoreLater" | "hasMoreOlder" };
};

type UpdateConversationMessageAfterSent = {
    type: typeof UPDATE_CONVERSATION_MESSAGE_AFTER_SENT;
    payload: { messageData: ConversationMessage; chatId: string };
};

export type SetSelectedConversationIsBottom = {
    type: typeof SET_SELECTED_CONVERSATION_IS_BOTTOM;
    payload: boolean;
};

export type SetShouldShowJumpToNewMessages = {
    type: typeof SET_SHOULD_SHOW_JUMP_TO_NEW_MESSAGES;
    payload: { value: boolean; conversationId: string };
};

type SetDefaultState = {
    type: typeof SET_DEFAULT_STATE;
    // viewType: ViewEnum;
};

type UpdateMessage = {
    type: typeof UPDATE_MESSAGE;
    payload: { messageData: ConversationMessage; conversationId: string };
};

export type ConversationMessagesActionTypes =
    | GetConversationsMessages
    | UpdateSendMessageProcessingStatus
    | SetHasMoreMessages
    | UpdateConversationMessageAfterSent
    | SetDefaultState
    | SetSelectedConversationIsBottom
    | SetShouldShowJumpToNewMessages
    | AddMessage
    | UpdateMessage
    | ClearMessages
    | SetShouldJumpToBottomOfChatlist
    | RemoveTyping;
