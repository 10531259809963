// tslint:disable jsx-no-lambda
import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { globalActions } from "src/actions";

import {
    BreadCrumbs,
    StyledDuoButtons,
    ImagePlusText,
    StyledDuoButtonsTypes,
    ImageUpload,
    SettingsTitle,
    StyledButton,
    StyledButtonTypes
} from "src/components";
import { ReactComponent as PersonBlueIcon } from "../../../../mpd-library/icon/assets/person-blue.svg"
import { ReactComponent as GroupBlueSmallIcon } from "../../../../mpd-library/icon/assets/group-blue-small.svg"
import { ReactComponent as MarkerBlueSmallIcon } from "../../../../mpd-library/icon/assets/marker-blue-small.svg"
import { ReactComponent as LocationTargetIcon } from "../../../../mpd-library/icon/assets/location-target.svg"
import { ReactComponent as PersonLockBlueSmallIcon } from "../../../../mpd-library/icon/assets/person-lock-blue-small.svg"
import { ReactComponent as EmailBlueSmallIcon } from "../../../../mpd-library/icon/assets/email-blue-small.svg"
import { ReactComponent as PhoneBlueSmallIcon } from "../../../../mpd-library/icon/assets/phone-blue-small.svg"

import { formatPhoneNumber } from "src/actions";

import { Route, Switch } from "react-router-dom";

import { Classes, MPDScroll } from "src/mpd-library";

import { IDetailView } from "../../../";

import { AccountDetailsSettingsEdit } from "./account-details-settings-edit/account-details-settings-edit";
import { History } from "history";

import "./styles.scss";
import { AppState, AccountSettingsState } from "src/store";
import { updateAccountSettingsDetails } from "src/actions/account-settings/account-settings-api";

interface IAccountDetailsSettingsProps extends IDetailView {
    accountSettings: AccountSettingsState;
    onCancelChanges: (pathname: string, history: History) => void;
    onUpdateAccountSettingsDetails: (updatedObj: AccountSettingsState, pathname: string, history: History) => void;
}

interface IMyInfoSettingsState {
    accountSettings: AccountSettingsState;
}

class AccountDetailsSettings extends React.Component<IAccountDetailsSettingsProps, IMyInfoSettingsState> {
    constructor(props: IAccountDetailsSettingsProps) {
        super(props);

        const { accountSettings } = this.props;

        this.state = {
            accountSettings: JSON.parse(JSON.stringify(accountSettings))
        };
    }

    public componentDidMount() {
        this.updateToolBar(this.props);
    }

    public componentWillReceiveProps(nextProps: IAccountDetailsSettingsProps) {
        if (nextProps.accountSettings !== this.props.accountSettings) {
            this.setState({ accountSettings: nextProps.accountSettings });
            this.updateToolBar(nextProps);
        }
        if (nextProps.routeProps !== this.props.routeProps) {
            this.updateToolBar(nextProps);
        }
    }

    public render() {
        const { currentPlan, routeProps } = this.props;
        const { accountSettings } = this.state;
        const classes = "account-details";
        const id = accountSettings.organization.details.id;

        return (
            <React.Fragment>
                <MPDScroll className={classes + "-scroll-container"} yDirection={true}>
                    <Switch location={routeProps.history.location}>
                        <Route
                            exact={true}
                            path={"/account-settings/account-details"}
                            render={() => this.renderAccountDetails(classes)}
                        />
                        <Route
                            path={`/account-settings/account-details/id-${id}`}
                            render={() => (
                                <AccountDetailsSettingsEdit
                                    currentPlan={currentPlan}
                                    details={accountSettings.organization.details}
                                    onChangeInput={this.onChangeDetailsInput}
                                    onUploadImage={this.onUploadDetailsImage}
                                    onSave={this.onUpdateAccountSettingsDetails}
                                />
                            )}
                        />
                        <Route exact={true} path={"/account-settings/sub-accounts"} render={() => null} />
                    </Switch>
                </MPDScroll>
            </React.Fragment>
        );
    }

    private renderAccountDetails = (classes: string) => {
        const { currentPlan, accountSettings } = this.props;
        const details = accountSettings.organization.details;
        const {
            accountName,
            center,
            teamAgentFirstName,
            teamAgentLastName,
            teamAgentEmail,
            teamAgentPhone,
            timeZone,
            totalEmployees,
            populationServed,
            address,
            logo
        } = details;

        return (
            <div className={classNames(Classes.CONTAINER_PADDING, Classes.ROW, Classes.RESPONSIVE)}>
                <div className={Classes.COLUMN}>
                    <div className={classes + "-details-info"}>
                        <SettingsTitle title="Basic Info" />
                        <div
                            className={classNames(
                                classes + "-details-info-item-container",
                                Classes.ASSIGN_BLOCK_PADDING
                            )}
                        >
                            <div className={classes + "-logo-wrapper"}>
                                <ImagePlusText element={<ImageUpload src={logo?.url || ""} />} text={accountName} />
                            </div>
                            <ImagePlusText IconComponent={PersonBlueIcon} text={totalEmployees || undefined} />
                            <ImagePlusText IconComponent={GroupBlueSmallIcon} text={populationServed || undefined} />
                        </div>
                    </div>
                    <div className={classes + "-details-info"}>
                        <SettingsTitle title={`ADDRESS & MAP CENTER`} />
                        <div
                            className={classNames(
                                classes + "-details-info-item-container",
                                Classes.ASSIGN_BLOCK_PADDING
                            )}
                        >
                            <ImagePlusText
                                className={classes + "-map-address-info-item"}
                                IconComponent={MarkerBlueSmallIcon}
                                text={address}
                            />
                            <ImagePlusText
                                IconComponent={LocationTargetIcon}
                                text={(center?.lat || "") + ", " + (center?.lng || "")}
                            />
                        </div>
                    </div>
                </div>
                <div className={Classes.COLUMN}>
                    <div className={classes + "-details-info"}>
                        <SettingsTitle title="team agent" />
                        <div
                            className={classNames(
                                classes + "-details-info-item-container",
                                Classes.ASSIGN_BLOCK_PADDING
                            )}
                        >
                            <ImagePlusText
                                IconComponent={PersonLockBlueSmallIcon}
                                text={teamAgentFirstName + " " + teamAgentLastName}
                            />
                            <ImagePlusText IconComponent={EmailBlueSmallIcon} text={teamAgentEmail} />
                            <ImagePlusText IconComponent={PhoneBlueSmallIcon} text={formatPhoneNumber(teamAgentPhone)} />
                        </div>
                    </div>
                    {currentPlan !== "essential" && (
                        <div className={classes + "-details-info"}>
                            <SettingsTitle title="localization" />
                            <div className={Classes.ASSIGN_BLOCK_MARGIN}>
                                <ImagePlusText icon="globeBlueSmall" text={timeZone} />
                                <ImagePlusText icon="clockBlueSmall" text="12 hour clock" />
                                <ImagePlusText icon="personSpeakingBlueSmall" text="English" />
                            </div>
                        </div>
                    )}
                    {currentPlan !== "essential" && (
                        <div className={classes + "-details-info"}>
                            <SettingsTitle title="subdomain" />
                            <div
                                className={classNames(
                                    classes + "-details-info-item-container",
                                    Classes.ASSIGN_BLOCK_PADDING
                                )}
                            >
                                <ImagePlusText icon="ballBlueSmall" text="mycityorg.mpdurl.com" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    private onUploadDetailsImage = (files: any) => {
        const { accountSettings } = this.state;
        const reader = new FileReader();
        const file = files[0];
        reader.readAsDataURL(file);
        if (accountSettings.organization.details.logo.file) {
            accountSettings.organization.details.logo.file = file;
            reader.onloadend = () => {
                accountSettings.organization.details.logo.src = reader.result;
                this.setState({ accountSettings });
            };
        }
    };

    private onChangeDetailsInput = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const { accountSettings } = this.state;
        const value = event.currentTarget.value;

        if (type === "lat" || type === "lng") {
            if (accountSettings.organization.details.center) {
                accountSettings.organization.details.center[type] = Number(value);
            } else {
                accountSettings.organization.details.center = { [type]: Number(value) };
            }
        } else if (type === "totalEmployees" || type === "populationServed") {
            accountSettings.organization.details[type] = Number(value);
        } else {
            accountSettings.organization.details[type] = value;
        }

        this.setState({ accountSettings });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    };

    private updateToolBar = (props: IAccountDetailsSettingsProps) => {
        const { routeProps } = props;
        const pathname = routeProps.history.location.pathname;
        const pathArr = pathname.split("/");

        const topBarProps = !pathArr[pathArr.length - 1].includes("id")
            ? {
                  leftElement: (
                      <BreadCrumbs
                          breadCrumbsList={[{ text: "Account Details" }]}
                          history={routeProps.history}
                          toggleButtonProps={{
                              onToggle: this.props.onToggleRightPanel,
                              onClose: this.props.onCloseRightPanel
                          }}
                      />
                  ),
                  rightElement: <StyledButton type={StyledButtonTypes.primary} text="Edit" onClick={this.onEdit} />
              }
            : {
                  leftElement: (
                      <BreadCrumbs
                          breadCrumbsList={[{ text: "Account Details" }]}
                          history={routeProps.history}
                          toggleButtonProps={{
                              onToggle: this.props.onToggleRightPanel,
                              onClose: this.props.onCloseRightPanel
                          }}
                      />
                  ),
                  rightElement: !updateAccountSettingsDetails && (
                      <StyledDuoButtons
                          firstButtonClick={this.onCancelChanges}
                          secondButtonClick={this.onUpdateAccountSettingsDetails}
                          firstButtonText="Cancel"
                          secondButtonText="Save"
                          type={StyledDuoButtonsTypes.primary}
                      />
                  )
              };
        this.props.onUpdateToolBar(topBarProps);
    };

    private onCancelChanges = () => {
        const { routeProps } = this.props;
        const { history } = routeProps;
        const pathname = `/account-settings/account-details`;
        this.props.onCancelChanges(pathname, history);
    };

    private onUpdateAccountSettingsDetails = async (values) => {
        const { accountSettings } = this.props;
        console.log(this.state.accountSettings)
        const id = accountSettings.organization.details.id;
       await this.props.onUpdateAccountSettingsDetails(
            {
                ...this.state.accountSettings,
                organization: {
                    ...this.state.accountSettings?.organization,
                    details: {
                        ...this.state.accountSettings?.organization?.details,
                        accountName: values?.accountName,
                        city: values?.city,
                        country: values?.country,
                        lat: values?.center?.lat,
                        lng: values?.center?.lng,
                        populationServed: values?.populationServed,
                        postalCode:values?.postalCode,
                        primaryAddress: values?.primaryAddress,
                        state: values?.state,
                        teamAgentFirstName: values?.teamAgentFirstName,
                        teamAgentLastName: values?.teamAgentLastName,
                        totalEmployees:values?.totalEmployees,
                    }
                }
            },
            `/account-settings/account-details/id-${id}`,
            this.props.routeProps.history
        );
    };

    private onEdit = () => {
        const { routeProps, accountSettings } = this.props;
        const id = accountSettings.organization.details.id;
        routeProps.history.push(`/account-settings/account-details/id-${id}`);
    };
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const currentPlan = accountSettings.organization.subscription.overview.currentPlan;

    return {
        accountSettings,
        currentPlan
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onUpdateAccountSettingsDetails: updateAccountSettingsDetails,
            onCancelChanges: globalActions.onCancelChanges
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsSettings);
