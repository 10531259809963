import classNames from "classnames";
import React from "react";
import * as myClasses from "../";

import { ITextAreaProps, Text, TextArea } from "@blueprintjs/core";

import "./styles.scss";

export interface IMPDFloatingTextAreaProps extends ITextAreaProps {
    maxCounter?: number;
    focused?: boolean;
    additionLabel?: string;
    isMaxLengthRequire?: boolean;
}

interface IMPDFloatingTextAreaState {
    counter?: number;
}

export class MPDFloatingTextArea extends React.Component<IMPDFloatingTextAreaProps, IMPDFloatingTextAreaState> {
    public static defaultProps: IMPDFloatingTextAreaProps = {
        placeholder: "Filler Placeholder"
    };

    private textarea: React.RefObject<any>;

    constructor(props: IMPDFloatingTextAreaProps) {
        super(props);
        this.textarea = React.createRef();
        this.setFocus = this.setFocus.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            counter: undefined
        };
    }

    public componentDidMount() {
        const { value } = this.props;

        if (value) {
            this.setState({ counter: value.toString().length });
        }
    }

    public componentDidUpdate(prevProps: IMPDFloatingTextAreaProps) {
        const { value } = this.props;

        if (value !== prevProps.value) {
            if (value) {
                this.setState({ counter: value.toString().length });
            }
        }
    }

    public UNSAFE_componentWillReceiveProps({ focused }: IMPDFloatingTextAreaProps) {
        if (focused !== undefined && focused !== this.props.focused) {
            this.setFocus(focused);
        }
    }

    public render() {
        const { className, placeholder, maxCounter, isMaxLengthRequire = true, disabled, ...newProps } = this.props;

        const { counter } = this.state;
        const classes = classNames(myClasses.FLOATING_TEXTAREA_CONTAINER, className, disabled && 'mpd-disabled');

        const properties = isMaxLengthRequire ? { maxLength: maxCounter } : null;

        return (
            <div className={classes} tabIndex={-1}>
                <TextArea
                    onChange={this.onChange}
                    required={true}
                    ref={this.textarea}
                    // maxLength={maxCounter}
                    placeholder={placeholder}
                    {...newProps}
                    {...properties}
                    disabled={disabled}
                />
                <span className={myClasses.FLOATING_TEXTAREA_LABEL}>{placeholder}</span>
                {maxCounter && (
                    <Text className={myClasses.FLOATING_TEXTAREA_COUNTER}>
                        {counter}/{maxCounter}
                    </Text>
                )}
            </div>
        );
    }

    private onChange(event: any) {
        const { onChange } = this.props;
        const counter = event.currentTarget.value.length;
        this.setState({ counter });
        if (onChange) {
            onChange(event);
        }
    }

    private setFocus(state: boolean) {
        if (this.textarea.current) {
            if (state) {
                this.textarea.current.focus();
            } else {
                this.textarea.current.blur();
            }
            return state;
        }
        return;
    }
}
