import { IncidentManagementState, IncidentManagementIncidentTab } from "src/store";

export const INCIDENT_MANAGEMENT_TOGGLE_RIGHTPANEL = "incident-management-toggle-rightpanel";
export const INCIDENT_MANAGEMENT_TOGGLE_CHAT = "incident-management-toggle-chat";
export const INCIDENT_MANAGEMENT_PREVENT_TOGGLE = "incident-management-prevent-toggle";
export const INCIDENT_MANAGEMENT_UPDATE = "incident-management-update";
export const INCIDENT_MANAGEMENT_UPDATE_OPENED_ROUTE = "incident-management-update-opened-route";
export const INCIDENT_MANAGEMENT_UPDATE_TABS = "incident-management-update-tabs";

interface IncidentManagementToggleRightpanelAction {
    type: typeof INCIDENT_MANAGEMENT_TOGGLE_RIGHTPANEL;
    payload: boolean;
}

interface IncidentManagementToggleChatAction {
    type: typeof INCIDENT_MANAGEMENT_TOGGLE_CHAT;
    payload: boolean;
}

interface IncidentManagementPreventToggleAction {
    type: typeof INCIDENT_MANAGEMENT_PREVENT_TOGGLE;
    payload: boolean;
}

interface IncidentManagementUpdateAction {
    type: typeof INCIDENT_MANAGEMENT_UPDATE;
    payload: IncidentManagementState;
}

interface IncidentManagementUpdateOpenedRouteAction {
    type: typeof INCIDENT_MANAGEMENT_UPDATE_OPENED_ROUTE;
    payload: IncidentManagementState;
}

interface IncidentManagementUpdateTabsAction {
    type: typeof INCIDENT_MANAGEMENT_UPDATE_TABS;
    payload: Array<IncidentManagementIncidentTab>;
}

export type IncidentManagementActionTypes =
    | IncidentManagementToggleRightpanelAction
    | IncidentManagementToggleChatAction
    | IncidentManagementPreventToggleAction
    | IncidentManagementUpdateAction
    | IncidentManagementUpdateOpenedRouteAction
    | IncidentManagementUpdateTabsAction;
