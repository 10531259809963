import { IToastType, ToastTypes } from "src/components";
import {
    getUserDataRequest,
    patchUserDataRequest,
    postPortalResetRequest,
    patchUserEmailRequest,
    UserEmailChangeData,
    patchUserPhoneRequest,
    UserPhoneChangeData,
    getMyActivityRequest,
    UserData
} from "../../requests";
import { navigate, updateChangesMade, updateToast, uploadFile } from "../global/global-api";
import {
    USER_SETTINGS_TOGGLE_RIGHTPANEL,
    USER_SETTINGS_MY_INFO_UPDATE,
    USER_SETTINGS_EMAIL_PASS_OTP_UPDATE,
    USER_SETTINGS_PASSWORD_UPDATE,
    USER_SETTINGS_PRIVILEGES_UPDATE,
    USER_SETTINGS_NOTIFICATIONS_UPDATE,
    USER_SETTINGS_SCHEDULE_UPDATE,
    USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE,
    USER_SETTINGS_SAVED_EMAIL_INTERMEDIATE_VALUE_UPDATE,
    USER_SETTINGS_ACTYVITY_UPDATE,
    USER_SETTINGS_ACTYVITY_LOADING_STATUS_UPDATE
} from "./types";
import { Dispatch } from "redux";
import {
    AppState,
    UserSettingsMyInfo,
    UserSettingsNotificationState,
    UserSettingsScheduleState,
    OTPAlertTypes,
    UserSettingsEmailPassOTPState
} from "../../store";
import { History } from "history";
import { getNotificationsSettings } from "../notifications/notifications-api";
import { updateNotificationsCounter } from "../notifications/notifications";
import { setConversationsCounter } from "../conversations/conversations";

interface UserSettingsSaveChanges {
    myInfo: UserSettingsMyInfo;
    pathname?: string;
    history?: History;
}
export const saveMyInfoChanges = (ref: UserSettingsSaveChanges) => async (dispatch: Dispatch<AppState>) => {
    const { myInfo, pathname, history } = ref;

    dispatch(updateUserSettings({ myInfo, pathname, history }));
};

export const toggleProfileStatus = () => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const { myInfo } = getState().userSettings;
    myInfo.status = !myInfo.status;
    dispatch(updateUserSettings({ myInfo }));
};

export const toggleRightPanel = () => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const userSettings = getState().userSettings;
    const { rightPanelExpanded } = userSettings;
    dispatch({
        type: USER_SETTINGS_TOGGLE_RIGHTPANEL,
        payload: !rightPanelExpanded
    });
};

export const updateUserSettings =
    (ref: { myInfo: UserSettingsMyInfo; pathname?: string; history?: History }) =>
    async (dispatch: Dispatch<AppState>) => {
        const { myInfo, pathname, history } = ref;

        if (myInfo.logo?.file) {
            const file = myInfo.logo.file;
            const { type } = file;

            const uploadedFileData = await uploadFile(file);
            const { url } = uploadedFileData;

            const logo = myInfo.logo;
            if (logo) {
                logo.url = url;
                logo.type = type;
                logo.updatedSrc = url;
            }
        }

        const newUser = formatUserSettingsToServer(myInfo);

        const res = await patchUserDataRequest({ user: newUser });
        const user = res.data.user;
        dispatch(formatServerToUserSettings(user));
        const toast: IToastType = {
            value: "Success! Your changes were saved",
            type: ToastTypes.saved
        };
        dispatch(updateToast({ toast }));
        const changesMade = false;
        dispatch(updateChangesMade({ changesMade }));

        if (pathname && history) {
            dispatch(navigate({ pathname, history }));
        }
    };

export const formatUserSettingsToServer = (myInfo: UserSettingsMyInfo): UserData => {
    const { firstName, lastName, logo } = myInfo;
    const url = logo?.url || logo?.updatedSrc || "";

    const profilePic =
        logo && (url as string)?.length > 0
            ? {
                  type: logo.type,
                  url
              }
            : null;

    return {
        first_name: firstName,
        last_name: lastName,
        ...(profilePic ? { profile_pic: profilePic as any } : {})
    };
};

interface UploadProfilePhotoProps {
    file: any;
}
export const uploadProfilePhoto =
    (ref: UploadProfilePhotoProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const { myInfo } = getState().userSettings;
        const { file } = ref;
        const { type } = file;

        const uploadedFileData = await uploadFile(file);
        const { url } = uploadedFileData;

        const logo = myInfo.logo;
        if (logo) {
            logo.url = url;
            logo.type = type;
        }
        dispatch({
            type: USER_SETTINGS_MY_INFO_UPDATE,
            payload: myInfo
        });
        dispatch(updateChangesMade({ changesMade: true }));
    };

export const getUserSettings = () => async (dispatch: Dispatch<any>) => {
    try {
        const res = await getUserDataRequest();
        const user = res.data.user;
        dispatch(formatServerToUserSettings(user));
        dispatch(getNotificationsSettings());
        dispatch(updateNotificationsCounter(res.data.counts.unread_notifications));
        dispatch(setConversationsCounter(res.data.counts.conversations));
        return res.data;
    } catch (err) {
        return err;
    }
};

export const formatServerToUserSettings =
    (user: any) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const userSettings = getState().userSettings;
        const {
            email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            profile_pic,
            privileges,
            uuid
        } = user;

        const data = {
            email,
            firstName,
            lastName,
            phoneNumber,
            logo: {
                updatedSrc: "",
                type: "",
                src: ""
            },
            privileges,
            uuid
        };

        if (profile_pic) {
            data.logo.updatedSrc = profile_pic.url;
            data.logo.type = profile_pic.type;
        }

        const myInfo = { ...userSettings.myInfo, ...data };
        dispatch({
            type: USER_SETTINGS_MY_INFO_UPDATE,
            payload: myInfo
        });

        dispatch({
            type: USER_SETTINGS_PRIVILEGES_UPDATE,
            payload: privileges
        });
    };

interface UpdataUserPasswordProps {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}
export const updateUserPassword =
    (ref: UpdataUserPasswordProps) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const {
            userSettings: { password }
        } = getState();

        const { currentPassword, newPassword, confirmNewPassword } = ref;
        const errors = password.errors;
        const error = validateForm({
            currentPassword,
            newPassword,
            confirmNewPassword,
            errors
        });

        if (!error) {
            const updatedObj = {
                current_password: currentPassword,
                password: newPassword,
                password_confirmation: confirmNewPassword
            };

            await postPortalResetRequest(updatedObj);

            password.currentPassword = "";
            password.newPassword = "";
            password.confirmNewPassword = "";
            password.errors = {};
            const toast: IToastType = {
                value: "Success! Your changes were saved",
                type: ToastTypes.saved
            };
            dispatch(updateToast({ toast }));
            const changesMade = false;
            dispatch(updateChangesMade({ changesMade }));
            dispatch({
                type: USER_SETTINGS_PASSWORD_UPDATE,
                payload: password
            });
        } else {
            password.currentPassword = currentPassword;
            password.newPassword = newPassword;
            password.confirmNewPassword = confirmNewPassword;
            dispatch({
                type: USER_SETTINGS_PASSWORD_UPDATE,
                payload: password
            });
        }
    };

export const resetUserPasswordErrors = () => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const {
        userSettings: { password }
    } = getState();

    dispatch({
        type: USER_SETTINGS_PASSWORD_UPDATE,
        payload: { ...password, errors: {} }
    });
};

interface ValidateFormProps {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    errors: any;
}
const validateForm = (ref: ValidateFormProps) => {
    const { currentPassword, newPassword, confirmNewPassword, errors } = ref;
    let error = false;

    if (currentPassword.length === 0) {
        errors.currentPassword = `Please enter a password`;
        error = true;
    } else {
        errors.password = ``;
    }

    if (newPassword.length === 0) {
        errors.newPassword = `Please enter a password`;
        error = true;
    } else {
        errors.password = ``;
    }

    if (confirmNewPassword.length === 0) {
        errors.confirmNewPassword = `Please enter confirm your password`;
        error = true;
    } else {
        errors.confirmPassword = ``;
    }

    if (newPassword !== confirmNewPassword) {
        errors.newPassword = `Passwords don't match`;
        errors.confirmNewPassword = `Passwords don't match`;
        error = true;
    }

    return error;
};

export const changeEmailPassOTP =
    (emailPassOtp: UserSettingsEmailPassOTPState) => async (dispatch: Dispatch<AppState>) => {
        dispatch({
            type: USER_SETTINGS_EMAIL_PASS_OTP_UPDATE,
            payload: emailPassOtp
        });
    };

export const sendForEmailOTP =
    (body: UserEmailChangeData, nextOTPAlertType: OTPAlertTypes) =>
    async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const emailPassOTP = getState().userSettings.emailPassOTP;
        const otp = await patchUserEmailRequest(body);

        dispatch(changeEmailPassOTP({ ...emailPassOTP, otpToken: otp.data.otp_token }));
        dispatch(changeOTPAlertType(nextOTPAlertType));
    };

export const confirmOTPForEmailChange =
    (
        body: UserEmailChangeData,
        myInfo: UserSettingsMyInfo,
        nextAlertType?: OTPAlertTypes,
        password?: string,
        pathname?: string,
        history?: History
    ) =>
    async (dispatch: Dispatch<AppState>) => {
        const res = await patchUserEmailRequest(body);

        if (res.data.status === "ok") {
            if (nextAlertType && myInfo.phoneNumber) {
                dispatch(changeEmailPassOTP({ otpAlertOpen: true, otpAlertType: nextAlertType, otpToken: undefined }));
                dispatch(sendForPhoneOTP({ phone_number: myInfo.phoneNumber, password }, "password"));
                dispatch(updateSavedEmailIntermediateValue(body.email));

                const toast: IToastType = {
                    value: "Email changes were saved",
                    type: ToastTypes.saved
                };
                dispatch(updateToast({ toast }));

                return;
            }

            dispatch(changeEmailPassOTP({ otpAlertOpen: false, otpAlertType: "password", otpToken: undefined }));
            dispatch(updateUserSettings({ myInfo, pathname, history }));
        }
    };

export const sendForPhoneOTP =
    (body: UserPhoneChangeData, nextOTPAlertType: OTPAlertTypes) =>
    async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const emailPassOTP = getState().userSettings.emailPassOTP;
        const otp = await patchUserPhoneRequest(body);

        dispatch(changeEmailPassOTP({ ...emailPassOTP, otpToken: otp.data.otp_token }));
        dispatch(changeOTPAlertType(nextOTPAlertType));
    };

export const confirmOTPForPhoneChange =
    (body: UserPhoneChangeData, myInfo: UserSettingsMyInfo, pathname?: string, history?: History) =>
    async (dispatch: Dispatch<AppState>) => {
        const res = await patchUserPhoneRequest(body);

        if (res.data.status === "ok") {
            dispatch(changeEmailPassOTP({ otpAlertOpen: false, otpAlertType: "password", otpToken: undefined }));
            dispatch(updateUserSettings({ myInfo, pathname, history }));
        }
    };

const changeOTPAlertType =
    (nextOTPAlertType: OTPAlertTypes) => async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const emailPassOTP = getState().userSettings.emailPassOTP;
        dispatch(changeEmailPassOTP({ ...emailPassOTP, otpAlertType: nextOTPAlertType }));
    };

export const updateNotifications =
    (notifications: UserSettingsNotificationState, pathname?: string, history?: History) =>
    async (dispatch: Dispatch<AppState>) => {
        dispatch({
            type: USER_SETTINGS_NOTIFICATIONS_UPDATE,
            payload: notifications
        });

        if (pathname && history) {
            dispatch(navigate({ pathname, history }));
        }
    };

export const updateSchedule =
    (schedule: UserSettingsScheduleState, pathname?: string, history?: History) =>
    async (dispatch: Dispatch<AppState>) => {
        dispatch({
            type: USER_SETTINGS_SCHEDULE_UPDATE,
            payload: schedule
        });

        if (pathname && history) {
            dispatch(navigate({ pathname, history }));
        }
    };

export const updateEmailPassChangeError = (error?: string) => (dispatch: Dispatch<AppState>) => {
    dispatch({
        type: USER_SETTINGS_EMAIL_PASS_CHANGE_ERROR_UPDATE,
        payload: error
    });
};

export const updateSavedEmailIntermediateValue = (email?: string) => (dispatch: Dispatch<AppState>) => {
    dispatch({
        type: USER_SETTINGS_SAVED_EMAIL_INTERMEDIATE_VALUE_UPDATE,
        payload: email
    });
};

