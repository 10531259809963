import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

import { MPDButton } from "src/mpd-library";

import { IProps, Text } from "@blueprintjs/core";

import "./styles.scss";

interface INotificationsItemProps extends IProps {
    name?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
    hoverName?: string;
    selectName?: string;
    numOfNotifications?: number;
    title?: string;
    onClick?: (event: React.SyntheticEvent) => void;
}

export const NotificationsItem: React.FunctionComponent<INotificationsItemProps> = (props) => {
    const { className, onClick, numOfNotifications, name, IconComponent, title } = props;

    const classes = "notifications-item";

    return (
        <div className={classNames(classes, className)} onClick={onClick}>
            <MPDButton className={classes + "-mpd-button"} IconComponent={IconComponent} text={title} />
            <Text className={classes + "-num-of-notifications"}>{numOfNotifications}</Text>
        </div>
    );
};
