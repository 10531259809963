import { DatePicker, IDateTimePickerProps } from "@blueprintjs/datetime";
import classNames from "classnames";
import styles from "./StyledDatePicker.module.scss";

interface StyledDatePicker extends IDateTimePickerProps {
    className: string;
}

export const StyledDatePicker = ({ className, ...props }: IDateTimePickerProps) => {
    return <DatePicker {...props} className={classNames(styles["datepicker"], className)} />;
};
