import { getConfig } from "src/common";
import { axiosInstance } from "src/actions";

export async function postUsersOnboardingSignupRequest(body: any) {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/users/signup`, body);
}

export async function postUsersOnboardingPresignedUrlRequest(body: any) {
    const config = getConfig();
    return await axiosInstance.post(`${config.API_URL}/users/signup/presigned_url`, body);
}
