import classNames from "classnames";
import React from "react";

import { IProps } from "@blueprintjs/core";

import { MPDScroll } from "../";

export interface IMPDNavBarGroupProps extends IProps {
    xDirection?: boolean;
    yDirection?: boolean;
}

export class MPDNavBarGroup extends React.Component<IMPDNavBarGroupProps> {
    public render() {
        const classes = "mpd-navbar-group";
        const { className, children, xDirection, yDirection, ...remainderProps } = this.props;

        const navItemWithProps = React.Children.map(children, (child: React.ReactElement<any>) =>
            React.cloneElement(child, { ...remainderProps })
        );

        return (
            <MPDScroll className={classNames(classes, className)} yDirection={yDirection} xDirection={xDirection}>
                {navItemWithProps}
            </MPDScroll>
        );
    }
}
