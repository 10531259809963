import moment from "moment";
import { getConversationMessagesRequest } from "src/requests/conversationsRequests";
import { getSelectedConversationAndId } from "src/selectors";
import { AppState } from "src/store";
import { axiosInstance } from "../common";
import { compareMicroseconds } from "../common/utils";
import { resetToZeroConversationUnreadCount } from "../conversations/conversations";
import { setLastSeen } from "../conversations/conversations-api";
import {
    addMessage,
    removeTyping,
    setConversationMessages,
    setHasMoreMessages,
    setShouldShowJumpToNewMessages,
    updateMessage,
    updateSendMessageProcessingStatus
} from "./conversationsMessages";

const prepareMessagesForStore = (
    selectedConversationMessagesList,
    newMessages,
    oldestOrLatest,
    selectedConversation
) => {
    const datesById = selectedConversationMessagesList?.dates?.byId;
    const datesAllIds = selectedConversationMessagesList?.dates?.allIds;
    const messagesById = selectedConversationMessagesList?.messages?.byId;
    const messagesAllIds = selectedConversationMessagesList?.messages?.allIds;
    const accumulator = {
        messages: {
            byId: { ...(messagesById ? messagesById : {}) },
            allIds: [...(messagesAllIds ? messagesAllIds : [])]
        },
        dates: {
            byId: { ...(datesById ? JSON.parse(JSON.stringify(datesById)) : {}) },
            allIds: datesAllIds ? [...datesAllIds] : []
        },
        newFromThisDate: null,
        fromIdAreNew: null
    };
    const { messages, dates } = accumulator;

    if (oldestOrLatest === "oldest") {
        messages.allIds = [...newMessages.map((message) => message.id), ...messages.allIds];
        newMessages.reverse();
    }

    newMessages.forEach((message) => {
        const currentMessageDate = moment(message.inserted_at).format("YYYY-MM-DD");
        messages.byId[message.id] = message;
        if (oldestOrLatest === "oldest") {
            dates.byId[currentMessageDate]
                ? dates.byId[currentMessageDate].unshift(message.id)
                : (dates.byId[currentMessageDate] = [message.id]);
            dates.allIds[0] !== currentMessageDate && dates.allIds.unshift(currentMessageDate);
        } else {
            messages.allIds.push(message.id);
            dates.byId[currentMessageDate]
                ? dates.byId[currentMessageDate].push(message.id)
                : (dates.byId[currentMessageDate] = [message.id]);
            dates.allIds[dates.allIds.length - 1] !== currentMessageDate && dates.allIds.push(currentMessageDate);
        }
        if (
            selectedConversation?.unread_count > 0 &&
            compareMicroseconds(selectedConversation?.last_seen_ts, message.ts) === 0
        ) {
            accumulator.fromIdAreNew = message.id;
        }
    });

    // направление массива полученного с сервера: от новых к старым
    // направление массива в сторе: от старых к новым
    // latest нужны более старые //
    // oldest нужны более новые  //
    return accumulator;
};

export const getTwoDirectionConversationMessages = (lastSeenTs: number) => async (dispatch, getState) => {
    const state = getState();
    const { selectedConversation, selectedConversationId } = getSelectedConversationAndId(state);
    const {
        conversationMessages: { conversationMessagesList }
    } = state;
    const res = await Promise.all([
        getConversationMessagesRequest(selectedConversationId, { limit: 30, oldest: lastSeenTs }),
        getConversationMessagesRequest(selectedConversationId, { limit: 30, latest: lastSeenTs })
    ]);

    // remove edge duplicated message (because of time stamps)
    if (res[0].data.conversation_messages[0]?.id === res[1].data.conversation_messages[0]?.id) {
        res[0].data.conversation_messages.pop();
    }

    const messageData = [...res[0].data.conversation_messages, ...res[1].data.conversation_messages];

    const messagesAndDates = prepareMessagesForStore(
        conversationMessagesList,
        messageData,
        "latest",
        selectedConversation
    );
    const selectedConversationMessagesList = conversationMessagesList[selectedConversationId];

    dispatch(
        setConversationMessages({
            selectedConversationId,
            conversationMessagesList: {
                ...selectedConversationMessagesList,
                ...messagesAndDates
            }
        })
    );

    dispatch(setHasMoreMessages("hasMoreOlder", res[1].data.has_more, selectedConversationId));
    dispatch(setHasMoreMessages("hasMoreLater", res[0].data.has_more, selectedConversationId));
    dispatch(setLastSeen(res[0].data.conversation_messages[0].ts, selectedConversationId));
};

export const getConversationsMessages =
    (oldestOrLatest?: string, lastSeenTs?: number | string) => async (dispatch, getState) => {
        const {
            conversations: { selectedConversationId, conversations: conversationsDetails },
            conversationMessages: { conversationMessagesList }
        } = getState();

        const selectedConversationMessagesList = conversationMessagesList[selectedConversationId];
        const res = await axiosInstance.get(
            `/conversations/${selectedConversationId}/messages?limit=30${
                oldestOrLatest && (lastSeenTs || lastSeenTs === 0) ? `&${oldestOrLatest}=${lastSeenTs}` : ""
            }`
        );

        const { has_more, conversation_messages } = res.data;

        dispatch(resetToZeroConversationUnreadCount(selectedConversationId));
        dispatch(setShouldShowJumpToNewMessages(selectedConversationId, false));

        // removing duplicated message on edge of pagination only if has more older
        (oldestOrLatest === "latest" || !oldestOrLatest) && has_more && conversation_messages.pop();

        const messagesAndDates = prepareMessagesForStore(
            selectedConversationMessagesList,
            conversation_messages,
            oldestOrLatest,
            conversationsDetails.byId[selectedConversationId]
        );

        dispatch(
            setConversationMessages({
                selectedConversationId,
                conversationMessagesList: {
                    ...messagesAndDates
                }
            })
        );

        dispatch(
            setHasMoreMessages(
                oldestOrLatest === "latest" || !oldestOrLatest ? "hasMoreOlder" : "hasMoreLater",
                has_more,
                selectedConversationId
            )
        );

        conversation_messages[0]?.ts && dispatch(setLastSeen(conversation_messages[0]?.ts, selectedConversationId));
    };

export const sendMessage = (message: ConversationMessage) => async (dispatch, getState) => {
    try {
        dispatch(updateSendMessageProcessingStatus(true));
        const {
            conversations: { selectedConversationId }
        } = getState();
        const res = await axiosInstance.post(`/conversations/${selectedConversationId}/send_message`, message);
        dispatch(setLastSeen(res.data.conversation_message.ts, selectedConversationId));
        dispatch(updateMessage(res.data.conversation_message, selectedConversationId));
    } finally {
        dispatch(updateSendMessageProcessingStatus(false));
    }
};

export const addTypingStatus = (dataFromSocket) => (dispatch, getState: () => AppState) => {
    const currentUuserId = getState().userSettings?.myInfo?.uuid;
    // const isChatScrolledToBottom = getState().conversationMessages.isBottom;
    const messagesById =
        getState().conversationMessages.conversationMessagesList[dataFromSocket.conversation_id].messages?.byId;
    const latestMessageId =
        getState().conversationMessages.conversationMessagesList[dataFromSocket.conversation_id].messages?.allIds[0];
    const latestMessage = messagesById[latestMessageId];
    const setHasMoreLater =
        getState().conversationMessages.conversationMessagesList[dataFromSocket.conversation_id].setHasMoreLater;

    const { typing_id, conversation_id, sender } = dataFromSocket;

    if (currentUuserId === sender?.id) {
        return;
    }

    // const updatedSender = sender;
    !messagesById[typing_id] &&
        !setHasMoreLater &&
        dispatch(
            addMessage(
                {
                    id: typing_id,
                    typingId: typing_id,
                    type: "typing",
                    sender,
                    ts:
                        moment(latestMessage.ts * 1000)
                            .endOf("day")
                            .valueOf() / 1e3
                },
                conversation_id
            )
        );
};

export const replaceTypingsToCurrentDate = (conversationId: string) => (dispatch, getState: () => AppState) => {
    const state = getState();
    const typingIds = state.conversationMessages.conversationMessagesList[conversationId].typingIds || [];
    const { byId } = state.conversationMessages.conversationMessagesList[conversationId].messages;

    if (typingIds.length) {
        const isTypingsOutDated = compareMicroseconds(byId[typingIds[0]].ts, moment().endOf("day").valueOf()) < 0;
        if (isTypingsOutDated) {
            typingIds.forEach((typingId) => {
                dispatch(removeTyping(conversationId, typingId));
                dispatch(
                    addMessage(
                        {
                            ...byId[typingId],
                            ts: moment().endOf("day").valueOf() / 1e3
                        },
                        conversationId,
                        byId[typingId]?.sender
                    )
                );
            });
        }
    }
};

export const endOfTyping = (data) => (dispatch) => {
    const { conversation_id: conversationId } = data;
    dispatch(removeTyping(conversationId, data.typing_id));
};
