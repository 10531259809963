import * as isEqual from "lodash.isequal";
import * as qrGenerator from "qrcode-generator";
import * as React from "react";
import { forwardRef } from "react";
import * as ReactDOM from "react-dom";

type EyeColor = string | InnerOuterEyeColor;
type InnerOuterEyeColor = {
    inner: string;
    outer: string;
};

type CornerRadii = number | [number, number, number, number] | InnerOuterRadii;
type InnerOuterRadii = {
    inner: number | [number, number, number, number];
    outer: number | [number, number, number, number];
};

export type QRStyle = "dots" | "rounded" | "extraRounded" | "squares";

export interface IProps {
    value?: string;
    ecLevel?: "L" | "M" | "Q" | "H";
    enableCORS?: boolean;
    size?: number;
    quietZone?: number;
    bgColor?: string;
    fgColor?: string;
    logoImage?: string;
    logoWidth?: number;
    logoHeight?: number;
    logoOpacity?: number;
    removeQrCodeBehindLogo?: boolean;
    eyeRadius?: CornerRadii | [CornerRadii, CornerRadii, CornerRadii];
    eyeColor?: EyeColor | [EyeColor, EyeColor, EyeColor];
    qrStyle?: QRStyle;
    style?: {};
    id?: string;
    forwardedRef: React.RefObject<HTMLCanvasElement>;
    setLogoWasLoaded: (value: boolean) => void;
}

interface ICoordinates {
    row: number;
    col: number;
}

class QRCodeComp extends React.Component<IProps, {}> {
    private canvas: React.RefObject<HTMLCanvasElement>;

    state = {
        svg: null
    };

    public static defaultProps: IProps = {
        value: "https://reactjs.org/",
        ecLevel: "M",
        enableCORS: false,
        size: 150,
        quietZone: 10,
        bgColor: "#FFFFFF",
        fgColor: "#000000",
        logoOpacity: 1,
        removeQrCodeBehindLogo: false,
        qrStyle: "squares",
        eyeRadius: [0, 0, 0]
    };

    private static utf16to8(str: string): string {
        let out = "",
            i: number,
            c: number;
        const len: number = str.length;
        for (i = 0; i < len; i++) {
            c = str.charCodeAt(i);
            if (c >= 0x0001 && c <= 0x007f) {
                out += str.charAt(i);
            } else if (c > 0x07ff) {
                out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f));
                out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
            } else {
                out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
            }
        }
        return out;
    }

    /**
     * Draw a rounded square in the canvas
     */
    private drawRoundedSquare(
        lineWidth: number,
        x: number,
        y: number,
        size: number,
        color: string,
        radii: number | number[],
        fill: boolean,
        ctx: CanvasRenderingContext2D
    ) {
        ctx.lineWidth = lineWidth;
        ctx.fillStyle = color;
        ctx.strokeStyle = color;

        // Adjust coordinates so that the outside of the stroke is aligned to the edges
        y += lineWidth / 2;
        x += lineWidth / 2;
        size -= lineWidth;

        if (!Array.isArray(radii)) {
            radii = [radii, radii, radii, radii];
        }

        // Radius should not be greater than half the size or less than zero
        radii = radii.map((r) => {
            r = Math.min(r, size / 2);
            return r < 0 ? 0 : r;
        });

        const rTopLeft = radii[0] || 0;
        const rTopRight = radii[1] || 0;
        const rBottomRight = radii[2] || 0;
        const rBottomLeft = radii[3] || 0;

        ctx.beginPath();

        ctx.moveTo(x + rTopLeft, y);

        ctx.lineTo(x + size - rTopRight, y);
        if (rTopRight) ctx.quadraticCurveTo(x + size, y, x + size, y + rTopRight);

        ctx.lineTo(x + size, y + size - rBottomRight);
        if (rBottomRight) ctx.quadraticCurveTo(x + size, y + size, x + size - rBottomRight, y + size);

        ctx.lineTo(x + rBottomLeft, y + size);
        if (rBottomLeft) ctx.quadraticCurveTo(x, y + size, x, y + size - rBottomLeft);

        ctx.lineTo(x, y + rTopLeft);
        if (rTopLeft) ctx.quadraticCurveTo(x, y, x + rTopLeft, y);

        ctx.closePath();

        ctx.stroke();
        if (fill) {
            ctx.fill();
        }
    }

    /**
     * Draw a single positional pattern eye.
     */
    private drawPositioningPattern(
        ctx: CanvasRenderingContext2D,
        cellSize: number,
        offset: number,
        row: number,
        col: number,
        color: EyeColor,
        radii: CornerRadii = [0, 0, 0, 0]
    ) {
        const lineWidth = Math.ceil(cellSize);

        let radiiOuter;
        let radiiInner;
        if (typeof radii !== "number" && !Array.isArray(radii)) {
            radiiOuter = radii.outer || 0;
            radiiInner = radii.inner || 0;
        } else {
            radiiOuter = radii as CornerRadii;
            radiiInner = radiiOuter;
        }

        let colorOuter;
        let colorInner;
        if (typeof color !== "string") {
            colorOuter = color.outer;
            colorInner = color.inner;
        } else {
            colorOuter = color;
            colorInner = color;
        }

        let y = row * cellSize + offset;
        let x = col * cellSize + offset;
        let size = cellSize * 7;

        // Outer box
        this.drawRoundedSquare(lineWidth, x, y, size, colorOuter, radiiOuter, false, ctx);

        // Inner box
        size = cellSize * 3;
        y += cellSize * 2;
        x += cellSize * 2;
        this.drawRoundedSquare(lineWidth, x, y, size, colorInner, radiiInner, true, ctx);
    }

    /**
     * Is this dot inside a positional pattern zone.
     */
    private isInPositioninZone(col: number, row: number, zones: ICoordinates[]) {
        return zones.some((zone) => row >= zone.row && row <= zone.row + 7 && col >= zone.col && col <= zone.col + 7);
    }

    private transformPixelLengthIntoNumberOfCells(pixelLength: number, cellSize: number) {
        return pixelLength / cellSize;
    }

    private isCoordinateInImage(
        col: number,
        row: number,
        dWidthLogo: number,
        dHeightLogo: number,
        dxLogo: number,
        dyLogo: number,
        cellSize: number,
        logoImage: string
    ) {
        if (logoImage) {
            const numberOfCellsMargin = 2;
            const firstRowOfLogo = this.transformPixelLengthIntoNumberOfCells(dxLogo, cellSize);
            const firstColumnOfLogo = this.transformPixelLengthIntoNumberOfCells(dyLogo, cellSize);
            const logoWidthInCells = this.transformPixelLengthIntoNumberOfCells(dWidthLogo, cellSize) - 1;
            const logoHeightInCells = this.transformPixelLengthIntoNumberOfCells(dHeightLogo, cellSize) - 1;

            return (
                row >= firstRowOfLogo - numberOfCellsMargin &&
                row <= firstRowOfLogo + logoWidthInCells + numberOfCellsMargin && // check rows
                col >= firstColumnOfLogo - numberOfCellsMargin &&
                col <= firstColumnOfLogo + logoHeightInCells + numberOfCellsMargin
            ); // check cols
        } else {
            return false;
        }
    }

    shouldComponentUpdate(nextProps: IProps) {
        return !isEqual(this.props, nextProps);
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps, prevState) {
        this.update();
        // Object.keys(prevProps).forEach((propName) => {
        //     if (prevProps[propName] !== this.props[propName]) {
        //         console.log(`Props ${propName} was updated`);
        //     }
        // });

        // Object.keys(prevState).forEach((propName) => {
        //     if (prevState[propName] !== this.state[propName]) {
        //         console.log(`State ${propName} was updated`);
        //     }
        // });
    }

    whichCornersRounded = (row, col, qrCode, radiusValue = 12, length) => {
        if (qrCode.isDark(row, col)) {
            const conrnersRadiuses = Array.of(radiusValue, radiusValue, radiusValue, radiusValue);
            //top
            if (row - 1 >= 0 && qrCode.isDark(row - 1, col)) {
                conrnersRadiuses[0] = 0;
                conrnersRadiuses[1] = 0;
            }
            // right
            if (col + 1 < length && qrCode.isDark(row, col + 1)) {
                conrnersRadiuses[1] = 0;
                conrnersRadiuses[2] = 0;
            }
            // bottom
            if (row + 1 < length && qrCode.isDark(row + 1, col)) {
                conrnersRadiuses[2] = 0;
                conrnersRadiuses[3] = 0;
            }
            // left
            if (col - 1 >= 0 && qrCode.isDark(row, col - 1)) {
                conrnersRadiuses[0] = 0;
                conrnersRadiuses[3] = 0;
            }
            return conrnersRadiuses;
            // return 0;
        }
        return 0;
        // qrCode.isDark(row, col);
    };

    innerRoundedCorners = (row, col, qrCode, length, cellSize, offset) => {
        const cornersRadiuses = [false, false, false, false];
        const x = Math.round(col * cellSize) + offset;
        const y = Math.round(row * cellSize) + offset;
        const c = cellSize;
        // top left
        if (
            row - 1 >= 0 &&
            col - 1 >= 0 &&
            qrCode.isDark(row, col - 1) &&
            qrCode.isDark(row - 1, col - 1) &&
            qrCode.isDark(row - 1, col)
        ) {
            cornersRadiuses[0] = {
                startAngle: Math.PI,
                endAngle: Math.PI + Math.PI / 2,
                lineStart: [x, y + c / 2],
                firstLineEnd: [x, y],
                secondLineEnd: [x + c / 2, y]
            };
        }
        // top right
        if (
            row - 1 >= 0 &&
            col + 1 < length &&
            qrCode.isDark(row - 1, col) &&
            qrCode.isDark(row - 1, col + 1) &&
            qrCode.isDark(row, col + 1)
        ) {
            // cornersRadiuses[1] = 1.5 * Math.PI;
            cornersRadiuses[1] = {
                startAngle: 1.5 * Math.PI,
                endAngle: 1.5 * Math.PI + Math.PI / 2,
                lineStart: [x + c / 2, y],
                firstLineEnd: [x + c, y],
                secondLineEnd: [x + c, y + c / 2]
            };
        }
        // bottom right
        if (
            row + 1 < length &&
            col + 1 < length &&
            qrCode.isDark(row, col + 1) &&
            qrCode.isDark(row + 1, col + 1) &&
            qrCode.isDark(row + 1, col)
        ) {
            // cornersRadiuses[2] = 0;
            cornersRadiuses[2] = {
                startAngle: 0,
                endAngle: 0 + Math.PI / 2,
                lineStart: [x + c, y + c / 2],
                firstLineEnd: [x + c, y + c],
                secondLineEnd: [x + c / 2, y + c]
            };
        }
        // bottom left
        if (
            row + 1 < length &&
            col - 1 >= 0 &&
            qrCode.isDark(row + 1, col) &&
            qrCode.isDark(row + 1, col - 1) &&
            qrCode.isDark(row, col - 1)
        ) {
            // cornersRadiuses[3] = Math.PI / 2;
            cornersRadiuses[3] = {
                startAngle: Math.PI / 2,
                endAngle: Math.PI,
                lineStart: [x + c / 2, y + c],
                firstLineEnd: [x, y + c],
                secondLineEnd: [x, y + c / 2]
            };
        }
        return cornersRadiuses;
    };

    async update() {
        const {
            value,
            ecLevel,
            enableCORS,
            size,
            quietZone,
            bgColor,
            fgColor,
            logoImage,
            logoWidth,
            logoHeight,
            logoOpacity,
            removeQrCodeBehindLogo,
            qrStyle,
            eyeRadius,
            eyeColor
        } = this.props;

        const qrCode = qrGenerator(0, ecLevel);
        qrCode.addData(QRCodeComp.utf16to8(value));
        qrCode.make();

        const canvas: HTMLCanvasElement = ReactDOM.findDOMNode(this.props.canvas.current) as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
        // var ctx = new C2S(500, 500);

        const length = qrCode.getModuleCount();
        const cellSize = Math.round(size / length);
        const roundedSize = cellSize * length;
        const canvasSize = roundedSize + 2 * +quietZone;
        const scale = window.devicePixelRatio || 1;
        canvas.height = canvas.width = canvasSize * scale;
        ctx.scale(scale, scale);

        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, canvasSize, canvasSize);

        const offset = +quietZone;

        const dWidthLogo = logoWidth || roundedSize * 0.2;
        const dHeightLogo = logoHeight || dWidthLogo;
        const dxLogo = (roundedSize - dWidthLogo) / 2;
        const dyLogo = (roundedSize - dHeightLogo) / 2;

        const positioningZones: ICoordinates[] = [
            { row: 0, col: 0 },
            { row: 0, col: length - 7 },
            { row: length - 7, col: 0 }
        ];

        ctx.strokeStyle = fgColor;
        if (qrStyle === "dots") {
            ctx.fillStyle = fgColor;
            const radius = cellSize / 2;
            for (let row = 0; row < length; row++) {
                for (let col = 0; col < length; col++) {
                    if (
                        qrCode.isDark(row, col) &&
                        !this.isInPositioninZone(row, col, positioningZones) &&
                        !(
                            removeQrCodeBehindLogo &&
                            this.isCoordinateInImage(
                                row,
                                col,
                                dWidthLogo,
                                dHeightLogo,
                                dxLogo,
                                dyLogo,
                                cellSize,
                                logoImage
                            )
                        )
                    ) {
                        ctx.beginPath();
                        ctx.arc(
                            Math.round(col * cellSize) + radius + offset,
                            Math.round(row * cellSize) + radius + offset,
                            (radius / 100) * 100,
                            0,
                            2 * Math.PI,
                            true
                        );
                        ctx.closePath();
                        ctx.fill();
                    }
                }
            }
        } else if (qrStyle === "rounded") {
            // const radius = cellSize / 2;
            for (let row = 0; row < length; row++) {
                for (let col = 0; col < length; col++) {
                    if (
                        qrCode.isDark(row, col) &&
                        !this.isInPositioninZone(row, col, positioningZones) &&
                        !(
                            removeQrCodeBehindLogo &&
                            this.isCoordinateInImage(
                                row,
                                col,
                                dWidthLogo,
                                dHeightLogo,
                                dxLogo,
                                dyLogo,
                                cellSize,
                                logoImage
                            )
                        )
                    ) {
                        ctx.fillStyle = fgColor;
                        ctx.beginPath();
                        const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
                        const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
                        ctx.roundRect(
                            Math.round(col * cellSize) + offset,
                            Math.round(row * cellSize) + offset,
                            w,
                            h,
                            this.whichCornersRounded(row, col, qrCode, 1, length)
                        );
                        ctx.fill();
                        ctx.closePath();
                        // ctx.fill();
                    }
                    if (
                        qrCode.isDark(row, col) &&
                        !this.isInPositioninZone(row, col, positioningZones) &&
                        !(
                            removeQrCodeBehindLogo &&
                            this.isCoordinateInImage(
                                row,
                                col,
                                dWidthLogo,
                                dHeightLogo,
                                dxLogo,
                                dyLogo,
                                cellSize,
                                logoImage
                            )
                        )
                    ) {
                        ctx.beginPath();
                        const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
                        const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
                        ctx.roundRect(
                            Math.round(col * cellSize) + offset,
                            Math.round(row * cellSize) + offset,
                            w,
                            h,
                            this.whichCornersRounded(row, col, qrCode, 1, length)
                        );
                        ctx.fill();
                        ctx.closePath();
                    }
                }
            }
        } else if (qrStyle === "extraRounded") {
            ctx.fillStyle = fgColor;
            const radius = cellSize / 2;
            for (let row = 0; row < length; row++) {
                for (let col = 0; col < length; col++) {
                    if (
                        !qrCode.isDark(row, col) &&
                        !this.isInPositioninZone(row, col, positioningZones) &&
                        !(
                            removeQrCodeBehindLogo &&
                            this.isCoordinateInImage(
                                row,
                                col,
                                dWidthLogo,
                                dHeightLogo,
                                dxLogo,
                                dyLogo,
                                cellSize,
                                logoImage
                            )
                        )
                    ) {
                        // const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
                        // const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
                        const res = this.innerRoundedCorners(row, col, qrCode, length, cellSize, offset);

                        res.forEach((regionData: number | boolean) => {
                            if (regionData) {
                                ctx.beginPath();
                                ctx.fillStyle = fgColor;

                                ctx.fill();
                                ctx.arc(
                                    Math.round(col * cellSize) + radius + offset,
                                    Math.round(row * cellSize) + radius + offset,
                                    radius,
                                    regionData.startAngle,
                                    regionData.endAngle,
                                    false
                                );
                                ctx.moveTo(regionData.lineStart[0], regionData.lineStart[1]);
                                ctx.lineTo(regionData.firstLineEnd[0], regionData.firstLineEnd[1]);
                                ctx.lineTo(regionData.secondLineEnd[0], regionData.secondLineEnd[1]);
                                ctx.closePath();
                                // ctx.stroke();
                                // ctx.fillStyle = "lime";
                                ctx.fill("evenodd");
                            }
                        });
                    }
                    if (
                        qrCode.isDark(row, col) &&
                        !this.isInPositioninZone(row, col, positioningZones) &&
                        !(
                            removeQrCodeBehindLogo &&
                            this.isCoordinateInImage(
                                row,
                                col,
                                dWidthLogo,
                                dHeightLogo,
                                dxLogo,
                                dyLogo,
                                cellSize,
                                logoImage
                            )
                        )
                    ) {
                        ctx.fillStyle = fgColor;
                        ctx.strokeStyle = fgColor;
                        const w = cellSize;
                        const h = cellSize;
                        ctx.beginPath();
                        ctx.roundRect(
                            col * cellSize + offset,
                            row * cellSize + offset,
                            w,
                            h,
                            this.whichCornersRounded(row, col, qrCode, cellSize / 2, length)
                        );
                        // ctx.stroke();
                        ctx.fill();
                        ctx.closePath();
                    }
                }
            }
        } else {
            for (let row = 0; row < length; row++) {
                for (let col = 0; col < length; col++) {
                    if (
                        qrCode.isDark(row, col) &&
                        !this.isInPositioninZone(row, col, positioningZones) &&
                        !(
                            removeQrCodeBehindLogo &&
                            this.isCoordinateInImage(
                                row,
                                col,
                                dWidthLogo,
                                dHeightLogo,
                                dxLogo,
                                dyLogo,
                                cellSize,
                                logoImage
                            )
                        )
                    ) {
                        ctx.fillStyle = fgColor;
                        const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
                        const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
                        ctx.fillRect(Math.round(col * cellSize) + offset, Math.round(row * cellSize) + offset, w, h);
                    } else {
                        ctx.fillStyle = bgColor;
                        const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
                        const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
                        ctx.fillRect(Math.round(col * cellSize) + offset, Math.round(row * cellSize) + offset, w, h);
                    }
                }
            }
        }

        // Draw positioning patterns
        for (let i = 0; i < 3; i++) {
            const { row, col } = positioningZones[i];

            let radii = eyeRadius;
            let color;

            if (Array.isArray(radii)) {
                radii = radii[i];
            }
            if (typeof radii == "number") {
                radii = [radii, radii, radii, radii];
            }

            if (!eyeColor) {
                // if not specified, eye color is the same as foreground,
                color = fgColor;
            } else {
                if (Array.isArray(eyeColor)) {
                    // if array, we pass the single color
                    color = eyeColor[i];
                } else {
                    color = eyeColor as EyeColor;
                }
            }

            this.drawPositioningPattern(ctx, cellSize, offset, row, col, color, radii as CornerRadii);
        }

        if (logoImage) {
            this.props.setLogoWasLoaded(false);
            const image = new Image();
            if (enableCORS) {
                image.crossOrigin = "Anonymous";
            }
            image.src = logoImage;
            image.onload = async () => {
                ctx.save();
                ctx.globalAlpha = logoOpacity;
                if (image.naturalWidth !== 0) {
                    await ctx.drawImage(image, dxLogo + offset, dyLogo + offset, dWidthLogo, dHeightLogo);
                    ctx.restore();
                    this.props.setLogoWasLoaded(true);
                }
            };
        }

        // var mySerializedSVG = ctx.getSerializedSvg();
        // var svg = ctx.getSvg();
        // var dataUrl = "data:image/svg+xml," + encodeURIComponent(mySerializedSVG);
        // const link = document.createElement("a");
        // link.download = "qrcode322.svg";
        // link.href = dataUrl;
        // link.click();

        //serialize your SVG
        // var mySerializedSVG = ctx.getSerializedSvg(); //true here, if you need to convert named to numbered entities.

        // //If you really need to you can access the shadow inline SVG created by calling:
        // var svg = ctx.getSvg();
        // this.setState({ svg });
        // var dataUrl = "data:image/svg+xml," + encodeURIComponent(mySerializedSVG);
        // // this.oGrayImg = new Image();
        // // this.oGrayImg.current.src = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
        // const img = new Image();
        // img.src = dataUrl;
        // ctxx.drawImage(img, 0, 0);
    }

    // update() {
    //     const {
    //         value,
    //         ecLevel,
    //         enableCORS,
    //         size,
    //         quietZone,
    //         bgColor,
    //         fgColor,
    //         logoImage,
    //         logoWidth,
    //         logoHeight,
    //         logoOpacity,
    //         removeQrCodeBehindLogo,
    //         qrStyle,
    //         eyeRadius,
    //         eyeColor
    //     } = this.props;

    //     const qrCode = qrGenerator(0, ecLevel);
    //     qrCode.addData(QRCodeComputf16to8(value));
    //     qrCode.make();

    //     const canvas: HTMLCanvasElement = ReactDOM.findDOMNode(this.canvas.current) as HTMLCanvasElement;
    //     const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

    //     const canvasSize = +size + 2 * +quietZone;
    //     const length = qrCode.getModuleCount();
    //     const cellSize = size / length;
    //     const scale = window.devicePixelRatio || 1;
    //     canvas.height = canvas.width = canvasSize * scale;
    //     ctx.scale(scale, scale);

    //     ctx.fillStyle = bgColor;
    //     ctx.fillRect(0, 0, canvasSize, canvasSize);

    //     const offset = +quietZone;

    //     const dWidthLogo = logoWidth || size * 0.2;
    //     const dHeightLogo = logoHeight || dWidthLogo;
    //     const dxLogo = (size - dWidthLogo) / 2;
    //     const dyLogo = (size - dHeightLogo) / 2;

    //     const positioningZones: ICoordinates[] = [
    //         { row: 0, col: 0 },
    //         { row: 0, col: length - 7 },
    //         { row: length - 7, col: 0 }
    //     ];

    //     ctx.strokeStyle = fgColor;
    //     if (qrStyle === "dots") {
    //         ctx.fillStyle = fgColor;
    //         const radius = cellSize / 2;
    //         for (let row = 0; row < length; row++) {
    //             for (let col = 0; col < length; col++) {
    //                 if (
    //                     qrCode.isDark(row, col) &&
    //                     !this.isInPositioninZone(row, col, positioningZones) &&
    //                     !(
    //                         removeQrCodeBehindLogo &&
    //                         this.isCoordinateInImage(
    //                             row,
    //                             col,
    //                             dWidthLogo,
    //                             dHeightLogo,
    //                             dxLogo,
    //                             dyLogo,
    //                             cellSize,
    //                             logoImage
    //                         )
    //                     )
    //                 ) {
    //                     ctx.beginPath();
    //                     ctx.arc(
    //                         Math.round(col * cellSize) + radius + offset,
    //                         Math.round(row * cellSize) + radius + offset,
    //                         (radius / 100) * 75,
    //                         0,
    //                         2 * Math.PI,
    //                         false
    //                     );
    //                     ctx.closePath();
    //                     ctx.fill();
    //                 }
    //             }
    //         }
    //     } else {
    //         for (let row = 0; row < length; row++) {
    //             for (let col = 0; col < length; col++) {
    //                 if (
    //                     qrCode.isDark(row, col) &&
    //                     !this.isInPositioninZone(row, col, positioningZones) &&
    //                     !(
    //                         removeQrCodeBehindLogo &&
    //                         this.isCoordinateInImage(
    //                             row,
    //                             col,
    //                             dWidthLogo,
    //                             dHeightLogo,
    //                             dxLogo,
    //                             dyLogo,
    //                             cellSize,
    //                             logoImage
    //                         )
    //                     )
    //                 ) {
    //                     ctx.fillStyle = fgColor;
    //                     const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
    //                     const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
    //                     ctx.fillRect(Math.round(col * cellSize) + offset, Math.round(row * cellSize) + offset, w, h);
    //                 }
    //             }
    //         }
    //     }

    //     // Draw positioning patterns
    //     for (let i = 0; i < 3; i++) {
    //         const { row, col } = positioningZones[i];

    //         let radii = eyeRadius;
    //         let color;

    //         if (Array.isArray(radii)) {
    //             radii = radii[i];
    //         }
    //         if (typeof radii == "number") {
    //             radii = [radii, radii, radii, radii];
    //         }

    //         if (!eyeColor) {
    //             // if not specified, eye color is the same as foreground,
    //             color = fgColor;
    //         } else {
    //             if (Array.isArray(eyeColor)) {
    //                 // if array, we pass the single color
    //                 color = eyeColor[i];
    //             } else {
    //                 color = eyeColor as EyeColor;
    //             }
    //         }

    //         this.drawPositioningPattern(ctx, cellSize, offset, row, col, color, radii as CornerRadii);
    //     }

    //     if (logoImage) {
    //         const image = new Image();
    //         if (enableCORS) {
    //             image.crossOrigin = "Anonymous";
    //         }
    //         image.onload = () => {
    //             ctx.save();
    //             ctx.globalAlpha = logoOpacity;
    //             ctx.drawImage(image, dxLogo + offset, dyLogo + offset, dWidthLogo, dHeightLogo);
    //             ctx.restore();
    //         };
    //         image.src = logoImage;
    //     }
    // }

    render() {
        const size = +this.props.size + 2 * +this.props.quietZone;
        // return React.createElement("canvas", {
        //     id: this.props.id ?? "react-qrcode-logo",
        //     height: size,
        //     width: size,
        //     style: { height: size + "px", width: size + "px" },
        //     ref: this.canvas
        // });
        return (
            <>
                {React.createElement("canvas", {
                    id: this.props.id ?? "react-qrcode-logo",
                    height: size,
                    width: size,
                    style: { height: size + "px", width: size + "px" },
                    ref: this.props.canvas
                })}
            </>
        );
    }
}

export const QRCode = forwardRef<HTMLCanvasElement, IProps>((props, ref) => <QRCodeComp {...props} canvas={ref} />);

// ----------------------------------------------------

// import * as isEqual from "lodash.isequal";
// import * as qrGenerator from "qrcode-generator";
// import * as React from "react";
// import * as ReactDOM from "react-dom";

// type EyeColor = string | InnerOuterEyeColor;
// type InnerOuterEyeColor = {
//     inner: string;
//     outer: string;
// };

// type CornerRadii = number | [number, number, number, number] | InnerOuterRadii;
// type InnerOuterRadii = {
//     inner: number | [number, number, number, number];
//     outer: number | [number, number, number, number];
// };

// export interface IProps {
//     value?: string;
//     ecLevel?: "L" | "M" | "Q" | "H";
//     enableCORS?: boolean;
//     size?: number;
//     quietZone?: number;
//     bgColor?: string;
//     fgColor?: string;
//     logoImage?: string;
//     logoWidth?: number;
//     logoHeight?: number;
//     logoOpacity?: number;
//     removeQrCodeBehindLogo?: boolean;
//     eyeRadius?: CornerRadii | [CornerRadii, CornerRadii, CornerRadii];
//     eyeColor?: EyeColor | [EyeColor, EyeColor, EyeColor];
//     qrStyle?: "squares" | "dots";
//     style?: object;
//     id?: string;
// }

// interface ICoordinates {
//     row: number;
//     col: number;
// }

// export class QRCode extends React.Component<IProps, {}> {
//     private canvas: React.RefObject<HTMLCanvasElement>;

//     public static defaultProps: IProps = {
//         value: "https://reactjs.org/",
//         ecLevel: "M",
//         enableCORS: false,
//         size: 150,
//         quietZone: 10,
//         bgColor: "#FFFFFF",
//         fgColor: "#000000",
//         logoOpacity: 1,
//         removeQrCodeBehindLogo: false,
//         qrStyle: "squares",
//         eyeRadius: [0, 0, 0]
//     };

//     private static utf16to8(str: string): string {
//         let out: string = "",
//             i: number,
//             c: number;
//         const len: number = str.length;
//         for (i = 0; i < len; i++) {
//             c = str.charCodeAt(i);
//             if (c >= 0x0001 && c <= 0x007f) {
//                 out += str.charAt(i);
//             } else if (c > 0x07ff) {
//                 out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f));
//                 out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f));
//                 out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
//             } else {
//                 out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f));
//                 out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
//             }
//         }
//         return out;
//     }

//     /**
//      * Draw a rounded square in the canvas
//      */
//     private drawRoundedSquare(
//         lineWidth: number,
//         x: number,
//         y: number,
//         size: number,
//         color: string,
//         radii: number | number[],
//         fill: boolean,
//         ctx: CanvasRenderingContext2D
//     ) {
//         ctx.lineWidth = lineWidth;
//         ctx.fillStyle = color;
//         ctx.strokeStyle = color;

//         // Adjust coordinates so that the outside of the stroke is aligned to the edges
//         y += lineWidth / 2;
//         x += lineWidth / 2;
//         size -= lineWidth;

//         if (!Array.isArray(radii)) {
//             radii = [radii, radii, radii, radii];
//         }

//         // Radius should not be greater than half the size or less than zero
//         radii = radii.map((r) => {
//             r = Math.min(r, size / 2);
//             return r < 0 ? 0 : r;
//         });

//         const rTopLeft = radii[0] || 0;
//         const rTopRight = radii[1] || 0;
//         const rBottomRight = radii[2] || 0;
//         const rBottomLeft = radii[3] || 0;

//         ctx.beginPath();

//         ctx.moveTo(x + rTopLeft, y);

//         ctx.lineTo(x + size - rTopRight, y);
//         if (rTopRight) ctx.quadraticCurveTo(x + size, y, x + size, y + rTopRight);

//         ctx.lineTo(x + size, y + size - rBottomRight);
//         if (rBottomRight) ctx.quadraticCurveTo(x + size, y + size, x + size - rBottomRight, y + size);

//         ctx.lineTo(x + rBottomLeft, y + size);
//         if (rBottomLeft) ctx.quadraticCurveTo(x, y + size, x, y + size - rBottomLeft);

//         ctx.lineTo(x, y + rTopLeft);
//         if (rTopLeft) ctx.quadraticCurveTo(x, y, x + rTopLeft, y);

//         ctx.closePath();

//         ctx.stroke();
//         if (fill) {
//             ctx.fill();
//         }
//     }

//     /**
//      * Draw a single positional pattern eye.
//      */
//     private drawPositioningPattern(
//         ctx: CanvasRenderingContext2D,
//         cellSize: number,
//         offset: number,
//         row: number,
//         col: number,
//         color: EyeColor,
//         radii: CornerRadii = [0, 0, 0, 0]
//     ) {
//         const lineWidth = Math.ceil(cellSize);

//         let radiiOuter;
//         let radiiInner;
//         if (typeof radii !== "number" && !Array.isArray(radii)) {
//             radiiOuter = radii.outer || 0;
//             radiiInner = radii.inner || 0;
//         } else {
//             radiiOuter = radii as CornerRadii;
//             radiiInner = radiiOuter;
//         }

//         let colorOuter;
//         let colorInner;
//         if (typeof color !== "string") {
//             colorOuter = color.outer;
//             colorInner = color.inner;
//         } else {
//             colorOuter = color;
//             colorInner = color;
//         }

//         let y = row * cellSize + offset;
//         let x = col * cellSize + offset;
//         let size = cellSize * 7;

//         // Outer box
//         this.drawRoundedSquare(lineWidth, x, y, size, colorOuter, radiiOuter, false, ctx);

//         // Inner box
//         size = cellSize * 3;
//         y += cellSize * 2;
//         x += cellSize * 2;
//         this.drawRoundedSquare(lineWidth, x, y, size, colorInner, radiiInner, true, ctx);
//     }

//     /**
//      * Is this dot inside a positional pattern zone.
//      */
//     private isInPositioninZone(col: number, row: number, zones: ICoordinates[]) {
//         return zones.some((zone) => row >= zone.row && row <= zone.row + 7 && col >= zone.col && col <= zone.col + 7);
//     }

//     private transformPixelLengthIntoNumberOfCells(pixelLength: number, cellSize: number) {
//         return pixelLength / cellSize;
//     }

//     private isCoordinateInImage(
//         col: number,
//         row: number,
//         dWidthLogo: number,
//         dHeightLogo: number,
//         dxLogo: number,
//         dyLogo: number,
//         cellSize: number,
//         logoImage: string
//     ) {
//         if (logoImage) {
//             const numberOfCellsMargin = 2;
//             const firstRowOfLogo = this.transformPixelLengthIntoNumberOfCells(dxLogo, cellSize);
//             const firstColumnOfLogo = this.transformPixelLengthIntoNumberOfCells(dyLogo, cellSize);
//             const logoWidthInCells = this.transformPixelLengthIntoNumberOfCells(dWidthLogo, cellSize) - 1;
//             const logoHeightInCells = this.transformPixelLengthIntoNumberOfCells(dHeightLogo, cellSize) - 1;

//             return (
//                 row >= firstRowOfLogo - numberOfCellsMargin &&
//                 row <= firstRowOfLogo + logoWidthInCells + numberOfCellsMargin && // check rows
//                 col >= firstColumnOfLogo - numberOfCellsMargin &&
//                 col <= firstColumnOfLogo + logoHeightInCells + numberOfCellsMargin
//             ); // check cols
//         } else {
//             return false;
//         }
//     }

//     constructor(props: IProps) {
//         super(props);
//         this.canvas = React.createRef();
//     }

//     shouldComponentUpdate(nextProps: IProps) {
//         return !isEqual(this.props, nextProps);
//     }

//     componentDidMount() {
//         this.update();
//     }

//     componentDidUpdate() {
//         this.update();
//     }

//     update() {
//         const {
//             value,
//             ecLevel,
//             enableCORS,
//             size,
//             quietZone,
//             bgColor,
//             fgColor,
//             logoImage,
//             logoWidth,
//             logoHeight,
//             logoOpacity,
//             removeQrCodeBehindLogo,
//             qrStyle,
//             eyeRadius,
//             eyeColor
//         } = this.props;

//         const qrCode = qrGenerator(0, ecLevel);
//         qrCode.addData(QRCodeComputf16to8(value));
//         qrCode.make();

//         const canvas: HTMLCanvasElement = ReactDOM.findDOMNode(this.canvas.current) as HTMLCanvasElement;
//         const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

//         const canvasSize = +size + 2 * +quietZone;
//         const length = qrCode.getModuleCount();
//         const cellSize = size / length;
//         const scale = window.devicePixelRatio || 1;
//         canvas.height = canvas.width = canvasSize * scale;
//         ctx.scale(scale, scale);

//         ctx.fillStyle = bgColor;
//         ctx.fillRect(0, 0, canvasSize, canvasSize);

//         const offset = +quietZone;

//         const dWidthLogo = logoWidth || size * 0.2;
//         const dHeightLogo = logoHeight || dWidthLogo;
//         const dxLogo = (size - dWidthLogo) / 2;
//         const dyLogo = (size - dHeightLogo) / 2;

//         const positioningZones: ICoordinates[] = [
//             { row: 0, col: 0 },
//             { row: 0, col: length - 7 },
//             { row: length - 7, col: 0 }
//         ];

//         ctx.strokeStyle = fgColor;
//         if (qrStyle === "dots") {
//             ctx.fillStyle = fgColor;
//             const radius = cellSize / 2;
//             for (let row = 0; row < length; row++) {
//                 for (let col = 0; col < length; col++) {
//                     if (
//                         qrCode.isDark(row, col) &&
//                         !this.isInPositioninZone(row, col, positioningZones) &&
//                         !(
//                             removeQrCodeBehindLogo &&
//                             this.isCoordinateInImage(
//                                 row,
//                                 col,
//                                 dWidthLogo,
//                                 dHeightLogo,
//                                 dxLogo,
//                                 dyLogo,
//                                 cellSize,
//                                 logoImage
//                             )
//                         )
//                     ) {
//                         ctx.beginPath();
//                         ctx.arc(
//                             Math.round(col * cellSize) + radius + offset,
//                             Math.round(row * cellSize) + radius + offset,
//                             (radius / 100) * 75,
//                             0,
//                             2 * Math.PI,
//                             false
//                         );
//                         ctx.closePath();
//                         ctx.fill();
//                     }
//                 }
//             }
//         } else {
//             for (let row = 0; row < length; row++) {
//                 for (let col = 0; col < length; col++) {
//                     if (
//                         qrCode.isDark(row, col) &&
//                         !this.isInPositioninZone(row, col, positioningZones) &&
//                         !(
//                             removeQrCodeBehindLogo &&
//                             this.isCoordinateInImage(
//                                 row,
//                                 col,
//                                 dWidthLogo,
//                                 dHeightLogo,
//                                 dxLogo,
//                                 dyLogo,
//                                 cellSize,
//                                 logoImage
//                             )
//                         )
//                     ) {
//                         ctx.fillStyle = fgColor;
//                         const w = Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
//                         const h = Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
//                         ctx.fillRect(Math.round(col * cellSize) + offset, Math.round(row * cellSize) + offset, w, h);
//                     }
//                 }
//             }
//         }

//         // Draw positioning patterns
//         for (let i = 0; i < 3; i++) {
//             const { row, col } = positioningZones[i];

//             let radii = eyeRadius;
//             let color;

//             if (Array.isArray(radii)) {
//                 radii = radii[i];
//             }
//             if (typeof radii == "number") {
//                 radii = [radii, radii, radii, radii];
//             }

//             if (!eyeColor) {
//                 // if not specified, eye color is the same as foreground,
//                 color = fgColor;
//             } else {
//                 if (Array.isArray(eyeColor)) {
//                     // if array, we pass the single color
//                     color = eyeColor[i];
//                 } else {
//                     color = eyeColor as EyeColor;
//                 }
//             }

//             this.drawPositioningPattern(ctx, cellSize, offset, row, col, color, radii as CornerRadii);
//         }

//         if (logoImage) {
//             const image = new Image();
//             if (enableCORS) {
//                 image.crossOrigin = "Anonymous";
//             }
//             image.onload = () => {
//                 ctx.save();
//                 ctx.globalAlpha = logoOpacity;
//                 ctx.drawImage(image, dxLogo + offset, dyLogo + offset, dWidthLogo, dHeightLogo);
//                 ctx.restore();
//             };
//             image.src = logoImage;
//         }
//     }

//     render() {
//         const size = +this.props.size + 2 * +this.props.quietZone;
//         return React.createElement("canvas", {
//             id: this.props.id ?? "react-qrcode-logo",
//             height: size,
//             width: size,
//             style: { height: size + "px", width: size + "px" },
//             ref: this.canvas
//         });
//     }
// }
