import { Position } from "@blueprintjs/core";
import classNames from "classnames";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { onToggleRightPanel } from "src/actions/account-settings/account-settings-api";
import { onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import {
    BreadCrumbs,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    StyledTextArea,
    StyledTextAreaTypes,
    StyledButton,
    StyledButtonTypes,
    StyledAlert,
    StyledAlertTypes
} from "src/components";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { getTagDetails } from "src/requests/tagsRequests";
import { Classes } from "../../../../../mpd-library";
import { TagsContext } from "../tags-settings";
import styles from "./styles.module.scss";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UseConversationsTags } from "../../../../conversations/components/chat/hooks/useConversationsTags";
import { ReactComponent as CloseIcon } from "../../../../../mpd-library/icon/assets/close.svg";
import { StyledCheckbox } from "../../../../../components";
import { FormSectionTitle } from "../../../../../components/FormSectionTitle/FormSectionTitle";
import { getTags } from "../../../../../requests/tagsRequests";
import { Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "../../../../../mpd-library/dropdown/components/target/constants";

const classname = "add-new-tag";

type RouteParams = {
    id: string;
};

const FETCH_TAGS_DATA_PARAMS = {
    root_tags_only: true
};

export const AddNewTag = () => {
    const dispatch = useDispatch();
    const history = useHistory<History>();
    const [selectedTag, setSelectedTag] = useState<any>(null);
    const [isTagsPopoverOpen, setIsPopoverTagsOpen] = useState<boolean>(false);
    const [createTagProcessing, setCreatingTagProcessing] = useState<boolean>(false);
    const [getTagsDetailsProcessing, setGetTagsDetailsProcessing] = useState<boolean>(false);
    const { id: idFromUrl } = useParams<RouteParams>();
    const isEdit = useMemo(() => history.location.pathname.includes("edit"), [history]);
    const [selectedConversationTag, setSelectedConversationTag] = useState(null);
    const { updateTag, createTag, setHasMore, allIds } = useContext(TagsContext);
    const { deleteTag }: UseConversationsTags = useContext(TagsContext);
    const [isFormValid, setIsFormValid] = useState(false);
    const { conversations_enabled, contacts_enabled } = useSelector(
        (state: AppState) => state.accountSettings.organization
    );

    const formik = useFormik({
        initialValues: {
            newTagName: selectedConversationTag?.name,
            selectedTag: selectedConversationTag?.parent,
            tagDescription: selectedConversationTag?.description,
            enable_for_conversations: selectedConversationTag?.enable_for_conversations,
            enable_for_contacts: selectedConversationTag?.enable_for_contacts
        },

        enableReinitialize: true,
        validationSchema: Yup.object({
            newTagName: Yup.string().required("Can't be blank"),
            tagDescription: Yup.string().nullable(),
            enable_for_conversations: Yup.boolean(),
            enable_for_contacts: Yup.boolean()
        }),
        onSubmit: async (values, { setSubmitting }) => {
            await onSaveClick(values);
            setSubmitting(false);
        }
    });

    console.log("selectedConversationTag", selectedConversationTag, formik.values);

    const { handleChange, handleSubmit, touched, errors, values, setFieldValue, getFieldProps, setTouched } = formik;

    useEffect(() => {
        const isFormFilled = values.newTagName;

        setIsFormValid(isFormFilled);
    }, [values.newTagName]);

    const getfunction = () => {
        const onGetTagDetails = async () => {
            try {
                setGetTagsDetailsProcessing(true);
                const {
                    data: { tag }
                } = await getTagDetails(idFromUrl);
                setSelectedConversationTag(tag);
            } finally {
                setGetTagsDetailsProcessing(false);
            }
        };

        !!idFromUrl && onGetTagDetails();
    };

    useEffect(() => {
        getfunction();
    }, []);

    useEffect(() => {
        if (selectedConversationTag) {
            setFieldValue("newTagName", selectedConversationTag.name);
            setFieldValue("tagDescription", selectedConversationTag.description);
        }
    }, [selectedConversationTag]);

    const onDeleteClick = async () => {
        await dispatch(deleteTag(idFromUrl));
    };

    useEffect(() => {
        const breadCrumb = isEdit && selectedConversationTag ? selectedConversationTag.name : "New Tag";
        let topBarProps;
        if (!getTagsDetailsProcessing) {
            topBarProps = {
                leftElement: (
                    <BreadCrumbs
                        breadCrumbsList={[{ text: "Tags", pathname: "/account-settings/tags" }, { text: breadCrumb }]}
                        history={history}
                        toggleButtonProps={{
                            onToggle: () => dispatch(onToggleRightPanel()),
                            onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                        }}
                    />
                ),
                rightElement: !isEdit ? (
                    <StyledButton
                        className="actions-button"
                        type={StyledButtonTypes.primarySimple}
                        IconComponent={CloseIcon}
                        onClick={() => history.push("/account-settings/tags")}
                    />
                ) : (
                    <StyledAlert
                        Target={(props) => <StyledButton {...props} type={StyledButtonTypes.delete} text={"Delete"} />}
                        type={StyledAlertTypes.warning}
                        confirmButtonProps={{
                            type: StyledButtonTypes.delete
                        }}
                        confirmButtonText={"Delete"}
                        cancelButtonText={"Cancel"}
                        onConfirm={onDeleteClick}
                        globalContentProps={{
                            title: "Delete Tags",
                            description: "Are you sure you want to delete this Tag ?"
                        }}
                    />
                )
            };
        } else {
            topBarProps = null;
        }

        dispatch(onUpdateToolBar(topBarProps));
    }, [getTagsDetailsProcessing, values.newTagName, values?.selectedTag, values.tagDescription, createTagProcessing]);

    const onSaveClick = async (values) => {
        try {
            setCreatingTagProcessing(true);

            const data = {
                name: values.newTagName,
                description: values.tagDescription,
                parent_id: values.selectedTag?.id,
                enable_for_conversations: values?.enable_for_conversations,
                enable_for_contacts: values?.enable_for_contacts
            };
            let res;
            if (isEdit) {
                res = await updateTag(idFromUrl, data);
            } else {
                res = await createTag(data);
            }
            setSelectedConversationTag(res);
            const {
                data: { tag }
            } = await getTags({ offset: allIds.length });
            await setHasMore(tag);

            // history.push("/account-settings/tags");
        } finally {
            setCreatingTagProcessing(false);
        }
    };

    // const onOpenCloseTagsDropdown = (status: boolean) => {
    //     if (status) {
    //         setTouched({ ...touched, selectedTag: true });
    //     }
    //     setIsPopoverTagsOpen(status);
    // };

    const onClickTag = (tag) => {
        setSelectedTag(tag);
        // onOpenCloseTagsDropdown(false);
        setFieldValue("selectedTag", tag);
    };

    const onGetTags = async (params) => {
        const res = await getTags(params);
        return {
            ...res,
            data: {
                ...res.data,
                tags: selectedConversationTag.id
                    ? res.data.tags.filter(
                          (tag) => tag.id !== selectedConversationTag.id && !tag.ancestors_formatted.length
                      )
                    : res.data.tags
            }
        };
    };

    if (getTagsDetailsProcessing && isEdit) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[`${classname}-main-container`]}>
            <form onSubmit={handleSubmit}>
                <div className={styles[classname]}>
                    <FormSectionTitle title={"TAG DETAILS"} />
                    <section
                        className={classNames(
                            styles[`${classname}-name-and-tag-to-nest-wrapper`]
                            // Classes.CONTAINER_INPUTS_ROW_GAP
                        )}
                    >
                        <div className={styles[`${classname}-input-wrapper`]}>
                            <StyledFloatingInput
                                autoCapitalize="none"
                                autoCorrect="none"
                                floatingType={StyledFloatingInputTypes.primary}
                                value={values.newTagName}
                                onChange={handleChange}
                                placeholder="TAG NAME"
                                tabIndex={1}
                                isMaxLengthRequire={false}
                                showErrorText={true}
                                error={touched.newTagName && errors.newTagName}
                                {...getFieldProps("newTagName")}
                            />
                        </div>
                        <div
                            className={`${styles[`${classname}-input-wrapper`]} ${
                                styles[`${classname}-categories-search-wrapper`]
                            }`}
                        >
                            <Dropdown
                                title={"NEST TAG UNDER PARENT TAG"}
                                position={Position.BOTTOM}
                                ArrowIconComponent
                                targetClassName={DropdownTargetClasses.FORM_SELECT}
                                fetchDataRequest={onGetTags}
                                contentBlockHeight={300}
                                fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                                entityKey="tags"
                                selectedValue={
                                    formik?.values?.selectedTag
                                        ? {
                                              label: formik?.values?.selectedTag?.name,
                                              value: formik?.values?.selectedTag
                                          }
                                        : { label: "Select Parent Tag", value: undefined }
                                }
                                onStaticOptionClick={onClickTag}
                            />
                        </div>
                        <div className={styles[`${classname}-text-input-wrapper`]}>
                            <StyledTextArea
                                autoCapitalize="none"
                                autoCorrect="none"
                                type={StyledTextAreaTypes.primaryStatic}
                                placeholder="description"
                                tabIndex={1}
                                rows={5}
                                isMaxLengthRequire={false}
                                value={values.tagDescription}
                                showErrorText={true}
                                error={touched.tagDescription && errors.tagDescription}
                                {...getFieldProps("tagDescription")}
                                onChange={(e) => formik.setFieldValue("tagDescription", e.target.value)}
                            />
                        </div>
                    </section>
                    {(conversations_enabled || contacts_enabled) && (
                        <section className={styles["tag-settings-wrapper"]}>
                            <FormSectionTitle title={"TAG SETTINGS"} />
                            <section
                                className={classNames(styles["checkboxes-wrapper"], Classes.CONTAINER_INPUTS_ROW_GAP)}
                            >
                                {conversations_enabled && (
                                    <StyledCheckbox
                                        {...formik.getFieldProps("enable_for_conversations")}
                                        checked={formik.values.enable_for_conversations}
                                        disabled={!!selectedConversationTag?.conversations_count}
                                    >
                                        <div className={styles["checkbox-description"]}>Enable for Conversations</div>
                                    </StyledCheckbox>
                                )}
                                {contacts_enabled && (
                                    <StyledCheckbox
                                        {...formik.getFieldProps("enable_for_contacts")}
                                        checked={formik.values.enable_for_contacts}
                                        disabled={!!selectedConversationTag?.contacts_count}
                                    >
                                        <div className={styles["checkbox-description"]}>Enable for Contacts</div>
                                    </StyledCheckbox>
                                )}
                            </section>
                        </section>
                    )}
                </div>
            </form>
            {isEdit && <FormWasChanged formik={formik} />}
            {!isEdit && (
                <StyledDuoButtons
                    className={styles[`${classname}-footer`]}
                    firstButtonClick={() => {
                        history.replace("/account-settings/tags");
                        setSelectedConversationTag(null);
                    }}
                    secondButtonClick={() => onSaveClick(formik.values)}
                    firstButtonText="Cancel"
                    secondButtonText="Save"
                    secondButtonProps={{ processing: createTagProcessing, disabled: !isFormValid }}
                    type={StyledDuoButtonsTypes.primary}
                />
            )}
        </div>
    );
};
