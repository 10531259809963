// import * as firebase from "firebase/app";
// import "firebase/auth";
import { Dispatch } from "redux";

import { LoginState } from "../../containers";
import { validateEmail } from "..";

import { refreshAxiosInstance, createAxiosInstance } from "../common";

import {
    FORGOT_PASSWORD_CHANGED,
    LOGIN_STATE_ACTION,
    LOGIN_USER,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    PASSWORD_CHANGED,
    USERNAME_CHANGED,
    REMEMBER_ME_CHANGED,
    ONE_TIME_PASSWORD_CHANGED,
    LOGIN_USER_SUCCESS,
    OTP_CHANNEL_CHANGED
} from "./types";
import {
    postLoginRestoreRequest,
    postAuthRequest,
    AuthLoginResponse,
    postAskOneTimePasswordRequest
} from "../../requests";
import { AppState, UserSettingsState, AccountSettingsState } from "../../store";
import { AxiosResponse } from "axios";
import { loadInitialData } from "../global/global-api";

export const onUsernameChanged = (text: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: USERNAME_CHANGED, payload: text });
    };
};

export const onPasswordChanged = (text: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: PASSWORD_CHANGED, payload: text });
    };
};

export const onOneTimePasswordChanged = (text: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: ONE_TIME_PASSWORD_CHANGED, payload: text });
    };
};

export const onChangeOTPChannel = (channel: "email" | "phone") => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: OTP_CHANNEL_CHANGED, payload: channel });
    };
};

export const onForgotPasswordChanged = (text: string) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: FORGOT_PASSWORD_CHANGED, payload: text });
    };
};

export const onLoginAction = (username: string, password: string, remember_me: boolean) => {
    return async (dispatch: Dispatch<AppState>) => {
        if (!password.length) {
            return dispatch(loginUserFail("Enter a valid Password"));
        }

        const res = await postAuthRequest({
            username,
            password,
            remember_me
        });

        dispatch({ type: LOGIN_USER });
        dispatch(onSuccessAuth(username, res));
    };
};

export const sendAskOneTimePassword = (channel: "email" | "phone") => {
    return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const state = getState();
        const { auth } = state;
        const otpToken = auth.otpTokenValue;

        const res = await postAskOneTimePasswordRequest({
            otp_token: otpToken,
            otp_channel: channel || "email"
        });

        if (res.data.status === "ok") {
            dispatch({
                type: LOGIN_STATE_ACTION,
                payload: LoginState.EnteringOneTimePassword
            });
        }
    };
};

export const onSendOneTimePasswordAction = (oneTimePasswordValue: string) => {
    return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        if (!oneTimePasswordValue.length) {
            return dispatch(loginUserFail("Enter a valid Code"));
        }

        const state = getState();
        const { auth } = state;
        const { usernameValue, passwordValue, otpTokenValue, rememberMe } = auth;

        const res = await postAuthRequest({
            username: usernameValue,
            password: passwordValue,
            otp_token: otpTokenValue,
            otp: oneTimePasswordValue,
            remember_me: rememberMe
        });

        dispatch({ type: LOGIN_USER });
        dispatch(onSuccessAuth(usernameValue, res));
    };
};

export const logoutUserAction = () => {
    return (dispatch: Dispatch<AppState>) => {
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        refreshAxiosInstance();
        dispatch({ type: LOGOUT_USER });
    };
};

export const onLoginStateAction = (loginState: LoginState) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: LOGIN_STATE_ACTION, payload: loginState });
    };
};

export const onRememberMeChanged = (value: boolean) => {
    return (dispatch: Dispatch<AppState>) => {
        dispatch({ type: REMEMBER_ME_CHANGED, payload: value });
    };
};

export const onResetAction = (email: string) => {
    return async (dispatch: Dispatch<AppState>) => {
        if (!validateEmail(email)) {
            return dispatch(loginUserFail("Enter a Valid Email"));
        }

        const updatedObj = {
            email
        };

        await postLoginRestoreRequest(updatedObj);

        dispatch({
            type: LOGIN_STATE_ACTION,
            payload: LoginState.ThankYou
        });
    };
};

export const onNextAction = (email: string) => {
    return (dispatch: Dispatch<AppState>) => {
        if (!validateEmail(email)) {
            return dispatch(loginUserFail("Enter a Valid Email"));
        }

        dispatch({
            type: LOGIN_STATE_ACTION,
            payload: LoginState.EnteringPassword
        });
    };
};

export const loginUserFail = (error: string) => (dispatch: Dispatch<AppState>) => {
    dispatch({
        type: LOGIN_USER_ERROR,
        payload: error
    });
};

const loginUserSuccess =
    (
        auth: string,
        username: string,
        rememberMe: boolean,
        accountSettings: AccountSettingsState,
        userSettings: UserSettingsState
    ) =>
    async (dispatch: Dispatch<AppState>) => {
        localStorage.setItem("auth", auth);
        if (rememberMe) {
            localStorage.setItem("username", username);
        } else {
            localStorage.removeItem("username");
        }
        createAxiosInstance(auth);
        await dispatch(loadInitialData());
        dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: userSettings
        });
    };

const onSuccessAuth =
    (username: string, res: AxiosResponse<AuthLoginResponse>) =>
    (dispatch: Dispatch<AppState>, getState: () => AppState) => {
        const state = getState();
        const { auth, accountSettings, userSettings } = state;
        const { data } = res;
        const { jwt } = data;
        const { rememberMe } = auth;

        dispatch(loginUserSuccess(jwt, username, rememberMe, accountSettings, userSettings));
    };
