import { MATCH_COMPARISON_VALUE_EDITABLE } from "./contacts-list/components/filter-dropdown-content/contastants";

export const getFittedFilters = (filters: string | object) => {
    if (!!filters) {
        try {
            const match = JSON.parse(localStorage.getItem("contactsFiltersData")) || {};
            const parsed = typeof filters === "object" ? filters : JSON.parse(atob(filters));
            if (parsed.conditions.length === 0) {
                return null;
            }
            const converted = {
                ...parsed,
                conditions: parsed.conditions.map((condtionsGroup) => {
                    return {
                        ...condtionsGroup,
                        conditions: condtionsGroup.conditions.map((condition) => {
                            return {
                                ...condition,
                                ...match?.[condition.field],
                                isComparisonValueEditable: MATCH_COMPARISON_VALUE_EDITABLE[condition.comparison]
                            };
                        })
                    };
                })
            };
            return converted;
        } catch (err) {
            console.log("ERR CONVERT FILTERS >>>>>", err);
            return {};
        }
    } else {
        return {};
    }
};
