import { useState, useMemo, useRef, useEffect } from "react";
import classNames from "classnames";
import { Text, Collapse } from "@blueprintjs/core";
import { MPDIcon } from "../../../../mpd-library";
import styles from "./CollapseWithTitle.module.scss";
import { ReactComponent as DownArrowIcon } from "../../../../mpd-library/icon/assets/down-arrow.svg"

export const useFocusWithin = (element: HTMLElement | undefined, onFocusIn?: () => void, onFocusOut?: () => void) => {
    const [focusWithin, setFocusWithin] = useState(false);
    const isLoosingFocusFlag = useRef(false);

    useHtmlElementEventListener(element, "focusin", () => {
        setFocusWithin(true);
        onFocusIn?.();
        if (isLoosingFocusFlag.current) {
            isLoosingFocusFlag.current = false;
        }
    });

    useHtmlElementEventListener(element, "focusout", (e) => {
        isLoosingFocusFlag.current = true;
        setTimeout(() => {
            if (isLoosingFocusFlag.current) {
                onFocusOut?.();
                isLoosingFocusFlag.current = false;
                setFocusWithin(false);
            }
        });
    });

    return focusWithin;
};

export const useHtmlElementEventListener = <K extends keyof HTMLElementEventMap>(
    element: HTMLElement | undefined,
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any
) => {
    useEffect(() => {
        if (element) {
            element.addEventListener(type, listener as any);
            return () => element.removeEventListener(type, listener as any);
        }
    }, [element, listener, type]);
};

export const CollapseWIthTitle = ({ children, title, defaultOpen, AddDropdown, index }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
    const ref = useRef();

    // const focusWithin = ref?.current?.contains(document.activeElement);

    const onTitleClick = () => {
        setIsOpen(!isOpen);
    };
    // const css = useMemo(() => ({ zIndex: 4 - index }), [index]);

    // ref?.current?.contains(document.activeElement);

    // useEffect(() => {
    //     ref?.current?.addEventListener("focusin", () => console.log("IIIIII", title, "focusin"));
    //     ref?.current?.addEventListener("focusout", () => console.log("IIIIII", title, "focusout"));
    // }, []);

    return (
        <div
            className={styles["root"]}
            ref={ref}
            onFocus={() => {
                setTimeout(() => {
                    setIsFocused(true);
                }, 300);
            }}
            onBlur={() => {
                setIsFocused(false);
            }}
            // style={{ ...(isFocused ? { zIndex: 10 } : {}) }}
        >
            <div className={classNames(styles["top-wrapper"], isOpen && styles["top-wraper_expanded"])}>
                <div className={styles["top-wrapper__left-wrapper"]} onClick={onTitleClick}>
                    <MPDIcon IconComponent={DownArrowIcon} className={styles["down-arrow"]} />
                    <Text className={styles["title"]}>{title}</Text>
                </div>
                {AddDropdown && (
                    <AddDropdown
                        isSectionExpanded={isOpen}
                        onTitleClick={() => {
                            if (!isOpen) {
                                setIsOpen(!isOpen);
                            }
                        }}
                    />
                )}
            </div>
            <Collapse isOpen={isOpen}>
                <div className={styles["collapse-wrapper"]}>{children}</div>
            </Collapse>
        </div>
    );
};
