import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { EmptyBlock } from "src/components";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/store";
import { useDataFetch } from "../../../../../hooks/useDataFetch";
import { getApiLog } from "../../../../../requests";
import styles from "./styles.module.scss";
import { ApiLogItem, Header } from "../api-request-log-item/ApiRequestLogItem";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import { ReactComponent as ProgrammingBrowserThinIcon } from "src/mpd-library/icon/assets/programmingBrowserThin.svg";

const classname = "api-request-log";

export const ScrollingContext = createContext({});

export const ApiRequestLog = () => {
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [logListLoading, setLogListLoading] = useState<boolean>(true);
    const newApiRequest = useSelector((state: AppState) => state.accountSettings.newApiKeysRecord);
    const [isScrolling, setIsScrolling] = useState(false);

    const {
        entities: logList,
        updateEntites,
        setAtBottomOfList
    } = useDataFetch({
        listItemHeight: 60,
        entityKey: "requests",
        skeletonLoading: true,
        fetchDataRequest: getApiLog
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (newApiRequest) {
            updateEntites({ [newApiRequest.id]: newApiRequest });
        }
    }, [newApiRequest?.id]);

    const computeItemKey = useCallback(
        (index) => {
            return logList.allIds?.[index]?.id;
        },
        [logList.allIds]
    );

    return (
        <div className={styles[classname]}>
            <div className={styles[`${classname}-list`]}>
                <ScrollingContext.Provider
                    value={{ isScrolling, logList, expandedItemId, newApiRequest, setExpandedItemId }}
                >
                    <AnimatePresence initial={false}>
                        <LayoutGroup>
                            {/* <Header /> */}

                            <Virtuoso
                                style={{ height: "100%", width: "100%" }}
                                className={logList.allIds.length === 0 && styles["no-results"]}
                                totalCount={logList.allIds.length}
                                isScrolling={setIsScrolling}
                                components={{
                                    Header: Header,
                                    Item: ApiLogItem
                                }}
                                atBottomStateChange={setAtBottomOfList}
                            />
                            {!logList.allIds.length && !logListLoading && (
                                <EmptyBlock
                                    IconComponent={ProgrammingBrowserThinIcon}
                                    title="Call Logs Empty"
                                    subtitle="It looks like you haven’t started sending any data to the Atlas One API Yet. Once you begin sending data, you’ll see a history of your call logs here."
                                />
                            )}
                        </LayoutGroup>
                    </AnimatePresence>
                </ScrollingContext.Provider>
            </div>
        </div>
    );
};
