// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import { IProps, Text } from "@blueprintjs/core";

import { SettingsTitle, SubscriptionAddonsBox } from "src/components";

import { Classes } from "src/mpd-library";

import { SubscriptionBox, SubscriptionBoxContent } from "../components";

import "./styles.scss";
import { AppState, AccountSettingsSubscriptionOverviewState } from "src/store";
import { ReactComponent as PhoneBlueIcon } from "../../../../../mpd-library/icon/assets/phone-blue.svg"
import { ReactComponent as SmsBlueIcon } from "../../../../../mpd-library/icon/assets/sms-blue.svg"
import { ReactComponent as GroupBlueIcon } from "../../../../../mpd-library/icon/assets/group-blue.svg"
import { ReactComponent as FavouriteBlueIcon } from "../../../../../mpd-library/icon/assets/favourite-blue.svg"

export interface ISubscriptionSettingsOverviewProps extends IProps {
    overview: AccountSettingsSubscriptionOverviewState;
}

interface ISubscriptionSettingsOverview {
    essentialList: Array<string>;
    proList: Array<string>;
    premiumList: Array<string>;
}

const settingsLists: ISubscriptionSettingsOverview = {
    essentialList: [
        "iOS & Android Apps",
        "Unlimited Geo-Fenced Alerts",
        "Attachments & Multimedia",
        "Social Media Integrations"
    ],
    proList: [
        "Public Alert Groups",
        "IPAWS Integration",
        "Analytics",
        "Reporting & Two-Way Chat",
        "Crime Tips, Help Desk, 311",
        "Unlimited Cards"
    ],
    premiumList: [
        "Private Alert Groups",
        "Private Cards",
        "Advanced Analytics & Reporting",
        "On-Demand NG911",
        "Triggers",
        "FOIA & CJIS Compliance",
        "Audit Log",
        "Training"
    ]
};

class SubscriptionSettingsOverview extends React.Component<ISubscriptionSettingsOverviewProps, {}> {
    public render() {
        const { className, overview } = this.props;
        const { essentialList, proList, premiumList } = settingsLists;
        const classes = "subscription-settings-overview";

        return (
            <div className={classNames(classes, Classes.CONTAINER_PADDING, className)}>
                <div
                    className={classNames(
                        classes + "-subscription-box-container",
                        Classes.ROW_WRAP,
                        Classes.RESPONSIVE
                    )}
                >
                    <SubscriptionBox
                        className={classes + "-subscription-box"}
                        active={overview.currentPlan === "essential"}
                        title="essential"
                        subtitle="1 Administrator account"
                        description={
                            <div className={classes + "-subscription-box-description"}>
                                <Text className={classes + "-subscription-box-description-title"}> 1 </Text>
                                <Text className={classes + "-subscription-box-description-subtitle"}> User </Text>
                            </div>
                        }
                        content={
                            <SubscriptionBoxContent
                                className={classes + "-subscription-box-content"}
                                title="Features:"
                                list={essentialList}
                                actionProps={
                                    overview.currentPlan === "essential"
                                        ? {
                                              text: "CURRENT PLAN"
                                          }
                                        : {
                                              text: "FREE 30 DAY TRIAL",
                                              type: "primary"
                                          }
                                }
                            />
                        }
                    />
                    <SubscriptionBox
                        className={classes + "-subscription-box"}
                        active={overview.currentPlan === "pro"}
                        title="pro"
                        subtitle="10 Administrator accounts"
                        description={
                            <div className={classes + "-subscription-box-description"}>
                                <Text className={classes + "-subscription-box-description-title"}> 10 </Text>
                                <Text className={classes + "-subscription-box-description-subtitle"}> Users </Text>
                            </div>
                        }
                        content={
                            <SubscriptionBoxContent
                                className={classes + "-subscription-box-content"}
                                title="All of Essential Plus:"
                                list={proList}
                                actionProps={
                                    overview.currentPlan === "pro"
                                        ? {
                                              text: "CURRENT PLAN"
                                          }
                                        : {
                                              text: "FREE 30 DAY TRIAL",
                                              type: "primary"
                                          }
                                }
                            />
                        }
                    />
                    <SubscriptionBox
                        className={classes + "-subscription-box"}
                        active={overview.currentPlan === "premium"}
                        title="premium"
                        subtitle="25 Administrator accounts"
                        description={
                            <div className={classes + "-subscription-box-description"}>
                                <Text className={classes + "-subscription-box-description-title"}> 25 </Text>
                                <Text className={classes + "-subscription-box-description-subtitle"}> Users </Text>
                            </div>
                        }
                        content={
                            <SubscriptionBoxContent
                                className={classes + "-subscription-box-content"}
                                title="All of Pro Plus:"
                                list={premiumList}
                                actionProps={
                                    overview.currentPlan === "premium"
                                        ? {
                                              text: "CURRENT PLAN"
                                          }
                                        : {
                                              text: "FREE 30 DAY TRIAL",
                                              type: "primary"
                                          }
                                }
                            />
                        }
                    />
                </div>
                <div className={classNames(classes + "-addons")}>
                    <SettingsTitle title="ADD-ONS" />
                    <div className={classNames(classes + "-addons-container", Classes.ROW_WRAP, Classes.RESPONSIVE)}>
                        <SubscriptionAddonsBox IconComponent={PhoneBlueIcon} title="Voice" subtitle="$.04/CALL" />
                        <SubscriptionAddonsBox IconComponent={SmsBlueIcon} title="SMS" subtitle="$.02/TEXT" />
                        <SubscriptionAddonsBox IconComponent={GroupBlueIcon} title="Admin Users" subtitle="$750/User" />
                        <SubscriptionAddonsBox IconComponent={FavouriteBlueIcon} title="Branded App" subtitle="$5,000 Set Up" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings } = state;
    const overview = accountSettings.organization.subscription.overview;
    return {
        overview
    };
};

export default connect(mapStateToProps, {})(SubscriptionSettingsOverview);
