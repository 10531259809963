import { Text } from "@blueprintjs/core";

import { Classes } from "../../../../mpd-library";
import styles from "./NotesCard.module.scss";
import moment from "moment";
import classNames from "classnames";

moment.defineLocale("en-cust1", {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        ss: "%d secs",
        m: "a min ago",
        mm: "%d mins ago",
        h: "an hour",
        hh: "%dh ago",
        d: "a day ago",
        dd: "%d days ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
    }
});

export const NotesCard = ({ note }) => {
    return (
        <div className={classNames(Classes.CARD_BOX_SHADOW, styles["root"])}>
            <div className={styles["top-wrapper"]}>
                <Text className={styles["note-by"]}>
                    <span>Note by</span>
                    {" " + note.created_by_admin.first_name + " " + note.created_by_admin.last_name}
                </Text>
                <Text className={styles["date"]}>{moment(note.inserted_at).locale("en-cust1").fromNow()}</Text>
            </div>
            <Text className={styles["note-value"]}>{note.note}</Text>
        </div>
    );
};
