import { Text } from "@blueprintjs/core";
import { useEffect, useMemo, useState } from "react";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { MPDIcon, MPDSelectItem } from "src/mpd-library";
import { getSnippetsCategories } from "src/requests/conversationsRequests";
import { SnippetCategory } from "src/store";
import styles from "./styles.module.scss";
import { ReactComponent as PlusRoundedIcon } from "src/mpd-library/icon/assets/plus-rounded.svg"

const classname = "categories-dropdown";

type CategoriesDropdownProps = {
    searchValue: string;
    selectedCategoryId: string;
    setIsEnterCategoryNameModalOpen: (value: boolean) => void;
    onClickItem: (value: string, categoryId: string) => void;
};

export const CategoriesDropdown = ({
    searchValue,
    selectedCategoryId,
    setIsEnterCategoryNameModalOpen,
    onClickItem
}: CategoriesDropdownProps) => {
    const [getCategoriesProcessing, setGetCategoriesProcessing] = useState(false);
    const [allCategories, setAllCategories] = useState<any>([]);

    useEffect(() => {
        setGetCategoriesProcessing(true);
        const asyncGetSnippetsCategories = async () => {
            try {
                const {
                    data: { snippet_categories }
                } = await getSnippetsCategories();
                setAllCategories(snippet_categories);
            } finally {
                setGetCategoriesProcessing(false);
            }
        };

        asyncGetSnippetsCategories();
    }, []);

    const categories = useMemo(() => {
        if (searchValue) {
            return allCategories.filter((category: SnippetCategory) => {
                return category.name.toLowerCase().includes(searchValue.toLocaleLowerCase());
            });
        }
        return allCategories;
    }, [searchValue, allCategories]);

    const onClickAddCategory = async () => {
        setIsEnterCategoryNameModalOpen(true);
    };

    return (
        <div className={styles[classname]}>
            <>
                {getCategoriesProcessing && <PageLoadingSpinner />}
                {!!categories.length && !getCategoriesProcessing && (
                    <>
                        <Text className={styles[`${classname}-list-subtitle`]}>SELECT EXISTING CATEGORY</Text>
                        {categories.map((category: SnippetCategory) => {
                            return (
                                <MPDSelectItem
                                    className={styles[`${classname}-item`]}
                                    onClick={() => {
                                        onClickItem(category.name, category.id);
                                    }}
                                    key={category.id}
                                    selected={category.id === selectedCategoryId}
                                >
                                    <Text>{category.name}</Text>
                                </MPDSelectItem>
                            );
                        })}
                    </>
                )}
                {!getCategoriesProcessing && (
                    <>
                        <Text className={styles[`${classname}-list-subtitle`]}>ADD NEW CATEGORY</Text>
                        <MPDSelectItem className={styles[`${classname}-add-cat-wrapper`]} onClick={onClickAddCategory}>
                            <>
                                <div className={styles[`${classname}-add-category-item`]}>
                                    <div className={styles[`${classname}-plus-wrapper`]}>
                                        <MPDIcon IconComponent={PlusRoundedIcon} />
                                    </div>
                                    <div className={styles[`${classname}-add-category-item-left-wrapper`]}>
                                        <Text className={styles[`${classname}-title`]}>Create New Category</Text>
                                    </div>
                                </div>
                            </>
                        </MPDSelectItem>
                    </>
                )}
            </>
        </div>
    );
};
