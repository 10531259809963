import React, { FunctionComponent, SVGProps } from "react";
import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import { memo, useMemo } from "react";
import { MPDIcon } from "src/mpd-library";
import styles from "./styles.module.scss";

type RoundedIconProps = {
    iconName?: string;
    size: number;
    src?: string;
    backgroundColor?: string;
    className?: string;
    name?: string;
    IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
};

const classname = "rounded-icon";

const DEFAULT_INITIALS_FONT_SIZE = 19;
const DEFAULT_INITILAS_LINE_HEIGHT = 18;
const DEFAULT_CIRCLE_SIZE = 60;

export const RoundedIcon = memo(({ iconName, size, src, backgroundColor, className, name, IconComponent }: RoundedIconProps) => {
    const initials = useMemo(() => {
        if (name) {
            const nameSplit = name?.split(" ");
            return `${nameSplit[0].charAt(0).toUpperCase()}${nameSplit[1]?.charAt(0).toUpperCase() || ""}`;
        }
        return null;
    }, [name]);

    if (backgroundColor) {
        return (
            <div
                className={classNames(styles[classname], className)}
                style={{
                    backgroundColor,
                    width: `${size}px`,
                    maxWidth: `${size}px`,
                    height: `${size}px`,
                    minWidth: `${size}px`
                }}
            >
                {(iconName || IconComponent) && <MPDIcon IconComponent={IconComponent} name={iconName} className={iconName} />}
                {initials && !iconName && (
                    <Text
                        className={styles["initials"]}
                        style={{
                            fontSize: (DEFAULT_INITIALS_FONT_SIZE * size) / DEFAULT_CIRCLE_SIZE,
                            lineHeight: (DEFAULT_INITILAS_LINE_HEIGHT * size) / DEFAULT_CIRCLE_SIZE
                        }}
                    >
                        {initials}
                    </Text>
                )}
            </div>
        );
    }

    if (src) {
        return (
            <img
                src={src}
                className={classNames(styles[classname], className)}
                style={{ width: `${size}px`, height: `${size}px` }}
            />
        );
    }

    return <MPDIcon IconComponent={IconComponent} name={iconName} width={size} height={size} className={classNames(styles[classname], className)} />;
});
