import { useContext, useMemo } from "react";
import { Position } from "@blueprintjs/core";
import { Dropdown, ICreateNewEntityProps } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import {
    TCreateContactsListRequestParams,
    createContactsList,
    getContactsLists
} from "../../../../../requests/contactsRequests";
import { GroupedDropdownContent } from "../../../../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import { CustomOptionsComponentProps } from "../../../../../mpd-library/dropdown/dropdown";
import { SummaryContext } from "../../../contacts";
import { ReactComponent as SearchIcon } from "src/mpd-library/icon/assets/search.svg";
import { Tag } from "../../../../../components";

const fetchDataParams = {
    type: "static"
};

export const StaticListsDropdown = ({ apply, ...remain }) => {
    const { setSummary, summary } = useContext(SummaryContext);

    const createNewListProps = useMemo<ICreateNewEntityProps<TCreateContactsListRequestParams>>(
        () => ({
            create: createContactsList,
            entityName: "list",
            onCreateNewEntityClick: (value) => {
                const updatedSummary = { ...summary, lists: [...summary.lists, value] };
                setSummary(updatedSummary);
            }
        }),
        [createContactsList]
    );

    return (
        <Dropdown
            {...remain}
            transitionDuration={300}
            targetClassName={DropdownTargetClasses.FORM_SELECT}
            withSearch
            title={"ADD TO LIST"}
            LeftIconComponent={SearchIcon}
            searchInputPlaceholder={"Search for lists..."}
            position={Position.BOTTOM}
            entityKey={"lists"}
            fetchDataRequest={getContactsLists}
            fetchDataParams={fetchDataParams}
            contentBlockHeight={320}
            values={remain.value || []}
            SelectedItemComponent={({ item: list, remove }) => (
                <Tag label={list.name} onDeleteClick={() => remove(list)} />
            )}
            CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => {
                return (
                    <GroupedDropdownContent
                        {...props}
                        secondGroupName={"SELECT LISTS"}
                        firstGroupName={"Existing lists"}
                    />
                );
            }}
            ArrowIconComponent
            createNewEntityProps={createNewListProps}
        />
    );
};
