import Prism from "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-json";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import {
    StyledTabs,
    StyledTabsTypes,
    TAB_UNDERLINED_WITH_YELLOW_CLASSNAME,
    TAB_WITH_DOT_CLASSNAME
} from "src/components";
import { PrismCode } from "src/components/prism-code/prism-code";
import { formatJson } from "src/actions/common/formatJsonUtils";
import classNames from "classnames";

const classname = "json-output";

export const JsonOutput = ({ responseBody, payload, warnings }) => {
    const [selectedTabId, setSelectedTabId] = useState(0);
    useEffect(() => {
        Prism.highlightAll();
    }, [selectedTabId]);

    const code = useMemo(() => {
        try {
            const res = JSON.stringify(JSON.parse(selectedTabId === 0 ? payload : responseBody), null, 2);
            return res;
        } catch (err) {
            return formatJson(selectedTabId === 0 ? payload : responseBody);
        }
    }, [responseBody, payload, selectedTabId]);

    const onSelectTab = (tabId) => {
        setSelectedTabId(tabId);
    };

    return (
        <div className={styles[classname]}>
            <div className={styles[`${classname}-tabs-wrapper`]}>
                <StyledTabs
                    type={StyledTabsTypes.freeBlue}
                    className={"tabs"}
                    tabs={[
                        { title: "PAYLOAD" },
                        { title: "RESPONSE" },
                        ...(warnings?.length
                            ? [
                                  {
                                      title: "WARNINGS",
                                      className: classNames(
                                          TAB_UNDERLINED_WITH_YELLOW_CLASSNAME,
                                          TAB_WITH_DOT_CLASSNAME
                                      )
                                  }
                              ]
                            : [])
                    ]}
                    tabsProps={{
                        id: "response-tabs",
                        onChange: onSelectTab,
                        selectedTabId: selectedTabId
                    }}
                    panel={
                        <div className={styles[`${classname}-panel-wrapper`]}>
                            {selectedTabId !== 2 ? (
                                <PrismCode code={code} language="json" plugins={["line-numbers"]} />
                            ) : (
                                <ul className={styles[`${classname}-warnings-list`]}>
                                    {warnings?.map((warning) => {
                                        return <li className={styles[`${classname}-warnings-list-item`]}>{warning}</li>;
                                    })}
                                </ul>
                            )}
                        </div>
                    }
                />
            </div>
        </div>
    );
};
