// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions } from "src/actions";

import { Redirect, Route, Switch } from "react-router-dom";

import { TabId, Text } from "@blueprintjs/core";

import { SelectListAdd } from "../../../components";

import TeamsSettingsAdd from "../teams-settings-add/teams-settings-add";

import { IDetailView } from "src/containers";

import {
    StyledAlert,
    StyledAlertTypes,
    StyledAlertGlobalContent,
    BreadCrumbs,
    ContainerEmpty,
    SimpleList,
    StyledButton,
    StyledTabs,
    StyledTabsTypes,
    StyledButtonTypes
} from "src/components";

import { SettingsGroupsListItem } from "../../../components";

import "./styles.scss";
import { AppState, AccountSettingsUserState } from "src/store";
import { bindActionCreators, Dispatch } from "redux";
import { onConfirmInviteTeamMembers } from "src/actions/account-settings/account-settings-api";
import { ReactComponent as GroupBlueIcon } from "../../../../../mpd-library/icon/assets/group-blue.svg"

interface ITeamsSettingsEditProps extends IDetailView {
    users: Array<AccountSettingsUserState>;
    currentTeam: any;
    onConfirmInviteTeamMembers: (invitedUsers: Array<any>, id: string) => void;
    // onViewUser: (history: History, currentUser: any) => void;
}

interface ITeamsSettingsEditState {
    currentTeam: any;
    invitedMembers: Array<string>;
    selectedTabNum: TabId;
    tabs: Array<any>;
    showAlert: boolean;
    listHeaderChecked: boolean;
}

class TeamsSettingsEdit extends React.Component<ITeamsSettingsEditProps, ITeamsSettingsEditState> {
    constructor(props: ITeamsSettingsEditProps) {
        super(props);

        const { currentTeam } = this.props;

        this.state = {
            currentTeam,
            invitedMembers: [],
            listHeaderChecked: false,
            selectedTabNum: 0,
            tabs: ["members", "settings"],
            showAlert: false
        };

        this.updateToolBar();
    }

    public componentDidUpdate(prevProps: ITeamsSettingsEditProps) {
        if (prevProps.routeProps !== this.props.routeProps) {
            this.updateToolBar();
        }
    }

    public onCancelClickHandler = () => {
        this.setState({ showAlert: false, invitedMembers: [] });
    };

    public render() {
        const { className, routeProps, users } = this.props;
        const { selectedTabNum, showAlert, currentTeam, invitedMembers, tabs } = this.state;
        const classes = classNames("teams-settings-edit", className);

        return (
            <div className={classes}>
                <StyledTabs
                    className={classes + "-tabs"}
                    tabs={tabs}
                    type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                    tabsProps={{
                        id: "social-settings-tabs",
                        onChange: this.onChangeTab,
                        selectedTabId: selectedTabNum
                    }}
                />
                <StyledAlert
                    className={classNames(classes + "-alert")}
                    type={StyledAlertTypes.primary}
                    isOpen={showAlert}
                    onConfirm={this.onConfirmInviteTeamMembers}
                    onCancel={this.onCancelClickHandler}
                    cancelButtonText="Cancel"
                    confirmButtonText="Okay"
                    canOutsideClickCancel={true}
                    globalContentProps={{
                        title: "Add Team Members",
                        onCancel: this.onCancelClickHandler
                    }}
                >
                    <div className={classes + "-alert-inner-container"}>
                        {/* <StyledAlertGlobalContent title="Add Team Members" onCancel={this.onCancelClickHandler} /> */}
                        <Text className={classes + "-alert-subtitle"}>Team members</Text>
                        <div className={classes + "-alert-team-members"}>
                            <SelectListAdd
                                keyName="title"
                                displayedList={invitedMembers}
                                onUpdateList={(list) => this.setState({ invitedMembers: list })}
                                options={users}
                            />
                        </div>
                    </div>
                </StyledAlert>
                <Switch location={routeProps.history.location}>
                    <Route
                        exact={true}
                        path={`/account-settings/teams/edit/members/${currentTeam.id}`}
                        render={() => this.renderMembers(classes)}
                    />
                    <Route
                        exact={true}
                        path={`/account-settings/teams/edit/settings/${currentTeam.id}`}
                        render={() => (
                            <TeamsSettingsAdd
                                routeProps={routeProps}
                                onCloseRightPanel={this.props.onCloseRightPanel}
                                onToggleRightPanel={this.props.onToggleRightPanel}
                                onUpdateChangesMade={this.props.onUpdateChangesMade}
                                team={currentTeam}
                            />
                        )}
                    />
                    <Redirect
                        exact={true}
                        from={`/account-settings/teams/edit/${currentTeam.id}`}
                        to={`/account-settings/teams/edit/members/${currentTeam.id}`}
                    />
                </Switch>
            </div>
        );
    }

    private onConfirmInviteTeamMembers = () => {
        const { invitedMembers, currentTeam } = this.state;
        if (invitedMembers.length > 0) {
            this.props.onConfirmInviteTeamMembers(invitedMembers, currentTeam.id);
        }
        this.setState({ showAlert: false });
    };

    private renderMembers = (classes: string) => {
        const { currentTeam, listHeaderChecked } = this.state;
        const { isOrSmallerTabletPortraitSize } = this.props;
        return (
            <React.Fragment>
                {currentTeam.members.length === 0 ? (
                    <ContainerEmpty
                        className={classes + "-empty"}
                        title={`No Team Found`}
                        subtitle="It looks like no one has signed up for your Team yet."
                        IconComponent={GroupBlueIcon}
                    />
                ) : (
                    <SimpleList
                        isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                        renderedComponent={this.renderList}
                        list={currentTeam.members}
                        showOptions={listHeaderChecked}
                        placeholder="Search teams…"
                    />
                )}
            </React.Fragment>
        );
    };

    private renderList = (props: any) => {
        return (
            <div className={"team-settings-edit-list"}>
                {props.list.map((team: any, index: number) => (
                    <SettingsGroupsListItem
                        key={index}
                        active={true}
                        checked={team.checked}
                        onCheckChanged={() => this.onCheckChanged(index)}
                        description={team.description}
                        onClick={() => this.onViewUser(index)}
                        members={team.members}
                        selectValue={"Active Since " + team.date}
                        options={["edit", "delete", "deactivate"]}
                        title={team.title}
                    />
                ))}
            </div>
        );
    };

    private onViewUser = () => {
        // const { routeProps } = this.props;
        // const { currentTeam } = this.state;
        // const history = routeProps.history;
        // const currentUser = currentTeam.members[index];
        // this.props.onViewUser( history, currentUser );
    };

    private onCheckChanged = (index: number) => {
        const { currentTeam } = this.state;
        currentTeam.members[index].checked = !currentTeam.members[index].checked;
        if (currentTeam.members.some((element: any) => element.checked)) {
            this.setState({ listHeaderChecked: true });
        } else if (currentTeam.members.every((element: any) => !element.checked)) {
            this.setState({ listHeaderChecked: false });
        }
        this.setState({ currentTeam });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    };

    private onChangeTab = (newTabNum: TabId) => {
        const { routeProps } = this.props;
        const { tabs, currentTeam } = this.state;
        const tabId = tabs[newTabNum].toLowerCase().replace(/\s+/g, "-");
        this.setState({ selectedTabNum: newTabNum });
        routeProps.history.push(`/account-settings/teams/edit/${tabId}/${currentTeam.id}`);
    };

    private updateToolBar = () => {
        const { routeProps } = this.props;
        const { currentTeam } = this.state;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Teams", pathname: "/account-settings/teams" },
                        { text: currentTeam.name }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledButton
                    type={StyledButtonTypes.primary}
                    text="INVITE MEMBERS"
                    onClick={this.onInviteTeamMembers}
                />
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    };

    private onInviteTeamMembers = () => {
        const { showAlert } = this.state;
        this.setState({ showAlert: !showAlert });
    };
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings, global } = state;
    const { isOrSmallerTabletPortraitSize } = global;
    const { users } = accountSettings.general;
    return {
        users: users.list,
        isOrSmallerTabletPortraitSize
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onConfirmInviteTeamMembers
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TeamsSettingsEdit);
