import {
    USER_ONBOARDING_UPDATE,
    USER_ONBOARDING_CONFIRM,
    UserOnboardingActionTypes
} from "../actions/user-onboarding/types";
import { UserOnBoardingState, defaultUserOnBoardingState } from "../store";

export const userOnBoardingReducer = (
    state: UserOnBoardingState = defaultUserOnBoardingState,
    action: UserOnboardingActionTypes
) => {
    switch (action.type) {
        case USER_ONBOARDING_CONFIRM:
            return {
                ...state,
                ...defaultUserOnBoardingState
            };
        case USER_ONBOARDING_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
