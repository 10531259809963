import { Alignment, Justify, Incidents, Priorities, Severities, States, Utilities } from "../";

export const NS = "mpd";

export const CONTAINER_PADDING = `${NS}-container-padding`;
export const CONTAINER_PADDING_VERTICAL = `${NS}-container-padding-vertical`;
export const CONTAINER_PADDING_TOP = `${NS}-container-padding-top`;
export const CONTAINER_PADDING_BOTTOM = `${NS}-container-padding-bottom`;
export const CONTAINER_PADDING_HORIZONTAL = `${NS}-container-padding-horizontal`;
export const CONTAINER_PADDING_LEFT = `${NS}-container-padding-left`;
export const CONTAINER_PADDING_RIGHT = `${NS}-container-padding-right`;

export const CONTAINER_INPUTS_ROW_GAP = `${NS}-container-inputs-row-gap`;
export const CONTAINER_INPUTS_COLUMN_GAP = `${NS}-container-inputs-column-gap`;

export const CARD_BOX_SHADOW = `${NS}-card-box-shadow`;

export const CONTAINER_MARGIN = `${NS}-container-margin`;
export const CONTAINER_MARGIN_VERTICAL = `${NS}-container-margin-vertical`;
export const CONTAINER_MARGIN_HORIZONTAL = `${NS}-container-margin-horizontal`;
export const CONTAINER_MARGIN_BOTTOM = `${NS}-container-margin-bottom`;
export const CONTAINER_MARGIN_TOP = `${NS}-container-margin-top`;
export const CONTAINER_MARGIN_LEFT = `${NS}-container-margin-left`;
export const CONTAINER_MARGIN_RIGHT = `${NS}-container-margin-right`;

export const ASSIGN_BLOCK_PADDING = `${NS}-assign-block-padding`;
export const ASSIGN_BLOCK_MARGIN = `${NS}-assign-block-margin`;

export const BLOCK_MARGIN = `${NS}-block-margin`;
export const BLOCK_MARGIN_VERTICAL = `${NS}-block-margin-vertical`;
export const BLOCK_MARGIN_HORIZONTAL = `${NS}-block-margin-horizontal`;
export const BLOCK_MARGIN_BOTTOM = `${NS}-block-margin-bottom`;
export const BLOCK_MARGIN_TOP = `${NS}-block-margin-top`;
export const BLOCK_MARGIN_LEFT = `${NS}-block-margin-left`;
export const BLOCK_MARGIN_RIGHT = `${NS}-block-margin-right`;

export const BLOCK_PADDING = `${NS}-block-padding`;
export const BLOCK_PADDING_VERTICAL = `${NS}-block-padding-vertical`;
export const BLOCK_PADDING_HORIZONTAL = `${NS}-block-padding-horizontal`;
export const BLOCK_PADDING_BOTTOM = `${NS}-block-padding-bottom`;
export const BLOCK_PADDING_TOP = `${NS}-block-padding-top`;
export const BLOCK_PADDING_LEFT = `${NS}-block-padding-left`;
export const BLOCK_PADDING_RIGHT = `${NS}-block-padding-right`;
export const BLOCK_PADDING_40 = `${NS}-block-padding-40`;

export const COLUMN = `${NS}-column`;
export const ROW = `${NS}-row`;
export const ROW_WRAP = `${NS}-row-wrap`;
export const RESPONSIVE = `${NS}-responsive`;

export const DIVISION_1 = `${NS}-division-1`;
export const DIVISION_2 = `${NS}-division-2`;
export const DIVISION_3 = `${NS}-division-3`;
export const DIVISION_4 = `${NS}-division-4`;
export const DIVISION_5 = `${NS}-division-5`;
export const DIVISION_6 = `${NS}-division-6`;
export const DIVISION_7 = `${NS}-division-7`;
export const DIVISION_8 = `${NS}-division-8`;
export const DIVISION_9 = `${NS}-division-9`;
export const DIVISION_10 = `${NS}-division-10`;
export const DIVISION_11 = `${NS}-division-11`;
export const DIVISION_12 = `${NS}-division-12`;

export const JUSTIFY_TOP = `${NS}-justify-top`;
export const JUSTIFY_TOP_MIDDLE = `${NS}-justify-top-middle`;
export const JUSTIFY_BOTTOM = `${NS}-justify-bottom`;
export const JUSTIFY_BOTTOM_MIDDLE = `${NS}-justify-bottom-middle`;
export const JUSTIFY_CENTER = `${NS}-justify-center`;

export const ALIGN_RIGHT = `${NS}-align-right`;
export const ALIGN_RIGHT_MIDDLE = `${NS}-align-right-middle`;
export const ALIGN_LEFT = `${NS}-align-left`;
export const ALIGN_LEFT_MIDDLE = `${NS}-align-left-middle`;
export const ALIGN_CENTER = `${NS}-align-center`;

export const SEVERE = `${NS}-severe`;
export const MINOR = `${NS}-minor`;
export const MODERATE = `${NS}-moderate`;
export const EXTREME = `${NS}-extreme`;

export const PRIORTY1 = `${NS}-p1`;
export const PRIORTY2 = `${NS}-p2`;
export const PRIORTY3 = `${NS}-p3`;
export const PRIORTY4 = `${NS}-p4`;

export const ACTIVE = `${NS}-active`;
export const SELECT = `${NS}-select`;
export const DISABLED = `${NS}-disabled`;
export const HOVER = `${NS}-hover`;
export const DEFAULT = `${NS}-default`;

export const NEW = `${NS}-new`;
export const OPEN = `${NS}-open`;
export const PENDING = `${NS}-pending`;
export const CLOSED = `${NS}-closed`;
export const FINISHED = `${NS}-finished`;
export const SELECTED = `${NS}-selected`;
export const EXPANDED = `${NS}-expanded`;
export const COLLAPSED = `${NS}-collapsed`;
export const HAS_CHILD = `${NS}-has-child`;
export const NO_CHILD = `${NS}-no-child`;
export const UNSELECTED = `${NS}-unselected`;
export const LOADING = `${NS}-loading`;
export const LIST_LOADER_ANIMATED = `${NS}-list-loader-animated`;
export const ERROR = `${NS}-error`;
export const SUCCESS = `${NS}-success`;

export const FIRST = `${NS}-first`;
export const LAST = `${NS}-last`;

export const EMPTY = `${NS}-empty`;
export const NOT_EMPTY = `${NS}-not-empty`;
export const FULL = `${NS}-full`;

export const HIDE = `${NS}-hide`;
export const SHOW = `${NS}-show`;

export const TOP = `${NS}-top`;
export const BOTTOM = `${NS}-bottom`;
export const LEFT = `${NS}-left`;
export const RIGHT = `${NS}-right`;

export function justifyClass(justify?: Justify) {
    switch (justify) {
        case Justify.TOP:
            return JUSTIFY_TOP;
        case Justify.TOP_MIDDLE:
            return JUSTIFY_TOP_MIDDLE;
        case Justify.BOTTOM:
            return JUSTIFY_BOTTOM;
        case Justify.BOTTOM_MIDDLE:
            return JUSTIFY_BOTTOM_MIDDLE;
        case Justify.CENTER:
            return JUSTIFY_CENTER;
        default:
            return undefined;
    }
}

export function alignmentClass(alignment?: Alignment) {
    switch (alignment) {
        case Alignment.CENTER:
            return ALIGN_CENTER;
        case Alignment.RIGHT:
            return ALIGN_RIGHT;
        case Alignment.RIGHT_MIDDLE:
            return ALIGN_RIGHT_MIDDLE;
        case Alignment.LEFT:
            return ALIGN_LEFT;
        case Alignment.LEFT_MIDDLE:
            return ALIGN_LEFT_MIDDLE;
        default:
            return undefined;
    }
}

export function statesClass(state?: States) {
    switch (state) {
        case States.hover:
            return HOVER;
        case States.selected:
            return SELECTED;
        case States.unselected:
            return UNSELECTED;
        case States.default:
            return;
        default:
            return undefined;
    }
}

export function utilitiesClass(utility?: Utilities) {
    switch (utility) {
        case Utilities.error:
            return ERROR;
        case Utilities.loading:
            return LOADING;
        case Utilities.success:
            return SUCCESS;
        default:
            return undefined;
    }
}

export function severitiesClass(severity?: string | Severities) {
    if (severity) {
        switch ((severity as string).toLowerCase()) {
            case Severities.severe:
                return SEVERE;
            case Severities.moderate:
                return MODERATE;
            case Severities.minor:
                return MINOR;
            case Severities.extreme:
                return EXTREME;
            default:
                return undefined;
        }
    }
    return;
}

export function prioritiesClass(priority?: string | Priorities) {
    if (priority) {
        switch ((priority as string).toLowerCase()) {
            case Priorities.p1:
                return PRIORTY1;
            case Priorities.p2:
                return PRIORTY2;
            case Priorities.p3:
                return PRIORTY3;
            case Priorities.p4:
                return PRIORTY4;
            default:
                return undefined;
        }
    }
    return;
}

export function incidentsClass(priority?: string | Priorities) {
    if (priority) {
        switch ((priority as string).toLowerCase()) {
            case Incidents.new:
                return NEW;
            case Incidents.closed:
                return CLOSED;
            case Incidents.pending:
                return PENDING;
            case Incidents.open:
                return OPEN;
            default:
                return undefined;
        }
    }
    return;
}
