import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Virtuoso } from "react-virtuoso";
import { ListHeader, ListHeaderIcons } from "../../../../components";
import { useDataFetch } from "../../../../hooks/useDataFetch";
import { getAccountSettingsUsersRequest } from "../../../../requests";
import { SettingsUsersListItem } from "../../components";
import {
    // addUser,
    // changeUserStatus,
    // getAccountSettingsUsers,
    // initiateUsers,
    viewUser
} from "src/actions/account-settings/account-settings-api";
import { useDispatch } from "react-redux";
import { changeUserStatus } from "../../../../actions/account-settings/account-settings-api";

const FETCH_DATA_PARAMS_INIT_VALUES = {
    order: "first_name:asc,last_name:asc"
};

export const UsersList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        entities: users,
        getMoreData: getMoreUsers,
        searchValue,
        setAtBottomOfList,
        setSearchValue,
        fetchDataParams,
        setFetchDataParams
    } = useDataFetch({
        entityKey: "users",
        fetchDataRequest: getAccountSettingsUsersRequest,
        skeletonLoading: true,
        listItemHeight: 60,
        fetchDataParams: FETCH_DATA_PARAMS_INIT_VALUES
    });

    const onViewUser = (id: number) => {
        if (id) {
            dispatch(viewUser(id));
            history.push(`/account-settings/users/view/details/id-${id}`);
        }
    };

    const onListStatusChange = (event: any, _: string, user: any) => {
        let value = event.currentTarget.textContent.toLowerCase();
        if (value === "activate") {
            value = "activated";
        } else if (value === "deactivate") {
            value = "deactivated";
        } else if (value === "archive") {
            value = "archived";
        }

        if (value.includes("edit")) {
            onViewUser(user.id);
        } else {
            dispatch(changeUserStatus(value, user));
        }
    };

    const onOrderChange = useCallback(
        (_, newValue: string) => {
            if (newValue.toLowerCase().includes("a-z")) {
                setFetchDataParams({ order: "first_name:asc,last_name:asc" });
            } else {
                setFetchDataParams({ order: "first_name:desc,last_name:desc" });
            }
        },
        [fetchDataParams]
    );

    return (
        <>
            <ListHeader
                placeholder={"Search"}
                className={"simple-list-list-header"}
                // leftElement={showOptions ? <ListHeaderIcons hideDetails={isOrSmallerTabletPortraitSize} /> : undefined}
                // selectChange={this.onListSelectChange}
                hideCheckMarkSelect
                onSearchChange={(e) => setSearchValue(e.target.value)}
                // label={label}
                // leftIcon={leftIcon}
                // hideCheckMarkSelect={hideCheckMarkSelect}
                // hideSortSelect={hideSortSelect}
                // sortOrder={sortOrder}
                sortChange={onOrderChange}
                // checked={listHeaderChecked}
                // placeholder={placeholder}
                // rightElement={rightElement}
                // customHeader={customHeader}
                searchValue={searchValue}
            />
            <Virtuoso
                style={{ height: "100%" }}
                data={users.allIds}
                endReached={getMoreUsers}
                itemContent={(index, id) => {
                    return (
                        <SettingsUsersListItem
                            index={index}
                            account={id === "loading" ? {} : users.byId[id]}
                            loading={id === "loading"}
                            onStatusChanged={onListStatusChange}
                            onClick={onViewUser}
                        />
                    );
                }}
                atBottomStateChange={setAtBottomOfList}
                // components={{
                //     ScrollSeekPlaceholder: ({ index }) => <SettingsUsersListItem loading index={index} />
                // }}
                // increaseViewportBy={INCREASE_VIEWPORT_BY}
                // scrollSeekConfiguration={SCROLL_VELOCITY_CONFIG}
            />
        </>
    );
};
