// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { globalActions, convertToDate, dateTypes } from "src/actions";
import debounce from "lodash/debounce";

import { TabId, Text } from "@blueprintjs/core";

import RoleSettingsAdd from "../role-settings-add/role-settings-add";

import { SelectListAdd } from "../../../components";

import { StyledAlert, StyledAlertTypes, StyledAlertGlobalContent } from "src/components";

import { IDetailView } from "src/containers";

import {
    BreadCrumbs,
    ContainerEmpty,
    SimpleList,
    StyledButton,
    StyledTabs,
    StyledTabsTypes,
    StyledButtonTypes
} from "src/components";

import { SettingsRolesListItem } from "../../../components";
import { ReactComponent as GroupBlueIcon } from "../../../../../mpd-library/icon/assets/group-blue.svg"

import "./styles.scss";
import { AppState, AccountSettingsUserState, AccountSettingsGeneralCurrentRole } from "src/store";
import { AccountUser } from "src/requests";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import {
    clearCurrentRole,
    confirmInviteUsers,
    removeUserFromRole,
    viewUser
} from "src/actions/account-settings/account-settings-api";

interface IRoleSettingsEditProps extends IDetailView {
    currentRole: AccountSettingsGeneralCurrentRole;
    users: Array<AccountSettingsUserState>;
    onClearCurrentRole: () => void;
    onConfirmInviteUsers: (list: Array<string>, id: string) => void;
    onRemoveUserFromRole: (list: AccountUser, currentRole: AccountSettingsGeneralCurrentRole) => void;
    onViewUser: (id: string, routeProps: RouteComponentProps) => void;
}

interface IRoleSettingsEditState {
    currentRole: AccountSettingsGeneralCurrentRole;
    invitedUsers: Array<string>;
    listHeaderChecked: boolean;
    selectedTabNum: TabId;
    tabs: Array<string>;
    showAlert: boolean;
    userFilter: string;
}

class RoleSettingsEdit extends React.Component<IRoleSettingsEditProps, IRoleSettingsEditState> {
    private onSearchUserChange = debounce((params: any) => {
        const { query } = params;
        this.setState({ userFilter: query });
    }, 300);

    constructor(props: IRoleSettingsEditProps) {
        super(props);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.updateToolBar = this.updateToolBar.bind(this);
        this.onAssignRole = this.onAssignRole.bind(this);
        this.onConfirmInviteUsers = this.onConfirmInviteUsers.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.renderRoleUsers = this.renderRoleUsers.bind(this);
        this.renderList = this.renderList.bind(this);
        this.onUserClicked = this.onUserClicked.bind(this);

        const { currentRole } = this.props;

        this.updateToolBar(this.props);

        this.state = {
            currentRole,
            invitedUsers: [],
            listHeaderChecked: false,
            selectedTabNum: 0,
            tabs: ["users", "settings"],
            showAlert: false,
            userFilter: ""
        };
    }

    public componentDidUpdate(prevProps: IRoleSettingsEditProps) {
        const { currentRole } = this.props;
        if (prevProps.currentRole !== currentRole && currentRole.id) {
            this.setState({ currentRole });
            this.updateToolBar(this.props);
        }
    }

    public componentWillUnmount() {
        this.props.onClearCurrentRole();
    }

    public render() {
        const { className, routeProps, currentRole, users } = this.props;
        const { selectedTabNum, showAlert, tabs, invitedUsers } = this.state;
        const classes = classNames("roles-settings-edit", className);
        return (
            <div className={classes}>
                <StyledTabs
                    className={classes + "-tabs"}
                    tabs={tabs}
                    type={[StyledTabsTypes.freeBlue, StyledTabsTypes.padding]}
                    tabsProps={{
                        id: "social-settings-tabs",
                        onChange: this.onChangeTab,
                        selectedTabId: selectedTabNum
                    }}
                    panel={
                        selectedTabNum === 0 ? (
                            this.renderRoleUsers(classes)
                        ) : (
                            <RoleSettingsAdd
                                routeProps={routeProps}
                                onCloseRightPanel={this.props.onCloseRightPanel}
                                onToggleRightPanel={this.props.onToggleRightPanel}
                                onUpdateChangesMade={this.props.onUpdateChangesMade}
                                currentRole={currentRole}
                                RoleSettingsEdit={RoleSettingsEdit}
                            />
                        )
                    }
                />
                <StyledAlert
                    className={classNames(classes + "-alert")}
                    type={StyledAlertTypes.primary}
                    isOpen={showAlert}
                    onCancel={() => this.setState({ showAlert: false, invitedUsers: [] })}
                    onConfirm={this.onConfirmInviteUsers}
                    cancelButtonText="Cancel"
                    confirmButtonText="Okay"
                    globalContentProps={{
                        title: "Assign Role To",
                        onCancel: () =>
                            this.setState({
                                showAlert: false,
                                invitedUsers: []
                            }),
                        hideDescription: true
                    }}
                >
                    <div className={classes + "-alert-inner-container"}>
                        {/* <StyledAlertGlobalContent
                            title="Assign Role To"
                            onCancel={() =>
                                this.setState({
                                    showAlert: false,
                                    invitedUsers: []
                                })
                            }
                            hideDescription={true}
                        /> */}
                        <Text className={classes + "-alert-subtitle"}>Users</Text>
                        <div className={classes + "-alert-team-members"}>
                            <SelectListAdd
                                keyName="first_name"
                                displayedList={invitedUsers}
                                onUpdateList={(list) => this.setState({ invitedUsers: list })}
                                options={users}
                            />
                        </div>
                    </div>
                </StyledAlert>
            </div>
        );
    }

    private onConfirmInviteUsers() {
        const { invitedUsers, currentRole } = this.state;
        this.props.onConfirmInviteUsers(invitedUsers, currentRole.id);
        this.setState({
            showAlert: false,
            invitedUsers: []
        });
    }

    private renderRoleUsers(classes: string) {
        const { currentRole, listHeaderChecked } = this.state;
        const { isOrSmallerTabletPortraitSize } = this.props;
        return (
            <React.Fragment>
                {currentRole.users === undefined || currentRole.users.length === 0 ? (
                    <ContainerEmpty
                        className={classes + "-empty"}
                        title={`No Users Found`}
                        subtitle={"It looks like you havent assigned this role to any users yet"}
                        IconComponent={GroupBlueIcon}
                    />
                ) : (
                    <SimpleList
                        isOrSmallerTabletPortraitSize={isOrSmallerTabletPortraitSize}
                        placeholder="Search members…"
                        renderedComponent={this.renderList}
                        list={this.onFilterUsers(currentRole.users)}
                        showOptions={listHeaderChecked}
                        sort={["first_name", "last_name"]}
                        hideCheckMarkSelect={true}
                        listId={"usersList"}
                        onGetInitalList={this.onSearchUserChange}
                    />
                )}
            </React.Fragment>
        );
    }

    private onFilterUsers = (users: any) => {
        const { userFilter } = this.state;
        return userFilter
            ? users.filter((user: any) => {
                  return user.last_name.concat(user.first_name).toLowerCase().includes(userFilter.toLowerCase());
              })
            : users;
    };

    private renderList(props: any) {
        return (
            <div className="role-settings-edit-list">
                {props.list.map((user: any, index: number) => (
                    <SettingsRolesListItem
                        key={index}
                        firstName={user.first_name}
                        lastName={user.last_name}
                        date={"Member Since " + convertToDate(user.inserted_at, dateTypes.dateTime)}
                        selectOptions={["Edit", "Remove"]}
                        onClick={() => this.onUserClicked(user)}
                        checkBoxProps={{
                            checked: user.checked,
                            onChange: (e) => this.onCheckChanged(e, index)
                        }}
                        onSelectChanged={(e) => this.onSelectChanged(e, index)}
                    />
                ))}
            </div>
        );
    }

    private onUserClicked(user: any) {
        const { routeProps } = this.props;

        this.props.onViewUser(user.id, routeProps);
    }

    private onSelectChanged(event: any, index: number) {
        const { routeProps, currentRole, onRemoveUserFromRole } = this.props;
        const list = currentRole.users;
        const user = list[index];
        const id = user.id;
        const value = event.currentTarget.textContent.toLowerCase();
        if (value.includes("edit")) {
            this.props.onViewUser(id, routeProps);
        } else if (value.includes("remove")) {
            onRemoveUserFromRole(user, currentRole);
        }
    }

    private onCheckChanged() {
        const { currentRole } = this.state;
        const list = currentRole.users;
        if (list.some((element: any) => element.checked)) {
            this.setState({ listHeaderChecked: true });
        } else if (list.every((element: any) => !element.checked)) {
            this.setState({ listHeaderChecked: false });
        }
        this.setState({ currentRole });
        if (this.props.onUpdateChangesMade) {
            this.props.onUpdateChangesMade(true);
        }
    }

    private onChangeTab(newTabNum: TabId) {
        this.setState({ selectedTabNum: newTabNum });
        if (newTabNum === 0) {
            this.updateToolBar(this.props);
        }
    }

    private updateToolBar(props: IRoleSettingsEditProps) {
        const { routeProps, currentRole } = props;
        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[
                        { text: "Roles", pathname: "/account-settings/roles" },
                        { text: currentRole.title }
                    ]}
                    history={routeProps.history}
                    toggleButtonProps={{
                        onToggle: this.props.onToggleRightPanel,
                        onClose: this.props.onCloseRightPanel
                    }}
                />
            ),
            rightElement: (
                <StyledButton type={StyledButtonTypes.primary} text="Assign Role" onClick={this.onAssignRole} />
            )
        };
        this.props.onUpdateToolBar(topBarProps);
    }

    private onAssignRole() {
        const { showAlert } = this.state;
        this.setState({ showAlert: !showAlert });
    }
}

const mapStateToProps = (state: AppState) => {
    const { accountSettings, global } = state;
    const { isOrSmallerTabletPortraitSize } = global;
    const roles = accountSettings.general.roles;
    const users = accountSettings.general.users.list;

    return {
        users,
        roles,
        isOrSmallerTabletPortraitSize
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) =>
    bindActionCreators(
        {
            onUpdateToolBar: globalActions.onUpdateToolBar,
            onConfirmInviteUsers: confirmInviteUsers,
            onRemoveUserFromRole: removeUserFromRole,
            onViewUser: viewUser,
            onClearCurrentRole: clearCurrentRole
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RoleSettingsEdit);
