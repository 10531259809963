import { AxiosResponse } from "axios";
import { axiosInstance } from "src/actions";
import { getConfig } from "src/common";

// ------------------ CONVERSATIONS REQUESTS -------------------

export type ConversationAdmin = {
    id: string;
    first_name: string;
    last_name: string;
    profile_pic: {
        type: string;
        url: string;
    } | null;
};

export enum ConversationChannelStatuses {
    offline = "offline",
    online = "online"
}

export type ChannelsTypes = "facebook" | "twitter" | "instagram";

export type ConversationChannel = {
    id: string;
    name: string;
    status: ConversationChannelStatuses;
    type: ChannelsTypes;
};

export type ConversationUser = {
    id: string;
    first_name: string;
    last_name: string;
    blocked: boolean | null;
    blocked_until: string | null;
    contacts: {
        email: string | null;
        phone_number: string | null;
    };
    notes: Array<any>;
};

export type Conversation = {
    id: string;
    admin: ConversationAdmin | null;
    admin_id: string | null;
    channel: ConversationChannel;
    inserted_at: string;
    last_message_summary: string;
    last_seen_ts: number;
    number: number;
    queue: {
        id: string;
        name: string;
    };
    queue_id: string;
    rating: "positive" | "negative";
    referrer: null | any;
    snoozed_until: string | null;
    spam: boolean;
    starred: boolean;
    status: "opened" | "assigned" | "resolved";
    tags: Array<Partial<ConversationTag>>;
    unread_count: number;
    updated_at: string;
    user_identity_hidden: boolean;
    user_rating: any | null;
    user: ConversationUser;
};

export type ResponseConversationData = {
    conversation: Conversation;
};

type CountsKyesEnum = "opened" | "assigned" | "mentions" | "mentions_resolved" | "resolved" | "snoozed" | "spam";

export type ConversationsCounts = {
    counts: {
        [key in CountsKyesEnum]: number;
    };
};

/* 
"contact_id": "..",
  "queue_id" => "..",
  "invite_method" => "sms",
  "admin_id" => nil,
  "tag_ids" => [],  
  "notes" => "Any message"
*/

export const createConversation = async (body: any) => {
    // return await new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve("RESOLVED >>>>");
    //     }, 1500);
    // });

    return await axiosInstance.post(`/conversations/create`, body);
    // return await axiosInstance.post("/conversations/create", body)
};

export const getCountsRequest = async () => {
    return await axiosInstance.get<ConversationsCounts>(`/conversations/counts`);
};

export const updateConversationLastSeenTs = (conversatioonId: string, ts: number) => {
    return axiosInstance.post<Conversation>(`/conversations/${conversatioonId}/seen`, { ts });
};

export const getConversationDetailsRequest = async (conversationId: string) => {
    return await axiosInstance.get<ResponseConversationData>(`/conversations/${conversationId}`);
};

export const getConversationsRequest = async (requestParams: {
    limit?: number;
    filters?: string;
    offset?: number;
    view?: "all";
}) => {
    return await axiosInstance.get("/conversations/", {
        params: requestParams
    });
};

export const searchConversationsRequest = async (requestParams: {
    limit?: number;
    filters?: string;
    offset?: number;
    view?: "all";
}) => {
    return await axiosInstance.get("/conversations/search", {
        params: requestParams
    });
};

export type ResolveConversationBody = {
    note: string;
    rating: "positive" | "negative";
    tag_ids: Array<string>;
};

export const resolveConversationRequest = async (id: string, body: ResolveConversationBody) => {
    return await axiosInstance.post(`${config.API_URL}/conversations/${id}/resolve`, body);
};

export const replaceToQueueRequest = async (conversationId: string, queueId: string) => {
    return await axiosInstance.post(`/conversations/${conversationId}/move_to_queue`, {
        queue_id: queueId
    });
};

export const starUserRequest = async (userId: string, starred: boolean) => {
    return await axiosInstance.post(`conversations/users/${userId}/star`, { starred });
};

export const spamConversationRequest = async (conversationId: string) => {
    return await axiosInstance.post(`/conversations/${conversationId}/mark_as_spam`);
};

export const starConversationRequest = async (conversationId: string, starred: boolean) => {
    return await axiosInstance.post(`/conversations/${conversationId}/star`, {
        starred
    });
};

export const snoozeConversationRequest = async (conversationId: string, ts: number) => {
    return await axiosInstance.post(`/conversations/${conversationId}/snooze`, {
        snooze_until: ts
    });
};

export const acceptConversationRequest = async (conversationId: string) => {
    return await axiosInstance.post(`/conversations/${conversationId}/accept`);
};

export const reopenConversationRequest = async (conversationId: string) => {
    return axiosInstance.post(`/conversations/${conversationId}/reopen`);
};

export const returnToQueueRequest = async (conversationId: string, queueId: string) => {
    return await axiosInstance.post(`/conversations/${conversationId}/return`, { queue_id: queueId });
};

export const assignConversationToAdminRequest = async (conversationId: string, adminId: string) => {
    return axiosInstance.post(`/conversations/${conversationId}/assign`, {
        admin_id: adminId
    });
};

export const applyTagsRequest = async (conversationId: string, tagIds: Array<string>) => {
    return await axiosInstance.post(`${config.API_URL}/conversations/${conversationId}/assign_tags`, {
        tag_ids: tagIds
    });
};

export const blockUserRequest = async (userId: string, requestBody: { blocked: boolean; blocked_until: number }) => {
    return await axiosInstance.post(`/conversations/users/${userId}/block`, requestBody);
};

// -------------------------------------------------------------

// ---------------------- QUEUES REQUESTS ----------------------

type QueuesResponseAdmin = {
    admin: {
        first_name: string;
        last_name: string;
        profile_pic: {
            type: string;
            url: string;
        };
    };
    based_on_permissions: boolean;
};

export type ResponseQueue = {
    admins: Array<QueuesResponseAdmin>;
    color: string;
    description: null | string;
    id: string;
    is_default: boolean;
    name: string;
};

type GetConversatonsQueuesResponse = {
    data: { queues: Array<ResponseQueue>; count: number };
};

const config = getConfig();

export const getQueuesAdmins = async (id: string, params) => {
    const res = await axiosInstance.get(`/conversations/queues/${id}/admins`, { params });
};

export const getAdminsForQueue = async (id?: string, params) => {
    return await axiosInstance.get(`/conversations/queues${id ? `/${id}/` : "/"}admins`, { params });
};

export const getConversationsQueues = async ({
    myQueues,
    query,
    offset,
    limit
}: {
    myQueues?: boolean;
    query?: string;
    offset?: number;
    limit?: SVGAnimatedNumberList;
}): Promise<GetConversatonsQueuesResponse> => {
    const requestParams: any = {};

    requestParams.offset = offset;

    if (myQueues) {
        requestParams.filter = "my_queues";
    }

    if (query) {
        requestParams.q = query;
    }

    limit ? (requestParams.limit = limit) : (requestParams.limit = 10);

    const res = await axiosInstance.get(`${config.API_URL}/conversations/queues?limit=10`, { params: requestParams });
    return res;
};

// export const getQueues = async (params) => {
//     return await axiosInstance.get("/queues", params);
// };

export const getQueueDetails = async (queueId: string) => {
    return await axiosInstance.get(`/conversations/queues/${queueId}`);
};

export const createQueueRequest = async (data: Partial<ResponseQueue>) => {
    return await axiosInstance.post(`/conversations/queues/create`, data);
};

export const updateQueueRequest = async (queueId: string, data: Partial<ResponseQueue>) => {
    return await axiosInstance.patch<Partial<ResponseQueue>>(`/conversations/queues/${queueId}`, data);
};

// ------------------------------------------------------------------

// --------------------------------------- SNIPETS REQUESTS ---------------------------------------

export type ConversationsSnippetCategory = {
    id: string;
    name: string;
};

export type ConversationsSnippet = {
    id: string;
    name: string;
    text: string;
};

export type GetConversationsSnippetsResponseItem = {
    category: ConversationsSnippetCategory;
    snippets: Array<ConversationsSnippet>;
};

export type GetSnippetDetailsResponse = ConversationsSnippet & {
    category: ConversationsSnippetCategory;
};

export const getConversationsSnippets = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get<GetConversationsSnippetsResponseItem>(`/conversations/snippets`);
};

export const getSnippetDetails = async (snippetId: string): Promise<AxiosResponse> => {
    return await axiosInstance.get<GetSnippetDetailsResponse>(`/conversations/snippets/${snippetId}`);
};

export const getSnippetsCategories = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get<Array<ConversationsSnippetCategory>>(`/conversations/snippets/categories`);
};

export const createSnippetCategory = async (name: string): Promise<AxiosResponse> => {
    return await axiosInstance.post<Array<ConversationsSnippetCategory>>(`/conversations/snippets/categories/create`, {
        name
    });
};

export const createSnippet = async (requestBody): Promise<AxiosResponse> => {
    return await axiosInstance.post<GetSnippetDetailsResponse>(`/conversations/snippets/create`, requestBody);
};
export const deleteSnippetRequest = async (snippetId: string): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`/conversations/snippets/${snippetId}`);
};

export const updateSnippet = async (snippetId: string, data): Promise<AxiosResponse> => {
    return await axiosInstance.patch<GetSnippetDetailsResponse>(`/conversations/snippets/${snippetId}`, data);
};

// -----------------------------------------------------------------------------------------------

// --------------------------- MESSAGES REQUESTS ---------------------------

export type ConversationMessage = {
    id: string;
    attachments: Array<any>;
    body: string;
    inserted_at: string;
    location?: any | null;
    sent_via_formatted: string;
    type: "message" | "event";
    unsent: boolean;
    unsent_at: string | null;
    updated_at: string | null;
};

export const getConversationMessagesRequest = async (conversationId: string, params: any) => {
    return await axiosInstance.get(`/conversations/${conversationId}/messages`, { params });
};

export const unsendMessage = async (conversationId: string, messageId: string): Promise<AxiosResponse> => {
    const res = await axiosInstance.patch<ConversationMessage>(
        `/conversations/${conversationId}/message/${messageId}/unsend`
    );
    return res;
};

// -------------------------------------------------------------------------

// ----------------------- VIEWS REQUESTS -----------------------

export const getViewsRequest = async () => {
    return await axiosInstance.get(`/conversations/views`);
};

export const deleteViewRequest = async (viewId: string) => {
    return await axiosInstance.delete(`/conversations/views/${viewId}`);
};

// --------------------------------------------------------------

export const searchContacts = async (params) => {
    return await axiosInstance.get(`/contacts`, { params });
};

// --------------------------------------------------------------
